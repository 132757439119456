import { useContext } from "react"
import { makeStyles } from "@mui/styles";
import { Box, Typography, CircularProgress } from "@mui/material"
import { FilesIcon } from '../../../../core/global/Icons'
import { ToolContext } from "../../../../core/context/ToolContext";
import { Loader } from "./Loader";

const useStyles = makeStyles({
    topHalf: {
        color: '#107038',
        '& .MuiCircularProgress-svg': {transform: 'rotate(90deg) !important'}
    }
});

export const Storage = () => {
    const { cloud_state } = useContext(ToolContext)
    const { storage, storageLoader } = cloud_state
    
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
    const classes = useStyles();
    
    return (
        <Box bgcolor="#ffffff" minHeight={262} borderRadius={3} p={2}>
            <Typography fontWeight={600} fontSize={14}>Storage</Typography>
            {storageLoader.data ? 
                <Loader/>
            :
                <>
                    <Box sx={{position: 'relative'}} height={100} display="flex" justifyContent="center">
                        <Box sx={{position: 'absolute', clip: 'rect(0px, 190px, 94px, 0px)'}}>
                            <Box sx={{position: 'relative'}} alignItems="center">
                                <CircularProgress
                                    variant="determinate"
                                    value={100}
                                    sx={{color: '#F8F9FA'}}
                                    size={190}
                                    thickness={4}
                                />
                                <Box sx={{position: 'absolute', left: 0, top: 0}}>
                                    <CircularProgress
                                        variant="determinate"
                                        value={(((storage.data.used / storage.data.total) * 100) / 2) + 50}
                                        className={classes.topHalf}
                                        size={190}
                                        thickness={4}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box mt="50px" textAlign="center">
                            <Box fontSize={20} fontWeight={700} color="#313131">
                                {
                                    storage.data.used === 0 ? (
                                        '0.00B'
                                    ) : (
                                        parseFloat((storage.data.used / Math.pow(1024, parseInt(Math.floor(Math.log(storage.data.used) / Math.log(1024))))).toFixed(2)) + '' + sizes[parseInt(Math.floor(Math.log(storage.data.used) / Math.log(1024)))]
                                    )
                                }
                            </Box>
                            <Box fontSize={12} fontWeight={600} color="#333333">Used of {storage.data.total === 0 ? 0 : parseFloat((storage.data.total / Math.pow(1024, parseInt(Math.floor(Math.log(storage.data.total) / Math.log(1024))))).toFixed(2)) + '' + sizes[parseInt(Math.floor(Math.log(storage.data.total) / Math.log(1024)))]}</Box>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" pt="24px">
                        <Box display="flex" alignItems="center" gap="12px">
                            <FilesIcon/>
                            <Box>
                                <Box fontSize={12} fontWeight={600} color="#313131">Registrations</Box>
                                <Box fontSize={12} fontWeight={600} color="#737373">{storage.data.regFile} files</Box>
                            </Box>
                        </Box>
                        <Box pr="16px" fontSize={12} fontWeight={600} color="#107038">{storage.data.reg === 0 ? `${0} B` : parseFloat((storage.data.reg / Math.pow(1024, parseInt(Math.floor(Math.log(storage.data.reg) / Math.log(1024))))).toFixed(2)) + ' ' + sizes[parseInt(Math.floor(Math.log(storage.data.reg) / Math.log(1024)))]}</Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" pt="8px">
                        <Box display="flex" alignItems="center" gap="12px">
                            <FilesIcon/>
                            <Box>
                                <Box fontSize={12} fontWeight={600} color="#313131">Contracts</Box>
                                <Box fontSize={12} fontWeight={600} color="#737373">{storage.data.conFile} files</Box>
                            </Box>
                        </Box>
                        <Box pr="16px" fontSize={12} fontWeight={600} color="#107038">{storage.data.con === 0 ? `${0} B` :parseFloat((storage.data.con / Math.pow(1024, parseInt(Math.floor(Math.log(storage.data.con) / Math.log(1024))))).toFixed(2)) + ' ' + sizes[parseInt(Math.floor(Math.log(storage.data.con) / Math.log(1024)))]}</Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" pt="8px">
                        <Box display="flex" alignItems="center" gap="12px">
                            <FilesIcon/>
                            <Box>
                                <Box fontSize={12} fontWeight={600} color="#313131">Documents</Box>
                                <Box fontSize={12} fontWeight={600} color="#737373">{storage.data.docFile} files</Box>
                            </Box>
                        </Box>
                        <Box pr="16px" fontSize={12} fontWeight={600} color="#107038">{storage.data.docs === 0 ? `${0} B` :parseFloat((storage.data.docs / Math.pow(1024, parseInt(Math.floor(Math.log(storage.data.docs) / Math.log(1024))))).toFixed(2)) + ' ' + sizes[parseInt(Math.floor(Math.log(storage.data.docs) / Math.log(1024)))]}</Box>
                    </Box>
                </>
            }
        </Box>
    )
}