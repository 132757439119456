import { Box } from "@mui/material"
import { Top } from "./Top"
import { TBody } from "./TBody"
import { View } from "./View"
import { useContext, useState } from "react"
import { ToolContext } from "../../../../../core/context/ToolContext"
import { NewSuccessAlert } from "../../../../../core/global/forms/CstmModal"
import { Loader } from "../../../../fetching/Loader"
import { NoFound } from "../../../../mycloud/global/DesktopDsg"
import { NoResult } from "../../../../empty/Index"

export const ConList = ({ concernData, concernForm, __SESSION, uai_user, handleView, cModal, setCModal, conSearch, setConSearch, loadMoreData }) => {
    const { speakout_state } = useContext(ToolContext)
    const { successOpen, message, isLoading, infntScroll } = speakout_state
    const [open, setOpen] = useState(false)

    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column">
            <Box minHeight="140px">
                <Top concernData={concernData} __SESSION={__SESSION} uai_user={uai_user} conSearch={conSearch} isLoading={isLoading} setConSearch={setConSearch}/>
            </Box>
            {
                isLoading.data ?
                <Loader />
                :
                concernData.data.length === 0 ?
                    conSearch === '' ?
                    <NoFound msg="No Concern Found!"/>
                    :
                    <NoResult />
                :
                <TBody concernData={concernData} handleView={handleView} isLoading={isLoading} conSearch={conSearch} setConSearch={setConSearch} loadMoreData={loadMoreData} infntScroll={infntScroll}/>
            }
            {
                cModal.view && 
                <View open={open} setOpen={setOpen} concernForm={concernForm} __SESSION={__SESSION} uai_user={uai_user} cModal={cModal} setCModal={setCModal}/>
            }
            {
                successOpen.data &&
                <NewSuccessAlert successOpen={successOpen.data} setSuccessOpen={successOpen.set} text={message.data}/>
            }
        </Box>
    )
}