import { Box, Skeleton } from "@mui/material"

export const Loader = () => {

    return (
        <>
            <Box px={2}>
                <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={1}>
                    <Skeleton variant="rectangular" width='100px' height="15px" sx={{borderRadius:'4px'}}/>
                </Box>
                <Skeleton variant="rectangular" width='100%' height="40px" sx={{borderRadius:'4px'}}/>
                <Box mt={2}>
                    <Skeleton variant="rectangular" width='100%' height="206px" sx={{borderRadius:'4px'}}/>
                </Box>
                <Box mt="20px" mb={1}> </Box>
                <Skeleton variant="rectangular" width='100px' height="15px" sx={{borderRadius:'4px'}}/>
                <Box mb={1}> </Box>
                <Skeleton variant="rectangular" width='100%' height="40px" sx={{borderRadius:'4px'}}/>
                <Box mt="20px" mb={1}> </Box>
                <Skeleton variant="rectangular" width='100px' height="15px" sx={{borderRadius:'4px'}}/>
                <Box mb={1}> </Box>
                <Skeleton variant="rectangular" width='100%' height="40px" sx={{borderRadius:'4px'}}/>
                <Box mt="20px" mb={1}> </Box>
                <Skeleton variant="rectangular" width='100px' height="15px" sx={{borderRadius:'4px'}}/>
                <Box mb={1}> </Box>
                <Box display="flex" gap="12px">
                    <Skeleton variant="rectangular" width='50%' height="40px" sx={{borderRadius:'4px'}}/>
                    <Skeleton variant="rectangular" width='50%' height="40px" sx={{borderRadius:'4px'}}/>
                </Box>
            </Box>
        </>
    )
}