import { Box, Skeleton } from '@mui/material'

export const LoaderFiles = () => {
    return (
        ['','','','','','','','','',''].map((v, k) => (
            <Box key={k} p={1} display="flex" alignItems="center" justifyContent="space-between" bgcolor="#FFFFFF" borderRadius="6px" width="100%" fontSize={12} fontWeight={600} gap="12px">
                <Box width="70%" display="flex" alignItems="center" gap="12px" >
                    <Skeleton variant="rectangular" sx={{minWidth:40, height:40, borderRadius:'8px'}} />
                    <Skeleton variant="text" sx={{width:'100%', height:'30px', borderRadius:'8px'}} />
                </Box>
                <Box display="flex" alignItems="center" minHeight={20} minWidth={40} > 
                    <Box display="flex" alignItems="center" justifyContent="flex-end" gap="8px">
                        <Skeleton variant="rectangular" sx={{borderRadius: '8px'}} width={20} height={20} />
                        <Skeleton variant="rectangular" sx={{borderRadius: '8px'}} width={5} height={20} />
                    </Box>
                </Box>
            </Box>
        ))
    )
}
