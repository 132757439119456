import { useContext } from 'react';
import { Box } from '@mui/material';
// import { Points } from './Points';
import { NoGraphicalReportFound, NoToolFound } from '../../../empty/Index';
import { Index as GraphReport } from '../../chart/Index'
import { Index as List } from './tool/Index';
import { ToolContext } from '../../../../core/context/ToolContext';

export const Index = ({commPoints, expPoints, toolOwned }) => {
    const { home_state } = useContext(ToolContext)
  
    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column" py={3}>
            {/* <Box minHeight="88px" height="88px" className='tenth-step' bgcolor="#00923D" borderRadius={3}>
                <Points commPoints={commPoints} expPoints={expPoints} />
                <Box bgcolor="#C2E8DB" borderRadius="0px 0px 10px 10px" height="15px" mx={2}/>
            </Box> */}
            <Box height="100%" width="100%" display="flex" flexDirection="column">
                <Box minHeight="30px" pb={3} component="strong">Tool Graphical Reports</Box>
                <Box minHeight="390px">
                    <GraphReport selectTool={home_state.selectTool.data} toolOwned={toolOwned} NoGraphicalReportFound={NoGraphicalReportFound} />
                </Box>
                <Box height="100%" >
                    <List selectTool={home_state.selectTool.data} setSelectTool={home_state.selectTool.set} toolOwned={toolOwned} NoToolFound={NoToolFound} />
                </Box>
            </Box>
        </Box>
    )
}