import { Box, Button, InputAdornment, TextField } from "@mui/material"
import { ArrowDown } from "../../../../../core/global/Icons"
import { FormatFilesize, UnsavedMsgModal } from "../../../global/DesktopDsg"
import { motion } from "framer-motion"
import Add  from '../../../../../assets/images/icons/add.png'
import Pdf  from '../../../../../assets/images/icons/pdf.png'

export const Left = ({form, listRegName, modal, dsg, regsn, getRootProps, getInputProps, nav, ocrLoader, subparams, actType, prevURL, __SESSION}) => {
    const urlParams = new URLSearchParams(window.location.search);

    const HandlerCancel = () => {
        if ( atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0') {
            nav.push('/ubr?type=select')
        }else{
            if ( subparams === 'review') {
                modal.set({...modal.data, unsaved : { val : true } })
                return
            }else{
                form.set({...form.data, 
                    date_from:{val:'', stat:false, msg:''},
                    date_to:{val:'', stat:false, msg:''},
                    regName:{val:'', stat:false, msg:''},
                    regType:{val:'', stat:false, msg:''},
                    regFile:{
                        photo:{val:'', stat:false, msg:''}, 
                        file:{val:'', stat:false, msg:''}
                    },
                    name:{val:'', stat:false, msg:''},
                    number:{val:'', stat:false, msg:''},
                    regOther:{val:'', stat:false, msg:''},
                })
            }
            if (urlParams.get('filter') === 'ubr') {
                nav.push(prevURL.data)
            }else{
                nav.push('/my-cloud/registrations/list')
            }
        }
    }

    const HandlerOpenSelect = () => {
        if (subparams === 'create') {
            if (!ocrLoader.data) {
                modal.set({...modal.data, openRegList : { val : true } })
            }
        }
    }

    const HandlerBtn = (type) => {
        if (type === 'stay') {
            modal.set({...modal.data, unsaved : { val : false } })
        }else{
            modal.set({...modal.data, unsaved : { val : false } })
            if (urlParams.get('filter') === 'ubr') {
                nav.push(prevURL.data)
            }else{
                nav.push('/my-cloud/registrations/list')
            }
        }
    }
    
    return (
        <Box height="100%" width="50%" bgcolor="#FFFFFF" px="60px" pt="64px" pb="118px" display="flex" flexDirection="column">
            <Box>
                <Button variant="contained" disableElevation onClick={() => HandlerCancel()}
                    sx={{bgcolor: '#F8F9FA', color: '#333333', fontWeight: 600, py: '12px', lineHeight: '16px', borderRadius: '6px', border: '1px solid #E6E6E6',
                    '&:hover': {bgcolor: '#E2EDE7', color: '#107038'} }}
                >
                    Cancel
                </Button>
                <Box display="flex" alignItems="baseline" mt={5} mb={4} lineHeight="26px">
                    <Box fontSize={20} fontWeight={600} mr={2}> {subparams === 'create' ? 'Upload Registration' : subparams === 'review' ? 'Review : Registration Info' : 'Update Registration'} </Box>
                    <Box fontSize={12} fontWeight={400}>Registration Number: { actType.data === 'update' ? form.data.series_num : regsn.data }</Box>
                </Box>
                <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={1}>Registration Name*</Box>
                <TextField fullWidth 
                    value={ form.data.regName.val !== '' ? listRegName.data.filter((f) => {return f.id === form.data.regName.val})[0].name : ''} 
                    onClick={() => HandlerOpenSelect()}
                    className={dsg.txtfld}
                    sx={{ '& .MuiOutlinedInput-root':{ backgroundColor: ocrLoader.data || subparams !== 'create' ? '#E6E6E6 !important' : '#FFFFFF' } }}
                    inputProps={{ style: { padding: '14px 16px'} }} 
                    InputProps={{ 
                        endAdornment: (
                            <InputAdornment position='end'>
                                <ArrowDown stroke="#333333" />
                            </InputAdornment>
                        )
                    }}
                />
                 <Box>
                    {
                        form.data.regName.val === 0 &&
                            <Box fontSize={12} lineHeight="19px" fontWeight={400} mt={4}>
                                Our scanning feature does not support other registration types.
                            </Box>
                    }
                </Box>
            </Box>
            <Box>
            <UploadFileDsg form={form} getRootProps={getRootProps} getInputProps={getInputProps} ocrLoader={ocrLoader} />
                {
                    form.data.regFile.file.val !== '' && subparams !== 'review' &&
                        <Box display="flex" justifyContent="center" mt={3}>
                            <Button {...getRootProps({className: 'dropzone'})} disabled={ocrLoader.data}
                                sx={{bgcolor: '#F8F9FA', border: '1px solid #E6E6E6', color: '#333333', p: '12px 16px', lineHeight: '16px', borderRadius: '6px'}}
                            >
                                Change
                            </Button>
                        </Box>
                }
            </Box>
            <UnsavedMsgModal open={modal.data.unsaved.val} HandlerBtn={HandlerBtn} />
        </Box>
    )
}

const UploadFileDsg = ({form, getRootProps, getInputProps, ocrLoader}) => {
  
    return(
        <Box minHeight="272px" height="272px" bgcolor="#F8F9FA" borderRadius="12px" p={2} mt={4} className="overflowY ubr-upload" display="flex" alignItems="center" justifyContent="center">
            {
                form.data.regFile.file.val !== '' ? 
                    <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column" className={!ocrLoader.data && "overflowY" } position="relative">
                        {
                            form.data.regFile.file.val[0].type.includes('image') ?
                                <img src={form.data.regFile.photo.val} alt={form.data.regFile.photo.val} height="100%" width="auto" />
                            :
                                <Box display="flex" alignItems="center" width="100%"  p="9px 13px" bgcolor="#FFFFFF" borderRadius="6px" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)"> 
                                    <img src={Pdf} alt={Pdf} />
                                    <Box display="flex" flexDirection="column" ml="13px"> 
                                        <Box color="#333333" fontWeight={600} fontSize={12} lineHeight="16px" flexWrap="wrap"> {form.data.regFile.file.val[0].name} </Box>
                                        <Box color="#737373" fontWeight={400} fontSize={10} lineHeight="16px"> {FormatFilesize(form.data.regFile.file.val[0].size)} </Box>
                                        
                                    </Box>
                                </Box>
                        }
                        <Box bgcolor="#FFFFFF" height="100%" width="100%" position="absolute" sx={{opacity: 0.2}} className="overflowY" /> 
                        {
                            ocrLoader.data &&
                                <Box  position="absolute" width="100%"  component={motion.div} animate={{ y: [-150, 150] }} transition={{ repeat: Infinity, repeatType: "reverse" }}>
                                    <Box sx={{background: "linear-gradient(360deg, rgba(16, 112, 56, 0.12) 3.33%, rgba(16, 112, 56, 0) 65.83%)", }} width="100%" height="60px"  />
                                    <Box bgcolor="#107038" borderRadius="100px" width="100%" height="4px"   />
                                    <Box sx={{background: "linear-gradient(360deg, rgba(16, 112, 56, 0.12) 3.33%, rgba(16, 112, 56, 0) 65.83%)", transform: 'rotate(-180deg)' }} width="100%" height="60px"  />
                                </Box>
                        }
                    </Box>
                :
                    <Box {...getRootProps({className: 'dropzone'})} border="2px dashed #D9D9D9" height="100%" width="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column" className="c-pointer">
                        <img src={Add} alt={Add} />
                        <Box mt={5} fontSize={14} fontWeight={400} lineHeight="19px">Drag and drop an image/pdf, or Browse</Box>
                        <Box mt="12px" fontSize={12} fontWeight={400} lineHeight="19px">Maximum file size is 3 MB</Box>
                        <input {...getInputProps()} />
                    </Box>
            }
        </Box>
    )
}