import { env } from "../../core/Env"
import { rqx } from "../../core/request/API"
import { db } from "../../db/db"

export const UbrApi = async(ubrdata, comp_id, __SESSION) => {
    let regids = 0;
    let conids = 0;
    let docids = 0;
    let polids = 0;
    if (ubrdata.reglist.length > 0) {
        let rd = ubrdata.reglist[0]
        regids = rd.id
        let data = {
            id:0,
            name:rd.registration_name,
            type:rd.type,
            company_id:comp_id,
            user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
            registered_name:rd.registered_name,
            reference_num:rd.reference_num,
            start_date:rd.start_date === null ? '' : rd.start_date,
            end_date:rd.end_date === null ? '' : rd.end_date,
            file_name:rd.file_name,
            file_type:rd.file_type,
            file_size:rd.file_size,
            file_link:rd.file_link,
            storage:rd.storage,
            ref:''
        }
        await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/registration/create`, data)
        // if (res.message === 'success') {
        //     regids = rd.id
        // }
    }
    if (ubrdata.conlist.length > 0) {
        let cd = ubrdata.conlist[0]
        let data = {
            id:0,
            name:cd.contract_name,
            company_id:comp_id,
            user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
            client_name:cd.client_name,
            start_date:cd.start_date,
            end_date:cd.end_date,
            file:JSON.parse(cd.file),
            ref:''
        }
        conids = cd.id
        await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/contracts/create`, data)
        // if (res.message === 'success') {
        //     conids = cd.id
        // }
    }

    if (ubrdata.docs.length > 0) {
        let dcs = ubrdata.docs[0]
        docids = dcs.id
        if (dcs.type === 'create_folder') {
            let data = {
                parent_id: 0,
                totalSize:dcs.file_size,
                file:dcs.file,
                user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                company_id: comp_id,
                status: 1,
                filter: 'insert'
            }
            await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/documents/uploadFolder`, data)
        }else{
            let data = {
                type: 'create_file',
                company_folder_id: '',
                user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                company_id: comp_id,
                status: 1,
                file: dcs.file,
            }
            await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/documents/create`, data)
        }
        // if (res === 'success' || res.msg === 'success') {
        //     docids = dcs.id
        // }
    }

    if (ubrdata.pol.length > 0) {
        let pol = ubrdata.pol[0]
        let data = {...pol,
            company_id: comp_id,
            user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
            preparedby:atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
            approvedby:atob(__SESSION.data.ainfo.aid).split('_', 2)[1]
        }
        polids = pol.id
        await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/policy/create`, data)
        // console.log(res)
        // if (res.msg === 'success') {
        //     polids = pol.id
        // }
    }
   
    polids !== 0 && db.pol.delete(polids)
    regids !== 0 && db.reglist.delete(regids)
    conids !== 0 && db.conlist.delete(conids)
    docids !== 0 && db.docs.delete(docids)
    return 'success'
}