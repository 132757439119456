import { aws, env } from '../Env';
import { jsPDF } from "jspdf";
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import S3FileUpload from 'react-s3';

const config = {
    bucketName: aws('bucket_name'),
    dirName: `biz/${env()}`,
    region: aws('region'),
    accessKeyId: aws('access_key'),
    secretAccessKey: aws('secret_key'), 
}

export const S3Upload = (file) => {
    const d = new Date()
    let split_name = file[0].name.split('.')
    let ext = split_name[split_name.length - 1]
    let blob = file[0].slice(0, file[0].size, file[0].type);
    let newFile = new File([blob], `${d.getTime()}.${ext}`, {
        type: file[0].type
    });

    return new Promise(resolve => {
        S3FileUpload.uploadFile(newFile, config).then(data => {
            return resolve(data)
        }).catch(err => {
            return resolve(err)
        })
    })
}

export const Download = (e, links, name) => {
    return new Promise(resolve => {
        fetch(links, {method: 'GET', headers: { "Cache-Control": 'no-cache' } })
        .then(res => {
            return res.blob();
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();  
            setTimeout(
            _ => { window.URL.revokeObjectURL(url); },
            60000);
            a.remove();
            return resolve('success')
        })
        .catch(err => {
            console.error('err: ', err);
            return resolve('error')
        })
    }).catch(err => {
        console.error('err: ', err);
        return 'error'
    })
}

export const DownloadPDF = (link, name) => {
    const doc = new jsPDF();
    doc.addImage(link, name.split('.')[1], 15, 40, 180, 180);
    doc.save(name.split('.')[0] + '.pdf');
    return 'success'
}

export const DownloadZip = async(data) => {
    const zip = new JSZip();

    for (let i = 0; i < data.length; i++) {
        var mainFolder = data[0].folder_name
        if (data[i].parent_id === 0) {
            mainFolder = zip.folder(data[i].folder_name);
            // eslint-disable-next-line 
            const addFileToZip = async (file) => {
                try {
                    const content = await JSZipUtils.getBinaryContent(file.link);
                    mainFolder.file(file.name, content, { binary: true });
                } catch (error) {
                    console.error(`Error downloading file ${file.name} from ${file.link}:`, error);
                }
            };
    
            await Promise.all(JSON.parse(data[i].files).map(addFileToZip))
        }
        else{
            for (let j = 0; j < data.length; j++) {
                const subFolders = zip.folder(mainFolder);
                let subFolder = data[i].folder_name
                if (data[i].parent_id === data[j].id) {
                    if (data[j].parent_id === 0) {
                        subFolder = subFolders.folder(data[i].folder_name);
                    }else{
                        subFolder = subFolders.folder(data[j].folder_name + '/' + data[i].folder_name);
                    }
                    // eslint-disable-next-line 
                    const addFileToZip = async (file) => {
                        try {
                            const content = await JSZipUtils.getBinaryContent(file.link);
                            subFolder.file(file.name, content, { binary: true });
                        } catch (error) {
                            console.error(`Error downloading file ${file.name} from ${file.link}:`, error);
                        }
                    };
                    await Promise.all(JSON.parse(data[i].files).map(addFileToZip))
                    break;
                }
            }
        }
    }

    const zipBlob = await zip.generateAsync({ type: 'blob' });

    saveAs(zipBlob, `${data[0].folder_name}.zip`);
    return 'success'


    // / const data = [
        //     {
        //         id:1,
        //         parent_id:0,
        //         folder_name:'folder ',
        //         files:[
        //             {
        //                 name:'POFBusiness_Client_V1.pdf',
        //                 link:'https://pofsis-business.s3.amazonaws.com/biz/dev/1709102170721.pdf'
        //             },
        //             {
        //                 name:'POFBusiness_Client_V2.pdf',
        //                 link:'https://pofsis-business.s3.amazonaws.com/biz/dev/1709102170721.pdf'
        //             },
        //         ]
        //     },
        //     {
        //         id:2,
        //         parent_id:1,
        //         folder_name:'subfolder 1',
        //         files:[
        //             {
        //                 name:'file1',
        //                 link:'link1'
        //             }
        //         ]
        //     }, 
        //     {
        //         id:3,
        //         parent_id:1,
        //         folder_name:'subfolder 2',
        //         files:[
        //             {
        //                 name:'PPOFSIS_Overall_Master_PLan.xlsx',
        //                 link:'https://pofsis-business.s3.amazonaws.com/biz/dev/1698033230652.xlsx'
        //             }
        //         ]
        //     },
        //     {
        //         id:4,
        //         parent_id:2,
        //         folder_name:'subfolder 1.1',
        //         files:[
        //             {
        //                 name:'file1.1',
        //                 link:'link1.1'
        //             }
        //         ]
        //     },
        // ]
    
        // const zip = new JSZip();

        // for (let i = 0; i < data.length; i++) {
        //     var mainFolder = data[0].folder_name
        //     if (data[i].parent_id === 0) {
        //         mainFolder = zip.folder(data[i].folder_name);
                
        //         const addFileToZip = async (file) => {
        //             try {
        //                 const content = await JSZipUtils.getBinaryContent(file.link);
        //                 mainFolder.file(file.name, content, { binary: true });
        //             } catch (error) {
        //                 console.error(`Error downloading file ${file.name} from ${file.link}:`, error);
        //             }
        //         };
        
        //         await Promise.all(data[i].files.map(addFileToZip))
        //     }
        //     else{
        //         for (let j = 0; j < data.length; j++) {
        //             const subFolders = zip.folder(mainFolder);
        //             let subFolder = data[i].folder_name
        //             if (data[i].parent_id === data[j].id) {
        //                 if (data[j].parent_id === 0) {
        //                     subFolder = subFolders.folder(data[i].folder_name);
        //                 }else{
        //                     subFolder = subFolders.folder(data[j].folder_name + '/' + data[i].folder_name);
        //                 }
                       
        //                 const addFileToZip = async (file) => {
        //                     try {
        //                         const content = await JSZipUtils.getBinaryContent(file.link);
        //                         subFolder.file(file.name, content, { binary: true });
        //                     } catch (error) {
        //                         console.error(`Error downloading file ${file.name} from ${file.link}:`, error);
        //                     }
        //                 };
                
        //                 await Promise.all(data[i].files.map(addFileToZip))
        //                 break;
        //             }
        //         }
        //     }
        // }

        // const zipBlob = await zip.generateAsync({ type: 'blob' });
    
        // saveAs(zipBlob, `${data[0].folder_name}.zip`);

     // const subFolder = mainFolder.folder(data[1].fname);
            // data[1].files.forEach(file => {
            //     subFolder.file(file.name, file.link);
            // });
            // const subFolder2 = mainFolder.folder(data[2].fname);
            // data[2].files.forEach(file => {
            //     subFolder2.file(file.name, file.link);
            // });
            // const subFolder3 = subFolder.folder(data[3].fname);
            // data[3].files.forEach(file => {
            //     subFolder3.file(file.name, file.link);
            // });
        
            // const zipBlob = await zip.generateAsync({ type: 'blob' });
        
            // saveAs(zipBlob, `${data[0].fname}.zip`);
}