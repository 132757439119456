import { useState } from "react"

export const HomeStates = () => {
    const [categories, setCategories] = useState({personal: null, business: null})
    const [selected, setSelected] = useState(null)

    //FOR GRAPH REPORTS
    const [showGraph, setShowGraph] = useState(false)
    const [showDocu, setShowDocu] = useState(false)
    const [showSysCons, setShowSysCons] = useState(false)
    const [content, setContent] = useState({
        fa: {count: null}, 
        ip: {count: null},
        accredited: {count: null},
        rejected: {count: null}
    })
    const [ds, setDs] = useState({load: 'loader', label: [], counts: []})
    const [list, setList] = useState(null)
    const [drawer, setDrawer] = useState({graph: {open: false}})
    const [form, setForm] = useState({
        graph: { 
            interval: {value: '', stat: false, msg: '', label:''},
            date: {value: '', stat: false, msg: ''},
            sequence: {value: '4', stat: false, msg: ''},
            order: {value: '', stat: false, msg: ''},
            quarter: {value: '1st', stat: false, msg: ''},
        }
    })
    const [selectTool, setSelectTool] = useState('')

    // HOME DASHBOARD
    const [isLoader, setIsLoader] = useState(true);
    const [expPoints, setExpPoints] = useState(0);
    const [commPoints, setCommPoints] = useState(0);
    const [dailyTask, setDailyTask] = useState([
        {id:1, task_name:'Daily Log in', list:1, xp_points:10, cp_points: 5, active:1, status:'Claim', rdrct:false},
        {id:2, task_name:'Avail Tool', list:0, xp_points:10, cp_points: 5,  active:1, status:'Do tasks', rdrct:true},
        {id:3, task_name:'React and share blogs', list:0, xp_points:10, cp_points: 5,  active:1, status:'Do tasks', rdrct:true},
        {id:4, task_name:'Complete your profile', list:1, xp_points:10, cp_points: 5,  active:1, status:'Claim', rdrct:false},
    ])
    const [contracts, setContracts] = useState({nearly: 0, expired: 0, non_exp: 0, expiring: 0, total: 0})
    const [registrations, setRegistrations] = useState({nearly: 0, expired: 0, non_exp: 0, expiring: 0, total: 0})
    const [dateToday, setDateToday] = useState()

    return {
        categories: {data: categories, set: setCategories},
        selected: {data: selected, set: setSelected},
        
        // HOME DASHBOARD
        isLoader: {data: isLoader, set: setIsLoader},
        expPoints: {data: expPoints, set: setExpPoints},
        commPoints: {data: commPoints, set: setCommPoints},
        dailyTask: {data: dailyTask, set: setDailyTask},

        // FOR GRAPH REPORTS
        content: {data: content, set: setContent},
        list: {data: list, set: setList},
        drawer: {data: drawer, set: setDrawer},
        form: {data: form, set: setForm},
        ds: {data: ds, set: setDs},
        selectTool: {data: selectTool, set: setSelectTool},

        // MOBILE GRAPH REPORT
        showGraph: {data: showGraph, set: setShowGraph},
        showDocu: {data: showDocu, set: setShowDocu},
        showSysCons: {data: showSysCons, set: setShowSysCons},
        contracts: {data: contracts, set: setContracts},
        registrations: {data: registrations, set: setRegistrations},
        dateToday: {data: dateToday, set: setDateToday}
    }
}