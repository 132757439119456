import { Box, IconButton, Modal } from "@mui/material"
import { FormatFilesize } from "../../../../global/DesktopDsg";
import { motion } from "framer-motion";
import CloseIcon from '@mui/icons-material/Close';
import Pdf  from '../../../../../../assets/images/icons/pdf.png'

export const ImgScan = ({form, nav, dfltForm, modal, ocrLoader,}) => {
    const handlerClose = () => {
        nav.push('/my-cloud/registrations/create')
        form.set(dfltForm)
        ocrLoader.set(false)
        modal.set({...modal.data, sampleImg : {val : false} })
    }
    
    return (
        <Modal open={modal.data.sampleImg.val}>
            <Box height="100%" width="100%" display="flex" flexDirection="column" bgcolor="#3333331F">
                <Box p={3} minHeight={20}>
                    {
                        !ocrLoader.data &&
                            <IconButton sx={{p: '0px'}} onClick={() => handlerClose()}>
                                <CloseIcon sx={{fontSize: '20px', color: '#F7F7F7'}} />
                            </IconButton>
                    }
                </Box>
                <Box height="100%" width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" position="relative">
                    <Box minHeight={211} width="100%" px="45px">
                        {
                            form.data.regFile.file.val[0].type.includes('image') ?
                                <Box sx={{ backgroundImage: `url(${form.data.regFile.photo.val})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '100%', width: '100%' }} />
                            :
                                <Box display="flex" alignItems="center" width="100%"  p="9px 13px" bgcolor="#FFFFFF" borderRadius="6px" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)"> 
                                    <img src={Pdf} alt={Pdf} />
                                    <Box display="flex" flexDirection="column" ml="13px"> 
                                        <Box color="#333333" fontWeight={600} fontSize={12} lineHeight="16px" flexWrap="wrap"> {form.data.regFile.file.val[0].name} </Box>
                                        <Box color="#737373" fontWeight={400} fontSize={10} lineHeight="16px"> {FormatFilesize(form.data.regFile.file.val[0].size)} </Box>
                                        
                                    </Box>
                                </Box>
                        }
                    </Box>
                    {
                        ocrLoader.data &&
                            <Box position="absolute"  width="100%" px="35px" component={motion.div} animate={{ y: [-110, 120] }} transition={{ repeat: Infinity, repeatType: "reverse" }}>
                                <Box sx={{background: "linear-gradient(0deg, rgba(16, 112, 56, 0.42) 24.01%, rgba(16, 112, 56, 0) 62.5%)", }} width="100%" height="92px"  />
                                <Box bgcolor="#F2F7FF" borderRadius="100px" width="100%" height="4px"   />
                                <Box sx={{background: "linear-gradient(360deg, rgba(16, 112, 56, 0.42) 17.64%, rgba(16, 112, 56, 0) 80.98%)", transform: 'rotate(-180deg)' }} width="100%" height="92px"  />
                            </Box>
                    }
                </Box>

                <Box minHeight="150px" bgcolor="#FFFFFF" borderRadius="24px 24px 0px 0px" p="24px 0px 16px 0px" display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
                    <Box fontSize={16} fontWeight={600} lineHeight="26px">
                        Registration
                    </Box>
                    <Box fontSize={14} fontWeight={400} lineHeight="26px">
                        Scan your document.
                    </Box>
                    <Box fontSize={14} fontWeight={400} lineHeight="26px" color="#107038">
                        Scanning...
                    </Box>
                    <Box height="4px" width="144px" bgcolor="#E6E6E6" borderRadius="100px" />
                </Box>
            </Box>
        </Modal>
  )
}
