import { Box, IconButton } from "@mui/material"
import { Buttons } from "../../../../../core/global/forms/Button"
import { NewAddRoundIcon } from "../../../../../core/global/GMicons"
import { Fields } from "./Fields"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useContext, useEffect, } from "react"
import { ToolContext } from "../../../../../core/context/ToolContext"
import { rqx } from "../../../../../core/request/API"
import { env } from "../../../../../core/Env"

export const CForm = ({ match, ctgyForm, setCtgyForm, __init, categoryType, ctgyData }) => {
    const { speakout_state, tool_state } = useContext(ToolContext)
    const { message, successOpen, saveLoader } = speakout_state
    const { __SESSION } = tool_state

    const ctgyid = ctgyData.data.length

    const nav = useHistory()
    const handleBack = () => {
        nav.push('/speakout/category/list')
    }

    const handleSave = async() => {
        // Extract existing category names from ctgyData
        const categoryExist = ctgyData.data.filter((cat) => cat && cat.name).map((cat) => cat.name.toLowerCase());

        //Find the category being updated
        const existingCategory = ctgyData.data.find((t) => t.id === ctgyForm[0]?.id);
        const existingCategoryName = existingCategory ? existingCategory.name.toLowerCase() : '';

        // Check for duplicates and update state with error messages
        let hasDuplicate = false;
        const updatedForm = ctgyForm.map((item) => {
            if (item.categoryname) {
                const lowerCaseName = item.categoryname.toLowerCase();
                // Exclude the current category name when updating
                const isDuplicate = match.params === 'update'
                    ? categoryExist.includes(lowerCaseName) && lowerCaseName !== existingCategoryName.toLowerCase()
                    : categoryExist.includes(lowerCaseName);
    
                if (isDuplicate) {
                    hasDuplicate = true;
                    return { ...item, error: 'Category Name already exists.' };
                }
            }
            return { ...item, error: '' }; 
        });

        if (hasDuplicate) {
            setCtgyForm(updatedForm);
            return;
        }

        if (match.params === 'create') {
            saveLoader.set(true)
            let bodyArray = [...ctgyForm]
            for (let i = 0; i < bodyArray.length; i++) {
                let body =  {
                    company_id:__SESSION.data.uaccess[0].company_id,
                    user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                    category_name:bodyArray[i].categoryname,
                    type:bodyArray[i].type
                }
                let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/scategory/create`, body)
                if (res.message === 'success') {
                    successOpen.set(true)
                    message.set('Category successfully saved.')
                    __init(0, '')
                    nav.push('/speakout/category/list')
                    saveLoader.set(false)
                }
            }
        } else {
            let body = {...ctgyForm}
            let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/scategory/update`, {id: body[0].id, category_name: body[0].categoryname, type: body[0].type, active: body[0].active, user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1]})

            if (res.message === 'success') {
                successOpen.set(true)
                message.set('Category successfully updated.')
                __init(0, '')
                nav.push('/speakout/category/list')
                saveLoader.set(true)
            }
        }
    }

    const handleAddRow = () => {
        
        setCtgyForm([...ctgyForm, { id: ctgyid + ctgyForm.length + 1 , category_num: `CTGRY-000000000${ctgyid + ctgyForm.length + 1}`, categoryname:'', type:'', active:1}])
    }

    const __initialize = () => {
        if (match.params !== 'update') {
            setCtgyForm([{
                id: ctgyid + 1,
                category_num: `CTGRY-00000000${ctgyid + 1}`,
                categoryname: '',
                type: '',
                active: 1,
                error: ''
            }])
        }
    }
    
    useEffect(() => {
        __initialize()
        // eslint-disable-next-line
    }, [])

    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column">
            <Box minHeight="100px" bgcolor="#ECECEC" borderRadius="10px" p={3}  display="flex" justifyContent="space-between" alignItems="center">
                <Box fontSize="16px" fontWeight="700">{match.params === 'create' ? 'Add Category' : 'Update Category'}</Box>
                    {
                        match.params === 'create' && ctgyForm.length < 5 ?
                        <IconButton variant="contained" sx={{bgcolor: '#11783C', '&:hover':{ backgroundColor: '#1B984F' }}} onClick={handleAddRow}><NewAddRoundIcon /></IconButton>
                        :
                        match.params === 'create' &&
                        <Box fontSize="12px" color="red">You can add up to 5 categories only!</Box>
                    }
            </Box>
            {
                match.params === 'create' &&
                <Box minHeight="20px" fontSize="12px" color="red" display="flex" justifyContent="flex-end" mt={2}>* All Fields are required.</Box>
            }
            <Box maxHeight="600px" className="overflowY">
                <Fields match={match} ctgyForm={ctgyForm} setCtgyForm={setCtgyForm} ctgyid={ctgyid} categoryType={categoryType}/>
            </Box>
            <Box minHeight="60px" display="flex" justifyContent="flex-end" mt={4}>
                <Buttons variant="contained" margin="0px 24px 0px 0px" borderRadius="10px" color="cancel" width="190px" height="44px" event={()=>handleBack()} text="Back">Back</Buttons>
                <Buttons variant="contained" borderRadius="10px" width="190px" height="44px" event={()=>handleSave()} text={match.params === 'create' ? 'Save' : 'Update'} disabled={ctgyForm.some((item) => !item.categoryname || !item.type)}></Buttons>
            </Box>
        </Box>
    )
} 