import { Box, Button, Checkbox, Grid } from '@mui/material'
import { ViewIcon } from '../../../core/global/GMicons'
import { RemoveIcon } from '../../../core/global/Icons'
import { PrimaryToolTip } from '../../../core/global/ToolTip'
import { Waypoint } from 'react-waypoint'
import { SnakeLoader } from '../../../core/loader/SnakeLoader'

export const TBody = ({plcyData, __SESSION, uai_user, icon, checkedIcon, handleClick, inftScroll, loadMoreData, handleCheckChange, saveLoader, handleClickAgree}) => {
    // console.log(plcyData)
    return (
        <Box height="100%" width="100%" className="overflowY" display="flex" flexDirection="column" gap="12px" pr={3} pl={1.5}>
            {plcyData.data?.map((v,k) => ( 
                <Box key={k} width="100%" display="flex" p={2} gap="2px" border="1px solid #EBEBEB" bgcolor="#ffffff" borderRadius="10px" alignItems="center">
                    <Box minWidth="20px">
                        {(__SESSION.data.uaccess[0].role_id === parseInt(uai_user) && v.cp_status !== 3) && (
                            <Checkbox icon={icon} checkedIcon={checkedIcon} sx={{p: '0px', pr:'4px', mb:'3px'}} checked={v.stat} onChange={(e) => handleCheckChange(e, v)}></Checkbox>
                        )}
                    </Box>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={2} className="classTextEllipsis">
                            {v.policy_num}
                        </Grid>
                        <Grid item xs={2} className="classTextEllipsis">
                            {v.dept_name}
                        </Grid>
                        <Grid item xs={__SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? 2 : 3} className="classTextEllipsis">
                            {v.policy_name}
                        </Grid>
                        <Grid item xs={__SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? 2 : 3} className="classTextEllipsis">
                            {v.description}
                        </Grid>
                        {__SESSION.data.uaccess[0].role_id !== parseInt(uai_user) && ( 
                            <Grid item xs={2} className="classTextEllipsis" >
                                <Box width="100%" display="flex" textAlign="center" justifyContent="center" onClick={v.status === 2 ? null : () => handleClick('update', v.id)}>
                                    {v.status === 1 ?
                                        <PrimaryToolTip title={'Update Status to Issued'} open={false} arrow placement="top">
                                            <Box color={v.status === 1 ? '#00B74C' : '#333333'} fontSize="14px" fontWeight={v.status === 1 ? 500 : 400} lineHeight="16px" bgcolor={v.status === 1 && '#00be811f'} borderRadius="16px">
                                                <Button sx={{p:'0px', px:'8px', m:'0px', fontSize:'12px'}} >{'Uploaded'}</Button>
                                            </Box>
                                        </PrimaryToolTip>
                                    :
                                        <Box color={'#333333'} fontSize="14px" fontWeight={400} lineHeight="16px" borderRadius="16px">
                                            <Button sx={{p:'0px', px:'8px', m:'0px', fontSize:'12px'}} disableRipple>{'Issued'}</Button>
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={2} className="classTextEllipsis">
                                <Box display="flex" justifyContent="center" alignItems="center" gap="8px">
                                    <PrimaryToolTip title="View" arrow placement="top">
                                        <Box fontWeight={500} className="c-pointer" onClick={() => handleClick('view', v.id, v.cp_status)} display="flex" justifyContent="center">
                                            <ViewIcon stroke='#292d32' />
                                        </Box>
                                    </PrimaryToolTip>
                                    {
                                        __SESSION.data.uaccess[0].role_id === parseInt(uai_user) ? 
                                            v.cp_status !== 3  ?
                                                <PrimaryToolTip title={"By clicking this you understood and agree that you have read this policy in its entirety and if there is any policy and provision that you do not understand, you shall seek clarification from your immediate superior."} arrow placement="top">
                                                    <Box color={'#00B74C'} fontSize="16px" fontWeight={500} lineHeight="16px" bgcolor={'#00be811f'} borderRadius="10px">
                                                        <Button sx={{p:'0px', m:'0px'}} onClick={() => handleClickAgree (v.id)}>Agree</Button>
                                                    </Box>
                                                </PrimaryToolTip>
                                            :
                                                    <Box color={'#333333'} fontSize="16px" fontWeight={ 400} lineHeight="16px" borderRadius="10px">
                                                        <Button disableRipple sx={{p:'0px', m:'0px'}}>Agreed</Button>
                                                    </Box>
                                            
                                        :
                                            <PrimaryToolTip title="Remove" arrow placement="top">
                                                <Box fontWeight={500} className="c-pointer" onClick={v.status === 2 ? null : () => handleClick('remove', v.id)}>
                                                    <RemoveIcon stroke={v.status === 1 ?'#292d32':'#A2A3A9'} width="24" height="24"/>
                                                </Box>
                                            </PrimaryToolTip>
                                    }
                                </Box>
                        </Grid>
                        
                    </Grid>
                </Box>
            ))}
            {
                inftScroll.data && (
                    <Box display="flex" justifyContent="center">
                        <Waypoint onEnter={loadMoreData} >
                            <Box component="strong" mt={5} display="flex" justifyContent="center">
                                <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                            </Box>
                        </Waypoint>
                    </Box>
                )
            }
        </Box>
    )
}