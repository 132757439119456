import { Avatar, AvatarGroup, Box, Button, IconButton, Modal, Skeleton, Slide, Typography } from "@mui/material"
import { ArrowLeft } from "../../../../../core/global/Icons"
import { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { Preview } from "./Preview";
import { rqx } from "../../../../../core/request/API";
import { env } from "../../../../../core/Env";
import { CheckBox } from '@mui/icons-material';
import { AgreeList } from "./AgreeList";

let uai_user = process.env.REACT_APP_UAI_REGULAR;

export const FileInfo = ({ pModal, setPModal, __SESSION, handleClick, handleClickAgree }) => {
    const [viewPDF, setViewPDF] = useState(false);
    const closePDFView = () => setViewPDF(false);

    const [isLoading, setIsLoading] = useState(true)

    const plcyInfo = [
        {name: 'Policy Number', val: pModal.data[0].series_num},
        {name: 'Department', val: pModal.data[0].dept_name},
        __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) && {name: 'Status', val: pModal.data[0].status === 1 ? 'Uploaded' : 'Issued'},
        __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) && {name: 'Date of Upload', val: pModal.data[0].dateupload},
        {name: 'Date Issued', val: pModal.data[0].dateissued},
        {name: 'Prepared By', val: pModal.data[0].prepared_name},
        {name: 'Approved By', val: pModal.data[0].approved_name}
    ]

    const handlerCloseFView = async() => {
        setPModal({...pModal, fullview: false, data: [] })
    }

    const __init = async() => {
        setIsLoading(true)
        const getAllUsers = await rqx.get(`<<global>>/${env()}/tooluser/gettooluser`, {company_id: __SESSION.data.uaccess[0].company_id !== null ? __SESSION.data.uaccess[0].company_id : 0, tool_id: process.env.REACT_APP_TID, ref: 'active' })
        let samap = getAllUsers.superadmin?.map(v => {return {...v, uname:v.first_name+' '+v.last_name }} )
        let umap = getAllUsers.users?.map(v => {return {...v, uname:v.first_name+' '+v.last_name}} )
        let fdata = [...samap, ...umap]

        let mergeArray = pModal.data.map(pd => {
            let fltrApprovedBy = fdata.find(u => { return u.id === pd.approvedby } )
            let fltrPreparedBy = fdata.find(u => { return u.id === pd.preparedby } )
            return {...pd, approved_name:fltrApprovedBy.uname, prepared_name: fltrPreparedBy.uname }
        })
        let agreeCount = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/policy/agreecount`, {cpid: pModal.data[0].id})
        let agreeList = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/policy/agreelist`, {cpid: pModal.data[0].id})
       
        let agreeMerge = agreeList?.map(pa => {
            let fltrList = umap.filter(u => { return u.id === pa.user_id })
            return {...pa, profile:fltrList.length > 0 && JSON.parse(fltrList[0].profile).profile, uname:fltrList.length > 0 ? fltrList[0].uname : 'Inactive User'}
        })
        let sortArray = agreeMerge.sort((a,b) => a.uname.localeCompare(b.uname))
        setPModal((prev) => ({...prev, data:mergeArray, count:agreeCount, agreeinfo:sortArray}))
        setIsLoading(false) 
    }

    useEffect(() => {
        __init()
        //eslint-disable-next-line
    }, [])

    return (
        <Modal open={pModal.fullview} sx={{zIndex: 1301}} className="noutlined">
            <Slide in={pModal.fullview} direction="left" className="noutlined">
                <Box height="100%" width="100%" bgcolor="#F8F9FA" display="flex" flexDirection="column">
                    <Box minHeight="44px" p="12px 16px" display="flex" alignItems="center">
                        <Box sx={{width: '35%'}}>
                            <IconButton sx={{p: '0px'}} onClick={handlerCloseFView}>
                                <ArrowLeft stroke="#313131" width={16} height={16} />
                            </IconButton>
                        </Box>
                        <Box display="flex" justifyContent="center" fontSize="16px" fontWeight={600} lineHeight="26px">Policy Details</Box>
                        <Typography display="flex" justifyContent="center" fontSize="16px" fontWeight={600} lineHeight="26px"></Typography>
                    </Box>
                    <Box height="216px" p="12px 16px 24px 16px" className="overflowY" onClick={() => handleClick('fileview', pModal)}>
                        <Box minHeight="176px" bgcolor="#FFFFFF" display="flex" justifyContent="center" >
                            <Worker workerUrl={'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'}>
                                <Box component={Viewer} fileUrl={pModal.data[0].file_link}></Box>
                            </Worker>
                        </Box>
                    </Box>
                    <Box height="100%" width="100%" bgcolor="#FFFFFF" borderRadius="12px 12px 0 0" p={2} className="overflowY" >
                        <Box maxWidth="90%" fontSize="16px" fontWeight={600} lineHeight="25px" p="20px 0 16px 0" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{pModal.data[0].policyname}</Box>
                        <Box fontSize="12px" fontWeight={400} lineHeight="16px" mb="32px" color="#333333" >{pModal.data[0].description}</Box>
                        <Box fontSize="14px" fontWeight={600} lineHeight="16px" m="16px 0px">Policy Information</Box>
                        <Box display="flex" flexDirection="column" gap="8px" >
                        {
                            plcyInfo.map((v, k) => (
                                v &&
                                    <Box key={k} fontSize="14px" fontWeight={400} lineHeight="19px" display="flex" alignItems="center" >
                                        <Box width="50%" color="#737373" >{v.name}</Box>
                                        {
                                            isLoading ?
                                            <Skeleton variant="rectangle" width="50%"/>
                                            :
                                            <Box width="50%" color="#333333" >{v.val}</Box>
                                        }
                                    </Box>
                            ))
                        }
                        </Box>
                        {
                            __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) && 
                            <Box>
                                <Box fontSize="14px" fontWeight={600} lineHeight="16px" m="16px 0px">Agree Information</Box>
                                <Box>
                                    <Box fontSize="14px" fontWeight={400} lineHeight="19px" display="flex" alignItems="center" flexDirection="row" mb={1}>
                                        <Box width="50%" color="#737373" >Number of Agree</Box>
                                        {
                                            isLoading ?
                                            <Skeleton variant="rectangle" width="50%"/>
                                            :
                                            <Box width="50%" color="#333333" >{pModal.count}</Box>
                                        }
                                    </Box>
                                    <Box fontSize="14px" fontWeight={400} lineHeight="19px" display="flex" alignItems="center" flexDirection="row" mb={1}>
                                        <Box width="50%" color="#737373" >Employees who Agreed</Box>
                                        <Box width="50%" color="#333333" display="flex" justifyContent="flex-start" onClick={() => handleClick('agreelist', pModal)}>
                                            {
                                                isLoading ?
                                                <Skeleton variant="square" width={32} height={32} sx={{borderRadius: '100px'}}/>
                                                :
                                                <AvatarGroup max={4} sx={{gap: '12px', '& .MuiAvatar-root': {width: 32, height: 32, fontSize: 14} }} className='c-pointer'>
                                                    {
                                                        pModal.agreeinfo?.map((v,k) => (
                                                            <Avatar src={v.profile} alt="profile" sx={{width: 32, height: 32}} key={k}/>
                                                        ))
                                                    }
                                                </AvatarGroup>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        }
                        {
                            __SESSION.data.uaccess[0].role_id === parseInt(uai_user) && pModal.cp_status === null && (
                            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" pb={1} pt={2}>
                                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" p={1} gap="8px" fontSize="12px" fontWeight={400} lineHeight="20px">
                                    <CheckBox sx={{height: '20px', width: '20px'}}></CheckBox>
                                    <Box  >I hereby acknowledge receipt and agree that I have read this policy in its entirety and if there is any policy and provision that I do not understand, I shall seek clarification from my immediate superior.</Box>
                                </Box>
                                <Box display="flex" justifyContent="center" color="#00B74C" fontSize="20px" fontWeight={600} lineHeight="16px" bgcolor="#00be811f" borderRadius="10px" height="40px" width="250px">
                                    <Button onClick={() => handleClickAgree (pModal.id)}>Understood and Agree</Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                    <Preview viewPDF={viewPDF} closePDFView={closePDFView} pModal={pModal} setPModal={setPModal} />
                    <AgreeList pModal={pModal} setPModal={setPModal}/>
                </Box>
            </Slide>
        </Modal>
    )
}