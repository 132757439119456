import { useContext, useEffect } from "react";
import { Box } from "@mui/material"
import { ConfirmRemove, NoFound } from "../../../global/DesktopDsg"
import { Folder } from './Folder';
import { Files } from './Files';
import { ToolContext } from "../../../../../core/context/ToolContext";
import { env } from "../../../../../core/Env";
import { rqx } from "../../../../../core/request/API";
import Upload from '../../../../../assets/images/icons/upload.png'
import { FileInfo } from "../modal/FileInfo";
import { NotifCount } from "../../../../../core/request/Notif";

export const List = ({match, __InitDocuFolder, getRootProps, isDragActive, __InitDocuFile}) => { 
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { successOpen, message, show, active, rename, fileInfi, fileLoader, docuFile, folderInfi, fileLid, folderLid, docuFolder, folderLoader, showDl, errOpen } = cloud_state
    const { __SESSION } = tool_state
    let user_id = atob(__SESSION.data.ainfo.aid).split('_', 2)[1];
    
    const handlerShowIcon = (index, action, type) => {
        if (action === 'enter') {
            active.set({...active.data, val : index, type: type})
        }else{
            if (active.data.openMenu === null) {
                active.set({...active.data, val : ''})
            }
        }
    }

    const __LoadMoreFolder = () => {
        if (folderLid.data !== undefined) {
            __InitDocuFolder(folderLid.data)
        }
    }
    
    const __LoadMoreData = () => {
        if (fileLid.data !== undefined) {
            if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') {
                __InitDocuFile(fileLid.data)
            }
        }

    }
    
    const hanlderModalBtn = async(option) => {
       
        if (!show.data.remove.loader) {
            if (option === 'cancel') {
                show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}})
            }else{
                show.set({...show.data, remove:{...show.data.remove, loader:true}})
                let body = {
                    documents_id:show.data.data.id,
                    folder_id: show.data.data.folder_id, 
                    user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                    status:4, 
                    ref:'trash',
                    filter: show.data.data.feature_type === 3 ? 'folder' : 'file'
                }

                let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/documents/remove`, body)
                if (res.message === 'success') {
                    await NotifCount([{file_id:show.data.data.feature_type === 3 ? show.data.data.folder_id : show.data.data.id, file:show.data.data.feature_type === 3 ? 'folder' : 'file'}], __SESSION, 'delete')
                    message.set('Successfully removed')
                    successOpen.set(true)
                    show.set({...show.data, remove:{...show.data.remove, val:false, loader:false},  view : false, fullview : false})
                    active.set({...active.data, val : '', openMenu : null})
                    show.data.data.feature_type === 3 ?
                        __InitDocuFolder(0)
                    :
                        __InitDocuFile(0)
                }
            }
        }
    }

    const handlerAddFav = async(e,v) => {
        e.stopPropagation()
        let body = {
            status: v.user_favorite_status === 2 ? 1 : 2, 
            registration_id: "",
            contracts_id: "",
            user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
            documents_id: v.feature_type === 4 ? v.id : "",
            folder_id:  v.feature_type === 3 ? v.id : ""
        }
        let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/favorites/create`, body)
        if (res.message === 'success') {
            await message.set(v.user_favorite_status === 2 ? 'Sucessfully removed from favorites' : 'Sucessfully added to favorites')
            successOpen.set(true)
            if (v.feature_type === 4) {
                docuFile.set(
                    docuFile.data.map((d,k) => d.id === v.id ? {...d, user_favorite_status : v.user_favorite_status === 2 ? 1 : 2} : {...d} )
                )
            }else{
                docuFolder.set(
                    docuFolder.data.map((d,k) => d.id === v.id ? {...d, user_favorite_status : v.user_favorite_status === 2 ? 1 : 2} : {...d} )
                )
            }
            show.set({...show.data, data : {...show.data.data, user_favorite_status : v.user_favorite_status === 2 ? 1 : 2}  })
            // show.data.data.user_favorite_status
        }
    }
   
    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            rename.set(false)
        }
        return () => subscribe = false
        // eslint-disable-next-line 
    }, [])
    
    return (
        <>
            <Box height="100%" width="100%" display="flex" flexDirection="column"  position="relative" 
                {...getRootProps()}
                bgcolor={isDragActive ? "rgba(16, 112, 56, 0.12)" : "transparent"} 
                border={isDragActive ? "2px dashed #107038" : "0px"} borderRadius="32px" 
            >
            {
                (docuFile.data.length === 0 && docuFolder.data.length === 0 && !fileLoader.data && !folderLoader.data) ?
                    <NoFound msg="No File found. Upload Now!" />
                :
                    <Box height="100%" display="flex" flexDirection="column" gap={3} className="overflowY cstmscroll">
                        <Box minHeight="300px" p={3} ml={2} mr={4} display="flex" flexDirection="column">
                            <Box fontSize={14} fontWeight={600} lineHeight="22px" mb="20px" >Folders</Box>
                            <Box height="100%" className="overflowY">
                                <Folder match={match} handlerShowIcon={handlerShowIcon} docuFolder={docuFolder} __LoadMoreFolder={__LoadMoreFolder} folderInfi={folderInfi} show={show} active={active} rename={rename} user_id={user_id} message={message} successOpen={successOpen} folderLoader={folderLoader} handlerAddFav={handlerAddFav} errOpen={errOpen} __SESSION={__SESSION}
                                />
                            </Box>
                        </Box>
                        <Box 
                        sx={{ minHeight:{xs:'400px !important', lg:'0px'}, height:{lg:'100% !important'} }}
                        p={3} ml={2} mr={4} display="flex" flexDirection="column" className="overflowY">
                            <Box fontSize={14} fontWeight={600} lineHeight="22px" mb="20px" >Files</Box>
                            <Box height="100%" className="overflowY">
                                <Files match={match} handlerShowIcon={handlerShowIcon} docuFile={docuFile} __LoadMoreData={__LoadMoreData} fileInfi={fileInfi} show={show} active={active} rename={rename} user_id={user_id} message={message} successOpen={successOpen} fileLoader={fileLoader} handlerAddFav={handlerAddFav} errOpen={errOpen} __SESSION={__SESSION} /> 
                            </Box>
                        </Box>
                        <Box minHeight="30px"></Box>
                    </ Box>
            }
            {
                isDragActive &&
                    <Box height="100%" width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="flex-end" pb={5} position="absolute">
                        <Box width="304px" p="24px" display="flex" alignItems="center" justifyContent="center" flexDirection="column" bgcolor="#107038" borderRadius="12px" gap="12px">
                            <img src={Upload} alt="upload-img" />
                            <Box fontSize={14} lineHeight="16px" color="#ffffff">Drop your files to upload.</Box>
                        </Box>
                    </Box>
            }
            </Box>
            {
                show.data.view &&
                    <FileInfo show={show} active={active} showDl={showDl} handlerAddFav={handlerAddFav} />
            }
            <ConfirmRemove show={show} HandlerDelete={hanlderModalBtn}  />
        </>
    )
}
