import { Box, Divider, Grid, Skeleton, } from '@mui/material';
import { VM } from '../../../core/VM';

let view = VM()

export const BannerLoader = ({listBanner}) => {
    
    return (
        <>
            <Box width="100%" px={3}>
                <Box pt={5}>
                    <Skeleton variant="text" height={24} width="106px" />
                </Box>
                <Box mt={3}>
                    <Skeleton variant="text" height={24} width="250px"/>
                </Box>
                <Box mt={3} pb={1}>
                    <Skeleton variant="text" height={24} width="330px"/>
                </Box>
                {listBanner.data.length !== 0 &&
                    <>
                        <Box  width="100%" display="flex">
                            {listBanner.data.map((v,k) =>(
                                <Box key={k} py={1} px={2} width="100%">
                                    <Box display="flex" flexDirection="column" justifyContent="space-around" bgcolor="#FFFFFF" borderRadius={3} width="100%" height="100%" px={2}>
                                        <Box height="20%" my={3}>
                                            <Skeleton variant="circular" height="40px" width="40px"/>
                                        </Box>
                                        <Box height="60%" display="flex" flexDirection="column" justifyContent="space-around">
                                            <Box my={1}>
                                                <Skeleton variant="text" height="30px" width="40%"/>
                                            </Box>
                                            <Box mb={2}>
                                                <Skeleton variant="text" width="70%"/>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                        <Box mt={1} mb={1} display='flex' justifyContent='center'>
                            {listBanner.data.map((v,k) =>(
                                <Box mx={.5} key={k}>
                                    <Skeleton  variant="circular"  width="14px" height="14px"/> 
                                </Box>
                            ))}
                        </Box>
                    </>
                }
            </Box>
        </>
    )
}

export const ToolSummaryLoader = ({__SESSION}) => {
    let superAdmin  = ['', '', '']
    let adminReg = ['']
    return (
        <Grid container >
            <Grid item sm={__SESSION[0].role_id !== 0 ? 4 : 12} lg={ __SESSION[0].role_id !== 0 ? 4 : 12} bgcolor="#FFFFFF" p={2} borderRadius="6px">
                <Grid container spacing="12px">
                    {(__SESSION[0].role_id === 0 ? superAdmin : adminReg).map((v, i) => (
                        <Grid item key={i} sm={__SESSION[0].role_id === 0 ? 4 : 12} lg={__SESSION[0].role_id === 0 ? 4 : 12}>
                            <Skeleton variant="rectangular" height={121} sx={{borderRadius: '6px'}} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )
}

export const ToolFeaturesLoader = ({__SESSION}) => {
    return (
        <Box height="auto">
            {view === '' && (
                <ToolSummaryLoader __SESSION={__SESSION}/>
            )}
            {__SESSION[0].role_id !== parseInt(process.env.REACT_APP_UAI_REGULAR) &&
                <Box bgcolor="#FFFFFF" mt={{xs:0, sm:4}} borderRadius="6px" p={{xs:'0px', sm:'16px'}}>
                    <Skeleton variant="text" height={30} width={110} sx={{borderRadius: '6px', mb:'12px'}} />
                    <Box display="flex" gap="12px" justifyContent="space-between">
                        {(view === '' ? ['20%','15%','20%','15%','20%','10%'] : ['20%','15%','20%','10%']).map((v, k) => (
                            <Skeleton key={k} variant="text" width={v} height={30} sx={{borderRadius: '6px'}} />
                        ))} 
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between" p="16px 12px" border="1px solid #ECECEC" mt={{xs:'6px', sm:"26px"}} mb="12px" borderRadius={1} height={42} gap="12px">
                        {(view === '' ? ['20%','15%','20%','15%','20%','10%'] : ['20%','15%','20%','10%']).map((v, k) => (
                            <Skeleton key={k} variant="text" width={v} height={30} sx={{borderRadius: '6px'}} />
                        ))}
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between" p="16px 12px" border="1px solid #ECECEC" mt={{xs:'6px', sm:"26px"}} mb="12px" borderRadius={1} height={42} gap="12px">
                        {(view === '' ? ['20%','15%','20%','15%','20%','10%'] : ['20%','15%','20%','10%']).map((v, k) => (
                            <Skeleton key={k} variant="text" width={v} height={30} sx={{borderRadius: '6px'}} />
                        ))}
                    </Box>
                </Box>
            }
        </Box>
    )
}

export const ToolLoader = () => {
    return(
        <Box height="100%">
            <Box minHeight={60}> 
                <Skeleton variant="text" height={30} width={110} sx={{borderRadius: '6px', mb:'12px'}} />
                <Skeleton variant="rectangular" height={40} width={196} sx={{borderRadius: '6px'}} />
            </Box>
            <Box minHeight={10} mt={3} mb={2}> 
                <Box pr={2} display="flex" gap="4px">
                    {(view === '' ? ['','','','','','',''] : ['','','']).map((v, k) => (
                        <Skeleton key={k} variant="rectangular" width="100%" height={15} sx={{borderRadius: '6px'}} />
                    ))}
                </Box>
            </Box>
            <Box mb={2}> 
                <Box pr={2} gap="12px" display="flex" flexDirection="column">
                    {(view === '' ? ['',''] : ['']).map((v, k) => (
                        <Box key={k} p="16px 12px" border="1px solid #ECECEC" borderRadius={1}>
                            <Box display="flex" gap="12px">
                                {(view === '' ? ['','','','','','',''] : ['','','']).map((v, k) => (
                                    <Skeleton key={k} variant="rectangular" width="100%" height={30} sx={{borderRadius: '6px'}} />
                                ))}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export const MListLoader = ({__SESSION}) => {
    let superAdmin  = ['', '', '']
    let adminReg = ['']
    return(
       <Box>
            <Skeleton variant="text" height={20} width={150} sx={{borderRadius: '6px', mb:'12px'}} />
            <Grid container spacing={1}>
                {(__SESSION[0].role_id === 0 ? superAdmin : adminReg).map((v, index) => (
                    <Grid item xs={ __SESSION[0].role_id !== 0 ? 6 : 4} key={index}>
                        <Box display="flex" flexDirection="column" justifyContent="space-between" bgcolor="#F0F6F3" p="12px" borderRadius="6px">
                            <Skeleton variant='text' width="60px" />
                            <Box display="flex" justifyContent="space-between" alignItems='center'>
                                <Skeleton variant='text' width="20px" />
                                <Skeleton variant='text' width="20px" />
                            </Box>
                        </Box>
                    </Grid>  
                ))}
            </Grid>
       </Box>
    )
}

export const SystemConcernsLoader = () => {
    return (
        <>
            <Box minHeight="130px" px={{xs:0, sm:2}}>
                <Skeleton variant="text" height={30} width={130} sx={{borderRadius: '6px', mb:'12px'}} />
                <Skeleton variant="rectangular" height={40} width={196} sx={{borderRadius: '6px'}} />
                <Box mt={3}>
                    {view === '' ? 
                        <Grid container mb={2} px={2} spacing={3}>
                            <Grid item sm={3} lg={3}>
                                <Skeleton variant="rectangular" height={20} width="100%" sx={{borderRadius: '6px', mb:'12px'}} />
                            </Grid>
                            <Grid item sm={3} lg={3} display="flex" alignItems="center" justifyContent="center">
                                <Skeleton variant="rectangular" height={20} width="100%" sx={{borderRadius: '6px', mb:'12px'}} />
                            </Grid>
                            <Grid item sm={6} lg={6} display="flex" justifyContent="space-around" gap="12px">
                                {['','',''].map((v, k) => (
                                    <Box key={k} display="flex" alignItems="center" width="100%" >
                                        <Skeleton variant="rectangular" height={15} width={15} sx={{borderRadius: '6px', mb:'12px', mr:'2px'}} />
                                        <Skeleton variant="rectangular" height={15} width="100%" sx={{borderRadius: '6px', mb:'12px'}} />
                                    </Box>
                                ))}
                            </Grid>
                        </Grid>
                    :
                        <Box display="flex" alignItems="center" width="100%" gap="12px" >
                            <Box minWidth="130px">
                                <Skeleton variant="rectangular" height={15} width="100px" sx={{borderRadius: '6px', mb:'12px'}} />
                            </Box>
                            <Box width="100%">
                                <Skeleton variant="rectangular" height={15} width="100px" sx={{borderRadius: '6px', mb:'12px'}} />
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
            <Box height="100%" pt={2} px={{xs:0, sm:2}}>
                {view === '' ? 
                    ['',''].map((v,k) => (
                        <Grid key={k} container border="1px solid #ECECEC" pl={2} mb="12px">
                            <Grid item sm={3} lg={3} height={73} display="flex" alignItems="center">
                                <Box display="flex" alignItems="center" gap="12px">
                                    <Skeleton variant="rectangular" height={40} width={40} sx={{borderRadius: '6px'}} />
                                    <Skeleton variant="rectangular" height={15} width={110} sx={{borderRadius: '6px'}} />
                                </Box>
                            </Grid>
                            <Grid item sm={3} lg={3} display="flex" alignItems="center" justifyContent="center">
                                <Skeleton variant="rectangular" height={20} width={40} sx={{borderRadius: '6px'}} />
                            </Grid>
                            <Grid item sm={6} lg={6} borderLeft="1px solid #E8F5EE" display="flex" justifyContent="space-evenly" flexDirection="column">
                                <Skeleton variant="rectangular" height={15} width={5} sx={{borderRadius: '6px'}} />
                                <Skeleton variant="rectangular" height={15} width={5} sx={{borderRadius: '6px'}} />
                                <Skeleton variant="rectangular" height={15} width={5} sx={{borderRadius: '6px'}} />
                            </Grid>
                        </Grid>
                    ))
                :
                    <>
                        {['',''].map((v,k) => (
                            <Box key={k} border="1px solid #ECECEC" pl={2} mb="12px" display="flex" minHeight="73px" width="100%">
                                <Box display="flex" minWidth="130px" >
                                    <Box display="flex" alignItems="center" gap="12px">
                                        <Skeleton variant="rectangular" height={20} width={20} sx={{borderRadius: '6px'}} />
                                        <Skeleton variant="rectangular" height={10 } width={50} sx={{borderRadius: '6px'}} />
                                    </Box>
                                    <Divider orientation="vertical" variant="fullWidth" sx={{height:'73px', width:'1px', backgroundColor:'#ECECEC', opacity:0.5, mx:3}}/>
                                </Box>
                                <Box display="flex" alignItems="center" gap="12px" width="100%">
                                    <Box width="50%">
                                        <Skeleton variant="rectangular" height={10 } width={50} sx={{borderRadius: '6px'}} />
                                    </Box>
                                    <Box width="50%" display="flex" flexDirection="column" gap="6px">
                                        <Skeleton variant="rectangular" height={15} width={5} sx={{borderRadius: '6px'}} />
                                        <Skeleton variant="rectangular" height={15} width={5} sx={{borderRadius: '6px'}} />
                                        <Skeleton variant="rectangular" height={15} width={5} sx={{borderRadius: '6px'}} />
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                        <Box width="100%" display="flex" justifyContent="center" alignItems="center" gap="12px">
                            {['','',''].map((v, k) => (
                                <Box key={k} display="flex" alignItems="center" gap="6px">
                                    <Skeleton variant="rectangular" height={10} width={10} sx={{borderRadius: '6px'}} />
                                    <Skeleton variant="rectangular" height={5} width={40} sx={{borderRadius: '6px'}} />
                                </Box>
                            ))}
                        </Box>
                    </>
                }
            </Box>
        </>
    )
}