import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Backdrop, Modal, Fade } from '@mui/material';
import { Buttons } from '../../core/global/forms/Button';
import { ToolContext } from '../../core/context/ToolContext';
import { SuccessIcon } from '../../core/global/Icons';
import { rqx } from '../../core/request/API';
import { env } from '../../core/Env';
import Cafgu2 from '../../assets/images/core/cafgu2.svg'
import MCafgu2 from '../../assets/images/core/cafgu_bg.png'
import { VM } from '../../core/VM';

let view = VM()

export const WelcomePersonalization = ({personalizationData}) => {
    const { personalization_state } = useContext(ToolContext);
    const ps = personalization_state;
    const history = useHistory();

    const handleClose = async() => {
        ps.isShowWp.set(false);
        ps.isTourOpen.set(false);
        await prsnUpdate();
        history.push('/');
    };
    
    const escFunction = async(event) => {
        if(event.keyCode === 27) {
            ps.isShowWp.set(false);
            ps.isTourOpen.set(false);
            await prsnUpdate();
        }
    }

    const prsnUpdate = async() => {
        let filter_data = personalizationData.filter(v => { return v.feature_name === 'dashboard'});
        let filter = ps.personalizationData.data.filter(v => { return v.feature_name !== 'dashboard'} );
        let p_data = filter_data[0].personalization.map((v) =>  v.sf_name === 'welcomeTour' ? {...v, status:1} : {...v});
        let f_data = filter_data.map(v => { return {...v, personalization:p_data} } );
        ps.personalizationData.set([...filter, ...f_data]);
        let res = await rqx.post(`<<global>>/${env()}/personalization/create`, f_data[0])
        return res;
    }

    const handleProceed = () => {
        ps.isShowWp.set(false);
        ps.isTourOpen.set(true)
    }

    const [isShow, setIsShow] = useState(false);
    const [subscribe, setSubscribe] = useState(true)
    useEffect(() => {
        if (subscribe) {
            if(ps.isDonep.data) {
                setIsShow(true)
                setTimeout(() => {
                    setIsShow(false)
                }, 2000);
            }
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [ps.isDonep.data])

    return (  
        <Modal
            open={ps.isShowWp.data}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            onKeyDown={(e)=>escFunction(e)}
        >
            <Fade in={ps.isShowWp.data}>
                {view !== '' ? 
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Box position="absolute" width={311} height={440}>
                            <Box borderRadius={5} borderColor="#ffffff" bgcolor="white" className="shadow">
                                <img src={MCafgu2} width="100%" height="100%" alt="cafgu" />
                                <Box pt="38px" textAlign="center" fontSize="14px" fontWeight={600} color="#333333" px="24px">
                                    Let me show you some great features of POFBusiness!
                                </Box>
                                <Box display="flex" justifyContent="center" pt="55px" pb="24px" px="22px">
                                    <Box pr="3px" width="100%">
                                        <Buttons event={handleClose} borderRadius="8px" variant="contained" color="#E4E4E4" width="100%" height="36px" text={<Box fontSize={14} fontWeight={600} color="#333333">Skip</Box>} />
                                    </Box>
                                    <Box pl="4px" width="100%">
                                        <Buttons event={()=>handleProceed()} borderRadius="8px" variant="contained" color="#006129" width="100%" height="36px" text={<Box fontSize={14} fontWeight={600} color="#FFFFFF">Proceed</Box>} />
                                    </Box>
                                </Box>  
                                <Box display="flex" alignItems="center" justifyContent="center" mb={{xs: 2, sm:3}} pb="16px">
                                    <Box width={{xs: 17, md: 24}} height={{xs: 3, md: 4}} bgcolor="#009E6A" borderRadius={4} mr={1} />
                                    <Box width={{xs: 17, md: 24}} height={{xs: 3, md: 4}} bgcolor="#009E6A" borderRadius={4} />
                                </Box>
                            </Box>  
                        </Box>
                    </Box>
                :
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Box position="absolute" height="100%" width="452px">
                            <Box mx={3} mt={10} mb={8} borderRadius={3} bgcolor={isShow ? "#00BE81":''} fontSize={16} display="flex" justifyContent="center" alignItems="center" minHeight="44px" color="#ffffff">
                                {isShow && (
                                    <Box display="flex" justifyContent="center" alignItems="center"> 
                                        <SuccessIcon fill="#FFF"/><Box px={1}>User account details successfully saved </Box>
                                    </Box>   
                                )}
                            </Box>
                            <Box width="100%" display="flex" justifyContent="center">
                                <Box width="352px" fontSize={20} textAlign="center" borderRadius={5} borderColor="#ffffff" py={3} bgcolor="white" className="shadow"  minHeight={343}>
                                    <Box color="#121212">Let me show you some</Box>
                                    <Box display="flex" justifyContent="center">
                                        <Box color="primary.main" fontSize={20} component="strong">great features</Box>
                                        <Box pl={1} color="#121212">of POF<b>Business!</b></Box>
                                    </Box>
                                    <Box mt={3} textAlign="center">
                                        <img src={Cafgu2} width="230px" height="222px" alt="cafgu" />
                                    </Box>
                                </Box>  
                            </Box>
                            <Box width="100%" display="flex" justifyContent="center">
                                <Box width="352px" display="flex" justifyContent="center" mt="30px" >
                                    <Buttons event={handleClose} margin="0px 12px 0px 0px " borderRadius="12px" variant="contained" color="cancel" width="100%" height="48px" text={<Box fontSize={16} fontWeight={500}>Skip</Box>} />
                                    <Buttons event={()=>handleProceed()}  margin="0px 12px 0px 0px " borderRadius="12px" variant="contained" color="primary" width="100%" height="48px" text={<Box fontSize={16} fontWeight={500}>Proceed</Box>} />
                                </Box>  
                            </Box>
                        </Box>
                    </Box>
                }
            </Fade>
        </Modal>
    );
}