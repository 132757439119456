import { lazy, useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { VM } from '../VM';
import { Index as Personalization } from '../../components/personalization/Index';
import { ToolContext } from '../context/ToolContext';
import { NotFoundPage } from '../../components/empty/Index';
import { Index as ToolCategories } from '../../components/toolcategories/Index';
import { Index as Myprofile } from '../../components/profile/devices/myprofile/Index';
import { MyCloud } from '../../components/mycloud/Index';
import { Policies } from '../../components/policies/Index';

import { Index as UBRComponents } from '../../components/ubr/components/Index'
import { Index as UBR } from '../../components/ubr/Index';
import { Index as VR } from '../../components/vr/Index';
import { Notification } from '../../components/notifications/desktop/Index'
import { MblNotif } from '../../components/notifications/devices/Index'

import { Index as Dashboard } from '../../components/home/Index'
import { RenderAPI } from '../../components/global/RenderAPI';
import { Switching } from '../../components/swtiching/Index'
import { Continue } from '../../components/ubr/Continue';
import { PlcyPermission } from '../../components/personalization/PlcyPermission';
import { Speakout } from '../../components/speakout/Index';
 

import { Index as SalesInvoice } from '../../components/salesinvoice/Index.js';

let view = VM();
const uai_user = process.env.REACT_APP_UAI_REGULAR;
const Navigation = lazy(() => import(`../../components/layouts/navigations${view}/Index`))
const Profile = lazy(() => import(`../../components/profile${view}/Index`))
const GM = lazy(() => import(`../../components/generalmaintenance${view}/Index`))
const CompanySetup = lazy(() => import(`../../components/generalmaintenance/personalization${view}/CompanySetup`))
const SubToolCS = lazy(() => import(`../../components/generalmaintenance/personalization${view}/SubToolCS`))
const Profiling = lazy(() => import(`../../components/profiling${view}/Index`))
const ReferUser = lazy(() => import(`../../components/referUser${view}/Index`))

export const WebRoute = () => {
    const location = useLocation().pathname;
    const { tool_state } = useContext(ToolContext);
    const { __SESSION } = tool_state
    
    return (
        <Switch>
            {/* FOR UBR */}
            <Route exact path="/ubr" render={props=>(Render(UBR, props, location))} />
            {/* FOR VALIDATION REFERAL */}
            <Route exact path="/vr" render={props=>(<VR/>)} />
            {/* SALES INVOICE PRINT */}
            <Route exact path="/sales-invoice" render={props=>(<SalesInvoice/>)} />
            {/* DASHBOARD ROUTES */}
            <Route exact path="/" render={props=>(Render(Dashboard, props, location))} />
            <Route exact path="/switching" render={props=><Switching/>} />
            <Route exact path="/profiling" render={props=>(Render(Profiling, props, location))} />
            <Route exact path="/tool-categories/:params" render={props=>(Render(ToolCategories, props, location))} />
            <Route exact path="/notification" render={props=>(Render( view === '' ? Notification : MblNotif, props, location))} />
            <Route exact path="/general-maintenance/company-setup/subtools" render={props=>(Render(SubToolCS, props, location))} />
            {__SESSION.data.pinfo.length > 0 && (
                <Route>
                    <Route exact path="/profile" render={props=>(Render(Profile, props, location))} />
                    <Route exact path="/my-profile" render={props=>(Render(Myprofile, props, location))} />
                    {__SESSION.data.uaccess.length > 0 && (
                        <>
                            <Route exact path="/my-cloud/:feature/:params" render={props=>(Render(MyCloud, props, location))} />
                            <Route exact path="/policies/:params" render={props=>(Render(Policies, props, location))} />
                            <Route exact path="/speakout/:feature/:params" render={props=>(Render(Speakout, props, location))} />
                            {__SESSION.data.uaccess[0].role_id !== parseInt(uai_user) && (
                                <Route>
                                    <Route exact path="/general-maintenance" render={props=>(Render(GM, props, location))} />
                                    <Route exact path="/general-maintenance/company-setup" render={props=>(Render(CompanySetup, props, location))} />
                                </Route>
                            )}
                        </>
                    )}
                </Route>
            )}
            {/* 404 NOT FOUND */}
            <Route render={props=>(<NotFoundPage/>)} />
        </Switch>
    )
}

const Render = (Component, props, location) => (
    <>
        {view === '/devices/mobile' || view === '/devices/tablet' ?
            <Box width="100%" height="100%">
                <Box width="100%" height="100%" display="flex" flexDirection="column">
                    {(location !== '/profiling' && location !== '/my-profile' && location !== '/profile') && (
                        <Box minHeight={location === '/' ? '92px' : '40px'}>
                            <Navigation side={0} />
                        </Box>
                    )}
                    <Box height="100%" width="100%" className="overflowY noScrollcss"><Component {...props} /></Box>
                    {(window.location.pathname !== '/profile' && window.location.pathname !== '/profiling' && window.location.pathname !== '/general-maintenance/company-setup') && window.location.search !== '?feature=trash' &&
                    // {(window.location.pathname !== '/profile' && window.location.pathname !== '/profiling' && window.location.pathname !== '/general-maintenance' && window.location.pathname !== '/general-maintenance/company-setup') && window.location.search !== '?feature=trash' && window.location.pathname !== '/notification' &&
                        <Box minHeight="54px">
                            <Navigation side={2} />
                        </Box>
                    } 
                </Box>
            </Box>
        :
            <Box width="100%" height="100%">
                <Box width="100%" height="100%" display="flex">
                    <Box height="100%">
                        {(location !== '/profiling' && location !== '/general-maintenance/company-setup/subtools') && (<Navigation side={1} />)}
                    </Box>
                    <Box display="flex" flexDirection="column" width="100%" height="100%">
                        <Navigation side={0} />
                        <Box height="100%" className="overflowY noScrollcss">
                            <Component {...props} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
        {/* FOR UBR */}
        <Continue/>
        <UBRComponents {...props}/>
        {/* FOR POLICY PERMISSION REQUIRED BEFORE TRANSACTION */}
        
        {location !== '/policies/list' && (
            <PlcyPermission/>
        )}
        {/* RENDER API GLOBAL */}
        <RenderAPI/>
        {location !== '/general-maintenance/company-setup/subtools' && (
            <Personalization />
        )}
        <ReferUser />
    </>
)