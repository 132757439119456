import { useState } from "react"
import { useHistory } from "react-router-dom"
import { Box, ListItemButton } from "@mui/material"
import { StarIcon, TrashIcon, ShareIcons } from '../../../core/global/Icons'
import { Storage } from "./storage/Storage"

export const McDrawer = ({match, filter, csuccessOpen, successOpen, show}) => {
    const nav = useHistory()
    const [index, setIndex] = useState('')

    const navBtnTop = [
        {name: 'Recently Added', icon: '', iconActive: '', url: 'recent'},
        {name: 'Registrations', icon: '', iconActive: '', url: 'registrations'},
        {name: 'Contracts', icon: '', iconActive: '', url: 'contracts'},
        {name: 'My Documents', icon: '', iconActive: '', url: 'documents'},
        {name: 'Shared Documents', icon: <ShareIcons/>, iconActive: <ShareIcons fill="#107038" />, url: 'shared-access',},
        {name: 'Favorites', icon: <StarIcon stroke="#313131"/>, iconActive: <StarIcon stroke="#107038" fill="#107038" />, url: 'favorites',},
        {name: 'Trash', icon: <TrashIcon fill="#313131"/>, iconActive: <TrashIcon fill="#107038" />, url: 'trash'},
    ]

    const styles = (url, k) => {
        let active = (url === match.feature)
        return{
            borderRadius: '6px',
            padding: '12px !important',
            backgroundColor: (active || index === k ) ? '#ffffff' : '#F8F9FA',
            color: (active || index === k ) ? '#107038' : '#313131',
            fontWeight: (active || index === k ) ? 600 : 400,
            lineHeight: '16px',
            height: 40,
            '&:hover':{
                backgroundColor: '#ffffff'
            }
        }
    }

    const handlerRedirect = (e, url) => {
        e.preventDefault()
        if (match.feature !== url) {
            show.set({...show.data, data : '', view : false, fullview : false})
            csuccessOpen.set(false)
            successOpen.set(false)
            if (url === 'registrations') {
                filter.set(2)
            }
            nav.push(`/my-cloud/${url}/list`)
        }
    }

    return (
        <Box height="100%" minWidth={272} maxWidth={272} bgcolor="#F8F9FA" className="overflowY cstmscroll">
            <Box display="flex" justifyContent="space-between" flexDirection="column" p="24px 16px" width="100%" height="100%">
                <Box height="100%">
                    {
                        navBtnTop.map((v,k) => (
                            <Box key={k}>
                                {
                                    (k === 3 || k === 5) &&
                                        <Box width="100%" height="1px" my={1} bgcolor="#E6E6E6" />
                                }
                                <ListItemButton sx={styles(v.url, k)} 
                                    onClick={(e) => handlerRedirect(e, v.url)} 
                                    onMouseEnter={() =>  setIndex(k)}
                                    onMouseLeave={() => setIndex('')}
                                >
                                    { (v.url === match.feature || index === k) ? v.iconActive : v.icon }
                                    <Box ml={1}>{v.name}</Box>
                                </ListItemButton>
                            </Box>
                        ))
                    }
                </Box>
                <Box mt={5}>
                    <Storage/>
                </Box>
            </Box>
        </Box>
    )
}
