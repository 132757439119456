import { makeStyles } from "@mui/styles"
import { VM } from '../../core/VM'
let view = VM()
export const NotifStyles = () => {
    let s = makeStyles({
        btnPink: {
            backgroundColor: '#EFCCCC',
            color: '#DC4E4E',
            fontSize: 12,
            borderRadius: 8,
            height: 36,
            width:  view === '' ? '40%' : '56%',
            '&:hover':{
                backgroundColor: '#EFCCCC',
            }
        },
        btnGreen: {
            backgroundColor: '#99E2B8',
            color: '#11783C',
            fontSize: 12,
            borderRadius: 8,
            height: 36,
            width: view === '' ? '40%' : '56%',
            '&:hover':{
                backgroundColor: '#99E2B8',
            }
        }
    })
    return s()
}

export const MenuStyles = () => {
    return{
        overflow: 'visible',
        borderRadius: '10px',
        width: '224px',
        top: window.location.pathname === '/notification' ? '120x !important' : '107px !important',
        fontWeight:'regular',
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 9,
            width: 9,
            height: 9,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    }
}