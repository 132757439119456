import { useContext, useState } from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import { GraphDrawer } from './GraphDrawer';
import { Chart } from './Chart';
import { ToolContext } from '../../../core/context/ToolContext';
import { rqx } from '../../../core/request/API';
import { env } from '../../../core/Env';
import { SnakeLoader } from '../../../core/loader/SnakeLoader';
import Setting from '../../../assets/images/assetLogo/new-setting.svg';
import moment from 'moment';

export const Index = ({selectTool, toolOwned, NoGraphicalReportFound}) => {
    const { tool_state, home_state } = useContext(ToolContext)
    const { content, ds, drawer, __SESSION } = {...tool_state, ...home_state}

    const [isShow, setIsShow] = useState(false);
   
    const animations = {
        y: {
            duration: 1500,
            easing: 'easeInOutElastic'
        },
        tension: {
            duration: 2000,
            easing: 'easeInOutElastic',
            from: 0.4,
            to: 0.4,
            loop: true
        }
    }

    const __graphinit = async (interval, sequence = 4, order, date, quarter) => {
        ds.set({load: 'loader', label: [], counts: []})
        let dates
        let datasets = {load: 'data', label: [], counts: []}

        if (interval === 'days') {
            let nd = new Date(date)
            dates = [[`${date} 00:00:01`, `${date} 23:59:59`]]
            for (let i = 1; i < sequence; i++) {
                if (order === 'desc') {
                    dates = [[`${moment(nd).subtract(i, "days").format("MM-DD-YYYY")} 00:00:01`, `${moment(nd).subtract(i, "days").format("MM-DD-YYYY")} 23:59:59`], ...dates]
                } else {
                    dates = [...dates, [`${moment(nd).add(i, "days").format("MM-DD-YYYY")} 00:00:01`, `${moment(nd).add(i, "days").format("MM-DD-YYYY")} 23:59:59`]]
                }
            }
            datasets.label = dates.map(d => {
                let dd = new Date(d[0])
                // return moment(dd).format("ddd, MMMM D, YYYY")
                return [`${moment(dd).format("ddd")}`, `${moment(dd).format("MMM D, YY")}` ]
            })
        } else if (interval === 'weeks') {
            let nd = new Date(date)
            let nextdate = order==='desc'?`${moment(nd).subtract(6, "days").format("MM-DD-YYYY")} 00:00:01`:`${moment(nd).add(6, "days").format("MM-DD-YYYY")} 23:59:59`
            dates = [order==='desc'?[nextdate, `${date} 23:59:59`]:[`${date} 00:00:01`, nextdate]]
            for (let i = 1; i < sequence; i++) {
                let parse_nd = new Date(nextdate)
                if (order === 'desc') {
                    dates = [[`${moment(parse_nd).subtract(7, "days").format("MM-DD-YYYY")} 00:00:01`, `${moment(parse_nd).subtract(1, "days").format("MM-DD-YYYY")} 23:59:59`], ...dates]
                    nextdate = `${moment(parse_nd).subtract(7, "days").format("MM-DD-YYYY")}`
                } else {
                    dates = [...dates, [`${moment(parse_nd).add(1, "days").format("MM-DD-YYYY")} 00:00:01`, `${moment(parse_nd).add(7, "days").format("MM-DD-YYYY")} 23:59:59`]]
                    nextdate = `${moment(parse_nd).add(7, "days").format("MM-DD-YYYY")} 23:59:59`
                }
            }
          
            datasets.label = dates.map(d => {
                let nd1 = new Date(d[0])
                let nd2 = new Date(d[1])
                return [`${moment(nd1).format("MMM D, YY")}`, `to`, ` ${moment(nd2).format("MMM D, YY")}` ]
            })
        } else if (interval === 'months') {
            let nd = new Date(date)
            dates = [[`${moment(nd).startOf('month').format('MM-DD-YYYY')} 00:00:01`, `${moment(nd).endOf('month').format('MM-DD-YYYY')} 23:59:59`]];
            let nmonth = moment(nd).startOf('month')
            for (let i = 1; i < sequence; i++) {
                let parse_nm = new Date(nmonth)
                if (order === 'desc') {
                    let m = moment(parse_nm).subtract(1, 'month')
                    dates = [[`${moment(m).startOf('month').format('MM-DD-YYYY')} 00:00:01`, `${moment(m).endOf('month').format('MM-DD-YYYY')} 23:59:59`], ...dates]
                    nmonth = moment(m).startOf('month')
                } else {
                    let m = moment(parse_nm).add(1, 'month')
                    dates = [...dates, [`${moment(m).startOf('month').format('MM-DD-YYYY')} 00:00:01`, `${moment(m).endOf('month').format('MM-DD-YYYY')} 23:59:59`]]
                    nmonth = moment(m).startOf('month')
                }
            }

            datasets.label = dates.map(d => {
                let nd1 = new Date(d[0])
                return [`Month`, `of`, `${moment(nd1).format("MMM, YY")}`]
            })
        } else if (interval === 'quarters') {
            let d = new Date(date)
            let qrt = quarter === '1st' ? '01-01' : quarter === '2nd' ? '04-01' : quarter === '3rd' ? '07-01' : '10-01'
            let nd = new Date(`${qrt}-${moment(d).format('YYYY')}`)
            let nextdate = order==='desc'?`${moment(nd).add(2, "months").endOf('month').format("MM-DD-YYYY")} 23:59:59`:`${moment(nd).add(2, "months").endOf('month').format("MM-DD-YYYY")} 23:59:59`
            dates = [order==='desc'?[nextdate, `${moment(nd).format('MM-DD-YYYY')} 00:00:01`]:[`${moment(nd).format('MM-DD-YYYY')} 00:00:01`, nextdate]]
           
            for (let i = 1; i < sequence; i++) {
                let parse_nd = new Date(nextdate)
                if (order === 'desc') {
                    dates = [[`${moment(parse_nd).subtract(3, "months").endOf('month').format("MM-DD-YYYY")} 23:59:59`, `${moment(parse_nd).subtract(5, "months").startOf('month').format("MM-DD-YYYY")} 00:00:01`], ...dates]
                    nextdate = `${moment(parse_nd).subtract(3, "months").format("MM-DD-YYYY")}`
                } else {
                    dates = [...dates, [`${moment(parse_nd).add(2, "days").startOf('month').format("MM-DD-YYYY")} 00:00:01`, `${moment(parse_nd).add(3, "months").endOf('month').format("MM-DD-YYYY")} 23:59:59`]]
                    nextdate = `${moment(parse_nd).add(3, "months").format("MM-DD-YYYY")} 23:59:59`
                }
            }

            datasets.label = dates.map(d => {
                let nd1 = new Date(d[0])
                let nd2 = new Date(d[1])
                return [`${moment(nd1).format("MMM D, YY")}`, `to`, ` ${moment(nd2).format("MMM D, YY")}` ]
            })
            
        } else if (interval === 'years') {
            let nd = new Date(date)
            dates = [[moment(nd).format('[01-01]-YYYY [00:00:01]'), moment(nd).format('[12-31]-YYYY [23:59:59]')]]
            let nyear = moment(nd)
            for (let i = 1; i < sequence; i++) {
                let parse_ny = new Date(nyear)
                if (order === 'desc') {
                    let m = moment(parse_ny).subtract(1, 'year')
                    dates = [[moment(m).format('[01-01]-YYYY [00:00:01]'), moment(m).format('[12-31]-YYYY [23:59:59]')], ...dates]
                    nyear = moment(m).format('[01-01]-YYYY')
                } else {
                    let m = moment(parse_ny).add(1, 'year')
                    dates = [...dates, [moment(m).format('[01-01]-YYYY [00:00:01]'), moment(m).format('[12-31]-YYYY [23:59:59]')]]
                    nyear = moment(m).format('[01-01]-YYYY')
                }
            }

            datasets.label = dates.map(d => {
                let nd1 = new Date(d[0])
                return [`Year`, `of`, `${moment(nd1).format("YYYY")}`]
            })
        }

        // console.log(JSON.stringify(dates));
        // return
        
        let frcount = await rqx.get(`<<tb>>/${env()}/report/read`, {company_id:__SESSION.data.uaccess[0].company_id, filters: 'fr', values: JSON.stringify(dates)})
        datasets.counts.push({label: 'Finished Request', animations: animations, data: frcount, backgroundColor: ['#99260E'], borderColor: ['#99260E'] })

        let urcount = await rqx.get(`<<tb>>/${env()}/report/read`, {company_id:__SESSION.data.uaccess[0].company_id, filters: 'ur', values: JSON.stringify(dates)})
        datasets.counts.push({label: 'Unfinish Request', animations: animations, data: urcount, backgroundColor: ['#1B9F53'], borderColor: ['#1B9F53'] })

        let fjocount = await rqx.get(`<<tb>>/${env()}/report/read`, {company_id:__SESSION.data.uaccess[0].company_id, filters: 'fjo', values: JSON.stringify(dates)})
        datasets.counts.push({label: 'Finished Job Order', animations: animations, data: fjocount, backgroundColor: ['#FF8000'], borderColor: ['#FF8000'] })

        let ujocount = await rqx.get(`<<tb>>/${env()}/report/read`, {company_id:__SESSION.data.uaccess[0].company_id, filters: 'ujo', values: JSON.stringify(dates)})
        datasets.counts.push({label: 'Unfinish Job Order', animations: animations, data: ujocount, backgroundColor: ['#FBE829'], borderColor: ['#FBE829'] })
        
        ds.set(datasets)
    }

    const gdHandler = (method) => {
        drawer.set({...drawer.data, graph: {...drawer.data.graph, open: method}})
    }

  return (
    <>
        <Box borderRadius={3} sx={{border:"1px solid #EBEBEB"}} bgcolor="#ffffff" p={2} height="370px" width="100%">
            {toolOwned.length > 0 && selectTool !== ''? 
                <>
                    <Box height="49px" display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            <img src={selectTool !== '' ? JSON.parse(toolOwned.filter( v => {return selectTool === v.tid})[0].logo) : Setting} alt="teambond-icon" height={32} width={32} />
                            <Box>
                                <Box fontSize={14} fontWeight="bold" ml="12px">{selectTool !== '' ? toolOwned.filter( v => {return selectTool === v.tid})[0].name : 'Tool Name'}</Box>
                                <Box fontSize={12} ml="12px">Tool Graphical Reports</Box>
                            </Box>
                        </Box>
                        <IconButton onClick={()=>gdHandler(true)}>
                            <img src={Setting} alt="setting-icon" />
                        </IconButton>
                    </Box>
                    {isShow && (
                        ds.data.load ==='loader' ?
                            <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt="-49px" >
                                <Box display="flex" alignItems="center">
                                    <Box component="b" fontSize={16} color="primary.main">GENERATING REPORT</Box>
                                    <Box pt={1}><SnakeLoader size="5px" bg="#11783C" distance="0.3rem" /></Box>
                                </Box>
                            </Box>
                        :
                            <Grid container>
                                <Grid item sm={12}>
                                    <Chart {...home_state} content={content} ds={ds}/>
                                </Grid>
                            </Grid>
                        
                    )}
                </>
            : 
                <Box height="100%" display="flex" alignItems="center">
                    <NoGraphicalReportFound m={0}/>
                </Box>
            } 
        </Box>
        <GraphDrawer setIsShow={setIsShow} {...home_state} gdHandler={gdHandler} __graphinit={__graphinit} />
    </>
  )
}