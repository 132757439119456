import { Box, Button, Modal, Slide, TextField } from "@mui/material"
import { UseStyles } from "../../../global/DesktopDsg"
import { rqx } from "../../../../../core/request/API"
import { env } from "../../../../../core/Env"
import { NotifCount } from "../../../../../core/request/Notif"

export const NewFolder = ({ __SESSION, cloud_state, user_id, company_id, __InitDocuFolder}) => {
    const { err, folderName, openModal, successOpen, message, saveLoader, reloadStorage } = cloud_state
    const dsg = UseStyles()
    const urlParams = new URLSearchParams(window.location.search)

    const btnStyle = (type, folderName) => {
       return {
            padding: '12px 16px',
            fontSize: 14,
            lineHeight: '16px', 
            color: type === 'create' ? '#FFFFFF' : '#333333',
            backgroundColor: type === 'create' && folderName.data !== '' ? '#107038': type === 'create' ? '#A2C4B7' : '#ffffff',
            border: type === 'create' ? null : '1px solid #ACACAC',
            borderRadius: '6px',
            '&:hover': {
                backgroundColor: type === 'create' && folderName.data !== '' ? '#107038': type === 'create' ? '#A2C4B7' : '#ffffff',
                color: type === 'create' ? '#FFFFFF' : '#333333',
            }
       }
    }

    const handlerBtn = async(type) => {
        if (type === 'create') {
            if (!saveLoader.data && folderName.data !== '') {
                saveLoader.set(true)
                let body = {
                    type: 'create_folder',
                    folder_name: folderName.data,
                    user_id: user_id,
                    company_id: company_id,
                    parent_id: urlParams.get('f_id') !== null ? window.atob(urlParams.get('f_id')) : 0,
                    status: 1
                }
                const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/documents/create`, body)
                if (res.msg === 'exist') {
                    err.set({...err.data, val : true, msg: 'Folder name already exist.'})
                    saveLoader.set(false)
                }else if (res.msg === 'success') {
                    let fltrData = [{user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]), file:'folder'}]
                    await NotifCount(fltrData, __SESSION, `create`)
                    openModal.set(false)
                    message.set('Successfully saved')
                    reloadStorage.set(true)
                    successOpen.set(true)
                    saveLoader.set(false)
                    err.set({...err.data, val : false, msg: ''})
                    __InitDocuFolder(0)
                }
            }else{
                err.set({...err.data, val : true, msg: 'Folder name is required.'})
            }
        }else{
            openModal.set(false)
        }
    }

    const handlerOnChange = (e) => {
        folderName.set(e.target.value)
        err.set({...err.data, val : false, msg: ''})
    }
    
    return (
        <Modal open={openModal.data} className="noutlined">
            <Slide in={openModal.data} direction="up">
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <Box width={319} bgcolor="#FFFFFF" borderRadius="6px" p={2}>
                        <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={1}>New Folder</Box>
                        <TextField fullWidth onChange={(e) => handlerOnChange(e)} value={folderName.data} className={dsg.txtfld} placeholder="Input folder name" error={(folderName.data === '' && err.data.val) || err.data.val} helperText={err.data.msg} />
                        <Box display="flex" alignItems="center" justifyContent="flex-end" gap="8px" mt={3}>
                            <Button sx={btnStyle('cancel', folderName)} onClick={() => handlerBtn('cancel')} disabled={saveLoader.data}>
                                Cancel
                            </Button>
                            <Button sx={btnStyle('create', folderName)} onClick={() => handlerBtn('create')} disabled={saveLoader.data}>
                                Create
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}
