import { Box, IconButton } from "@mui/material"
import { ListIndex } from "./list/Index"
import { useContext, useState } from "react"
import AddIcon from "@mui/icons-material/Add"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { SIndex } from "./setup/Index"
import { ToolContext } from "../../../../core/context/ToolContext"
import { PermissionModal } from "../../../mycloud/global/DesktopDsg"
import { NotFoundPage } from "../../../empty/Index"

let uai_user = process.env.REACT_APP_UAI_REGULAR;

export const MobIndex = ({ match, deptStaticData }) => {
    const { tool_state, install_state } = useContext(ToolContext)
    const { __SESSION } = tool_state 
    
    const nav = useHistory()
    const [isShow, setisShow] = useState(false)

    const handlerOpenCreate = () => {
        nav.push(`/policies/create`)
    }

    const handleProceed = async(type) => {
        await setisShow(false)
        setTimeout(() => {
            if (type === 'cancel') {
                nav.push('/')
            } else {
                nav.push('/general-maintenance/company-setup')
            }
        }, 200);
    }

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Box height="100%" sx={{ overflowY: 'auto'}}>
                {/* <ListIndex deptStaticData={deptStaticData} /> */}
                <Components match={match} deptStaticData={deptStaticData}/>
            </Box>
            <Box position="fixed" display="flex" flexDirection="column" alignItems="flex-end" bottom={match.params.params === 'list' ? (install_state.prompt.data !== null ? 139 :75) : 16} right={40} zIndex={1101} 
            sx={{ transition: 'bottom 0.3s ease 0s'}}>
            {
                __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) &&
                <IconButton sx={{  bgcolor: '#107038B8', height: '48px', width: '48px', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.04),0px 8px 16px rgba(0, 0, 0, 0.08)' }} onClick={handlerOpenCreate}>
                    <AddIcon sx={{color: '#FFFFFF'}}/>
                </IconButton>
            }
            </Box>
            <Box minHeight="20px" bgcolor="#FFFFFF"></Box>
            {atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0' && (
                <PermissionModal show={isShow} handleProceed={handleProceed} type="policies"/>
            )}
            <Box minHeight={install_state.prompt.data !== null ? "76px" : "12px"} />
        </Box>
    )
}

const Components = ({match, deptStaticData, isLoading }) => {
    
    if (match.params.params === 'list') {   
        return deptStaticData.length > 0 && <ListIndex match={match} deptStaticData={deptStaticData} isLoading={isLoading}/>
    }else if (match.params.params === 'create' || match.params.params === 'review') {
        return <SIndex match={match} deptStaticData={deptStaticData}/>
    }else{
        return <NotFoundPage/>
    }
}