import { Avatar, Box, IconButton, Modal, Typography } from '@mui/material'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { ArrowLeft, VeriticalThreeDots } from '../../../../../../core/global/Icons'

export const PreviewCons = ({show, conData, handlerThreeDots}) => {

    return (
        <>
            <Modal open={show.data.fullview}   
                sx={{borderColor:'none !important', zIndex: 1303}}
                onClose={()=> show.set({...show.data, view: true, fullview:false})}>
                <Box height="100%" width="100%" display="flex" flexDirection="column" bgcolor="#313131"> 
                    <Box minHeight="44px" width="100%" display="flex" alignItems="center" justifyContent="space-between" px="16px" py="14px">
                        <Box width="90%" display="flex" alignItems="center">
                            <IconButton sx={{mt:'4px'}} onClick={()=> show.set({...show.data, view: true, fullview:false})}>
                                <ArrowLeft stroke='#FFFFFF' width={20} height={20} />
                            </IconButton>
                            <Typography color="#FFFFFF" fontSize={14} fontWeight={400} lineHeight="26px" width="100%"  noWrap>{show.data.data.contract_name} ({show.data.data.client_name}) {conData.file_name}</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" minHeight={20} minWidth={20}>
                            <IconButton sx={{p: '0px'}} onClick={(e)=>handlerThreeDots(e, show.data.data, 'con')}>
                                <VeriticalThreeDots fill={"#F2F7FF"} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box height="100%" mt="68px" mx='20px' pr="1px" className="overflowY">
                        {
                            conData.file_type.includes('image')? 
                                // <Box component="img" src={conData.file_link} alt={conData.file_link} height="auto" width="100%" borderRadius="8px 8px 0px 0px" />
                                <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                                    <Avatar src={conData.file_link} alt={conData.file_link} sx={{height:200, width:'auto', borderRadius:'8px'}}/>
                                </Box>
                            :
                                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                    <Box sx={{height: 'auto', width:'100%', borderRadius:'8px 8px 0px 0px'}}>
                                        <Box component={Viewer} fileUrl={conData.file_link} 
                                        // defaultScale={SpecialZoomLevel.PageFit} 
                                        />
                                    </Box>
                                </Worker>
                        }
                    </Box>
                    <Box minHeight="87px"></Box>
                </Box>
            </Modal>
        </>
    )
}