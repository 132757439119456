import { Box } from "@mui/material"
import { ToWords } from "to-words";
import { ParsingAmount } from "../../core/global/Format";

export const PrintSI = ({componentRef, form, items, show}) => {
    
    const toWords = new ToWords({
        localeCode: "en-PH", // Supports multiple locales
        converterOptions: {
          currency: true, // Add currency-related words if needed
          ignoreDecimal: false, // Convert the decimal part as well
          ignoreZeroCurrency: false,
        },
    });

    return (
        <Box width="100%" height="100%" ref={componentRef}>
            <Box sx={{position:'relative !important', maxHeight:'100% !important'}}>
                <Box fontSize={12} sx={{position:'absolute !important', width:'100% !important', height:'100% !important', zIndex:'1000'}} top={0} borderRadius={2} bgcolor="transparent" >
                    {show&&<Box position="absolute" top={150} left={540} fontSize={12} fontWeight={700}>{form.date}</Box>}
                    <Box position="absolute" top={167} left={80} fontSize={12} fontWeight={700}>{form.sold_to}</Box>
                    <Box position="absolute" top={167} left={540} fontSize={12} fontWeight={700}>{form.tin}</Box>
                    <Box position="absolute" top={195} left={80} lineHeight="10px" fontSize={10} fontWeight={700}>{form.address_at}</Box>
                    <Box position="absolute" top={215} left={80} lineHeight="10px" fontSize={10} fontWeight={700} width="370px">{toWords.convert(Number(form.pesos))}</Box>
                    <Box position="absolute" top={215} left={540} fontSize={12} fontWeight={700}>{ParsingAmount(form.pesos)}</Box>
                    {items.map((v,k) => (
                        v.qty !== '' && 
                            <Box key={k}>
                                <Box position="absolute" top={270 + ( 27 * (k+(k === 0 ? 0 : v.nature_of_service.length > 99 ? v.spacing - 1 : v.spacing ) ) )} left={35} fontSize={12} fontWeight={700}>
                                    {v.qty}
                                </Box>
                                <Box position="absolute"  top={270 + ( 27 * (k+(k === 0 ? 0 : v.nature_of_service.length > 99 ? v.spacing - 1 : v.spacing ) ) )} left={100} fontSize={10} fontWeight={700} lineHeight="10px" width="80px">{v.unit}</Box>
                                <Box position="absolute"  top={268 + (v.nature_of_service.length < 99 ? 3 : 0) + ( 27 * (k+(k === 0 ? 0 : v.nature_of_service.length > 99 ? v.spacing - 1 : v.spacing ) ) )} left={192} lineHeight={v.nature_of_service.length > 99 ? "10px" : "12px"} fontSize={12} fontWeight={700} width="310px">{v.nature_of_service}</Box>
                                <Box position="absolute"  top={270 + ( 27 * (k+(k === 0 ? 0 : v.nature_of_service.length > 99 ? v.spacing - 1 : v.spacing ) ) )} left={530} fontSize={12} lineHeight="10px" fontWeight={700} width="28px">{ParsingAmount(v.unit_cost)}</Box>
                                <Box position="absolute"  top={270 + ( 27 * (k+(k === 0 ? 0 : v.nature_of_service.length > 99 ? v.spacing - 1 : v.spacing ) ) )} left={650} fontSize={12} lineHeight="10px" fontWeight={700} width="30px">{ParsingAmount(v.amount)}</Box>

                                {/* <Box position="absolute" top={270 + ( 27 * (k+(k === 0 ? 0 : v.nature_of_service.length > 120 ? v.spacing - 1 : v.spacing ) ) )} left={35} fontSize={12} fontWeight={700}>{270 + ( 27 * (k+(k === 0 ? 0 : v.nature_of_service.length > 120 ? v.spacing - 1 : v.spacing ) ) )}</Box> */}
                                {/* <Box position="absolute" top={270 + ( 27 * k)} left={35} fontSize={12} fontWeight={700}>{v.qty}</Box>
                                <Box position="absolute" top={270 + ( 27 * k)} left={100} fontSize={9} fontWeight={700} lineHeight="10px" width="75px">{v.unit}</Box>
                                <Box position="absolute" top={265 + (k>10?3:0) + ( 27 * k)} left={192} letterSpacing=".5px" lineHeight={v.nature_of_service.length > 120 ? "8px" : "10px"} fontSize={v.nature_of_service.length > 120 ? 9 : 10} fontWeight={700} width="310px">{v.nature_of_service}</Box>
                                <Box position="absolute" top={270 + ( 27 * k)} left={530} fontSize={12} lineHeight="10px" fontWeight={700} width="28px">{ParsingAmount(v.unit_cost)}</Box>
                                <Box position="absolute" top={270 + ( 27 * k)} left={650} fontSize={12} lineHeight="10px" fontWeight={700} width="30px">{ParsingAmount(v.amount)}</Box> */}
                            </Box>
                    ))}
                    <Box position="absolute" top={900} left={350} fontSize={12} fontWeight={700}>{ParsingAmount(form.vatable_sales) || 0}</Box>
                    <Box position="absolute" top={930} left={350} fontSize={12} fontWeight={700}>{ParsingAmount(form.vat)}</Box>
                    <Box position="absolute" top={955} left={350} fontSize={12} fontWeight={700}>{ParsingAmount(form.zero_rated_sales)}</Box>
                    <Box position="absolute" top={980} left={350} fontSize={12} fontWeight={700}>{ParsingAmount(form.vat_exempt_sales)}</Box>
                    <Box position="absolute" top={955} left={640} fontSize={12} fontWeight={700}>{ParsingAmount(form.amount_due)}</Box>
                    <Box position="absolute" top={980} left={640} fontSize={12} fontWeight={700}>{ParsingAmount(form.add_vat)}</Box>
                    <Box position="absolute" top={1010} left={640} fontSize={12} fontWeight={700}>{ParsingAmount(form.total_amount_due)}</Box>
                </Box>
            </Box>
        </Box>
    )
}