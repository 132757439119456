import { Box, Button, Grid, Skeleton, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { HandlerRegType, ValidateBtnNxtReg, ValidateColor, __InitRegList } from '../../../global/Function'
import { S3Upload } from '../../../../../core/transmit/S3'
import { rqx } from '../../../../../core/request/API'
import { env } from '../../../../../core/Env'
import { useContext } from 'react'
import { ToolContext } from '../../../../../core/context/ToolContext'
import moment from 'moment'
import { NotifCount } from '../../../../../core/request/Notif'
import { db } from '../../../../../db/db'

export const Right = ({ dsg, nav, __SESSION, subparams}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const { cloud_state } = useContext(ToolContext)
    const { regList, isLoading, filter, inftScroll, limit, lid, listRegName, form, ocrLoader, actType, successOpen, message, saveLoader, reloadStorage, sufficientStorage, insuffShow, storage } = cloud_state
    // const [ isload, setIsLoad ] = useState(false);

    const __ChangeHandler = (e) => {
        const { name, value } = e.target 
        form.set({...form.data, 
            [name]: { val: value, stat: value === '' ? true : false, msg : value === '' ? 'This field is required.' : '' } 
        })
    }
    
    const HandlerBtn = async(type) => {
        if (type === 'next') {
            if (sufficientStorage.data && subparams === 'create' && atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') {
                insuffShow.set(true)
            }else{
                if (!form.data.name.stat && !form.data.number.stat) {
                    if (ValidateBtnNxtReg(form).disabled !== true) {
                        if (urlParams.get('filter') === 'ubr') {
                            nav.push('/my-cloud/registrations/review?filter=ubr')
                        }else{
                            nav.push('/my-cloud/registrations/review')
                        }
                    }
                }
            }
        }else if (type === 'edit') {
            if (form.data.id === undefined) {
                nav.push(`/my-cloud/registrations/create`)
            }else{
                nav.push(`/my-cloud/registrations/${window.btoa(form.data.id)}`)
            }
        }else{
            if (!saveLoader.data) {
                saveLoader.set(true)
                const urlS3 = actType.data === 'update' && form.data.regFile.photo.val.slice(0, 5) === 'https' ? actType.data : await S3Upload(form.data.regFile.file.val);
                if (urlS3) {
                    if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0') {
                        let data = { 
                            file_link:form.data.regFile.photo.val.slice(0, 5) === 'https' ? form.data.regFile.photo.val : urlS3.location,
                            file_name:form.data.regFile.file.val[0].name,
                            file_type:form.data.regFile.file.val[0].type,
                            file_size:form.data.regFile.file.val[0].size,
                            reference_num:form.data.number.val,
                            registered_name:form.data.name.val,
                            registration_name: form.data.regName.val === 0 ? form.data.regOther.val : listRegName.data.filter(v => { return v.id === form.data.regName.val})[0].name,
                            series_num:"RGST-000000001",
                            start_date:form.data.regType.val === 1 ? moment(form.data.date_from.val).format('YYYY-MM-DD') : null,
                            end_date:form.data.regType.val === 1 ? moment(form.data.date_to.val).format('YYYY-MM-DD') : null,
                            status: 1,
                            type: form.data.regType.val,
                            user_favorite_id: null,
                            user_favorite_status:null,
                            user_id:null,
                            storage:form.data.regFile.storage,
                        }
                        db.reglist.add(data);
                        regList.set([data])
                        saveLoader.set(false)
                        storage.set({...storage.data, 
                            used:storage.data.used + form.data.regFile.file.val[0].size,
                            total:21474836480,
                            reg:form.data.regFile.file.val[0].size, 
                            regFile:1,
                        }) 
                        nav.push('/my-cloud/registrations/list')
                    }else{
                        let data = {
                            id:actType.data === 'update' ? form.data.id : 0,
                            name:form.data.regName.val === 0 ? form.data.regOther.val : listRegName.data.filter(v => { return v.id === form.data.regName.val})[0].name,
                            type:form.data.regType.val,
                            company_id:__SESSION.data.uaccess[0].company_id,
                            user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                            registered_name:form.data.name.val,
                            reference_num:form.data.number.val,
                            start_date:form.data.regType.val === 1 ? moment(form.data.date_from.val).format('YYYY-MM-DD') : '',
                            end_date:form.data.regType.val === 1 ? moment(form.data.date_to.val).format('YYYY-MM-DD') : '',
                            file_name:form.data.regFile.file.val[0].name,
                            file_type:form.data.regFile.file.val[0].type,
                            file_size:form.data.regFile.file.val[0].size,
                            storage:form.data.regFile.storage,
                            // file_size: form.data.regFile.photo.val.slice(0, 5) === 'https' ? form.data.regFile.file.val[0].size :count += Math.round(form.data.regFile.file.val[0].size / 1024),
                            file_link:form.data.regFile.photo.val.slice(0, 5) === 'https' ? form.data.regFile.photo.val : urlS3.location,
                            ref:''
                        }
                        let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/registration/${actType.data === 'update' ? 'update' : 'create'}`, data)
                        if (res.message === 'success') {
                            await message.set(`Successfully ${actType.data === 'update' ? 'updated':'saved'}`)
                            await successOpen.set(true)
                            await __InitRegList(0, filter.data, isLoading, inftScroll, __SESSION, limit, lid, regList)
                            saveLoader.set(false)
                            reloadStorage.set(true)
                            let fltrData = [{user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]), file:'reg'}]
                            await NotifCount(fltrData, __SESSION, `create`)
                            nav.push('/my-cloud/registrations/list')
                        }else if(res === 'Register name is already exist'){
                            form.set({...form.data, 
                                name:{...form.data.name, stat:true, msg:'Register name is already exist'},
                            })
                            saveLoader.set(false)
                            if (actType.data === 'update') {
                                nav.push(`/my-cloud/registrations/${window.btoa(form.data.id)}`)
                            }else{
                                nav.push('/my-cloud/registrations/create')
                            }
                        }else if(res === 'Reference Num is already exist'){
                            form.set({...form.data, 
                                number:{...form.data.number, stat:true, msg:'Reference number is already exist'},
                            })
                            saveLoader.set(false)
                            if (actType.data === 'update') {
                                nav.push(`/my-cloud/registrations/${window.btoa(form.data.id)}`)
                            }else{
                                nav.push('/my-cloud/registrations/create')
                            }
                        }
                    }
                }
            }
        }
    }
    
    return (
        <Box height="100%" width="50%" bgcolor="#F8F9FA" display="flex" justifyContent="space-between" flexDirection="column" py="40px" px="60px">
            <Box></Box>
            <Box>
                {
                    ocrLoader.data ? 
                        <Box>
                            <Skeleton variant="text" height={11} width={142} sx={{borderRadius: '4px', mb:2}} />
                            <Skeleton variant="text" height={11} width={142} sx={{borderRadius: '4px', mb:2}} />
                            <Skeleton variant="rectangular" height={37} width="100%" sx={{borderRadius: '4px', mb: 3}} />
                            <Skeleton variant="text" height={11} width={142} sx={{borderRadius: '4px', mb:'12px'}} />
                            <Skeleton variant="rectangular" height={37} width="100%" sx={{borderRadius: '4px', mb: '20px'}} />
                            <Skeleton variant="text" height={11} width={142} sx={{borderRadius: '4px', mb:'12px'}} />
                            <Skeleton variant="rectangular" height={37} width="100%" sx={{borderRadius: '4px', mb: '20px'}} />
                        </Box>
                    :
                        <>
                            <Box color="#107038" fontSize={14} fontWeight={600} lineHeight="16px" mb={2}>Registration Details</Box>
                            <Box color="#313131" fontSize={14} fontWeight={600} lineHeight="16px" mb="12px">Registration Type*</Box>
                            <Box display="flex" alignItems="center" className="c-pointer" mb={2}>
                                <Box width="50%" py="8px" textAlign="center" borderRadius="6px" fontSize={14}
                                    bgcolor={ValidateColor(form).bg_xp} 
                                    color={ValidateColor(form).color_xp} 
                                    onClick={() => HandlerRegType(form, 'exp')}
                                >
                                    Expiring
                                </Box>
                                <Box width="50%" py="8px" textAlign="center" borderRadius="6px" fontSize={14}
                                    bgcolor={ValidateColor(form).bg_non} 
                                    color={ValidateColor(form).color_non} 
                                    onClick={() => HandlerRegType(form, 'non-exp')}
                                >
                                        Non-Expiring
                                </Box>
                            </Box>
                            <Box className="ubr-regInfo">
                                {
                                    form.data.regName.val === 0 &&
                                    <>
                                        <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={1}>Name of Registration*</Box>
                                        <TextField fullWidth 
                                            name="regOther"
                                            className={dsg.txtfld} 
                                            placeholder='Ex.: Barangay Clearance, etc.'
                                            onChange={(e) => __ChangeHandler(e)}
                                            value={form.data.regOther.val}
                                            error={form.data.regOther.stat} 
                                            disabled={subparams === 'review'}
                                            sx={{ '& .MuiOutlinedInput-root':{ backgroundColor: subparams === 'review' ? '#E6E6E6 !important' : '#FFFFFF' } }}
                                        />
                                        <Box fontSize={10} color="red" pl="5px" pt="5px">{form.data.regOther.msg}</Box>
                                    </>
                                }
                                <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={1} mt={2}>Registered Name*</Box>
                                <TextField fullWidth 
                                    className={dsg.txtfld}  
                                    name="name" 
                                    placeholder='Ex.: Juan Dela Cruz Jr.'
                                    onChange={(e) => __ChangeHandler(e)}
                                    value={form.data.name.val}
                                    error={form.data.name.stat} 
                                    disabled={subparams === 'review'}
                                    sx={{ '& .MuiOutlinedInput-root':{ backgroundColor: subparams === 'review' ? '#E6E6E6 !important' : '#FFFFFF' } }}
                                />
                                <Box fontSize={10} color="red" pl="5px" pt="5px">{form.data.name.msg}</Box>
                                {
                                    form.data.regType.val === 1 &&
                                        <>
                                            <Box my={2} color="#737373" fontWeight={400} fontSize={14} lineHeight="16px">Validity Date</Box>
                                            <Grid container spacing={2.5}>
                                                <Grid item sm={6}>
                                                    <Box color="#313131" fontWeight={600} fontSize={14} lineHeight="16px" mb={1}>From</Box>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker label="" 
                                                            maxDate={new Date()} 
                                                            value={form.data.date_from.val} 
                                                            onChange={(e)=>__ChangeHandler({target: {name: 'date_from', value: e}})}
                                                            disabled={subparams === 'review'}
                                                            renderInput={(params) => 
                                                                <TextField {...params} fullWidth className={dsg.txtfld} error={form.data.date_from.val === null} 
                                                                    sx={{ '& .MuiOutlinedInput-root':{ backgroundColor: subparams === 'review' ? '#E6E6E6 !important' : '#FFFFFF' } }}
                                                                />
                                                        }
                                                        />
                                                    </LocalizationProvider>
                                                    <Box fontSize={10} color="red" pl="5px" pt="5px">{form.data.date_to.msg}</Box>
                                                </Grid>
                                                <Grid item sm={6}>
                                                    <Box color="#313131" fontWeight={600} fontSize={14} lineHeight="16px" mb={1}>To</Box>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker label=""
                                                            minDate={new Date(form.data.date_from.val)}
                                                            value={form.data.date_to.val} 
                                                            onChange={(e)=>__ChangeHandler({target: {name: 'date_to', value: e}})}
                                                            disabled={subparams === 'review'}
                                                            renderInput={(params) => 
                                                                <TextField {...params} fullWidth className={dsg.txtfld} error={form.data.date_to.val === null}  
                                                                    sx={{ '& .MuiOutlinedInput-root':{ backgroundColor: subparams === 'review' ? '#E6E6E6 !important' : '#FFFFFF' } }}
                                                                />
                                                        }
                                                        />
                                                    </LocalizationProvider>
                                                    <Box fontSize={10} color="red" pl="5px" pt="5px">{form.data.date_to.msg}</Box>
                                                </Grid>
                                            </Grid>
                                        </>
                                }
                                <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={1} mt={2}>Registration Number*</Box>
                                <TextField fullWidth 
                                    className={dsg.txtfld} 
                                    name="number" 
                                    onChange={(e) => __ChangeHandler(e)} 
                                    value={form.data.number.val}
                                    error={form.data.number.stat}
                                    disabled={subparams === 'review'}
                                    sx={{ '& .MuiOutlinedInput-root':{ backgroundColor: subparams === 'review' ? '#E6E6E6 !important' : '#FFFFFF' } }}
                                />
                                <Box fontSize={10} color="red" pl="5px" pt="5px">{form.data.number.msg}</Box>
                            </Box>
                            {
                                subparams === 'review' &&
                                    <Box fontSize={12} fontWeight={400} lineHeight="19.42px" color="#333333" mt={3}>
                                        Hey there! Please review your scanned data to ensure accuracy and avoid any errors or discrepancies. If you notice any mistakes, please feel free to input the correct data. Thanks for your help!
                                    </Box>
                            }
                        </>
                }
            </Box>
            <Box display="flex" justifyContent="flex-end">
                {
                    subparams === 'review' ?
                        <Box display="flex" alignItems="flex">
                            <Button variant="contained" disableElevation className={dsg.btnEdit} 
                                disabled={saveLoader.data}
                                onClick={() => HandlerBtn('edit')}
                            >
                                Edit
                            </Button>
                            <Button variant="contained" disableElevation className={dsg.btnNext} 
                                sx={{zIndex: saveLoader.data ? 2001 : 'auto'}}
                                onClick={() => HandlerBtn('save')}
                            >
                                {actType.data === 'update' ? 
                                    saveLoader.data ? 'Updating...' : 'Update'
                                :
                                    saveLoader.data ? 'Saving...' : 'Save'
                                }
                            </Button>
                        </Box>
                    :
                        <Button variant="contained" disableElevation className={dsg.btnNext} onClick={() => HandlerBtn('next')}
                            sx={{backgroundColor: form.data.name.stat || form.data.number.stat ? '#C7DAD7' : ValidateBtnNxtReg(form).bgcolor, '&:hover': { backgroundColor: form.data.name.stat || form.data.number.stat ? '#C7DAD7' : ValidateBtnNxtReg(form).bgcolor }  }}
                        >
                            Next
                        </Button>
                }
            </Box>
        </Box>
    )
}
