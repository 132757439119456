import { useState } from 'react';
import { Box, Modal, Fade, Typography, Button } from '@mui/material';
import { WelcomPageIcon1, WelcomPageIcon2 } from '../../core/global/Icons';
import { motion } from 'framer-motion';

export const WelcomeMyCloud = ({open, event}) => {
    const [show, setShow] = useState(false)

    const handleClick = () => {
        setShow(true)
    }
    return (  
        <Modal
            open={open}
            closeAfterTransition
            // BackdropProps={{ sx: { backgroundColor: '#00000014' } }}
        >
            <Fade in={open}>
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" >
                    <Box bgcolor="#ffffff" borderRadius="12px" width={411}>
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{backgroundImage:`url(https://pofsis-business.s3.amazonaws.com/biz/1683079246054.png)`, height:'250px', width:'100%', borderRadius:'12px 12px 0px 0px'}}
                        >
                            <Box component={motion.div} 
                            animate={show ? "closed" : "open"}
                            variants={{
                                open: { opacity: 1, x: 90 },
                                closed: { opacity: 0, x: "-65%", zIndex:'0 !important' },
                            }}
                            exit="exit"
                            transition={{
                                x: { type: "spring", stiffness: 300, damping: 30 },
                                opacity: { duration: 0.2 }
                            }}
                            >
                                <WelcomPageIcon1/>
                            </Box>
                            <Box component={motion.div} 
                            animate={show ? "open" : "closed"}
                            variants={{
                                open: { opacity: 1, x: -65 },
                                closed: { opacity: 0, x: "65%" },
                            }}
                            exit="exit"
                            transition={{
                                x: { type: "spring", stiffness: 300, damping: 30 },
                                opacity: { duration: 0.2 }
                            }}
                            >
                                <WelcomPageIcon2/>
                            </Box>
                        </Box>
                        <Box pt="14px" px="24px" display="flex" justifyContent="center" alignItems="center" gap="8px">
                            <Box onClick={()=>setShow(false)} className="c-pointer" width="32px" minHeight="4px" borderRadius="100px" bgcolor={show ? '#EAEFF7' : '#107038'}></Box>
                            <Box onClick={()=>setShow(true)} className="c-pointer" width="32px" minHeight="4px" borderRadius="100px" bgcolor={show ? '#107038' : '#EAEFF7'}></Box>
                        </Box>
                        <Box p="32px 24px 24px 24px">
                            <Typography fontSize={24} fontWeight={700} lineHeight="26px" color="#313131">{show ? 'Quick & Easy Setup' : 'Welcome to B Cloud!'}</Typography>
                            <Typography fontSize={14} fontWeight={400} lineHeight="23px" pt="16px" color="#737373">
                                {show ? 'The First thing you need to do is scan your business file.':'Manage your business file easily anytime and anywhere.'}
                            </Typography>
                            {show ? 
                                <Button variant="contained" fullWidth sx={{borderRadius: '6px', mt:'32px', minHeight:'48px'}} onClick={event}>
                                    Continue
                                </Button>
                            :
                                <Button variant="contained" fullWidth sx={{borderRadius: '6px', mt:'32px', minHeight:'48px'}} onClick={handleClick}>
                                    Next
                                </Button>
                            }
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

export const WelcomeMyCloudMb = ({open, event}) => {
    const [show, setShow] = useState(false)
    const handlerNext = () => {
        setShow(true)
    }
    
    return (
        <Modal open={open} sx={{zIndex:1304}}>
            <Fade in={open}>
                <Box height="100%" width="100%" >
                    <Box height="50%" width="100%">
                        <Box display="flex" justifyContent="center" alignItems="center" 
                            sx={{backgroundImage:`url(https://pofsis-business.s3.amazonaws.com/biz/1683079246054.png)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'top', height:'100%', width:'100%'}}
                        >
                            <Box component={motion.div} 
                            animate={show ? "closed" : "open"}
                            variants={{
                                open: { opacity: 1, x: 90 },
                                closed: { opacity: 0, x: "-65%", zIndex:'0 !important' },
                            }}
                            exit="exit"
                            transition={{
                                x: { type: "spring", stiffness: 300, damping: 30 },
                                opacity: { duration: 0.2 }
                            }}
                            >
                                <WelcomPageIcon1/>
                            </Box>
                            <Box component={motion.div} 
                            animate={show ? "open" : "closed"}
                            variants={{
                                open: { opacity: 1, x: -65 },
                                closed: { opacity: 0, x: "10%" },
                            }}
                            exit="exit"
                            transition={{
                                x: { type: "spring", stiffness: 300, damping: 30 },
                                opacity: { duration: 0.2 }
                            }}
                            >
                                <WelcomPageIcon2/>
                            </Box>
                        </Box>
                    </Box>
                    <Box height="50%" display="flex" flexDirection="column" bgcolor="#ffffff" py={3} px={4}>
                        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between" mb="58px">
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <Box onClick={()=>setShow(false)} height="4px" width="32px" bgcolor={show ? "#E6E6E6" : "#107038"} borderRadius="100px" mr={1} />
                                <Box onClick={()=>setShow(true)} height="4px" width="32px" bgcolor={show ? "#107038" : "#E6E6E6"} borderRadius="100px" mr={1} />
                            </Box>
                            <Box fontSize="24px" fontWeight={700} lineHeight="37px">
                                {show ? 'Quick & Easy Setup' : 'Welcome to B Cloud' } 
                            </Box>
                            <Box fontSize={14} fontWeight={400} lineHeight="23px" color="#737373">
                                {
                                    show ?
                                        'The First thing you need to do is scan your business file.'
                                    :
                                        'Manage your business file easily anytime and anywhere.'
                                }
                            </Box>
                            {
                                show ?
                                    <Button fullWidth variant="contained" sx={{height: 48, borderRadius: '6px'}} disableElevation onClick={event}>
                                        Continue
                                    </Button>
                                :
                                    <Button fullWidth variant="contained" sx={{height: 48, borderRadius: '6px'}} disableElevation onClick={() => handlerNext()}>
                                        Next
                                    </Button>
                            }
                        </Box>
                        <Box display="flex" justifyContent="center" minHeight="4px">
                            <Box width="144px" borderRadius="100px" bgcolor="#E6E6E6" />
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}
