import { motion } from "framer-motion";
import { Box, Modal, Slide } from "@mui/material"
import camera from '../../../../../../assets/images/icons/camera.png';
import file from '../../../../../../assets/images/icons/file.png';
import Tesseract from "tesseract.js";
import { ErrorAlert } from "../../../../../../core/global/forms/CstmModal";

export const Choose = ({choices, form, modal, ocrLoader, errOpen, message}) => {
    const handlerCloseChoises = () => {
        choices.set({...choices.data, val: '', show: false})
    }

    const onChangeFile = (e) => {
        let files = e.target.files[0]
        if (files.type.includes('image') || files.type.includes('application/pdf')) {
            
            let sizelmt = 4194304
            if (files.size > sizelmt) {
                message.set('File uploads must not exceed 3MB.')
                errOpen.set(true)
                return
            }

            choices.set({...choices.data, val: '', show: false})
            let _file = e.target.files[0]
            form.set({...form.data, 
                regFile:{
                    photo:{...form.data.regFile.photo, val : URL.createObjectURL(_file) },
                    file:{...form.data.regFile.file, val : e.target.files }
                }
            })
            if (_file.type.includes('image') && form.data.regName.val === 5) {
                ocrLoader.set(true)
                modal.set({...modal.data, sampleImg : {val : true} })
                Tesseract.recognize(
                    _file,
                    'eng',
                ).then(({data: { text } }) => {
                    if (text.includes('that') && text.includes('Employer Number')) {
                        let searchText = "that";
                        let indexrname = text.indexOf(searchText)
                        let regName = text.slice(indexrname+5).split('\n')[0]
                        let searchRegNum = "Employer Number"
                        let indexrnum = text.indexOf(searchRegNum)
                        let regnum = text.slice(indexrnum+16).split('.')[0]
                        form.set({...form.data, 
                            name: {val: regName, stat: false, msg: ''},
                            number: {val: regnum, stat: false, msg: ''},
                            regFile: {
                                photo: {...form.data.regFile.photo, val: URL.createObjectURL(_file)},
                                file: {...form.data.regFile.file, val: e.target.files}
                            },
                        })
                        ocrLoader.set(false)
                        modal.set({...modal.data, regForm : {val : true}, sampleImg : {val : false} })
                    }else{
                        ocrLoader.set(false)
                        form.set({...form.data, 
                            regFile:{
                                photo:{val:URL.createObjectURL(_file), stat:true, msg:''}, 
                                file:{val:e.target.files, stat:true, msg:'Image scan failed: We are unable to read the photo you have selected. Please upload a clearer image or verify that it is the intended registration document.'}
                            }
                        })
                        modal.set({...modal.data, regForm : {val : true}, sampleImg : {val : false} })
                    }
                })
            }else{
                modal.set({...modal.data, regForm : {val : true}, sampleImg : {val : false} })
            }
        }else{
            message.set('Uploading is restricted to image and PDF file formats exclusively.')
            errOpen.set(true)
            return
        }
    }
    
    return (
        <>
            <Modal open={choices.data.show} className="noutlined">
                <Slide in={choices.data.show} direction="up" className="noutlined">
                    <Box height="100%" width="100%" display="flex" flexDirection="column">
                        <Box height="100%" width="100%" bgcolor="#3333331F" onClick={() => handlerCloseChoises()}>
                        
                        </Box>
                        <Box minHeight={160} width="100%" bgcolor="#FFFFFF" borderRadius="24px 24px 0px 0px" p={2} display="flex" alignItems="center" justifyContent="space-around" flexDirection="column">
                            <Box border="1.5px solid #313131" width="24px" sx={{opacity: 0.2}}/>
                            <Box fontWeight={400} fontSize={14} lineHeight="26px" >
                                {choices.data.val.name}
                            </Box>
                            <Box fontWeight={400} fontSize={14} lineHeight="26px" color="#8D98A9">
                                Choose Method
                            </Box>
                            <Box display="flex" justifyContent="space-evenly" width="100%">
                                <Box display="flex" alignItems="center" flexDirection="column">
                                    <Box height={40} width={40} border="1px solid #8D98A9" textAlign="center" borderRadius="8px" p={1} component={motion.div} whileTap={{ backgroundColor: '#E2EDE7'}}>
                                        <input accept="image/*,.pdf" type="file" id="camera" hidden capture="camera" onChange={(e) => onChangeFile(e)} />
                                        <label htmlFor="camera">
                                            <img src={camera} alt={camera} />
                                        </label>
                                    </Box>
                                    <Box fontSize={10} fontWeight={400} lineHeight="17px" mt={1}>Camera</Box>
                                </Box>
                                <Box display="flex" alignItems="center" flexDirection="column">
                                    <Box height={40} width={40} border="1px solid #8D98A9" textAlign="center" borderRadius="8px" p={1} component={motion.div} whileTap={{ backgroundColor: '#E2EDE7'}}>
                                        <input accept="image/*,.pdf" type="file" id="file" hidden onChange={(e) => onChangeFile(e)} />
                                        <label htmlFor="file">
                                            <img src={file} alt={file} />
                                        </label>
                                    </Box>
                                    <Box fontSize={10} fontWeight={400} lineHeight="17px" mt={1}>Files</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Slide>
            </Modal>
            {errOpen.data && (
                <ErrorAlert errOpen={errOpen.data} setErrOpen={errOpen.set} text={message.data} duration={3000}/>
            )}
        </>
    )
}
