import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import { ConfirmRemove, Contracts, FileInfo, NoFound, Registration, ThreeDotsMenu } from '../../global/DesktopDsg';
import { HandlerThreeDots, HandlerView } from "../../global/Function";
import { NewSuccessAlert } from "../../../../core/global/forms/CstmModal";
import { rqx } from "../../../../core/request/API";
import { env } from "../../../../core/Env";
import { Loader } from "../../Loader";
import { Download, DownloadPDF } from "../../../../core/transmit/S3";
import { NotifCount } from "../../../../core/request/Notif";

export const List = ({rcntList, isLoading, regActive, starActive, listView, show, conActive, inftScroll, __SESSION, match, successOpen, message, showDl, __InitRecentList, openShare, withAccess, shareType, notif_state}) => {
    const nav = useHistory()

    const handlerSeeAll = (tab) => {
        nav.push(`/my-cloud/${tab}/list`)
    }

    const handleUpdate = async(e) => {
        HandlerThreeDots(e, '', '', 'close', regActive, show)
        HandlerThreeDots(e, '', '', 'close', conActive, show)
        nav.push(`/my-cloud/${show.data.data.feature_type === 1 ? 'registrations' : 'contracts'}/${window.btoa(show.data.data.id)}`)
    }

    const HandlerDL = async(e, type) => {
        if (type === 'pdf') {
            let res;
            if (show.data.data.file_type.includes('image')) {
                res = await DownloadPDF(show.data.data.file_link, show.data.data.file_name)       
            }else{
                res = await Download(e, show.data.data.file_link, show.data.data.file_name)
            }
            if (res === 'success') {
                showDl.set(null)
                if (show.data.data.feature_type === 1) {
                    regActive.set({...regActive.data, val : '', openMenu : null})
                }else{
                    conActive.set({...conActive.data, val : '', openMenu : null})
                }
                message.set('Successfully download')
                successOpen.set(true)
            }
        }else{
            let res = await Download(e, show.data.data.file_link, show.data.data.file_name)
            if (res === 'success') {
                showDl.set(null)
                if (show.data.data.feature_type === 1) {
                    regActive.set({...regActive.data, val : '', openMenu : null})
                }else{
                    conActive.set({...conActive.data, val : '', openMenu : null})
                }
                message.set('Successfully download')
                successOpen.set(true)
            }
        }
    }

    const HandlerDelete = async(type) => {
        if (!show.data.remove.loader) {
            if (type === 'alert') {
                show.set({...show.data, remove:{...show.data.remove, val:true}})
            }else if(type === 'cancel'){
                show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}})
            }else{
                show.set({...show.data, remove:{...show.data.remove, loader:true}})
                let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/${show.data.data.feature_type === 1 ? 'registration' : 'contracts'}/update`, {
                    id:show.data.data.id, 
                    user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                    status:4, 
                    ref:'trash'
                })
                if (res.message === 'success') {
                    await NotifCount([{file_id:show.data.data.id, file:show.data.data.feature_type === 1 ? 'reg' : 'con'}], __SESSION, 'delete')
                    message.set('Successfully removed')
                    successOpen.set(true)
                    if (show.data.data.feature_type === 1) {
                        regActive.set({...regActive.data, val : '', openMenu : null})
                    }else{
                        conActive.set({...conActive.data, val : '', openMenu : null})
                    }
                    __InitRecentList( isLoading, __SESSION, rcntList)
                    setTimeout(() => {
                        show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}, view:false})
                    }, 1000);
                }
            }
        }
    }

    const handlerShare = async(val) => {
        if (show.data.data.access !== null || show.data.data.access !== undefined ) {
            shareType.set('details')
        }
        openShare.set(true)
        regActive.set({...regActive.data, val : '', openMenu :  null})
        conActive.set({...conActive.data, val : '', openMenu :  null})
        show.set({...show.data, data : val, view : false, fullview : false})
        // withAccess.set({...withAccess.data, val: [], loader: true})
        // let res = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/access/read`, val.feature_type === 1 ?  {registration_id: val.id} : {contracts_id: val.id})
        // withAccess.set({...withAccess.data, val: res, loader: false})
    }
    
    return (
        <Box height="100%" width="100%" p="40px" className="overflowY">
            {
                (isLoading.data ? 
                    <Loader />
                :
                    (rcntList.data.contracts.length === 0 && rcntList.data.reg.length === 0 ?
                        <NoFound msg="No File found. Upload Now!" />
                    :
                        <>
                            <Box display="flex" justifyContent="space-between" mb={3}>
                                <Box fontSize={14} fontWeight={600} lineHeight="16px">Registrations</Box>
                                {
                                    rcntList.data.reg.length === 4 &&
                                        <Box fontSize={14} fontWeight={600} lineHeight="19px" color="#107038" className="c-pointer" onClick={() => handlerSeeAll('registrations')}> See all</Box>
                                }
                            </Box>
                            {
                                rcntList.data.reg.length === 0 ?
                                    <NoFound msg="No File found. Upload Now!" />
                                :
                                    <Registration data={rcntList.data.reg} regActive={regActive} starActive={starActive} show={show} listView={listView} inftScroll={inftScroll} match={match} __SESSION={__SESSION} state={rcntList} successOpen={successOpen} message={message} withAccess={withAccess}
                                    />
                            }
                            <Box display="flex" justifyContent="space-between" mt={4} mb={3}>
                                <Box fontSize={14} fontWeight={600} lineHeight="16px">Contracts</Box>
                                {
                                    rcntList.data.contracts.length === 4 &&
                                        <Box fontSize={14} fontWeight={600} lineHeight="19px" color="#107038" className="c-pointer" onClick={() => handlerSeeAll('contracts')} >See all</Box>
                                }
                            </Box>
                            {
                                rcntList.data.contracts.length === 0 ?
                                    <NoFound msg="No File found. Upload Now!" />
                                :
                                    <Contracts data={rcntList.data.contracts} conActive={conActive} starActive={starActive} show={show} listView={listView} inftScroll={inftScroll} match={match} __SESSION={__SESSION} state={rcntList} successOpen={successOpen} message={message} withAccess={withAccess} />
                            }
                        </>)
                )
            }
            {
                (Boolean(regActive.data.openMenu) || Boolean(conActive.data.openMenu)) &&
                    <ThreeDotsMenu open={Boolean(regActive.data.openMenu) || Boolean(conActive.data.openMenu)} anchorEl={regActive.data.openMenu || conActive.data.openMenu} 
                        onClose={(e) => HandlerThreeDots(e, '', '', 'close', regActive.data.openMenu !== null ? regActive : conActive, show)}
                        viewInfo={() => HandlerView(show.data.data, show, regActive.data.openMenu !== null ? regActive : conActive, withAccess)}
                        share={() => handlerShare(show.data.data)}
                        update={(e)=>handleUpdate(e)}
                        download={HandlerDL}
                        remove={() => HandlerDelete('alert')}
                        show={show}
                    />
            }
            {
                show.data.view &&
                    <FileInfo show={show} match={match} __SESSION={__SESSION} state={rcntList} successOpen={successOpen} message={message} stateActive={regActive.data.openMenu !== null ? regActive : conActive}
                        withAccess={withAccess} handlerShare={handlerShare} notif_state={notif_state}
                    />
            }
            <NewSuccessAlert successOpen={successOpen.data} setSuccessOpen={successOpen.set} text={message.data} /> 
            <ConfirmRemove show={show} HandlerDelete={HandlerDelete}/>
        </Box>
    )
}