import { Box, Button, IconButton, Modal } from "@mui/material"
import { ArrowLeft } from "../../../../../core/global/Icons"
import { Details } from "./Details"
import { UseStyles } from "../../../../mycloud/global/DesktopDsg"
import { SelectDept } from "./SelectDept"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { useContext, useEffect, useState } from "react"
import { ToolContext } from "../../../../../core/context/ToolContext"
import { rqx } from "../../../../../core/request/API"
import { env } from "../../../../../core/Env"
import { ErrorAlert } from "../../../../../core/global/forms/CstmModal"
import { Review } from "./Review"
import { SnakeLoader } from "../../../../../core/loader/SnakeLoader"
import { db } from "../../../../../db/db"
import { S3Upload } from "../../../../../core/transmit/S3"
import { useDropzone } from "react-dropzone"

export const  SIndex = ({ deptStaticData, match }) => {
    const { policy_state, tool_state } = useContext(ToolContext)
    const { plcysn, errorOpen, message, saveLoader, pName, plcyData, successOpen, polForm } = policy_state
    const { __SESSION } = tool_state
    let company_id = __SESSION.data.uaccess[0].company_id;
    const dsg = UseStyles()

    const nav = useHistory()
    const [open, setOpen] = useState(false)

    const handleClose  = () => {
        if ( atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0') {
            nav.push('/ubr?type=select')
        }else{
            polForm.set({
                departmentname: {id:0, name:''},
                file_name: '',
                file_size: '',
                file_type: '',
                file_link: '',
                policyname: '',
                description: '',
                dateupload: new Date(),
                dateissued: '',
                preparedby: {id:0, uname:''},
                approvedby: {id:0, uname:''},
                file: '',
            });
            nav.push('/policies/list')
        }
    }
    
    const __seriesNum = async() => {
        let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/policy/series_num`, {
            company_id: __SESSION.data.uaccess[0].company_id 
        })
        plcysn.set(res)
    }

    let stat 
    if (polForm.data.departmentname === '' || polForm.data.file_name === '' || polForm.data.policyname === '' || polForm.data.description === '' || polForm.data.dateissued === '' || polForm.data.preparedby === '' || polForm.data.approvedby === '' || pName.data ) {
        stat = true
    } else {
        stat = false
    }

    const onDrop = async(files) => {
        if (files.length === 0) {
            message.set('Only PDF file formats are allowed for upload.')
            errorOpen.set(true)
            return
        }
        
        let sizelmt = 4194304
        if (files[0].size > sizelmt) {
            message.set('File upload must not exceed 3MB.')
            errorOpen.set(true)
            return
        }
        
        let _file = files[0];
        polForm.set({...polForm.data, file: files, file_name: _file.name, file_size: _file.size, file_type: _file.type, file_link: URL.createObjectURL(_file)})
        // setForm({...form, file: {name: _file.name, size: _file.size, type: _file.type, link: URL.createObjectURL(_file)}})     
    }

    const {getRootProps, getInputProps} = useDropzone({onDrop, accept: { 'application/pdf' : ['.pdf'] } })

    const handlerBtn = async(type) => {
        if (type === 'next') {
            if (!stat) {
                nav.push('/policies/review')
            }
        }else if (type === 'edit') {
            nav.push('/policies/create')
        }
        else {
            saveLoader.set(true)
            let res_s3 = await S3Upload(polForm.data.file)
            
            
            if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0') {
                //UBR PROCESS
                let data = {
                    dept_name: 'All',
                    description: polForm.data.description,
                    policy_name: polForm.data.policyname,
                    policyname: polForm.data.policyname,
                    policy_num: "PLCY-000000001",
                    stat:false,
                    status : 1,
                    file_link:res_s3.location,
                    file_name:"POFSIS Admin Access v1.pdf",
                    file_size:2605928,
                    file_type:"application/pdf",
                    dept_id:polForm.data.departmentname.id,
                    dateupload:polForm.data.dateupload,
                    dateissued:polForm.data.dateissued,

                }

                db.pol.add(data);
                plcyData.set([data])
                successOpen.set(true)
                message.set('Successfully Saved.')
                nav.push('/policies/list')
                saveLoader.set(false)
            }else{
                let body = {...polForm.data,
                    file_link:res_s3.location,
                    company_id:__SESSION.data.uaccess[0].company_id,
                    user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                    dept_id:polForm.data.departmentname.id
                }
                let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/policy/create`, body)
                if (res.message === 'success') {
                    successOpen.set(true)
                    message.set('Successfully Saved.')
                    polForm.set({
                        departmentname: {id:0, name:''},
                        file_name: '',
                        file_size: '',
                        file_type: '',
                        file_link: '',
                        policyname: '',
                        description: '',
                        dateupload: new Date(),
                        dateissued: '',
                        preparedby: {id:0, uname:''},
                        approvedby: {id:0, uname:''},
                        file: '',
                    });
                    nav.push('/policies/list')
                    saveLoader.set(false)
                }else {
                    nav.push('/policies/create')
                    errorOpen.set(true)
                    pName.set(true)
                    message.set('Policy Name already exist.')
                    saveLoader.set(false)
                }
            }
        }
    }

    const __init = async() => {
        const getAllUsers = await rqx.get(`<<global>>/${env()}/tooluser/gettooluser`, {company_id: company_id !== null ? company_id : 0, tool_id: process.env.REACT_APP_TID, ref: 'active' })
        let samap = getAllUsers.superadmin?.map(v => {return {...v, uname:v.first_name+' '+v.last_name }} )
        let umap = getAllUsers.users?.map(v => {return {...v, uname:v.first_name+' '+v.last_name }} )
        setAllUsers([...samap, ...umap])
    }

    const [allUsers, setAllUsers] = useState([])
    useEffect(() => {
        const __intialize = () => {
            if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0') {
                setAllUsers([{id:1, uname:'Guest'}])
                polForm.set({...polForm.data, departmentname: {id:0, name:'All'}, preparedby: 1, approvedby: 1})
            }else{
                __init()
                __seriesNum()
            }
        }
        __intialize()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {
                errorOpen.data && (
                    <ErrorAlert errOpen={errorOpen.data} setErrOpen={errorOpen.set} text={message.data} duration={3000}/>
                )
            }
            <Modal open={true}>
                <Box height="100%" width="100%" bgcolor="#F0F5F9" display="flex" flexDirection="column">
                    <Box minHeight="86px" p="12px 16px" width="100%">
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <IconButton sx={{p: '0px'}} onClick={handleClose}>
                                <ArrowLeft stroke="#0000008a" width={16} height={16} />
                            </IconButton>
                            <Box display="flex" justifyContent="center" fontSize="16px" fontWeight={600} lineHeight="26px">Upload Policy</Box>
                            <Box></Box>
                        </Box>
                        <Box py="12px" display="flex" flexDirection="row" justifyContent="space-between" gap={1} fontSize="12px" fontWeight={400} lineHeight="19px">
                            <Box width="100%" display="flex" alignItems="center" flexDirection="column" color={match.params.params !== 'review' ? '#107038' : '#737373'}>
                                <Box>Details</Box>
                                <Box height="4px" width="100%" bgcolor={match.params.params !== 'review' ? '#107038' : '#737373'} borderRadius="12px"></Box>
                            </Box>
                            <Box width="100%" display="flex" alignItems="center" flexDirection="column" color={match.params.params === 'review' ? '#107038' : '#737373'}>
                                <Box>Review</Box>
                                <Box height="4px" width="100%" bgcolor={match.params.params === 'review' ? '#107038' : '#737373'} borderRadius="12px"></Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box height="100%" widthh="100%" bgcolor="#FFFFFF" borderRadius="12px 12px 0px 0px" p="0px 16px 16px 16px" sx={{overflowY: 'auto'}}>
                        <Box display="flex" justifyContent="center" my="11px">
                            <Box height="2px" width="37px" bgcolor="#EAEFF7"></Box>
                        </Box>
                        {
                            match.params.params === 'review' ?
                            <Review form={polForm.data} plcysn={plcysn} allUsers={allUsers}/>
                        :
                            <Details dsg={dsg} form={polForm.data} setForm={polForm.set} match={match} setOpen={setOpen} __SESSION={__SESSION} plcysn={plcysn} handlerBtn={handlerBtn} pName={pName} allUsers={allUsers} getRootProps={getRootProps} getInputProps={getInputProps}/>
                        }
                    </Box>
                    <Box minHeight="76px" p="16px" bgcolor="#FEFEFE" boxShadow="0px 0px 24px rgba(0, 0, 0, 0.08)">
                        {
                            match.params.params === 'review' ?
                            <Box display="flex" alignItems="center" gap="9px">
                                <Button variant="contained" fullWidth disableElevation
                                    sx={{ bgcolor: '#E2EDE7', border: '1px solid #107038', color: '#107038', borderRadius: '6px', '&:hover': { bgcolor: '#E2EDE7', color: '#107038', }  }} onClick={saveLoader.data ? null : () => handlerBtn('edit')}
                                >
                                    Edit
                                </Button>
                                <Button variant="contained" fullWidth disableElevation
                                    sx={{ borderRadius: '6px', zIndex: 1102 }} onClick={() => handlerBtn('save')} 
                                >
                                {
                                    saveLoader.data ? 
                                        <Box display="flex" alignItems="center">
                                            <Box mr="10px">Saving</Box>
                                            <SnakeLoader size="5px" bg="#FFFFFF" distance="0.1rem" />
                                        </Box>
                                    :
                                        'Save'
                                }
                                </Button>
                            </Box>
                        :
                            <Button variant="contained" fullWidth disableElevation onClick={() => handlerBtn('next')}
                                sx={{backgroundColor: stat && '#C7DAD7' , '&:hover': { backgroundColor: stat && '#C7DAD7' }  }} 
                            >
                                Next
                            </Button>
                        }
                        {/* <Button variant="contained" fullWidth width="100%">Next</Button> */}
                    </Box>
                    {atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0' && (
                        <SelectDept open={open} setOpen={setOpen} deptStaticData={deptStaticData} form={polForm.data} setForm={polForm.set}/>
                    )}
                </Box>
            </Modal>
        </>
    )
}