import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Backdrop, Modal, Fade } from '@mui/material';
import { Buttons } from '../../core/global/forms/Button';
import Cafgu from '../../assets/images/core/cafgu.svg'

export const Profiling = ({type}) => {
    const history = useHistory();
    const [open, setOpen] = useState(true);
    const handleClose = async() => {
        if (type === 'GM') {
            history.push('/general-maintenance/company-setup');
        }else{
            history.push('/profiling?account=setup');
        }
        setOpen(false)
    };
    
    return (  
        <Modal
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={open}>
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <Box position="absolute" width={type === 'GM' ? 490 : 443} height={600}>
                        <Box borderRadius={5} borderColor="#ffffff" p={3} bgcolor="white" className="shadow">
                            <Box display="flex" justifyContent="center">
                                {type === 'GM' ? 
                                    <>
                                        <Box color="primary.main" fontSize={24} mr={1}>Welcome to </Box>
                                        <Box fontSize={24} component="strong">General Maintenance!</Box> 
                                    </>
                                    :
                                    <>
                                        <Box color="primary.main" fontSize={29} mr={1}>Welcome to </Box>
                                        <Box fontSize={29}>POF<Box component="strong">Business!</Box></Box>
                                    </>  
                                }
                            </Box>
                            {type === 'GM' ? 
                                <Box display="flex" justifyContent="center">
                                    <Box mt="15px" fontSize={15}>
                                        Set up your company details for a better experience.
                                    </Box>
                                </Box>
                            : 
                                <>
                                    <Box mt="15px" fontSize={15}>
                                        I am Cafgu, your business coach, I will guide you on 
                                        your journey to grow your extraordinary business.
                                    </Box>
                                </>
                            }
                            
                          
                            <Box my={3} textAlign="center">
                                <img src={Cafgu} width="230px" height="222px" alt="cafgu" />
                            </Box>
                            <Box fontSize={ type === 'GM' ?14:16} display="flex" justifyContent="center">
                                {type === 'GM' ? 
                                    <Box>All information will appear to your transaction reports.</Box>
                                :
                                   <> <Box>To continue,</Box> <Box color="primary.main" px={.5}> set up your account </Box> <Box> details first.</Box></>
                                }
                            </Box>
                        </Box>  
                        <Box display="flex" justifyContent="center" mt="30px" >
                            <Buttons event={handleClose} variant="contained" borderRadius="8px" color="primary" width="226px" height="48px" fontSize="16px" text="Continue"/>
                        </Box>  
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}