import { Box } from "@mui/material"
import { CList } from "./list/Index"
import { CForm } from "./form/Index"
import { rqx } from "../../../../core/request/API"
import { env } from "../../../../core/Env"
import { useContext, useEffect, useState } from "react"
import { ToolContext } from "../../../../core/context/ToolContext"

export const Category = ({ match, categoryType }) => {
    const { tool_state, speakout_state } = useContext(ToolContext)
    const { ctgyData, isLoading, infntScroll } = speakout_state
    const { __SESSION } = tool_state

    const [ctgyForm, setCtgyForm] = useState([{
        id: 0,
        category_num: `CTGRY-000000001`,
        categoryname: '',
        type: '',
        active: 1
    }])

    const __init = async(val) => {
        if (val === 0) {
            isLoading.set(true)
            infntScroll.set(true)
        }

        if (val !== undefined) {
            let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/scategory/read`, {
                company_id: __SESSION.data.uaccess[0].company_id,
                filter: 'all'
            })
            let result = res.map(pd => ({...pd }))
            ctgyData.set(result)
        }
        isLoading.set(false)
    }

    useEffect(() => {
        const __initialize = () => {
            if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') {
                ctgyData.set([]);
                __init(0, '')
            }
        }
        __initialize()
        // eslint-disable-next-line
    }, [])

    return (
        <Box height="100%" width="100%" >
        {
            match.params === 'create' || match.params === 'update' ?
            <Box height="100%" width="100%" borderRadius="12px" display="flex" flexDirection="row">
                <CForm match={match} ctgyData={ctgyData} ctgyForm={ctgyForm} setCtgyForm={setCtgyForm} __init={__init} categoryType={categoryType}/>
            </Box>
            :
            <Box height="100%" width="100%" bgcolor="#FFFFFF" borderRadius="12px" border="1px solid #EBEBEB">
                <CList ctgyData={ctgyData} isLoading={isLoading} __SESSION={__SESSION} ctgyForm={ctgyForm} setCtgyForm={setCtgyForm} />
            </Box>
        }
        </Box>
    )
}