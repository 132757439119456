import { Box, IconButton, Modal, Slide, Typography } from "@mui/material"
import { useState } from "react"
import { useEffect } from "react"
import { env } from "../../../../../core/Env"
import { ArrowLeft } from "../../../../../core/global/Icons"
import { rqx } from "../../../../../core/request/API"
import { motion } from "framer-motion"
import { ToolFeaturesLoader } from "../../../loader/MidLoader"
import moment from "moment"

export const Document = ({hs, __SESSION, nav}) => {
    const [loader, setLoader] = useState(true)
    const [subscribe, setSubscribe] = useState(true)
    const left = [ {title: 'Categories', name: ''}, {title: 'Registration', name: 'registrations'}, {title: 'Contracts', name: 'contracts'} ]
    const _headerTop = [{title: 'Total', size: 50}, {title: 'Non-Expiring', size: 100}, {title: 'Expiring', size: 80}, {title: 'Nearly Expired', size: 100}, {title: 'Expired!', size: 80}]
    const _reg = [{value: hs.registrations.data.total, size: 50}, {value: hs.registrations.data.non_exp, size: 100}, {value: hs.registrations.data.expiring, size: 80}, {value: hs.registrations.data.nearly, size: 100}, {value: hs.registrations.data.expired, size: 80}]
    const _con = [{value: hs.contracts.data.total, size: 50}, {value: hs.contracts.data.non_exp, size: 100}, {value: hs.contracts.data.expiring, size: 80}, {value: hs.contracts.data.nearly, size: 100}, {value: hs.contracts.data.expired, size: 80}]

    const __redirect = (val) => {
        if (val !== '') {
            nav.push(`/my-cloud/${val}/list`);
            hs.showDocu.set(false)
        }
    }

    useEffect(() => {
        const __init = async() => {
            let start_date = new Date(hs.dateToday.data)
            let end_date = new Date(hs.dateToday.data)
           
            start_date.setDate(start_date.getDate() + 1)
            end_date.setDate(end_date.getDate() + 7)
           
            let con = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/dashboard/contracts`, {ref:'reports', company_id: __SESSION[0].company_id, start: moment(start_date).format('YYYY-MM-DD'), end: moment(end_date).format('YYYY-MM-DD'), today: hs.dateToday.data})
            hs.contracts.set((prev) => ({...prev, nearly: con.nearly, expired: con.expired, expiring: con.expiring, total: con.expiring}));
            // hs.contracts.set({...hs.contracts.data, nearly: con.nearly, expired: con.expired, expiring: con.expiring, total: con.expiring});
            
            let reg = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/dashboard/registration`, {ref: 'reports', company_id: __SESSION[0].company_id, start: moment(start_date).format('YYYY-MM-DD'), end: moment(end_date).format('YYYY-MM-DD'), today: hs.dateToday.data })
            hs.registrations.set((prev) => ({...prev, nearly: reg.nearly, expired: reg.expired, expiring: reg.expiring, non_exp: reg.nonexpiring, total: reg.expiring + reg.nonexpiring}));
            
            // hs.registrations.set({...hs.registrations.data, nearly: reg.nearly, expired: reg.expired, expiring: reg.expiring, non_exp: reg.nonexpiring, total: reg.expiring + reg.nonexpiring});

            setLoader(false)
        }
        if (subscribe) {
            if (hs.dateToday.data !== undefined) {
                __init()
            }
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [hs.dateToday.data])
    
    return (
        <Modal open={hs.showDocu.data}>
            <Slide in={hs.showDocu.data} direction="up">
                <Box height="100%" width="100%" bgcolor="#F0F6F3" display="flex" flexDirection="column">
                    <Box minHeight={56} p={2} bgcolor="#ffffff" display="flex"  alignItems="center">
                        <IconButton sx={{p: '0px !important', position:'absolute'}} onClick={() =>hs.showDocu.set(false)}>
                            <ArrowLeft />
                        </IconButton>
                        <Typography fontSize={14} fontWeight={600} textAlign="center" width="100%">Documents</Typography>
                    </Box>
                    <Box mt="12px" bgcolor="#ffffff" p="14px 12px" width="100%">
                        {loader ?
                            <ToolFeaturesLoader __SESSION={__SESSION}/>
                        :
                            <>
                                <Box fontSize={14} fontWeight={500} mb="17px">Documents</Box>
                                <Box display="flex" width="100%">
                                    <Box display="flex" flexDirection="column" minWidth={120}>
                                        {left.map((v, k) => (
                                            <Box display="flex" alignItems="center" p="8px 10px"  bgcolor={ k === 0 ? "#F0F6F3" : '#ffffff'} borderRadius="4px 0px 0px 4px" height={ k === 0 ? 29 : 36} border="1px solid #ECECEC" mb={1} key={k} component={motion.div} whileTap={{ backgroundColor: '#F0F6F3'}} onClick={() => __redirect(v.name)}>
                                                <Typography fontSize={14} fontWeight={500}>{v.title}</Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box width="100%" sx={{overflowX: 'scroll'}} className="scrollXColor scrollXCustom">
                                        <Box display="flex" width="100%" mb={1}>
                                            {_headerTop.map((v, k) => (
                                                <Box display="flex" alignItems="center" p="8px 10px"  bgcolor="#F0F6F3" borderRadius="4px 0px 0px 4px" height={29} borderTop="1px solid #ECECEC" borderBottom="1px solid #ECECEC" key={k}>
                                                    <Typography fontSize={14} fontWeight={500} width={v.size} textAlign="center">{v.title}</Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                        <Box display="flex" width="100%" mb={1}>
                                            {_reg.map((v, k) => (
                                                <Box display="flex" alignItems="center" p="8px 10px"  borderRadius="4px 0px 0px 4px" height={36} borderTop="1px solid #ECECEC" borderBottom="1px solid #ECECEC"  key={k} component={motion.div} whileTap={{ backgroundColor: '#F0F6F3'}} onClick={() => __redirect('registrations')}>
                                                    <Typography fontSize={14} fontWeight={500} width={v.size} textAlign="center">{v.value}</Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                        <Box display="flex" width="100%" mb={1}>
                                            {_con.map((v, k) => (
                                                <Box display="flex" alignItems="center" p="8px 10px"  borderRadius="4px 0px 0px 4px" height={36} borderTop="1px solid #ECECEC" borderBottom="1px solid #ECECEC"  key={k} component={motion.div} whileTap={{ backgroundColor: '#F0F6F3'}} onClick={() => __redirect('contracts')}>
                                                    <Typography fontSize={14} fontWeight={500} width={v.size} textAlign="center">{v.value}</Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        }
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}