import { Avatar, Box, IconButton, Typography } from "@mui/material"
import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core"
import { FavStarMbl, VeriticalThreeDots } from "../../../../../../core/global/Icons"
import { motion } from "framer-motion"
import moment from "moment"
import FolderIcon from "../../../../../../assets/images/icons/folder.png"
import { FileExtension } from "../../../../global/FileExtension";

export const ListView = ({favList, handlerView, handlerThreeDots}) => {
    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" gap="8px">
            {favList.data.map((v,k) => (
                <Box key={k} >
                    <Box p={1} boxShadow=" 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02)" display="flex" alignItems="center" flexDirection="row" bgcolor="#FFFFFF" borderRadius="6px" component={motion.div} whileTap={{ backgroundColor: '#f0f0f0' }}>
                        {v.folder_id === undefined ?
                            <Box display="flex" gap="12px" width="92%">
                                <Box display="flex" alignItems="center" minHeight={40} minWidth={40} onClick={() => handlerView(v)}>
                                    {
                                        JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('pdf') ?
                                            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                <Box sx={{maxHeight: '40px', width:'40px', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                                    <Box component={Viewer} fileUrl={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} defaultScale={SpecialZoomLevel.PageFit} />
                                                </Box>
                                            </Worker>
                                        : JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('image') ?
                                            <Box sx={{backgroundImage: `url(${JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link})`, backgroundSize: 'cover',
                                                backgroundPosition: 'center center', height: '40px', width: '40px', backgroundRepeat: 'no-repeat', borderRadius: '6px' }} 
                                            />
                                        :
                                            <Box height="100%" width="40px" display="flex" alignItems="center" justifyContent="center">
                                                <FileExtension link={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} width={40} height={40}/>
                                            </Box>
                                    }
                                </Box>
                                <Box width="80%" onClick={() => handlerView(v)}>
                                    <Typography fontSize={12} fontWeight={600} lineHeight="19px" width="100%"  noWrap>{v.type === 4 ? v.name: `${v.type_name} (${v.name})`} </Typography>
                                    <Box display="flex" alignItems="center">
                                        {
                                            v.user_favorite_status === 2  &&
                                                <FavStarMbl fill={v.user_favorite_status === 2 ? '#11783C' : 'none'} />
                                        }
                                        <Box ml={v.user_favorite_status === 2 && 1} fontSize={12} fontWeight={400} lineHeight="23px"> 
                                            {
                                                v.end_date === null ?
                                                    'Non-Expiring'
                                                :
                                                    `Expiring • ED: ${moment(v.end_date).format('MM/DD/YYYY')}`
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        :
                            <Box display="flex" gap="12px" width="92%">
                                <Box display="flex" alignItems="center" minHeight={40} minWidth={40} onClick={() => handlerView(v)}>
                                    <Avatar src={FolderIcon} variant="square" alt="folder-img" sx={{width:40, height:40}}/>
                                </Box>
                                <Box width="80%" onClick={() => handlerView(v)}>
                                    <Typography fontSize={12} fontWeight={600} lineHeight="19px" width="100%"  noWrap>{v.folder_name}</Typography>
                                    <Box display="flex" alignItems="center">
                                        {
                                            v.user_favorite_status === 2  &&
                                                <FavStarMbl fill={v.user_favorite_status === 2 ? '#11783C' : 'none'} />
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        }
                        <Box display="flex" alignItems="center" minHeight={20} minWidth={20}> 
                            <IconButton sx={{p: '4px 6px 8px 4px'}} onClick={() => handlerThreeDots(v)}>
                                <VeriticalThreeDots fill={"#737373"} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    )
}