import { useState } from "react"
import { VM } from "../VM"


let view = VM()
export const PersonalizationState = () => {
    const [personalizationData, setPersonalizationData] = useState([])

    //FOR PROFILING
    const [isDonep, setIsDonep] = useState(false)

    //FOR WELCOME TOUR PERSONALIZATION
    const [isShowWp, setIsShowWp] = useState(true)

    //FOR TOUR
    const [isTourOpen, setIsTourOpen] = useState(false)
    const [isSteps, setIsSteps] = useState(
        view !== '' ? 
            [
                {target:'.first-step' , name:'Dashboard', desc:'Your transaction summary.', content:[], disableBeacon: true, placement: "top-start", },
                {target:'.second-step' , name:'B Cloud', desc:'Save and organize your business documents.', content:[], placement: "top-start", },
                {target:'.third-step' , name:'General Maintenance', desc:'All tool set ups are stored and maintained here.', content:[], placement: "top-start"},
                {target:'.fourth-step' , name:'Tool Categories', desc:'Tools you need for your business growth.', content:[], placement: "top-end"},
                {target:'.fifth-step' , name:'Profile', desc:'Here you can find information about your profile, account settings, and other features.', content:[], placement: "left-end"},
                {target:'.sixth-step' , name:'Personal', desc:'Switch to Personal to grow your career.', content:[], placement: "bottom"},
                {target:'.seventh-step' , name:'Business', desc:'Switch to Business to grow your business.', content:[], placement: "bottom"},
                // {target:'.seventh-step' , name:'My Tools', desc:'Access your assigned tools here.', content:[], placement: "right"},
                // {target:'.eighth-step' , name:'My Points', desc:'Your total earned points.', content:[], disableBeacon: true,},
                // {target:'.ninth-step' , name:'Daily Tasks', desc:'Complete daily tasks to earn more points..', content:[], placement: "top"},
            ]
        :
            [
                {target:'.first-step' , name:'Dashboard', desc:'Your transaction summary.', content:[], disableBeacon: true, placement: "right", },
                {target:'.second-step' , name:'B Cloud', desc:'Save and organize your business documents.', content:[], placement: "right", },
                {target:'.second-step-first' , name:'Policies', desc:'Manage and organize your business policies.', content:[], placement: "right", },
                {target:'.second-step-second' , name:'General Maintenance', desc:'All tool set ups are stored and maintained here.', content:[], placement: "right"},
                {target:'.third-step' , name:'Tool Categories', desc:'Tools you need for your business growth.', content:[], placement: "right"},
                {target:'.fourth-step' , name:'Refer a Hero User', desc:'Earn points by referring a hero user!', content:[], placement: "right"},
                // {target:'.fifth-step' , name:'Updates', desc:'POFBusiness system updates.', content:[], placement: "right"},
                {target:'.sixth-step' , name:'Get app for desktop', desc:'Download app for easy access.', content:[], placement: "right-end"},
                {target:'.seventh-step' , name:'Market Place', desc:'Purchase tools you need for your business growth.', content:[], placement: "right-end"},
                {target:'.eighth-step' , name:'Personal', desc:'Switch to Personal to grow your career.', content:[],  placement: 'auto'},
                {target:'.ninth-step' , name:'Business', desc:'Switch to Business to grow your business.', content:[], placement: "auto"},
                // {target:'.tenth-step' , name:'My Tools', desc:'Access your purchased tools here.', content:[]},
                // {target:'.tenth-step' , name:'My Points', desc:'Your total earned points.', content:[], placement: "left"},
                // {target:'.eleventh-step' , name:'Daily Tasks', desc:'Complete daily tasks to earn more points.', content:[], placement: "right"},
            ]
    ) 
    // const [isSteps, setIsSteps] = useState([
    //     {target:'.first-step' , name:'Dashboard', desc:'Your transaction summary.', content:[], disableBeacon: true, placement: "right", },
    //     {target:'.second-step' , name:'General Maintenance', desc:'All tool set ups are stored and maintained here.', content:[], placement: "right", },
    //     {target:'.third-step' , name:'Tool Categories', desc:'Tools you need for your business growth.', content:[], placement: "right"},
    //     {target:'.fourth-step' , name:'Refer a Hero User', desc:'Earn points by referring a hero user!', content:[], placement: "right"},
    //     {target:'.fifth-step' , name:'Updates', desc:'POFBusiness system updates.', content:[], placement: "right"},
    //     {target:'.sixth-step' , name:'Get app for desktop', desc:'Download app for easy access.', content:[], placement: "right-end"},
    //     {target:'.seventh-step' , name:'Market Place', desc:'Purchase tools you need for your business growth.', content:[], placement: "right-end"},
    //     {target:'.eighth-step' , name:'Personal', desc:'Switch to Personal to grow your career.', content:[], disableBeacon: true,},
    //     {target:'.ninth-step' , name:'My Tools', desc:'Access your purchased tools here.', content:[]},
    //     {target:'.tenth-step' , name:'My Points', desc:'Your total earned points.', content:[], placement: "left"},
    //     {target:'.eleventh-step' , name:'Daily Tasks', desc:'Complete daily tasks to earn more points.', content:[], placement: "right"},
    // ]) 
    
    return {
        personalizationData: {data: personalizationData, set: setPersonalizationData},
        isDonep: {data: isDonep, set: setIsDonep},
        isShowWp: {data: isShowWp, set: setIsShowWp},
        isTourOpen: {data: isTourOpen, set: setIsTourOpen},
        isSteps: {data: isSteps, set: setIsSteps},
    }
}