import { useContext, useEffect, useState } from "react"
import { Backdrop, Box, IconButton } from "@mui/material"
import { GridViewIcon, IconSearch, ListViewIcon, TrashIcon } from "../../../../../core/global/Icons"
import { ToolContext } from "../../../../../core/context/ToolContext"
import { __InitRecentList } from "../../../global/Function"
import { MblFetchingLoader } from "../../../../fetching/Loader"
import { ConfirmRemove, NoFound } from "../../../global/DesktopDsg"
import { Storage } from "./Storage"
import { Overview } from "./Overview"
import { List } from "./list/Index"
// import { ConList } from "./ConList"
import { ThreeDots } from './modal/ThreeDots';
import { MblAlert } from "../../../../../core/global/forms/CstmModal"
import { rqx } from "../../../../../core/request/API"
import { env } from "../../../../../core/Env"
import { useHistory } from "react-router-dom"
import { ShareAccess } from "../shareAccess/Index"

export const Recent = ({match}) => {
    const nav = useHistory()
    const [subscribe, setSubscribe] = useState(true)
    const { cloud_state, tool_state, install_state } = useContext(ToolContext)
    const { __SESSION } = tool_state
    const { listView, rcntAdd, isLoading, regActive, conActive, show, rcntList, csuccessOpen, successOpen, message, storageLoader, storage, openShare, sharedCount } = cloud_state

    const topBtnIcons = [
        {icon: <IconSearch stroke='#313131' />, type: 'search'},
        {icon: listView.data ? <ListViewIcon /> : <GridViewIcon />, type: 'changeview'},
        {icon: <TrashIcon fill="#313131" />, type: 'trash'},
    ]

    const handlerTopBtnIcn = (type) => {
        if (type === 'changeview') {
            listView.set(!listView.data)
        }else if (type === 'trash') {
            nav.push('/my-cloud/trash/list')
        }
    }

    const handlerOpenMenu = (e,val, type) => {
        e.stopPropagation()
        if (type === 'reg') {
            regActive.set({...regActive.data, openMenu: true})
            show.set({...show.data, data : val})
        }else{
            conActive.set({...conActive.data, openMenu: true})
            show.set({...show.data, data : val})
        }
    }

    const handlerCloseMenu = () => {
        regActive.set({...regActive.data, openMenu: null})
        conActive.set({...conActive.data, openMenu: null})
    }

    const HandlerDelete = async(type) => {
        if (!show.data.remove.loader) {
            if(type === 'cancel'){
                show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}})
            }else{
                show.set({...show.data, remove:{...show.data.remove, loader:true}})
                let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/${show.data.data.feature_type === 1 ? 'registration' : 'contracts'}/update`, {
                    id:show.data.data.id, 
                    user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                    status:4, 
                    ref:'trash'
                })
                if (res.message === 'success') {
                    message.set('Successfully removed')
                    show.data.data.feature_type === 1  ? successOpen.set(true) : csuccessOpen.set(true)
                    show.data.data.feature_type === 1  ?
                        regActive.set({...regActive.data, val : '',  openMenu : null })
                    :
                        conActive.set({...conActive.data, val : '',  openMenu : null })
                    
                    __InitRecentList(isLoading, __SESSION, rcntList)
                    setTimeout(() => {
                        show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}, view:false})
                    }, 500);
                }
            }
        }
    }

    useEffect(() => {
        if (subscribe) {
            __InitRecentList(isLoading, __SESSION, rcntList)
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [])

    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column" bgcolor="#F8F9FA">
            <Backdrop sx={{bgcolor: '#FFFFFF', opacity: '0.8 !important', zIndex: 1101}} open={rcntAdd.data} />
            <Box minHeight={44} display="flex" alignItems="center" justifyContent="space-between" px={2}>
                <Box fontSize={16} fontWeight={600} lineHeight="26px">B Cloud</Box>
                <Box display="flex" alignItems="center">
                    {
                        topBtnIcons.map((v,k, d) => (
                            <IconButton sx={{p: '2px', mr: d.length - 1 !== k ? 2 : 0}} key={k} onClick={() => handlerTopBtnIcn(v.type)}>
                                {v.icon}
                            </IconButton>
                        ))
                    }
                </Box>
            </Box>
            <Box height="100%" width="100%" className="overflowY">
                <Box p={2}>
                    <Storage/>
                </Box>
                <Box  bgcolor="#FFFFFF" borderRadius="12px 12px 0px 0px" p={2}>
                    <Box display="flex" justifyContent="center" mt="11px">
                        <Box bgcolor="#333333" height="2px" width="37px" mb={2} />
                    </Box>
                    <Overview storage={storage} storageLoader={storageLoader} sharedCount={sharedCount} />
                    <Box fontSize={14} fontWeight={600} lineHeight="26px" mt={4}>Recently Added</Box>
                    {
                        (isLoading.data ?
                            <Box minHeight={100}>
                                <MblFetchingLoader />
                            </Box>
                        :
                            (rcntList.data.contracts.length === 0 && rcntList.data.reg.length === 0 ?
                                <NoFound msg="No File found. Upload Now!" />
                            :
                                <List cloud_state={cloud_state} listView={listView} handlerOpenMenu={handlerOpenMenu} />
                            )
                        )
                    }
                    {
                        match.feature === 'recent' &&
                            (regActive.data.openMenu || conActive.data.openMenu) &&
                                <ThreeDots cloud_state={cloud_state} tool_state={tool_state} open={regActive.data.openMenu || conActive.data.openMenu} close={() => handlerCloseMenu()} show={show} />
                    }
                </Box>
            </Box>
            <Box minHeight={install_state.prompt.data !== null ? "76px" : "12px"} />
            <MblAlert successOpen={show.data.data.feature_type === 1 ? successOpen.data : csuccessOpen.data} setSuccessOpen={show.data.data.feature_type === 1 ? successOpen.set : csuccessOpen.set} text={message.data} />
            <ConfirmRemove show={show} HandlerDelete={HandlerDelete}/>
            {
                openShare.data &&
                    <ShareAccess />
            }
        </Box>
    )
}
