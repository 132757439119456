import { useHistory } from "react-router-dom"
import { Box, IconButton, Modal, Slide, Typography } from "@mui/material"
import { MblFetchingLoader } from "../../../../../fetching/Loader"
import { Choose } from "../modal/Choose";
import CloseIcon from '@mui/icons-material/Close';

export const SelectRegType = ({modal, listRegName, form, ocrLoader, choices, sufficientStorage, insuffShow, errOpen, message}) => {
    const nav = useHistory()
    
    const handlerClose = () => {
        nav.push('/my-cloud/registrations/list')
    }

    const handlerShowChoices = (v) => {
        if (sufficientStorage.data) {
            insuffShow.set(true)
        }else{
            form.set({...form.data, 
                regName: {val: v.id, stats: false, msg: ''}, 
                regType : { val:  v.name === 'Others' ? 0 : v.type, stat: false, msg: ''}
            })
            choices.set({...choices.data, val: v, show: true})
        }       
    }
   
    return (
        <>
            <Modal open={modal.data.openRegList.val} className="noutlined">
                <Slide in={modal.data.openRegList.val} direction='up' className="noutlined">
                    <Box height="100%" width="100%" bgcolor="#FFFFFF" display="flex" flexDirection="column">
                        <Box minHeight={63} p="21px 16px" borderBottom="1px solid #F1F4F4">
                            <IconButton sx={{p: '0px', minHeight: 20, position: 'absolute'}} onClick={() => handlerClose()}>
                                <CloseIcon sx={{fontSize: 20}} />
                            </IconButton>
                            <Box display="flex" justifyContent="center" fontSize={16} fontWeight={600} lineHeight="26px">
                                Select Registration
                            </Box>
                        </Box>
                        <Box height="100%" width="100%" px={2}>
                            {
                                listRegName.data.length === 0 ?
                                    <MblFetchingLoader mt={0} />    
                                :
                                    listRegName.data.map((v,k) => (
                                        <Typography fontSize={14} fontWeight={400} lineHeight="16px" borderBottom="1px solid #EFF1F4" p="13px 16px" key={k} onClick={() => handlerShowChoices(v)}
                                            sx={{backgroundColor: choices.data.val.id === v.id ? '#E2EDE7' : '#fffff' }}
                                        >
                                            {v.name}
                                        </Typography>
                                    ))
                            }
                        </Box>
                    </Box>
                </Slide>
            </Modal>
            <Choose choices={choices} form={form} modal={modal} ocrLoader={ocrLoader} errOpen={errOpen} message={message} />
        </>
    )
}
