import { Box } from "@mui/material"
import { Top } from "../../global/DesktopDsg"
import { TrashList } from "./list/Index";
import { useContext, useEffect } from "react";
import { ToolContext } from "../../../../core/context/ToolContext";
import { env } from "../../../../core/Env";
import { rqx } from "../../../../core/request/API";
import { NewSuccessAlert } from "../../../../core/global/forms/CstmModal";
import { __InitTrashFiles, __InitTrashFolder } from "../../global/Function";
import { NotifCount } from "../../../../core/request/Notif";

// let uai_user = process.env.REACT_APP_UAI_REGULAR;

export const Trash = ({match}) => {
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { trashFiles, isLoading, inftScroll, limit, lid, show, active, successOpen, message, listView, modalType, selectedFiles, reloadStorage, trashFolder, folderLoader, folderInfi, folderLid, errOpen, limitSelect } = cloud_state
    const { __SESSION } = tool_state 
    
    const __InitTrashFilesList = async() => {
        await __InitTrashFiles(0, isLoading, inftScroll, __SESSION, limit, lid, trashFiles)
    }

    const __InitTrashFolderList = async(val) => {
        await __InitTrashFolder(0, folderLoader, folderInfi, __SESSION, limit, folderLid, trashFolder)
    }
    
    const __LoadmoreFilesData = () => {
        __InitTrashFiles(lid.data, isLoading, inftScroll, __SESSION, limit, lid, trashFiles)
    }
    
    const __LoadmoreFolderData = () => {
        __InitTrashFolder(folderLid.data, folderLoader, folderInfi, __SESSION, limit, folderLid, trashFolder)
    }
    
    const hanlderModalBtn = async(option) => {

        // if (selectedFiles.data.length > limitSelect.data.limit && limitSelect.data.type === '') {
        //     errOpen.set(true)
        //     message.set(`Exceed 10 limits only!` )
        //     show.set((prev) => ({...prev, remove:{...show.data.remove, val:false, loader:false},  view : false, fullview : false}))
        //     return
        // }
        
        if (!show.data.remove.loader) {
            if (option === 'cancel') {
                show.set((prev) => ({...prev, remove:{...show.data.remove, val:false, loader:false}}))
                if (modalType.data === 'empty') {
                    trashFiles.set(trashFiles.data.map((v) => {return {...v, checked : false } } ))
                    selectedFiles.set([])
                }
            }else{
                show.set((prev) => ({...prev, remove:{...show.data.remove, loader:true}}))
                let filterReg = selectedFiles.data.filter((v) => {return v.type === 1})
                let regList = filterReg.map(v => {return {registration_id: v.id}})

                let filterCon = selectedFiles.data.filter((v) => {return v.type === 2})
                let conList = filterCon.map(v => {return {contracts_id: v.id}})

                let filterFldr = selectedFiles.data.filter((v) => {return v.type === 3})
                let fldrList = filterFldr.map(v => {return {folder_id: v.id}})

                let filterDoc = selectedFiles.data.filter((v) => {return v.type === 4})
                let docList = filterDoc.map(v => {return {documents_id: v.id}})

                let res;
                if (selectedFiles.data.length <= 1) {
                    let showtype = show.data.data.type === undefined ? selectedFiles.data[0].type : show.data.data.type
                    if (showtype === 3 || showtype === 4 ) {
                        let body =  {
                            id: show.data.data.id === undefined ? selectedFiles.data[0].id : show.data.data.id, 
                            user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                            status:modalType.data === 'restore' ? 3 : 4, 
                            ref: modalType.data === 'restore' ? 'restore' : 'permanent',
                            filter: showtype === 3 ? 'folder':'file',
                            company_id: __SESSION.data.uaccess[0].company_id,
                        }
                        res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/trash/update`, body)
                    }else{
                        res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/${showtype ===  1 ? 'registration' : 'contracts'}/update`, {
                            id:show.data.data.id, 
                            status:3, 
                            user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                            ref:modalType.data === 'restore' ?  'restore' : 'remove',
                            company_id: __SESSION.data.uaccess[0].company_id  
                        })
                    }
                    let fltrData;
                    if (show.data.data.access === null) {
                        fltrData = [{user_id:0, file:showtype, file_id:show.data.data.id, created_by:show.data.data.created_by}]
                    }else{
                        fltrData = JSON.parse(show.data.data.access).map(v => { return { user_id:v.user_id, file:showtype, file_id:show.data.data.id, created_by:show.data.data.created_by } } )
                    }
                    await NotifCount(fltrData, __SESSION, `${modalType.data === 'restore' ?  'restore' : 'remove'}`)
                }else{
                    // let validate = limitSelect.data.type === 'select' && __SESSION.data.uaccess[0].role_id !== parseInt(uai_user)

                    // let body = {
                    //     filter:validate ? 'select' : '',
                    //     ref:modalType.data === 'restore' ? 'restore' : 'permanent',
                    //     user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                    //     contracts: validate ? [] : conList.length !== 0 ? conList : JSON.parse(`[{"contracts_id":${0}}]`),
                    //     registration: validate ? [] : regList.length !== 0 ? regList : JSON.parse(`[{"registration_id":${0}}]`),
                    //     folder: validate ? [] : fldrList.length !== 0 ? fldrList : JSON.parse(`[{"folder_id":${0}}]`),
                    //     documents: validate ? [] : docList.length !== 0 ? docList : JSON.parse(`[{"documents_id":${0}}]`),
                    //     company_id: __SESSION.data.uaccess[0].company_id,
                    // }
                    // console.log(JSON.stringify(body))
                    // show.set((prev) => ({...prev, remove:{...show.data.remove, val:false, loader:false},  view : false, fullview : false}))
                    // return

                    let body = {
                        ref:modalType.data === 'restore' ? 'restore' : 'permanent',
                        user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                        contracts: conList.length !== 0 ? conList : JSON.parse(`[{"contracts_id":${0}}]`),
                        registration: regList.length !== 0 ? regList : JSON.parse(`[{"registration_id":${0}}]`),
                        folder: fldrList.length !== 0 ? fldrList : JSON.parse(`[{"folder_id":${0}}]`),
                        documents: docList.length !== 0 ? docList : JSON.parse(`[{"documents_id":${0}}]`),
                        company_id: __SESSION.data.uaccess[0].company_id,
                    }

                    res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/trash/remove`, body)

                    let uid  = []
                    selectedFiles.data.map(v => { 
                        return (
                            v.access === null ? 
                            uid.push({user_id:[0], file:v.type, file_id:v.id, created_by:v.created_by}) 
                            :
                                uid.push({user_id:JSON.parse(v.access).map(i => { 
                                    return i.user_id 
                                }), 
                                    file:v.type, file_id:v.id, created_by:v.created_by})
                        )
                    })
                    await NotifCount(uid, __SESSION, `${modalType.data === 'restore' ?  'multi-restore' : 'multi-remove'}`)
                }
                if (res.message === 'success') {
                    selectedFiles.set([])
                    successOpen.set(true)
                    show.set((prev) => ({...prev, remove:{...show.data.remove, val:false, loader:false},  view : false, fullview : false}))
                    active.set({...active.data, val : '', openMenu : null})
                    if (modalType.data === 'restore') {
                        message.set('Successfully restored')
                    }else{
                        message.set('Successfully removed')
                        reloadStorage.set(true)
                    }
                    
                    if (show.data.data.type !== 4) {
                        await __InitTrashFolder(0, folderLoader, folderInfi, __SESSION, limit, folderLid, trashFolder)
                    }
                    await __InitTrashFiles(0, isLoading, inftScroll, __SESSION, limit, lid, trashFiles)
                }
            }
        }
    }

    const handlerRestoreRemove = async(type) => {
        modalType.set(type)
        show.set({...show.data, remove:{...show.data.remove, val:true}, data: selectedFiles.data.length === 1 ? selectedFiles.data[0] : '' })
    }

    const handlerEmptyTrash = (type) => {
        modalType.set(type)
        show.set({...show.data, remove:{...show.data.remove, val:true}})
        // trashFiles.set(trashFiles.data.map((v) => {return {...v, checked : true } } ))
    }
    
    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            __InitTrashFilesList()
            __InitTrashFolderList()
            selectedFiles.set([])
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [])
    
    return (
        <Box display="flex" flexDirection="column" height="100%">
            <Top title="Trash" listView={listView} match={match.feature} trashFiles={trashFiles} trashFolder={trashFolder} selectedFiles={selectedFiles}
                changeView={() => listView.set(!listView.data)} 
                restore={() => handlerRestoreRemove('restore')} 
                perma={() => handlerRestoreRemove('perma')} 
                empty={() => handlerEmptyTrash('empty')} 
                active={show.data.remove.val && modalType.data === 'empty'}
                limitSelect={limitSelect}
            />
            <TrashList 
                __SESSION={__SESSION}                
                show={show} 
                active={active}  
                modalType={modalType}
                selectedFiles={selectedFiles}
                hanlderModalBtn={hanlderModalBtn}
                errOpen={errOpen}
                message={message}
                limitSelect={limitSelect}
                // FOLDER
                __LoadmoreFolderData={__LoadmoreFolderData} 
                __InitTrashFolderList={__InitTrashFolderList}  
                trashFolder={trashFolder} 
                // Files
                trashFiles={trashFiles} 
                __LoadmoreFilesData={__LoadmoreFilesData} 
                __InitTrashFilesList={__InitTrashFilesList}  
            />
          
            <NewSuccessAlert successOpen={successOpen.data} setSuccessOpen={successOpen.set} text={message.data} /> 
        </Box>
    )
}