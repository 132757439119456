import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDropzone } from "react-dropzone"
import { Box, IconButton, Modal, Slide } from '@mui/material';
import { ArrowLeft } from '../../../../../core/global/Icons';
import { ToolContext } from '../../../../../core/context/ToolContext';
import { List } from './list/Index';
import { rqx } from '../../../../../core/request/API';
import { env } from '../../../../../core/Env';
import { ErrorAlert, NewSuccessAlert } from '../../../../../core/global/forms/CstmModal';
import { ConfirmRemove } from '../../../global/DesktopDsg';
import { ShareAccess } from '../shareAccess/Index';
import { Add } from './Add';
import { NewFolder } from './modal/NewFolder';
import { Uploading } from './modal/Uploading';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { NotifCount } from '../../../../../core/request/Notif';

export const Documents = ({match}) => {
    const nav = useHistory()
    const urlParams = new URLSearchParams(window.location.search)
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { listView, reloadStorage, successOpen, message, show, active, fileInfi, fileLoader, fileLid, docuFile, errOpen, accessLoader, openShare, openMaxFile, selectedFiles, addMenu, uploading } = cloud_state
    const { __SESSION } = tool_state
    let user_id = atob(__SESSION.data.ainfo.aid).split('_', 2)[1];
    let company_id = __SESSION.data.uaccess[0].company_id
    let uai_user = process.env.REACT_APP_UAI_REGULAR;

    const [open, setOpen] = useState(true)

    const handlerDocBack = () => {
        nav.push(`/my-cloud/${match.feature}/list`)
    }

    const __InitDocuFile = async(val, type) => {
        if (val === 0) {
            fileLoader.set(true)
            fileInfi.set(true)
        }
        let qsp = { 
            feature: match.feature,
            lastId: val, 
            limit: type === 'folder' ? 100 : 10, 
            user_id: user_id, 
            ref: type, 
            user_type: __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? 'admin' : 'regular_user', 
            company_id: company_id, 
            parent_id: match.params === 'list' ? '' : window.atob(urlParams.get('f_id')), 
            status: 1
        }

        let res_data = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/documents/read`, qsp)
        if (res_data) {
            let res = res_data.map(v => { 
                return {...v, 
                    feature_type: type === 'folder'?3:4, 
                    currFname:type === 'folder'?v.folder_name:v.file_name.split('.')[0],
                    file_name:type === 'folder' ? '' : v.file_name.split('.')[0],
                    file_extension: type === 'folder' ? '' : v.file_name.split('.')[1]
                } 
            })
            if (type === 'folder') {
                docuFile.set(res);
                return
            }

            let l_id;
            for (let i = 0; i < res_data.length; i++) { l_id = res_data[i].id }
            fileLid.set(l_id)
            
            docuFile.set((prev) => [...prev, ...res]);

            if (res_data.length === 0) {
                fileLoader.set(false)
                fileInfi.set(false)
                return
            }
            fileLoader.set(false)
        }
    }

    const HandlerDelete = async(option) => {
        if (!show.data.remove.loader) {
            if (option === 'cancel') {
                show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}})
            }else{
                show.set({...show.data, remove:{...show.data.remove, loader:true}})
                let prms = show.data.data.feature_type === 3 ? {folder_id:show.data.data.id}:{documents_id: show.data.data.id}
                let body = {
                    ref:'trash',
                    filter: show.data.data.feature_type === 3 ? 'folder' : 'file',
                    status:4, 
                    user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                    ...prms
                }
                let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/documents/remove`, body)
                if (res.message === 'success') {
                    await NotifCount([{file_id:show.data.data.feature_type === 3 ? show.data.data.folder_id : show.data.data.id, file:show.data.data.feature_type === 3 ? 'folder' : 'file'}], __SESSION, 'delete')
                    message.set('Successfully removed')
                    successOpen.set(true)
                    let fltr = docuFile.data.filter(v => { return show.data.data.id !== v.id || v.feature_type !== show.data.data.feature_type })
                    docuFile.set(fltr)
                    show.set({...show.data, remove:{...show.data.remove, val:false, loader:false},  view : false, fullview : false})
                    active.set({...active.data, val : '', openMenu : null})
                }
            }
        }
    }

    const onDrop = async(files) => {
        let totalFileSize = 0;  
        for (let i = 0; i < files.length; i++) {
            totalFileSize += parseInt(files[i].size)
        }
        if (totalFileSize > 25 * 1024 * 1024) {
            openMaxFile.set(true)
        }else{
            if (files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    selectedFiles.set((prev) => prev.concat({progress: 0, file_name: files[i].name, file_type: files[i].type, file_size: files[i].size, link: URL.createObjectURL(files[i]), root: Array(files[i]) }))
                }
                addMenu.set(null)
                uploading.set({stat:true, type:'file'})
            }
        }
    }

    const {getRootProps, getInputProps} = useDropzone({onDrop, noClick: true})

    const handlerBack = () => {
        setOpen(false)
        setTimeout(() => {
            reloadStorage.set(true)
            nav.push('/my-cloud/recent/list')
        }, 200);
    }
    
    const handlerFileBack = (v) => {
        //SUBFNAME
        let subfname = urlParams.get('subfname')
        let indx = subfname.indexOf(v)
        let subfnameUrl = subfname.slice(0, indx+v.length)
        //SUBFID
        let subfid = urlParams.get('subfid')
        let arrSubfname = subfnameUrl.split('/').length
        let arrSubfidFltr = subfid.split('/').filter((i,k) => { return k + 1 === arrSubfname })
        let indxfid = subfid.indexOf(arrSubfidFltr[0])
        let fnal = subfid.slice(0, indxfid+arrSubfidFltr[0].length)
        
        nav.push(`/my-cloud/${match.feature}/folder?subfname=${subfnameUrl}&subfid=${fnal}&f_id=${arrSubfidFltr[0]}&f_name=${v}`)
    }

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (match.params === 'folder' && urlParams.get('f_id') === null) {
                nav.push(`/my-cloud/${match.feature}/list`)
            }else{
                const __init = async() => {
                    await __InitDocuFile(0, 'folder')
                    await __InitDocuFile(0, 'file')
                }
                __init()
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line 
    }, [urlParams.get('f_id'), match.params, match.feature, accessLoader.data])

    return (
        <>
            <Modal open={open} className="noutlined" sx={{height:'100% !important', width:'100% !important'}}>
                <Slide in={open} direction="left" sx={{height:'100% !important', width:'100% !important'}} className="noutlined">
                    <Box height="100%" width="100%" bgcolor="#ffffff" display="flex" flexDirection="column">
                        <Box minHeight="44px" maxHeight="44px" display="flex" alignItems="center" justifyContent="space-between" p="12px 16px">
                            <IconButton onClick={() => handlerBack()}>
                                <ArrowLeft stroke="#313131" width="16" height="16" />
                            </IconButton>
                            <Box fontWeight={600} lineHeight="26px">{match.feature === 'shared-access' ? 'Shared Documents' : 'My Documents'}</Box>
                            <IconButton onClick={() => listView.set(!listView.data)}>
                                {/* {listView.data ? <ListViewIcon /> : <GridViewIcon />} */}
                            </IconButton>
                        </Box>
                        <Box px={1} minHeight="30px" maxHeight="100px" className='overflowY cstmscroll'>
                            <Box display="flex" flexWrap="wrap" fontSize={14}>
                                <Box display="flex" alignItems="center" >
                                    <Box component={urlParams.get('subfname') === null ? "" : "u"} onClick={() => handlerDocBack()} 
                                    color={urlParams.get('subfname') === null ? "#333333" : "#A2A3A9"} 
                                    sx={{':hover':{color:`#333333`}}}
                                    fontWeight={urlParams.get('subfname') === null ? 700 : 400}
                                    className="c-pointer">{'Documents'}</Box>
                                </Box>
                                {
                                    urlParams.get('subfname') !== null &&
                                        urlParams.get('subfname').split('/').map((v,k, d) => (
                                            <Box display="flex" alignItems="center" key={k} flexWrap="wrap" >
                                                <KeyboardArrowRightIcon sx={{fontSize: 20, color:"#A2A3A9" }} />
                                                <Box component={k === d.length - 1 ? "" : "u"} onClick={() => handlerFileBack(v)} 
                                                color={k === d.length - 1 ? "#333333" : "#A2A3A9"} 
                                                fontWeight={k === d.length - 1 ? 700 : 400}
                                                sx={{':hover':{color:`#333333`}}}
                                                className="c-pointer">{v}</Box>
                                            </Box>
                                        ))
                                }
                            </Box>
                        </Box>
                        <Box height="100%" width="100%" className="overflowY noutlined">
                            <List match={match}/>
                        </Box>
                        <Box minHeight="20px" ></Box>
                    </Box>
                </Slide>
            </Modal>
            <NewSuccessAlert successOpen={successOpen.data} setSuccessOpen={successOpen.set} text={message.data} /> {/*  SNACKBAR MESSAGE SUCCESS */}
            <ErrorAlert errOpen={errOpen.data} setErrOpen={errOpen.set} text={message.data} /> {/*  SNACKBAR MESSAGE ERROR */}
            <ConfirmRemove show={show} HandlerDelete={HandlerDelete}/>
            {
                openShare.data &&
                    <ShareAccess />
            }
            {(match.feature === 'documents' && !show.data.view && !show.data.fullview) && (
                <Add match={match} __SESSION={__SESSION} cloud_state={cloud_state} getRootProps={getRootProps} getInputProps={getInputProps} __InitDocuFile={__InitDocuFile} user_id={user_id} company_id={company_id} />
            )}
            <NewFolder __SESSION={__SESSION} cloud_state={cloud_state} user_id={user_id} company_id={company_id} __InitDocuFile={__InitDocuFile} /> {/* MODAL ADD NEW FOLDER */}
            <Uploading uploading={uploading} selectedFiles={selectedFiles} user_id={user_id} show={show} /> {/* UPLOADING FILE MODAL */}
        </>
    )
}
