import { Box } from "@mui/material"
import { Top } from "../../global/DesktopDsg"
import { useContext, useEffect } from "react"
import { ToolContext } from "../../../../core/context/ToolContext"
import { List } from "./List"
import { __InitConList } from '../../global/Function'
import { useHistory } from "react-router"
import { NewSuccessAlert } from "../../../../core/global/forms/CstmModal"
import { FormCon } from "./setup/Index"
import { ShareAccess } from "../share/Index";

export const Contracts = ({match}) => {
    const { cloud_state, tool_state, notif_state } = useContext(ToolContext)
    const { conList, isLoading, inftScroll, limit, lid, conActive, listView, starActive, show, csuccessOpen, message, showDl, openShare, sufficientStorage, insuffShow, withAccess, shareType } = cloud_state
    const { __SESSION } = tool_state 
    const nav = useHistory()
    
    const __LoadmoreData = () => {
        __InitConList(lid.data, isLoading, inftScroll, __SESSION, limit, lid, conList)
    }

    const addHandler = () => {
        if (sufficientStorage.data) {
            insuffShow.set(true)
        }else{
            nav.push(`/my-cloud/contracts/create`)
        }   
    }
    
    const __init = async() => {
       await __InitConList(0, isLoading, inftScroll, __SESSION, limit, lid, conList)
    }
    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            listView.set(false)
            __init()
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [])
    
    return (
        match.params === 'list' ? 
            <Box display="flex" flexDirection="column" height="100%">
                <Top title="Contracts" btnName="Add Contracts" changeView={() => listView.set(!listView.data)} listView={listView} add={() => addHandler()} />
                <List conList={conList} isLoading={isLoading} conActive={conActive} starActive={starActive} show={show} listView={listView} inftScroll={inftScroll} __LoadmoreData={__LoadmoreData} nav={nav} match={match.feature} __SESSION={__SESSION} csuccessOpen={csuccessOpen} message={message} showDl={showDl} __InitConList={__InitConList} limit={limit} lid={lid} openShare={openShare} withAccess={withAccess} shareType={shareType} notif_state={notif_state}
                />
                {
                    openShare.data &&
                        <ShareAccess match={match.feature} />
                }
                <NewSuccessAlert successOpen={csuccessOpen.data} setSuccessOpen={csuccessOpen.set} text={message.data} /> 
            </Box>
        :
            <FormCon match={match.params}/>
    )
}
