import { updateBusinessTriggerV2 } from '../../graphql/mutations'
// import { listBusinessTriggerV2s } from '../../graphql/queries'
import { API, graphqlOperation } from "aws-amplify";

export const NotifCount = async(uid, __SESSION, action) => {
    let data = {company_id:__SESSION.data.uaccess[0].company_id, access:uid, action:action }
    const res = await API.graphql(graphqlOperation(updateBusinessTriggerV2, {input: {id: process.env.REACT_APP_RTID, ifRun: true, uid:JSON.stringify(data)} } ))
    return res
    // const res = await API.graphql(graphqlOperation(updateBusinessTriggerV2, {input: {id: '1793dc10-3a1c-48fc-b385-38956d0a2437', ifRun: true, uid:'0' } } ))
    // const res = await API.graphql(graphqlOperation(listBusinessTriggerV2s, {filter: {ifRun: {eq: stat}  }} ))
    // await API.graphql(graphqlOperation(createBusinessTriggerV2, {input: {ifRun: true} } ))
    // return {
    //     res,
    //     a
    // }
    // if (res.data.listBusinessTriggerV2s.items[0] === undefined) {
    //     await API.graphql(graphqlOperation(createBusinessTriggerV2, {input: {count: 1, email: form.email, userId: 0} } ))
    // }else{
    //     await API.graphql(graphqlOperation(updateBusinessTriggerV2, {input: {count: res.data.listBusinessTriggerV2s.items[0].count + 1, email: form.email, id: res.data.listPtbpersonal_notifications.items[0].id, userId: 0} } ))
    // }
}

export const RemoveAccessTrigger = async(comp_id, uid) => {
    let data = {company_id:comp_id, user_id:uid }
    const res = await API.graphql(graphqlOperation(updateBusinessTriggerV2, {input: {id: process.env.REACT_APP_RTVAID, ifRun: true, uid:JSON.stringify(data)} } ))
    return res
}