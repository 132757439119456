import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Avatar, Box, Button, Divider, List, ListItemButton } from '@mui/material';
import { useContext } from 'react';
import { ToolContext } from '../../../../core/context/ToolContext';
import { rqx } from '../../../../core/request/API';
import { domain, env } from '../../../../core/Env';
import { LogoutIcons, SupportIcons } from '../../../../core/global/Icons';

const tool_id =  process.env.REACT_APP_TID;

export const Index = () => {
    const __LOCATION = useLocation().pathname
    const nav = useHistory()
    const { navigation_state, tool_state, personalization_state, install_state, notif_state } = useContext(ToolContext)
    const { tc, 
        // openRef 
    } = navigation_state 
    const { openNotif } = notif_state
    const ps = personalization_state
    // eslint-disable-next-line
    const [tourData, setTourData ] = useState('')
    const pinfo = tool_state.__SESSION.data.pinfo[0]
    // eslint-disable-next-line
    const [showTourProfile, setShowTourProfile] = useState(false)
    const [openRefer, setOpenRefer] = useState({
        refer:false,
        new:false,
    })
    
    const handleClick = async(e, name) => {
        console.log()
        if (name === 'Get App for Desktop' || name === 'Get App for Mobile') {
            install_state.ip.set({stat: true, prompt_type: 'install'})
        }else if(name === 'Support'){
            window.chaport.open();
        }else if(name === 'Tool Categories'){
            nav.push('/tool-categories')    
        }else if(name === 'Refer a Hero Users'){
            // 'NOT APPLICABLE FOR NOW'
            // openRef.set(true)
            // if (openRefer.new) {
            //     const user_id = atob(tool_state.__SESSION.data.ainfo.aid).split('_', 2)[1];
            //     let filter = ps.personalizationData.data.filter(v => {return v.feature_name !== 'biz_refer'} );      
            //     let data = {
            //         user_id:parseInt(user_id), 
            //         tool_id:parseInt(tool_id), 
            //         feature_name:'biz_refer', 
            //         personalization:[
            //             {sf_name:'new_ref', status:1},
            //             {sf_name:'tooltip_ref', status:1},
            //         ] 
            //     };
            //     ps.personalizationData.set([...filter, ...[data]]);
            //     setOpenRefer({...openRefer, new:false, refer:false})
            //     await rqx.post(`<<global>>/${env()}/personalization/create`, data )
            // }
        }else if(name === 'Policies') {
            nav.push('/policies/list')
        }
    }

    const logoutHandler = async () => {
        if (install_state.prompt.data !== null && !('serviceWorker' in navigator && window.matchMedia('(display-mode: standalone)').matches)) {
            install_state.ip.set({stat: true, prompt_type: 'logout'})
        } else {
            localStorage.removeItem('biz_session')
            window.location.href = `${domain('pm').url}/biz/login`
        }
    }
   
    // const closeRefer = async(e) => {
    //     e.stopPropagation()
    //     const user_id = atob(tool_state.__SESSION.data.ainfo.aid).split('_', 2)[1];
    //     let __res = ps.personalizationData.data.filter(v => {return v.feature_name === 'biz_refer'} );
    //     let filter = ps.personalizationData.data.filter(v => {return v.feature_name !== 'biz_refer'} );

    //     let stat = 0
    //     if (__res.length > 0) {
    //         let newref = __res[0].personalization.filter((v) => { return v.sf_name === 'new_ref' && v.status === 1 } );
    //         stat = newref.length === 0 ? 0 : 1
    //     }

    //     let data = {
    //         user_id:parseInt(user_id), 
    //         tool_id:parseInt(tool_id), 
    //         feature_name:'biz_refer', 
    //         personalization:[
    //             {sf_name:'tooltip_ref', status:1},
    //             {sf_name:'new_ref', status:stat},
    //         ] 
    //     };
    //     ps.personalizationData.set([...filter, ...[data]]);
    //     setOpenRefer({...openRefer, refer:false})
    //     await rqx.post(`<<global>>/${env()}/personalization/create`, data )
    // }

    const __init = async() => {
        const user_id = atob(tool_state.__SESSION.data.ainfo.aid).split('_', 2)[1];
        let _res = ps.personalizationData.data.filter(v => {return v.feature_name === 'my-profile'} );
        let filter = ps.personalizationData.data.filter(v => {return v.feature_name !== 'my-profile'} );
        
        if (_res.length === 0) {
            setShowTourProfile(true)
            let data = {
                user_id:parseInt(user_id), 
                tool_id:parseInt(tool_id), 
                feature_name:'my-profile', 
                personalization:[
                    {sf_name:'tour-profile', status:1},
                ] 
            };
            setTourData(data)
            ps.personalizationData.set([...filter, ...[data]]);
            if (tool_state.__SESSION.data.uaddress.length > 0 && JSON.parse(tool_state.__SESSION.data.pinfo[0].other_info).gender !== null) {
                setShowTourProfile(false)
                await rqx.post(`<<global>>/${env()}/personalization/create`, data )
            }
        }else{
            setShowTourProfile(false)
            let data =  _res[0];
            ps.personalizationData.set([...filter, ...[data]]);
        }
    }

    const __initRefer = async() => {
        let __res = ps.personalizationData.data.filter(v => {return v.feature_name === 'biz_refer'} );
        if (__res.length === 0) {
            setOpenRefer({refer:true, new:true})
        }else{
            let tpref = __res[0].personalization.filter((v) => { return v.sf_name === 'tooltip_ref' && v.status === 1 } );
            let newref = __res[0].personalization.filter((v) => { return v.sf_name === 'new_ref' && v.status === 1 } );
            let d = {...openRefer}
            if (tpref.length === 0) {
                d = {...d, refer:true}
            }
            if (newref.length === 0) {
                d = {...d, new:true}
            }
            setOpenRefer(d)
        }

        if (tool_state.__SESSION.data.uaccess[0].role_name !== 'Super Admin') {
            let fleft = navigation_state.tabs.data.msetting.filter(v => { return v.name !== 'Refer a Hero Users'} )
            navigation_state.tabs.set({...navigation_state.tabs.data, msetting:fleft})
        }
    }

    const [subscribe, setSubscribe] = useState(true)
    useEffect(() => {
        if (subscribe) {
            __LOCATION === '/profile' && (
                tc.set({...tc.data, open: false, list: false})
            )
            if (!openNotif.data || (("Notification" in window) && Notification.permission !== 'default')) {
                __init()
                __initRefer()
            }
        } 
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [openNotif.data])

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" gap="37px" bgcolor="#FFFFFF">
            <Box component={Button} onClick={()=>nav.push('/profile')} width="100%" height="91px" py="12px" px="16px" display="flex" justifyContent="flex-start" alignItems="center" gap="16px">
                <Avatar src={
                    JSON.parse(pinfo.profile).profile === null || JSON.parse(pinfo.profile).profile === '' ? 
                        'https://pofsis-business.s3.amazonaws.com/biz-image/1653891687343.jpg' 
                    : 
                        JSON.parse(pinfo.profile).profile 
                    }
                sx={{width:'56px', height:'56px'}}/>
                <Box display="flex" flexDirection="column" alignItems="flex-start" gap="4px" color="#283745">
                    {tool_state.__SESSION.data.pinfo.length === 0 ?
                        <Box fontSize="14px" fontWeight={600} lineHeight="23px">Hero User</Box>
                    :
                        <Box fontSize="14px" fontWeight={600} lineHeight="23px">{pinfo.first_name} {pinfo.last_name}</Box>
                    }
                    <Box fontSize="11px" fontWeight={400} lineHeight="19px">{tool_state.__SESSION.data.ainfo.email}</Box>
                    <Box fontSize="11px" fontWeight={400} lineHeight="19px">{tool_state.__SESSION.data.uaccess[0].role_id === 0 ? 'Business Account' : 'Work Account'}</Box>
                </Box>
            </Box>
            <Box height="100%" className="overflowY cstmscroll">
                <List>
                        {
                            navigation_state.tabs.data.msetting.map((t, k) => (
                                <Box key={k} height="48px" fontSize="14px" fontWeight={400} color="#283745" lineHeight="23px">
                                    <ListItemButton onClick={(e)=>handleClick(e, t.name)}  sx={{px:'16px !important', display:'flex', justifyContent:"space-between", alignItems:'center', gap:'12px'}} >
                                        {/* FOR FUTURE USE 
                                        <Box display="flex" alignItems="center" width="100%" zIndex={1}>
                                            {__LOCATION.split('/')[1] === t.ref ? t.active : t.not_active}
                                            <Box pl="13px" fontSize={14} color="#333333" fontWeight={400}>{t.name}</Box>
                                        </Box>
                                        {(t.name === 'Refer a Hero Users' && openRefer.new) && (
                                            <Box bgcolor="#11783C" height="18px" width="39px" fontSize={10} display="flex" alignItems="center" justifyContent="center" color="#FFFFFF" borderRadius="6px">NEW</Box>
                                        )} */}

                                        <Box display="flex" alignItems="center" gap="12px">
                                            {__LOCATION.split('/')[1] === t.ref ? t.active : t.not_active}
                                            <Box color={t.name === 'Refer a Hero Users' ? '#A2A3A9' : "#283745"}>{t.name}</Box>
                                        </Box>
                                        {(t.name === 'Refer a Hero Users') && (
                                            <Box minWidth="30px" minHeight="15px" bgcolor="#DDE9E7" color="#107038" fontSize="9px" lineHeight="16px" fontWeight={400} borderRadius="6px" textAlign="center" p="4px">
                                                Soon
                                            </Box>
                                        )}
                                        {/* {(t.name === 'Refer a Hero Users' && openRefer.refer) && (
                                            <Box position="absolute" top={50} zIndex={2}>
                                                <Box color="#11783C" position="absolute" top={-14} left={150}><ArrowDropUpIcon/></Box>
                                                <Box sx={{bgcolor:'#11783C', color:'#FFF', borderRadius:'8px', padding:'18px 16px 18px 16px', fontSize:'11px'}}>
                                                    <Box display="flex" alignItems="center" >
                                                        <Box pr="40px">
                                                            You can help POFBusiness to grow by referring a Hero user
                                                        </Box>
                                                        <Box onClick={(e)=>closeRefer(e)}>
                                                            <CloseIcon sx={{height:'14px', width:'14px'}}/> 
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )} */}
                                    </ListItemButton>
                                </Box>
                            ))
                        }
                    </List>
            </Box>
            <Box px="16px">
                <Divider sx={{height:'1px', bgcolor:'#C9D6DF', p:'0px !important'}} />
            </Box>
            <Box height="96px" >
                <Box height="48px" fontSize="14px" fontWeight={400} color="#283745" lineHeight="23px">
                    <ListItemButton onClick={(e)=>handleClick(e, 'Support')} sx={{px:'16px !important', display:'flex', alignItems:'center', gap:'12px'}} >
                        <SupportIcons/>
                        Support
                    </ListItemButton>
                </Box>
                <Box height="48px" fontSize="14px" fontWeight={400} color="#283745" lineHeight="23px">
                    <ListItemButton onClick={(e)=>logoutHandler(e)} sx={{px:'16px !important', display:'flex', alignItems:'center', gap:'12px'}} >
                        <LogoutIcons/>
                        Logout
                    </ListItemButton>
                </Box>
            </Box>
            <Box minHeight={install_state.prompt.data !== null ? "64px" : "0px"} />
        </Box>
    )
}