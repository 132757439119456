import { Box, Divider, IconButton, Menu, MenuItem, Modal, Slide } from '@mui/material';
import { ArrowDown, ImportIcon, ArrowLeft } from '../../../../core/global/Icons';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { useState, useContext } from 'react';
import { ToolContext } from '../../../../core/context/ToolContext';
import { Download, DownloadPDF } from '../../../../core/transmit/S3';
import pdfDL from '../../../../assets/images/icons/pdf.png';
import imgDL from '../../../../assets/images/icons/imgDl.png';
import { FileExtension } from '../../global/FileExtension';

export const FullView = ({show, datas}) => {
    let validate = (show.data.data.access === null || show.data.data.access === undefined || (show.data.data.access !== null && show.data.data.access[0].download))
    const [open, setOpen] = useState(false)
    const { cloud_state } = useContext(ToolContext)
    const { successOpen, message, active } = cloud_state

    const handlerDwnld = async(e, type) => {
        let parse = JSON.parse(show.data.data.file)
        
        for (let i = 0; i < parse.length; i++) {
            let res;
            if (type === 'pdf') {
                if (parse[i].file_type.includes('image')) {
                    res = await DownloadPDF(parse[i].file_link, parse[i].file_name)       
                }else{
                    res = await Download(e, parse[i].file_link, parse[i].file_name)
                }
            }else{
                res = await Download(e, parse[i].file_link, parse[i].file_name)
            }
            if (res === 'success' && i === parse.length - 1 ) {
                setOpen(null)
                active.set({...active.data, val : '', openMenu : null})
                message.set('Successfully download')
                successOpen.set(true)
            }
        }
    }

    const handleClick = async(e) => {
        if (datas.file_type.includes('image')) {
            if (validate) {
                setOpen(e.currentTarget)
            }
        }else{
            await handlerDwnld()
        }
    }

    return (
        <>
            <Modal open={show.data.fullview}   
                sx={{borderColor:'none !important'}}
                onClose={()=> show.set({...show.data, view: true, fullview:false})}>
                <Slide in={show.data.fullview} direction='up'>
                    <Box height="100%" width="100%" display="flex" flexDirection="column"> 
                        <Box minHeight="56px" display="flex" justifyContent="space-between" bgcolor="#313131" px={3} py="20px">
                            <Box display="flex" alignItems="center">
                                <IconButton onClick={()=> show.set({...show.data, view: true, fullview:false})}>
                                    <ArrowLeft stroke='#FFFFFF' />
                                </IconButton>
                                <Box ml={2} color="#FFFFFF" fontSize={14} lineHeight="16px" fontWeight={400}>
                                    {show.data.data.type === 4 ? show.data.data.name : `${show.data.data.type_name} (${show.data.data.name})` }
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center" p="8px 16px" bgcolor="#3A3A3A" borderRadius="6px" className={validate ? 'c-pointer' : '' } 
                                onClick={(e) => handleClick(e)}
                                sx={{'&:hover': {backgroundColor: validate ? '#107038' : ''}, opacity: validate ? 1 : 0.3  }}
                            >
                                <ImportIcon />
                                <Box mx="10px" fontSize={14} lineHeight="16px" fontWeight={600} color="#FFFFFF">{ datas.file_type.includes('image') ? 'Download as' : 'Download'}</Box>
                                { datas.file_type.includes('image') ? <ArrowDown /> : ''}
                            </Box>
                        </Box>
                        <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                            <Box>
                                <Box minHeight="730px" maxHeight="730px" width="100%" className="overflowY">
                                    <Box height="100%" width="100%">
                                        { 
                                            datas.file_type.includes('image')? 
                                                <Box component="img" src={datas.file_link} alt={datas.file_link} height="100%" width="531px" borderRadius="12px" />
                                            : datas.file_type.includes('pdf')? 
                                                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                    <Box sx={{height: '730px', width:'730px', borderRadius:'12px'}}>
                                                        <Box component={Viewer} fileUrl={datas.file_link} defaultScale={SpecialZoomLevel.PageFit} />
                                                    </Box>
                                                </Worker>
                                            :
                                                <FileExtension link={datas.file_link} />
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Slide>
            </Modal>
            <Menu open={validate ? Boolean(open) : null} anchorEl={validate ? open : false} onClose={() => setOpen(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <Box px={1} minWidth={240}>
                    <MenuItem sx={{minHeight: '44px !important', py: 0}} onClick={(e) => handlerDwnld(e, 'pdf')}>
                        <img src={pdfDL} alt={pdfDL} />
                        <Box ml="12px" fontSize={14} fontWeight={400}> PDF </Box>
                    </MenuItem>
                    <Divider sx={{my: '0px !important'}} />
                    <MenuItem sx={{minHeight: '44px !important', py: 0}} onClick={(e) => handlerDwnld(e, 'img')}>
                        <img src={imgDL} alt={imgDL} />
                        <Box ml="12px" fontSize={14} fontWeight={400}> Image </Box>
                    </MenuItem>
                    <Divider sx={{my: '0px !important'}} />
                </Box>
            </Menu>
        </>
    )
}
