import { useContext, useEffect } from "react";
import { Box } from "@mui/material"
import { Top } from "../../global/DesktopDsg";
import { ToolContext } from "../../../../core/context/ToolContext";
import { rqx } from "../../../../core/request/API";
import { env } from "../../../../core/Env";
import { NewSuccessAlert } from "../../../../core/global/forms/CstmModal";
import { FavoritesList } from "./list/Index";

const uai_user = process.env.REACT_APP_UAI_REGULAR;

export const Favorites = ({match}) => {
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { favList, isLoading, inftScroll, limit, lid, successOpen, message, listView,  docuFolder, folderLoader, folderInfi, folderLid} = cloud_state
    const { __SESSION } = tool_state 

    const __LoadmoreFiles = () => {
        __InitFavList(lid.data)
    }
    const __LoadmoreFolders = () => {
        __InitFolderList(folderLid.data)
    }

    const __InitFavList = async(val) => {
        if (val === 0) {
            isLoading.set(true)
            inftScroll.set(true)
        }
        if (val !== undefined) {
            let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/favorites/read`, {
                company_id:__SESSION.data.uaccess[0].company_id, 
                lastId:val, 
                limit:50,
                ref: __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? '' : 'regular_user',
                user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                filter:'file'
            })
            let res_fltr = res.map(v => v.access === null || v.access === undefined ? {...v} : {...v, access : JSON.parse(v.access).filter(f => {return f.user_id === parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) }) } )
            let fltr_files = res_fltr.filter(f => {return f.type !== 3})
            if (res) {
                let l_id;   
                for (let i = 0; i < res.length; i++) { l_id = res[i].updated_at}
                lid.set(l_id);
                if (l_id === 1 || res.length !== 50 ) {
                    inftScroll.set(false);
                }
                if ( val === 0 || val === undefined || val === '') {
                    favList.set(fltr_files);
                }else{
                    favList.set([...favList.data, ...fltr_files]);
                }
                isLoading.set(false);
            }
        }
    }
    
    const __InitFolderList = async(val) => {
        if (val === 0) {
            folderLoader.set(true)
            folderInfi.set(true)
        }
        if (val !== undefined) {
            let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/favorites/read`, {
                company_id:__SESSION.data.uaccess[0].company_id, 
                lastId:val, 
                limit:limit.data,
                ref: __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? '' : 'regular_user',
                user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                filter:'folder'
            })
            let res_fltr = res.map(v => v.access === null || v.access === undefined ? {...v} : {...v, access : JSON.parse(v.access).filter(f => {return f.user_id === parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) }) } )
            // let fltr_fldr = res_fltr.filter(f => {return f.type === 3})
            let fltr_fldr = res_fltr.map(v => { return {...v, feature_type: 3, currFname:v.folder_name} } )
            if (res) {
                let l_id;   
                for (let i = 0; i < res.length; i++) { l_id = res[i].updated_at}
                folderLid.set(l_id);
                if (l_id === 1 || res.length !== limit.data ) {
                    folderInfi.set(false);
                }
                if ( val === 0 || val === undefined || val === '') {
                    docuFolder.set(fltr_fldr)
                }else{
                    docuFolder.set([...docuFolder.data, ...fltr_fldr])
                }
                folderLoader.set(false);
            }
        }
    }

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            __InitFavList(0)
            __InitFolderList(0)
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [])

    return (
        <Box display="flex" flexDirection="column" height="100%">
            <Top title="Favorites" match={match.feature} changeView={() => listView.set(!listView.data)} listView={listView}   />
            <FavoritesList cloud_state={cloud_state} __SESSION={__SESSION} __InitFavList={__InitFavList} __LoadmoreFiles={__LoadmoreFiles} __LoadmoreFolders={__LoadmoreFolders} __InitFolderList={__InitFolderList} />
            <NewSuccessAlert successOpen={successOpen.data} setSuccessOpen={successOpen.set} text={message.data} /> 
        </Box>
    )
}
