import { useState } from "react";
import { Box, IconButton, Modal, Slide } from "@mui/material";
import { TrashIcon, VeriticalThreeDots } from "../../../../core/global/Icons";
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { Info } from "@mui/icons-material";
import { FormatFilesize } from "../../global/DesktopDsg";
import { SnakeLoader } from "../../../../core/loader/SnakeLoader";
import { Timer } from "./Timer";
import { FullView } from "./FullView";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { FileExtension } from "../../global/FileExtension";

export const FileInfo = ({show, __InitTrashFilesList, active}) => {
    const [loader, setLoader] = useState(false)
    const [conData, setConData] = useState({
        file_type:JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_type,
        file_size:JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_size,
        file_link:JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_link
    })
    let conFile = show.data.data.type === 2 && JSON.parse(show.data.data.file)
    let validity_date = `${moment(show.data.data.start_date).format('MM/DD/YYYY')} to ${moment(show.data.data.end_date).format('MM/DD/YYYY')}`
    const con = [
        {name: 'Contract Number', val: show.data.data.series_num},
        {name: 'Contract Name', val: show.data.data.type_name},
        {name: 'Client/Company Name', val: show.data.data.name},
        {name: 'Validity Date', val: validity_date},
    ]

    const reg = [
        {name: 'Registration Number', val: show.data.data.series_num},
        {name: 'Registration Name', val: show.data.data.type_name},
        {name: 'Registration Type', val: show.data.data.end_date !== null ? 'Expiring' : 'Non-Expiring'},
        {name: 'Registered Name', val: show.data.data.name},
        {name: 'Reference Number', val: show.data.data.ref},
    ]

    const doc = [
        {name: 'Document Number', val: show.data.data.series_num},
        {name: 'Document Name', val: show.data.data.name},
    ]

    const file_info = [
        {name: 'File Type', val: conData.file_type },
        {name: 'File Size', val: FormatFilesize(conData.file_size) },
    ]

    const handlerViewTab = async(val) => {
        setLoader(true)
        await setConData({
            file_type:val.file_type,
            file_size:val.file_size,
            file_link:val.file_link
        })
        setTimeout(() => {
            setLoader(false)
        }, 500);
    }

    const HandlerImgView = (e, val) => {
        if (e.detail >= 1) {
            show.set({...show.data, view: true, fullview:true, data: val})
        }
    }

    const handlerThereeDots = (e) => {
        active.set({...active.data, val : '', openMenu : e.currentTarget})
    }
    
    return (
        <>
            <Modal open={show.data.view}>
                <Slide in={show.data.view} direction="up" >
                    <Box height="100%" width="100%" display="flex" flexDirection="column">
                        <Box minHeight={88} display="flex" justifyContent="flex-end" alignItems="center" px={4} onClick={() => show.set({...show.data, view : false, data : ''})}>
                            <IconButton onClick={() => show.set({...show.data, view : false, data : ''})}>
                                <CloseIcon sx={{color: '#FFFFFF'}} />
                            </IconButton>
                        </Box>
                        <Box height="100%" width="100%"  borderRadius="24px 24px 0px 0px" display="flex">
                            <Box height="100%" width="100%" bgcolor="#FFFFFF" borderRadius="24px 0px 0px 0px" display="flex" justifyContent="center" >
                                <Box height="100%" width={{xs:'100%', xl:'883px'}} p="135px 105px 135px 125px" display="flex" flexDirection="column">
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                        <Box display="flex" flexDirection="column">
                                            <Box fontSize={16} fontWeight={600} lineHeight="16px"  >
                                                {
                                                    show.data.data.type === 3 || show.data.data.type === 4 ?
                                                        show.data.data.name
                                                    :
                                                        `${show.data.data.type_name} (${show.data.data.name})`
                                                }
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <TrashIcon fill="#F68511" width="12" height="12" />
                                                <Timer expiration={moment(show.data.data.updated_at).add(30, 'd').format('MM-DD-YYYY HH:mm:ss')} v={show.data.data}  __InitTrashData={__InitTrashFilesList} ftype="file"/>
                                            </Box>
                                        </Box>
                                        <Box display="flex" gap="12px">
                                        {
                                            (show.data.data.access === null || show.data.data.access === undefined || ( show.data.data.access !== null && (show.data.data.access[0].update || show.data.data.access[0].download)) ) && (
                                                <Box 
                                                    display="flex" alignItems="center" justifyContent="center" height={36} width={36} bgcolor="#E2EDE7" borderRadius="6px" className='c-pointer' onClick={(e) => handlerThereeDots(e)}
                                                    pr="4px" pb="4px"
                                                    sx={{border:  `1px solid ${active.data.openMenu !== null ? '#107038' : '#E2EDE7'}`, '&:hover': { border: '1px solid #107038' } }}
                                                >
                                                    <IconButton sx={{p: '0px !important'}}>
                                                        <VeriticalThreeDots fill={active.data.openMenu !== null ? "#107038" : "#737373"} />
                                                    </IconButton>
                                                </Box>
                                            )
                                        }
                                        </Box>
                                    </Box>
                                    <Box height="100%" width="100%" bgcolor="#F8F9FA" p="61px 89px" borderRadius="16px" className="overflowY overflowx c-pointer" onClick={(e) => HandlerImgView(e,show.data.data)}>
                                        { 
                                            loader ? 
                                                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                                                    <SnakeLoader size="1rem" bg="#11783C" distance="0.3rem" />
                                                </Box>
                                            : 
                                                conData.file_type.includes('image')? 
                                                    <img src={conData.file_link} alt={conData.file_link} width="100%" />
                                                : conData.file_type.includes('pdf') ? 
                                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                        <Box sx={{height: '100%', width:'100%'}}>
                                                            <Box component={Viewer} fileUrl={conData.file_link} defaultScale={SpecialZoomLevel.PageFit} />
                                                        </Box>
                                                    </Worker>
                                                :
                                                    <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                                        <FileExtension link={conData.file_link} />
                                                    </Box>
                                        }
                                    </Box>
                                    {show.data.data.type === 2 && (
                                        <Box height="87px" mt="24px" display="flex" justifyContent="center" gap="20px">
                                            {conFile.map((v,k) => (
                                                <Box key={k} className="c-pointer" width="150px" bgcolor="#F8F9FA" border={`1px solid ${conData.file_link === v.file_link ? '#107038' : '#F8F9FA'}`} borderRadius="12px" display="flex" justifyContent="center" onClick={()=> handlerViewTab(v)}>
                                                    {
                                                        v.file_type.includes('image')? 
                                                            <Box component="img" src={v.file_link} alt={v.file_link} minHeight="70px" width="118px" mt="16px" borderRadius="12px 12px 0px 0px" />
                                                        :
                                                            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                                <Box sx={{mt:'16px', maxHeight:'70px', width:'118px', overflow:'hidden !important', borderRadius:'12px 12px 0px 0px'}}>
                                                                    <Box component={Viewer} fileUrl={v.file_link} defaultScale={SpecialZoomLevel.PageFit} />
                                                                </Box>
                                                            </Worker>
                                                    }
                                                </Box>
                                            )).reverse()}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <Box height="100%" minWidth={{md: 320, lg: 380}} bgcolor="#F8F9FA" borderRadius="0px 24px 0px 0px">
                                <Box height="100%" p="80px 24px">
                                    <Box display="flex" alignItems="center" mb="20px">
                                        <Box p="7px" bgcolor="#FFFFFF" borderRadius="6px" display="flex" alignItems="center" mr="20px">
                                            <Info  />
                                        </Box>
                                        <Box fontSize={14} fontWeight={600} lineHeight="16px"> {show.data.data.type === 2 ? 'Contract' : show.data.data.type === 1 ? 'Registration' : 'Document' }  </Box>
                                    </Box>
                                    {
                                        show.data.data.type === 2 ?
                                            con.map((v,k) => (
                                                <Box width="100%" display="flex" alignItems="baseline" mb={k === 3 ? 3 : 2} fontSize={14} fontWeight={400} lineHeight="19px" key={k} >
                                                    <Box width="50%" color="#737373">{v.name}</Box>
                                                    <Box width="50%" color="#333333" sx={{wordBreak: 'break-all'}}>{v.val}</Box>
                                                </Box>
                                            ))
                                        :
                                            show.data.data.type === 1 ?
                                                <>
                                                    {reg.map((v,k, d) => (
                                                        <Box width="100%" display="flex" alignItems="baseline" mb={ (k === d.length - 1 && show.data.data.end_date === null) ? 3 : 2} fontSize={14} fontWeight={400} lineHeight="19px" key={k}>
                                                            <Box width="50%" color="#737373">{v.name}</Box>
                                                            <Box width="50%" color="#333333" sx={{wordBreak: 'break-all'}}>{v.val}</Box>
                                                        </Box>
                                                    ))}
                                                    {
                                                        show.data.data.start_date !== null && show.data.data.end_date !== null &&
                                                            <Box width="100%" display="flex" alignItems="baseline" mb={3} fontSize={14} fontWeight={400} lineHeight="19px">
                                                                <Box width="50%" color="#737373">Validity Date</Box>
                                                                <Box width="50%" color="#333333">{validity_date}</Box>
                                                            </Box>
                                                    }
                                                </>
                                            :   
                                                doc.map((v,k, d) => (
                                                    <Box width="100%" display="flex" alignItems="baseline" mb={ (k === d.length - 1 && show.data.data.end_date === null) ? 3 : 2} fontSize={14} fontWeight={400} lineHeight="19px" key={k}>
                                                        <Box width="50%" color="#737373">{v.name}</Box>
                                                        <Box width="50%" color="#333333" sx={{wordBreak: 'break-all'}}>{v.val}</Box>
                                                    </Box>
                                                ))
                                    }
                                    <Box fontSize={14} fontWeight={600} color="#333333" lineHeight="16px" mb="20px">File Info</Box>
                                    {
                                        file_info.map((v,k) => (
                                            <Box width="100%" display="flex" alignItems="baseline" mb={2} fontSize={14} fontWeight={400} lineHeight="19px" key={k}>
                                                <Box width="50%" color="#737373">{v.name}</Box>
                                                <Box width="50%" color="#333333" sx={{wordBreak: 'break-all'}}>{v.val}</Box>
                                            </Box>
                                        ))
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Slide>
            </Modal>
            {
                show.data.fullview &&
                    <FullView show={show} conData={conData} />
            }
        </>
    )
}
