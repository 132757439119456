import { useState } from "react";
import { Avatar, Box, Button, Checkbox } from "@mui/material"
import { CheckBoxOutlineBlank, CheckBox, KeyboardArrowDown } from '@mui/icons-material';
import { AccessList } from "../menu/AccessList";
import { NoResult } from "../../../../empty/Index";

export const UserList = ({userList, searchData, setSearchData, searchInput, slctAllTxt, usrData, setUsrData}) => {   
    const icon = <CheckBoxOutlineBlank fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;
    const checkedIcon = <CheckBox fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;
    const [anchorEl, setAnchorEl] = useState(null)
    const [idata, setIdata] = useState([])

    const handlerSelectedUser = (e, id) => {
        let data = userList.data.map((v) => v.id === id ? {...v, stat : {...v.stat, view:e.target.checked} } : {...v})
        let fltrdata = data.filter(v => { return !v.stat.view })
        slctAllTxt.set(fltrdata.length === 0 ? true :false)
        userList.set(data)
        setUsrData({...usrData, users: usrData.users.map((v) => v.id === id ? {...v, stat : {...v.stat, view:e.target.checked} } : {...v}) })
        if (searchInput.data !== '') {
            setSearchData(searchData.map((v) => v.id === id ? {...v, stat : {...v.stat, view:e.target.checked} } : {...v}))
        }
    }

    const handlerSelect = (e, checked, value) => {
        if (checked) {
            setIdata(value)
            setAnchorEl(e.currentTarget)
        }
    }  
    
    const handleCheck = (type) => {
        if (type === 'download') {
            if (searchInput.data !== '') {
                setSearchData(
                    searchData.map((i) => i.id === idata.id ? {...i, stat:{...i.stat, download:!i.stat.download}} : {...i} )
                )
            }
            userList.set(
                userList.data.map((i) => i.id === idata.id ? {...i, stat:{...i.stat, download:!i.stat.download}} : {...i} )
            )
        }else{
            if (searchInput.data !== '') {
                setSearchData(
                    searchData.map((i) => i.id === idata.id ? {...i, stat:{...i.stat, update:!i.stat.update}} : {...i} )
                )
            }
            userList.set(
                userList.data.map((i) => i.id === idata.id ? {...i, stat:{...i.stat, update:!i.stat.update}} : {...i} )
            )
        }
    }
   
    return ( 
        (searchInput.data !== '' && searchData.length === 0) || userList.data.length === 0 ?
            <NoResult/>
        :
            (searchInput.data !== '' ? searchData.sort((a, b) => a.first_name.localeCompare(b.first_name)) : userList.data.sort((a, b) => a.first_name.localeCompare(b.first_name))).map((v,k) => (
                <Box display="flex" alignItems="center" justifyContent="space-between" p="8px 16px" key={k}>
                    <Box display="flex" alignItems="center" gap="24px">
                        <Checkbox checked={v.stat.view} icon={icon} checkedIcon={checkedIcon} sx={{p: '0px'}} onChange={(e) => handlerSelectedUser(e, v.id)} />
                        <Box display="flex" alignItems="center">
                            <Avatar src={JSON.parse(v.profile).profile} alt="profile_img" sx={{height: 32, width: 32}} />
                            <Box fontSize={14} fontWeight={400} lineHeight="24px" ml="12px">{v.first_name} {v.last_name}</Box>
                        </Box>
                    </Box>
                    <Button onClick={(e) => handlerSelect(e, v.stat.view, v)}
                        sx={{p: '14px 16px', bgcolor: '#F8F9FA', border: '1px solid #E6E6E6', borderRadius: '6px', color: '#333333', opacity: v.stat.view ? 1 : 0.2,
                            '&:hover': { color: '#107038' } 
                        }}
                    >
                        {v.stat.update && v.stat.download ?
                            ` Update & Download`
                        :
                            v.stat.update ? `View & Update` : v.stat.download ? `View & Download` : 'View'
                        }
                        <KeyboardArrowDown  />
                    </Button>
                    {(v.stat.view && idata.id === v.id) && (
                        <AccessList v={v} handleCheck={handleCheck} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                    )}
                </Box>
            ))
    )
}
