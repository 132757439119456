import { Avatar, Box, Checkbox, IconButton, Typography } from "@mui/material"
import { CheckBoxOutlineBlankOutlined as CheckBoxOutlineBlankOutlinedIcon } from '@mui/icons-material';
import { TrashIcon, VeriticalThreeDots } from "../../../../../core/global/Icons"
import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core"
import { motion } from "framer-motion"
import { Timer } from './Timer'
import { useState } from "react"
import { ThreeDots } from "./menu/ThreeDots"
import { FileInfo } from './FileInfo'
import { ConfirmationTrash } from "../../../global/DesktopDsg"
import { useEffect } from "react"
import { SnakeLoader } from "../../../../../core/loader/SnakeLoader"
import { Waypoint } from "react-waypoint"
import moment from "moment"
import CheckBoxIcon from '../../../../../assets/images/icons/check.png'
import FolderIcon from "../../../../../assets/images/icons/folder.png"
import { FileExtension } from "../../../global/FileExtension";

export const List = ({trashFiles, __LoadmoreData,  show, active, __InitTrashFiles, modalType, selectedFiles, hanlderModalBtn, showSelect, setShowSelect, inftScroll, __SESSION}) => {
    const [data] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0
	});

    const leading0 = (num) => {
        return num < 10 ? "0" + num : num;
	};

    const handlerThreeDots = (e, val) => {
        e.stopPropagation()
        handlerTouchMove()
        active.set({...active.data, openMenu : true})
        show.set({...show.data, data : val})
    }

    const handlerView = (e, val) => {
        e.stopPropagation()
        if (val.type !== 3) {
            handlerTouchMove()
            if (!showSelect) {
                show.set({...show.data, data : val, view : true, fullview : false})
                active.set({...active.data, val : '',  openMenu : false })
            }
        }
    }

    let timer = null;
  
    const handlerTouchMove =() => {
        clearTimeout(timer)
        timer = null
    }

    const handlerSelect = (e, id, v) => {
        e.stopPropagation()
        let type = v.type 
        trashFiles.set(trashFiles.data.map((d) => d.id === id && d.type === type ? {...d, checked : !v.checked} : {...d}))
        if (v.checked) {
            selectedFiles.set(
                selectedFiles.data.filter(d => { return d.id !== v.id || d.type !== v.type })
            )
        }else{
            selectedFiles.set((prev) => [...prev, v])
        }
        // if (v.checked) {
        //     console.log(v)
        //     console.log(selectedFiles)
        //     if (selectedFiles.data.filter((d) => {return d.id === v.id && d.type === v.type} ).length === 0) {
        //         selectedFiles.set([...selectedFiles.data, v])
        //     }
        // }else{
        //     console.log('2')
        //     selectedFiles.set(
        //         selectedFiles.data.filter((d) => d.id === v.id && d.type === v.type ? null : {...d})
        //     )
        // }
    }

    useEffect(() => {
        if (selectedFiles.data.length === 0) {
            setShowSelect(false)
        }
        // eslint-disable-next-line
    }, [selectedFiles.data])

    return (
        <>
            {
                trashFiles.data.map((v,k) => (
                    <Box key={k}>
                        <Box p={1} display="flex" alignItems="center" flexDirection="row" bgcolor="#FFFFFF" borderRadius="6px" component={motion.div} whileTap={{ backgroundColor: '#f0f0f0' }}
                            onClick={(e) => handlerView(e, v)}
                        >
                            <Box display="flex" gap="12px" width="92%">
                                <Box display="flex" alignItems="center" minHeight={40} minWidth={40}>
                                    <Box height="100%" display="flex" flexDirection="column" justifyContent="center" position="relative">
                                        {/* {
                                            showSelect || v.checked || selectedFiles.data.length > 0 ? */}
                                                <Box height={18} display="flex" alignItems="center"   ml="10px" position="absolute" bgcolor={v.checked ? "#FFFFFF" : "transparent"}  borderRadius="50%" zIndex={1001}>
                                                    <Checkbox size="small" sx={{ p: '0px !important'}}
                                                        checked={v.checked}
                                                        onClick={(e) => handlerSelect(e, v.id, v)}
                                                        icon={  <CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 20, color: '#11783C'}} /> } 
                                                        checkedIcon={ <img src={CheckBoxIcon} alt={CheckBoxIcon} /> } 
                                                    />    
                                                </Box>
                                            {/* :   
                                                <Box height={20} position="absolute" />
                                        } */}
                                        { v.type !== 3 ? 
                                            (
                                                JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('image')? 
                                                    <Box sx={{backgroundImage: `url(${JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link})`, backgroundSize: 'cover',
                                                        backgroundPosition: 'center center', height: '40px', width: '40px', backgroundRepeat: 'no-repeat', borderRadius: '6px', zIndex: 1000 }} 
                                                    />
                                                : JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('pdf') ?
                                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                        <Box sx={{maxHeight: '40px', width:'40px', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                                            <Box component={Viewer} fileUrl={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} defaultScale={SpecialZoomLevel.PageFit} />
                                                        </Box>
                                                    </Worker>
                                                :
                                                    <Box height="100%" width="40px" display="flex" alignItems="center" justifyContent="center">
                                                        <FileExtension link={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} width={40} height={40}/>
                                                    </Box>
                                            )
                                        :
                                            <Avatar src={FolderIcon} variant="square" alt="folder-img" sx={{width:40, height:40}}/>
                                        }
                                    </Box>
                                </Box>
                                <Box width="80%">
                                    <Typography fontSize={12} fontWeight={600} lineHeight="19px" width="100%"  noWrap>
                                        {v.type === 4 || v.type === 3 ? 
                                            v.name
                                        :
                                            `${v.type_name} (${v.name})`
                                        }
                                    </Typography>
                                    <Box display="flex" alignItems="center" gap="4px">
                                        <TrashIcon fill={leading0(data.days) < 10 ? "#FA3E3E" : leading0(data.days) < 20 ? "#F68511" : "#A2A3A9" } width="22" height="22" />
                                        <Timer expiration={moment(v.updated_at).add(30, 'd').format('MM-DD-YYYY HH:mm:ss')} v={v} __SESSION={__SESSION}  __InitTrashData={__InitTrashFiles} ftype={v.type !== 3 ? 'file' : 'folder'}/>
                                    </Box>
                                </Box>
                            </Box>
                            {
                                !showSelect && selectedFiles.data.length === 0 &&
                                    <Box display="flex" alignItems="center" minHeight={20} minWidth={20}> 
                                        <IconButton sx={{p: '0px'}} onClick={(e) => handlerThreeDots(e,v)}>
                                            <VeriticalThreeDots fill={"#737373"} />
                                        </IconButton>
                                    </Box>
                            }
                        </Box>
                    </Box>
                ))
            }
            {
                inftScroll.data && (
                    <Box display="flex" justifyContent="center">
                        <Waypoint onEnter={__LoadmoreData} >
                            <Box component="strong" mt={5} display="flex" justifyContent="center">
                                <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                            </Box>
                        </Waypoint>
                    </Box>
                )
            }
            {active.data.openMenu && (
                <ThreeDots active={active} show={show} handlerView={handlerView} modalType={modalType} />
            )}
            {
                show.data.view &&
                    <FileInfo show={show} handlerThreeDots={handlerThreeDots} modalType={modalType} />  // Viewing file
            }
            {
                show.data.remove.val &&
                    <ConfirmationTrash show={show} hanlderModalBtn={hanlderModalBtn} modalType={modalType.data} trashList={selectedFiles}/>
            }
        </>
    )
}
