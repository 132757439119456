import { Avatar, Box, IconButton, Modal, Slide } from '@mui/material';
import { ArrowLeft, TrashIcon, VeriticalThreeDots } from '../../../../../core/global/Icons';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { FormatFilesize } from '../../../global/DesktopDsg';
import { useState } from 'react';
// import { Preview } from './modal/Preview'
import moment from 'moment';
import { motion } from 'framer-motion';
import { Preview } from './Preview';
import { FileExtension } from '../../../global/FileExtension';

export const FileInfo = ({show, handlerThreeDots, modalType}) => {
    const [fileData, setFileData] = useState({
        file_type:JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_type,
        file_size:JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_size,
        file_link:JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_link
    })
    let conFile = show.data.data.type === 2 && JSON.parse(show.data.data.file)
    let validity_date = `${moment(show.data.data.start_date).format('MM/DD/YYYY')} to ${moment(show.data.data.end_date).format('MM/DD/YYYY')}`
    const con = [
        {name: 'Contract Number', val: show.data.data.series_num},
        {name: 'Contract Name', val: show.data.data.type_name},
        {name: 'Client/Company Name', val: show.data.data.name},
        {name: 'Validity Date', val: validity_date},
    ]

    const reg = [
        {name: 'Registration Number', val: show.data.data.series_num},
        {name: 'Registration Name', val: show.data.data.type_name},
        {name: 'Registration Type', val: show.data.data.end_date !== null ? 'Expiring' : 'Non-Expiring'},
        {name: 'Registered Name', val: show.data.data.name},
        {name: 'Reference Number', val: show.data.data.ref},
    ]

    const doc = [
        {name: 'Document Number', val: show.data.data.series_num},
        {name: 'Document Name', val: show.data.data.name},
    ]

    const file_info = [
        {name: 'File Type', val: fileData.file_type },
        {name: 'File Size', val: FormatFilesize(fileData.file_size) },
    ]

    const handlerViewTab = (val) => {
        setFileData({
            file_type:val.file_type,
            file_size:val.file_size,
            file_link:val.file_link
        })
    }

    const handlerImgView = (e, val) => {
        if (e.detail >= 1) {
            show.set({...show.data, view: true, fullview:true, data: val})
        }
    }

    const handlerInTrash = () => {
        modalType.set('perma')
        show.set({...show.data, remove:{...show.data.remove, val:true}})
    }
    
    return (
        <>
            <Modal open={show.data.view} className="noutlined">
                <Slide in={show.data.view} direction='left' className="noutlined">
                    <Box height="100%" width="100%" display="flex" flexDirection="column" bgcolor="#F8F9FA">
                        <Box minHeight={44} p="12px 16px" display="flex" alignItems="center" justifyContent="space-between">
                            <IconButton sx={{p: '0px'}} onClick={() => show.set({...show.data, view : false, data : ''})}>
                                <ArrowLeft stroke="#313131" width="16" height="16" />
                            </IconButton>
                            <Box fontSize={16} fontWeight={600} lineHeight="26px"> {`${show.data.data.type === 4 || show.data.data.type === 3 ? 'Document' : show.data.data.type === 1 ? 'Registration' : 'Contract'} Details`}  </Box>
                            <IconButton sx={{p: '0px'}} onClick={() => handlerThreeDots(show.data.data)}>
                                <VeriticalThreeDots fill={"#737373"} />
                            </IconButton>
                        </Box>
                        <Box p="12px 16px 24px 16px" minHeight={216} className="overflowY overflowx c-pointer">
                            <Box bgcolor="#FFFFFF" minHeight={156} p="24px 24px 0px 24px" onClick={(e) => handlerImgView(e,show.data.data)} display="flex" justifyContent="center" alignItems="center">
                                {
                                    fileData.file_type.includes('image')? 
                                        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                                            <Avatar src={fileData.file_link} alt={fileData.file_link} sx={{height:'auto', width:'100%', borderRadius:'8px'}}/>
                                        </Box>
                                    : fileData.file_type.includes('pdf')? 
                                        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                            <Box sx={{ height: 216, width:'100%', borderRadius:'8px 8px 0px 0px' }}>
                                                <Box component={Viewer} fileUrl={fileData.file_link} 
                                                // defaultScale={SpecialZoomLevel.PageFit} 
                                                />
                                            </Box>
                                        </Worker>
                                    :
                                        <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                            <FileExtension link={fileData.file_link} width={'auto'} height={100}/>
                                        </Box>
                                }
                            </Box>
                        </Box>
                        <Box height="100%" width="100%" bgcolor="#FFFFFF" borderRadius="12px 12px 0px 0px" p={2} className='overflowY'>
                            <Box display="flex" justifyContent="center">
                                <Box height="2px" width="37px" bgcolor="#333333" />
                            </Box>
                            {
                                show.data.data.type === 2 && (
                                    <Box height="56px" mt="16px" display="flex" gap="12px">
                                        {conFile.map((v,k) => (
                                            <Box onClick={()=> handlerViewTab(v)} key={k} className="c-pointer" width="80px" bgcolor="#E6E6E6" border={`1px solid ${fileData.file_link === v.file_link ? '#107038' : '#F8F9FA'}`} borderRadius="6px" display="flex" justifyContent="center">
                                                {v.file_type.includes('image')? 
                                                    <Box sx={{backgroundImage:`url(${v.file_link})`, backgroundSize:"cover", backgroundPosition:"center"}} minHeight="37px" width="62px" mt="10px" borderRadius="6px 6px 0px 0px"/>
                                                :
                                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                        <Box sx={{ mt:'10px', height:'37px', width:'62px', overflow:'hidden !important', borderRadius:'6px 6px 0px 0px'}}>
                                                            <Box component={Viewer} fileUrl={v.file_link} 
                                                            // defaultScale={SpecialZoomLevel.PageFit} 
                                                            />
                                                        </Box>
                                                    </Worker>
                                                }
                                            </Box>
                                        )).reverse()}
                                    </Box>
                                )
                            }
                            <Box fontSize={16} fontWeight={600} lineHeight="25px" pt="20px" pb="32px">
                                {show.data.data.type === 3 || show.data.data.type === 4  ? show.data.data.name : `${show.data.data.type_name} (${show.data.data.name})`}
                            </Box>
                            <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={2}>
                                {`${ show.data.data.type === 3 || show.data.data.type === 4  ? 'Document' : show.data.data.type === 1 ? 'Registration' : 'Contract'} Information`}  
                            </Box>
                            {
                                show.data.data.type === 2 ?
                                    con.map((v,k) => (
                                        <Box width="100%" display="flex" alignItems="baseline" mb={k === 3 ? 3 : 2} fontSize={14} fontWeight={400} lineHeight="19px" key={k} >
                                            <Box width="50%" color="#737373">{v.name}</Box>
                                            <Box width="50%" color="#333333" sx={{wordBreak: 'break-all'}}>{v.val}</Box>
                                        </Box>
                                    ))
                                : show.data.data.type === 1 ?
                                    <>
                                        {reg.map((v,k, d) => (
                                            <Box width="100%" display="flex" alignItems="baseline" mb={ (k === d.length - 1 && show.data.data.end_date === null) ? 3 : 2} fontSize={14} fontWeight={400} lineHeight="19px" key={k}>
                                                <Box width="50%" color="#737373">{v.name}</Box>
                                                <Box width="50%" color="#333333" sx={{wordBreak: 'break-all'}}>{v.val}</Box>
                                            </Box>
                                        ))}
                                        {
                                            show.data.data.start_date !== null && show.data.data.end_date !== null &&
                                                <Box width="100%" display="flex" alignItems="baseline" mb={3} fontSize={14} fontWeight={400} lineHeight="19px">
                                                    <Box width="50%" color="#737373">Validity Date</Box>
                                                    <Box width="50%" color="#333333">{validity_date}</Box>
                                                </Box>
                                        }
                                    </> 
                                :
                                    doc.map((v,k, d) => (
                                        <Box width="100%" display="flex" alignItems="baseline" mb={ (k === d.length - 1 && show.data.data.end_date === null) ? 3 : 2} fontSize={14} fontWeight={400} lineHeight="19px" key={k}>
                                            <Box width="50%" color="#737373">{v.name}</Box>
                                            <Box width="50%" color="#333333" sx={{wordBreak: 'break-all'}}>{v.val}</Box>
                                        </Box>
                                    ))
                            }
                            <Box fontSize={14} fontWeight={600} lineHeight="16px" mt={2} mb={2}>File Info</Box>
                            {
                                file_info.map((v,k) => (
                                    <Box width="100%" display="flex" alignItems="baseline" mb={1} gap="4px" key={k}>
                                        <Box width="50%" color="#737373" fontSize={14} fontWeight={400} lineHeight="19px">{v.name}</Box>
                                        <Box width="50%" color="#333333" fontSize={14} fontWeight={400} lineHeight="19px" sx={{wordBreak: 'break-all'}}>{v.val}</Box>
                                    </Box>
                                ))
                            }
                            
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center" p="8px 8px 30px 8px" bgcolor="#FFFFFF">
                            <Box display="flex" alignItems="center" bgcolor="#F8F9FA" p="4px 12px" onClick={() => handlerInTrash()}
                                borderRadius="6px" component={motion.div} whileTap={{ backgroundColor: '#E2EDE7'}}
                            >
                                <TrashIcon fill='#A2A3A9' />
                                <Box ml="4px" fontSize={12} fontWeight={400} lineHeight="22px">In Trash</Box>
                            </Box>
                        </Box>
                    </Box>
                </Slide>
            </Modal>
            {show.data.fullview && (
                <Preview show={show} fileData={fileData} handlerThreeDots={handlerThreeDots} handlerInTrash={handlerInTrash} />
            )}
        </>
    )
}
