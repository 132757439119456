import React from 'react'
import { Box, Button, IconButton, Modal, Slide } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { StarIcon, VeriticalThreeDots } from '../../../../../core/global/Icons';
import { FormatFilesize } from '../../../global/DesktopDsg';
import { Info } from '@mui/icons-material';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { FullView } from './FullView'
import { FileExtension } from '../../../global/FileExtension';

export const FileInfo = ({show, active, showDl, handlerAddFav}) => {

    const doc = [
        {name: 'Document Number', val: show.data.data.series_num},
        {name: 'Document Name', val: show.data.data.file_name},
    ]

    const file_info = [
        {name: 'File Type', val: show.data.data.file_type},
        {name: 'File Size', val: FormatFilesize(show.data.data.file_size)},
    ]

    const HandlerImgView = (e, val) => {
        if (e.detail >= 1) {
            show.set({...show.data, view: true, fullview:true, data: val})
        }
    }

    const handlerThereeDots = (e) => {
        active.set({...active.data, val : '', openMenu : e.currentTarget})
    }
    return (
        <>
            <Modal open={show.data.view} className="noutlined">
                <Slide in={show.data.view} direction="up" >
                    <Box height="100%" width="100%" display="flex" flexDirection="column">
                        <Box minHeight={88} display="flex" justifyContent="flex-end" alignItems="center" px={4} onClick={() => show.set({...show.data, view : false, data : ''})}>
                            <IconButton onClick={() => show.set({...show.data, view : false, data : ''})}>
                                <CloseIcon sx={{color: '#FFFFFF'}} />
                            </IconButton>
                        </Box>
                        <Box height="100%" width="100%"  borderRadius="24px 24px 0px 0px" display="flex">
                            <Box height="100%" width="100%" bgcolor="#FFFFFF" borderRadius="24px 0px 0px 0px" display="flex" justifyContent="center" >
                                <Box height="100%" width={{xs:'100%', xl:'883px'}} p="135px 105px 135px 125px" display="flex" flexDirection="column">
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                        <Box fontSize={16} fontWeight={600} lineHeight="16px"  >{show.data.data.file_name}</Box>
                                        <Box display="flex" gap="12px">
                                            <Button variant="contained" disableElevation onClick={(e) => handlerAddFav(e, show.data.data)}
                                                sx={{p: '10px 24px', fontSize: 14, fontWeight: 400, lineHeight: '16px', bgcolor: '#F8F9FA', color: '#333333', '&:hover': {bgcolor: '#E2EDE7', color: '#107038'}  }}
                                            >
                                                <StarIcon 
                                                    fill={ show.data.data.user_favorite_status === 2 ? '#11783C' : 'none' } 
                                                    stroke={ show.data.data.user_favorite_status === 2 ? '#11783C' : '#737373' }
                                                />
                                                {
                                                    show.data.data.user_favorite_status === 2 ?
                                                        <Box ml="12px">Revome from Favorites</Box>
                                                    :
                                                        <Box ml="12px">Add to Favorites</Box>
                                                }
                                            </Button>
                                            {
                                                // (show.data.data.access === null || show.data.data.access === undefined || ( show.data.data.access !== null && (show.data.data.access[0].update || show.data.data.access[0].download)) ) && (
                                                    <Box onClick={(e) => handlerThereeDots(e)}
                                                        display="flex" alignItems="center" justifyContent="center" height={36} width={36} bgcolor="#E2EDE7" borderRadius="6px" className='c-pointer'
                                                        pr="4px" pb="4px"
                                                        // sx={{border: `1px solid ${active.data.openMenu !== null ? '#107038' : '#E2EDE7'}`,  '&:hover': { border: '1px solid #107038' } }}
                                                    >
                                                        <IconButton sx={{p: '0px !important'}}>
                                                            <VeriticalThreeDots fill={active.data.openMenu !== null ? "#107038" : "#737373"} />
                                                        </IconButton>
                                                    </Box>
                                                // )
                                            }
                                        </Box>
                                    </Box>
                                    <Box height="100%" width="100%" bgcolor="#F8F9FA" p="61px 89px" borderRadius="16px" className="overflowY overflowx c-pointer" onClick={(e) => HandlerImgView(e,show.data.data)}>
                                        { 
                                            show.data.data.file_type.includes('pdf')? 
                                                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                    <Box sx={{height: '100%', width:'100%'}}>
                                                        <Box component={Viewer} fileUrl={show.data.data.file_link} />
                                                    </Box>
                                                </Worker>
                                            :
                                                show.data.data.file_type.includes('image')? 
                                                    <img src={show.data.data.file_link} alt={show.data.data.file_link} width="100%" />
                                                :
                                                    <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                                        <FileExtension link={show.data.data.file_link} />
                                                    </Box>
                                               
                                        }
                                    </Box>
                                </Box>
                            </Box>
                            <Box height="100%" minWidth={{md: 320, lg: 380}} bgcolor="#F8F9FA" borderRadius="0px 24px 0px 0px">
                                <Box height="100%" p="80px 24px">
                                    <Box display="flex" alignItems="center" mb="20px">
                                        <Box p="7px" bgcolor="#FFFFFF" borderRadius="6px" display="flex" alignItems="center" mr="20px">
                                            <Info  />
                                        </Box>
                                        <Box fontSize={14} fontWeight={600} lineHeight="16px"> Document Info </Box>
                                    </Box>
                                    {
                                        doc.map((v,k) => (
                                            <Box width="100%" display="flex" alignItems="baseline" mb={k === 3 ? 3 : 2} fontSize={14} fontWeight={400} lineHeight="19px" key={k} >
                                                <Box width="50%" color="#737373">{v.name}</Box>
                                                <Box width="50%" color="#333333" sx={{wordBreak: 'break-all'}}>{v.val}</Box>
                                            </Box>
                                        ))
                                    }
                                    <Box fontSize={14} fontWeight={600} color="#333333" lineHeight="16px" mb="20px">File Info</Box>
                                    {
                                        file_info.map((v,k) => (
                                            <Box width="100%" display="flex" alignItems="baseline" mb={2} fontSize={14} fontWeight={400} lineHeight="19px" key={k}>
                                                <Box width="50%" color="#737373">{v.name}</Box>
                                                <Box width="50%" color="#333333" sx={{wordBreak: 'break-all'}}>{v.val}</Box>
                                            </Box>
                                        ))
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Slide>
            </Modal>
            <FullView show={show} showDl={showDl} />
        </>
    )
}
