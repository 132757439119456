import { Box } from "@mui/material"
import { Top } from "./Top"
import { TBody } from "./TBody"
import { useContext } from "react"
import { ToolContext } from "../../../../../core/context/ToolContext"
import { NewSuccessAlert } from '../../../../../core/global/forms/CstmModal'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { Loader } from "../../../../fetching/Loader"
import { NoFound } from "../../../../mycloud/global/DesktopDsg"

export const CList = ({ ctgyData, isLoading, loadMoreData, ctgyForm, setCtgyForm, infntScroll }) => {
    const { speakout_state } = useContext(ToolContext)
    const { successOpen, message } = speakout_state

    const nav = useHistory()

    const handleUpdate = async(e, value, type) => {
        setCtgyForm([{...ctgyForm[0], id: value.id, category_num: value.category_number,categoryname: value.name, type: value.type, active: value.active}])
        nav.push('/speakout/category/update')
    }
 
    return (
        <Box height="100%" width="100%" >
            <Box height="100%" width="100%" display="flex" flexDirection="column" borderRadius="12px" bgcolor="#FFFFFF">
                <Box minHeight="140px">
                    <Top isLoading={isLoading} ctgyData={ctgyData}/>
                </Box>
                {isLoading.data && <Loader />}
                {!isLoading.data && ctgyData.data.length === 0 && <NoFound  msg="No Category Found." />}
                {ctgyData.data.length > 0 && <TBody ctgyData={ctgyData} loadMoreData={loadMoreData} handleUpdate={handleUpdate} infntScroll={infntScroll}/>}
                {
                    successOpen.data &&
                    <NewSuccessAlert successOpen={successOpen.data} setSuccessOpen={successOpen.set} text={message.data}/>
                }

            </Box>
        </Box>
    )
}