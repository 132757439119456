import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router';
import { ToolContext } from '../../../../core/context/ToolContext';
import { rqx } from '../../../../core/request/API';
import { env, domain } from '../../../../core/Env';
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

const ResizePlugin = (slider) => {
    const observer = new ResizeObserver(function () {
      slider.update()
    })
    slider.on("created", () => {
      observer.observe(slider.container)
    })
    slider.on("destroyed", () => {
      observer.unobserve(slider.container)
    })
}

export const Banner = ({linkacct}) => {
    const { tool_state, banner_state, switching_state, gen_main_state } = useContext(ToolContext);
    const { globalLoader } = tool_state
    const __SESSION = tool_state.__SESSION.data.uaccess;
    const listBanner = banner_state.bannerList;
    const gms = gen_main_state;

    const [currentSlide, setCurrentSlide] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const nav = new useHistory();

    const handleClick = async(e, url, name) =>{
        let userId = tool_state.__SESSION.data.ainfo.aid
        if (name === 'market-place') {
            window.location.href = `${domain('mp').url}/sso/account/auth?ainfo=${encodeURI(JSON.stringify(tool_state.__SESSION.data.ainfo))}`
        }else if(name === 'link'){
            let res = await rqx.get(`<<global>>/${env()}/account/getlinkaccount`, {aid:userId, mode:'linkaccount'})
            if (res) {
                if (res.length > 0) {
                    window.location.href = `${domain('psl').url}/sso/account/auth?ainfo=${encodeURI(JSON.stringify(tool_state.__SESSION.data.ainfo))}`
                }else{
                    if (tool_state.__SESSION.data.ainfo.account_type === 3) {
                        window.location.href = `${domain('psl').url}/sso/account/auth?ainfo=${encodeURI(JSON.stringify(tool_state.__SESSION.data.ainfo))}`
                    }else{
                        switching_state.switchForm.set({...switching_state.switchForm.data, stat: true, platform: 'psl'})
                    }
                }
            }
        }else{
            nav.push(url)
        }
    }
    
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        loop: listBanner.data.length > 2 ? true : false,
        created() {
            setLoaded(true)
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        slides: {perView: 1, spacing:10},
        
    },[ResizePlugin]);
    
    const [isShow, setIsShow] = useState(false);
    const [subscribe, setSubscribe] = useState(true)
    
    useEffect(() => {
        if (subscribe) {
            if (!globalLoader.data) {
                let data;
                if (__SESSION[0].role_id !== 0) {
                    let arr = listBanner.data.filter(v => { return v.name !== 'gen-maintenance'});
                    if (tool_state.__SESSION.data.ainfo.account_type === 3 || linkacct.length > 0) {
                        data = arr.filter(v => { return v.name !== 'link'});
                    }else{
                        data = arr
                    }
                }else{
                    if (tool_state.__SESSION.data.ainfo.account_type === 3 || linkacct.length > 0) {
                        data = listBanner.data.filter(v => { return v.name !== 'link'});
                    }else{
                        data = listBanner.data;
                    }
                }
        
                let s_data = data;
                if(tool_state.toolOwned.data.length > 0){
                    s_data = data.filter(v => { return v.name !== 'market-place'});
                }else{
                    if (__SESSION[0].role_id !== 0) {
                        s_data = data.filter(v => { return v.name !== 'market-place'});
                    }
                }
        
                let f_data = s_data;
                if (tool_state.__SESSION.data.pinfo.length > 0) {
                    if (JSON.parse(tool_state.__SESSION.data.pinfo[0].other_info).gender !== null ) {
                        f_data = s_data.filter(v => { return v.name !== 'profile'});
                    }
                }
                
                let comp_profile = f_data;
                if(gms.compinfo.data.length > 0){
                    if(gms.compinfo.data[0].name !== ''){
                        comp_profile = f_data.filter(v => { return v.name !== 'gen-maintenance'});
                    }
                }
                listBanner.set(comp_profile)
                setIsShow(true)
            }
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [linkacct, globalLoader.data])

    return (
        isShow  && (
            (listBanner.data.length > 0 ? 
                <Box width="100%" p="12px 0px 12px 0px">
                    <Box pb="16px" px={1} fontSize="14px">Here's where you can begin your journey.</Box>
                    <Box ref={sliderRef} className="keen-slider" zIndex={0} width="100%" height="140px">
                        {listBanner.data.map((value, index) =>(
                            <Box key={index} bgcolor="#FFFFFF" borderRadius="8px" p="16px" width={'100%'} className={`keen-slider__slide keen-slider__slide_css keen-slider__slidecstm c-pointer`} onClick={(e)=>handleClick(e, value.links, value.name)} >
                                <Box sx={{ backgroundImage: `url(${value.icon})`, backgroundSize: "cover", height:'24px', width:'24px'}}/>
                                <Box pt="13px" fontSize={14} fontWeight={600} color="#00923D">{value.title}</Box> 
                                <Box pt={1} fontSize={10} fontWeight={400}>{value.description}</Box>
                            </Box>
                        ))}
                    </Box>
                    {loaded && instanceRef.current && listBanner.data.length > 1 && (
                        <Box className="dots">
                            {[...Array(instanceRef.current.track.details.slides.length).keys()].map((idx) => {
                                return (
                                    <button
                                        key={idx}
                                        onClick={() => {
                                            instanceRef.current?.moveToIdx(idx)
                                        }}
                                        className={"dot" + (currentSlide === idx ? " active" : "")}
                                    />
                                )
                            })}
                        </Box>
                    )}
                </Box>
            :
                <Box pb="16px"></Box>
            )
        )
    )
}