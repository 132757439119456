import { useContext, useState, useEffect } from "react"
import { Avatar, Box, Button, Divider, IconButton, InputAdornment, TextField } from "@mui/material"
import { KeyboardArrowDown } from "@mui/icons-material"
import { ArrowLeft, ShareIcon, Search } from "../../../../../../core/global/Icons"
import { ToolContext } from "../../../../../../core/context/ToolContext"
import { UserList } from "./UserList"
import { UseStyles } from "../../../../global/DesktopDsg"
import { FilterDept } from './FilterDept'
import { SearchLoader } from '../Loader'

export const Details = ({handleClose}) => {
    const dsg = UseStyles()
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { __SESSION } = tool_state
    const { show, selectedDept, menuDept, filterActive, shareType, searchInput, allUsrList, ulistAccess, withAccess, departmentList, successOpen, message, saveLoader } = cloud_state
    const [searchData, setSearchData] = useState(ulistAccess.data)
    const [searchLoader, setSearchLoader] = useState(false)
    const [defaultList, setDefaultList] = useState([])

    let dfltOwnerList = [{...allUsrList.data.superadmin[0], name:'Super Admin'}, ...allUsrList.data.users.filter(f => {return f.name === 'Admin'})]
    let regUsrList = [{...allUsrList.data.superadmin[0], name:'Super Admin'}, ...allUsrList.data.users]
    let ownerList = regUsrList.filter(f => {return show.data.data.created_by === f.id})

    const searchUser = async() => {
        let filter_fn = ulistAccess.data.map((v) => {return {...v, fullname: `${v.first_name} ${v.last_name}`}})
        let res = filter_fn.filter((item) => 
            item.first_name.toLowerCase().match(searchInput.data.toLowerCase()) || item.last_name.toLowerCase().match(searchInput.data.toLowerCase()) || item.fullname.toLowerCase().match(searchInput.data.toLowerCase())
        )
        await setSearchData(res)
        await setSearchLoader(false)
    }

    const handlerBack = () => {
        if (show.data.data.access === null || show.data.data.access === undefined) {
            shareType.set('create_access')
        }else{
            handleClose()
        }
        menuDept.set(false)
        searchInput.set('')
        selectedDept.set([])
        filterActive.set({id: 0, name: 'All'})
    }

    const handlerFilterDept = () => {
        menuDept.set(true)
    }

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (searchInput.data !== '') {
                setSearchLoader(true)      
                const searchFcn = setTimeout(() => {
                    searchUser()
                }, 500);
                return () => clearTimeout(searchFcn)      
            }else{
                setSearchLoader(false)
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [searchInput.data])

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (!withAccess.data.loader && withAccess.data.val.length !== 0) {
                let fdata = withAccess.data.val[0].access
                if (fdata.length !== ulistAccess.data.length) {
                    ulistAccess.set(fdata.sort((a, b) => a.first_name.localeCompare(b.first_name)))
                }
                selectedDept.set(departmentList.data.filter(v=> fdata.find(x => {return x.company_department_id === v.id }) ))
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [withAccess.data.loader])

    
    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between" minHeight={44} maxHeight={44} p="12px 16px">
                <Box display="flex" alignItems="center" width="100%">
                    <IconButton sx={{ p: '0px'}} onClick={() => handlerBack()}>
                        <ArrowLeft   stroke="#313131" width="16" height="16" />
                    </IconButton>
                    <Box fontWeight={600} lineHeight="26px" textAlign="center" width="100%"> Sharing Setting </Box>
                </Box>
                {
                    (shareType.data === 'details' && (show.data.data.access === null || show.data.data.access === undefined)) &&
                        <IconButton sx={{p: '0px'}} onClick={() => handlerBack()}>
                            <ShareIcon />
                        </IconButton>
                }
            </Box>
            <Box height="100%" width="100%" display="flex" flexDirection="column" className="overflowY">
                <Box fontSize={14} fontWeight={400} lineHeight="22px" p="16px 16px 24px 16px">
                    {
                        show.data.data.feature_type === 1 ?
                            `${show.data.data.registration_name} (${show.data.data.registered_name})`
                        :
                            show.data.data.feature_type === 2 ?
                                `${show.data.data.contract_name} (${show.data.data.client_name})`
                            :
                                show.data.data.feature_type === 3 ?
                                    show.data.data.folder_name
                                :
                                    show.data.data.file_name
                    }
                    {/* {
                        show.data.data.registration_name === undefined && show.data.data.contract_name === undefined ?
                            `${show.data.data.type_name} (${show.data.data.name})`
                        :
                            show.data.data.feature_type === 1 ?
                                `${show.data.data.registration_name} (${show.data.data.registered_name})`
                            :
                                `${show.data.data.contract_name} (${show.data.data.client_name})`
                    } */}
                </Box>
                <Box height="28%" width="100%"  position="relative" display="flex" flexDirection="column"> 
                    <Box height="100%" width="100%" px={2} className="overflowY">
                        {
                            dfltOwnerList.map((v,k) => (
                                <Box display="flex" alignItems="center" gap="11px" mb="16px" key={k}>
                                    <Avatar src={JSON.parse(v.profile).profile} sx={{height: 32, width: 32}} />
                                    <Box display="flex" flexDirection="column" fontWeight={400}>
                                        <Box fontSize={14} lineHeight="24px">{v.first_name} {v.last_name}</Box>
                                        <Box fontSize={12} lineHeight="16px">Default Owner</Box>
                                        <Box fontSize={12} lineHeight="16px">{v.name}</Box>
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>
                    <Box position="absolute" bottom={0} minHeight="12px" width="100%" sx={{opacity:0.5}} bgcolor="#F1F1F1" borderRadius="4px 4px 0px 0px" />
                </Box>
                {
                    ownerList.map((v, k) => (
                        <Box display="flex" alignItems="center" gap="11px" key={k} p={2}>
                            <Avatar src={JSON.parse(v.profile).profile} sx={{height: 32, width: 32}} />
                            <Box display="flex" flexDirection="column" fontWeight={400}>
                                <Box fontSize={14} lineHeight="24px">{v.first_name} {v.last_name}</Box>
                                <Box fontSize={12} lineHeight="16px">Owner</Box>
                            </Box>
                        </Box>
                    ))
                }
                <Box bgcolor="#F8F9FA" height="8px" />
                <Box height="72%" p="24px 16px 16px"> 
                <Box fontSize={14} fontWeight={400} lineHeight="22px" mb={2}>Shared with:</Box>
                {
                    ulistAccess.data.length > 0 &&
                    <>
                        <TextField onInput={(e) => searchInput.set(e.target.value)} fullWidth className={dsg.search} placeholder='Search...' InputProps={{ startAdornment: <InputAdornment position='start'> <Search />  </InputAdornment> }} />
                        <Box display="flex" alignItems="center" gap="12px" mt={2} onClick={(e) => handlerFilterDept(e)}>
                            <KeyboardArrowDown sx={{fontSize: 20}} />
                            <Box fontSize={12} fontWeight={600} lineHeight="24px">{filterActive.data.name}</Box>
                        </Box>
                    </>
                }
                {
                    searchLoader ?
                        <SearchLoader shareType={shareType} />
                    :
                        <UserList searchInput={searchInput} searchData={searchData} ulistAccess={ulistAccess} show={show} withAccess={withAccess} setSearchData={setSearchData} successOpen={successOpen} message={message} saveLoader={saveLoader} __SESSION={__SESSION} setDefaultList={setDefaultList} />
                }
                </Box>
            </Box>
            <Divider variant="middle"/>
            <Box minHeight={64} maxHeight={64} display="flex" alignItems="center" justifyContent="center">
                {(show.data.data.access === null || show.data.data.access === undefined) && (

                    <Button onClick={() => ulistAccess.data.length > 0 ? shareType.set('remove_access') : null}
                        sx={{  opacity: ulistAccess.data.length > 0 ? 1 : 0.2,
                            p: '12px 16px', border: '1px solid #E6E6E6', borderRadius: '6px', color: '#333333', bgcolor:"#F8F9FA", fontSize: 14, height: 40, '&:hover': ulistAccess.data.length > 0 ?  { bgcolor: '#A4CAB4', color: '#107038' } : ''
                        }}
                    >
                        Remove Access
                    </Button>
                )}
            </Box>
            {menuDept.data  && ( 
                <FilterDept menuDept={menuDept} selectedDept={selectedDept} filterActive={filterActive} ulistAccess={ulistAccess} searchInput={searchInput} withAccess={withAccess} defaultList={defaultList} setDefaultList={setDefaultList} />
            )}
        </>
    )
}
