import { Line } from 'react-chartjs-2';

export const Chart = ({ form, ds }) => {
    let txt = form.data.graph.interval.value;
    
    const legendMargin = [{
        beforeInit(chart, legend, options) {
            const fitValue = chart.legend.fit;
            chart.legend.fit = function fit(){
                fitValue.bind(chart.legend)();
                return this.height += 20;
            }
        }
    }]

    return (
        <Line 
            plugins={legendMargin}
            data={{ 
                labels: ds.data.label,
                datasets: ds.data.counts,
            }}
            options={{
                aspectRatio: 1,
                responsive: true,
                plugins:{
                    title: {
                        display: true,
                        text: txt === 'days' ? 'Daily' : txt === 'weeks' ? 'Weekly' : txt === 'months' ? 'Monthly' : txt === 'quarters' ? 'Quarterly' : txt === 'years' ? 'Yearly' : '',
                        font: {
                            size: 16
                        },
                        align: 'start',
                    },
                    legend: {
                        align:'start',
                        display: true,
                        position: 'bottom',
                        labels:{
                            padding:10,
                            boxHeight:20,
                            boxWidth:20,
                            usePointStyle:true,
                            pointStyle:'rectRounded'
                        },
                    }
                },
                scales: {
                    y: {
                        suggestedMax: 30,
                        beginAtZero: true,
                        ticks:{
                            display: true,
                            stepSize: 1
                        },
                        grid:{
                            borderDash: [5, 5],
                            display:true,
                        }
                    },
                    x:{
                        grid:{
                            display:false
                        },
                    },
                }
            }}
        />
    )
}