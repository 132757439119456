import { Box, MenuItem, Modal, Slide, Typography } from "@mui/material";
import { InfoIcon, Restore, TrashIcon, } from "../../../../../../core/global/Icons";
// import { DL } from './DL';

export const ThreeDots = ({active, show, handlerView, modalType}) => {
    const btnList = [
        {name: 'View Details', icon: <InfoIcon />, type: 'view'},
        {name: 'Restore', icon: <Restore />, type: 'restore'},
        {name: 'Permanently Remove', icon: <TrashIcon fill="#737373" />, type: 'perma'},
    ]

    const handlerCloseMenu = () => {
        active.set({...active.data, openMenu: false})
    }

    const handlerMenuBtn = (e, type) => {
        if (type === 'view') {
            handlerView(e, show.data.data)
        }else if (type === 'restore' || type === 'perma') {
            handlerCloseMenu()
            modalType.set(type)
            show.set({...show.data, remove:{...show.data.remove, val:true}})
        }
    }
    
    return (
        <>
            <Modal open={active.data.openMenu} onClose={() => handlerCloseMenu()} BackdropProps={{ sx: {bgcolor: '#0000001F'} }} sx={{zIndex: 1303}} className="noutlined">
                <Slide in={active.data.openMenu} direction="up" className="noutlined">
                    <Box height="100%" width="100%" display="flex" justifyContent="flex-end" flexDirection="column">
                        <Box height="100%" onClick={() => handlerCloseMenu()} />
                        <Box minHeight={show.data.view ? "120px" : "180px"} bgcolor="#FFFFFF" p={2} borderRadius="16px 16px 0px 0px" boxShadow="0px 0px 24px rgba(0, 0, 0, 0.08)">
                            <Box display="flex" justifyContent="center" mb={1}>
                                <Box height="2px" width="37px" bgcolor="#333333" />
                            </Box>
                            {
                                !show.data.view &&
                                    <Typography fontSize={14} fontWeight={600} lineHeight="25px" noWrap>
                                        { show.data.data.type === 3 || show.data.data.type === 4  ? show.data.data.name : `${show.data.data.type_name} (${show.data.data.name})`}
                                    </Typography>
                            }
                            {
                                (show.data.view ? btnList.filter(v => { return v.type !== 'view'}) : btnList).map((v,k) => (
                                    (show.data.data.type !== 3 || (show.data.data.type === 3 && v.name !== 'View Details' )) && (
                                        <MenuItem sx={{px: '0px', py: '8px', minHeight: '36px'}} key={k} onClick={(e) => handlerMenuBtn(e, v.type)}>
                                            {v.icon}
                                            <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px">{v.name}</Box>
                                        </MenuItem>
                                    )
                                ))
                            }
                        </Box>
                    </Box>
                </Slide>
            </Modal>
            {/* <DL showDl={showDl} show={show} message={message} successOpen={successOpen} regActive={regActive} /> */}
        </>
    )
}
