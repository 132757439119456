import { Box, Button, IconButton, Modal, Slide } from "@mui/material"
import { ArrowLeft, GridViewIcon, ListViewIcon } from "../../../../../core/global/Icons"
import { List } from './list/Index';
import { useContext, useState } from "react";
import { ToolContext } from '../../../../../core/context/ToolContext';
import { useEffect } from "react";
import { Types, __InitRegList } from '../../../global/Function'
import { useHistory } from "react-router-dom";
import { MblFetchingLoader } from "../../../../fetching/Loader";
import { MblAlert } from '../../../../../core/global/forms/CstmModal'
import { FormCon } from "./setup/Index";
import { ShareAccess } from '../shareAccess/Index'

export const Registrations = ({match}) => {
    const nav = useHistory()
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { filter, isLoading, inftScroll, limit, lid, regList, listView, successOpen, message, reloadStorage, openShare, } = cloud_state
    const { __SESSION } = tool_state
    const [open, setOpen] = useState(true)
    const [subscribe, setSubscribe] = useState(true)

    const handlerBack = () => {
        setOpen(false)
        setTimeout(() => {
            reloadStorage.set(true)
            nav.push('/my-cloud/recent/list')
        }, 200);
    }

    const handlerFilter = (id) => {
        if (!isLoading.data) {
            filter.set(id)
            __InitRegList(0, id, isLoading, inftScroll, __SESSION, limit, lid, regList)
        }
    }
    
    const __LoadmoreData = () => {
        __InitRegList(lid.data, filter.data, isLoading, inftScroll, __SESSION, limit, lid, regList)
    }

    useEffect(() => {
        if (subscribe) {
            listView.set(true)
            __InitRegList(0, filter.data, isLoading, inftScroll, __SESSION, limit, lid, regList)
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [])
     
     return (
        match.params === 'list' ? 
            <>
                <Modal open={open} className="noutlined">
                    <Slide in={open} direction="left" className="noutlined">
                        <Box height="100%" width="100%" bgcolor="#ffffff" display="flex" flexDirection="column">
                            <Box minHeight="44px" maxHeight="44px" display="flex" alignItems="center" justifyContent="space-between" p="12px 16px">
                                <IconButton onClick={() => handlerBack()}>
                                    <ArrowLeft stroke="#313131" width="16" height="16" />
                                </IconButton>
                                <Box fontSize={16} fontWeight={600} lineHeight="26px">Registration</Box>
                                <IconButton onClick={() => listView.set(!listView.data)}>
                                {listView.data ? <ListViewIcon /> : <GridViewIcon />}
                                </IconButton>
                            </Box> 
                            <Box p={2} display="flex" justifyContent="space-between" gap="12px">
                                {
                                    Types.map((v,k) => (
                                        <Button key={k} fullWidth onClick={() => handlerFilter(v.id)}
                                            sx={{ fontSize: 12, fontWeight: 600, bgcolor: '#E2EDE7', borderRadius: '6px', 
                                                color: v.id === filter.data ? "#107038" : "#737373",
                                                border: `1px solid ${v.id === filter.data ? '#107038' : '#E2EDE7'}`,
                                                '&:hover': { bgcolor: '#E2EDE7' }
                                            }}
                                        >
                                            {v.name}
                                        </Button>
                                    ))
                                }
                            </Box>
                            <Box height="100%" width="100%" className="overflowY">
                                {isLoading.data ?
                                    <MblFetchingLoader mt={0} />    
                                :
                                    <List __SESSION={__SESSION} __LoadmoreData={__LoadmoreData} cloud_state={cloud_state} />
                                }
                            </Box>
                            <Box minHeight="60px"></Box>
                        </Box>
                    </Slide>
                </Modal>
                <MblAlert successOpen={successOpen.data} setSuccessOpen={successOpen.set} text={message.data} />
                {
                    openShare.data &&
                        <ShareAccess />
                }
            </>
        :
            <FormCon match={match.params}/>
    )
}
