import { Box, Container, IconButton,} from "@mui/material"
import { useContext, useEffect } from "react";
import { ToolContext } from "../../../core/context/ToolContext";
import { rqx } from "../../../core/request/API";
import { env } from "../../../core/Env";
import { List } from "./List";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export const Notification = () => {
    const {notif_state, navigation_state, tool_state } = useContext(ToolContext)
    const { tab, loader, notifData, anchorNotif, count } = notif_state
    const { __SESSION } = tool_state
    
    const _clickThreeDots = (e) => {
        e.preventDefault()
        anchorNotif.set(e.currentTarget)
    }

    const _clickTab = (type) => {
        tab.set(type)
    }

    const _readNotif = async() => {
        loader.set(false)
        let d = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/notification/read`, {
            user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1], 
            lastId:0, 
            limit:10,
            ref:tab.data
        })
        let c = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/notification/read`, {
            user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1], 
            ref:'count'
        })
        notifData.set(d);
        count.set(c[0].count)
        loader.set(true)
    }

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            navigation_state.tc.set({...navigation_state.tc.data, open: false, list: false})
            _readNotif()
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [tab.data])
    
    return (
        <Container maxWidth="md" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <Box display="flex" alignItems="center" justifyContent="space-between" pt="38px" pb={3}>
                <Box fontSize={20} fontWeight={600}>Notifications</Box> 
                <IconButton sx={{p: '0px !important'}} onClick={(e) => _clickThreeDots(e)}>
                    <MoreHorizIcon />
                </IconButton>
            </Box>
            <Box minHeight={35} bgcolor="#FFFFFF" sx={{borderRadius: "8px 8px 0px 0px"}}>
                <Box display="flex" alignItems="center" p="8px 12px 0px 12px">
                    <Box borderBottom={tab.data === 'all' ? "3px solid black" : "1px solid #525B6D"} textAlign="center" minWidth="50px" color={tab.data === 'all' ? "#1B1B1B" : "#989A9A"} className="c-pointer" onClick={() => _clickTab('all')}>All</Box>
                    <Box borderBottom={tab.data === 'unread' ? "3px solid black" : "1px solid #525B6D"} minWidth='90px' display="flex" alignItems="center" justifyContent="center" className="c-pointer" onClick={() => _clickTab('unread')}>
                        <Box textAlign="center" width="59px" color={tab.data === 'unread' ? "#1B1B1B" : "#989A9A"}>Unread</Box> 
                        {count.data !== 0 && 
                            <Box ml="4px" textAlign="center" height="17px" px="6px" bgcolor={tab.data === 'unread' ? "#1B1B1B" : "#989A9A"} color="#FFFFFF" fontSize="12px" borderRadius="5px">{count.data}</Box>
                        }
                    </Box>
                </Box>
                <Box width="100%" height="1px" mt={-.2} bgcolor="#525B6D" />
            </Box>
            <List notifData={notifData} loader={loader} __SESSION={__SESSION}/>
        </Container>
    )
}
