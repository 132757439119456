import { Box, Checkbox, IconButton } from "@mui/material"
import { NoFound } from "../../../global/DesktopDsg"
import { TrashIcon, VeriticalThreeDots } from "../../../../../core/global/Icons"
import { Waypoint } from "react-waypoint"
import { SnakeLoader } from "../../../../../core/loader/SnakeLoader"
import { Timer } from "../Timer";
import { CheckCircle, CircleOutlined } from "@mui/icons-material";
import { Loader } from "../../../Loader";
import { useContext } from "react"
import { ToolContext } from "../../../../../core/context/ToolContext"
import moment from "moment";
import FolderIcon from '../../../../../assets/images/icons/folder.png'

export const FolderList = ({ __LoadmoreFolderData, __InitTrashFolderList, handlerThreeDots, showThreeDots, handlerSelect}) => {
    const uai_user = process.env.REACT_APP_UAI_REGULAR;
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { trashFolder, folderLoader, folderInfi, active, selectedFiles } = cloud_state
    const { __SESSION } = tool_state 
    
    return (
        (folderLoader.data ?
            <Loader />
        :
            (trashFolder.data.length === 0 ?
                <NoFound msg="Trash Folder is Empty" submsg = '' folder={true} />
            :
                <>
                    <Box fontSize={14} fontWeight={600} lineHeight="19px" mb={2}>Folder</Box>
                    <Box width="100%" display="flex" flexWrap="wrap" gap="20px">
                        {
                            trashFolder.data.map((v, k) => (
                                <Box minWidth='273px' maxWidth='273px' display="flex" justifyContent="space-between" alignItems="center" p="12px" borderRadius="6px" key={k} mb={2} className="c-pointer" 
                                    sx={{bgcolor: active.data.openMenu !== null && k === active.data.val  ? '#f0f0f0' : v.checked ? '#E2EDE7' : '#ffffff', '&:hover': { bgcolor:  v.checked ? '#E2EDE7' : '#f0f0f0' } }}
                                    onMouseOver={() => showThreeDots(k, 'enter')} 
                                    onMouseLeave={() => showThreeDots('', 'leave')}
                                >
                                    <Box display="flex" alignItems="center" width="100%" position="relative">
                                         {
                                            ((active.data.val === k || v.checked || selectedFiles.data.length > 0) && (v.access === null || __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) )) ?
                                                <Box height={20} display="flex" alignItems="center" bgcolor="#ffffff" borderRadius="6px"  ml="14px" position="absolute" zIndex={999}>
                                                    <Checkbox size="small" sx={{ p: '0px !important'}}
                                                        checked={v.checked}
                                                        onChange={(e) => handlerSelect(e, v.id, v)}
                                                        icon={  <CircleOutlined sx={{fontSize: 20}} /> } 
                                                        checkedIcon={ <CheckCircle sx={{fontSize: 20}} /> } 
                                                    />    
                                                </Box>
                                            :   
                                                <Box height={20} position="absolute" />
                                        }
                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            <img src={FolderIcon} alt="folder-img" />
                                        </Box>
                                        <Box display="flex" flexDirection="column" ml="12px">
                                            <Box fontSize={14} fontWeight={600} lineHeight="19px">{v.name}</Box>
                                            <Box display="flex" alignItems="center">
                                                <TrashIcon fill="#F68511" width="12" height="12" />
                                                <Timer expiration={moment(v.updated_at).add(30, 'd').format('MM-DD-YYYY HH:mm:ss')} v={v} __SESSION={__SESSION}  __InitTrashData={__InitTrashFolderList} ftype="folder"/>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        {active.data.val === k &&
                                            <IconButton sx={{p: '0px !important'}} onClick={(e) => handlerThreeDots(e, k, v, 'open')}>
                                                <VeriticalThreeDots fill={active.data.openMenu !== null ? "#107038" : "#737373"} />
                                            </IconButton>
                                        }
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>
                    {folderInfi.data && 
                        <Box display="flex" justifyContent="center">
                            <Waypoint onEnter={__LoadmoreFolderData} >
                                <Box component="strong" mt={5} display="flex" justifyContent="center">
                                    <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                                </Box>
                            </Waypoint>
                        </Box>
                    }
                </>
            )
        )
    )
}