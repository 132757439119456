import { useContext, useState, useEffect } from "react"
import { Box, Button, Divider, IconButton, InputAdornment, TextField } from "@mui/material"
import { ArrowLeft, Search } from "../../../../../../core/global/Icons"
import { ToolContext } from "../../../../../../core/context/ToolContext"
import { RemoveShareConfirmation, UseStyles } from "../../../../global/DesktopDsg"
import { KeyboardArrowDown } from "@mui/icons-material"
import { UserList } from "./UserList"
import { SearchLoader } from '../Loader'
import { rqx } from "../../../../../../core/request/API"
import { env } from "../../../../../../core/Env"
import { FilterDept } from './FilterDept'
import { NotifCount } from "../../../../../../core/request/Notif"

export const RemoveAccess = () => {
    const dsg = UseStyles()
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { shareType, filterActive, searchInput, ulistAccess, allUsrList, confirmRemoveShare, show, saveLoader, withAccess, successOpen, message, openShare } = cloud_state
    const { __SESSION } = tool_state
    let company_id = __SESSION.data.uaccess[0].company_id;
    let user_id =  atob(__SESSION.data.ainfo.aid).split('_', 2)[1]
    const [removeDeptModal, setRemoveDeptModal] = useState(false)
    const [usrData, setUsrData] = useState(allUsrList.data)
    const [count, setCount] = useState(0)
    const [slctTxt, setSlctTxt] = useState(false)
    const [searchData, setSearchData] = useState(ulistAccess.data)
    const [searchLoader, setSearchLoader] = useState(false)

    const handleBack = async() => {
        let fltr = usrData.users.filter(v => { return v.stat.view }  )
        ulistAccess.set(fltr)
        await filterActive.set({id: 0, name: 'All'})
        shareType.set('details')
        searchInput.set('')
        setRemoveDeptModal(false)
    }

    const handleFltrDept = () => {
        setRemoveDeptModal(true)
    }

    const handlerCloseConfirmation = () => {
        confirmRemoveShare.set({...confirmRemoveShare.data, val : false})
    }

    const handleSelectAll = async() => {
        setSlctTxt(!slctTxt)
        let res;
        if (filterActive.data.name === 'All') {
            if (searchInput.data !== '') {
                res = searchData.map(v => { return {...v, stat:{...v.stat, remove:!slctTxt}} }  )
                setSearchData(res)
            }else{
                res = ulistAccess.data.map(v => { return {...v, stat:{...v.stat, remove:!slctTxt}} }  )
            }
        }else{
            if (searchInput.data !== '') {
                res = searchData.map((v) => v.company_department_id === filterActive.data.id ? {...v, stat:{...v.stat, remove:!slctTxt}} : {...v} )
                setSearchData(res)
            }else{
                res = ulistAccess.data.map((v) => v.company_department_id === filterActive.data.id ? {...v, stat:{...v.stat, remove:!slctTxt}} : {...v} )
            }
        }
        const mergeArr = res
        
        let data = []
        if (filterActive.data.name === 'All') {
            const au = mergeArr.filter((au) => au.name !== 'Admin' )
            data = [...data, ...au]
        }else{
            const au = mergeArr.filter((au) => au.company_department_id === filterActive.data.id && au.name !== 'Admin' )
            data = [...data, ...au]
        }
        ulistAccess.set(data)
        const mergeArr2 = usrData.users.map((md) => {
            const td = data.find((td) => td.id === md.id);
            return {...md, ...td};
        });
        setUsrData({...usrData, users:mergeArr2})
    }

    const handlerRemoveAccess = () => {
        if (count > 0) {
            confirmRemoveShare.set({...confirmRemoveShare.data, val : true, type : 'remove'})
        }
    }

    const handlerSubmit = async() => {
        if (!saveLoader.data) {
            saveLoader.set(true)
            let fltr = ulistAccess.data.filter(v => { return !v.stat.remove} )
            let acc = fltr.length === 0 ? fltr : fltr.map(v => { return { user_id: v.id, view:v.stat.view, update:v.stat.update, download:v.stat.download } }  )
           
            let body = {
                company_id: company_id,
                access: acc,
                user_id: user_id,
                id: withAccess.data.val[0].id,
                ref: 'update'
            }
            const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/access/update`, body)
            const fileType = show.data.data.feature_type === 1 ? 'reg' : show.data.data.feature_type === 2 ? 'con' : show.data.data.feature_type === 3 ? 'folder' : 'file'
            if (res.message === 'success') {
                let fltrAccess = ulistAccess.data.filter(v => { return v.stat.remove} )
                if (fltrAccess.length > 0) {
                    let removeAcc = fltrAccess.map(v => { return { user_id: v.id, view:v.stat.view, update:v.stat.update, download:v.stat.download, remove:true, file:fileType, file_id:show.data.data.id } })
                    await NotifCount(removeAcc, __SESSION, 'rmvacc')
                }
                confirmRemoveShare.set({...confirmRemoveShare.data, val : false, type: ''})
                saveLoader.set(false)
                openShare.set(false)    
                successOpen.set(true)
                message.set('Successfully removed')
            }
        }
    }

    const RemoveUlist = async() => {
        const filter_fn = ulistAccess.data.map((v) => { return {...v, fullname:`${v.first_name} ${v.last_name}`  } }  )
        const res = filter_fn.filter((item) =>
            item.first_name.toLowerCase().match(searchInput.data.toLowerCase()) || item.last_name.toLowerCase().match(searchInput.data.toLowerCase()) || item.fullname.toLowerCase().match(searchInput.data.toLowerCase())
        );
        setSlctTxt(res.length === 0 ? false : res.filter(v => { return !v.stat.remove }  ).length > 0 ? false : true)
        await setSearchData(res)
        await setSearchLoader(false)
    }

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (searchInput.data !== '') {
                setSearchLoader(true)
                const searchFcn = setTimeout(() => {
                    RemoveUlist()
                }, 500)
                return () => clearTimeout(searchFcn)
            }else{
                setSearchLoader(true)
                if (usrData.users.length > 0) {
                    const mergeArr = usrData.users.map((md) => {
                        const td = ulistAccess.data.find((td) => td.id === md.id);
                        return {...md, ...td};
                    });
                    setUsrData({...usrData, users:mergeArr})
                    let au;
                    if (filterActive.data.name === 'All') {
                        au = mergeArr.filter((au) => { return au.name !== 'Admin' && au.stat.view } )
                    }else{
                        au = mergeArr.filter((au) => au.company_department_id === filterActive.data.id && au.name !== 'Admin' && au.stat.view )
                    }
                    setSlctTxt(au.filter(v => { return !v.stat.remove }  ).length > 0 ? false : true)
                    ulistAccess.set(au.sort((a, b) => a.first_name.localeCompare(b.first_name)))
                }
                
                setSearchLoader(false)
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [searchInput.data])

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            let res = usrData.length === 0 ? [] : usrData.users.filter(v => { return v.stat.remove })
            if (res.length === 0) {
                res = ulistAccess.data.filter(v => { return v.stat.remove })
            }
            setCount(res.length)
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [ulistAccess.data, usrData])
    
    return (
        <>
           <Box minHeight={44} maxHeight={44} p="12px 16px" display="flex" alignItems="center" position="relative">
                <IconButton sx={{position: 'absolute', p: '0px'}} onClick={() => handleBack()}>
                    <ArrowLeft stroke="#313131" width="16" height="16" />
                </IconButton>
                <Box width="100%" textAlign="center" fontSize={16} fontWeight={600} lineHeight="26px">Remove Access</Box>
            </Box>
            <Box height="100%" width="100%" p={2} className="overflowY">
                <Box fontSize={14} fontWeight={400} lineHeight="22px" mb={4}>Please select the employee you want to restrict from accessing this file.</Box>
                <TextField onInput={(e) => searchInput.set(e.target.value)} fullWidth className={dsg.search} placeholder='Search...' InputProps={{ startAdornment: <InputAdornment position='start'> <Search />  </InputAdornment> }} />
                <Box display="flex" allignItem="center" justifyContent="space-between" mt={3}>
                    <Box display="flex" alignItems="center" gap="12px">
                        <Box display="flex" alignItems="center" gap="12px" onClick={() => handleFltrDept()}>
                            <KeyboardArrowDown />
                            <Box fontSize={14} fontWeight={600} lineHeight="24px">{filterActive.data.name}</Box>
                        </Box>
                        <Button sx={{fontSize: 14, fontWeight: 400, lineHeight: '24px', color: '#A2A3A9', p: '0px'}} onClick={() => handleSelectAll()}> {slctTxt ? 'Unselect all' : 'Select all'}</Button>
                    </Box>
                    <Box display="flex" alignItems='center' >
                        {count > 0 && (
                            <Box fontSize={12} fontWeight={400} lineHeight="24px" color="#107038">{count} selected</Box>
                        )}
                    </Box>
                </Box>
                {
                    searchLoader ?
                        <SearchLoader shareType={shareType} />
                    :
                        <UserList ulistAccess={ulistAccess} setSlctTxt={setSlctTxt} searchData={searchData} setSearchData={setSearchData} searchInput={searchInput} usrData={usrData} setUsrData={setUsrData} />
                }
            </Box>
            <Divider variant="middle"/>
            <Box minHeight={64} maxHeight={64} display="flex" alignItems="center" justifyContent="center">
                <Button onClick={() => handlerRemoveAccess()}
                    sx={{  opacity: ulistAccess.data.filter(v => { return v.stat.remove } ).length > 0 ? 1 : 0.2, height: 32,
                        p: "8px 62px", borderRadius: "6px", bgcolor: "#107038", color: "#FFFFFF", '&:hover': {bgcolor: "#16522F"} 
                    }}
                >
                    Remove
                </Button>
            </Box>
            <RemoveShareConfirmation
                name={
                    show.data.data.feature_type === 1 ? 
                        show.data.data.registration_name 
                    :show.data.data.feature_type === 2 ? 
                        show.data.data.contract_name 
                    :show.data.data.feature_type === 3 ? 
                        show.data.data.folder_name
                    : 
                        show.data.data.file_name
                } 
                subname={
                    show.data.data.feature_type === 1 ? 
                        show.data.data.registered_name 
                    : show.data.data.feature_type === 2 ? 
                        show.data.data.client_name
                    : ''
                }
                // name={ sharedFltr.data.type === 'all' ? show.data.data.type_name :  show.data.data.feature_type === 1 ?  show.data.data.registration_name : show.data.data.contract_name} 
                // subname={ sharedFltr.data.type === 'all' ? show.data.data.name : show.data.data.feature_type === 1 ? show.data.data.registered_name : show.data.data.client_name}
                confirmRemoveShare={confirmRemoveShare}  
                cancel={() => handlerCloseConfirmation()}
                submit={() => handlerSubmit()}
                saveLoader={saveLoader.data}
            />
            {
                removeDeptModal && 
                    <FilterDept removeDeptModal={removeDeptModal} setRemoveDeptModal={setRemoveDeptModal} cloud_state={cloud_state} usrData={usrData} setUsrData={setUsrData} setSlctTxt={setSlctTxt}  />
            }
        </>
    )
}
