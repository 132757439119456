import { useState } from "react"

export const UserRoleState = () => {
   
    // USER ROLE
    const [typusr, setTypUsr] = useState({id:1, name:'Employee'})
    const [showTypeUsr, setShowTypeUsr] = useState(false)
    const [typeUserData, setTypeUserData] = useState(
        [
            {id:1, name:'Employee'},
            {id:2, name:'Client'}
        ]
    )
    const [sortUsrList, setSortUsrList] = useState([])
    
    const [roleNameByTool, setRoleNameByTool] = useState([])

    return {
        typusr: {data: typusr, set: setTypUsr},
        showTypeUsr: {data: showTypeUsr, set: setShowTypeUsr},
        typeUserData: {data: typeUserData, set: setTypeUserData},
        sortUsrList: {data: sortUsrList, set: setSortUsrList},
        roleNameByTool: {data: roleNameByTool, set: setRoleNameByTool},
    }
}