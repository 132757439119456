import { useState } from 'react'
import { Box, IconButton, TextField } from '@mui/material'
import { StarIcon, VeriticalThreeDots } from "../../../../../core/global/Icons"
import { NoFound } from '../../../global/DesktopDsg'
import { SnakeLoader } from '../../../../../core/loader/SnakeLoader'
import { Waypoint } from 'react-waypoint'
import { useHistory } from 'react-router-dom'
import { rqx } from '../../../../../core/request/API'
import { env } from '../../../../../core/Env'
import { LoaderFolder } from './Loader'
import FolderIcon from '../../../../../assets/images/icons/folder.png'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export const Folder = ({match, handlerShowIcon, docuFolder, __LoadMoreFolder, folderInfi, show, active, rename, user_id, message, successOpen, folderLoader, handlerAddFav, errOpen, __SESSION}) => {
    const uai_user = process.env.REACT_APP_UAI_REGULAR
    const nav = useHistory()
    const urlParams = new URLSearchParams(window.location.search)
    const [activeStar, setActiveStar] = useState('')

    const handlerRedirectTo = async(f_id, name, v) => {

        let access = ''
        if (match.feature === 'shared-access' && __SESSION.data.uaccess[0].role_id === parseInt(uai_user)) {
            let qsp = { 
                feature: match.feature,
                ref: 'access', 
                parent_id: v.folder_id, 
            }
            let res = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/documents/read`, qsp)
            if (res) {
                if (res.length > 0) {
                    access = JSON.stringify(res)
                }else{
                    access = window.atob(urlParams.get('access'))
                }
            }
        }
        let subfname = urlParams.get('subfname') === null ? name : urlParams.get('subfname')+'/'+name
        let subfid = urlParams.get('subfid') === null ? f_id : urlParams.get('subfid')+'/'+f_id
        if (!rename.data.val) {
            nav.push(`/my-cloud/${match.feature}/folder?subfname=${subfname}&subfid=${subfid}&f_id=${f_id}${match.feature === 'shared-access' && __SESSION.data.uaccess[0].role_id === parseInt(uai_user) ? `&access=${window.btoa(access)}` : '' } `)
        }
    }

    const handlerThreeDots = (e, k, v, type) => {
        e.stopPropagation()
        let vdata = {...v, access:v.access === null && match.feature === 'shared-access' && __SESSION.data.uaccess[0].role_id === parseInt(uai_user) ? JSON.parse(window.atob(urlParams.get('access')))[0].access : __SESSION.data.uaccess[0].role_id === parseInt(uai_user) ?  v.access : null}
        active.set({...active.data, val: k, openMenu: e.currentTarget, type : type})
        show.set({...show.data, data : vdata, view : false, fullview : false})
    }

    const handlerOnChange = (e, id) => {
        docuFolder.set((prevData) => {
            return prevData.map((v) => {
                if (v.id === id) {
                    return {...v, folder_name : e.target.value}
                }
                return v
            })
        })
    }
   
    const handlerCloseRename = (id) => {
        rename.set({...rename.data, val : false})
        docuFolder.set((prevData) => {
            return prevData.map((v) => {
                if (v.id === id) {
                    return {...v, folder_name : show.data.data.folder_name }
                }
                return v
            })
        })
    }
   
    const handlerSaveRename = async(v) => {
        if (v.currFname === v.folder_name) {
            rename.set({...rename.data, val : false})
            return
        }

        let body = {
            parent_id:v.parent_id,
            company_id:v.company_id,
            folder_name: v.folder_name,
            folder_id: v.folder_id,
            user_id: user_id,
            ref: 'folder'
        }
      
        const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/documents/update`, body)
        if (res.message === 'success') {
            rename.set({...rename.data, val : false})
            message.set('Successfully saved')
            successOpen.set(true)
        }else if(res.message === "exist"){
            errOpen.set(true)
            message.set('Folder Name Already Exist')
        }
    }
    
    return (
        folderLoader.data ? 
            <LoaderFolder />
        :
            <>
                <Box display="flex" alignItems="center" gap="20px" flexWrap="wrap">
                    {
                        docuFolder.data.length === 0 ?
                            <NoFound msg="No File found. Upload Now!" submsg = '' folder={true} />
                        :
                            docuFolder.data.map((v, k) => (
                                <Box width={273} height={64} display="flex" alignItems="center" justifyContent="space-between" p="8px 12px" className="c-pointer" key={k}
                                    boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02)" borderRadius="6px"  
                                    bgcolor={active.data.val === k && active.data.type === 'folder' ? '#f0f0f0' : '#ffffff'}
                                    onMouseOver={() => handlerShowIcon(k, 'enter', 'folder')}
                                    onMouseLeave={() => handlerShowIcon('', 'leave', 'folder')}
                                    onClick={() => handlerRedirectTo(window.btoa(v.id), v.folder_name, v)}
                                    // onClick={() => handlerRedirectTo(window.btoa(v.folder_id), v.folder_name, window.btoa(v.id))}
                                >
                                    <Box display="flex" alignItems="center" gap="8px">
                                        <img src={FolderIcon} alt="folder-img" />
                                        {
                                            rename.data.id === v.id && rename.data.val && rename.data.type === 'folder' ?
                                                <TextField size="small" variant="standard" value={v.folder_name} sx={{ lineHeight: '22px', fontSize: 14, fontWeight: 600}} onChange={(e) => handlerOnChange(e, v.id)}  />
                                            :
                                                <Box fontSize={14} lineHeight="22px" fontWeight={600}>{v.folder_name}</Box>
                                        }
                                    </Box>
                                    {
                                        (rename.data.id === v.id && rename.data.val && rename.data.type === 'folder') ?
                                            <Box display="flex" alignItems="center" justifyContent="flex-end" gap="8px">
                                                <IconButton onClick={() => handlerSaveRename(v)}
                                                    sx={{p:'4px', bgcolor: '#ffffff', boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)'}} 
                                                >
                                                    <CheckIcon sx={{fontSize: 20, color: '#107038'}} />
                                                </IconButton>
                                                <IconButton onClick={() => handlerCloseRename(v.id)}
                                                    sx={{p:'4px', bgcolor: '#ffffff', boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)'}}
                                                >
                                                    <CloseIcon sx={{fontSize: 20, color: '#FA3E3E'}}  />
                                                </IconButton>
                                            </Box>
                                        :
                                            <Box display="flex" alignItems="center" gap="8px">
                                                {
                                                    ((active.data.val === k && active.data.type === 'folder') || v.user_favorite_status === 2) &&
                                                        <IconButton sx={{p: '0px !important'}} 
                                                            onClick={(e) => handlerAddFav(e,v)}
                                                            onMouseEnter={() => setActiveStar(k)} 
                                                            onMouseLeave={() => setActiveStar('')}
                                                        >
                                                            <StarIcon fill={ activeStar === k && v.user_favorite_status !== 2 ? '#333333' : v.user_favorite_status === 2 ? '#11783C' : 'none'}
                                                                stroke={activeStar === k && v.user_favorite_status !== 2 ? '#333333' : v.user_favorite_status === 2 ? '#11783C' : '#737373'} 
                                                            />
                                                        </IconButton>
                                                }
                                                {
                                                    active.data.val === k && active.data.type === 'folder' &&
                                                        <IconButton sx={{p: '0px !important'}} onClick={(e) => handlerThreeDots(e, k, v, 'folder')}>
                                                            <VeriticalThreeDots fill={active.data.openMenu !== null ? "#107038" : "#737373"}   />
                                                        </IconButton>
                                                }
                                            </Box>
                                    }
                                </Box>
                            ))
                    }
                </Box>
                {
                    folderInfi.data &&  (
                        <Box display="flex" justifyContent="center">
                            <Waypoint onEnter={__LoadMoreFolder} >
                                <Box component="strong" mt={5} display="flex" justifyContent="center">
                                    <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                                </Box>
                            </Waypoint>
                        </Box>
                    )
                }
            </>
    )
}
