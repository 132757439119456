import { useContext, useEffect } from 'react';
import { Box, Container, useMediaQuery } from '@mui/material';
import { ToolContext } from '../../../core/context/ToolContext';
import { Index as Primary } from './primary/Index';
import { SecondaryLoader } from '../loader/RightLoader';
import { Index as Secondary } from './secondary/Index';

export const Index = ({linkacct}) => {
    const xl = useMediaQuery('(min-width:1921px)');
    const { tool_state, home_state, banner_state, gen_main_state } = useContext(ToolContext);
    const listBanner = banner_state.bannerList;
    const gms = gen_main_state;
    const hs = home_state
    const ts = tool_state;
    const __SESSION = ts.__SESSION.data.uaccess;
    
    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (!ts.globalLoader.data) {
                let data;
                let __bview;
                if (__SESSION[0].role_id !== 0) {
                    let arr;
                    arr = listBanner.data.filter(v => { return v.name !== 'gen-maintenance'});
                    if (tool_state.__SESSION.data.ainfo.account_type === 3 || linkacct.length > 0) {
                        data = arr.filter(v => { return v.name !== 'link'});
                    }else{
                        data = arr
                    }
                    __bview = (data.length === 2  ? 2 : data.length)
                }else{
                    if (tool_state.__SESSION.data.ainfo.account_type === 3 || linkacct.length > 0) {
                        data = listBanner.data.filter(v => { return v.name !== 'link'});
                    }else{
                        data = listBanner.data;
                    }
                    __bview = (data.length > 3 ? 3 : data.length)
                }
                banner_state.BannerPerView.set(__bview)
                
                let s_data = data;
                if(tool_state.toolOwned.data.length > 0){
                    s_data = data.filter(v => { return v.name !== 'market-place'});
                }else{
                    if (__SESSION[0].role_id !== 0) {
                        s_data = data.filter(v => { return v.name !== 'market-place'});
                    }
                }
                
                let f_data = s_data;
                if (tool_state.__SESSION.data.pinfo.length > 0) {
                    if (JSON.parse(tool_state.__SESSION.data.pinfo[0].other_info).gender !== null ) {
                        f_data = s_data.filter(v => { return v.name !== 'profile'});
                    }
                }
                
                let comp_profile = f_data;
                if(gms.compinfo.data.length > 0){
                    if(gms.compinfo.data[0].name !== ''){
                        comp_profile = f_data.filter(v => { return v.name !== 'gen-maintenance'});
                    }
                }
                listBanner.set(comp_profile)
                banner_state.isShow.set(true)
                // setTimeout(() => {
                // }, 1000);
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [linkacct, ts.globalLoader.data])

    return (
        <Box display="flex" height="100%" width="100%">
            <Box height="100%" width="100%" className="overflowY noScrollcss" component={xl ? Container : ''}  sx={{p:'0px !important'}}>
                <Primary hs={hs} ts={ts} __SESSION={__SESSION} listBanner={listBanner} banner_state={banner_state} />
            </Box>
            <Box position={xl ? 'fixed' :''} right={xl ? 3 : ''} height="100%" width={{sm: "292px", lg: '392px'}} minWidth={{sm: "292px", lg: '392px'}} display="flex" flexDirection="column" px={3} m={0} boxShadow="0px 0px 4px rgba(0, 0, 0, 0.08)" className="overflowY">
                {banner_state.isShow.data ? 
                    <Secondary commPoints={hs.commPoints.data} expPoints={hs.expPoints.data} toolOwned={tool_state.toolOwned.data}/>
                :
                    <SecondaryLoader/>
                }
            </Box>
            <Box position="fixed" bottom={0} minHeight="30px" width="100%" sx={{opacity:0.8}} bgcolor="#F1F1F1" borderRadius="4px 4px 0px 0px" />
        </Box>
    )
}