import { Box, Checkbox, Grid, InputBase } from "@mui/material"
import { SearchIcons } from "../../../core/global/Icons"

export const Top = ({pSearch, setPSearch, __SESSION, uai_user, icon, checkedIcon, selectAll, setSelectAll, handleCheckChange, plcyData, isLoading}) => {
    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/;

    const handleSearch = (e) => {
        if (!isLoading.data) {
            isLoading.set(true)
        }
        setPSearch(e.target.value)
    }
    return(
        <Box>
            <Box px={3}>
                <InputBase
                    onKeyDown={(event) => {
                        if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                          event.preventDefault();
                        }
                    }}
                    onPaste={(e)=>{
                        e.preventDefault()
                        return false;
                    }} 
                    onCopy={(e)=>{
                        e.preventDefault()
                        return false;
                    }}
                    value={pSearch}
                    onChange={(e) => handleSearch(e)}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search' }} endAdornment={ <Box pr={1} pt={.5}><SearchIcons /></Box> }
                    sx={{backgroundColor: "#FFFFFF", border: "#D9EBE8 solid 2px", borderRadius: '10px', padding: '5px 5px 5px 20px', minWidth: '291', height: '49px'}}
                />
            </Box>
            <Box width="100%" height="100%" pr={3} pl={1.5}>
                <Box width="100%" my={3} px={2} display="flex" gap="2px" fontWeight={500}>
                    <Box minWidth="20px">
                        {(__SESSION.data.uaccess[0].role_id === parseInt(uai_user) && plcyData.data.filter((v) => { return v.cp_status === null}).length !== 0) && (
                            <Checkbox icon={icon} checkedIcon={checkedIcon} sx={{p: '0px', pr:'4px', mb:'5px'}} checked={selectAll} onChange={(e) => handleCheckChange (e, 'ALL')}></Checkbox>
                        )}
                    </Box>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={2}>
                            <Box>
                                Policy No.
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box>
                                Department
                            </Box>
                        </Grid>
                        <Grid item xs={__SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? 2 : 3}>
                            <Box>
                                Title 
                            </Box>
                        </Grid>
                        <Grid item xs={__SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? 2 : 3}>
                            <Box>
                                Description
                            </Box>
                        </Grid>
                        {__SESSION.data.uaccess[0].role_id !== parseInt(uai_user) && ( 
                            <Grid item xs={2} textAlign="center">
                                <Box>
                                    Status
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={2} textAlign="center">
                            <Box>
                                Action
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}