import { Box, Dialog } from "@mui/material"
import { motion } from 'framer-motion';
import SyncTwoToneIcon from '@mui/icons-material/SyncTwoTone';

export const SwitchingLoader = ({load, logo}) => {
    return (
        <Dialog open={load} keepMounted fullWidth={true} maxWidth={'md'}  
        PaperProps={{
            style: {
                backgroundColor: 'transparent',
                boxShadow: 'none'
            }
        }}
        sx={{zIndex:1301}}
        >
            <Box display="flex" justifyContent="center" height="300px">
                <Box position="relative" display="flex" justifyContent="center" alignItems="center" >
                    <Box component={motion.div} 
                        animate={{ rotate: -360 }}
                        transition={{ 
                            duration: 2,
                            loop: Infinity,
                            ease: "linear",
                        }}
                    >
                        {load && (
                            <SyncTwoToneIcon sx={{height:'150px', width:{xs:'150px', sm:'150px'}, color:'#FFF'}}/>
                        )}
                    </Box>
                    <Box position="absolute" width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                        {logo !== '' && (
                            <img src={logo} height={35} width={35} alt="Pofsis" />
                        )}
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}