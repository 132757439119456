import { Box } from "@mui/material"
import moment from "moment"

export const Review = ({form, listRegName}) => {
    
    return (
        <>
            <Box fontSize={20} lineHeight="26px" fontWeight={600} mb={2}> Review : Registration Info </Box>
            <Box fontSize={12} fontWeight={400} lineHeight="16px" mb="20px"> Notice! Make sure that your personal information matches what’s written in the document.</Box>
            <Box minHeight={240} width="100%" className="img-border-dashed" borderColor="#E6E6E6" p={2} borderRadius="6px" mt={2} mb={3}>
                <Box sx={{ backgroundImage: `url(${form.data.regFile.photo.val})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '215px', width: '100%' }} />
            </Box>
            <Box fontSize={12} fontWeight={400} lineHeight="19px" mb="6px"> Registration Name </Box>
            <Box fontSize={14} fontWeight={600} lineHeight="22px" mb={1}> 
                {
                    form.data.regName.val !== '' ?  
                        listRegName.data.filter((f) => {return f.id === form.data.regName.val})[0].name 
                    : 
                        ''
                }
            </Box>
            {
                form.data.regName.val === 0 &&
                    <> 
                        <Box fontSize={12} fontWeight={400} lineHeight="19px" mb="6px"> Name of Registration* </Box>
                        <Box fontSize={14} fontWeight={600} lineHeight="22px" mb={1}> {form.data.regOther.val} </Box>
                    </>
            }
            <Box fontSize={12} fontWeight={400} lineHeight="19px" mb="6px"> Registration Type </Box>
            <Box fontSize={14} fontWeight={600} lineHeight="22px" mb={1}> { form.data.regType.val === 0 ? 'Non-Expiring' : 'Expiring'} </Box>
            <Box fontSize={12} fontWeight={400} lineHeight="19px" mb="6px"> Registred Name </Box>
            <Box fontSize={14} fontWeight={600} lineHeight="22px" mb={1}> {form.data.name.val} </Box>
            {
                form.data.regType.val === 1 &&
                    <>
                        <Box fontSize={12} fontWeight={400} lineHeight="19px" mb="6px"> Validity Date </Box>
                        <Box fontSize={12} fontWeight={400} lineHeight="19px" mb="6px"> From </Box>
                        <Box fontSize={14} fontWeight={600} lineHeight="22px" mb={1}> {moment(form.data.date_from.val).format('MM/DD/YYYY')} </Box>
                        <Box fontSize={12} fontWeight={400} lineHeight="19px" mb="6px"> To </Box>
                        <Box fontSize={14} fontWeight={600} lineHeight="22px" mb={1}> {moment(form.data.date_to.val).format('MM/DD/YYYY')} </Box>
                    </> 
            }
            <Box fontSize={12} fontWeight={400} lineHeight="19px" mb="6px"> Registration Number </Box>
            <Box fontSize={14} fontWeight={600} lineHeight="22px" mb={1}> {form.data.number.val} </Box>
        </>
    )
}
