import Dexie from 'dexie'

export const db = new Dexie('biz_db')
db.version(1).stores(
    {
        pol: '++id',
        docs: '++id',
        conlist: '++id',
        reglist:'++id'
    }
)

// MULTIPLE TABLE
// db.reg.add({ id: 1, name: 'Rannie Pavillon', age: 25 });
// db.con.add({ id: 1, name: 'Post 1', content: 'Content 1' });
// db.docs.add({ id: 1, timestamp: Date.now(), data: 'Some data' });
// db.pol.add({ id: 1, timestamp: Date.now(), data: 'Some data' });

// const ubrdb = useLiveQuery(() => db.ubr.toArray());

// const add = async() =>{
//     await reglist.add({ name:'Yancy'});
// }

// const update  = async(id)=>{
//     await reglist.put({id:id , name:"YANCY SAKALAM 😂😂" , age:30})
//     alert("updated your database with static data ")
// }

// const delete = async(id) => reglist.delete(id)



// REGISTRATION
// attachment_id: 233, 
// company_id:2,
// created_by:4,
// end_date:null,
// feature_type:1,
// file_link:"https://pofsis-business.s3.amazonaws.com/biz/dev/1713950938449.pdf",
// file_name:"Master Plan.pdf",
// file_size:"104146",
// file_type:"application/pdf",
// id:233,
// reference_num:"1358481215",
// registered_name: "Juan Dela Cruz",
// registration_id:3,
// registration_name: "Bureau of Internal Revenue",
// series_num:"RGST-000000016",
// start_date: null,
// status: 1,
// type: 0,
// user_favorite_id: null,
// user_favorite_status:null,
// user_id:null


// reglist.add({ 
//     file_link:"https://pofsis-business.s3.amazonaws.com/biz/dev/1713950938449.pdf",
//     file_name:"Master Plan.pdf",
//     file_size:"104146",
//     file_type:"application/pdf",
//     reference_num:"1358481215",
//     registered_name: "Juan Dela Cruz",
//     registration_name: "Bureau of Internal Revenue",
//     series_num:"RGST-000000016",
//     start_date: null,
//     end_date:null,
//     status: 1,
//     type: 0,
//     user_favorite_id: null,
//     user_favorite_status:null,
//     user_id:null
// });
    