import { Box, Skeleton, Typography } from "@mui/material"
import { useState, useContext, useEffect } from "react";
import { rqx } from "../../../../../core/request/API";
import { env } from "../../../../../core/Env";
import { ToolContext } from "../../../../../core/context/ToolContext";


export const Timer = ({expiration, v, __SESSION, __InitTrashData, ftype}) => {
    const { cloud_state } = useContext(ToolContext)
    const { show, successOpen, message, trashFiles, trashFolder } = cloud_state

    const [data, setData] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0
	});
    const [daysLoader, setDaysLoader] = useState(true)
	useEffect(() => {
        let subscribe = true
        if (subscribe) {
            componentWillMount();
            startCount();
        }
        return () => subscribe = false
       //eslint-disable-next-line
	}, []);
    
	const componentWillMount = () => {
		getTimeUntil(expiration);
	};

	let componentDidMount
	const startCount = () => {
		componentDidMount = setInterval(() => getTimeUntil(expiration), 1000);
	}

	const leading0 = (num) => {
        return num < 10 ? "0" + num : num;
	};

	const getTimeUntil = async (expiration) => {
        setDaysLoader(true)
		const time = Date.parse(expiration) - Date.parse(new Date());
        let type = v.type ===  1 ? 'registration' : v.type ===  2 ? 'contracts' : 'documents'
        if (time < 0) {
           let body = {
                id:v.id, 
                user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                company_id:__SESSION.data.uaccess[0].company_id,
                types: v.type === 3 ? 'folder' : v.type === 4 ? 'file' : '',
                ref:'remove'
            }
            let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/${type}/update`, body)
            if (res.message === 'success') {
                await show.set({
                    view:false, 
                    fullview:false,
                    data:''
                })  
                await message.set(`${ftype === 'file' ? 'File' : 'Folder'} removed permanently`)
                await successOpen.set(true)
                (ftype === 'file' ? trashFiles : trashFolder).set(
                    (prev) => (
                        prev.filter(i => { return i.id !== v.id})
                    )
                )
                await __InitTrashData(0)
            }
        	setData({ days: 0, hours: 0, minutes: 0, seconds: 0 });
			
			clearInterval(componentDidMount)
            setDaysLoader(false)
		} else {
           const seconds = Math.floor((time / 1000) % 60);
			const minutes = Math.floor((time / 1000 / 60) % 60);
			const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
			const days = Math.floor(time / (1000 * 60 * 60 * 24));
			setData({ days, hours, minutes, seconds });
            setDaysLoader(false)
		}
	};
    
    return (
        <Box width="100%">
            {
                daysLoader ?
                    <Skeleton variant="text" animation="wave" width="60px" />
                :
                    leading0(data.days) < 10 ?
                        <Typography fontSize={12} ml="7px" fontWeight={400} lineHeight="23px" color="#FA3E3E" noWrap>
                            {data.days !== 0 ? (
                                `${parseInt(leading0(data.days))} Day/s Left`
                            ) : data.hours !== 0 ? (
                                `${parseInt(leading0(data.hours))} Hour/s Left` 
                            ) : data.minutes !== 0 ? (
                                `${parseInt(leading0(data.minutes))} Min/s Left` 
                            ) : data.seconds !== 0 ? (
                                `${parseInt(leading0(data.seconds))} sec/s Left` 
                            ) : (
                                `Permanently Removing. . . `
                            )}
                        </Typography>
                    : 
                        leading0(data.days) < 20 ?
                            <Typography fontSize={12} ml="7px" fontWeight={400} lineHeight="23px" noWrap color="#ff9000">{leading0(data.days)} Days Left </Typography>
                        : 
                            <Typography fontSize={12} ml="7px" fontWeight={400} lineHeight="23px" noWrap color="#ff9000">{leading0(data.days)} Days Left</Typography>
            }
        </Box>
    )
}




// import { Box, Skeleton, Typography } from "@mui/material"
// import { useState, useContext, useEffect } from "react";
// import { rqx } from "../../../../../core/request/API";
// import { env } from "../../../../../core/Env";
// import { ToolContext } from "../../../../../core/context/ToolContext";


// export const Timer = ({expiration, v, __SESSION, __InitTrashData, ftype, data, setData, leading0}) => {
//     const { cloud_state } = useContext(ToolContext)
//     const { show, successOpen, message } = cloud_state
//     const [daysLoader, setDaysLoader] = useState(true)
//     const [subscribe, setSubscribe] = useState(true)

// 	useEffect(() => {
//         if (subscribe) {
//             componentWillMount();
//             startCount();
//         }
//         return () => setSubscribe(false)
// 		//eslint-disable-next-line
// 	}, []);
    
// 	const componentWillMount = () => {
// 		getTimeUntil(expiration);
// 	};

// 	let componentDidMount
// 	const startCount = () => {
// 		componentDidMount = setInterval(() => getTimeUntil(expiration), 1000);
// 	}

// 	const getTimeUntil = async (expiration) => {
//         setDaysLoader(true)
// 		const time = Date.parse(expiration) - Date.parse(new Date());
//         if (time < 0) {
//             let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/${v.type ===  1 ? 'registration' : 'contracts'}/update`, {
//                 id:v.id, 
//                 user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
//                 ref:'remove'
//             })
//             if (res.message === 'success') {
//                 await show.set({
//                     view:false, 
//                     fullview:false,
//                     data:''
//                 })  
//                 await message.set('File removed permanently')
//                 await successOpen.set(true)
//                 await __InitTrashData(0, 'folder')
//                 await __InitTrashData(0, 'file')
//             }
//         	setData({ days: 0, hours: 0, minutes: 0, seconds: 0 });
			
// 			clearInterval(componentDidMount)
//             setDaysLoader(false)
// 		} else {
// 			const seconds = Math.floor((time / 1000) % 60);
// 			const minutes = Math.floor((time / 1000 / 60) % 60);
// 			const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
// 			const days = Math.floor(time / (1000 * 60 * 60 * 24));
// 			setData({ days, hours, minutes, seconds });
//             setDaysLoader(false)
// 		}
// 	};
    
//     return (
//         <Box width="100%">
//             {
//                 daysLoader ?
//                     <Skeleton variant="text" animation="wave" width="60px" />
//                 :
//                     leading0(data.days) < 10 ?
//                         <Typography fontSize={12} fontWeight={400} lineHeight="23px" color="#FA3E3E" noWrap>
//                             {data.days !== 0 ? (
//                                 `${parseInt(leading0(data.days))} Day/s Left`
//                             ) : data.hours !== 0 ? (
//                                 `${parseInt(leading0(data.hours))} Hour/s Left` 
//                             ) : data.minutes !== 0 ? (
//                                 `${parseInt(leading0(data.minutes))} Min/s Left` 
//                             ) : data.seconds !== 0 ? (
//                                 `${parseInt(leading0(data.seconds))} sec/s Left` 
//                             ) : (
//                                 `Permanently Removing. . . `
//                             )}
//                         </Typography>
//                     : 
//                         leading0(data.days) < 20 ?
//                             <Typography fontSize={12} fontWeight={400} lineHeight="23px" noWrap color="#F68511">{leading0(data.days)} Days Left </Typography>
//                         : 
//                             <Typography fontSize={12} fontWeight={400} lineHeight="23px" noWrap color="#A2A3A9">{leading0(data.days)} Days Left</Typography>
//             }
//         </Box>
//     )
// }
