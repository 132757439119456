import { useContext } from "react";
import { Box } from "@mui/material";
import { GMTourstyle } from "../../../core/styling/tour";
import Joyride from "react-joyride";
import { ToolContext } from "../../../core/context/ToolContext";

export const PolPzl = () => {
    const { ubr_state, policy_state } = useContext(ToolContext)
    const { ubrTour, welcomeModal } = ubr_state 
    const { polForm } = policy_state 
   
  
    const polUploadCont = [
        {
            target: '.ubr-upload',
            disableBeacon: true,
            placement:'bottom', 
            content:
                <Box fontSize={14} fontWeight={500} display="flex" alignItems="center" justifyContent="center">
                    Upload the file, which should be an image or PDF, with a maximum size of at least 3 MB.
                </Box>
        }
    ]

    const polInfoCont = [   
        {
            target: '.ubr-Info',
            disableBeacon: true,
            placement:'bottom', 
            content:
                <Box fontSize={14} fontWeight={500} display="flex" alignItems="center" justifyContent="center">
                    Please provide your name and a description of the policy, or indicate if your uploaded files have an issue date and are prepared and approved.
                </Box>
        }
    ]

    return (
        <>
            {(ubrTour.data.pol.upload && polForm.data.file_name === '' && !welcomeModal.data ) && (
                <Joyride
                    steps={polUploadCont} 
                    run={ubrTour.data.pol.upload}
                    continuous={false}
                    hideCloseButton={true}
                    spotlightClicks={true}
                    disableCloseOnEsc={true}
                    spotlightPadding={0}
                    disableOverlay={false}
                    disableOverlayClose={true}
                    disableScrolling={true}
                    disableScrollParentFix={true}
                    showSkipButton={false}
                    styles={GMTourstyle('11px', 'ubr')}
                />
            )}
            {(polForm.data.file_name !== '' && polForm.data.policyname === '' && polForm.data.description === '' && polForm.data.dateissued === '') && (
                <Joyride
                    steps={polInfoCont} 
                    run={ubrTour.data.pol.info}
                    continuous={true}
                    hideBackButton={true}
                    hideCloseButton={true}
                    spotlightClicks={false}
                    disableCloseOnEsc={true}
                    spotlightPadding={7}
                    disableOverlay={false}
                    disableOverlayClose={true}
                    disableScrolling={true}
                    disableScrollParentFix={true}
                    showSkipButton={true}
                    locale={{
                        last:'Got it',
                    }}
                    styles={GMTourstyle('11px', 'last-ubr')}
                />
            )}
        </>
    )
}