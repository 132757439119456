import { Avatar, AvatarGroup, Box, Button, IconButton, Modal, useMediaQuery} from '@mui/material'
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { rqx } from '../../../core/request/API';
import { env } from '../../../core/Env';
import { Loader } from '../../fetching/Loader';
import { CheckBox } from '@mui/icons-material';
import { List } from './List'

export const View = ({pModal, setPModal, __SESSION, isLoading, uai_user, handleClickAgree}) => {

    const hght900 = useMediaQuery('(max-height:900px)');
    const handleClose = async() => {
        await setPModal({...pModal, view: false, data:[], agreeinfo:[], count:0})
    }
    
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true);
    }
    
    const __init = async() => {

        const getAllUsers = await rqx.get(`<<global>>/${env()}/tooluser/gettooluser`, {company_id: __SESSION.data.uaccess[0].company_id !== null ? __SESSION.data.uaccess[0].company_id : 0, tool_id: process.env.REACT_APP_TID, ref: 'active' })
        let samap = getAllUsers.superadmin?.map(v => {return {...v, uname:v.first_name+' '+v.last_name }} )
        let umap = getAllUsers.users?.map(v => {return {...v, uname:v.first_name+' '+v.last_name}} )
        let fdata = [...samap, ...umap]

        let mergeArray = pModal.data.map(pd => {
            let fltrApprovedBy = fdata.find(u => { return u.id === pd.approvedby } )
            let fltrPreparedBy = fdata.find(u => { return u.id === pd.preparedby } )
            return {...pd, approved_name:fltrApprovedBy.uname, prepared_name: fltrPreparedBy.uname }
        })
        let agreeCount = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/policy/agreecount`, {cpid: pModal.data[0].id})
        let agreeList = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/policy/agreelist`, {cpid: pModal.data[0].id})
       
        let agreeMerge = agreeList?.map(pa => {
            let fltrList = umap.filter(u => { return u.id === pa.user_id })
            return {...pa, profile:fltrList.length > 0 && JSON.parse(fltrList[0].profile).profile, uname:fltrList.length > 0 ? fltrList[0].uname : 'Inactive User'}
        })
        let sortArray = agreeMerge.sort((a,b) => a.uname.localeCompare(b.uname))
        setPModal((prev) => ({...prev, data:mergeArray, count:agreeCount, agreeinfo:sortArray})) 
    }

    useEffect(() => {
        __init()
        //eslint-disable-next-line
    }, [])
    
    return (
        <Modal open={pModal.view} >
            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="row">
                <Box minHeight="30px"></Box>
                <Box className="overflowY" height={hght900 ? "100%" : 830} bgcolor="#FFFFFF" width={800} borderRadius="12px 0px 0px 12px" boxShadow="0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)">
                    {isLoading.data ? 
                        <Loader />
                    :
                        <Box p={2} height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center">
                            <Box minHeight="50px" display="flex" alignItems="center" justifyContent="center" fontSize="20px" fontWeight={700} lineHeight="16px">{pModal.data[0].policyname}</Box>
                            <Box width="100%" display="flex" flexDirection="column" justifyContent="flex-start" p={3}>
                                <Box fontSize="14px" fontWeight={600} lineHeight="19px" color="#333333">Description:</Box>
                                <Box fontSize="14px" fontWeight={400} lineHeight="19px" color="#333333">{pModal.data[0].description}</Box>
                            </Box>
                            <Box height="100%" maxHeight="600px" my={2} className="overflowY cstmscroll5">
                                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                    <Box sx={{height: 'auto', width:'100%', borderRadius:'8px 8px 0px 0px'}}>
                                        <Box component={Viewer} fileUrl={pModal.data[0].file_link}/>
                                    </Box>
                                </Worker>
                                {
                                    (__SESSION.data.uaccess[0].role_id === parseInt(uai_user) && pModal.cp_status === null) && (
                                        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" pb={2}>
                                            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" p={2} m={2} gap="8px" fontSize="14px" fontWeight={400} lineHeight="20px">
                                                <CheckBox></CheckBox>
                                                <Box>I hereby acknowledge receipt and agree that I have read this policy in its entirety and if there is any policy and provision that I do not understand, I shall seek clarification from my immediate superior.</Box>
                                            </Box>
                                            <Box display="flex" justifyContent="center" color="#00B74C" fontSize="20px" fontWeight={600} lineHeight="16px" bgcolor="#00be811f" borderRadius="10px" height="40px" width="250px">
                                                <Button onClick={() => handleClickAgree (pModal.id)}>Understood and Agree</Button>
                                            </Box>
                                        </Box>
                                    )
                                }
                            </Box>
                        </Box>
                    }
                </Box>
                <Box className="overflowY" height={hght900 ? "100%" : 830} bgcolor="#F8F9FA" width={350} borderRadius="0px 12px 12px 0px" boxShadow="0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)">
                    <Box minHeight="20px" width="100%" display="flex" justifyContent="flex-end" p={2}>
                        <IconButton sx={{p: '0px !important'}} onClick={() => handleClose()}>
                            <CloseIcon sx={{color: '#313131'}} className="c-pointer"/>
                        </IconButton>
                    </Box>
                    <Box p="5px 24px">
                        <Box width="100%" display="flex" flexDirection="row" mb={2}>
                            <Box width="100%" fontSize="14px" fontWeight={600} lineHeight="16px" color="#000000" mb="20px">Policy Information</Box>
                        </Box>
                        <Box width="100%" display="flex" flexDirection="row" mb={2}>
                            <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#737373">Policy Number</Box>
                            <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#333333">{pModal.data[0].series_num}</Box>
                        </Box>
                        <Box width="100%" display="flex" flexDirection="row" mb={2}>
                            <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#737373">Department</Box>
                            <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#333333">{pModal.data[0].dept_name}</Box>
                        </Box>
                        {__SESSION.data.uaccess[0].role_id !== parseInt(uai_user) &&
                            <Box>
                                <Box width="100%" display="flex" flexDirection="row" mb={2}>
                                    <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#737373">Status</Box>
                                    <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#333333">{pModal.data[0].status !== 1 ? 'Issued':'Uploaded'}</Box>
                                </Box>
                                <Box width="100%" display="flex" flexDirection="row" mb={2}>
                                    <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#737373">Date of Upload</Box>
                                    <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#333333">{moment(pModal.data[0].dateupload).format("MM-DD-YYYY")}</Box>
                                </Box>
                            </Box>
                        }
                        <Box width="100%" display="flex" flexDirection="row" mb={2}>
                            <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#737373">{__SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? 'Date to Issue' : 'Date Issued'}</Box>
                            <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#333333">{moment(pModal.data[0].dateissued).format("MM-DD-YYYY")}</Box>
                        </Box>
                        <Box width="100%" display="flex" flexDirection="row" mb={2}>
                            <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#737373">Prepared By</Box>
                            <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#333333">{pModal.data[0].prepared_name}</Box>
                        </Box>
                        <Box width="100%" display="flex" flexDirection="row" mb={2}>
                            <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#737373">Approved By</Box>
                            <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#333333">{pModal.data[0].approved_name}</Box>
                        </Box>
                        {__SESSION.data.uaccess[0].role_id !== parseInt(uai_user) &&
                            <Box borderTop="1px solid #E6E6E6" pt="28px">
                                <Box width="100%" display="flex" flexDirection="row" mb={2}>
                                    <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#737373">Number of Agree</Box>
                                    <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#333333">{pModal.count}</Box>
                                </Box>
                                <Box width="100%" display="flex" flexDirection="row" mb={2} alignItems="center">
                                    <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#737373">Employees who Agreed</Box>
                                    <Box width="100%" fontSize="14px" fontWeight={400} lineHeight="19px" color="#333333" display="flex">
                                        <AvatarGroup max={3} sx={{gap: '12px', '& .MuiAvatar-root': {width: 32, height: 32, fontSize: 14} }} className='c-pointer' onClick={handleOpen}>
                                            {
                                                pModal.agreeinfo?.map((v,k) => (
                                                    <Avatar src={v.profile} alt="profile" sx={{width: 32, height: 32}} key={k}/>
                                                ))
                                            }
                                        </AvatarGroup>
                                    </Box>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
                <Box minHeight="30px"></Box>
                <List open={open} setOpen={setOpen} pModal={pModal}/>
            </Box>
        </Modal>
    )
}