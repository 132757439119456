import { Box, Button } from "@mui/material";
import { ConfirmRemove, FileInfo, NoFound, Registration, ThreeDotsMenu } from "../../global/DesktopDsg";
import { HandlerThreeDots, HandlerView, Types } from "../../global/Function";
import { Download, DownloadPDF } from "../../../../core/transmit/S3";
import { rqx } from "../../../../core/request/API";
import { env } from "../../../../core/Env";
import { Loader } from "../../Loader";
import { NotifCount } from "../../../../core/request/Notif";

export const List = ({regList, isLoading, regActive, starActive, show, listView, filter, __InitRegList, __SESSION, inftScroll, limit, lid, match, successOpen, message, nav, showDl, openShare, withAccess, shareType, notif_state }) => {

    const handlerFilter = async(type) => {
        filter.set(type)
        __InitRegList(0, type, isLoading, inftScroll, __SESSION, limit, lid, regList)
    }

    const __LoadmoreData = () => {
        __InitRegList(lid.data, filter.data, isLoading, inftScroll, __SESSION, limit, lid, regList)
    }

    const handleUpdate = (e) => {
        nav.push(`/my-cloud/registrations/${window.btoa(show.data.data.id)}`)
        HandlerThreeDots(e, '', '', 'close', regActive, show)
    }

    const HandlerDL = async(e, type) => {
        if (type === 'pdf') {
            let res;
            if (show.data.data.file_type.includes('image')) {
                res = await DownloadPDF(show.data.data.file_link, show.data.data.file_name)       
            }else{
                res = await Download(e, show.data.data.file_link, show.data.data.file_name)
            }
            if (res === 'success') {
                showDl.set(null)
                regActive.set({...regActive.data, val : '', openMenu : null})
                message.set('Successfully download')
                successOpen.set(true)
            }
        }else{
            let res = await Download(e, show.data.data.file_link, show.data.data.file_name)
            if (res === 'success') {
                showDl.set(null)
                regActive.set({...regActive.data, val : '', openMenu : null})
                message.set('Successfully download')
                successOpen.set(true)
            }
        }
    }
    
    const HandlerDelete = async(type) => {
        if (!show.data.remove.loader) {
            if (type === 'alert') {
                show.set({...show.data, remove:{...show.data.remove, val:true}})
            }else if(type === 'cancel'){
                show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}})
            }else{
                show.set({...show.data, remove:{...show.data.remove, loader:true}})
                let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/registration/update`, {
                    id:show.data.data.id, 
                    user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                    status:4, 
                    ref:'trash'
                })
                if (res.message === 'success') {
                    await NotifCount([{file_id:show.data.data.id, file:'reg'}], __SESSION, 'delete')
                    message.set('Successfully removed')
                    successOpen.set(true)
                    regActive.set({...regActive.data, val : '', openMenu : null})
                    __InitRegList(0, filter.data, isLoading, inftScroll, __SESSION, limit, lid, regList)
                    setTimeout(() => {
                        show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}, view:false})
                    }, 1000);
                }
            }
        }
    }

    const handlerShare = async(val) => {
        // if (show.data.data.access === null || show.data.data.access === undefined || ( show.data.data.access !== null && show.data.data.access[0].update)) {
            openShare.set(true)
            regActive.set({...regActive.data, val : '', openMenu :  null})
            show.set({...show.data, data : val, view : false, fullview : false})
            shareType.set('details')
            // shareType.set('create_access')
        // }
    }
    
    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column" className="overflowY">
            <Box minHeight={30} px="40px" pt="40px" pb={3}>
                <Box display="flex" alignitems="center">
                    {
                        Types.map((v,k) => (
                            <Button variant="contained" disableElevation key={k} onClick={() => handlerFilter(v.id)}
                                sx={{p: '8px 24px', lineHeight: '16px', bgcolor: (filter.data === v.id ? '#E2EDE7' : '#FFFFFF'), color: '#333333', mr: '20px', '&:hover': { bgcolor: '#E2EDE7', color: '#107038' } }}
                            >
                                {v.name}
                            </Button>
                        ))
                    }
                </Box>
            </Box>
            <Box px="40px" pb="40px" height="100%" className="overflowY">
                {
                    (isLoading.data ?
                        <Loader />
                    :
                        (           
                            regList.data.length === 0 ?
                                <NoFound msg="No File found. Upload Now!" />
                            :
                                <Registration data={regList.data} regActive={regActive} starActive={starActive} show={show} listView={listView} inftScroll={inftScroll} 
                                    __LoadmoreData={__LoadmoreData} match={match} __SESSION={__SESSION} state={regList} successOpen={successOpen} message={message} withAccess={withAccess}
                                />
                        )
                    )
                }
                {
                    Boolean(regActive.data.openMenu) &&
                        <ThreeDotsMenu open={Boolean(regActive.data.openMenu)} anchorEl={regActive.data.openMenu} 
                            onClose={(e) => HandlerThreeDots(e, '', '', 'close', regActive, show)}
                            viewInfo={() => HandlerView(show.data.data, show, regActive, withAccess)}
                            share={() => handlerShare(show.data.data)}
                            update={(e) => handleUpdate(e)}
                            download={HandlerDL}
                            remove={() => HandlerDelete('alert')}
                            show={show}
                        />
                }
                {
                    show.data.view &&
                        <FileInfo show={show} match={match} __SESSION={__SESSION} state={regList} successOpen={successOpen} message={message} stateActive={regActive} withAccess={withAccess} handlerShare={handlerShare} notif_state={notif_state} />
                }
                <ConfirmRemove show={show} HandlerDelete={HandlerDelete}/>
            </Box>
        </Box>
    )
}