import { useContext } from "react"
import { makeStyles } from "@mui/styles";
import { Box, CircularProgress, Skeleton } from "@mui/material"
import { ToolContext } from "../../../../../core/context/ToolContext"

const useStyles = makeStyles({
    topHalf: {
        color: '#107038',
        '& .MuiCircularProgress-svg': {transform: 'rotate(90deg) !important'}
    }
});

export const Storage = () => {
    const { cloud_state } = useContext(ToolContext)
    const { storage, storageLoader } = cloud_state
   
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
    const classes = useStyles();

    return (
        <Box bgcolor="#FFFFFF" minHeight="154px" borderRadius="12px" p="12px">
            <Box fontSize={14} fontWeight={600} lineHeight="22px" color="#333333">Storage</Box>
            {storageLoader.data ? 
                <Box sx={{position: 'relative'}} height={100} display="flex" justifyContent="center" alignItems="center">
                    <Skeleton variant="text" height={150} width="150px" />
                </Box>
            : 
                <Box sx={{position: 'relative'}} height={100} display="flex" justifyContent="center">
                    <Box sx={{position: 'absolute', clip: 'rect(0px, 190px, 94px, 0px)'}}>
                        <Box sx={{position: 'relative'}} alignItems="center">
                            <CircularProgress
                                variant="determinate"
                                value={100}
                                sx={{color: '#F8F9FA'}}
                                size={190}
                                thickness={4}
                            />
                            <Box sx={{position: 'absolute', left: 0, top: 0}}>
                                <CircularProgress
                                    variant="determinate"
                                    // value={(((1288490188 / storage.data.total) * 100) / 2) + 50}
                                    value={(((storage.data.used / storage.data.total) * 100) / 2) + 50}
                                    className={classes.topHalf}
                                    size={190}
                                    thickness={4}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box mt="50px" textAlign="center">
                        <Box fontSize={20} fontWeight={700} color="#313131">
                            {
                                storage.data.used === 0 ? (
                                    '0.00B'
                                ) : (
                                    parseFloat((storage.data.used / Math.pow(1024, parseInt(Math.floor(Math.log(storage.data.used) / Math.log(1024))))).toFixed(2)) + '' + sizes[parseInt(Math.floor(Math.log(storage.data.used) / Math.log(1024)))]
                                )
                            }
                        </Box>
                        <Box fontSize={12} fontWeight={600} color="#333333">Used of {storage.data.total === 0 ? 0 : parseFloat((storage.data.total / Math.pow(1024, parseInt(Math.floor(Math.log(storage.data.total) / Math.log(1024))))).toFixed(2)) + '' + sizes[parseInt(Math.floor(Math.log(storage.data.total) / Math.log(1024)))]}</Box>
                    </Box>
                </Box>
            }
        </Box>
    )
}