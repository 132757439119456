import { Box, TextField, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { HandlerRegType, ValidateColor } from "../../../../global/Function"
import { FormatFilesize } from "../../../../global/DesktopDsg"
import Pdf  from '../../../../../../assets/images/icons/pdf.png'
import { motion } from "framer-motion"

export const Details = ({form, dsg, listRegName, subparams, modal, ocrLoader, choices}) => {
    const __ChangeHandler = (e) => {
        const { name, value } = e.target
        form.set({...form.data, [name] : { val : value, stat: value === '' ? true : false, msg: value === '' ? 'This field is required.' : ''}})
    }
    
    const handlerOpenSelect = () => {
        if (subparams === 'create') {
            if (!ocrLoader.data) {
                modal.set({...modal.data, openRegList : { val : true } })
            }
        }
    }
    const handlerChangeFile = () => {
        choices.set({...choices.data, val: listRegName.data.filter(v => {return v.id === form.data.regName.val})[0], show : true})
    }
    return (
        <>
            <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={1}>
                Registration Name*
            </Box>
            <TextField fullWidth className={dsg.txtfld}  
                value={ form.data.regName.val !== '' ?  listRegName.data.filter((f) => {return f.id === form.data.regName.val})[0].name : ''} 
                onClick={() => handlerOpenSelect()}
            />
            <Box height={240} width="100%" display="flex" flexDirection="column" className="img-border-dashed" borderColor="#E6E6E6" p={2} borderRadius="6px" mt={2}>
                <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" mb="12px">
                    {
                        form.data.regFile.file.val[0].type.includes('image') ?
                            <Box sx={{ backgroundImage: `url(${form.data.regFile.photo.val})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '100%', width: '100%' }} />
                        :
                            <Box display="flex" alignItems="center" width="100%"  p="9px 13px" bgcolor="#FFFFFF" borderRadius="6px" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)"> 
                                <Box minWidth={32}> 
                                    <img src={Pdf} alt={Pdf} />
                                </Box>
                                <Box width="85%" ml="13px"> 
                                    <Typography color="#333333" fontWeight={600} fontSize={12} lineHeight="16px" noWrap>{form.data.regFile.file.val[0].name}</Typography>
                                    <Box color="#737373" fontWeight={400} fontSize={10} lineHeight="16px"> {FormatFilesize(form.data.regFile.file.val[0].size)} </Box>
                                </Box>
                            </Box>
                    }
                </Box>
                <Box minHeight={32} display="flex" alignItems="center" justifyContent="center" 
                    py={1} fontSize={14} fontWeight={600} lineHeight="14px" 
                    component={motion.div} whileTap={{backgroundColor: '#E2EDE7', color: '#107038', borderRadius: '6px'}}
                    onClick={() => handlerChangeFile()}
                >
                    Change
                </Box>
            </Box>
            <Box fontSize={12} fontWeight={400} mt={3}>
                Check scanned data for accuracy and correct any mistakes you find. Thank you for your assistance!
            </Box>
            <Box fontSize={14} fontWeight={600} lineHeight="16px" my={2}>
                Registration Type
            </Box>
            <Box bgcolor="#F2F2F2" p="4px" gap="4px" borderRadius="6px" display="flex" mb={2}>
                <Box width="100%" fontSize={14} minHeight={36} display="flex" justifyContent="center" alignItems="center" borderRadius="6px" fontWeight={ form.data.regType.val === 1 ? 600 : 400} 
                    bgcolor={ValidateColor(form).bg_xp} 
                    color={ValidateColor(form).color_xp} 
                    onClick={() => HandlerRegType(form, 'exp')}
                >
                    Expiring
                </Box>
                <Box width="100%" fontSize={14}  minHeight={36} display="flex" justifyContent="center" alignItems="center" borderRadius="6px" fontWeight={ form.data.regType.val === 0 ? 600 : 400} 
                    bgcolor={ValidateColor(form).bg_non} 
                    color={ValidateColor(form).color_non} 
                    onClick={() => HandlerRegType(form, 'non-exp')}
                >
                    Non-Expiring
                </Box>
            </Box>
            {
                form.data.regName.val === 0 &&
                    <>
                        <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={1}>  Name of Registration* </Box>
                        <TextField fullWidth className={dsg.txtfld} 
                            placeholder='Ex.: Barangay Clearance, etc.' 
                            name="regOther" 
                            value={form.data.regOther.val}
                            error={form.data.regOther.stat}
                            onChange={(e) => __ChangeHandler(e)} 
                        />
                        <Box fontSize={10} color="red" pl="5px" pt="5px">{form.data.regOther.msg}</Box>
                    </>
            }
            <>
                <Box fontSize={14} fontWeight={600} lineHeight="16px" mt="20px" mb={1}> Registered Name* </Box>
                <TextField fullWidth className={dsg.txtfld} 
                    placeholder='Ex.: Juan Dela Cruz Jr.' 
                    name="name" 
                    value={form.data.name.val}
                    error={form.data.name.stat}
                    onChange={(e) => __ChangeHandler(e)} 
                />
                <Box fontSize={10} color="red" pl="5px" pt="5px">{form.data.name.msg}</Box>
            </>
            {
                form.data.regType.val === 1 &&
                <>
                    <Box fontSize={14} fontWeight={600} lineHeight="16px" mt="20px" mb={1}> Validity Date </Box>
                    <Box fontSize={14} fontWeight={600} lineHeight="16px" mt="20px" mb={1}> From </Box>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker label="" maxDate={new Date()}
                            value={form.data.date_from.val} 
                            onChange={(e)=>__ChangeHandler({target: {name: 'date_from', value: e}})}
                            renderInput={(params) => 
                                <TextField {...params} fullWidth className={dsg.txtfld} error={form.data.date_from.val === null} />
                            }
                        />
                    </LocalizationProvider>
                    <Box fontSize={14} fontWeight={600} lineHeight="16px" mt="20px" mb={1}> To </Box>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker label="" minDate={new Date(form.data.date_from.val)}
                            value={form.data.date_to.val} 
                            onChange={(e)=>__ChangeHandler({target: {name: 'date_to', value: e}})}
                            renderInput={(params) => 
                                <TextField {...params} fullWidth className={dsg.txtfld} error={form.data.date_to.val === null} />
                            }
                        />
                    </LocalizationProvider>
                </>
            }
            <>
                <Box fontSize={14} fontWeight={600} lineHeight="16px" mt="20px " mb={1}> Registration Number* </Box>
                <TextField fullWidth className={dsg.txtfld}
                    name="number" 
                    value={form.data.number.val}
                    error={form.data.number.stat}
                    onChange={(e) => __ChangeHandler(e)} 
                />
                <Box fontSize={10} color="red" pl="5px" pt="5px">{form.data.number.msg}</Box>
            </>
        </>
    )
}
