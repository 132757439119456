import { Box, Typography } from "@mui/material"
import { FormatFilesize } from "../../../../../global/DesktopDsg"
import Pdf from '../../../../../../../assets/images/icons/pdf.png'
import moment from "moment"

export const Review = ({cloud_state}) => {

    const { cform, listConName } = cloud_state
    
    return (
        <>
            <Box fontSize={20} lineHeight="26px" fontWeight={600} mb={2}> Review : Contracts Info </Box>
            <Box fontSize={12} fontWeight={400} lineHeight="16px"> Notice! Make sure that your personal information matches what’s written in the document.</Box>
            <Box py="16px" display="flex" flexDirection="column" gap="12px">
                {cform.data.conFile.file.val !== '' && (
                    <Box width="100%" minHeight="60px" border="1px solid #E6E6E6" borderRadius="6px" display="flex" alignItems="center" justifyContent="space-between" py="14px" px="12px">
                        <Box width="90%" display="flex" alignItems="center" gap="12px">
                            {cform.data.conFile.file.val[0].type.split('/')[1] === 'pdf' ? 
                                <img src={Pdf} alt={Pdf} width={32} height={32} />
                            :
                                <Box component="img" src={cform.data.conFile.photo.val} alt={cform.data.conFile.photo.val} height={32} width={32} borderRadius="12px"/>
                            }
                            <Box width="80%" display="flex" flexDirection="column" ml="13px"> 
                                <Typography color="#333333" fontSize={12} fontWeight={600} lineHeight="16px" noWrap>{cform.data.conFile.file.val[0].name} </Typography>
                                <Box color="#737373" fontWeight={400} fontSize={10} lineHeight="16px"> {FormatFilesize(cform.data.conFile.file.val[0].size)} </Box>
                            </Box>
                        </Box>
                    </Box>
                )}
                {cform.data.conFile2.file.val !== '' && (
                    <Box minHeight="60px" border="1px solid #E6E6E6" borderRadius="6px" display="flex" alignItems="center" justifyContent="space-between" py="14px" px="12px">
                        <Box width="90%" display="flex" alignItems="center" gap="12px">
                            {cform.data.conFile2.file.val[0].type.split('/')[1] === 'pdf' ? 
                                <img src={Pdf} alt={Pdf} width={32} height={32} />
                            :
                                <Box component="img" src={cform.data.conFile2.photo.val} alt={cform.data.conFile2.photo.val} height={32} width={32} borderRadius="12px"/>
                            }
                            <Box width="80%" display="flex" flexDirection="column" ml="13px"> 
                                <Typography color="#333333" fontSize={12} fontWeight={600} lineHeight="16px" noWrap>{cform.data.conFile2.file.val[0].name}</Typography>
                                <Box color="#737373" fontWeight={400} fontSize={10} lineHeight="16px"> {FormatFilesize(cform.data.conFile2.file.val[0].size)} </Box>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
            <Box fontSize={12} fontWeight={400} lineHeight="19px" mb="6px" color="#A2A3A9"> Contracts Name </Box>
            <Box fontSize={14} fontWeight={600} lineHeight="22px" mb={1}> 
                {
                    cform.data.conName.val !== '' && (
                        listConName.data.filter((f) => {return f.id === cform.data.conName.val})[0].name 
                    ) 
                }
            </Box>
            {
                cform.data.conName.val === 0 &&
                    <> 
                        <Box fontSize={12} fontWeight={400} lineHeight="19px" mb="6px" color="#A2A3A9"> Name of Contracts* </Box>
                        <Box fontSize={14} fontWeight={600} lineHeight="22px" mb={1}> {cform.data.conOther.val} </Box>
                    </>
            }
            <Box fontSize={12} fontWeight={400} lineHeight="19px" mb="6px" color="#A2A3A9"> Client/Company Name </Box>
            <Box fontSize={14} fontWeight={600} lineHeight="22px" mb={1}> {cform.data.name.val} </Box>
            <Box fontSize={12} fontWeight={400} lineHeight="19px" mb="6px" color="#A2A3A9"> Validity Date </Box>
            <Box fontSize={14} fontWeight={600} lineHeight="19px" mb="6px" color="#333333"> {moment(cform.data.date_from.val).format('MM/DD/YYYY')} - {moment(cform.data.date_to.val).format('MM/DD/YYYY')} </Box>
        </>
    )
}
