import { Autocomplete, Box, IconButton } from "@mui/material"
import { CstmTextField } from '../../../../../core/global/forms/CstmTextField'
import { NewRemoveRoundIcon } from "../../../../../core/global/GMicons"
import { IOSSwitch } from "../../../../../core/global/forms/CstmToggle"

export const Fields = ({ match, ctgyForm, setCtgyForm, ctgyid, categoryType }) => {

    const handleInputCategory = (e, id, indx, type) => {
        const {name, value, checked} = e.target
       
        let fdata = [...ctgyForm]
        if (type === 'type') {
            ctgyForm[indx][type] = id
        }else{
            ctgyForm[indx][name] = name === 'categoryname' ? value : (checked ? 1 : 0)
        }
        setCtgyForm(fdata)
    }

    const handleRemoveRow = (e, index) => {
        let data = [...ctgyForm]
        data.splice(index, 1)
        data = data.map((v,k) => ({...v, category_num:`CTGRY-00000000${ctgyid + k + 1}`}))
        setCtgyForm(data)
    }

    return (
        ctgyForm.map((v,k) => (
            <Box key={k} width="100%" p={3} display="flex" flexDirection="column" bgcolor="#FFFFFF" alignItems="center" mt={2} borderRadius="10px">
                <Box width="100%" display="flex" flexDirection="row" bgcolor="#FFFFFF" alignItems="center" mt={2} borderRadius="10px">
                    <Box width="100%" display="flex" justifyContent="space-between">
                        <Box mr={2} width="100%">
                            <CstmTextField fullWidth label="Category Number" disabled value={v.category_num}/>
                        </Box>
                        <Box mx={2} width="100%" display="flex" flexDirection="column">
                            <CstmTextField fullWidth label="Category Name" name="categoryname" value={v.categoryname} error={Boolean(v.error)} helperText={v.error} onChange={(e) => handleInputCategory (e, v.id, k, '')} />
                        </Box>
                        <Box ml={2} width="100%">
                            <Autocomplete 
                                noOptionsText="No Result"
                                name="type"
                                autoHighlight={true}
                                options={categoryType}
                                getOptionLabel={(data) => (data.name)}
                                value={ctgyForm[k].type === '' ? null : categoryType.filter((f) => ctgyForm[k].type === f.id ? {...f} : null)[0]}
                                onChange={(e, value) => handleInputCategory (e, value ? value.id : null, k, 'type')}
                                renderInput={(params) => (
                                    <CstmTextField fullWidth label="Type of Concern" error={ctgyForm.type === null} {...params} />
                                )}
                            />
                        </Box>
                    </Box>
                    {
                        ctgyForm.length > 1 &&
                        <Box minWidth="5%" ml={2}>
                            <IconButton variant="contained" sx={{bgcolor: '#D8D5D5', '&:hover':{ backgroundColor: '#1B984F' }}} onClick={(e) => handleRemoveRow(e, k)}>
                                <NewRemoveRoundIcon />
                            </IconButton>
                        </Box>
                    }
                </Box>
                {
                    match.params === 'update' &&
                    <Box width="100%" display="flex" justifyContent="flex-end" gap={1} p="16px 16px 0px 16px">
                        <IOSSwitch name="active" value={ctgyForm.active} checked={ctgyForm[k].active === 1 ? true : false} onClick={(e) => handleInputCategory (e, v.id, k, '')}/>
                        <Box>{v.active === 1 ? 'Active' : 'Inactive'}</Box>
                    </Box>
                }
            </Box>
        ))
    )
}