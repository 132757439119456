import { env } from "../../../core/Env";
import { rqx } from "../../../core/request/API";

export const NotifReg = async (uid, tid) => {
    const register = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`, {
        scope:'/'
    });
   
    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
    
        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
    
    await register.pushManager.subscribe({
        userVisibleOnly: true, 
        applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_KEY)
    }).then(async(response) => {
        const r = JSON.parse(JSON.stringify(response))
        console.log(r)
        await rqx.post(`<<global>>/${env() === 'local' ? 'dev' : env()}/notification/save`, {user_id :uid, tool_id:tid, endpoint:r.endpoint, p256dh:r.keys.p256dh, auth:r.keys.auth, content_encoding:''} )
    }).catch((err) => {
        return err
    })
   
    return {
        'msg': ("Notification" in window) && Notification.permission
    }
}

export const rqxNotifPermission = async (uid, notifications) => {
    return new Promise(function (resolve, reject) {
        const rqxres = Notification.requestPermission((result) => resolve(result));
        if (rqxres) rqxres.then(resolve, reject);
    }).then((rqxres) => {
        if (rqxres !== 'granted') {
            console.log(rqxres)
            // let n = {...notifications.data}
            // n.push.request.stat = false
            // notifications.set(n)
            throw new Error("Notification permission denied.");
        }
        // pushnotif(uid, notifications)
    });
}