import { Box, IconButton, TextField, Typography } from "@mui/material"
import { StarIcon, VeriticalThreeDots } from "../../../../../core/global/Icons"
import { NoFound, UseStyles } from "../../../global/DesktopDsg"
import { Waypoint } from "react-waypoint"
import { SnakeLoader } from "../../../../../core/loader/SnakeLoader"
import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core"
import { useState } from "react"
import { rqx } from "../../../../../core/request/API"
import { env } from "../../../../../core/Env"
import { LoaderFile } from "./Loader"
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { FileExtension } from "../../../global/FileExtension"

export const Files = ({match, handlerShowIcon, docuFile, __LoadMoreData, fileInfi, show, active, rename, user_id, message, successOpen, fileLoader, handlerAddFav, errOpen, __SESSION}) => {
    const uai_user = process.env.REACT_APP_UAI_REGULAR
    const urlParams = new URLSearchParams(window.location.search)
    const dsg = UseStyles()
    const [activeStar, setActiveStar] = useState('')
    
    const handlerThreeDots = (e, k, v, type) => {
        let vdata = {...v, access:v.access === null && match.feature === 'shared-access' && __SESSION.data.uaccess[0].role_id === parseInt(uai_user) ? JSON.parse(window.atob(urlParams.get('access')))[0].access : __SESSION.data.uaccess[0].role_id === parseInt(uai_user) ?  v.access : null}
        active.set({...active.data, val: k, openMenu: e.currentTarget, type : type})
        show.set({...show.data, data : vdata, view : false, fullview : false})
    }
    
    const handlerView = (v) => {
        if (!rename.data.val) {
            show.set({...show.data, view: true, data: v})
        }
    }

    const handlerOnChange = (e, id)=> {
        docuFile.set((prevData) => {
            return prevData.map((v) => {
                if (v.id === id) {
                    return {...v, file_name : e.target.value }
                }
                return v
            })
        })
    }

    const handlerCloseRename = (id) => {
        rename.set({...rename.data, val : false})
        docuFile.set((prevData) => {
            return prevData.map((v) => {
                if (v.id === id) {
                    return {...v, file_name : show.data.data.file_name }
                }
                return v
            })
        })
    }

    const handlerSaveRename = async(v) => {
        if (v.currFname === v.file_name) {
            rename.set({...rename.data, val : false})
            return
        }

        let body = {
            company_folder_id:v.company_folder_id,
            company_id:v.company_id,
            file_name: v.file_name,
            documents_id: v.id,
            user_id: user_id,
            ref: 'file'
        }
        
        const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/documents/update`, body)
        if (res.message === 'success') {
            rename.set({...rename.data, val : false})
            message.set('Successfully saved')
            successOpen.set(true)
        }else if(res.message === "exist"){
            errOpen.set(true)
            message.set('File Name Already Exist')
        }
    }
    
    return (
        fileLoader.data ?
            <LoaderFile />
        :
            <>
                <Box display="flex" alignItems="center" gap="20px" flexWrap="wrap">
                    {
                        docuFile.data.length === 0 ?
                            <NoFound msg="No File found. Upload Now!" />
                        :
                            docuFile.data.map((v,k) => (
                                <Box width={273} height={241} className={dsg.paper} key={k}
                                    bgcolor={active.data.val === k  && active.data.type === 'file'? '#f0f0f0' : '#ffffff'}
                                    onMouseOver={() => handlerShowIcon(k, 'enter', 'file')}
                                    onMouseLeave={() => handlerShowIcon('', 'leave', 'file')}
                                >
                                    <Box className="c-pointer" onClick={() =>  handlerView(v)}>
                                        <Box p="16px 16px 0px 16px" bgcolor="#F8F9FA" borderRadius="8px" height="125px">
                                            {
                                                v.file_type.includes('pdf') ?
                                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                        <Box sx={{maxHeight: '109px', width:'100%', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                                            <Box component={Viewer} fileUrl={v.file_link} defaultScale={SpecialZoomLevel.PageFit} />
                                                        </Box>
                                                    </Worker>
                                                :
                                                    v.file_type.includes('image') ?
                                                        <Box sx={{backgroundImage:`url(${v.file_link})`, backgroundSize:"cover", backgroundPosition:"center center", height: '109px', width: '100%', borderRadius: '6px 6px 0px 0px'}}/>
                                                    :
                                                        <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                                            <FileExtension link={v.file_link} />
                                                        </Box>
                                            }
                                        </Box>
                                        {
                                            (rename.data.id === v.id && rename.data.val &&  rename.data.type === 'file') ?
                                                <TextField size="small" variant="standard" value={v.file_name.split('.')[0]} name="file_name" sx={{mt: '12px', mb: '24px', lineHeight: '22px', fontSize: 14, fontWeight: 600}} onChange={(e) => handlerOnChange(e, v.id)} />
                                            :
                                                <Typography fontSize={14} fontWeight={600} lineHeight="22px" mt="12px" mb="35px" noWrap>{v.file_name}</Typography>
                                        }
                                    </Box>
                                    {
                                        (rename.data.id === v.id && rename.data.val && rename.data.type === 'file') ?
                                            <Box display="flex" alignItems="center" justifyContent="flex-end" gap="8px">
                                                <IconButton onClick={() => handlerSaveRename(v)}
                                                    sx={{p:'4px', bgcolor: '#ffffff', boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)'}} 
                                                >
                                                    <CheckIcon sx={{fontSize: 20, color: '#107038'}} />
                                                </IconButton>
                                                <IconButton onClick={() => handlerCloseRename(v.id)}
                                                    sx={{p:'4px', bgcolor: '#ffffff', boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)'}}
                                                >
                                                    <CloseIcon sx={{fontSize: 20, color: '#FA3E3E'}}  />
                                                </IconButton>
                                            </Box>
                                        :
                                            <Box display="flex" alignItems="center" justifyContent="flex-end" gap="8px">
                                                {
                                                    ((active.data.val === k && active.data.type === 'file') || v.user_favorite_status === 2) &&
                                                        <IconButton sx={{p: '0px !important'}} 
                                                            onClick={(e) => handlerAddFav(e, v)}
                                                            onMouseEnter={() => setActiveStar(k)} 
                                                            onMouseLeave={() => setActiveStar('')}
                                                        >
                                                            <StarIcon fill={ activeStar === k && v.user_favorite_status !== 2 ? '#333333' : v.user_favorite_status === 2 ? '#11783C' : 'none'}
                                                                stroke={activeStar === k && v.user_favorite_status !== 2 ? '#333333' : v.user_favorite_status === 2 ? '#11783C' : '#737373'} 
                                                            />
                                                        </IconButton>
                                                }
                                                {
                                                    active.data.val === k && active.data.type === 'file' &&
                                                        <IconButton sx={{p: '0px !important'}} onClick={(e) => handlerThreeDots(e, k, v, 'file')}>
                                                            <VeriticalThreeDots fill={active.data.openMenu !== null ? "#107038" : "#737373"}  />
                                                        </IconButton>
                                                }
                                            </Box>
                                    }
                                </Box>
                            ))
                        }
                </Box>
                {

                    fileInfi.data &&  (
                        <Box display="flex" justifyContent="center">
                            <Waypoint onEnter={__LoadMoreData} >
                                <Box component="strong" mt={5} display="flex" justifyContent="center">
                                    <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                                </Box>
                            </Waypoint>
                        </Box>
                    )
                }
            </>
    )
}
