import { Box, IconButton, Slide } from "@mui/material";
import { FavToolTip } from "../../../../core/global/ToolTip";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

export const Add = ({rcntAdd, rcntList, isLoading, match, actType, conList, regList, sufficientStorage, insuffShow, install_state }) => {
    const nav = useHistory()
    const [tipShow, setTipShow] = useState(false)
    const btnList = [
        {name: 'Registration', url: 'registrations'},
        {name: 'Contracts', url: 'contracts'},
        // {name: 'Document', url: 'documents'},
    ]

    const handlerSelectAdd = (url) => {
        nav.push(`/my-cloud/${url}/create`)
        rcntAdd.set(false)
    }
    
    const handlerAddIcon = () => {
        if (sufficientStorage.data) {
            insuffShow.set(true)
        }else{
            if (match === 'recent') {
                rcntAdd.set(!rcntAdd.data)
            }else if (match === 'registrations') {
                nav.push(`/my-cloud/${match}/create`)
                actType.set('create')
            }else if (match === 'contracts') {
                nav.push(`/my-cloud/${match}/create`)
            }
        }   
    }
    
    const toolTipDsg = () => {
        return(
            <Box bgcolor="#333333" fontSize={14} lineHeight="12px" fontWeight={600} display="flex" alignItems="center">
                Upload your first file.
                <IconButton sx={{p: '0px'}} onClick={() => setTipShow(false)}>
                    <CloseIcon sx={{color: '#FFFFFF29', height: 20, width: 20}} />
                </IconButton>
            </Box>
        )
    }
   
    const [subscribe, setSubscribe] = useState(true)
    useEffect(() => {
        if (subscribe) {
            if (!isLoading.data) {
                if (rcntList.data.reg.length === 0 && rcntList.data.contracts.length === 0 && conList.data.length === 0 && regList.data.length === 0 ) {
                    setTimeout(() => {
                        setTipShow(true)
                    }, 500);
                }
            }
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [isLoading.data])
    
    return (
        <Box position="fixed" display="flex" flexDirection="column" alignItems="flex-end" 
            bottom={match === 'recent' ? (install_state.prompt.data !== null ? 139 :75) : 16} right={40} zIndex={1101} 
            sx={{ transition: 'bottom 0.3s ease 0s'}}
        >
            {
                rcntAdd.data &&
                    <Slide in={rcntAdd.data} direction="up">
                        <Box mb={1}>
                            {
                                btnList.map((v,k) => (
                                    <Box bgcolor="#333333" width="100%" textAlign="center" borderRadius="4px 0px 4px 4px" key={k}
                                        onClick={() => handlerSelectAdd(v.url)}
                                        p={1} fontSize={12} fontWeight={400} lineHeight="12px" 
                                        color="#F2F7FF" mb={1} component={motion.div}
                                        whileTap={{backgroundColor: '#E2EDE7'}}
                                    >
                                        {v.name}
                                    </Box>
                                ))
                            }
                        </Box>
                    </Slide>
            }
            <FavToolTip open={tipShow && match === 'recent' ? true :false} title={toolTipDsg()}  placement="left" arrow>
                <IconButton onClick={() => handlerAddIcon()}
                    sx={{bgcolor: rcntAdd.data ? "#FFFFFF" : "#107038B8", height: 48, width: 48, 
                        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)', 
                        '&:hover': { bgcolor: rcntAdd.data ? "#FFFFFF" : "#107038B8"}
                    }}
                >
                    {rcntAdd.data ? <CloseIcon sx={{ color: "#333333"}} /> : <AddIcon sx={{ color: "#FFFFFF"}} />} 
                </IconButton>
            </FavToolTip>
        </Box>
    )
}