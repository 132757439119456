import { Box, Skeleton } from '@mui/material';

export const SearchLoader = () => {
    return (
        ['','','',''].map((v,k) => (
            <Box display="flex" alignItems="center" justifyContent="space-between" p="8px 16px" key={k}>
                <Box display="flex" alignItems="center" gap="24px">
                    <Skeleton variant="rectangular" width='32px' height="32px" sx={{borderRadius:'100px', bgcolor:'#E5E5E5'}}/>
                    <Skeleton variant="rectangular" width='149px' height="10px" sx={{bgcolor:'#E5E5E5'}}/>
                </Box>
                <Skeleton variant="rectangular" width='86px' height="44px" sx={{borderRadius:'6px', bgcolor:'#E5E5E5'}}/>
            </Box>
        ))
    )
}