import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material"
import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core"
import { FavStarMbl, VeriticalThreeDots } from "../../../../../../core/global/Icons"
import { motion } from "framer-motion"
import moment from "moment"

export const RegGridView = ({handlerView, data, handlerOpenMenu}) => {
    return (
        <Grid container spacing="11px">
            {
                data.map((v,k) => (
                    <Grid item xs={6} sm={6} key={k}>
                        <Box display="flex" flexDirection="column" minHeight={164} component={motion.div} whileTap={{ backgroundColor: '#f0f0f0' }}  onClick={() => handlerView(v)}>
                            {
                                v.file_type === null || v.file_type.includes('image') ?
                                    <Box minHeight="80px">
                                        <Box sx={{backgroundImage: `url(${v.file_link})`, backgroundSize: 'cover', backgroundPosition: 'center center', height: '80px', borderRadius: '6px 6px 0px 0px'}} /> 
                                    </Box>
                                :
                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                        <Box sx={{maxHeight: '80px', minHeight:'80px', width:'100%', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                            <Box component={Viewer} fileUrl={v.file_link} defaultScale={SpecialZoomLevel.PageWidth} />
                                        </Box>
                                    </Worker>
                            }
                            <Box py="12px" px="8px" display="flex" flexDirection="column" height="100%">
                                <Box height="100%">
                                    <Typography fontSize={12} fontWeight={600} lineHeight="19px" sx={{wordBreak: 'break-word'}} className="nowrapY" >{v.registration_name} ({v.registered_name})</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" justifyContent="space-between" height={20} width="100%">
                                    <Box display="flex" alignItems="center">
                                        {
                                            v.user_favorite_status === 2  &&
                                                <FavStarMbl fill={v.user_favorite_status === 2 ? '#11783C' : 'none'} />
                                        }
                                        <Box ml={v.user_favorite_status === 2 && 1} fontSize={10} fontWeight={400} lineHeight="20px"> 
                                            {
                                                v.end_date === null ?
                                                    'Non-Expiring'
                                                :
                                                    `Expiring • ED: ${moment(v.end_date).format('MM/DD/YYYY')}`
                                            }
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center" minHeight={20} minWidth={20}>
                                        <IconButton sx={{p: '0px'}} onClick={() => handlerOpenMenu(v,'reg')}>
                                            <VeriticalThreeDots fill={"#737373"} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                ))
            }
        </Grid>
    )
}

export const ConGridView = ({handlerView, data, handlerOpenMenu}) => {
    return(
        <Grid container spacing="11px">
            {
                data.map((v,k) => (
                    <Grid item xs={6} sm={6} key={k}>
                        <Box display="flex" flexDirection="column" minHeight={164} component={motion.div} whileTap={{ backgroundColor: '#f0f0f0' }} onClick={() => handlerView(v)}>
                            {
                                JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('image')? 
                                    <Avatar variant="rounded" src={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} sx={{borderRadius: '6px'}} />
                            :
                                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                    <Box sx={{height:'80px', maxHeight:'80px', minHeight: '80px', width:'100%', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                        <Box component={Viewer} fileUrl={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link}  defaultScale={SpecialZoomLevel.PageWidth}  />
                                    </Box>
                                </Worker>
                            }
                            <Box py="12px" px="8px" display="flex" flexDirection="column" height="100%">
                                <Box height="100%">
                                    <Typography fontSize={12} fontWeight={600} lineHeight="19px" sx={{wordBreak: 'break-word'}} className="nowrapY" >{v.contract_name} ({v.client_name})</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" justifyContent="space-between" height={20} width="100%">
                                    <Box display="flex" alignItems="center">
                                        {
                                            v.status === 2  &&
                                                <FavStarMbl fill={v.status === 2 ? '#11783C' : 'none'} />
                                        }
                                        <Box ml={v.status === 2 && 1} fontSize={10} fontWeight={400} lineHeight="20px"> 
                                            ED: {moment(v.end_date).format('MM/DD/YY')}
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center" minHeight={20} minWidth={20}>
                                        <IconButton sx={{p: '0px'}} onClick={() => handlerOpenMenu(v,'con')}>
                                            <VeriticalThreeDots fill={"#737373"} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                ))
            }
        </Grid>
    )
}