import { useContext, useState } from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router';
import { ToolContext } from '../../../../../core/context/ToolContext';
import { Custom } from '../../../../../core/styling/Custom';
import { rqx } from '../../../../../core/request/API';
import { env, domain } from '../../../../../core/Env';
import { List } from './List';
import { Wave1 } from '../../../../../core/global/Icons';
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

const ResizePlugin = (slider) => {
    const observer = new ResizeObserver(function () {
      slider.update()
    })
    slider.on("created", () => {
      observer.observe(slider.container)
    })
    slider.on("destroyed", () => {
      observer.unobserve(slider.container)
    })
}

export const Index = () => {
    const cstm = Custom();
    const nav = new useHistory();
    const { tool_state, banner_state, switching_state } = useContext(ToolContext);
    const __SESSION = tool_state.__SESSION.data.uaccess;
    const listBanner = banner_state.bannerList;
    const [currentSlide, setCurrentSlide] = useState(0)
    const [loaded, setLoaded] = useState(false)

    const handleClick = async(e, url, name) =>{
        let userId = tool_state.__SESSION.data.ainfo.aid
        if (name === 'market-place') {
            window.location.href = `${domain('mp').url}/sso/account/auth?ainfo=${encodeURI(JSON.stringify(tool_state.__SESSION.data.ainfo))}`
        }else if(name === 'link'){
            let res = await rqx.get(`<<global>>/${env()}/account/getlinkaccount`, {aid:userId, mode:'linkaccount'})
            if (res) {
                if (res.length > 0) {
                    window.location.href = `${domain('psl').url}/sso/account/auth?ainfo=${encodeURI(JSON.stringify(tool_state.__SESSION.data.ainfo))}`
                }else{
                    if (tool_state.__SESSION.data.ainfo.account_type === 3) {
                        window.location.href = `${domain('psl').url}/sso/account/auth?ainfo=${encodeURI(JSON.stringify(tool_state.__SESSION.data.ainfo))}`
                    }else{
                        switching_state.switchForm.set({...switching_state.switchForm.data, stat: true, platform: 'psl'})
                    }
                }
            }
        }else{
            nav.push(url)
        }
    }
    let __perView = (__SESSION[0].role_id !== 0 ? 2 : banner_state.BannerPerView.data)
    
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        loop: listBanner.data.length > 2 ? true : false,
        created() {
            setLoaded(true)
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        slides: {perView: __perView, spacing: 10},
        breakpoints: {
            "(min-width:1024px)": {
                slides: {perView: 2, spacing: 10},
            },
            "(min-width:1440px)": {
                slides: {perView: 3, spacing: 1},
            },
        },
        
    },[ResizePlugin]);

    const currentDayStatus = () => {
        const dateToday = new Date()
        const hours = dateToday.getHours()
        let dayStatus;
        if (hours < 12 ) {
            dayStatus = 'Morning'
        }else if (hours < 18) {
            dayStatus = 'Afternoon'
        }else{
            dayStatus = 'Evening'
        }
        return dayStatus;
    }

    return (
        <Box height="100%" width="100%" px={3}>
            <Box pt={5}>
                <Box fontSize={20} component="strong">Dashboard {tool_state.__SESSION.data.uaccess !== undefined && `(${tool_state.__SESSION.data.uaccess[0].role_name === 'Super Admin' ? 'Owner' : tool_state.__SESSION.data.uaccess[0].role_name === 'Regular User' ? 'User' : tool_state.__SESSION.data.uaccess[0].role_name})`}</Box>
            </Box>
            <Box mt={3} display="flex">
                <Box component="strong" pr={.5}> Extraordinary {currentDayStatus()}, </Box>
                <Box component="span" fontWeight={500} pr={.5}> 
                    {tool_state.__SESSION.data.pinfo.length === 0 ? 'Hero ' : tool_state.__SESSION.data.pinfo[0].first_name }
                </Box>
                <Wave1 fill='#FFCB22'/>
            </Box>
            {banner_state.isShow.data && listBanner.data.length > 0 && (
                <Box mt={3} pb={1}>
                    <Box component="span" fontWeight={500}> Here's where you can begin your journey.</Box>
                </Box>
            )}
            <List isShow={banner_state.isShow.data} sliderRef={sliderRef} listBanner={listBanner} handleClick={handleClick} cstm={cstm} loaded={loaded} instanceRef={instanceRef} currentSlide={currentSlide} />
        </Box>
    )
}