import { useContext, useState, useEffect } from "react"
import { Box, IconButton, Modal, Slide } from "@mui/material"
import { useHistory } from "react-router-dom"
import { ArrowLeft, GridViewIcon, ListViewIcon } from "../../../../../core/global/Icons"
import { MblFetchingLoader } from "../../../../fetching/Loader"
import { ToolContext } from "../../../../../core/context/ToolContext"
import { Index as List } from "./list/Index"
import { __InitConList } from "../../../global/Function"
import { MblAlert } from "../../../../../core/global/forms/CstmModal"
import { ConfirmRemove } from "../../../global/DesktopDsg"
import { rqx } from "../../../../../core/request/API"
import { env } from "../../../../../core/Env"
import { FormCon } from "./setup/Index"
import { ShareAccess } from "../shareAccess/Index"

export const Contracts = ({match}) => {
    const nav = useHistory()
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { isLoading, inftScroll, limit, lid, conList, listView, conActive, show, csuccessOpen, message, reloadStorage, openShare, shareType } = cloud_state
    const { __SESSION } = tool_state
    const [open, setOpen] = useState(true) 
    const [subscribe, setSubscribe] = useState(true)

    const handlerBack = () => {
        setOpen(false)
        setTimeout(() => {
            reloadStorage.set(true)
            nav.push('/my-cloud/recent/list')
        }, 200);
    }

    const HandlerDelete = async(type) => {
        if (!show.data.remove.loader) {
            if(type === 'cancel'){
                show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}})
            }else{
                show.set({...show.data, remove:{...show.data.remove, loader:true}})
                let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/contracts/update`, {
                    id:show.data.data.id, 
                    user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                    status:4, 
                    ref:'trash'
                })
                if (res.message === 'success') {
                    message.set('Successfully removed')
                    csuccessOpen.set(true)
                    conActive.set({...conActive.data, val : '', openMenu : null})
                    __InitConList(0, isLoading, inftScroll, __SESSION, limit, lid, conList)
                    setTimeout(() => {
                        show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}, view:false})
                    }, 500);
                }
            }
        }
    }

    const __LoadmoreData = () => {
        __InitConList(lid.data, isLoading, inftScroll, __SESSION, limit, lid, conList)
    }
    
    useEffect(() => {
        if (subscribe) {
            listView.set(true)
            __InitConList(0, isLoading, inftScroll, __SESSION, limit, lid, conList)
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [])

    return (
        match.params === 'list' ?
            <>
                <Modal open={open} className="noutlined">
                    <Slide in={open} direction="left" className="noutlined">
                        <Box height="100%" width="100%" bgcolor="#ffffff" display="flex" flexDirection="column">
                            <Box minHeight="44px" maxHeight="44px" display="flex" alignItems="center" justifyContent="space-between" p="12px 16px">
                                <IconButton onClick={() => handlerBack()}>
                                    <ArrowLeft stroke="#313131" width="16" height="16" />
                                </IconButton>
                                <Box fontSize={16} fontWeight={600} lineHeight="26px">Contracts</Box>
                                <IconButton onClick={() => listView.set(!listView.data)}>
                                    {listView.data ? <ListViewIcon /> : <GridViewIcon />}
                                </IconButton>
                            </Box> 
                            <Box height="100%" width="100%" className="overflowY">
                                {isLoading.data ?
                                    <MblFetchingLoader mt={0} />    
                                :
                                    <List conList={conList} listView={listView} conActive={conActive} show={show} __SESSION={__SESSION} csuccessOpen={csuccessOpen} message={message} inftScroll={inftScroll} __LoadmoreData={__LoadmoreData} openShare={openShare} shareType={shareType} />
                                }
                            </Box>
                            <Box minHeight="60px"></Box>
                        </Box>
                    </Slide>
                </Modal>
                <MblAlert successOpen={csuccessOpen.data} setSuccessOpen={csuccessOpen.set} text={message.data} />
                <ConfirmRemove show={show} HandlerDelete={HandlerDelete}/>
                {
                    openShare.data &&
                        <ShareAccess />
                }
            </>
        :
            <FormCon match={match.params}/>
    )
}