import { Box, IconButton, Skeleton, TextField } from "@mui/material"
import { StarIcon, VeriticalThreeDots } from "../../../../../core/global/Icons"
import { NoFound, Tooltipdsg } from "../../../global/DesktopDsg";
import { Waypoint } from "react-waypoint";
import { SnakeLoader } from "../../../../../core/loader/SnakeLoader";
import { rqx } from "../../../../../core/request/API";
import { env } from "../../../../../core/Env";
import { useHistory } from 'react-router-dom'
import { FavToolTip } from "../../../../../core/global/ToolTip";
import FolderIcon from '../../../../../assets/images/icons/folder.png'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export const Folder = ({cloud_state, addFav, handlerThreeDots, __LoadmoreFolders, showThreeDots, rename, __SESSION}) => {
    const { docuFolder, folderLoader, folderInfi, active, message, successOpen, show, errOpen } = cloud_state
    const uai_user = process.env.REACT_APP_UAI_REGULAR
    const nav = useHistory()
    
    const handlerRedirectTo = async(v) => {
        let uid = parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1])
        let feature = v.access === null && uid === v.created_by ? 'documents' : 'shared-access'
        let access = [{access:JSON.stringify(v.access)}]
        nav.push(`/my-cloud/${feature}/folder?subfname=${v.folder_name}&subfid=${window.btoa(v.folder_id)}&f_id=${window.btoa(v.folder_id)}${feature === 'shared-access' && __SESSION.data.uaccess[0].role_id === parseInt(uai_user) ? `&access=${window.btoa(JSON.stringify(access))}` : '' } `)
    }

    const handlerSaveRename = async(v) => {
       
        if (v.currFname === v.folder_name) {
            rename.set({...rename.data, val : false})
            return
        }

        let body = {
            parent_id:v.parent_id,
            company_id:v.company_id,
            folder_name: v.folder_name,
            folder_id: v.folder_id,
            user_id: parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
            ref: 'folder'
        }
        const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/documents/update`, body)
      
        if (res.message === 'success') {
            rename.set({...rename.data, val : false})
            message.set('Successfully saved')
            successOpen.set(true)
        }else if(res.message === "exist"){
            errOpen.set(true)
            message.set('Folder Name Already Exist')
        }
    }

    const handlerCloseRename = (id) => {
        rename.set({...rename.data, val : false})
        docuFolder.set((prevData) => {
            return prevData.map((v) => {
                if (v.id === id) {
                    return {...v, folder_name : show.data.data.folder_name }
                }
                return v
            })
        })
    }

    const handlerOnChange = (e, id) => {
        docuFolder.set((prevData) => {
            return prevData.map((v) => {
                if (v.id === id) {
                    return {...v, folder_name : e.target.value}
                }
                return v
            })
        })
    }

    return (
        folderLoader.data ?
            <Box height="100%" width="100%" display="flex" flexDirection="column" >
                <Box height="100%" display="flex" flexDirection="column">
                    <Box height="100%">
                        <Box display="flex" alignItems="center" gap="20px" flexWrap="wrap">
                            {
                                ['','',].map((v,k) => (
                                    <Box width={273} height={64} display="flex" alignItems="center" justifyContent="space-between"  className="c-pointer" key={k}>
                                        <Skeleton variant='rectangular' height="100%" width="100%" sx={{borderRadius: '6px'}} />
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        :
            docuFolder.data.length === 0 ? 
                <NoFound msg="No favorite folder" submsg="Activate stars for folder you wish to find later with ease." folder={true} />
            :
                <>
                    <Box display="flex" alignItems="center" gap="20px" flexWrap="wrap">
                        {
                            docuFolder.data.map((v, k) => (
                                <Box width={273} height={64} display="flex" alignItems="center" justifyContent="space-between" p="8px 12px" className="c-pointer"  mb="20px" key={k}
                                    boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02)" borderRadius="6px"  
                                    bgcolor={active.data.val === k && active.data.type === 'folder' ? '#f0f0f0' : '#ffffff'}
                                    onMouseOver={() => showThreeDots(k, 'enter', 'folder')}
                                    onMouseLeave={() => showThreeDots('', 'leave', 'folder')}
                                    onClick={() => handlerRedirectTo(v)}
                                    // onClick={() => handlerRedirectTo(window.btoa(v.folder_id), v.folder_name, window.btoa(v.id))}
                                    >
                                    <Box display="flex" alignItems="center" gap="8px">
                                        <img src={FolderIcon} alt="folder-img" />
                                        {
                                            rename.data.id === v.id && rename.data.val && rename.data.type === 'folder' ?
                                                <TextField size="small" variant="standard" value={v.folder_name} sx={{ lineHeight: '22px', fontSize: 14, fontWeight: 600}} onChange={(e) => handlerOnChange(e, v.id)} />
                    
                                            :
                                                <Box fontSize={14} lineHeight="22px" fontWeight={600}>{v.folder_name}</Box>
                                        }
                                    </Box>
                                    {
                                        (rename.data.id === v.id && rename.data.val && rename.data.type === 'folder') ?
                                            <Box display="flex" alignItems="center" justifyContent="flex-end" gap="8px">
                                                <IconButton onClick={() => handlerSaveRename(v)}
                                                    sx={{p:'4px', bgcolor: '#ffffff', boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)'}} 
                                                >
                                                    <CheckIcon sx={{fontSize: 20, color: '#107038'}} />
                                                </IconButton>
                                                <IconButton onClick={() => handlerCloseRename(v.id)}
                                                    sx={{p:'4px', bgcolor: '#ffffff', boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)'}}
                                                >
                                                    <CloseIcon sx={{fontSize: 20, color: '#FA3E3E'}}  />
                                                </IconButton>
                                            </Box>
                                        :
                                            <Box display="flex" alignItems="center" gap="8px">
                                                <FavToolTip title={Tooltipdsg(v.user_favorite_status)}>
                                                    <IconButton sx={{p: '0px !important'}} onClick={(e) => addFav(e,v)}>
                                                        <StarIcon fill={v.user_favorite_status === 2 ? '#11783C' : 'none'} 
                                                            stroke={v.user_favorite_status === 2 ? '#11783C' : '#737373'} 
                                                        />
                                                    </IconButton>
                                                </FavToolTip>
                                                { active.data.val === k && active.data.type === 'folder' &&
                                                    <IconButton sx={{p: '0px !important'}}  onClick={(e) => handlerThreeDots(e, k, v, 'folder')}>
                                                        <VeriticalThreeDots fill={"#737373"}   />
                                                    </IconButton>
                                                }
                                            </Box>
                                    }
                                </Box>
                            ))
                        }
                    </Box>
                    {folderInfi.data && 
                        <Box display="flex" justifyContent="center">
                            <Waypoint onEnter={__LoadmoreFolders} >
                                <Box component="strong" mt={5} display="flex" justifyContent="center">
                                    <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                                </Box>
                            </Waypoint>
                        </Box>
                    }
                </>
    )
}
