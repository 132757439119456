import { Avatar, Box, IconButton, TextField, Typography } from "@mui/material"
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { motion } from "framer-motion"
import { StarIcon, VeriticalThreeDots } from "../../../../../../core/global/Icons"
import { NoFound } from "../../../../global/DesktopDsg"
import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core"
import { useContext } from "react";
import { ToolContext } from "../../../../../../core/context/ToolContext";
import { rqx } from "../../../../../../core/request/API";
import { env } from "../../../../../../core/Env";
import { LoaderFiles } from "./Loader";
import FolderIcon from "../../../../../../assets/images/icons/folder.png"
import { FileExtension } from "../../../../global/FileExtension";

export const Files = ({ match, handlerThreeDots, handlerView }) => {
    const { cloud_state, tool_state} = useContext(ToolContext)
    const { fileLoader, docuFile, rename, show, errOpen, successOpen, message } = cloud_state
    const { __SESSION } = tool_state
    const user_id = atob(__SESSION.data.ainfo.aid).split('_', 2)[1];

    const handlerOnChange = (e, val) => {
        docuFile.set((prevData) => {
            return prevData.map((v) => {
                if (val.id === v.id && val.feature_type === v.feature_type) {
                    return val.feature_type === 3 ? {...v, folder_name : e.target.value} : {...v, file_name : e.target.value}
                }
                return v
            })
        })
    }

    const handlerCloseRename = (val) => {
        rename.set({...rename.data, val : false})
        docuFile.set((prevData) => {
            return prevData.map((v) => {
                if (val.id === v.id && val.feature_type === v.feature_type) {
                    return val.feature_type === 3 ? {...v, folder_name : show.data.data.folder_name } : {...v, file_name : show.data.data.file_name} 
                }
                return v
            })
        })
    }
   
    const handlerSaveRename = async(v) => {
        if ((v.feature_type === 3 && v.currFname === v.folder_name) || (v.feature_type === 4 && v.currFname === v.file_name)) {
            rename.set({...rename.data, val : false})
            return
        }

        let prms = v.feature_type === 3 ? { parent_id:v.parent_id, folder_name: v.folder_name,  folder_id: v.folder_id} : {company_folder_id:v.company_folder_id, file_name: v.file_name+'.'+v.file_extension,  documents_id: v.id}
        let body = {
            company_id:v.company_id,
            user_id: user_id,
            ref: v.feature_type === 3 ? 'folder' : 'file',
            ...prms
        }

        const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/documents/update`, body)
        if (res.message === 'success') {
            docuFile.set((prevData) => {
                return prevData.map((k) => {
                    if (k.id === v.id && k.feature_type === v.feature_type) {
                        return {...k, currFname : v.feature_type === 3 ? v.folder_name : v.file_name } 
                    }
                    return k
                })
            })
            rename.set({...rename.data, val : false})
            message.set('Successfully saved')
            successOpen.set(true)
        }else if(res.message === "exist"){
            errOpen.set(true)
            message.set(`${v.feature_type === 3 ?'Folder':'File'} Name Already Exist`)
        }
    }

    const handlerAddFav = async(e,v) => {
        e.stopPropagation()
        let body = {
            status: v.user_favorite_status === 2 ? 1 : 2, 
            registration_id: "",
            contracts_id: "",
            user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
            documents_id: v.feature_type === 4 ? v.id : "",
            folder_id:  v.feature_type === 3 ? v.id : ""
        }
        docuFile.set(
            docuFile.data.map((d) => (d.id === v.id && v.feature_type === d.feature_type) ? {...d, user_favorite_status : v.user_favorite_status === 2 ? 1 : 2} : {...d} )
        )
        let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/favorites/create`, body)
        if (res.message === 'success') {
            await message.set(v.user_favorite_status === 2 ? 'Sucessfully removed from favorites' : 'Sucessfully added to favorites')
            successOpen.set(true)
            show.set({...show.data, data : {...show.data.data, user_favorite_status : v.user_favorite_status === 2 ? 1 : 2}  })
        }
    }

    return (
        fileLoader.data ?
            <LoaderFiles />
        :
            ( docuFile.data.length === 0 ?
                <NoFound msg="No File found. Upload Now!" />
            :
                docuFile.data?.map((v,k)=> (
                    <Box key={k} p={1} display="flex" alignItems="center" justifyContent="space-between" bgcolor="#FFFFFF" borderRadius="6px" component={motion.div} whileTap={{ backgroundColor: '#f0f0f0' }} width="100%" fontSize={12} fontWeight={600} gap="12px">
                        <Box width="70%" display="flex" alignItems="center" gap="12px" onClick={rename.data.id === v.id && rename.data.val  ? null  : () => handlerView(v)} >
                            {v.feature_type === 4 ?
                                (
                                    v.file_type.includes('pdf') ?
                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                        <Box sx={{maxHeight: '40px', width:'40px', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                            <Box component={Viewer} fileUrl={v.file_link} defaultScale={SpecialZoomLevel.PageFit} />
                                        </Box>
                                    </Worker>
                                :
                                    v.file_type.includes('image') ?
                                        <Box sx={{backgroundImage:`url(${v.file_link})`, backgroundSize:"cover", backgroundPosition:"center center", height: '40px', width: '40px', borderRadius: '6px 6px 0px 0px'}}/>
                                    :
                                        <Box height="100%" width="40px" display="flex" alignItems="center" justifyContent="center">
                                            <FileExtension link={v.file_link} width={40} height={40}/>
                                        </Box>
                                )
                            :
                                <Avatar src={FolderIcon} variant="square" alt="folder-img" sx={{width:40, height:40}}/>
                            }
                            {rename.data.id === v.id && rename.data.val ?
                                    <TextField size="small" variant="standard" value={v.feature_type === 4 ? v.file_name : v.folder_name} sx={{ lineHeight: '22px', fontSize: 14, fontWeight: 600}} onChange={(e) => handlerOnChange(e, v)}  />
                            :
                                <Typography fontSize={12} fontWeight={600} lineHeight="19px" width="100%" noWrap> 
                                    {v.feature_type === 4 ? v.file_name : v.folder_name}
                                </Typography>
                            }
                        </Box>

                        {(rename.data.id === v.id && rename.data.val) ?
                            <Box display="flex" alignItems="center" justifyContent="flex-end" gap="8px">
                                <IconButton onClick={() => handlerSaveRename(v)}
                                    sx={{p:'4px', bgcolor: '#ffffff', boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)'}} 
                                >
                                    <CheckIcon sx={{fontSize: 20, color: '#107038'}} />
                                </IconButton>
                                <IconButton onClick={() => handlerCloseRename(v)}
                                    sx={{p:'4px', bgcolor: '#ffffff', boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)'}}
                                >
                                    <CloseIcon sx={{fontSize: 20, color: '#FA3E3E'}}  />
                                </IconButton>
                            </Box>
                        :
                        
                            <Box display="flex" alignItems="center" minHeight={20} minWidth={40} > 
                                <Box display="flex" alignItems="center" justifyContent="flex-end" gap="8px">
                                    <IconButton sx={{p: '0px !important'}} onClick={(e) => handlerAddFav(e, v)}>
                                        <StarIcon fill={ v.user_favorite_status !== 2 ? '#FFFFFF' : '#11783C'}
                                            stroke={v.user_favorite_status !== 2 ? '#11783C' : '#11783C'} 
                                        />
                                    </IconButton>
                                    <IconButton sx={{p:'0px'}} onClick={(e) => handlerThreeDots(e, k, v)}>
                                        <VeriticalThreeDots fill={"#737373"} />
                                    </IconButton>
                                </Box>
                            </Box>
                        }
                    </Box>
                ))
            )
    )
}