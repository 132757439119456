import { Avatar, Box, Checkbox, IconButton, Typography } from "@mui/material"
import { NoFound } from "../../../global/DesktopDsg"
import { TrashIcon, VeriticalThreeDots } from "../../../../../core/global/Icons"
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { Waypoint } from "react-waypoint"
import { SnakeLoader } from "../../../../../core/loader/SnakeLoader"
import { Timer } from "../Timer";
import { CheckCircle, CircleOutlined } from "@mui/icons-material";
import { Loader } from "../../../Loader";
import moment from "moment";
import FolderIcon from '../../../../../assets/images/icons/folder.png'
import { useContext } from "react";
import { ToolContext } from "../../../../../core/context/ToolContext";
import { FileExtension } from "../../../global/FileExtension";

export const FileList = ({ __LoadmoreFilesData, __InitTrashFilesList, handlerThreeDots, showThreeDots, handlerSelect }) => {
    const uai_user = process.env.REACT_APP_UAI_REGULAR;
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { trashFiles, isLoading, show, active, listView, selectedFiles , inftScroll } = cloud_state
    const { __SESSION } = tool_state
    // console.log(__SESSION.data.uaccess[0].role_id === parseInt(uai_user))

    const handlerViewFile = (val) => {
        if (val.type === 3) {
            console.log(val)
        }else{
            show.set({...show.data, data : val, view : true, fullview : false})
            active.set({...active.data, val : '', openMenu : null})
        }
    }

    return (
        (isLoading.data ?
            <Loader />
        :
            (trashFiles.data.length === 0 ?
                <NoFound msg="Trash File is Empty" />
            :
                <>
                    <Box fontSize={14} fontWeight={600} lineHeight="19px" mb={2}>Files</Box>
                    {
                        listView.data ?
                            trashFiles.data.map((v,k) => (
                                <Box display="flex" justifyContent="space-between" alignItems="center" p="12px" borderRadius="6px" key={k} mb={2} className="c-pointer" 
                                    sx={{bgcolor: active.data.openMenu !== null && k === active.data.val  ? '#f0f0f0' : v.checked ? '#E2EDE7' : '#ffffff', '&:hover': { bgcolor:  v.checked ? '#E2EDE7' : '#f0f0f0' } }}
                                    onMouseOver={() => showThreeDots(k, 'enter')} 
                                    onMouseLeave={() => showThreeDots('', 'leave')}
                                >
                                    <Box display="flex" alignItems="center" width="100%" position="relative">
                                         {
                                             ((active.data.val === k || v.checked || selectedFiles.data.length > 0) && (v.access === null || __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) )) ?
                                                <Box height={20} display="flex" alignItems="center" bgcolor="#ffffff" borderRadius="6px"  ml="14px" position="absolute" zIndex={999}>
                                                    <Checkbox size="small" sx={{ p: '0px !important'}}
                                                        checked={v.checked}
                                                        onChange={(e) => handlerSelect(e, v.id, v)}
                                                        icon={  <CircleOutlined sx={{fontSize: 20}} /> } 
                                                        checkedIcon={ <CheckCircle sx={{fontSize: 20}} /> } 
                                                    />    
                                                </Box>
                                            :   
                                                <Box height={20} position="absolute" />
                                        }
                                        {
                                            v.type === 3 ?
                                                <Box display="flex" alignItems="center" justifyContent="center">
                                                    <img src={FolderIcon} alt="folder-img" />
                                                </Box>
                                            :
                                                JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('pdf')? 
                                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                        <Box sx={{maxHeight: '48px', width:'48px', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                                            <Box component={Viewer} fileUrl={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} defaultScale={SpecialZoomLevel.PageFit} />
                                                        </Box>
                                                    </Worker>
                                                :
                                                    <Avatar src={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} variant="rounded" sx={{ width: 48, height: 48, borderRadius: '6px' }} />
                                        }
                                        <Box display="flex" flexDirection="column" ml="12px" onClick={() => handlerViewFile(v)}>
                                            <Box fontSize={14} fontWeight={600} lineHeight="19px">{`${v.type_name === null ? '' : v.type_name}(${v.name})`}</Box>
                                            <Box display="flex" alignItems="center">
                                                <TrashIcon fill="#F68511" width="12" height="12" />
                                                <Timer expiration={moment(v.updated_at).add(30, 'd').format('MM-DD-YYYY HH:mm:ss')} v={v} __SESSION={__SESSION}  __InitTrashData={__InitTrashFilesList} ftype="file"/>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        {
                                            active.data.val === k &&
                                                <IconButton sx={{p: '0px !important'}} onClick={(e) => handlerThreeDots(e, k, v, 'open')}>
                                                    <VeriticalThreeDots fill={active.data.openMenu !== null ? "#107038" : "#737373"} />
                                                </IconButton>
                                        }
                                    </Box>
                                </Box>
                            ))
                        :
                            <Box width="100%" display="flex" flexWrap="wrap" gap="20px">
                                {
                                    trashFiles.data.map((v, k) => (
                                        <Box minWidth='273px' maxWidth='273px' minHeight={241} key={k}>
                                            <Box height="100%" width="100%" p="12px" borderRadius="6px" boxShadow='0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)' bgcolor="#ffffff" display="flex" flexDirection="column"
                                                sx={{bgcolor: active.data.openMenu !== null && k === active.data.val  ? '#f0f0f0' : v.checked ? '#E2EDE7' : '#ffffff', '&:hover': { bgcolor:  v.checked ? '#E2EDE7' : '#f0f0f0' } }}
                                                onMouseOver={() => showThreeDots(k, 'enter')} 
                                                onMouseLeave={() => showThreeDots('', 'leave')}
                                            > 
                                                <Box height="100%">
                                                    <Box  bgcolor="#F8F9FA" borderRadius="8px" minHeight={125}>
                                                        {
                                                            ((active.data.val === k || v.checked || selectedFiles.data.length > 0) && (v.access === null || __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ) ) ?
                                                                <Box height={20}>
                                                                    <Checkbox size="small" sx={{ p: '0px !important'}}
                                                                        checked={v.checked}
                                                                        onChange={(e) => handlerSelect(e, v.id, v)}
                                                                        icon={  <CircleOutlined sx={{fontSize: 20}} /> } 
                                                                        checkedIcon={ <CheckCircle sx={{fontSize: 20}} /> } 
                                                                    />    
                                                                </Box>
                                                            :   
                                                                <Box height={20} />
                                                        }
                                                        <Box p="0px 16px 0px 16px" className="c-pointer" onClick={() => handlerViewFile(v)}>
                                                            {
                                                                JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('image')? 
                                                                    <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                                                        <Avatar src={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} variant="rounded" sx={{height:100, width: 'auto', borderRadius: '6px 6px 0px 0px'}} />
                                                                    </Box>
                                                                : JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('pdf')? 
                                                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                                        <Box sx={{maxHeight: '109px', width:'100%', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                                                            <Box component={Viewer} fileUrl={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} defaultScale={SpecialZoomLevel.PageFit} />
                                                                        </Box>
                                                                    </Worker>
                                                                :
                                                                    <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                                                        <FileExtension link={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} />
                                                                    </Box>
                                                            }
                                                        </Box>
                                                    </Box>
                                                    <Box height="100%">
                                                        <Typography fontSize={14} fontWeight={600} lineHeight="22px" sx={{wordBreak: 'break-all'}} className="nowrapY" mt="12px">
                                                            {v.type_name} {`(${v.name})`}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box display="flex" alignItems="center" justifyContent="space-between" minHeight={24}>
                                                    <Box display="flex" alignItems="center">
                                                        <TrashIcon fill="#F68511" width="12" height="12" />
                                                        <Timer expiration={moment(v.updated_at).add(30, 'd').format('MM-DD-YYYY HH:mm:ss')} v={v} __SESSION={__SESSION}  __InitTrashData={__InitTrashFilesList} ftype="file"/>
                                                    </Box>
                                                    <Box display="flex" alignItems="center">
                                                        {
                                                            active.data.val === k &&
                                                                <IconButton sx={{p: '0px !important'}} onClick={(e) => handlerThreeDots(e, k, v, 'open')}>
                                                                    <VeriticalThreeDots fill={"#737373"} />
                                                                </IconButton>
                                                        }
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </Box>
                    }
                    {inftScroll.data && 
                        <Box display="flex" justifyContent="center">
                            <Waypoint onEnter={__LoadmoreFilesData} >
                                <Box component="strong" mt={5} display="flex" justifyContent="center">
                                    <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                                </Box>
                            </Waypoint>
                        </Box>
                    }
                </>
            )
        )
    )
}
