import { useEffect, useState, useContext } from 'react';
import { ToolContext } from '../../../../core/context/ToolContext';
import { WelcomeMyCloudMb } from '../../../personalization/WelcomeMyCloud';
import { rqx } from '../../../../core/request/API';
import { env } from '../../../../core/Env';
import { Recent } from './recent/Index';
import { Registrations } from './registrations/Index';
import { Add } from './Add';
import { Contracts } from './contracts/Index';
import { Favorites } from './favorites/Index';
import { Trash } from './trash/Index';
import { NotFoundPage } from '../../../empty/Index';
// import { SharedList } from './shareAccess/sorting/Index';
import { Documents } from './documents/Index';

export const MIndex = ({match}) => {
    const [subscribe, setSubscribe] = useState(true)
    const { personalization_state, cloud_state , notif_state, tool_state, install_state} = useContext(ToolContext)
    const { globalLoader, __SESSION } = tool_state
    const { openCloud, listView, rcntList, isLoading, rcntAdd, actType, conList, regList, sufficientStorage, insuffShow, openShare } = cloud_state
    const { openNotif } = notif_state
    const { personalizationData } = personalization_state

    let res = personalizationData.data.filter(v => {return v.feature_name === 'cloud'} );
    const __initPersonalization = async() => {
        if (res.length === 0) {
            openCloud.set({...openCloud.data, welcome : true})
        }else{
            let welcome = res[0].personalization.filter((v) => { return v.sf_name === 'welcome' && v.status === 1 } );
            let d = {...openCloud.data}
            if (welcome.length === 0) {
                d = {...openCloud.data, welcome : true}
            }
            openCloud.set(d)
        }
    }

    const handlerClose = async() => {
        const user_id = atob(__SESSION.data.ainfo.aid).split('_', 2)[1];
        let filter = personalizationData.data.filter(v => {return v.feature_name !== 'cloud'} );
        let data
        if (res.length > 0) {
            let newprs = res[0].personalization.filter((v) => { return v.sf_name === 'new' && v.status === 1 } );
            let tp = res[0].personalization.filter((v) => { return v.sf_name === 'tooltip' && v.status === 1 } );
            data = {
                user_id:parseInt(user_id), 
                tool_id:parseInt(process.env.REACT_APP_TID), 
                feature_name:'cloud', 
                personalization:[
                    {sf_name:'welcome', status:1},
                    {sf_name:'tooltip', status: newprs.length === 0 ? 0 : 1},
                    {sf_name:'new', status: tp.length === 0 ? 0 : 1},
                ] 
            }
        }
        personalizationData.set([...filter, ...[data]]);
        openCloud.set({...openCloud.data, welcome : false})
        await rqx.post(`<<global>>/${env()}/personalization/create`, data )
    }

    useEffect(() => {
        listView.set(true)
        if (!globalLoader.data) {
            if (!openNotif.data || (("Notification" in window) && Notification.permission !== 'default')) {
                if (subscribe) {
                    __initPersonalization()
                }

                return () =>  setSubscribe(false)
            }
        }
        // eslint-disable-next-line
    }, [globalLoader.data])
    
    return (
        <>
            <Feature match={match}/>
            {__SESSION.data.uaccess[0].company_id !== null && (
                <WelcomeMyCloudMb open={openCloud.data.welcome} event={() => handlerClose()} />
            )}
            {
                ( match.params === 'list' && match.feature !== 'favorites' && match.feature !== 'trash' && match.feature !== 'shared-access' && __SESSION.data.uaccess[0].company_id !== null && !openShare.data) &&
                    <Add rcntAdd={rcntAdd} rcntList={rcntList} isLoading={isLoading} match={match.feature} actType={actType} conList={conList} regList={regList} sufficientStorage={sufficientStorage} insuffShow={insuffShow} install_state={install_state}/>
            }
        </>
    )
}

const Feature = ({match}) => {
    if (match.feature === 'registrations') {
        return <Registrations match={match}/>
    }else if(match.feature === 'contracts'){
        return <Contracts match={match}/>
    }else if (match.feature === 'recent') {
        return <Recent match={match}/>
    }else if (match.feature === 'favorites') {
        return <Favorites match={match} />
    }else if (match.feature === 'trash') {
        return <Trash match={match} />
    }else if (match.feature === 'documents') {
        return <Documents match={match} />
    }else if (match.feature === 'shared-access') {
        return <Documents match={match} />
    }else{
        return <NotFoundPage/>
    }
}