import { Autocomplete, Box } from "@mui/material"
import { CstmTextField } from '../../../../../core/global/forms/CstmTextField'
import { IOSSwitch } from "../../../../../core/global/forms/CstmToggle"

export const Fields = ({ match, categoryType, concernForm, ctgyTypeList, handleInputConcern, teamList }) => {
    
    return (
        <Box height="100%" width="100%" p={3} display="flex" flexDirection="column" bgcolor="#FFFFFF" alignItems="center" mt={2} borderRadius="10px">
            <Box width="100%" display="flex" flexDirection="column" alignItems="center" mt={2} gap={2} >
                <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between">
                    <Box width="100%">
                        <Autocomplete 
                            noOptionsText="No Result"
                            name="type"
                            autoHighlight={true}
                            options={categoryType}
                            getOptionLabel={(data) => (data.name)}
                            onChange={(e, val)=>handleInputConcern(e, val, 'type')}
                            value={concernForm.type ? categoryType.find((f) => f.id === concernForm.type) || null : null}
                            renderInput={(params) => (
                                <CstmTextField fullWidth label="Type of Concern" {...params} />
                            )}
                        />
                    </Box>
                    <Box ml={2} width="100%">
                        <Autocomplete 
                            noOptionsText="No Result"
                            name="category"
                            autoHighlight={true}
                            options={ctgyTypeList.data}
                            getOptionLabel={(data) => (data.name)}
                            onChange={(e, val)=>handleInputConcern(e, val, 'category')}
                            value={concernForm.category ? ctgyTypeList.data.find((f) => f.id === concernForm.category) || null : null}
                            renderInput={(params) => (
                                <CstmTextField fullWidth label="Category" {...params} />
                            )}
                            />
                    </Box>
                    <Box ml={2} width="100%">
                        <Autocomplete 
                            noOptionsText="No Result"
                            name="team"
                            autoHighlight={true}
                            options={teamList.data}
                            getOptionLabel={(data) => (data.name)}
                            onChange={(e, val)=>handleInputConcern(e, val, 'team')}
                            value={concernForm.team === '' ? null : teamList.data.find((f) => f.id === concernForm.team ? {...f} : null)}
                            renderInput={(params) => (
                                <CstmTextField fullWidth label="Team" {...params} />
                            )}
                            />
                    </Box>
                </Box>
                <Box mx={2} width="100%" display="flex" flexDirection="column">
                    <CstmTextField fullWidth multiline rows={5} label="Details" name="details" onChange={(e)=>handleInputConcern(e)} />
                </Box>
            </Box>
            {
                match.params === 'update' &&
                <Box height="100%" width="100%" display="flex" justifyContent="flex-end" gap={1} p="16px 16px 0px 16px">
                    <IOSSwitch name="active" />
                    <Box>Active</Box>
                </Box>
            }
        </Box>
    )
}