import { useContext, useEffect, useState } from "react"
import { Autocomplete, Box, Button, Checkbox, Chip, Divider, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import { CheckBox, CheckBoxOutlineBlank, KeyboardArrowDown } from "@mui/icons-material"
import { ArrowLeft, Search } from "../../../../../../core/global/Icons"
import { ToolContext } from "../../../../../../core/context/ToolContext"
import { SharedWith } from './SharedWith'
import { UseStyles } from "../../../../global/DesktopDsg"
import { UserList } from './UserList'
import { FilterDept } from "./FilterDept"
import { SearchLoader } from '../Loader'

export const CreateAccess = ({usrData, setUsrData}) => {
    const dsg = UseStyles()
    const { cloud_state } = useContext(ToolContext)
    const { departmentList, selectedDept, searchInput, slctAllTxt, allUsrList, withAccess, userList, show, filterActive, openShare, shareType, ulistAccess, confirmShare, menuDept } = cloud_state
    const [slctAll, setSlctAll] = useState(false)
    const [count, setCount] = useState(0)
    const [searchData, setSearchData] = useState(userList.data)
    const [searchLoader, setSearchLoader] = useState(false)
  
    const icon = <CheckBoxOutlineBlank fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;
    const checkedIcon = <CheckBox fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;

    let dept_id
    let check;
    const handlerSelectDept = (e, id, props) => {
        check = !props['aria-selected']
        dept_id = id
    }

    const handlerBck = () => {
        openShare.set(false)
    }

    const handlerOnChangeDept = (e, val) => {
        searchInput.set('')
        if (val.length === 0) {
            setCount(0)
            slctAllTxt.set(false)
            setUsrData(allUsrList.data)
        }
        
        let validateAll = val.filter(v => { return v.name === 'ALL' } )
        setSlctAll(validateAll.length > 0  ? true : false)
        
        let value;
        if (validateAll.length > 0) {
            value = departmentList.data
        }else{
            value = val
        }
        
        let data = []
        let userWithAccess = []
        if (withAccess.data.val.length !== 0) {
            userWithAccess = withAccess.data.val[0].access
        }
        
        value.filter((v) => {
            const au = allUsrList.data.users.filter((au) => au.company_department_id === v.id && au.name !== 'Admin');
            if (withAccess.data.val.length !== 0) {
                let filtered = au.filter(f => !userWithAccess.find(x => f.id === x.id))
                data = [...data, ...filtered]
            }else{
                data = [...data, ...au]
            }
            return false 
        });
        
        let fdata = data.map((v) => {
            const d = (usrData.length > 0 ? usrData.users : userList.data).filter((d) => d.id === v.id );
            return {...v, stat: d.length === 0 ? v.stat : d[0].stat }
        });
        
        let slctAllValidate = fdata.filter(v => { return !v.stat.view})
        if (slctAllValidate.length > 0) { slctAllTxt.set(false) }
        let fltrdata = fdata.filter(v => { return v.id !== show.data.data.created_by } )
        userList.set(fltrdata)
        setUsrData({...usrData, users:fltrdata})
        if (dept_id === 0) {
            if (check) {
                selectedDept.set(departmentList.data)
            }else{
                selectedDept.set([])
            }
        }else{
            selectedDept.set(value)
        }
        filterActive.set({id: 0, name: 'All'})
    }

    const Ulist = async() => {
        const filter_fn = userList.data.map((v) => { return {...v, fullname:`${v.first_name} ${v.last_name}`  } }  )
        const res = filter_fn.filter((item) =>
            item.first_name.toLowerCase().match(searchInput.data.toLowerCase()) || item.last_name.toLowerCase().match(searchInput.data.toLowerCase()) || item.fullname.toLowerCase().match(searchInput.data.toLowerCase())
        );
        slctAllTxt.set(res.length === 0 ? false : res.filter(v => { return !v.stat.view }  ).length > 0 ? false : true)
        await setSearchData(res)
        await setSearchLoader(false)
    }

    const handleSelectAll = () => {
        slctAllTxt.set(!slctAllTxt.data)

        let res;
        if (filterActive.data.name === 'All') {
            if (searchInput.data !== '') {
                res = searchData.map(v => { return {...v, stat:{...v.stat, view:!slctAllTxt.data}} }  )
                setSearchData(res)
            }else{
                res = userList.data.map(v => { return {...v, stat:{...v.stat, view:!slctAllTxt.data}} }  )
            }
        }else{
            if (searchInput.data !== '') {
                res = searchData.map((v) => v.company_department_id === filterActive.data.id ? {...v, stat:{...v.stat, view:!slctAllTxt.data}} : {...v} )
                setSearchData(res)
            }else{
                res = userList.data.map((v) => v.company_department_id === filterActive.data.id ? {...v, stat:{...v.stat, view:!slctAllTxt.data}} : {...v} )
            }
        }
        const mergeArr = res
        
        let data = []
        if (filterActive.data.name === 'All') {
            const au = mergeArr.filter((au) => au.name !== 'Admin' )
            data = [...data, ...au]
        }else{
            const au = mergeArr.filter((au) => au.company_department_id === filterActive.data.id && au.name !== 'Admin' )
            data = [...data, ...au]
        }
        userList.set(data)
        const mergeArr2 = usrData.users.map((md) => {
            const td = data.find((td) => td.id === md.id);
            return {...md, ...td};
        });
        setUsrData({...usrData, users:mergeArr2})
    }

    const handlerShareAccess = () => {
        if (count > 0) {
            confirmShare.set({...confirmShare.data, val : true, type : 'share'})
        }
    }

    const handlerFilterDept = (e) => {
        menuDept.set(true)
    }

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (searchInput.data !== '') {
                setSearchLoader(true)
                const searchFcn = setTimeout(() => {
                    Ulist()
                }, 500)
                return () => clearTimeout(searchFcn)
            }else{
                setSearchLoader(true)
                if (usrData.users.length > 0) {
                    const mergeArr = usrData.users.map((md) => {
                        const td = userList.data.find((td) => td.id === md.id);
                        return {...md, ...td};
                    });
                    setUsrData({...usrData, users:mergeArr})
                    let au;
                    if (filterActive.data.name === 'All') {
                        au = mergeArr.filter((au) => { return au.name !== 'Admin' } )
                    }else{
                        au = mergeArr.filter((au) => au.company_department_id === filterActive.data.id && au.name !== 'Admin' )
                    }
                    slctAllTxt.set(au.filter(v => { return !v.stat.view }  ).length > 0 ? false : true)
                    userList.set(au)
                }
                setSearchLoader(false)
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [searchInput.data])

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            let res = usrData.length === 0 ? [] : usrData.users.filter(v => { return v.stat.view })
            if (res.length === 0) {
                res = userList.data.filter(v => { return v.stat.view })
            }
            setCount(res.length)
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [userList.data, usrData])
    
    return (
            <>
                <Box minHeight={44} maxHeight={44} p="12px 16px" display="flex" alignItems="center" position="relative">
                    <IconButton sx={{position: 'absolute', p: '0px'}} onClick={() => handlerBck()}>
                        <ArrowLeft stroke="#313131" width="16" height="16" />
                    </IconButton>
                    <Box width="100%" textAlign="center" fontSize={16} fontWeight={600} lineHeight="26px">Sharing Setting</Box>
                </Box>
                <Box p={2} height="100%" width="100%" className="overflowY">
                    <Box fontSize={14} fontWeight={400} lineHeight="22px">
                        {
                            show.data.data.feature_type === 1 ?
                                `${show.data.data.registration_name} (${show.data.data.registered_name})`
                            :
                                show.data.data.feature_type === 2 ?
                                    `${show.data.data.contract_name} (${show.data.data.client_name})`
                                :
                                    show.data.data.feature_type === 3 ?
                                        show.data.data.folder_name
                                    :
                                        show.data.data.file_name
                        }
                            {/* {
                            show.data.data.registration_name === undefined && show.data.data.contract_name === undefined ?
                                `${show.data.data.type_name} (${show.data.data.name})`
                            :
                                show.data.data.feature_type === 1 ?
                                    `${show.data.data.registration_name} (${show.data.data.registered_name})`
                                :
                                    `${show.data.data.contract_name} (${show.data.data.client_name})`
                        } */}
                    </Box>
                    <Box fontSize={14} fontWeight={600} lineHeight="16px" mt={1} mb="13px">
                        Share access with
                    </Box>
                    <Autocomplete multiple options={departmentList.data} getOptionLabel={(data) => data.name} disableCloseOnSelect 
                        onChange={(e, val) => handlerOnChangeDept(e, val)}
                        value={selectedDept.data}
                        renderOption={(props, options, {selected}) => (
                            <Box {...props} style={{padding: '0px !important', margin: '0px !important'}}>
                                <Box display="flex" alignItems="center" onClick={(e) => handlerSelectDept(e, options.id, {...props})}>
                                    <Checkbox checked={selected} icon={icon} checkedIcon={checkedIcon} sx={{p: '0px'}}/>
                                    <Box fontSize={14} fontWeight={400} lineHeight="24px" ml="8px">{options.name === 'ALL' ? 'Select All' : options.name}</Box>
                                </Box>
                            </Box>
                        )}
                        renderTags={(tagValue, getTagProps) => {
                            return tagValue.map((option, index) => (
                                <Chip variant="outlined" {...getTagProps({ index })} label={option.name === 'ALL' ? 'Select All' : option.name} sx={{borderRadius: '8px', display: slctAll ? option.name === 'ALL' ? '' : 'none' : '' }} />
                            ))
                        }}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="Select Department" className={dsg.mblAutoTxt} />
                        )}
                    />
                    {
                        selectedDept.data.length > 0 &&
                            <>
                                <Box my={2}>
                                    <Box fontSize={12} fontWeight={400} lineHeight="22px" mb="12px">Give access directly to specific people within your company.</Box>
                                    <TextField onInput={(e) => searchInput.set(e.target.value)} value={searchInput.data} fullWidth className={dsg.search} placeholder='Search...' InputProps={{ startAdornment: <InputAdornment position='start'> <Search />  </InputAdornment> }} />
                                    <Box display="flex" allignItem="center" gap="16px" mt={3}>
                                        <Button fullWidth sx={{fontSize: 14, fontWeight: 600, lineHeight: '24px', color: '#333333', p: '0px', justifyContent: 'flex-start', gap: '12px'}} onClick={(e) => handlerFilterDept(e)} >
                                            <KeyboardArrowDown />
                                            <Typography fontSize={14} fontWeight={600} lineHeight="24px">{filterActive.data.name}</Typography>
                                        </Button>
                                        <Box display="flex" justifyContent="space-between" width="100%">
                                            <Button sx={{fontSize: 14, fontWeight: 400, lineHeight: '24px', color: '#333333', p: '0px'}} onClick={() => handleSelectAll()}> 
                                                {slctAllTxt.data ? 'Unselect all' : 'Select all'}
                                            </Button>
                                            <Box fontSize={12} fontWeight={400} lineHeight="24px" color="#107038">{count} selected</Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {
                                    searchLoader ? 
                                        <SearchLoader shareType={shareType} />
                                    :
                                        <UserList userList={userList} searchInput={searchInput} searchData={searchData} slctAllTxt={slctAllTxt} usrData={usrData} setUsrData={setUsrData} setSearchData={setSearchData} />
                                }
                            </>
                    }
                    
                </Box>
                <Divider variant="middle" />
                <Box minHeight={64} maxHeight={64} p={2}>
                    {
                        selectedDept.data.length > 0 ?
                            <Box display="flex" justifyContent="center"> 
                                <Button variant="contained" disableElevation onClick={() => handlerShareAccess()}
                                    sx={{p: '8px 44px', height: 32, opacity: count ? 1 : 0.2,  }}
                                >
                                    Share Access
                                </Button>
                            </Box>
                        :
                            <SharedWith shareType={shareType} withAccess={withAccess} ulistAccess={ulistAccess} />
                    }
                </Box>
                {
                    menuDept.data &&
                        <FilterDept selectedDept={selectedDept} menuDept={menuDept} filterActive={filterActive} allUsrList={allUsrList} userList={userList} usrData={usrData} setUsrData={setUsrData} slctAllTxt={slctAllTxt} searchInput={searchInput}/>
                }
            </>
    )
}
