import { useState } from "react"
import { Home1, GeneralMaintenanceIcon, Renew1, MyCloud, TcIcon, ReferUserIcon, NotificationIcons, MyToolIcons, PolicyIcons } from '../global/Icons';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import CampaignIcon from '@mui/icons-material/Campaign';

export const NavigationStates = () => {
    const [tabs, setTabs] = useState({
        top: [
            // {name:'Chat', className:'', subdir:'#', ref: '__chat', not_active:<ChatIcon fill="#292D32" />, active:<ChatIcon fill="#107038" stroke="#107038"/>},
            // {name:'Chat', className:'', subdir:'#', ref: '__chat', not_active:<ChatIcons/>, active:<ChatIcons/>},
            {name:'Notifications', className:'', subdir:'/notification', ref: '/notification', not_active: <NotificationIcons fill="#333333" />, active: <NotificationIcons  fill="#107038" />},
            {name:'MyTools', className:'tenth-step', subdir:'#', ref: '__mytools', not_active:<MyToolIcons fill="#292D32" />, active:<MyToolIcons fill="#107038" stroke="#107038"/>},
        ],
        mtop: [
            // {name:'Chat', className:'', subdir:'#', ref: '__chat', not_active:<ChatIcon fill="#292D32" stroke="#ffffff" width={20} height={20} />, active:<ChatIcon fill="#107038" stroke="#107038" width={20} height={20} />},
            {name:'Notifications', className:'', subdir:'/notification', ref: '/notification', not_active: <NotificationIcons fill="#fff" />, active: <NotificationIcons fill="#fff" />},
            {name:'MyTools', className:'tenth-step', subdir:'#', ref: '__mytools', not_active:<MyToolIcons fill="#FFFFFF" stroke="#FFFFFF" />, active:<MyToolIcons fill="#107038" stroke="#107038"/>},
        ],
        left: [
            {name:'Dashboard', className:'first-step',  subdir:'/', ref: '', not_active:<Home1 stroke="#89A594" />, active:<Home1 fill="#107038" stroke="#fff"/>},
            {name:'B Cloud', className:'second-step', subdir:'/my-cloud/recent/list', ref: 'my-cloud', not_active:<MyCloud stroke="#89A594" />, active:<MyCloud fill="#11783C" stroke="#fff"/>},
            {name:'Policies', className:'second-step-first', subdir:'/policies/list', ref: 'policies', not_active:<PolicyIcons stroke="#89A594" />, active:<PolicyIcons fill="#11783C" stroke="#fff"/>},
            {name:'Speak Out', className:'second-step-second', subdir:'/speakout/concern/list', ref: 'speakout', not_active:<CampaignOutlinedIcon sx={{color: '#89A594', fontSize: 20}}/>, active:<CampaignIcon fill="#11783C" sx={{color: '#11783C', fontSize: 20}}/>},
            {name:'General Maintenance', className:'second-step-third', subdir:'/general-maintenance?feature=company&tab=company-profile', ref: 'general-maintenance', not_active:<GeneralMaintenanceIcon stroke="#89A594" />, active:<GeneralMaintenanceIcon fill="#11783C" stroke="#11783C"/>},
            {name:'Tool Categories', className:'third-step', subdir:'/tool-categories/subscribed', ref: 'tool-categories', not_active:<TcIcon stroke={'#89A594'}  fill={'#fff'} />, active:<TcIcon fill="#107038" stroke="#107038"/>},
        ],
        msetting: [
            // {name:'Tool Categories', subdir:'/', ref: '', steps: 'sixth-step', not_active:<Application2 fill="none" stroke="#333333"/>, active:<Application2 fill="#11783C" stroke="#333333"/>},
            {name:'Policies', subdir:'/policies/list', ref: 'psl', steps: 'fifth-step', not_active:<PolicyIcons stroke="#89A594" />, active:<PolicyIcons fill="#333333" stroke="#fff"/>},
            {name:'Refer a Hero Users', subdir:'/psl', ref: 'psl', steps: 'sixth-step', not_active:<ReferUserIcon fill="#fff" stroke="#A2A3A9"/>, active:<ReferUserIcon fill="#333333" stroke="#333333"/>},
            // {name:`Get App for ${view === '' ? 'Desktop' : 'Mobile'}`, subdir:'/biz', ref: 'biz', steps: 'eighth-step', not_active:<Download1 stroke="#333333"/>, active:<DownloadActive fill={"#333333"}/>},
            {name:'System Updates', subdir:'/psl', ref: 'psl', steps: 'seventh-step', not_active:<Renew1 fill="none" stroke="#333333"/>, active:<Renew1 fill="#333333" stroke="#333333"/>},
            // {name:'Support', subdir:'/support', ref: 'support', steps: 'seventh-step', not_active:<Support fill="none" stroke="#333333"/>, active:<Support fill="#333333" stroke="#333333"/>},
        ],
        mobile: [
            {name:'Dashboard', sub_name:'', subdir:'/', ref: '', steps:'first-step', not_active:<Home1 stroke="#687B75"/>, active:<Home1 fill="#11783C" stroke="#fff"/>},
            {name:'B Cloud', sub_name:'', steps:'second-step', subdir:'/my-cloud/recent/list', ref: 'my-cloud', not_active:<MyCloud/>, active:<MyCloud fill="#11783C" stroke="#ffffff" />},
            {name:'General Maintenance', sub_name:'Maintenance', steps:'third-step', subdir:'/general-maintenance?feature=company&tab=company-profile', ref: 'general-maintenance', not_active:<GeneralMaintenanceIcon fill="none" stroke="#687B75" />, active:<GeneralMaintenanceIcon fill="#11783C" stroke="#fff"/>},
            {name:'Tool Categories', sub_name:'', steps:'fourth-step', subdir:'/tool-categories/subscribed', ref: 'tool-categories', not_active:<TcIcon stroke={'#89A594'}  fill={'#fff'} />, active:<TcIcon fill="#107038" stroke="#107038"/>},
        ]
    })
    const [tc, setTc] = useState({open: true, list: false})

    // FOR REFER USER
    const [isShowRU, setIsShowRU] = useState(false);

    // MOBILE PROFILE
    const [openprfl, setOpenprfl] = useState(false)

    // SHOW MY TOOL
    const [showMT, setShowMT] = useState(false)
    const [isLoader, setIsLoader] = useState(true)
    
    //SHOW MYCLOUD
    const [openMC, setOpenMC] = useState(false)

    //FOR REFERAL
    const [anchorElRef, setAnchorElRef] = useState(null)
    const [openRef, setOpenRef] = useState(false) //FOR MOBILE
    const [countRef, setCountRef] = useState(0) //FOR MOBILE
    const [openNotifMenu,  setOpenNotifMenu] = useState(false) // FOR DESKTOP OPEN NOTIF
    const [openSideBar, setOpenSideBar] = useState(true)

    //PREVIOUS URL
    const [prevURL, setPrevURL] = useState('')

    return {
        tabs: {data: tabs, set: setTabs},
        tc: {data: tc, set: setTc},
        isShowRU: {data: isShowRU, set: setIsShowRU},
        openprfl: {data: openprfl, set: setOpenprfl},
        showMT: {data: showMT, set: setShowMT},
        isLoader: {data: isLoader, set: setIsLoader},
        openMC: {data: openMC, set: setOpenMC},
        anchorElRef: {data: anchorElRef, set: setAnchorElRef},
        openRef: {data: openRef, set: setOpenRef},
        countRef: {data: countRef, set: setCountRef},
        openNotifMenu: {data: openNotifMenu, set: setOpenNotifMenu},
        openSideBar: {data: openSideBar, set: setOpenSideBar},
        prevURL: {data: prevURL, set: setPrevURL},
    }
}