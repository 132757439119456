import { useEffect, useState } from "react"
import { Box } from "@mui/material";
import { Logo } from "../../core/global/Icons";
import { SnakeLoader } from "../../core/loader/SnakeLoader";
import { domain } from "../../core/Env";

export const Index = () => {
    const urlParams = new URLSearchParams(window.location.search);
   
    const [subscribe, setSubscribe] = useState(true)
    useEffect(() => {
        const __init = async() => {
            // VALIDATING UBR
            if (urlParams.get('filter') === 'ubr') {
                let strg =  {"expiration":"1012030","ainfo":{"aid":"YWNjb3VudF8w","username":null,"email":"unknown@com","mobile_num":null,"account_type":2,"user_type":1,"verified":1,"client_id":null}}
                localStorage.setItem('biz_session', JSON.stringify(strg))
                window.location.href = '/ubr?type=welcome'
                return
            }
            
            // VALIDATING REFERRAL CODE
            if (urlParams.get('rc') !== null) {
                if (atob(urlParams.get('rc')).includes('aid') && atob(urlParams.get('rc')).includes('tid') && atob(urlParams.get('rc')).includes('ref_from')) {
                    window.location.href = `${domain('pm').url}/biz/registration?ref_a_frnd=${urlParams.get('rc')}`
                    return
                }
            }
            window.location.href = '/'
            return
        }
        if (subscribe) {
            __init()
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [])

    return (
        <Box height="100%" width="100%" display="flex" alignItems="center" flexDirection="column" justifyContent="center" pt="20px">
            <Logo size={60}/>
            <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                <Box pt={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <SnakeLoader size="1rem" bg="#11783C" distance="0.3rem" />
                    <Box pr="4px">Validating your link</Box> 
                    {/* <Box pr="4px">Validating your referral</Box>  */}
                </Box>
            </Box>
        </Box>
    )
}
