import { useRef, useState } from "react"
import { Box, Button, Container, TextField } from "@mui/material"
import { useReactToPrint } from "react-to-print"
import { ToWords } from "to-words";
import { isNumeric } from "../../core/global/Format";
import { PrintSI } from "./PrintSI.js";
import { PrintOutlined, RestartAlt, Visibility, VisibilityOff } from "@mui/icons-material";

export const Index = () => {

    const ActionHandler = (type) => {
        if (type === 'print') {
            printHandler()
        }else{
            setForm({
                sold_to:'',
                date:'',
                tin:'',
                address_at:'',
                pesos:'',
                vatable_sales:'',
                vat:'',
                zero_rated_sales:'',
                vat_exempt_sales:'',
                amount_due:'',
                add_vat:'',
                total_amount_due:''
            })
            setItems(
                Array(11).fill({ qty: '', unit: '', nature_of_service: '', unit_cost: '', amount: '' })
            );
        }
    }

    const componentRef = useRef();
    const printHandler = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'print-so'
    })

    const toWords = new ToWords({
        localeCode: "en-PH", // Supports multiple locales
        converterOptions: {
          currency: true, // Add currency-related words if needed
          ignoreDecimal: false, // Convert the decimal part as well
          ignoreZeroCurrency: false,
        },
    });

    const [form, setForm] = useState({
        sold_to:'',
        date:'',
        tin:'',
        address_at:'',
        pesos:'',
        vatable_sales:'',
        vat:'',
        zero_rated_sales:'',
        vat_exempt_sales:'',
        amount_due:'',
        add_vat:'',
        total_amount_due:''
    })

    const HandlerChangeForm = (e) => {
        const { name, value } = e.target;
        const updatedForm = { ...form };
        if (name === 'pesos') {
            const numeric = isNumeric(value) ? 'integer' : value.includes('.') ? 'integer' : 'string';
            const regex = /^[0-9]*\.?[0-9]*$/;
            if (regex.test(value)) {
                updatedForm[name] = numeric === 'integer' ? value : '';
            }
        } else {
            updatedForm[name] = value;
        }
        setForm(updatedForm);
    };

    const [items, setItems] = useState(
        Array(11).fill({ qty: '', unit: '', nature_of_service: '', unit_cost: '', amount: '' })
    );

    const HandlerChangeItems = (e, index) => {
        const { name, value } = e.target;
        
        const updatedItems = [...items];
        let data = updatedItems.map((v, k) =>  k === index ? {...v, [name]:value} : {...v})
        setItems(data)
        updateSpacing(data)
        // setItems(
        //     updatedItems.map((v, k) =>  k === index ? {...v, [name]:value} : {...v})
        // );
    };

    const updateSpacing = (data) => {
        const updatedItems = [...data];
        const lengthThreshold = 99;
        let count = 0; // Counter for how many items exceed the length threshold
    
        for (let i = 0; i < updatedItems.length; i++) {
            const currentLength = updatedItems[i].nature_of_service.length;
    
            if (currentLength > lengthThreshold) {
                count++; // Increment the counter when the condition is met
            }
            // Update the spacing for the current item
            updatedItems[i].spacing = count;
        }
        setItems(updatedItems);
    };

    const [show, setShow] = useState(true)

    const handleShowDate = () => {
        setShow(!show)
    }

    return (
        <Container sx={{height:'100%', width:'100%'}}>
            <Box display="none">
                <PrintSI componentRef={componentRef} form={form} items={items} show={show}/>
            </Box>
            <Box width="100%" height="100%" className="overflowY" display="flex" flexDirection="column" px={2}>
                <Box width="100%" minHeight="70px" display="flex" justifyContent="center" alignItems="center" >
                </Box> 

                <Box fontSize={12} width="100%" minHeight="40px" display="flex" justifyContent="flex-end" alignItems="flex-end" gap={1}>
                    {show && (
                        <>
                            <Box fontWeight={700}>Date</Box> 
                            <TextField onChange={(e) => HandlerChangeForm(e)} name="date" value={form.date} variant="standard" size="small" fullWidth inputProps={{ style:{fontSize:14, padding:'0px'}}} sx={{width:'120px'}} />
                        </>
                    )}
                    {show?<Visibility onClick={handleShowDate} sx={{color:'primary.main', height:24, width:24}} />:<VisibilityOff onClick={handleShowDate} sx={{color:'primary.main', height:24, width:24}} />}
                </Box> 
                <Box fontSize={12} width="100%" minHeight="40px" display="flex" alignItems="flex-end" gap={1}>
                    <Box width="100%" display="flex" alignItems="flex-end" gap={1}>
                        <Box minWidth="50px" fontWeight={700}>Sold to</Box> 
                        <TextField onInput={(e) => HandlerChangeForm(e)} name="sold_to" value={form.sold_to} multiline maxRows={2} variant="standard" size="small" fullWidth margin="none" inputProps={{style:{padding:'0px', lineHeight:'15px'}}} sx={{'.MuiInputBase-input': { fontSize: '14px'}}}/>
                    </Box> 
                    <Box minWidth="168px" display="flex" alignItems="center" gap={1}>
                        <Box minWidth="50px" fontWeight={700}>with TIN</Box> 
                        <TextField onInput={(e) => HandlerChangeForm(e)} name="tin" value={form.tin} variant="standard" size="small" fullWidth sx={{width:'100%'}} inputProps={{style:{fontSize:14, padding:'0px'}}} />
                    </Box> 
                </Box> 
                <Box fontSize={12} width="100%" minHeight="40px" display="flex" alignItems="flex-end" gap={1}>
                    <Box width="100%" display="flex" alignItems="flex-end" gap={1}>
                        <Box minWidth="70px" fontWeight={700}>address at</Box> 
                        <TextField onInput={(e) => HandlerChangeForm(e)} name="address_at" value={form.address_at} multiline maxRows={2} variant="standard" size="small" fullWidth margin="none" sx={{'.MuiInputBase-input': { fontSize: '14px', padding:'0px', lineHeight:'15px' }}} />
                    </Box> 
                </Box> 
                <Box fontSize={12} width="100%" minHeight="40px" display="flex" alignItems="flex-end" gap={1}>
                    <Box width="100%" display="flex" alignItems="flex-end" gap={1}>
                        <Box minWidth="70px" fontWeight={700}>the sum of</Box> 
                        <TextField disabled multiline maxRows={2} variant="standard" size="small" value={form.pesos === '' ? '' : toWords.convert(Number(form.pesos))} fullWidth margin="none" sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000"
                                },
                                '.MuiInputBase-input': { fontSize: '14px', padding:'0px', lineHeight:'15px' }
                            }}
                        />
                    </Box> 
                    <Box minWidth="175px" display="flex" alignItems="flex-end" gap={1}>
                        <Box minWidth="60px" fontWeight={700}>pesos (P)</Box> 
                        <TextField onInput={(e) => HandlerChangeForm(e)} name="pesos" value={form.pesos} variant="standard" size="small" fullWidth sx={{width:'100%'}} inputProps={{min:0, maxLength:20, style:{fontSize:14, padding:'0px'}}} />
                    </Box> 
                </Box> 
                <Box fontSize={14} width="100%" minHeight="70px" display="flex" justifyContent="space-between" alignItems="center" gap={1.5}>
                    <Box textAlign="center" width="10px" ></Box> 
                    <Box textAlign="center" width="250px" fontWeight={700}>QUANTITY</Box> 
                    <Box textAlign="center" width="300px" fontWeight={700}>UNIT</Box> 
                    <Box textAlign="center" width="100%" fontWeight={700}>NATURE OF SERVICE</Box> 
                    <Box textAlign="center" width="250px" fontWeight={700}>UNIT COST</Box> 
                    <Box textAlign="center" width="250px" fontWeight={700}>AMOUNT</Box> 
                </Box> 
                {/* 1140px */}
                <Box position="relative" fontSize={12} width="100%" minHeight="645px" display="flex" justifyContent="space-between" alignItems="center" gap={1.5}>
                    <Box position="absolute" display="flex" flexDirection="column" height="100%" width="100%">
                        {['','','','','','','','','','','',''].map((v,k) => (
                        // {['','','','','','','','','','','','','','','','','','','','',''].map((v,k) => (
                            <Box key={k} borderTop="1px solid #000000" sx={{opacity:10}} minHeight="55px"></Box>
                        ))}
                    </Box>
                    <Box position="absolute" top={-16} display="flex" flexDirection="column" justifyContent="center" height="100%" width="100%" gap="1px">
                        {items.map((v,k) => (
                            <Box key={k} width="100%" minHeight="54px" display="flex" justifyContent="space-between" alignItems="center" gap={1.5}>
                                <Box width="10px" height="100%" fontWeight={500}>{k + 1}</Box>
                                <Box width="250px" height="100%" fontWeight={500}>
                                    <TextField onInput={(e) => HandlerChangeItems(e, k)} value={v.qty} name="qty" fullWidth sx={{width:'100%', zIndex:1}} inputProps={{min:0, maxLength:10, style:{textAlign:'center', fontSize:12, padding:'15px'}}} />
                                </Box>
                                <Box width="300px" height="100%" fontWeight={500}>
                                    <TextField onInput={(e) => HandlerChangeItems(e, k)} value={v.unit} name="unit" multiline rows={2} size="small" fullWidth margin="none" sx={{'.MuiInputBase-input': { textAlign:'center', fontSize: '12px', padding:'0px', lineHeight:'15px' }}}/>
                                </Box>
                                <Box width="100%" height="100%" fontWeight={500}>
                                    <TextField onInput={(e) => HandlerChangeItems(e, k)} value={v.nature_of_service} name="nature_of_service" multiline rows={2} size="small" fullWidth margin="none" sx={{'.MuiInputBase-input': { fontSize: '12px', padding:'0px', lineHeight:'15px' }}} />
                                </Box>
                                <Box width="250px" height="100%" fontWeight={500}>
                                    <TextField onInput={(e) => HandlerChangeItems(e, k)} value={v.unit_cost} name="unit_cost" fullWidth sx={{width:'100%', zIndex:1}} inputProps={{min:0, style:{textAlign:'center', fontSize:12, padding:'15px'}}} />
                                </Box>
                                <Box width="250px" height="100%" fontWeight={500}>
                                    <TextField onInput={(e) => HandlerChangeItems(e, k)} value={v.amount} name="amount" fullWidth sx={{width:'100%', zIndex:1}} inputProps={{min:0, style:{textAlign:'center', fontSize:12, padding:'15px'}}} />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
                
                <Box width="100%" minHeight="200px" display="flex" justifyContent="flex-end">
                    <Box fontSize={12} minWidth="60%" display="flex" gap={5}>
                        <Box width="50%" display="flex" flexDirection="column" alignItems="center" gap={1}>
                            <Box width="100%" display="flex" alignItems="center" gap={1}>
                                <Box minWidth="105px" fontWeight={500}>VATable Sales</Box> 
                                <TextField onInput={(e) => HandlerChangeForm(e)} value={form.vatable_sales} name="vatable_sales" fullWidth size="small" sx={{width:'100%', zIndex:1}} inputProps={{min:0, maxLength:10, style:{textAlign:'right', fontSize:14, padding:'7px'}}} />
                            </Box> 
                            <Box width="100%" display="flex" alignItems="center" gap={1}>
                                <Box minWidth="105px" fontWeight={500}>VAT</Box>    
                                <TextField onInput={(e) => HandlerChangeForm(e)} value={form.vat} name="vat" fullWidth size="small" sx={{width:'100%', zIndex:1}} inputProps={{min:0, maxLength:10, style:{textAlign:'right', fontSize:14, padding:'7px'}}} />
                            </Box> 
                            <Box width="100%" display="flex" alignItems="center" gap={1}>
                                <Box minWidth="105px" fontWeight={500}>Zero Rated Sales</Box> 
                                <TextField onInput={(e) => HandlerChangeForm(e)} value={form.zero_rated_sales} name="zero_rated_sales" fullWidth size="small" sx={{width:'100%', zIndex:1}} inputProps={{min:0, maxLength:10, style:{textAlign:'right', fontSize:14, padding:'7px'}}} />
                            </Box> 
                            <Box width="100%" display="flex" alignItems="center" gap={1}>
                                <Box minWidth="105px" fontWeight={500}>VAT-Exempt Sales</Box> 
                                <TextField onInput={(e) => HandlerChangeForm(e)} value={form.vat_exempt_sales} name="vat_exempt_sales" fullWidth size="small" sx={{width:'100%', zIndex:1}} inputProps={{min:0, maxLength:10, style:{textAlign:'right', fontSize:14, padding:'7px'}}} />
                            </Box> 
                        </Box> 
                        <Box width="50%" display="flex" flexDirection="column" alignItems="center" gap={1}>
                            <Box width="100%" display="flex" alignItems="center" gap={1}>
                                <Box minWidth="105px" fontWeight={500}>Amount Due</Box> 
                                <TextField onInput={(e) => HandlerChangeForm(e)} value={form.amount_due} name="amount_due" fullWidth size="small" sx={{width:'100%', zIndex:1}} inputProps={{min:0, maxLength:10, style:{textAlign:'right', fontSize:14, padding:'7px'}}} />
                            </Box> 
                            <Box width="100%" display="flex" alignItems="center" gap={1}>
                                <Box minWidth="105px" fontWeight={500}>Add: VAT</Box> 
                                <TextField onInput={(e) => HandlerChangeForm(e)} value={form.add_vat} name="add_vat" fullWidth size="small" sx={{width:'100%', zIndex:1}} inputProps={{min:0, maxLength:10, style:{textAlign:'right', fontSize:14, padding:'7px'}}} />
                            </Box> 
                            <Box width="100%" display="flex" alignItems="center" gap={1}>
                                <Box minWidth="105px" fontWeight={700}>Total Amount Due</Box> 
                                <TextField onInput={(e) => HandlerChangeForm(e)} value={form.total_amount_due} name="total_amount_due" fullWidth size="small" sx={{width:'100%', zIndex:1}} inputProps={{min:0, maxLength:30, style:{textAlign:'right', fontSize:16, fontWeight:700, padding:'15px'}}} />
                            </Box> 
                        </Box> 
                    </Box> 
                </Box>
                <Box minHeight="200px">
                    <Box display="flex" justifyContent="flex-end" width="100%" height="50px" gap={2}>
                        <Box onClick={()=>ActionHandler('reset')} component={Button} startIcon={<RestartAlt sx={{height:100, width:24, color:'#FFFFFF'}}/>} height="100%" width="200px" borderRadius={2} display="flex" alignItems="center" justifyContent="center" bgcolor={"primary.main"} color={"#fff"} sx={{border:"1px solid #EBEBEB", ':hover':{bgcolor:'primary.main', color:'#FFFFFF'}}} px={3}>
                            <Box fontSize={14} fontWeight={400} lineHeight="19px">
                                Reset
                            </Box>
                        </Box>
                        <Box onClick={()=>ActionHandler('print')} component={Button} startIcon={<PrintOutlined sx={{height:100, width:24, color:'#FFFFFF'}}/>} height="100%" width="200px" borderRadius={2} display="flex" alignItems="center" justifyContent="center" bgcolor={"primary.main"} color={"#fff"} sx={{border:"1px solid #EBEBEB", ':hover':{bgcolor:'primary.main', color:'#FFFFFF'}}} px={3}>
                            <Box fontSize={14} fontWeight={400} lineHeight="19px">
                                Print
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}