import { Box, Button, Modal } from "@mui/material"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export const SelectDept = ({open, setOpen, form, setForm, deptStaticData}) => {
    
    const nav = useHistory()

    const handleClose = (e, val) => {
        setForm({...form, departmentname: {id:val.id, name:val.name} })
        setOpen(false)   
    }

    const handleCancel = () => {
        nav.push('/policies/list')
    }

    return (
        <Modal open={open} >
            <Box height="100%" width="100%" display="flex" alignItems="Center" justifyContent="center" flexDirection="column">
                <Box bgcolor="#FFFFFF" display="flex" flexDirection="column" mb={2} p={4} height={439} width={512} borderRadius="12px" boxShadow="0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)">
                    <Box fontSize={20} fontWeight={600} mb={4} lineHeight="16px">Select Department</Box>
                    <Box height="100%" className="overflowY">
                        {deptStaticData.map((v, k) => (
                            <Box key={k} >
                                <Box height="100%" p="12px" color="#313131" fontSize={14} fontWeight={400} lineHeight="16px" class-name="c-pointer" sx={{'&:hover': { color: '#107308', bgcolor: '#f0f0f0', fontWeight: 600 }}} onClick={(e)=>handleClose(e, v)}>
                                    {v.name}
                                </Box>
                                
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Button variant="outlined" disableElevation sx={{p: '12px 16px', lineHeight: '16px', fontWeight: 600, color: '#FFFFFF', borderRadius: '8px', borderColor: '#FFFFFF', '&:hover': { backgroundColor: '#E2EDE7', color: '#107038', borderColor: '#E2EDE7'}}} onClick={handleCancel}> 
                    Cancel
                </Button>
            </Box>
        </Modal>   

    )
}