import { Box } from "@mui/material"
import { Top } from "./Top"
import { TBody } from "./TBody"
import { useContext, useState } from "react"
import { Member } from "./Member"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { ToolContext } from "../../../../../core/context/ToolContext"
import { NewSuccessAlert } from "../../../../../core/global/forms/CstmModal"
import { Loader } from "../../../../fetching/Loader"
import { NoFound } from "../../../../mycloud/global/DesktopDsg"

export const TList = ({ teamData, allUsers, teamForm, setTeamForm, isLoading, tSearch }) => {
    const { speakout_state } = useContext(ToolContext)
    const { successOpen, message } = speakout_state


    const nav = useHistory()
    const [open, setOpen] = useState(false)

    const [teamModal, setTeamModal] = useState({
        id: '',
        data: '',
        member: false
    })

    const handleOpenMember = (e, value, type) => {
        let memberMerge = {...value, member: value.member.map(vm => allUsers.filter(ud => ud.id === vm).map(dd => dd.uname)[0]).sort((a,b) => a.localeCompare(b))}
        setTeamModal(memberMerge)
        setOpen(true)
    }

    const handleUpdate = async(e, value, type) => {
        setTeamForm([{...teamForm[0], id: value.id, team_number: value.team_number, team_name: value.name, member: value.member, active: value.active}])
        nav.push('/speakout/team/update')
    }

    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column">
            <Box minHeight="140px">
                <Top teamData={teamData}/>
            </Box>
            {isLoading.data && <Loader />}
            {!isLoading.data && teamData.data.length === 0 && <NoFound  msg="No Team Found." />}
            {teamData.data.length > 0 && <TBody teamData={teamData} handleOpenMember={handleOpenMember} handleUpdate={handleUpdate}/>}
            {
                successOpen.data &&
                <NewSuccessAlert successOpen={successOpen.data} setSuccessOpen={successOpen.set} text={message.data}/>
            }
            {
                open &&
                <Member open={open} setOpen={setOpen} teamForm={teamForm} teamData={teamData} allUsers={allUsers} teamModal={teamModal} setTeamModal={setTeamModal}/>
            }
        </Box>
    )
}