import { Box, IconButton, Modal, Slide } from "@mui/material"
import { ArrowDown, ArrowLeft, ImportIcon } from "../../../../../core/global/Icons"
import { Viewer, Worker } from "@react-pdf-viewer/core"
import { FileExtension } from "../../../global/FileExtension";

export const FullView = ({show, showDl}) => {
    const handlerShowDl = (e) => {
        showDl.set(e.currentTarget)
    }
    return (
        <Modal open={show.data.fullview} className="noutlined">
            <Slide in={show.data.fullview} direction="up">
                <Box height="100%" width="100%" display="flex" flexDirection="column" className="overflowY">
                    <Box minHeight={80} maxHeight={80} display="flex" alignItems="center" justifyContent="space-between" bgcolor="#313131" px={2}>
                        <Box display="flex" alignItems="center">
                            <IconButton onClick={() => show.set({...show.data, fullview : false })}>
                                <ArrowLeft stroke="#ffffff" />
                            </IconButton>
                            <Box ml={2} color="#ffffff" fontSize={14} fontWeight={400} lineHeight="16px">{show.data.data.file_name}</Box>
                        </Box>
                        <Box display="flex" alignItems="center" p="8px 16px" gap="10px" className="c-pointer" bgcolor="#3A3A3A" borderRadius="6px"
                            onClick={(e) => handlerShowDl(e)}
                            sx={{'&:hover': { backgroundColor: '#107038' } }}
                        >
                            <ImportIcon />
                            <Box fontSize={14} fontWeight={600} lineHeight="16px" color="#ffffff">Download as</Box>
                            <ArrowDown />
                        </Box>
                    </Box>
                    <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" my={5}>
                        {/* <Box minHeight="730px" maxHeight="730px" width="100%" textAlign="center" className="overflowY">
                            
                            <Box component="img" src={show.data.data.file_link} alt={show.data.data.file_link} height="100%" width="531px" borderRadius="12px"/>
                        </Box> */}
                        <Box>

                            <Box minHeight="730px" maxHeight="730px" width="100%" className="overflowY">
                                <Box height="100%" width="100%">
                                    { 
                                        show.data.data.file_type.includes('pdf')? 
                                            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                <Box sx={{height: '730px', width:'730px', borderRadius:'12px'}}>
                                                    <Box component={Viewer} fileUrl={show.data.data.file_link} />
                                                </Box>
                                            </Worker>
                                        :
                                            show.data.data.file_type.includes('image') ? 
                                                <Box component="img" src={show.data.data.file_link} alt={show.data.data.file_link} height="100%" width="531px" borderRadius="12px" />
                                            :
                                                <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                                    <FileExtension link={show.data.data.file_link} />
                                                </Box>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}
