import { Box } from "@mui/material"
import { TList } from "./list/Index"
import { TForm } from "./form/Index"
import { useContext, useEffect, useState } from "react"
import { ToolContext } from "../../../../core/context/ToolContext"
import { rqx } from "../../../../core/request/API"
import { env } from "../../../../core/Env"

export const Team = ({ match }) => {
    const { speakout_state, tool_state } = useContext(ToolContext)
    const { teamData, isLoading } = speakout_state
    const { __SESSION } = tool_state

    let company_id = __SESSION.data.uaccess[0].company_id;

    const [teamForm, setTeamForm] = useState([{
        id: 0,
        team_number: `TEAM-000000001`,
        team_name: '',
        member: [],
        active: 1,
        error: ''
    }])

    const __init = async(val) => {
        if (val === 0) {
            isLoading.set(true)
        }

        if (val !== undefined) {
            let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/team/read`, {
                company_id: __SESSION.data.uaccess[0].company_id,
                filter: 'all'
            })
            let result = res.map(pd => ({...pd, member: JSON.parse(pd.member)}))
            teamData.set(result)
        }
        isLoading.set(false)
    }

    const [allUsers, setAllUsers] = useState([])
    const __allUsers = async() => {
        const getAllUsers = await rqx.get(`<<global>>/${env()}/tooluser/gettooluser`, {company_id: company_id !== null ? company_id : 0, tool_id: process.env.REACT_APP_TID, ref: 'active' })
        let samap = getAllUsers.superadmin?.map(v => {return {...v, uname:v.first_name+' '+v.last_name }} )
        let umap = getAllUsers.users?.map(v => {return {...v, uname:v.first_name+' '+v.last_name }} )
        setAllUsers([...samap, ...umap])
        
    }

    useEffect(() => {
        const __initialize = () => {
            if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') {
                teamData.set([]);
                __init(0, '')
                __allUsers()
            }
        }
        __initialize()
        // eslint-disable-next-line
    }, [])

    return (
        <Box height="100%" width="100%">
        {
            match.params === 'create' || match.params === 'update' ?
            <Box height="100%" width="100%" borderRadius="12px" display="flex" flexDirection="row">
                <TForm match={match} teamForm={teamForm} setTeamForm={setTeamForm} teamData={teamData} __init={__init} allUsers={allUsers}/>
            </Box>
            :
            <Box height="100%" width="100%" bgcolor="#FFFFFF" borderRadius="12px" border="1px solid #EBEBEB">
                <TList teamData={teamData} allUsers={allUsers} teamForm={teamForm} setTeamForm={setTeamForm} isLoading={isLoading}/>
            </Box>
        }
        </Box>
    )
}