import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Backdrop, Modal, Fade } from '@mui/material';
import { Buttons } from '../../core/global/forms/Button';
import Cafgu from '../../assets/images/core/cafgu.svg'

export const Welcome = ({ubrModal}) => {
    const history = useHistory();
    const [open] = useState(true);

    const handleClose = async() => {    
        ubrModal.set(true)
        history.push('/ubr?type=select');
    };
    
    return (  
        <Modal
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={open}>
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <Box position="absolute" width={470} height={600}>
                        <Box borderRadius={5} borderColor="#ffffff" p={3} bgcolor="white" className="shadow">
                            <Box display="flex" justifyContent="center">
                                <Box color="primary.main" fontSize={29} mr={1}>Welcome to </Box>
                                <Box fontSize={29}>POFSIS <Box component="strong">Business!</Box></Box>
                            </Box>
                            <Box mt="15px" fontSize={15}>
                                I am Cafgu, your business coach, I will guide you on 
                                your journey to grow your extraordinary business.
                            </Box>
                            <Box my={3} textAlign="center">
                                <img src={Cafgu} width="230px" height="222px" alt="cafgu" />
                            </Box>
                            <Box fontSize={16} textAlign="center">
                                You may upload your company documents here and share it within your company.
                            </Box>
                        </Box>  
                        <Box display="flex" justifyContent="center" mt="30px" >
                            <Buttons event={handleClose} variant="contained" borderRadius="8px" color="primary" width="auto" height="48px" fontSize="16px" text="Upload Company Documents"/>
                        </Box>  
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}