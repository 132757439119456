import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Box, IconButton, Modal, Slide } from "@mui/material";
import { ArrowLeft, GridViewIcon, ListViewIcon } from "../../../../../core/global/Icons";
import { ToolContext } from "../../../../../core/context/ToolContext";
import { rqx } from "../../../../../core/request/API";
import { env } from "../../../../../core/Env";
import { List } from './list/Index';
import { MblFetchingLoader } from "../../../../fetching/Loader";
import { MblAlert } from '../../../../../core/global/forms/CstmModal'
import { ConfirmRemove } from "../../../global/DesktopDsg";
// import { NotifCount } from "../../../../../core/request/Notif";

const uai_user = process.env.REACT_APP_UAI_REGULAR;

export const Favorites = ({match}) => {
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { listView, favList, isLoading, limit, inftScroll, lid, active, show, message, successOpen, showDl, reloadStorage } = cloud_state
    const { __SESSION } = tool_state
    const nav = useHistory()
    const [open, setOpen] = useState(true)
    const [subscribe, setSubscribe] = useState(true)

    const handlerBack = () => {
        setOpen(false)
        setTimeout(() => {
            reloadStorage.set(true)
            nav.push('/my-cloud/recent/list')
        }, 200)
    }

    const __LoadmoreData = () => {
        __InitFavList(lid.data, 'file')
    }

    const __InitFavList = async(val, type) => {
        if (val === 0) {
            isLoading.set(true)
            inftScroll.set(true)
        }

        if (val !== undefined) {
            let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/favorites/read`, {
                company_id:__SESSION.data.uaccess[0].company_id, 
                lastId:val, 
                limit:type === 'folder'? 100 : limit.data,
                ref: __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? '' : 'regular_user',
                user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                filter:type
            })
            if (res) {
                let res_fltr = res.map(v => v.access === null || v.access === undefined ? {...v} : {...v, access : JSON.parse(v.access).filter(f => {return f.user_id === parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) }) } )
                if (type === 'folder') {
                    favList.set(res_fltr);
                    return
                }

                let l_id;   
                for (let i = 0; i < res.length; i++) { l_id = res[i].updated_at}
                lid.set(l_id);
                
                if (l_id === 1 || res.length !== limit.data ) {
                    inftScroll.set(false);
                }
                favList.set((prev) => [...prev, ...res_fltr]);
                // if ( val === 0 || val === undefined || val === '') {
                //    favList.set((prev) => [...prev, ...res_fltr]);
                // }else{
                //     favList.set((prev) => [...prev, ...res_fltr]);
                // }
                isLoading.set(false);
            }
        }
    }

    const HandlerDelete = async(option) => {
        
        if (!show.data.remove.loader) {
            if (option === 'cancel') {
                show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}})
            }else{
                show.set({...show.data, remove:{...show.data.remove, loader:true}})
                let res;
                if (show.data.data.type === 1 || show.data.data.type === 2) {
                    res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/${show.data.data.type ===  1 ? 'registration' : 'contracts'}/update`, {
                        id:show.data.data.id, 
                        user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                        status:4, 
                        ref:'trash'
                    })
                }else{
                    let prms = show.data.data.type === 3 ? {folder_id:show.data.data.id}:{documents_id: show.data.data.id}
                    let body = {
                        ref:'trash',
                        filter: show.data.data.type === 3 ? 'folder' : 'file',
                        status:4, 
                        user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                        ...prms
                    }
                   
                    res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/documents/remove`, body)
                }
                if (res.message === 'success') {
                    message.set('Successfully removed')
                    successOpen.set(true)
                    let fltr = favList.data.filter(v => { return show.data.data.id !== v.id || v.type !== show.data.data.type }) 
                    favList.set(fltr)
                    // if(show.data.data.type !== 3){
                    //     await NotifCount([{file_id:show.data.data.id, file:show.data.data.type === 1 ? 'reg' : show.data.data.type === 2 ? 'con' : 'file'}], __SESSION, 'delete')
                    // }
                    show.set({...show.data, remove:{...show.data.remove, val:false, loader:false},  view : false, fullview : false})
                    active.set({...active.data, val : '', openMenu : null})
                }
            }
        }
    }
    
    useEffect(() => {
        if (subscribe) {
            listView.set(true)
            const __init = async() => {
                await __InitFavList(0, 'folder')
                await __InitFavList(0, 'file')
            }
            __init()
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Modal open={open} className="noutlined">
                <Slide in={open} direction="left" className="noutlined">
                    <Box height="100%" width="100%" bgcolor="#ffffff" display="flex" flexDirection="column">
                        <Box minHeight="44px" maxHeight="44px" display="flex" alignItems="center" justifyContent="space-between" p="12px 16px">
                            <IconButton onClick={() => handlerBack()}>
                                <ArrowLeft stroke="#313131" width="16" height="16" />
                            </IconButton>
                            <Box fontSize={16} fontWeight={600} lineHeight="26px">Favorites</Box>
                            <IconButton onClick={() => listView.set(!listView.data)}>
                                {listView.data ? <ListViewIcon /> : <GridViewIcon />}
                            </IconButton>
                        </Box>
                        <Box p={2} height="100%" width="100%" className="overflowY">
                            {
                                isLoading.data ?
                                    <MblFetchingLoader mt={0} />    
                                :
                                    <List favList={favList} isLoading={isLoading} listView={listView} active={active} show={show} __SESSION={__SESSION} message={message} successOpen={successOpen} showDl={showDl} __InitFavList={__InitFavList} __LoadmoreData={__LoadmoreData} inftScroll={inftScroll} />
                            }
                        </Box>
                    </Box>
                </Slide>
            </Modal>
            <MblAlert successOpen={successOpen.data} setSuccessOpen={successOpen.set} text={message.data} />
            <ConfirmRemove show={show} HandlerDelete={HandlerDelete}/>
        </>
    )
}
