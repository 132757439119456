import { Box, Button } from "@mui/material";
import { NoFound } from "../../../../global/DesktopDsg"
import { ConListView, RegListView } from "./List";
import { ConGridView, RegGridView } from './Grid'
import { useHistory } from "react-router-dom";
import '@react-pdf-viewer/core/lib/styles/index.css';
import { FICons } from "../fileinfo/FICons";
import { FIRegs } from "../fileinfo/FIRegs";
import { DownloadCons } from "../modal/DownloadCons";
import { DownloadRegs } from "../modal/DownloadRegs";

export const List = ({ cloud_state, listView, handlerOpenMenu}) => {

    const { show, regActive, conActive, rcntList, successOpen, csuccessOpen, message } = cloud_state

    const nav = useHistory()

    const handleRedirect = (type) => {
        if (type === 'registrations') {
            nav.push('/my-cloud/registrations/list')
        }else{
            nav.push('/my-cloud/contracts/list')
        }
    }

    const handlerView = (val) => {
        show.set({...show.data, data : val, view : true, fullview : false})
        val.feature_type === 1  ?
            regActive.set({...regActive.data, val : '',  openMenu : null })
        :
            conActive.set({...conActive.data, val : '',  openMenu : null })
    }

    const handlerOpenDL = () => {
        show.set({...show.data, download:true})
    }

    return (
        <>
            {
                (rcntList.data.reg.length === 0 ?
                    <NoFound msg="No Registration File found. Upload Now!" />
                :
                    <>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb="12px">
                            <Box fontSize={12} fontWeight={400} lineHeight="19px">Registrations</Box>
                            {
                                rcntList.data.reg.length === 4 &&
                                    <Button onClick={()=>handleRedirect('registrations')} sx={{fontSize: 12, fontWeight: 600, lineHeight: '19px', p: '0px', minWidth: '20px'}}> See all </Button>
                            }
                        </Box>
                        {
                            (listView.data ?
                                <RegListView handlerView={handlerView} data={rcntList.data.reg} handlerOpenMenu={handlerOpenMenu} />
                            :
                                <RegGridView handlerView={handlerView} data={rcntList.data.reg} handlerOpenMenu={handlerOpenMenu}/>
                            )       
                        }
                    </>
                ) 
            }
            {
                 (
                    rcntList.data.contracts.length === 0 ?
                        <NoFound msg="No Contracts File found. Upload Now!" />
                    :
                        <>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb="12px">
                                <Box fontSize={12} fontWeight={400} lineHeight="19px">Contracts</Box>
                                {
                                    rcntList.data.contracts.length === 4 &&
                                        <Button onClick={()=>handleRedirect('contracts')} sx={{fontSize: 12, fontWeight: 600, lineHeight: '19px', p: '0px', minWidth: '20px'}}> See all </Button>
                                }
                            </Box>
                            {
                                 (listView.data ?
                                    <ConListView handlerView={handlerView} data={rcntList.data.contracts} handlerOpenMenu={handlerOpenMenu} />
                                :
                                    <ConGridView handlerView={handlerView} data={rcntList.data.contracts} handlerOpenMenu={handlerOpenMenu}/>
                                )     
                            }
                        </>
                )
            }
            <Box minHeight="50px"></Box>
            {show.data.view &&
                <>
                    {show.data.data.feature_type === 1  ? 
                        <FIRegs show={show} handlerThreeDots={handlerOpenDL}/> 
                    :
                        <FICons show={show} handlerThreeDots={handlerOpenDL}/> 
                    }
                </>
            }
            {
                show.data.data.feature_type === 1 ? 
                    <DownloadRegs regActive={regActive} show={show} successOpen={successOpen} message={message} />
                :
                    <DownloadCons conActive={conActive} show={show} csuccessOpen={csuccessOpen} message={message} />
            }
        </>
    )
}
