import { Avatar, Box, Checkbox } from "@mui/material"
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { NoResult } from "../../../../empty/Index";
const icon = <CheckBoxOutlineBlank fontSize="small" sx={{color: '#C2C2C2', borderRadius: '4px'}} />;
const checkedIcon = <CheckBox fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;

export const UserList = ({ulistAccess, setSlctTxt, searchData, setSearchData, searchInput, usrData, setUsrData }) => {

    const handleSelectUser = (e, id) => {
        let data = ulistAccess.data.map((v) => v.id === id ? {...v, stat : {...v.stat, remove:e.target.checked} } : {...v})
        setSlctTxt(data.filter(v => { return !v.stat.remove }  ).length > 0 ? false : true)
        ulistAccess.set(data)
        setUsrData({...usrData, users: usrData.users.map((v) => v.id === id ? {...v, stat : {...v.stat, remove:e.target.checked} } : {...v}) })
        if (searchInput.data !== '') {
            setSearchData(searchData.map((v) => v.id === id ? {...v, stat : {...v.stat, remove:e.target.checked} } : {...v}))
        }
    }

    return (
        (searchInput.data !== '' && searchData.length === 0) || ulistAccess.data.length === 0 ?
            <NoResult/>
        :
            (searchInput.data !== '' ? searchData : ulistAccess.data).map((v,k) => (
                <Box display="flex" alignItems="center" justifyContent="space-between" p="8px 16px" key={k}>
                    <Box display="flex" alignItems="center" gap="24px">
                        <Checkbox onChange={(e)=>handleSelectUser(e, v.id)} checked={v.stat.remove} icon={icon} checkedIcon={checkedIcon} sx={{p: '0px'}} />
                        <Box display="flex" alignItems="center">
                            <Avatar src={JSON.parse(v.profile).profile} alt="profile_img" sx={{height: 32, width: 32}} />
                            <Box fontSize={14} fontWeight={400} lineHeight="24px" ml="12px">{v.first_name} {v.last_name}</Box>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" className="c-pointer" >
                        <Box fontSize={14} fontWeight={400} lineHeight="16px" >
                            {
                                v.stat.update === false && v.stat.download === false ?
                                    'View'
                                :
                                    v.stat.update && v.stat.download ?
                                        'Update & Download'
                                    :
                                        v.stat.update === false && v.stat.download ?
                                            'View & Download'
                                        :
                                            'View & Update'
                            }
                        </Box>
                    </Box>
                </Box>
            ))
    )
}
