import { useState } from "react"

export const MasterListState = () => {
    // CLIENT 
    const [clntForm, setClntForm] = useState({
        id:'', 
        user_id:0,
        series_num:'', 
        series_code:'', 
        name:'', 
        email:'',
        logo:'https://pofsis-business.s3.amazonaws.com/biz-image/mv.png', 
        contact_number:'', 
        province_id:'', 
        city_id: '',  
        barangay_id: '', 
        street_building_num:'', 
        zip_code:'', 
        department:0, 
        position:0, 
        superior:0, 
        active:'',
        assigneId:[],
    });

    const [dsblBnt, setDsblBnt] = useState(true)
    const [isShowLogo, setIsShowLogo] = useState({type:'', status:false});
    const [contctValidate, setContctValidate] = useState(false);
    const [allUsers, setAllUsers] = useState([])
    const [slctdUser, setSlctdUser] = useState([])
    const [slctAll, setSlctAll] = useState(false)

    return {
        clntForm: {data: clntForm, set: setClntForm},
        dsblBnt: {data: dsblBnt, set: setDsblBnt},
        isShowLogo: {data: isShowLogo, set: setIsShowLogo},
        contctValidate: {data: contctValidate, set: setContctValidate},
        allUsers: {data: allUsers, set: setAllUsers},
        slctdUser: {data: slctdUser, set: setSlctdUser},
        slctAll: {data: slctAll, set: setSlctAll},
    }
}