import { Box, Divider, Menu, MenuItem } from '@mui/material'
import { Download, DownloadPDF } from '../../../../../core/transmit/S3'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import imgDL from '../../../../../assets/images/icons/imgDl.png';
import pdfDL from '../../../../../assets/images/icons/pdfDl.png';

export const DownloadList = ({show, showDl, active, message, successOpen}) => {
    
    const handlerDL = async(e, type) => {
        if (type === 'pdf' && show.data.data.file_type !== 'application/pdf') {
            let res = await DownloadPDF(show.data.data.file_link, show.data.data.file_name)
            if (res === 'success') {
                showDl.set(null)
                active.set({...active.data, val : '', openMenu : null, type: ''})
                message.set('Successfully download')
                successOpen.set(true)
            }
        }else{
            let res = await Download(e, show.data.data.file_link, show.data.data.file_name)
            if (res === 'success') {
                showDl.set(null)
                active.set({...active.data, val : '', openMenu : null, type: ''})
                message.set('Successfully download')
                successOpen.set(true)
            }
        }
    }

    return (
        (show.data.data !== '' && show.data.data.file_type !== undefined) && (
            <Menu open={Boolean(showDl.data)} anchorEl={showDl.data} onClose={() => showDl.set(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <Box px={1} minWidth={240}>
                    <MenuItem disabled={active.data.type === 'file' && (show.data.data.file_type === 'application/pdf' || show.data.data.file_type.split('/')[0] === 'image' ? false : true) } sx={{minHeight: '44px !important', py: 0}} onClick={(e) => handlerDL(e, 'pdf')}>
                        <img src={pdfDL} alt={pdfDL} />
                        <Box ml="12px" fontSize={14} fontWeight={400}> PDF </Box>
                    </MenuItem>
                    <Divider sx={{my: '0px !important'}} />
                    {show.data.data.file_type !== 'application/pdf' && 
                        <MenuItem disabled={ active.data.type === 'file' && (show.data.data.file_type === 'application/pdf' || show.data.data.file_type.split('/')[0] === 'image'  ? false : true) } sx={{minHeight: '44px !important', py: 0}} onClick={(e) => handlerDL(e, 'img')}>
                            <img src={imgDL} alt={imgDL} />
                            <Box ml="12px" fontSize={14} fontWeight={400}> Image </Box>
                        </MenuItem>
                    }
                    <Divider sx={{my: '0px !important'}} />
                    {
                        active.data.type === 'file' && (
                            (show.data.data.file_type !== 'application/pdf' && show.data.data.file_type.split('/')[0] !== 'image' ) &&(
                                <MenuItem hidden={true} sx={{minHeight: '44px !important', py: 0}} onClick={(e) => handlerDL(e, 'img')}>
                                    <InsertDriveFileIcon sx={{width: 20, height: 20, fill: '#E2EDE7',}} />
                                    <Box ml="12px" fontSize={14} fontWeight={400}> Download </Box>
                                </MenuItem>
                            )
                        )
                    }
                </Box>
            </Menu>
        )
    )
}
