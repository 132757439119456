import { Box, Grid, Skeleton } from "@mui/material";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import registration from '../../../../../assets/images/icons/registrations.png';
import contracts from '../../../../../assets/images/icons/contracts.png';
import documents from '../../../../../assets/images/icons/documents.png';
import favorties from '../../../../../assets/images/icons/favorites.png';
import share from '../../../../../assets/images/icons/share.png';

export const Overview = ({storage, storageLoader, sharedCount}) => {
    const nav = useHistory()
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
    const data = [
        {name: 'Registrations', bg: '#52A35C3D', icon: registration, files:storage.data.regFile, size: storage.data.reg === 0 ? `${0} B` : parseFloat((storage.data.reg / Math.pow(1024, parseInt(Math.floor(Math.log(storage.data.reg) / Math.log(1024))))).toFixed(2)) + ' ' + sizes[parseInt(Math.floor(Math.log(storage.data.reg) / Math.log(1024)))], url: 'registrations',},
        {name: 'Contracts', bg: '#AF93F03D', icon: contracts, files:storage.data.conFile, size: storage.data.con === 0 ? `${0} B` :parseFloat((storage.data.con / Math.pow(1024, parseInt(Math.floor(Math.log(storage.data.con) / Math.log(1024))))).toFixed(2)) + ' ' + sizes[parseInt(Math.floor(Math.log(storage.data.con) / Math.log(1024)))], url: 'contracts'},
        {name: 'Documents', bg: '#F0936C3D', icon: documents, files:storage.data.docFile, size: storage.data.docs === 0 ? `${0} B` :parseFloat((storage.data.docs / Math.pow(1024, parseInt(Math.floor(Math.log(storage.data.docs) / Math.log(1024))))).toFixed(2)) + ' ' + sizes[parseInt(Math.floor(Math.log(storage.data.docs) / Math.log(1024)))], url: 'documents'},
        // {name: 'Documents', bg: '#F0936C3D', icon: documents, files:0, size: '0 B', url: 'recent'},
        {name: 'Favorites', bg: '#E2EDE7', icon: favorties, files:storage.data.favtotal, size: '', url: 'favorites'},
        {name: 'Shared', bg: '#3333331A', icon: share, files: sharedCount.data, size: '', url: 'shared-access'},
    ]

    const handlerOpenModal = (url) => {
        nav.push(`/my-cloud/${url}/list`)   
    }
    
    return (
        <Grid container spacing="12px">
            {
                data.map((v,k) => (
                    <Grid item xs={6} sm={6} key={k}>
                        <Box onClick={() => handlerOpenModal(v.url)}
                            minHeight={81} width="100%" p="12px" border="1px solid #E6E6E6" borderRadius="6px" 
                            component={motion.div} whileTap={{ backgroundColor: '#f0f0f0' }}
                            position="relative"
                        >
                            <Box bgcolor={v.bg} borderRadius="4px" display="flex" justifyContent="center" alignItems="center" height={28} width={28} mb={1}>
                                <img src={v.icon} alt="icon" />
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <Box fontSize={12} fontWeight={600} lineHeight="17px">{v.name}</Box>
                                {storageLoader.data ? 
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Skeleton variant="rectangular" width='45px' height="12px" sx={{borderRadius:'6px', mt:'4px'}}/>
                                        <Skeleton variant="rectangular" width='45px' height="12px" sx={{borderRadius:'6px', mt:'4px'}}/>
                                    </Box>
                                :
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Box fontSize={10} fontWeight={400} lineHeight="17px">{v.files} files</Box>
                                        <Box fontSize={10} fontWeight={600} lineHeight="17px">{v.size}</Box>
                                    </Box>
                                }
                            </Box>
                            {/* {(v.name === 'Documents' || v.name === 'Shared') && (
                                <Box position="absolute" top={0} left={0} height="100%" width="100%">
                                    <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%" bgcolor="#EBF2F0" sx={{opacity:0.7}}>
                                        <Box fontSize={12} color="red" textAlign="center">Currently, these features are unavailable.</Box>
                                    </Box>
                                </Box>
                            )} */}
                        </Box>
                    </Grid>
                ))
            }
        </Grid>
    )
}
