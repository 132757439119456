import { Box, Button, Grid } from "@mui/material"
import { AddRoundIcon } from "../../../../../core/global/GMicons"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export const Top = ({ ctgyData }) => {
    const nav = useHistory()
    const handleCategory = () => {
        nav.push('/speakout/category/create')
    }

    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column" p={4}>
            <Box height="100%" width="100%" display="flex" flexDirection="row" justifyContent="space-between">
                <Box></Box>
                <Button height="49px" variant="contained" sx={{borderRadius: '15px'}} onClick={handleCategory}>
                    <AddRoundIcon />
                    <Box>Category</Box>
                </Button>
            </Box>
            {
                
            }
            <Box height="100%" width="100%">
                {
                    ctgyData.data.length > 0 &&
                    <Box width="100%" my={3} px={2} fontWeight={500}>
                        <Grid container direction="row" spacing={2} pl={2}>
                            <Grid item xs={2}>
                                <Box>Category Number</Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box>Name of Category</Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box>Type of Concern</Box>
                            </Grid>
                            <Grid item xs={2} textAlign="center">
                                <Box>Status</Box>
                            </Grid>
                            <Grid item xs={2} textAlign="center">
                                <Box>Action</Box>
                            </Grid>
                        </Grid>
                    </Box>
                }
            </Box>
        </Box>
    )
}