import { Box, Backdrop, Modal, Fade, Button } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useContext } from 'react';
import { ToolContext } from '../../../core/context/ToolContext';
import { useHistory } from 'react-router-dom';
import { ErrorAlert } from '../../../core/global/forms/CstmModal';
import Add  from '../../../assets/images/icons/add.png'

export const Index = ({showDocs, setShowDocs}) => {
    const { cloud_state } = useContext(ToolContext)
    const nav = useHistory()
    const { addMenu, selectedFiles, uploading, message, errOpen } = cloud_state
    
    const handleClose = async(type) => {
        if (type === 'close') {
            setShowDocs(false)
        }
    };

    const onDrop = async(files) => {
        let totalFileSize = 0;  
        for (let i = 0; i < files.length; i++) {
            totalFileSize += parseInt(files[i].size)
        }
       
        if (totalFileSize > 25 * 1024 * 1024) {
            message.set('File uploads must not exceed 25MB.')
            errOpen.set(true)
        }else{
            if (files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    selectedFiles.set((prev) => prev.concat({progress: 0, name: files[i].name, file_name: files[i].name, file_type: files[i].type, file_size: files[i].size, link: URL.createObjectURL(files[i]), root: Array(files[i]), path: files[i].path.charAt(0) === '/' ? files[i].path.slice(1) : files[i].path }))
                }
                addMenu.set(null)
                uploading.set({stat:true, type: files[0].path.charAt(0) === '/'?'folder':'file'})
                setShowDocs(false)
                nav.push(`/my-cloud/documents/list?strg=${totalFileSize}`)
            }
        }
    }

    const {getRootProps, getInputProps} =  useDropzone({onDrop})

    return (
        <>
            {errOpen.data && (
                <ErrorAlert errOpen={errOpen.data} setErrOpen={errOpen.set} text={message.data} duration={3000}/>
            )}
            <Modal
                open={showDocs}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={showDocs}>
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Box position="absolute" width="auto" height="auto" borderRadius={5} p={3}>
                            <Box borderRadius={2} bgcolor="white" display="flex" gap={2} flexDirection="column" justifyContent="center" alignItems="center" p={2}>
                                <Box display="flex" flexDirection="column" justifyContent="center" gap={1.5} alignItems="center">
                                    <Box minWidth="320px" position="relative" {...getRootProps({className: 'dropzone'})} border="2px dashed #D9D9D9" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column" className="c-pointer" p={2}>
                                        <img src={Add} alt={Add} />
                                        <Box mt={5} fontSize={14} fontWeight={400} lineHeight="19px" color="#333333">Drag and drop or <Box component="label" color="#107038" >Browse</Box></Box>
                                        <Box mt="12px" fontSize={12} fontWeight={400} lineHeight="19px" color="#737373">The maximum file size is 25 MB.</Box>
                                        <input directory="" webkitdirectory="" multiple {...getInputProps()} />
                                    </Box>

                                    <Button onClick={()=>handleClose('close')} variant="outlined" sx={{ border:'2px solid #D3D3D3', height:'40px', width:'80px', borderRadius:'8px', bgcolor:'#D3D3D3', color:'#000000', ':hover':{bgcolor:'#D3D3D3', color:'#035827', border:'2px solid #D3D3D3'} }}>
                                        Close
                                    </Button>
                                </Box> 
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}