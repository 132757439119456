import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material';
import { FavStarMbl, VeriticalThreeDots } from '../../../../../../core/global/Icons';
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { motion } from 'framer-motion';
import moment from 'moment';
import '@react-pdf-viewer/core/lib/styles/index.css';

export const GridList = ({conList, handlerView, handlerThreeDots}) => {
    
    return (
        <Grid container spacing="11px" px={2}>
            {
                conList.data.map((v,k) => (
                    <Grid item xs={6} sm={6} key={k}>
                        <Box display="flex" flexDirection="column" minHeight={164} component={motion.div} whileTap={{ backgroundColor: '#f0f0f0' }} border="1px solid #E6E6E6" borderRadius="6px">
                            <Box minHeight="80px" onClick={() => handlerView(v)}>
                                {
                                    JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('image')? 
                                        <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                            <Avatar src={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} variant="rounded" sx={{height:80, width: 'auto', borderRadius: '6px 6px 0px 0px'}} />
                                        </Box>
                                    :
                                        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                            <Box sx={{maxHeight:'80px', minHeight: '80px', height: '80px', width:'100%', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                                <Box component={Viewer} fileUrl={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} 
                                                />
                                            </Box>
                                        </Worker>
                                }
                            </Box>
                            <Box py="12px" px="8px" display="flex" flexDirection="column" height="100%">
                                <Box height="100%" onClick={() => handlerView(v)}>
                                    <Typography fontSize={12} fontWeight={600} lineHeight="19px" noWrap >{v.contract_name} ({v.client_name})</Typography>
                                    {/* <Typography fontSize={12} fontWeight={600} lineHeight="19px" sx={{wordBreak: 'break-word'}} className="nowrapY" >{v.contract_name} ({v.client_name})</Typography> */}
                                </Box>
                                <Box display="flex" alignItems="center" justifyContent="space-between" height={40} width="100%">
                                    <Box display="flex" alignItems="center" onClick={() => handlerView(v)}>
                                        {
                                            v.user_favorite_status === 2  &&
                                                <FavStarMbl fill={v.user_favorite_status === 2 ? '#11783C' : 'none'} />
                                        }
                                        <Box ml={v.user_favorite_status === 2 && 1} fontSize={10} fontWeight={400} lineHeight="20px"> 
                                            Expiring • ED: {moment(v.end_date).format('MM/DD/YY')}
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center" minHeight={20} minWidth={20}>
                                        <IconButton sx={{p: '0px'}} onClick={() => handlerThreeDots(v)}>
                                            <VeriticalThreeDots fill={"#737373"} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                ))
            }
        </Grid>
    )
}