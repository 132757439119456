import { Box, Grid } from "@mui/material"
import { EditProfile } from "../../../../../core/global/GMicons"

export const TBody = ({ ctgyData, handleUpdate}) => {

    return (
        <Box height="100%" width="100%" gap="12px" className="overflowY" display="flex" flexDirection="column" p="0px 32px 32px 32px">
            {
                ctgyData.data?.map((v,k) => (
                    <Box key={k} width="100%" fontWeight={400} display="flex" alignItems="center" p={2} border="1px solid #EBEBEB" borderRadius="10px" bgcolor="#FFFFFF">
                        <Grid container direction="row" spacing={2} pl={2} display="flex" alignItems="center">
                            <Grid item xs={2} className="classTextEllipsis">
                                <Box>{v.category_number}</Box>
                            </Grid>
                            <Grid item xs={4} className="classTextEllipsis">
                                <Box>{v.name}</Box>
                            </Grid>
                            <Grid item xs={2} className="classTextEllipsis">
                                <Box>{v.type === 1 ? 'Suggestion/Recommendation' : 'Concern'}</Box>
                            </Grid>
                            <Grid item xs={2} display="flex" justifyContent="center">
                                <Box display="flex" alignItems="center" textAlign="center" justifyContent="center" bgcolor={v.active === 1 ? '#00be811f' : '#E4E5E6'} color={v.active === 1 ? '#00be81' : '#7B7F82'} borderRadius="16px" width="100px">
                                    <Box>{v.active === 1 ? 'Active' : 'Inactive'}</Box>
                                </Box>
                            </Grid>
                            <Grid item xs={2} textAlign="center" display="flex" alignItems="center" justifyContent="center">
                                <Box display="flex" flexDirection="row" alignContent="center" gap={1}>
                                    <Box className="c-pointer" onClick={(e) => handleUpdate(e, v)}><EditProfile stroke='#292d32' width="24" height="24"/></Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                ))
            }
        </Box>
    )
}