import { Avatar, Box, Button, IconButton, Modal } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { CstmTextField } from "../../../../../core/global/forms/CstmTextField";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { rqx } from "../../../../../core/request/API";
import { env } from "../../../../../core/Env";
import { ToolContext } from "../../../../../core/context/ToolContext";

export const View = ({ __SESSION, uai_user, cModal, setCModal }) => {
    const { speakout_state, } = useContext(ToolContext)
    const { message, successOpen } = speakout_state

    const handleCloseView = async() => {
        await setCModal({...cModal, view: false, data: [], feedback: []})
    }

    const concernInfo = [
        {name: 'Category: ', val: cModal.data[0].speakout_category},
        {name: 'Receiver: ', val: cModal.data[0].speakout_team},
        {name: 'Type: ', val: cModal.data[0].type === 1 ? 'Suggestion/Recommendation' : 'Concern'},
        {name: 'Status: ', val: cModal.data[0].status === 1 ? 'Received' : ''},
        {name: 'Date: ', val: moment(cModal.data[0].created_at).format("MMMM DD, YYYY h:mm A")},
    ]

    const [concernFeedback, setConcernFeedback] = useState({
        company_speakout_id: '',
        feedback: '',
    })

    const handleInputFeedback = (e, val) => {  
        const { name } = e.target
        const value = val && val.id ? val.id : val || ''
        setConcernFeedback((prev) => ({
            ...prev, [name]: value
        }))
    }

    const handleSaveFeedback = async() => {
        let body = {...concernFeedback, company_speakout_id: cModal.data[0].id , user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1]}
        let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/concern/feedback`, body)
        if (res.message === 'success') {
            let fdata = [{feedback: body.feedback, date: new Date()}]
            successOpen.set(true)
            message.set('Feedback successfully saved.')
            setConcernFeedback({
                feedback: '',
            })
            setCModal((prev) => ({...prev, feedback: fdata}))
        }
    }

    const __init = async() => {
        let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/concern/readfeedback`, {
            company_speakout_id: cModal.data[0].id
        })
        let fdata = res
        setCModal((prev) => ({...prev, feedback: fdata}))
    }

    useEffect(() => {
        __init()
        //eslint-disable-next-line
    }, [])

    return (
        <Modal open={cModal.view}>
            <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" m={2}> 
                <Box minHeight="100px" width="100%"></Box>
                {
                    cModal.data.map((v,k) => (
                    <Box key={k} height="100%" width="700px" className="overflowY" bgcolor="#FFFFFF" display="flex" flexDirection="column"borderRadius="12px" boxShadow="0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08">
                        <Box minHeight="60px" width="100%" display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" p="20px 24px 16px 24px" borderBottom="1px solid #0000001f">
                            <Box minHeight="20px" width="100%" display="flex" justifyContent="flex-end" >
                                <IconButton sx={{p: '0px !important'}} onClick={() => handleCloseView()}>
                                    <CloseIcon sx={{color: '#313131'}} className="c-pointer"/>
                                </IconButton>
                            </Box>
                        </Box>
                        <Box height="100%" width="100%" p="20px 24px" display="flex" flexDirection="column" className="overflowY">
                            {
                                concernInfo.map((v,k) => (
                                <Box key={k} display="flex" flexDirection="row">
                                    <Box fontSize="14px" fontWeight={500} mb={2}>{v.name}</Box> 
                                    <Box fontSize="14px" fontWeight={400} ml={1} mb={2}>{v.val}</Box> 
                                </Box>
                                ))
                            }
                            <Box fontSize="14px" fontWeight={500} mb={2}>Details:</Box> 
                            <Box fontSize="14px" fontWeight={400} mb={3}>{v.details}</Box>
                            <Box height="100%" width="100%" borderTop="1px solid #0000001f">
                                <Box fontSize="14px" fontWeight={500} my={3}>Feedback:</Box>
                                {
                                    __SESSION.data.uaccess[0].role_id === parseInt(uai_user) && cModal.feedback.length === 0 &&
                                    <Box fontSize="14px" fontWeight={400} my={3} display="flex" justifyContent="center">No Feedback yet.</Box>
                                }
                                {
                                    __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) && cModal.feedback.length === 0 &&
                                    <Box>
                                        <CstmTextField multiline rows={3} name="feedback" placeholder="Add a Feedback" onChange={(e)=>handleInputFeedback(e, e.target.value)}></CstmTextField>
                                        {
                                            concernFeedback.feedback &&
                                            <Button variant="contained" sx={{mt: '16px'}} onClick={() => handleSaveFeedback (v.id)}>Save</Button>
                                        }
                                    </Box>
                                }

                                {
                                    cModal.feedback.length !== 0  &&
                                    <Box display="flex" flexDirection="column" mt={2}>
                                        <Box display="flex" flexDirection="row" alignItems="center" fontSize="14px" fontWeight={500}>
                                            <Avatar alt="profile" sx={{width: 32, height: 32}} />
                                            <Box ml={2}>Admin</Box>
                                            <Box width="5px" height="5px" mx={1} borderRadius="50px" bgcolor="#EBEBEB"></Box>
                                            <Box>{moment(cModal.feedback[0].date).format("MMMM DD, YYYY h:mm A")}</Box>
                                        </Box>
                                        <Box fontSize="14px" fontWeight={400} ml={6}>{cModal.feedback[0].feedback}</Box>
                                    </Box>
                                }
                            </Box>
                        </Box>
                        <Box minHeight="30px" width="100%"></Box>
                    </Box>
                    ))
                }
                <Box minHeight="100px" width="100%"></Box>
            </Box>
        </Modal>
    )
}