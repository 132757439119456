import { Box } from '@mui/material';
import { NoFound } from '../../../../global/DesktopDsg';
import { List } from './List';
import { GridList } from './GridList';
import { ThreeDots } from '../modal/ThreeDots';
import { FileInfo } from '../FileInfo';
import { DownloadModal } from '../modal/Download';
import { SnakeLoader } from '../../../../../../core/loader/SnakeLoader';
import { Waypoint } from 'react-waypoint';
import '@react-pdf-viewer/core/lib/styles/index.css';

export const Index = ({conList, listView, conActive, show, __SESSION, csuccessOpen, message, inftScroll, __LoadmoreData, openShare, shareType}) => {

    const handlerThreeDots = (val) => {
        conActive.set({...conActive.data, openMenu : true})
        show.set({...show.data, data : val})
    }

    const handlerView = (val) => {
        show.set({...show.data, data : val, view : true, fullview : false})
        conActive.set({...conActive.data, val : '',  openMenu : null })
    }
    
    return (
        <>
            {
                (conList.data.length === 0 ?
                    <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
                        <NoFound msg="No File found. Upload Now!" />
                    </Box>
                :
                    <>
                        {
                            listView.data ?
                                <List conList={conList} handlerView={handlerView} handlerThreeDots={handlerThreeDots} />
                            :
                                <GridList conList={conList} handlerView={handlerView} handlerThreeDots={handlerThreeDots} />
                        }
                        {
                            inftScroll.data && (
                                <Box display="flex" justifyContent="center">
                                    <Waypoint onEnter={__LoadmoreData} >
                                        <Box component="strong" mt={5} display="flex" justifyContent="center">
                                            <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                                        </Box>
                                    </Waypoint>
                                </Box>
                            )
                        }
                    </>
                        
                )
            }
            {conActive.data.openMenu && 
                <ThreeDots conActive={conActive} show={show} __SESSION={__SESSION} conList={conList} csuccessOpen={csuccessOpen} message={message} openShare={openShare} shareType={shareType} />  // ThreeDotsModal
            }
            {show.data.view &&
                <FileInfo show={show} handlerThreeDots={handlerThreeDots} />  // Viewing file
            }
            {
                show.data.download &&
                    <DownloadModal conActive={conActive} show={show} csuccessOpen={csuccessOpen} message={message} />
            }
        </>
    )
}