import { Avatar, Box, IconButton, LinearProgress, Modal, Slide, Typography } from '@mui/material';
import { ValidateFileSz } from '../../../../../core/validator/Validator'
import CloseIcon from '../../../../../assets/images/ruLogo/closeIcon.png';
// import { rqx } from '../../../../../core/request/API';
// import { env } from '../../../../../core/Env';

export const Uploading = ({uploading, selectedFiles, user_id, show}) => {
    const handlerClose = () => {
        uploading.set({stat:false, type:''})
        selectedFiles.set([])
    }
    const handlerRemoveFile = async(val, index) => {
        const newFiles = [...selectedFiles.data]
        newFiles.splice(index, 1)
        selectedFiles.set(newFiles)
        // let body = {
        //     documents_id: show.data.data.id,
        //     ref: 'remove',
        //     user_id: user_id,
        //     filter: 'file',
        // }
        // await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/documents/remove`, body)
    }
    // console.log(selectedFiles.data)
    return (
        <Modal open={uploading.data.stat} BackdropProps={{sx: {backgroundColor: 'transparent'} }} className="noutlined">
            <Slide in={uploading.data.stat} direction='left'>
                <Box height="100%" width="100%" display="flex" justifyContent="flex-end" pr="27px" >
                    <Box height="100%" display="flex" flexDirection="column"  >
                        <Box minHeight={200} />
                        <Box width="337px" height="400px" display="flex" flexDirection="column" bgcolor="#ffffff" borderRadius="12px" p="24px" boxShadow="0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)">
                            <Box display="flex" alignItems="center" justifyContent="space-between" minHeight={20} maxHeight={20} mb="12px">
                                <Box fontSize={14} fontWeight={600} lineHeight="19px">Uploading {selectedFiles.data.length} Items</Box>
                                <IconButton onClick={() => handlerClose()}>
                                    <img src={CloseIcon} alt="close-img" />
                                </IconButton>
                            </Box>
                            <Box height="100%" width="100%" className="overflowY">
                                {
                                    selectedFiles.data.map((v,k) => (
                                        <Box key={k}>
                                            <Box width="100%" textAlign="right" color="#DA4B1B" fontSize={9}>{v.error !== undefined && v.error ? 'Already Exist!' : ''}</Box>
                                            <Box display="flex" alignItems="center"  borderRadius="6px" border={`1px solid ${v.error !== undefined && v.error ? '#DA4B1B' : '#E6E6E6'}`} height="60px" p="14px 12px" mb="12px" gap="12px" >
                                                <Box minWidth={32} >
                                                    <Avatar src={v.link} alt="file-img" variant='rounded' sx={{width: 32, height: 32}} />
                                                </Box>
                                                <Box display="flex" flexDirection="column" width="50%" >
                                                    <Typography fontSize={12} fontWeight={600} lineHeight="16px" noWrap>{v.file_name}</Typography>
                                                    <Box fontSize={10} fontWeight={400} lineHeight="16px">{v.file_size === 0 ? '0 bytes' :ValidateFileSz(v.file_size)}</Box>
                                                    <LinearProgress value={v.progress} variant='determinate' /> 
                                                </Box>
                                                <Box minWidth={32} display="flex" alignItems="center" gap="12px">
                                                    <Box fontSize={12} fontWeight={400} lineHeight="16px">
                                                        {v.progress}%
                                                    </Box>
                                                    {
                                                        v.progress !== 100 &&
                                                            <IconButton sx={{p: '0px'}} onClick={() => handlerRemoveFile(v, k)}>
                                                                <img src={CloseIcon} alt="close-img" />
                                                            </IconButton>
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Box>
                        <Box minHeight={400} />
                    </Box>
                </Box>
            </Slide>
        </Modal>
        
    )
}
