import { Box} from "@mui/material"
import { ConfirmationTrash } from "../../../global/DesktopDsg";
import { ThreeDots } from "../menu/ThreeDots"
import { FileInfo } from "../FileInfo";
import { FileList } from './File'
import { FolderList } from './Folder'
import { ErrorAlert } from "../../../../../core/global/forms/CstmModal";

export const TrashList = ({__SESSION, show, active, modalType, selectedFiles, hanlderModalBtn, __InitTrashFolderList, __LoadmoreFolderData, trashFolder, __InitTrashFilesList, __LoadmoreFilesData, trashFiles, errOpen, message, limitSelect}) => {
    const handlerViewFile = (val) => {
        if (val.type === 3) {
            console.log(val)
        }else{
            show.set({...show.data, data : val, view : true, fullview : false})
            active.set({...active.data, val : '', openMenu : null})
        }
    }

    const handlerThreeDots = (e, k, v, type) => {
        if (type === 'open') {
            active.set({...active.data, val : k, openMenu : e.currentTarget})
            show.set({...show.data, data : v, view : false, fullview : false})
        }else{
            active.set({...active.data, val : '', openMenu : null})
        }
    }

    const showThreeDots = (index, type) => {
        if (type === 'enter') {
            active.set({...active.data, val :  index })
        }else{
            if (active.data.openMenu === null) {
                active.set({...active.data, val : '' })
            }
        }
    }

    const handlerSelect = (e, id, v) => {
        e.stopPropagation() 

        // ALL COMMENTS FOR LIMIT SELECT FILES AND FOLDER
        let type = v.type 
        let listData = type === 3 ? trashFolder : trashFiles
        listData.set(listData.data.map((d) => d.id === id && d.type === type ? {...d, checked : e.target.checked} : {...d}))
        if (v.checked) {
            selectedFiles.set(
                selectedFiles.data.filter(d => { return d.id !== v.id || d.type !== v.type })
            )
        }else{
            selectedFiles.set((prev) => [...prev, v])
        }
    }

    const handlerMenuBtn = (type) => {
        modalType.set(type)
        show.set({...show.data, remove:{...show.data.remove, val:true}})
        active.set({...active.data, val : '', openMenu : null})
    }
    
    return (
        <Box height="100%" width="100%" mb="40px" display="flex" flexDirection="column" className="overflowY">
            <Box p="40px" height="40%" className="overflowY">
                <FolderList __LoadmoreFolderData={__LoadmoreFolderData} __InitTrashFolderList={__InitTrashFolderList} handlerThreeDots={handlerThreeDots} showThreeDots={showThreeDots} handlerSelect={handlerSelect} />
            </Box>
            <Box p="40px" height="60%" className="overflowY">
                <FileList __LoadmoreFilesData={__LoadmoreFilesData} __InitTrashFilesList={__InitTrashFilesList} handlerThreeDots={handlerThreeDots} showThreeDots={showThreeDots} handlerSelect={handlerSelect} />
            </Box>
            {
                show.data.view &&
                    <FileInfo show={show} __InitTrashFilesList={__InitTrashFilesList} active={active} />
            }
            {
                active.data.openMenu !== null &&
                    <ThreeDots __SESSION={__SESSION} show={show} active={active} handlerThreeDots={handlerThreeDots} 
                        restore={() => handlerMenuBtn('restore')} 
                        perma={() => handlerMenuBtn('perma')} 
                        viewInfo={() => handlerViewFile(show.data.data)}
                    />
            }
            {
                show.data.remove.val &&
                    <ConfirmationTrash show={show} hanlderModalBtn={hanlderModalBtn} modalType={modalType.data} trashList={selectedFiles} />
                }
                <ErrorAlert errOpen={errOpen.data} setErrOpen={errOpen.set} text={message.data} /> 
        </Box>
    )
}