import { useContext, useEffect } from 'react';
import { ToolContext } from '../../core/context/ToolContext';
import { VM } from '../../core/VM';
import { Welcome } from './Welcome';
import { Select } from './Select';
import { Index as Dashboard } from '../home/desktop/Index'
import Home from '../home/devices/mobile/Index';

const view = VM()

export const Index = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const { ubr_state } = useContext(ToolContext)
    const { ubrModal, welcomeModal } = ubr_state

    useEffect(() => {
        const __init = () => {
            if (!welcomeModal.data) {
                ubrModal.set(true)
            }
        }
        __init()
        // eslint-disable-next-line
    }, [])
 
    return (
        <>
            { view === '' ? 
                // DESKTOP
                <Dashboard linkacct={[]}/>
            :  
                // MOBILE
                <Home linkacct={[]}/>
            }
            {!welcomeModal.data && (
                (urlParams.get('type') === 'select' ? 
                    <Select txt="Select the file type you want to upload?"/>
                :
                    <Welcome ubrModal={ubrModal}/>
                )
            )}
        </>
    )
}