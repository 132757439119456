import { useState } from "react"

export const SubdivisionState = () => {
    const [province, setProvince] = useState([])
    const [city, setCity] = useState([])
    const [barangay, setBarangay] = useState([])
    
    return {
        province: {data: province, set: setProvince},
        city: {data: city, set: setCity},
        barangay: {data: barangay, set: setBarangay},
    }
}