import { useEffect, useContext } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../db/db";
import { Select } from "../Select";
import { UbrPersonalization } from "../personalization/Index";
import { ToolContext } from "../../../core/context/ToolContext";

const useCombinedData = () => {
    return useLiveQuery(async () => {
        const [docs, reglist, conlist, pol] = await Promise.all([
            db.docs.toArray(),
            db.reglist.toArray(),
            db.conlist.toArray(),
            db.pol.toArray(),
        ]);
        return { docs, reglist, conlist, pol };
    });
};

export const Index = ({match}) => {
    const ubrdata = useCombinedData()

    const { cloud_state, tool_state, ubr_state, policy_state} = useContext(ToolContext)
    const { __SESSION } = tool_state
    const { regList, conList, docuFile, docuFolder } = cloud_state
    const { plcyData} = policy_state
    const { ubrModal } = ubr_state

    const __init = async() => {
        if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0' && ubrdata !== undefined && (ubrdata.reglist.length > 0 || ubrdata.conlist.length > 0  || ubrdata.docs.length > 0 || ubrdata.pol.length > 0 )) {
            if (match.url.includes('policies')) {
                plcyData.set(ubrdata.pol)
                policy_state.isLoading.set(false)
            }else{
                regList.set(ubrdata.reglist)
                conList.set(ubrdata.conlist)
                if (ubrdata.docs.length > 0) {
                    if (ubrdata.docs[0].type === 'create_file') {
                        docuFile.set(ubrdata.docs)
                    }else{
                        docuFolder.set(ubrdata.docs)
                    }
                }
                cloud_state.isLoading.set(false)
            }
            setTimeout(() => {
                ubrModal.set(true)                
            }, 200);
        }
    }

    useEffect(() => {
        __init()
        // eslint-disable-next-line
    }, [ubrdata])

    return (
        atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0' && (
            <>
                {( (!match.url.includes('registrations') && !match.url.includes('contracts') && !match.url.includes('policies') ) || match.url.includes('list')  ) && (
                    (ubrdata !== undefined && (ubrdata.reglist.length > 0 || ubrdata.conlist.length > 0  || ubrdata.docs.length > 0 || ubrdata.pol.length > 0)) && (
                        <Select data={[{reg:ubrdata.reglist, con:ubrdata.conlist, doc:ubrdata.docs, pol:ubrdata.pol }]} txt="What would you like to do next?"/>
                    )
                )}
                {( (match.url.includes('registrations') || match.url.includes('contracts') || match.url.includes('policies')) && match.url.includes('create') ) && (
                    <UbrPersonalization match={match.url.includes('policies') ? match : match.params}/>
                )}
            </>
            // (( (!match.url.includes('registrations') && !match.url.includes('contracts') && !match.url.includes('policies')) || match.url.includes('list')  ) ? 
            //     (ubrdata !== undefined && (ubrdata.reglist.length > 0 || ubrdata.conlist.length > 0  || ubrdata.docs.length > 0 || ubrdata.pol.length > 0)) && (
            //         <Select data={[{reg:ubrdata.reglist, con:ubrdata.conlist, doc:ubrdata.docs, pol:ubrdata.pol }]} txt="What would you like to do next?"/>
            //     )
            // :
            //     <UbrPersonalization match={match.url.includes('policies') ? match : match.params}/>
            // )
        )
    )
}

// {( (!match.url.includes('registrations') && !match.url.includes('contracts') && !match.url.includes('policies') ) || match.url.includes('list')  ) && (
//     (ubrdata !== undefined && (ubrdata.reglist.length > 0 || ubrdata.conlist.length > 0  || ubrdata.docs.length > 0 || ubrdata.pol.length > 0)) && (
//         <Select data={[{reg:ubrdata.reglist, con:ubrdata.conlist, doc:ubrdata.docs, pol:ubrdata.pol }]} txt="What would you like to do next?"/>
//     )
// )}
// {(match.url.includes('registrations') || match.url.includes('contracts') || match.url.includes('policies') ) && (
//     <UbrPersonalization match={match.url.includes('policies') ? match : match.params}/>
// )}