import { useState } from "react"

export const PoliciesState = () => {
    const [successOpen, setSuccessOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [actType, setActType] = useState('create')
    const [saveLoader, setSaveLoader] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)
    const [pName, setPName] = useState(false)
    const [plcyData, setPlcyData] = useState([])
    const [openView, setOpenView] = useState(false)
    const [isLoading, SetIsLoading] = useState(true)
    const [plcysn, setPlcysn] = useState('')
    const [polForm, setPolForm] = useState({
        departmentname: {id:0, name:''},
        file_name: '',
        file_size: '',
        file_type: '',
        file_link: '',
        policyname: '',
        description: '',
        dateupload: new Date(),
        dateissued: '',
        preparedby: '',
        approvedby: '',
        file: '',
        create: false
    })

    return {
        successOpen: {data: successOpen, set: setSuccessOpen},
        message: {data: message, set: setMessage},
        actType: {data: actType, set: setActType},
        saveLoader: {data: saveLoader, set: setSaveLoader},
        errorOpen: {data: errorOpen, set: setErrorOpen},
        pName: {data: pName, set: setPName},
        plcyData: {data: plcyData, set: setPlcyData},
        openView: {data: openView, set: setOpenView},
        isLoading: {data: isLoading, set: SetIsLoading},
        plcysn: {data: plcysn, set: setPlcysn},
        polForm: {data: polForm, set: setPolForm}
    }
}