import { Avatar, Box, Button, Divider } from "@mui/material"
import { SnakeLoader } from "../../../core/loader/SnakeLoader"
import { NotifStyles } from "../../../core/styling/Notification"
import { DateTime } from "../../../core/validator/Validator"
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { rqx } from "../../../core/request/API";
import { env } from "../../../core/Env";
import { useHistory } from "react-router-dom";
import moment from "moment"

export const List = ({loader, notifData, __SESSION}) => {
    const dsg = NotifStyles()
    const nav = useHistory()

    const handleUpdate = async(type, v) => {
        await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/notification/update`, {
            user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1], 
            id:v.id, 
            ref:''
        })

        let ids
        if (type === 'contracts') {
            ids = v.con_id
        }else{
            ids = v.reg_id
        }
        nav.push(`/my-cloud/${type}/${window.btoa(ids)}`)
    }

    return (
        <Box mb={3} pt="12px" bgcolor="#FFFFFF" height="100%" width="100%" className="overflowY" sx={{borderRadius: "0px 0px 8px 8px"}} >
            {
                (loader.data ?
                    (notifData.data.length === 0 ?
                        <Box textAlign="center" py="20px">No notification</Box>
                    :
                            notifData.data.map((v,k) => (
                                <Box key={k} bgcolor={v.status === 0 ? '' : '#1294480F'}>
                                    {v.reg_id === null ? 
                                        <Con v={v} dsg={dsg} handleUpdate={handleUpdate}/>
                                    : 
                                        <Reg v={v} dsg={dsg} handleUpdate={handleUpdate}/>
                                    }
                                    <Divider sx={{mx: 2}} />
                                </Box>
                            ))
                    )
                :
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" py="20px">
                        <SnakeLoader bg="#11783C" size= "10px" distance= "5px" />
                    </Box>)    
            }
        </Box>
    )
}

const Con = ({v, dsg, handleUpdate}) => {
    const str = v.con_link
    const checkext = str === null ? false : str.includes("pdf") || str.includes("PDF")
  
    return (
        <Box p={2} display="flex" alignItems="center" width="100%" gap="12px">
            <Box minWidth="44px">
                {checkext ? 
                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                        <Box sx={{maxHeight:'44px', minHeight: '44px', height: '44px', width:'44px', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                            <Box component={Viewer} fileUrl={v.con_link} 
                            />
                        </Box>
                    </Worker>
                :
                    <Avatar src={v.con_link === null ? 'https://pofsis-business.s3.amazonaws.com/biz-image/1641878116475.png' : v.con_link} variant="rounded" sx={{height: 44, width: 44}} />
                }
            </Box>
            <Box width="100%" display="flex" flexDirection="column">
                <Box fontSize={12}>{v.con_type} {`( ${v.con_name} )`} {v.notif_remarks === 'expired' ? 'has expired.' : `will expire on ${moment(v.con_edate).format('MMMM DD, YYYY')}.`}</Box>
                <Box fontSize={10} display="flex" alignItems="center" mb="12px">
                    <Box>Contracts</Box>
                    <Box height="2px" width="2px" bgcolor="#707070"  mx={1} />
                    <Box><DateTime date_time={v.notif_date} moment={moment} /></Box>
                </Box>
                <Button className={v.status === 1 ? dsg.btnGreen : dsg.btnPink} sx={{width:'150px !important'}} onClick={() => handleUpdate('contracts', v)}>
                    Update Contracts
                </Button>
            </Box>
        </Box>
    )
}

const Reg = ({v, dsg, handleUpdate}) => {
    const str = v.reg_link
    const checkext = str === null ? false : str.includes("pdf") || str.includes("PDF")
    
    return (
        <Box p={2} display="flex" alignItems="center" width="100%" gap="12px"> 
            <Box minWidth="44px">
                {checkext ? 
                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                        <Box sx={{maxHeight:'44px', minHeight: '44px', height: '44px', width:'44px', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                            <Box component={Viewer} fileUrl={v.reg_link} 
                            />
                        </Box>
                    </Worker>
                :
                    <Avatar src={v.reg_link === null ? 'https://pofsis-business.s3.amazonaws.com/biz-image/1641878116475.png' : v.reg_link} variant="rounded" sx={{height: 44, width: 44}} />
                }
            </Box>
            <Box width="100%" display="flex" flexDirection="column">
                <Box fontSize={12}>{v.reg_type} {`( ${v.reg_name} )`} {v.notif_remarks === 'expired' ? 'has expired.' : `will expire on ${moment(v.reg_edate).format('MMMM DD, YYYY')}.`}</Box>
                <Box fontSize={10} display="flex" alignItems="center" mb="12px">
                    <Box>Registration</Box>
                    <Box height="2px" width="2px" bgcolor="#707070"  mx={1} />
                    <Box><DateTime date_time={v.notif_date} moment={moment} /></Box>
                </Box>
                <Button className={v.status === 1 ? dsg.btnGreen : dsg.btnPink} sx={{width:'150px !important'}} onClick={() => handleUpdate('registrations', v)}>
                    Update Registration
                </Button>
            </Box>
        </Box>
    )
}