import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material"
import { Top } from "../../global/DesktopDsg"
import { List } from "./List"
import { ToolContext } from "../../../../core/context/ToolContext";
import { __InitRegList, __RegSeriesNum } from "../../global/Function";
import { NewSuccessAlert } from "../../../../core/global/forms/CstmModal";
import { ShareAccess } from "../share/Index";
import { FormReg } from "./setup/Index";

export const Registrations = ({match}) => {
    const { cloud_state, tool_state, notif_state } = useContext(ToolContext)
    const { regList, isLoading, filter, regActive, starActive, show, listView, inftScroll, limit, lid, regsn, actType, successOpen, message, showDl, openShare, sufficientStorage, insuffShow, withAccess, shareType } = cloud_state
    const { __SESSION } = tool_state 
    const nav = useHistory()

    const HandlerAdd = async() => {
        if (sufficientStorage.data) {
            insuffShow.set(true)
        }else{
            await __RegSeriesNum(__SESSION, regsn)  
            nav.push(`/my-cloud/registrations/create`)
            actType.set('create')
        }   
    }
    const __init = async() => {
        listView.set(false)
        await __InitRegList(0, filter.data, isLoading, inftScroll, __SESSION, limit, lid, regList)
    }
    
    useEffect(() => {
        __init()
        // eslint-disable-next-line
    }, [])

    return (
        match.params === 'list' ? 
            <Box display="flex" flexDirection="column" height="100%">
                <Top title="Registrations" btnName="Add Registration" changeView={() => listView.set(!listView.data)} listView={listView} add={() => HandlerAdd()} />
                <List regList={regList} isLoading={isLoading} regActive={regActive} starActive={starActive} show={show} listView={listView} filter={filter} __InitRegList={__InitRegList} cloud_state={cloud_state} __SESSION={__SESSION} inftScroll={inftScroll} limit={limit} lid={lid} match={match.feature} successOpen={successOpen} message={message} nav={nav} showDl={showDl} openShare={openShare} withAccess={withAccess} shareType={shareType} notif_state={notif_state} />
                {
                    openShare.data &&
                        <ShareAccess match={match.feature} />
                }
                <NewSuccessAlert successOpen={successOpen.data} setSuccessOpen={successOpen.set} text={message.data} /> 
            </Box>
        :
            <FormReg match={match.params}/>
    )
}