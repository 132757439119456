import { useContext, useEffect, useState } from "react";
import { Box, Container, Modal, useMediaQuery } from "@mui/material"
import { ToolContext } from "../../../../../core/context/ToolContext";
import { SelectCon } from './SelectCon'
import { Left } from "./Left";
import { Right } from "./Right";
import { NoAccessModal, UseStyles } from "../../../global/DesktopDsg";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router";
import { rqx } from "../../../../../core/request/API";
import { env } from "../../../../../core/Env";
import { __InitConList } from "../../../global/Function";
import { ErrorAlert, NewSuccessAlert } from "../../../../../core/global/forms/CstmModal";
import { SnakeLoader } from "../../../../../core/loader/SnakeLoader";
import { FailedOcrAlrt } from "../../../../../core/global/forms/CstmModal";

export const FormCon = ({ match }) => {
    const xl = useMediaQuery('(min-width:1921px)');
    const dsg = UseStyles()
    const nav = useHistory()
    const [mLoader, setMLoader] = useState(false)
    const [fileError, setFileError] = useState(false)
    const { cloud_state, tool_state, notif_state } = useContext(ToolContext)
    const { cform, conList, listConName, consn, openConList, isLoading, inftScroll, limit, lid, cactType, cOthers, saveLoader, csuccessOpen, modal, reloadStorage, sufficientStorage, insuffShow, storage, errOpen, message } = cloud_state
    const { __SESSION } = tool_state 

    const [dfltForm] = useState({
        date_from:{val:'', stat:false, msg:''},
        date_to:{val:'', stat:false, msg:''},
        conName:{val:'', stat:false, msg:''},
        conFile:{
            photo:{val:'', stat:false, msg:''}, 
            file:{val:'', stat:false, msg:''}
        },
        conFile2:{
            photo:{val:'', stat:false, msg:''}, 
            file:{val:'', stat:false, msg:''}
        },
        name:{val:'', stat:false, msg:''},
        number:{val:'', stat:false, msg:''},
        conOther:{val:'', stat:false, msg:''},
    })
    
    const onDrop = async(files) => {
        if (files.length === 0) {
            message.set('Uploading is restricted to image and PDF file formats exclusively.')
            errOpen.set(true)
            return
        }
        
        let sizelmt = 4194304
        if (files[0].size > sizelmt) {
            message.set('File uploads must not exceed 3MB.')
            errOpen.set(true)
            return
        }

        let _file = files[0];
        if (_file !== undefined) {
            if (cform.data.conFile.file.val === '') {
                let crntsize = (paramsids !== 'create' && paramsids !== 'review') ? parseInt(cform.data.conFile.current_size) : 0 
                let totalsize = _file.size - crntsize
                // let vldtStrg = (storage.data.used + totalsize) > storage.data.used
                let vldtStrg = (storage.data.used + totalsize) > storage.data.total

                if (vldtStrg && atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') {
                    sufficientStorage.set(vldtStrg)
                    insuffShow.set(true)
                    return
                }else{
                    sufficientStorage.set(vldtStrg)
                    insuffShow.set(false)
                }

                cform.set({...cform.data, 
                    conFile:{...cform.data.conFile,
                        storage:{type:_file.size > crntsize ? 'add' : 'sub', val:totalsize},
                        photo:{...cform.data.conFile.photo, val:URL.createObjectURL(_file)}, 
                        file:{...cform.data.conFile.file, val:files}, 

                    }
                })
            }else if(cform.data.conFile2.file.val === '') {
                let crntsize = (paramsids !== 'create' && paramsids !== 'review') ? parseInt(cform.data.conFile2.current_size) : 0 
                let totalsize = _file.size - crntsize
                // let vldtStrg = (storage.data.used + totalsize) > storage.data.used
                let vldtStrg = (storage.data.used + totalsize) > storage.data.total

                if (vldtStrg && atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') {
                    sufficientStorage.set(vldtStrg)
                    insuffShow.set(true)
                    return
                }else{
                    sufficientStorage.set(vldtStrg)
                    insuffShow.set(false)
                }
                
                cform.set({...cform.data, 
                    conFile2:{...cform.data.conFile2,
                        storage:{type:_file.size > crntsize ? 'add' : 'sub', val:totalsize},
                        photo:{...cform.data.conFile2.photo, val:URL.createObjectURL(_file)}, 
                        file:{...cform.data.conFile2.file, val:files}, 
                    }
                })
            }
            setFileError(false)
        }else{
            setFileError(true)
        }
    }

    const {getRootProps, getInputProps} =  useDropzone({onDrop, accept: { 'image/png': ['.png'], 'image/jpeg' : ['.jpeg'], 'application/pdf' : ['.pdf'] } })

    const __seriesNum = async() => {
        let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/contracts/series_num`, {
            company_id:__SESSION.data.uaccess[0].company_id, 
        })
        consn.set(res)
    }
    
    const __ListConName = async() => {
        let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/contracts/default`, {
            company_id:__SESSION.data.uaccess[0].company_id, 
        })
        let f  = res.filter(v => { return v.id === 0})
        if (f.length === 0) {
            let others = [{id:0, name:'Others'}]
            listConName.set([...res, ...others])
        }else{
            listConName.set(res)
        }
    }

    const __initConList = async() => {
        await __InitConList(0, isLoading, inftScroll, __SESSION, limit, lid, conList)
    }

    const validateBase64String = () =>{
        try {
            window.atob(paramsids);
            return true;
        } catch (e) {
            return false;
        }
    }

    const paramsids = match;
    const isValid = validateBase64String(paramsids);

    const __initGETIDS = async() => {
        if (isNaN(parseInt(window.atob(match)))) {
            await nav.push('/my-cloud/contracts/list')
            return
        }
        let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/contracts/view`, {
            id:parseInt(window.atob(match)), 
        })

        cform.set({...cform.data, 
            id:parseInt(window.atob(match)),
            date_from:{...cform.data.date_from, val:res[0].start_date},
            date_to:{...cform.data.date_from, val:res[0].end_date},
            name:{...cform.data.name, val:res[0].client_name},
            conName:{...cform.data.name, val:res[0].contracts_id},
            conFile:{
                storage:{type:'add', val:0},
                current_size:JSON.parse(res[0].file)[0].file_size,
                at_id:JSON.parse(res[0].file)[0].attachment_id,
                photo:{val:JSON.parse(res[0].file)[0].file_link, stat:false, msg:''}, 
                file:{val:[{
                    name:JSON.parse(res[0].file)[0].file_name,
                    type:JSON.parse(res[0].file)[0].file_type,
                    size:JSON.parse(res[0].file)[0].file_size,
                }], stat:false, msg:''}
            },
            conFile2:{
                storage:{type:'add', val:0},
                current_size:JSON.parse(res[0].file).length > 1 ? JSON.parse(res[0].file)[1].file_size : 0 ,
                at_id:JSON.parse(res[0].file).length > 1 ? JSON.parse(res[0].file)[1].attachment_id : undefined,
                photo:{val:JSON.parse(res[0].file).length > 1  ? JSON.parse(res[0].file)[1].file_link : '', stat:false, msg:''}, 
                file:{stat:false, msg:'', val:JSON.parse(res[0].file).length > 1 
                    ?
                        [{
                            name:JSON.parse(res[0].file)[1].file_name,
                            type:JSON.parse(res[0].file)[1].file_type,
                            size:JSON.parse(res[0].file)[1].file_size,
                        }]
                    :
                        ''
                }
            },
        })
    }

    const handleCloseAccess = () => {
        notif_state.rtAccess.set(false)
        notif_state.notifPerUser.set(0)
        nav.push('/my-cloud/contracts/list')
    }

    const __init = async() =>{
        setMLoader(true)
        await __seriesNum()
        await __ListConName()
        if (isValid && (paramsids !== 'create' && paramsids !== 'review')) {
            cactType.set('update')
            await __initGETIDS()
        }else{
            if (paramsids !== 'create') {
                await nav.push('/my-cloud/contracts/list')
                return
            }
        }
        setMLoader(false)
    }

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            cform.set(dfltForm)
            openConList.set(true)
            __init()
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (notif_state.notifPerUser.data !== 0) {
            if (JSON.parse(notif_state.notifPerUser.data).company_id === __SESSION.data.uaccess[0].company_id) {
                let d = JSON.parse(notif_state.notifPerUser.data).access.filter(v => { return v.user_id === parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) } )
                if (d.length > 0) {
                    if ((!d[0].update || d[0].remove) && __SESSION.data.uaccess[0].role_id !== 0) {
                        if (d[0].file === 'con') {
                            if (cform.data.id === d[0].file_id) {
                                notif_state.rtAccess.set(true)
                            }
                        }
                    }else{
                        notif_state.rtAccess.set(false)
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [notif_state.notifPerUser.data])

    return (
        <>
            <Modal open={true}>
                <Box height="100%" width="100%" bgcolor="#FFF">
                    {
                        mLoader ? 
                            <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                                <SnakeLoader size="1rem" bg="#11783C" distance="0.3rem" />
                            </Box>
                        :
                            <Box className="overflowY" height="100%" width="100%" display="flex"  component={xl ? Container : ''} position="relative">
                                <Left saveLoader={saveLoader} openConList={openConList} cform={cform} listConName={listConName} consn={consn} dsg={dsg} getRootProps={getRootProps} getInputProps={getInputProps} nav={nav} cOthers={cOthers} dfltForm={dfltForm} paramsids={paramsids} cactType={cactType} modal={modal} __SESSION={__SESSION}/>
                                <Right csuccessOpen={csuccessOpen} saveLoader={saveLoader} cform={cform} dsg={dsg} __SESSION={__SESSION} listConName={listConName} cactType={cactType} cOthers={cOthers} __initConList={__initConList} __seriesNum={__seriesNum} __ListConName={__ListConName} nav={nav} paramsids={paramsids} reloadStorage={reloadStorage} conList={conList} storage={storage}
                                />
                                {saveLoader.data && (
                                    <Box height="100%" width="100%" position="absolute" bgcolor="rgba(0, 0, 0, 0.12)" zIndex={2000}/>
                                )}
                            </Box>
                    }
                </Box>
            </Modal>
            {(match === 'create') && (
                <SelectCon openConList={openConList} cform={cform} listConName={listConName} cOthers={cOthers} sufficientStorage={sufficientStorage} insuffShow={insuffShow} __SESSION={__SESSION} />
            )}
            <NewSuccessAlert successOpen={csuccessOpen.data} setSuccessOpen={csuccessOpen.set} text={`Successfully ${cactType.data === 'update' ? 'Updated' : 'Saved'}`} /> 
            <FailedOcrAlrt open={fileError} close={() => setFileError(false)} type="contracts" />
            {notif_state.rtAccess.data && (
                <NoAccessModal open={notif_state.rtAccess.data} handleCloseAccess={handleCloseAccess}/>
            )}
            {errOpen.data && (
                <ErrorAlert errOpen={errOpen.data} setErrOpen={errOpen.set} text={message.data} duration={3000}/>
            )}
        </>
    )
}