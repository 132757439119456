import { useHistory } from 'react-router-dom';
import { Box, Backdrop, Modal, Fade, Skeleton, Slide, useMediaQuery, Button } from '@mui/material';
import { Buttons } from '../../core/global/forms/Button';
import { useContext, useEffect, useState } from 'react';
import { ToolContext } from '../../core/context/ToolContext';

import Cafgu2 from '../../assets/images/core/cafgu2.svg'
import { domain } from '../../core/Env';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../db/db';
import { Index as DocModal } from './documents/Index';
import { PlcyIcons } from '../../core/global/Icons';
import { VM } from '../../core/VM';

const useCombinedData = () => {
    return useLiveQuery(async () => {
        const [reglist, conlist, docs, pol] = await Promise.all([
            db.reglist.toArray(),
            db.conlist.toArray(),
            db.docs.toArray(),
            db.pol.toArray()
        ]);
        return { reglist, conlist, docs, pol };
    });
};

let view = VM()

export const Select = ({data=[], txt}) => {
    const ubrdata = useCombinedData()
    const sm = useMediaQuery('(max-width:500px)')
    const { navigation_state, ubr_state, tool_state } = useContext(ToolContext)
    const { prevURL } = navigation_state
    const { ubrModal, welcomeModal } = ubr_state
    const { __SESSION } = tool_state
    const [txtMsg, setTxtMsg] = useState(txt)
    const [isShow, setIsShow] = useState(true)
    const [showDocs, setShowDocs] = useState(false)

    const history = useHistory();   

    const handleClose = async(type) => {
        if (type === 'register') {
            window.location.href = `${domain('pm').url}/biz/registration`
            return
        }
        if (type === 'reg') {
            history.push('/my-cloud/registrations/create?filter=ubr');
        }else if(type === 'con'){
            history.push('/my-cloud/contracts/create?filter=ubr');
        }else if(type === 'doc'){
            setShowDocs(true)
        }else if(type === 'pol'){
            history.push('/policies/create?filter=ubr');
        }
        welcomeModal.set(false)
        prevURL.set('/ubr?type=select')
        return
    };

    const[loader, setLoader] = useState({reg:false, con:false, doc:false, pol:false})
    useEffect(() => {
        const __init = () => {
            if (data.length > 0) {
                setLoader({
                    reg:data[0].reg !== undefined && data[0].reg.length > 0, 
                    con:data[0].con !== undefined && data[0].con.length > 0, 
                    doc:data[0].doc !== undefined && data[0].doc.length > 0, 
                    pol:data[0].pol !== undefined && data[0].pol.length > 0
                })
                setIsShow(false)
            }
        }
        __init()
        // eslint-disable-next-line
    }, [data])

    const __init = async() => {
        if (data.length === 0) {
            if (ubrdata !== undefined) {
                if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0' && (ubrdata.reglist.length > 0 || ubrdata.conlist.length > 0 || ubrdata.docs.length > 0 || ubrdata.pol.length > 0  )) {
                    setTxtMsg("What would you like to do next?")
                    setLoader({
                        reg:ubrdata.reglist !== undefined && ubrdata.reglist.length > 0, 
                        con:ubrdata.conlist !== undefined && ubrdata.conlist.length > 0, 
                        doc:ubrdata.docs !== undefined && ubrdata.docs.length > 0, 
                        pol:ubrdata.pol !== undefined && ubrdata.pol.length > 0, 
                    })
                    setIsShow(false)
                }else{
                    setIsShow(false)    
                }
            }
        }
    }

    useEffect(() => {
        __init()
        // eslint-disable-next-line
    }, [ubrdata])

    // const urlParams = new URLSearchParams(window.location.search);
    // console.log(urlParams.get('filter'))
   
    return (
        showDocs ? 
            <DocModal showDocs={showDocs} setShowDocs={setShowDocs} />
        :
            (loader.reg && loader.con && loader.doc && loader.pol ? 
                <Modal open={ubrModal.data} className="noutlined">
                    <Slide in={ubrModal.data} direction='up' className="noutlined">
                        <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" px={sm && 2}>
                            <Box width={sm ? '100%' : '456px'} bgcolor="#FFFFFF" px="24px" pb="24px" borderRadius="6px">
                                <Box minHeight="52px"></Box>
                                <Box display="flex" flexDirection="column" gap="20px">
                                    <PlcyIcons/>
                                    <Box color="#292C2F" fontSize={16} fontWeight={600} lineHeight="20px" >All Tasks Complete!</Box>
                                    <Box color="#5B6167" fontSize={14} fontWeight={400} lineHeight="21px" >
                                        To explore more features in POFSIS Business and enjoy unlimited access, just sign up. This is a limited edition offer, so don’t miss your chance.
                                    </Box>
                                    <Box width="flex" textAlign="right">
                                        <Button variant="contained" disableElevation onClick={()=>handleClose('register')}
                                                sx={{ width:'171px', minHeight:'48px', borderRadius:'6px', bgcolor: '#003022', color: '#FFFFFF', '&:hover': { bgcolor: '#292C2F' } }} 
                                            >
                                            Get it now!
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Slide>
                </Modal>
            :
                <Modal
                    open={ubrModal.data}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500 }}
                >
                    <Fade in={ubrModal.data}>
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <Box position="absolute" width={470} height="auto" borderRadius={5} p={3}>
                                <Box display="flex" flexDirection={view===''?'row':'column'} alignItems={view===''?'flex-start':'center'} height="100%" width="100%" gap="16px">
                                    <Box height="100%" pt={view===''?5:0}>
                                        <img src={Cafgu2} width="100px" height="100%" alt="cafgu" />
                                    </Box>
                                    {isShow ? 
                                        <Box borderRadius={2} bgcolor="white" display="flex" gap={2} flexDirection="column" justifyContent="center" alignItems="center" p={2}>
                                            <Box fontSize={16} fontWeight={600} textAlign="center">
                                                <Skeleton variant="text" width="250px" height="30px" />
                                            </Box>
                                            <Box display="flex" gap={1.5} flexDirection="column" alignItems="center">
                                                {['',''].map((v,k) => (
                                                    <Box key={k} display="flex" gap={1.5}>
                                                        <Skeleton variant="rectangular" width="150px" height="40px" sx={{borderRadius:'8px'}}/>
                                                        <Skeleton variant="rectangular" width="150px" height="40px" sx={{borderRadius:'8px'}}/>
                                                    </Box>
                                                ))}
                                            </Box> 
                                        </Box>
                                    :
                                        <Box borderRadius={2} bgcolor="white" display="flex" gap={2} flexDirection="column" justifyContent="center" alignItems="center" p={2}>
                                            <Box fontSize={16} fontWeight={600} textAlign="center">
                                                {txtMsg}
                                            </Box>
                                            <Box display="flex" gap={1.5} flexDirection="column" alignItems="center">
                                                <Box display="flex" gap={1.5}>
                                                    <Buttons disabled={loader.reg} event={()=>handleClose('reg')} variant="contained" borderRadius="8px" color={"#035827"} width="150px" height="40px" fontSize="13px" text="Registration" />
                                                    <Buttons disabled={loader.con} event={()=>handleClose('con')} variant="contained" borderRadius="8px" color="#035827" width="150px" height="40px" fontSize="13px" text="Contracts"/>
                                                </Box>
                                                <Box display="flex" gap={1.5}>
                                                    <Buttons disabled={loader.doc || history.location.pathname === '/my-cloud/documents/list'} event={()=>handleClose('doc')} variant="contained" borderRadius="8px" color="#035827" width="150px" height="40px" fontSize="13px" text="Documents"/>
                                                    <Buttons disabled={loader.pol} event={()=>handleClose('pol')} variant="contained" borderRadius="8px" color="#035827" width="150px" height="40px" fontSize="13px" text="Policy"/>
                                                </Box>
                                                {(data.length > 0 || loader.reg || loader.con || loader.doc || loader.pol ) && (
                                                    <Box color="#000000" fontWeight={500} fontSize={14} py={1}>
                                                        Get free, unlimited access? 
                                                        <Box component="span"> <Box component="u" className='c-pointer' color="#3D77E9" onClick={()=>handleClose('register')}>Get it now! </Box> </Box> 
                                                    </Box>
                                                )}
                                            </Box> 
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            )
    );
}