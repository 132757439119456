import { Avatar, Box, IconButton, Modal } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import GroupsIcon from '@mui/icons-material/Groups';

export const Member = ({ open, setOpen, teamModal }) => {

    const handleCloseMember = () => {
        setOpen(false)
    }

    return (
        <Modal open={open}>
            <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Box bgcolor="#FFFFFF" height={600} width={500} borderRadius="12px" boxShadow="0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)" display="flex" flexDirection="column">
                    <Box minHeight="60px" width="100%" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" p="20px 24px 16px 24px" borderBottom="1px solid #0000001f">
                        <Box mr={2}><GroupsIcon sx={{ fontSize: '40px' }}/></Box>
                        <Box width="100%" fontSize="16px" fontWeight={700}>{teamModal.name}</Box>
                        <Box minHeight="20px" width="100%" display="flex" justifyContent="flex-end" >
                            <IconButton sx={{p: '0px !important'}} onClick={() => handleCloseMember()}>
                                <CloseIcon sx={{color: '#313131'}} className="c-pointer"/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box minHeight="20px" fontSize="16px" fontWeight={700} className="overflowY" p="20px 24px" display="flex">Members:</Box>
                    <Box height="100%" p="20px 24px" display="flex" flexDirection="column" gap={2} className="overflowY">
                        {
                            teamModal.member.map((v,k) => (
                            <Box key={k} display="flex" alignItems="center" flexDirection="row" ml={2}>
                                <Avatar  alt="profile" sx={{width: 32, height: 32}}/>
                                <Box fontSize="14px" fontWeight={400} lineHeight="24px" ml={2}>{v}</Box>
                            </Box>
                            ))
                        }
                    </Box>
                    <Box minHeight="30px"></Box>
                </Box>
            </Box>
        </Modal>
    )
}