import { Box, Skeleton } from '@mui/material'

export const Loader = () => {
    return (
        <Box width="100%" display="flex" flexWrap="wrap" gap="20px">
            {
                ['','','',''].map((v, k) => (
                    <Box minWidth='273px' maxWidth='273px' key={k}>
                        <Box display="flex" flexDirection="column" p="12px" borderRadius="6px" mb={2} className="c-pointer" 
                            sx={{bgcolor: '#f0f0f0' }}
                        >   
                            <Box p="16px 16px 0px 16px" bgcolor="#F8F9FA" borderRadius="8px" height="125px" width='100%'>
                                <Skeleton variant="rectangular" width='100%' height="100%" sx={{borderRadius:'6px'}}/>
                            </Box>
                            <Skeleton variant="rectangular" width='150px' height="16px" sx={{borderRadius:'6px', mt:'16px'}}/>
                            <Skeleton variant="rectangular" width='120px' height="16px" sx={{borderRadius:'6px', mt:'16px'}}/>
                        </Box>
                    </Box>
                ))
            }
        </Box>
    )
}

export const MblFetchingLoader = ({mt=2}) => {
    return (
        <Box  mt={mt} height="100%" width="100%" display="flex" flexDirection="column" gap="12px">
            {['','','',''].map((v,k) => (
                <Box key={k} p={1} display="flex" alignItems="center" flexDirection="row" width="100%" border="1px solid #E6E6E6" borderRadius="6px">
                    <Box display="flex" alignItems="center" gap="12px" width="92%">
                        <Box display="flex" alignItems="center" minHeight={40} minWidth={40}>
                            <Skeleton variant="rectangular" width='40px' height="40px" sx={{borderRadius:'6px'}}/>
                        </Box>
                        <Box width="80%" display="flex" flexDirection="column" gap="6px">
                            <Skeleton variant="rectangular" width='150px' height="10px" sx={{borderRadius:'2px'}}/>
                            <Skeleton variant="rectangular" width='100px' height="10px" sx={{borderRadius:'2px'}}/>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" minHeight={20} minWidth={20}> 
                        <Skeleton variant="rectangular" width='20px' height="30px" sx={{borderRadius:'6px'}}/>
                    </Box>
                </Box>
            ))}
        </Box>
    )
}