import { Box, IconButton, Modal, Slide } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

export const ValidateMaxFile = ({openMaxFile}) => {
    return (
        <Modal open={openMaxFile.data}>
            <Slide in={openMaxFile.data} direction="up">
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <Box bgcolor="#ffffff" display="flex" flexDirection="column" height={200} width={300} p="16px" borderRadius="8px">
                        <Box minHeight={10} display="flex" justifyContent="flex-end">
                            <IconButton sx={{p: '0px'}} onClick={() => openMaxFile.set(false)}>
                                <CloseIcon sx={{fontSize: 20, color: '#333333'}} />
                            </IconButton>
                        </Box>
                       <Box height="100%" display="flex" alignItems="center" justifyContent="center" textAlign="center" fontWeight={600}>
                            File upload exceeds the maximum of 25MB only.
                       </Box>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}
