// Libraries
import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import { ToolProvider } from './core/context/ToolContext';

// Assets
import './assets/css/global.css';
import './assets/css/scrollbar.css';

// Layouts
import { Core as AuthCore } from './Core';
import { SSORqx } from './SSORqx';
import { Index as VR } from './components/vr/Index'
import { domain } from './core/Env';
import { VM } from './core/VM';

let view = VM()

const pageTheme = createTheme({
    palette: {
        background: {
            default: '#F0F5F9'
        },
		primary: {
			dark:'#035827',
			light:'#F2F8F6',
			main: '#11783C',
			lighter: '#f8f9fa',
		},
		secondary: {
			light: '#ffffff',
			main: '#165320',
			dark: '#f2f5fa',
		},
		error: {
			main: '#DA4B1B',
		},
		warning: {
			main: '#DA4B1B',
		},
		info: {
			light: '#0a2d33',
			main: '#15616D',
		},
		success: {
			main: '#4caf50',
		},
		btnYellow:{
			light: '#ffcd87',
			main: '#ffb347',
			color: '#f2f5fa'
		},
    },
    typography: {
		button: {
			textTransform: 'none'
		},
		fontFamily: [
			'Inter',
		].join(','),
	},
});

const __SESSION = JSON.parse(localStorage.getItem('biz_session'))
const loc = window.location.pathname
const urlParams = new URLSearchParams(window.location.search);

let Core
if ((loc === '/vr' && urlParams.get('rc') !== null) || (loc === '/dmVyaWZ5aW5nX2xpbms=' && urlParams.get('filter') === 'ubr')) {
	Core = VR
}else if (loc === '/sso/account/auth') {
	Core = SSORqx
} else if (__SESSION !== null) {
	Core = AuthCore
} else {
	if (view === '') {
		Core = SSORqx
	}else{
		window.location.href = `${domain('pm').url}/biz/login`
	}
}

ReactDOM.render(<ThemeProvider theme= { pageTheme }><CssBaseline /><ToolProvider><Core /></ToolProvider></ThemeProvider>, document.getElementById('root'));