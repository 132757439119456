
import { withStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";
import { VM } from "../VM";

let view = VM()

export const SwicthToolTip = withStyles({
    tooltip: {
        marginTop:'8px !important',
        color: "#FFFFFF",
        backgroundColor: "#1E92FD",
        padding:'16px !important',
        boxShadow:'0 1rem 3rem rgba(0, 0, 0, .175) !important',
        borderRadius: '8px',
        minHeight:'139px',
        maxWidth:view === '' ? '100%' : '343px',
        // minWidth:view === '' ? '100%' : '343px',
    },
    arrow: {
        color: "#ffffff",
        boxShadow:'0 1rem 3rem rgba(0, 0, 0, .175) !important',
    },
})(Tooltip);  

export const ToolTip = withStyles({
    tooltip: {
        color: "#000",
        backgroundColor: "#ffffff",
        padding:'5px 10px 5px 10px !important',
        boxShadow:'0 1rem 3rem rgba(0, 0, 0, .175) !important',
        borderRadius: '10px',
    },
    arrow: {
        color: "#ffffff",
        boxShadow:'0 1rem 3rem rgba(0, 0, 0, .175) !important',
    },
})(Tooltip);    

export const PrimaryToolTip = withStyles({
    tooltip: {
        color: "#fff",
        backgroundColor: "#11783C",
        padding:'8px 10px 8px 10px !important',
        boxShadow:'0 1rem 3rem rgba(0, 0, 0, .175) !important',
        borderRadius:'10px'
    },
    arrow: {
        color: "#11783C",
        boxShadow:'0 1rem 3rem rgba(0, 0, 0, .175) !important',
    },
})(Tooltip);    

export const MblToolTip = withStyles({
    tooltip: {
        color: "#000",
        backgroundColor: "#ffffff",
        padding:'5px 10px 5px 10px !important',
        boxShadow:'0 1rem 3rem rgba(0, 0, 0, .175) !important',
        borderRadius: '12px',
    },
    arrow: {
        color: "#ffffff",
        boxShadow:'0 1rem 3rem rgba(0, 0, 0, .175) !important',
    },
    popper: {
        zIndex: 2 + '!important',
    }
})(Tooltip);  

export const RefToolTip = withStyles({
    tooltip: {
        color: "#FFFFF",
        backgroundColor: "#11783C",
        padding:'0px !important',
        boxShadow:'0 1rem 3rem rgba(0, 0, 0, .175) !important',
        borderRadius:'8px'
    },
    arrow: {
        color: '#11783C',
        boxShadow:'0 1rem 3rem rgba(0, 0, 0, .175) !important',
    },
    popper: {
        zIndex:1001
    }
})(Tooltip); 

export const CloudToolTip = withStyles({
    tooltip: {
        color: "#FFFFF",
        backgroundColor: "#FFFFF",
        padding:'0px !important',
        boxShadow:'0 1rem 3rem rgba(0, 0, 0, .175) !important',
        borderRadius:'8px'
    },
    arrow: {
        "&:before": {
            border: "1px solid #FFFFFF"
        },
        color: '#FFFFFF'
    },
    popper: {
        zIndex:1200
    }
})(Tooltip); 

export const CopyToolTip = withStyles({
    tooltip: {
        color: "#F7F7F7",
        backgroundColor: "#373D3F",
        borderRadius:'8px',
        width: view === '' ? '73px' : '57px',
        height:'28px',
        padding:'4px 8px 4px 8px !important',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        boxShadow:'0.5px solid #F7F7F7 !important',
        fontSize:'12px',
    },
    arrow: {
        color: "#373D3F",
    },
})(Tooltip); 

export const FavToolTip = withStyles({
    tooltip: {
        backgroundColor: "#333333",
        borderRadius: "4px",
        padding: "12px 8px",
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.12)"
    },
    arrow: {
        color: "#333333",
    },
})(Tooltip);
