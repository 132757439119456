import { Avatar, Box, IconButton, Modal, Slide } from '@mui/material'
import { ArrowLeft, VeriticalThreeDots } from '../../../../../../core/global/Icons'
import { FormatFilesize } from '../../../../global/DesktopDsg'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { Preview } from './Preview'
import { FileExtension } from '../../../../global/FileExtension';

export const FileInfo = ({show, openShare, handlerThreeDots}) => {
    const file_info = [
        {name: 'File Type', val: show.data.data.file_type},
        {name: 'File Size', val: FormatFilesize(show.data.data.file_size)},
    ]
    
    const handlerImgView = (e, val) => {
        if (e.detail >= 1) {
            show.set({...show.data, view:true, fullview:true, data: val})
        }
    }

    return (
        <>
            <Modal open={show.data.view} sx={{zIndex: openShare.data ? 1300 : 1302}} className="noutlined">
                <Slide in={show.data.view} direction="left" className="noutlined">
                    { show.data.view ? 
                        <Box height="100%" width="100%" display="flex" flexDirection="column" bgcolor="#F8F9FA">
                            <Box minHeight={44} p="12px 16px" display="flex" alignItems="center" justifyContent="space-between">
                                <IconButton sx={{p: '0px'}} onClick={() => show.set({...show.data, view : false, data : ''})}>
                                    <ArrowLeft stroke="#313131" width="16" height="16" />
                                </IconButton>
                                <Box fontSize={16} fontWeight={600} lineHeight="26px">Document Details</Box>
                                <IconButton sx={{p: '0px'}} onClick={(e) => handlerThreeDots(e, 'file info', show.data.data)}>
                                    <VeriticalThreeDots fill={"#737373"} />
                                </IconButton>
                            </Box>
                            <Box p="12px 16px 24px 16px" minHeight={216} className="overflowY overflowx c-pointer">
                                <Box bgcolor="#FFFFFF" minHeight={156} p="24px 24px 0px 24px" onClick={(e) => handlerImgView(e,show.data.data)} display="flex" justifyContent="center" alignItems="center">
                                    {show.data.data.file_type.includes('image') ?
                                        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                                            <Avatar src={show.data.data.file_link} alt={show.data.data.file_link} sx={{height:'auto', width:'100%', borderRadius:'8px'}}/>
                                        </Box>
                                    : show.data.data.file_type.includes('pdf') ?
                                        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                            <Box sx={{ height: 216, width:'100%', borderRadius:'8px 8px 0px 0px' }}>
                                                <Box component={Viewer} fileUrl={show.data.data.file_link} />
                                            </Box>
                                        </Worker>
                                    :
                                        <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                            <FileExtension link={show.data.data.file_link} width={'auto'} height={100}/>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                            <Box height="100%" width="100%" bgcolor="#FFFFFF" borderRadius="12px 12px 0px 0px" p={2} className='overflowY'>
                                <Box display="flex" justifyContent="center">
                                    <Box height="2px" width="37px" bgcolor="#333333" />
                                </Box>
                                <Box fontSize={12} fontWeight={400} lineHeight="25px" pt="20px">
                                    {show.data.data.series_num}
                                </Box>
                                <Box fontSize={16} fontWeight={600} lineHeight="25px" pb="32px">
                                    {show.data.data.file_name}
                                </Box>
                                <Box fontSize={14} fontWeight={600} lineHeight="16px" mt={2} mb={2}>File Info</Box>
                                {
                                    file_info.map((v,k) => (
                                        <Box width="100%" display="flex" alignItems="baseline" mb={1} gap="4px" key={k}>
                                            <Box width="50%" color="#737373" fontSize={14} fontWeight={400} lineHeight="19px">{v.name}</Box>
                                            <Box width="50%" color="#333333" fontSize={14} fontWeight={400} lineHeight="19px" sx={{wordBreak: 'break-word'}}>{v.val}</Box>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Box>
                    :
                        <Box height="100%" width="100%" display="flex" flexDirection="column" bgcolor="#F8F9FA"></Box>
                    }
                </Slide>
            </Modal>
            {show.data.fullview && (
                <Preview show={show} handlerThreeDots={handlerThreeDots} />
            )}
        </>
    )
}
