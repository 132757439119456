import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material"
import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core"
import { motion } from "framer-motion"
import moment from "moment"
import { FavStarMbl, VeriticalThreeDots } from "../../../../../../core/global/Icons"
import FolderIcon from "../../../../../../assets/images/icons/folder.png"
import { FileExtension } from "../../../../global/FileExtension";

export const GridView = ({favList, handlerView, handlerThreeDots}) => {
    return (
        <Grid container spacing="11px">
            {
                favList.data.map((v,k) => (
                    <Grid item xs={6} sm={6} key={k}>
                        {v.folder_id === undefined ?
                            <Box pt={1} boxShadow=" 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02)" display="flex" flexDirection="column" minHeight={105} component={motion.div} whileTap={{ backgroundColor: '#f0f0f0' }}>
                                <Box minHeight="40px" width="100%" display="flex" justifyContent="center" onClick={() => handlerView(v)}>
                                    {
                                        JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('image')? 
                                            <Box sx={{backgroundImage: `url(${JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link})`, backgroundSize: 'cover',
                                                backgroundPosition: 'center center', height: '40px', width: '40px', backgroundRepeat: 'no-repeat', borderRadius: '6px' }} 
                                            />
                                        : JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('pdf') ?
                                            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                <Box sx={{maxHeight: '40px', width:'40px', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                                    <Box component={Viewer} fileUrl={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} defaultScale={SpecialZoomLevel.PageFit} />
                                                </Box>
                                            </Worker>
                                        :
                                            <Box height="100%" width="40px" display="flex" alignItems="center" justifyContent="center">
                                                <FileExtension link={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} width={40} height={40}/>
                                            </Box>
                                    }
                                </Box>
                                <Box py="12px" px="8px" display="flex" flexDirection="column" height="100%">
                                    <Box height="100%" onClick={() => handlerView(v)} >
                                        <Typography fontSize={12} fontWeight={600} lineHeight="19px"noWrap >{v.type === 4 ? v.name: `${v.type_name} (${v.name})`} </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="space-between" height={20} width="100%">
                                        <Box minWidth="20px">
                                            {
                                                v.user_favorite_status === 2  &&
                                                    <FavStarMbl fill={v.user_favorite_status === 2 ? '#11783C' : 'none'} />
                                            }
                                        </Box>
                                        <Typography width="100%"  fontSize={10} fontWeight={400} lineHeight="20px" noWrap onClick={() => handlerView(v)}>
                                            {
                                                v.end_date === null ?
                                                    'Non-Expiring'
                                                :
                                                    `Expiring • ED: ${moment(v.end_date).format('MM/DD/YYYY')}`
                                            }
                                        </Typography>
                                        <Box display="flex" alignItems="center" minWidth={20} width={20}>
                                            <IconButton sx={{p:'2px 1px 4px 0px'}} onClick={() => handlerThreeDots(v)}>
                                                <VeriticalThreeDots fill={"#737373"} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        :
                            <Box pt={1} boxShadow=" 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02)" display="flex" flexDirection="column" minHeight={105} component={motion.div} whileTap={{ backgroundColor: '#f0f0f0' }}>
                                <Box minHeight="40px" width="100%" display="flex" justifyContent="center" onClick={() => handlerView(v)}>
                                    <Avatar src={FolderIcon} variant="square" alt="folder-img" sx={{width:40, height:40}}/>
                                </Box>
                                <Box py="12px" px="8px" display="flex" flexDirection="column" height="100%">
                                    <Box height="100%" onClick={() => handlerView(v)} >
                                        <Typography fontSize={12} fontWeight={600} lineHeight="19px"noWrap >{v.folder_name}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="space-between" height={20} width="100%">
                                        <Box minWidth="20px">
                                            {
                                                v.user_favorite_status === 2  &&
                                                    <FavStarMbl fill={v.user_favorite_status === 2 ? '#11783C' : 'none'} />
                                            }
                                        </Box>
                                        <Box display="flex" alignItems="center" minWidth={20} width={20}>
                                            <IconButton sx={{p:'2px 1px 4px 0px'}} onClick={() => handlerThreeDots(v)}>
                                                <VeriticalThreeDots fill={"#737373"} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        }
                    </Grid>
                ))
            }
        </Grid>
    )
}
