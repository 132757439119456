import { Avatar, Box, Button, MenuItem, Select, Typography } from '@mui/material'
import { domain, env } from '../../../../../core/Env';
import { useState } from 'react';
import Warning from '../../../../../assets/images/icons/warning.png'
import { rqx } from '../../../../../core/request/API';
import { motion } from 'framer-motion';
import { ToolLoader } from '../../../loader/MidLoader';

export const Tools = ({dateToday, bizID, __SESSION, datas, isLoading, tool_state}) => {
    const { preload, globalLoader } = tool_state
    const [selected, setSelected] = useState(0)
    const _header = [ {title: 'Subscription', size: 100}, {title: 'Available User Seats', size: 157}, {title: 'Total Verified Users', size: 157}, {title: 'Total Unverified Users', size: 170}, {title: `Today's Active Users`, size: 170}, {title: `Today's Inactive Users`, size: 172}, ]

    const changeHandler = async(value) => {
        setSelected(value)
    }

    const __redirect = async(e, tid, logo) => {
       e.stopPropagation()
       if (parseInt(bizID) !== tid) {
            preload.set({loader:true, logo:JSON.parse(logo)})
            let res = await rqx.get(`<<global>>/${env()}/tools/toolcb`, {tid: tid, env:env() !== 'prod' ? 'sandbox' : 'production'});
            if (res.msg === 'success') {
                if (res.tu.length > 0) {
                    window.location.href = `${res.tu[0].value}?ainfo=${encodeURIComponent(JSON.stringify(tool_state.__SESSION.data.ainfo))}`
                }
                setTimeout(() => {
                    preload.set({...preload.data, loader:false})
                }, 2000);
                return
            }
       }
    }
   
    return (
        <Box my="12px" py={2} px="12px" width="100%" bgcolor="#FFFFFF" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.12)" maxHeight={300} className="overflowY">
            {isLoading || globalLoader.data ?
                <ToolLoader/>
            : 
                <>
                    <Box minHeight={28}> 
                        <Box pb="12px" fontSize={14} fontWeight={600} color="#333333">Tools Summary</Box>
                        <Select sx={{width: '196px'}} size="small" value={selected} onChange={(e) => changeHandler(e.target.value)}>
                            <MenuItem selected value={0}>All Categories</MenuItem>
                        </Select>
                    </Box>
                    <Box mt={3} mb={2}>
                        <Box display="flex">
                            <Box minWidth={160} display="flex" flexDirection="column">
                                <Box display="flex" alignItems="center" justifyContent="center"  p="12px 10px" height={37} bgcolor="#F0F6F3" border="1px solid #ECECEC" borderRadius="4px 0px 0px 4px" mb={1}>
                                    <Typography fontSize={14} fontWeight={500} >
                                        Tool Name 
                                    </Typography>
                                </Box>
                                {datas.map((v, k) => (
                                    <Box display="flex" flexDirection="column"  mb={1} key={k}>
                                        <Box display="flex" alignItems="center" p="12px 10px" border="1px solid #ECECEC" onClick={(e) => __redirect(e, v.tid, v.logo)} component={motion.div} whileTap={{ backgroundColor: '#F0F6F3'}}>
                                            <Avatar src={ v.tid === parseInt(bizID) ? v.logo : JSON.parse(v.logo)} alt="logo" variant="rounded" sx={{width: 20, height: 20}} />
                                            <Typography ml={1} fontSize={14} noWrap>{v.name}</Typography>
                                        </Box>
                                        {__SESSION[0].role_name === 'Super Admin' &&
                                            <RenewDsg data={v} dateToday={dateToday} tool_state={tool_state} />
                                        }
                                    </Box>
                                ))}
                            </Box>
                            <Box width="100%" sx={{overflowX: 'scroll'}} className="scrollXColor scrollXCustom"> 
                                <Box display="flex" width="100%" mb={1}>
                                    {_header.map((v, k) => (
                                        <Box display="flex" alignItems="center" justifyContent="center" p="12px 10px" height={37} bgcolor="#F0F6F3" borderBottom="1px solid #ECECEC" borderTop="1px solid #ECECEC" key={k}>
                                            <Typography fontSize={14} fontWeight={500} width={v.size} textAlign="center">
                                                {v.title}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                                {datas.map((v, k) => (
                                    <Box display="flex" flexDirection="column" width="100%" mb={1} key={k}>
                                        <Box display="flex" onClick={(e) => __redirect(e, v.tid, v.logo)} component={motion.div} whileTap={{ backgroundColor: '#F0F6F3'}}>
                                            <Box display="flex" alignItems="center"  p="12px 10px" borderBottom="1px solid #ECECEC" borderTop="1px solid #ECECEC">
                                                <Box width={100} display="flex" alignItems="center" justifyContent="center">
                                                    {v.end_date <= dateToday.data ?
                                                            <Typography p="1px 8px" borderRadius="100px" bgcolor="#FEE9E9" color="#F84949" fontSize={10} noWrap> Expired </Typography>
                                                        :
                                                            <Typography p="1px 8px" borderRadius="100px" bgcolor="#07A42A1A" color="#07A42A" fontSize={10} noWrap> Ongoing </Typography>

                                                    }
                                                </Box>
                                            </Box>
                                            <Box display="flex" alignItems="center" p="12px 10px" borderBottom="1px solid #ECECEC" borderTop="1px solid #ECECEC">
                                                { v.tid === parseInt(bizID) ? 
                                                        <Typography fontSize={11} color="#333333" width={157} textAlign="center">Unlimited</Typography> 
                                                    : 
                                                        <Box display="flex" justifyContent="center" width={157}>
                                                            <Typography fontSize={11} color={v.avlblseat === 0 ? "#F84949" : "#333333"} mr="4px">{v.avlblseat}</Typography>
                                                            {v.avlblseat === 0 && (
                                                                <Avatar src={Warning} alt={Warning} sx={{width: 12, height: 12}} />
                                                            )}
                                                        </Box>
                                                }
                                            </Box>
                                            <Box display="flex" alignItems="center" p="12px 10px" borderBottom="1px solid #ECECEC" borderTop="1px solid #ECECEC">
                                                <Typography fontSize={14} width={157} textAlign="center">{v.verified_user}</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" p="12px 10px" borderBottom="1px solid #ECECEC" borderTop="1px solid #ECECEC">
                                                <Typography fontSize={14} width={170} textAlign="center">{v.unverified_user}</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" p="12px 10px" borderBottom="1px solid #ECECEC" borderTop="1px solid #ECECEC">
                                                <Typography fontSize={14} width={170} textAlign="center">{v.active_user}</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" p="12px 10px" borderBottom="1px solid #ECECEC" borderTop="1px solid #ECECEC">
                                                <Typography fontSize={14} width={172} textAlign="center">{v.inactiveuser}</Typography>
                                            </Box>
                                        </Box>
                                        {
                                            (v.end_date !== undefined && v.end_date <= dateToday.data) && 
                                                <Box display="flex" minHeight={51}> 
                                                    {_header.map((v,k) => (
                                                        <Box display="flex" alignItems="center" p="12px 10px" borderBottom="1px solid #ECECEC" key={k}>
                                                            <Typography fontSize={14} width={v.size} textAlign="center"></Typography>
                                                        </Box>
                                                    ))}
                                                </Box>
                                        }
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </>
            }
        </Box>
    )
}

const RenewDsg = ({data, dateToday, tool_state}) => {
    const _clickRenew = () => {
        const domainLocation = `${domain('mp').url}/sso/account/auth?ainfo=${encodeURIComponent(JSON.stringify(tool_state.__SESSION.data.ainfo))}&market_renewal=1&tid=${data.tid}&platform=business`
        try {
            window.open(domainLocation, '_blank').focus()
        } catch (error) {
            window.location.href = domainLocation;
        }
        return
    }
    return(
        (data.end_date !== undefined && data.end_date <= dateToday.data) && 
            <Box p="12px 10px" borderBottom="1px solid #ECECEC" borderLeft="1px solid #ECECEC" borderRadius="4px 0px 0px 4px">
                <Button variant='contained' sx={{fontSize: 12, p: '8px 12px', bgcolor: '#07A42A', height: 25 }} onClick={() => _clickRenew()}>
                    Renew Subscription
                </Button>
            </Box>
    )
}