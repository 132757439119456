import { VM } from '../VM'
const view = VM()
export const Tourstyle = () => { 
    const tourcss = {
        options:{
            zIndex: 1202,
            width: view === '' ? '317px' : '270px',
            borderRadius:'15px',

        },
        buttonNext:{
            fontFamily: "Inter",
            fontWeight: 500,
            backgroundColor: "#127A3E",
            padding: "8px",
            fontSize: "12px !important",
            borderRadius: "15px",
            color: "#fff",
            cursor: "pointer",
            textAlign: "center",
            width: "80px",
            height:'33px'
        },
        buttonSkip:{
            fontFamily: "Inter",
            fontWeight: 500,
            padding: "8px",
            fontSize: "12px !important",
            color: "#042C0D",
            cursor: "pointer",
            textAlign: "center",
            width: "80px",
            height:'33px',
            left: 0,
            marginRight:'2px',
            float:'right'
        },
        tooltip:{
            minHeight:'140px',
            height:'100%',
            borderRadius:'15px',
        },
        tooltipFooter:{
            marginTop:0,
            padding:'0px 16px 0px 16px',
            minHeight:'40px',
        },
        tooltipContent:{
            height:'100%',
            padding:'10px'
        },
        overlay:{
           height:'100%'
        },
    }
    return tourcss
}

export const GMTourstyle = (br, type) => { 
    const tourcss = {
        spotlight:{
            borderRadius: br === 'tabs' || br === undefined  ? '10px': br === '11px' ? '11px':'15px',
        },
        options:{
            zIndex: type === 'ubr' || type === 'last-ubr'? 1300 : 1202,
            // zIndex: 1202,
            width:'317px',
            borderRadius:'15px',
        },
        buttonNext:{
            fontFamily: "Inter",
            fontWeight: 500,
            backgroundColor: "#127A3E",
            padding: "8px",
            fontSize: "12px !important",
            borderRadius: "15px",
            color: "#fff",
            cursor: "pointer",
            textAlign: "center",
            width: "80px",
            height:'33px',
            display:type === 'last-ubr' ? 'block' : 'none'
        },
        tooltip:{
            minHeight:'68px',
            height:'100%',
            borderRadius:'15px',
        },
        tooltipFooter:{
            marginTop:0,
            padding:'0px 16px 0px 16px',
        },
        tooltipContent:{
            height:'100%',
            padding:'10px'
        },
        overlay:{
           height:'100%'
        },
    }
    return tourcss
}