import { Avatar, Box, Divider, IconButton } from '@mui/material';
import { MblToolTip } from '../../core/global/ToolTip';
import { SearchFilter } from '../../core/global/Icons';
import AddIcon from '../../assets/images/ruLogo/addNew.svg'
import Close from '../../assets/images/icons/close.png'
import CloseBlck from '../../assets/images/ruLogo/closeIcon.png'
import { WarningAmber } from '@mui/icons-material';

export const AddNewBtnDsg = ({open, msg, close, event}) => {
    const tooltipDesign = () => {
        return (
            <Box display="flex" alignItems="center" bgcolor="#ffffff" color="#1B1B1B">
                <Box fontSize={14} fontWeight={400}>{msg}</Box>
                <IconButton onClick={close} sx={{ml: 1, p: '0px !important'}}> <img src={Close} alt={Close} /> </IconButton>
            </Box>
        )
        
    }
  return (
        <MblToolTip open={open} title={tooltipDesign()} placement='top-start' arrow>
            <Avatar onClick={event} variant='circular' src={AddIcon} alt={AddIcon} sx={{cursor: 'pointer', position: 'fixed', right: 24, bottom: 80, zIndex: 1,   }} />
        </MblToolTip>
  )
}

export const BottomNavDsg = ({text, event}) => {
    return(
        <Box p={2} minHeight={76}>
            <Box display="flex" alignItems="center" justifyContent="center" bgcolor="#11783C" color="#FFFFFF" borderRadius={2} height="100%" width="100%" onClick={event}>{text}</Box>
        </Box>
    )
}

export const TopNavDsg = ({title, event}) => {
    return(
        <>
            <Box display="flex" alignItems="center" p="12px 20px" width="100%" minHeight={57}>
                <IconButton sx={{ position: 'absolute', p: '0px !important'}} onClick={event}>
                    <img src={CloseBlck} alt={CloseBlck} />
                </IconButton>
                <Box textAlign="center" width="100%" color="#1B1B1B" fontSize={22} fontWeight={600}>
                    {title}
                </Box>
            </Box>
            <Divider />
        </>
    )
}

export const FilterDsg = ({event}) => {
    return(
        <Box display="flex" justifyContent="flex-end" mb={3}>
            <IconButton sx={{bgcolor:"#FFFFFF", borderRadius: 2, '&:hover': { bgcolor:"#FFFFFF" } }} onClick={event}> 
                <SearchFilter stroke='#11783C' /> 
            </IconButton>
        </Box>
    )
}

export const WarningAdmin = ({text, subtext}) => {
    return (
        <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
            <Box width="456px" bgcolor="#FFFFFF" px="24px" pb="24px" borderRadius="6px">
                <Box minHeight="52px"></Box>
                <Box display="flex" flexDirection="column" gap="20px">
                    <WarningAmber/>
                    <Box color="#292C2F" fontSize={16} fontWeight={600} lineHeight="20px" >{text}</Box>
                    <Box color="#5B6167" fontSize={14} fontWeight={400} lineHeight="21px" >
                        {subtext}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
