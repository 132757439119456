import { Box, IconButton, Modal, Typography } from '@mui/material'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { ArrowLeft, TrashIcon, VeriticalThreeDots } from '../../../../../core/global/Icons'
import { motion } from 'framer-motion'
import { FileExtension } from '../../../global/FileExtension';

export const Preview = ({show, fileData, handlerThreeDots, handlerInTrash}) => {
    
    return (
        <Modal open={show.data.fullview}   
            sx={{borderColor:'none !important', zIndex: 1303}}
            onClose={()=> show.set({...show.data, view: true, fullview:false})} className="noutlined">
            <Box height="100%" width="100%" display="flex" flexDirection="column" bgcolor="#313131"> 
                <Box minHeight="44px" width="100%" display="flex" alignItems="center" justifyContent="space-between" px="16px" py="14px">
                    <Box width="90%" display="flex" alignItems="center">
                        <IconButton sx={{mt:'4px'}} onClick={()=> show.set({...show.data, view: true, fullview:false})}>
                            <ArrowLeft stroke='#FFFFFF' width={20} height={20} />
                        </IconButton>
                        <Typography color="#FFFFFF" fontSize={14} fontWeight={400} lineHeight="26px" width="100%"  noWrap>{show.data.data.type_name} ({show.data.data.name})</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" minHeight={20} minWidth={20}>
                        <IconButton sx={{p: '0px'}} onClick={(e)=>handlerThreeDots(e, show.data.data)}>
                            <VeriticalThreeDots fill={"#F2F7FF"} />
                        </IconButton>
                    </Box>
                </Box>
                <Box height="100%" mt="68px" mx='20px' pr="1px" className="overflowY">
                    {
                        fileData.file_type.includes('image')? 
                            <Box component="img" src={fileData.file_link} alt={fileData.file_link} height="auto" width="100%" borderRadius="8px 8px 0px 0px" />
                        : fileData.file_type.includes('pdf')? 
                            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                <Box sx={{height: 'auto', width:'100%', borderRadius:'8px 8px 0px 0px'}}>
                                    <Box component={Viewer} fileUrl={fileData.file_link} />
                                </Box>
                            </Worker>
                        :
                            <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                <FileExtension link={fileData.file_link} width={'auto'} height={200}/>
                            </Box>
                    }
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center" p="8px 8px 30px 8px" bgcolor="#313131">
                    <Box display="flex" alignItems="center" bgcolor="#FFFFFF1A" p="4px 12px" onClick={() => handlerInTrash()}
                        borderRadius="6px" component={motion.div} whileTap={{ backgroundColor: '#E2EDE7'}}
                    >
                        <TrashIcon fill='#E6E6E6' />
                        <Box color="#E6E6E6" ml="4px" fontSize={12} fontWeight={400} lineHeight="22px">In Trash</Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}