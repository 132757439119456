import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material"
import { FavStarMbl, VeriticalThreeDots } from "../../../../../../core/global/Icons"
import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core"
import { motion } from "framer-motion"
import moment from "moment"

export const GridView = ({regList, handlerView, handlerThreeDots}) => {
    
    return (
        <Grid container spacing="11px" px={2}>
            {
                regList.data.map((v,k) => (
                    <Grid item xs={6} sm={6} key={k}>
                        <Box display="flex" flexDirection="column" minHeight={164} component={motion.div} whileTap={{ backgroundColor: '#f0f0f0' }}>
                            <Box minHeight="80px" onClick={() => handlerView(v)}>
                                {
                                    v.file_type === null || v.file_type.includes('image') ?
                                        <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                            <Avatar src={v.file_link} variant="rounded" sx={{height:80, width: 'auto', borderRadius: '6px 6px 0px 0px'}} />
                                        </Box>
                                    :
                                        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                            <Box sx={{maxHeight: '80px', minHeight:'80px', width:'100%', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                                <Box component={Viewer} fileUrl={v.file_link} defaultScale={SpecialZoomLevel.PageWidth} />
                                            </Box>
                                        </Worker>
                                }
                            </Box>
                            <Box py="12px" px="8px" display="flex" flexDirection="column" height="100%">
                                <Box height="100%" onClick={() => handlerView(v)}>
                                    <Typography fontSize={12} fontWeight={600} lineHeight="19px" sx={{wordBreak: 'break-word'}} className="nowrapY" >{v.registration_name} ({v.registered_name})</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" justifyContent="space-between" height={20} width="100%">
                                    <Box display="flex" alignItems="center" onClick={() => handlerView(v)}>
                                        {
                                            v.user_favorite_status === 2  &&
                                                <FavStarMbl fill={v.user_favorite_status === 2 ? '#11783C' : 'none'} />
                                        }
                                        <Box ml={v.user_favorite_status === 2 && 1} fontSize={10} fontWeight={400} lineHeight="20px"> 
                                            {
                                                v.end_date === null ?
                                                    'Non-Expiring'
                                                :
                                                    `Expiring • ED: ${moment(v.end_date).format('MM/DD/YYYY')}`
                                            }
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center" minHeight={20} minWidth={20}>
                                        <IconButton sx={{p: '0px'}} onClick={(e) => handlerThreeDots(e, v)}>
                                            <VeriticalThreeDots fill={"#737373"} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                ))
            }
        </Grid>
    )
}
