import { Box, Backdrop, Modal, Fade, Button } from '@mui/material';
import { useContext, useEffect } from 'react';
import { ToolContext } from '../../core/context/ToolContext';
import { domain } from '../../core/Env';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../db/db';
import Cafgu2 from '../../assets/images/core/cafgu2.svg'
import { useLocation } from 'react-router-dom';

const useCombinedData = () => {
    return useLiveQuery(async () => {
        const [reglist, conlist, docs, pol] = await Promise.all([
            db.reglist.toArray(),
            db.conlist.toArray(),
            db.docs.toArray(),
            db.pol.toArray(),
        ]);
        return { reglist, conlist, docs, pol };
    });
};

export const Continue = () => {
    const ubrdata = useCombinedData()
    const { tool_state, ubr_state } = useContext(ToolContext)
    const { __SESSION } = tool_state
    const { ubrModal, welcomeModal } = ubr_state
    const history = useHistory();

    const handleClose = async(type) => {
        if (type === 'log' || type === 'reg') {
            window.location.href = `${domain('pm').url}/biz/${type === 'log' ?'login':'registration'}`
        }else{
            history.push('/ubr?type=welcome');
            welcomeModal.set(false)
        }
    };

    const urlParams = new URLSearchParams(window.location.search);
    const location = useLocation();
    const locindex = location.pathname.split('/');
    
    useEffect(() => {
        if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0' && urlParams.get('type') !== 'select' && ubrModal.data) {
            if ( location.pathname === '/' || (location.pathname.includes('list') || ( locindex[1] !== 'my-cloud' && locindex[1] !== 'policies' )) ) {
                history.push('/ubr?type=select');
            }
            return
        }
        // eslint-disable-next-line
    }, [location])

    return (
        (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0' && !ubrModal.data &&  ( ubrdata !== undefined && (ubrdata.reglist.length === 0 && ubrdata.conlist.length === 0 && ubrdata.docs.length === 0 && ubrdata.pol.length === 0 ))  ) && (
            <Modal
                open={welcomeModal.data}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={welcomeModal.data}>
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Box position="absolute" width={470} height="auto" borderRadius={5} p={3} mt="-100px">
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" gap="16px">
                                <img src={Cafgu2} width="100px" height="100%" alt="cafgu" />
                                <Box bgcolor="#FFFFFF" borderRadius={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={2}>
                                    <Button onClick={()=>handleClose('log')} variant="outlined" sx={{height:'40px', width:'300px', borderRadius:'8px', ':hover':{bgcolor:'#035827', color:'#FFFFFF'} }}>
                                        Login existing account
                                    </Button>
                                    <Button onClick={()=>handleClose('reg')} variant="outlined" sx={{mt:2, height:'40px', width:'300px', borderRadius:'8px', ':hover':{bgcolor:'#035827', color:'#FFFFFF'} }}>
                                        Create an account
                                    </Button>
                                    <Box textAlign="center">or</Box>
                                    <Button onClick={()=>handleClose('guest')} variant="outlined" sx={{height:'40px', width:'300px', borderRadius:'8px', ':hover':{bgcolor:'#035827', color:'#FFFFFF'} }}>
                                        Continue without account
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        )
    );
}