import { useContext } from "react"
import { ToolContext } from "../../../../../../core/context/ToolContext"
import { Box } from "@mui/material"
import { Files } from "./Files"
import { ThreeDots } from "../modal/ThreeDots"
import { FileInfo } from "../modal/FileInfo"
import { DL } from "../modal/Dl"
import { useHistory } from "react-router-dom"
import { rqx } from "../../../../../../core/request/API"
import { env } from "../../../../../../core/Env"

export const List = ({match}) => {
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { successOpen, message, show, active, rename, showDl, openShare, shareType, docuFile } = cloud_state
    const { __SESSION } = tool_state
    const uai_user = process.env.REACT_APP_UAI_REGULAR
    const urlParams = new URLSearchParams(window.location.search)
    const nav = useHistory()

    const handlerThreeDots = (e, k, v) => {
        let vdata = {...v, access:v.access === null && match.feature === 'shared-access' && __SESSION.data.uaccess[0].role_id === parseInt(uai_user) ? JSON.parse(window.atob(urlParams.get('access')))[0].access : __SESSION.data.uaccess[0].role_id === parseInt(uai_user) ?  v.access : null}

        if (k === 'file info') {
            active.set({...active.data, openMenu : e.currentTarget})
            show.set({...show.data, data : vdata})
        }else if (k === 'dl') {
            active.set({...active.data, openMenu : null})
            showDl.set(e.currentTarget)
        }else{
            active.set({...active.data, val: k, openMenu: e.currentTarget, type : v.feature_type === 4 ? 'file' : 'folder'})
            show.set({...show.data, data : vdata, view : false, fullview : false})
        }
    }

    const handlerView = async(val) => {
        if (val.feature_type === 4 && !(rename.data.id === show.data.data.id && rename.data.val)) {
            show.set({...show.data, data : val, view : true, fullview : false})
            active.set({...active.data, val : '',  openMenu : false })
        }else{
            let f_id = window.btoa(val.id)
            let name = val.folder_name
            let access = ''
            if (match.feature === 'shared-access' && __SESSION.data.uaccess[0].role_id === parseInt(uai_user)) {
                let qsp = { 
                    feature: match.feature,
                    ref: 'access', 
                    parent_id: val.folder_id, 
                }
                let res = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/documents/read`, qsp)
                if (res) {
                    if (res.length > 0) {
                        access = JSON.stringify(res)
                    }else{
                        access = window.atob(urlParams.get('access'))
                    }
                }
            }
            let subfname = urlParams.get('subfname') === null ? name : urlParams.get('subfname')+'/'+name
            let subfid = urlParams.get('subfid') === null ? f_id : urlParams.get('subfid')+'/'+f_id
            if (!rename.data.val) {
                nav.push(`/my-cloud/${match.feature}/folder?subfname=${subfname}&subfid=${subfid}&f_id=${f_id}${match.feature === 'shared-access' && __SESSION.data.uaccess[0].role_id === parseInt(uai_user) ? `&access=${window.btoa(access)}` : '' } `)
            }
        }
    }

    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column" alignContent="space-between">
            <Box p={2} height="100%" width="100%" display="flex" flexDirection="column" gap="8px" >
                <Box height="100%" width="100%">
                   <Files match={match} handlerThreeDots={handlerThreeDots} handlerView={handlerView}/>
                </Box>
            </Box>
            {active.data.openMenu && 
                <ThreeDots active={active} showDl={showDl} show={show} openShare={openShare} shareType={shareType} rename={rename} message={message} successOpen={successOpen} __SESSION={__SESSION} docuFile={docuFile}/>
            }
            <FileInfo show={show} openShare={openShare} handlerThreeDots={handlerThreeDots} /> 
            {Boolean(showDl.data) && (
                <DL showDl={showDl} show={show} message={message} successOpen={successOpen} active={active} />
            )}
        </Box>
    )
}