import { useEffect } from "react";
import { Box, MenuItem, Modal, Slide, Typography } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';

export const FilterDept = ({removeDeptModal, setRemoveDeptModal, cloud_state, usrData, setUsrData, setSlctTxt }) => {
    const { selectedDept, filterActive, ulistAccess, searchInput, allUsrList, withAccess } = cloud_state

    const handlerFilter = (d, type) => {
        const mergeAccess = withAccess.data.val[0].access.map((md) => {
            const td = usrData.users.find((td) => td.id === md.id);
            return {...md, ...td, stat:{view:md.stat.view, update:md.stat.update, download:md.stat.download, remove:td.stat.remove === undefined ? false : td.stat.remove}};
        });

        const mergeArr = usrData.users.map((md) => {
            const td = mergeAccess.find((td) => td.id === md.id);
            return {...md, ...td};
        });
       
        setUsrData({...usrData, users:mergeArr})

        let data = []
        if (type === 'ALL') {
            filterActive.set({id: 0, name: 'All'})
            selectedDept.data.filter((v) => {
                const au = mergeArr.filter((au) => au.company_department_id === v.id && au.name !== 'Admin' && au.stat.view )
                data = [...data, ...au]
                return false
            })
        }else{
            filterActive.set(d)
            const au = mergeArr.filter((au) => au.company_department_id === d.id && au.name !== 'Admin' && au.stat.view )
            data = [...data, ...au]
        }
        let fltrData = data.filter(v => { return !v.stat.remove })
        setSlctTxt(fltrData.length > 0 || data.length === 0 ? false : true)
        
        if (searchInput.data !== '') {
            const filter_fn = data.map((v) => { return {...v, fullname:`${v.first_name} ${v.last_name}`  } }  )
            const res = filter_fn.filter((item) =>
                item.first_name.toLowerCase().match(searchInput.data.toLowerCase()) || item.last_name.toLowerCase().match(searchInput.data.toLowerCase()) || item.fullname.toLowerCase().match(searchInput.data.toLowerCase())
            );
            ulistAccess.set(res.sort((a, b) => a.first_name.localeCompare(b.first_name)))
        }else{
            ulistAccess.set(data.sort((a, b) => a.first_name.localeCompare(b.first_name)))
        }
        setRemoveDeptModal(false)
    }

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            setUsrData(allUsrList.data)
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [])

    return (
        <Modal open={removeDeptModal}>
            <Slide in={removeDeptModal} direction="up">
                <Box height="100%" width="100%" display="flex" flexDirection="column">
                    <Box height="100%" onClick={() => setRemoveDeptModal(false)}   />
                    <Box bgcolor="#FFFFFF" borderRadius="6px 6px 0px 0px" minHeight="30%" className="overflowY">
                        <MenuItem sx={{p: "12px", display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} onClick={() => handlerFilter('', 'ALL')}>
                            <Typography fontSize={14} fontWeight={400} lineHeight="24px" noWrap>All</Typography>
                            {filterActive.data.id === 0 && <CheckIcon sx={{color: '#A2A3A9', fontSize: 20}} />} 
                        </MenuItem>
                        {
                            selectedDept.data.map((v, k) => (
                                v.name !== 'ALL' && (
                                    <MenuItem key={k} sx={{p: '12px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} onClick={() => handlerFilter(v, '')}>
                                        <Typography fontSize={14} fontWeight={400} lineHeight="24px" noWrap>{v.name}</Typography>
                                        {filterActive.data.id === v.id &&  <CheckIcon sx={{color: '#A2A3A9', fontSize: 20}} /> }
                                    </MenuItem>  
                                )
                            ))
                        }
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}
