import { useState } from "react"
import { Box, Button, Checkbox, IconButton, Modal, Slide } from "@mui/material"
import { CheckBoxOutlineBlankOutlined as CheckBoxOutlineBlankOutlinedIcon } from '@mui/icons-material';
import { ArrowLeft } from "../../../../../core/global/Icons"
import { useHistory } from "react-router-dom"
import { useContext } from "react"
import { ToolContext } from "../../../../../core/context/ToolContext"
import { rqx } from "../../../../../core/request/API"
import { env } from "../../../../../core/Env"
import { useEffect } from "react"
import { List } from './List'
import { MblAlert } from '../../../../../core/global/forms/CstmModal'
import { NoFound } from "../../../global/DesktopDsg"
import { MblFetchingLoader } from "../../../../fetching/Loader"
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxIcon from '../../../../../assets/images/icons/check.png'
import { NotifCount } from "../../../../../core/request/Notif"

const uai_user = process.env.REACT_APP_UAI_REGULAR;

export const Trash = () => {
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { trashFiles, isLoading, inftScroll, limit, lid, selectedFiles, show, active, message, successOpen, modalType, reloadStorage } = cloud_state
    const { __SESSION } = tool_state 
    
    const nav = useHistory()
    const [open, setOpen] = useState(true)
    const [showSelect, setShowSelect] = useState(false)
    const [subscribe, setSubscribe] = useState(true)

    const __InitTrashFiles = async(val, fltr) => {
        if (val === 0) {
            isLoading.set(true)
            inftScroll.set(true)
        }

        if (val !== undefined) {
            let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/trash/read`, {
                company_id:__SESSION.data.uaccess[0].company_id, 
                lastId:val, 
                limit:fltr === 'folder' ? 100 : limit.data,
                ref: __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? '' : 'regular_user',
                user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                filter: fltr,
            })
            if (res) {
                let res_fltr = res.map(v => { return {...v, checked : false} } )
                if (fltr === 'folder') {
                    trashFiles.set(res_fltr);
                    return
                }

                let l_id;   
                for (let i = 0; i < res_fltr.length; i++) { l_id = res_fltr[i].updated_at}
                lid.set(l_id);

                if (l_id === 1 || res_fltr.length !== limit.data ) {
                    inftScroll.set(false);
                }

                trashFiles.set((prev) => [...prev, ...res_fltr]);
                isLoading.set(false);
            }
        }
    }

    const __LoadmoreData = () => {
        __InitTrashFiles(lid.data, 'file')
    }

    const handlerBack = () => {
        setOpen(false)
        setTimeout(() => {
            reloadStorage.set(true)
            nav.push('/my-cloud/recent/list')
        }, 200);
    }

    const hanlderModalBtn = async(option) => {
        if (!show.data.remove.loader) {
            if (option === 'cancel') {
                show.set((prev) => ({...prev, remove:{...show.data.remove, val:false, loader:false}}))
                if (modalType.data === 'empty') {
                    trashFiles.set(trashFiles.data.map((v) => {return {...v, checked : false } } ))
                    selectedFiles.set([])
                }
            }else{
                show.set((prev) => ({...prev, remove:{...show.data.remove, loader:true}}))
                let filterReg = selectedFiles.data.filter((v) => {return v.type === 1})
                let regList = filterReg.map(v => {return {registration_id: v.id}})

                let filterCon = selectedFiles.data.filter((v) => {return v.type === 2})
                let conList = filterCon.map(v => {return {contracts_id: v.id}})

                let filterFldr = selectedFiles.data.filter((v) => {return v.type === 3})
                let fldrList = filterFldr.map(v => {return {folder_id: v.id}})

                let filterDoc = selectedFiles.data.filter((v) => {return v.type === 4})
                let docList = filterDoc.map(v => {return {documents_id: v.id}})

                let res;
                if (selectedFiles.data.length <= 1) {
                    let showtype = show.data.data.type === undefined ? selectedFiles.data[0].type : show.data.data.type
                    if (showtype === 3 || showtype === 4 ) {
                        let body =  {
                            id: show.data.data.id === undefined ? selectedFiles.data[0].id : show.data.data.id, 
                            user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                            status:modalType.data === 'restore' ? 3 : 4, 
                            ref: modalType.data === 'restore' ? 'restore' : 'permanent',
                            filter: showtype === 3 ? 'folder':'file',
                            company_id: __SESSION.data.uaccess[0].company_id,
                        }
                        res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/trash/update`, body)
                    }else{
                        res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/${showtype ===  1 ? 'registration' : 'contracts'}/update`, {
                            id:show.data.data.id, 
                            status:3, 
                            user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                            ref:modalType.data === 'restore' ?  'restore' : 'remove',
                            company_id: __SESSION.data.uaccess[0].company_id  
                        })
                    }
                    let fltrData;
                    if (show.data.data.access === null) {
                        fltrData = [{user_id:0, file:showtype, file_id:show.data.data.id, created_by:show.data.data.created_by}]
                    }else{
                        fltrData = JSON.parse(show.data.data.access).map(v => { return { user_id:v.user_id, file:showtype, file_id:show.data.data.id, created_by:show.data.data.created_by } } )
                    }
                    await NotifCount(fltrData, __SESSION, `${modalType.data === 'restore' ?  'restore' : 'remove'}`)
                }else{
                    let body = {
                        ref:modalType.data === 'restore' ? 'restore' : 'permanent',
                        user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                        contracts: conList.length !== 0 ? conList : JSON.parse(`[{"contracts_id":${0}}]`),
                        registration: regList.length !== 0 ? regList : JSON.parse(`[{"registration_id":${0}}]`),
                        folder: fldrList.length !== 0 ? fldrList : JSON.parse(`[{"folder_id":${0}}]`),
                        documents: docList.length !== 0 ? docList : JSON.parse(`[{"documents_id":${0}}]`),
                        company_id: __SESSION.data.uaccess[0].company_id,
                    }

                    res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/trash/remove`, body)

                    let uid  = []
                    selectedFiles.data.map(v => { 
                        return (
                            v.access === null ? 
                            uid.push({user_id:[0], file:v.type, file_id:v.id, created_by:v.created_by}) 
                            :
                                uid.push({user_id:JSON.parse(v.access).map(i => { 
                                    return i.user_id 
                                }), 
                                    file:v.type, file_id:v.id, created_by:v.created_by})
                        )
                    })
                    await NotifCount(uid, __SESSION, `${modalType.data === 'restore' ?  'multi-restore' : 'multi-remove'}`)
                }
                if (res.message === 'success') {
                    selectedFiles.set([])
                    successOpen.set(true)
                    show.set((prev) => ({...prev, remove:{...show.data.remove, val:false, loader:false},  view : false, fullview : false}))
                    active.set({...active.data, val : '', openMenu : null})
                    if (modalType.data === 'restore') {
                        message.set('Successfully restored')
                    }else{
                        message.set('Successfully removed')
                        reloadStorage.set(true)
                    }
                    await __InitTrashFiles(0, 'folder')
                    await __InitTrashFiles(0, 'file')
                }
            }
        }
    }

    const handlerRestoreRemove = async(type) => {
        modalType.set(type)
        show.set({...show.data, remove:{...show.data.remove, val:true}, data: selectedFiles.data.length === 1 ? selectedFiles.data[0] : '' })
    }

    const handlerSelectAll = (e) => {
        e.stopPropagation()
        let option
        if (trashFiles.data.length === selectedFiles.data.length) {
            option = 'deselect'
        }else{
            option = 'select'
        }
        if (option === 'select') {
            trashFiles.set(trashFiles.data.map((v) => {return {...v, checked : option === 'select' ? true : false} } ))
            selectedFiles.set(trashFiles.data)
        }else{
            trashFiles.set(trashFiles.data.map((v) => {return {...v, checked : false} } ))
            selectedFiles.set([])
        }
    }

    useEffect(() => {
        if (subscribe) {
            const __init = async() => {
                await __InitTrashFiles(0, 'folder')
                await __InitTrashFiles(0, 'file')
            }
            __init()
            selectedFiles.set([])
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [])
    
    return (
        <>
            <Modal open={open} className="noutlined">
                <Slide in={open} direction="left" className="noutlined">
                    <Box height="100%" width="100%" bgcolor="#ffffff" display="flex" flexDirection="column">
                        <Box minHeight="44px" maxHeight="44px" display="flex" alignItems="center" mb="12px" p={2} bgcolor={selectedFiles.data.length === 0 ? "#ffffff" : "#E2EDE7"}>
                            {
                                selectedFiles.data.length === 0 ?
                                    <>
                                        <IconButton sx={{position: 'absolute', p: '0px'}} onClick={() => handlerBack()}>
                                            <ArrowLeft stroke="#313131" width="16" height="16" />
                                        </IconButton>
                                        <Box width="100%" textAlign="center" fontSize={16} fontWeight={600} lineHeight="26px">Trash</Box>
                                    </>
                                :
                                    <>
                                        <IconButton sx={{position: 'absolute'}} onClick={(e) => handlerSelectAll(e, 'deselect')}>
                                            <CloseIcon sx={{fontSize: 16, color: '#313131'}} />
                                        </IconButton>
                                        <Box color="#107038" width="100%" textAlign="center" fontSize={16} fontWeight={400} lineHeight="26px"> {`${selectedFiles.data.length} item${selectedFiles.data.length > 1 ? 's' : ''}`}</Box>
                                    </>
                            }
                        </Box>
                        <Box fontSize={12} fontWeight={400} lineHeight="19px" py="10px" px={2}>Files are permanently remove after 30 days.</Box>
                        {(!isLoading.data && trashFiles.data.length > 0)  && (
                            <Box display="flex" alignItems="center" px={2} onClick={(e) => handlerSelectAll(e)}>
                                <Checkbox size="small" sx={{ p: '0px !important'}}
                                    checked={trashFiles.data.length === selectedFiles.data.length}
                                    icon={  <CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 20, color: '#11783C'}} /> } 
                                    checkedIcon={ <img src={CheckBoxIcon} alt={CheckBoxIcon} /> } 
                                />    
                                <Box color="#333333" ml="12px" fontSize={14} fontWeight={400} lineHeight="24px"> {trashFiles.data.length === selectedFiles.data.length ? "Deselect all" : "Select All"}</Box>
                            </Box>
                        )}
                        <Box height="100%" width="100%" className="overflowY" p={2}>
                            {
                                isLoading.data ?
                                    <MblFetchingLoader mt={0} />    
                                :
                                    trashFiles.data.length === 0 ?
                                        <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
                                            <NoFound msg="Trash is Empty" />
                                        </Box>
                                    :
                                        <List trashFiles={trashFiles} __LoadmoreData={__LoadmoreData} show={show} active={active} __InitTrashFiles={__InitTrashFiles} 
                                            modalType={modalType} selectedFiles={selectedFiles} hanlderModalBtn={hanlderModalBtn} showSelect={showSelect} setShowSelect={setShowSelect}
                                            inftScroll={inftScroll}
                                            __SESSION={__SESSION}
                                        />
                            }
                        </Box>
                        {
                            selectedFiles.data.length !== 0 &&
                                <Box minHeight={40} width="100%" display="flex" alignItems="center" gap="9px" px={2} pb={2}
                                    color="#107038" fontSize={14} fontWeight={600} lineHeight="16px" textAlign="center"
                                >
                                    <Button fullWidth onClick={() => handlerRestoreRemove('restore')}
                                        sx={{height: '100%', border: '1px solid #107038', borderRadius: '6px', bgcolor: '#E2EDE7'}}
                                    >
                                        Restore
                                    </Button>
                                    <Button fullWidth onClick={() => handlerRestoreRemove('perma')}
                                        sx={{height: '100%', border: '1px solid #107038', borderRadius: '6px', bgcolor: '#E2EDE7'}}
                                    >
                                        Permanently Remove
                                    </Button>
                                </Box>
                        }
                    </Box>
                </Slide>
            </Modal>
            <MblAlert successOpen={successOpen.data} setSuccessOpen={successOpen.set} text={message.data} />
        </>
    )
}
