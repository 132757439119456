import { env } from "../../../core/Env"
import { rqx } from "../../../core/request/API"

const uai_user = process.env.REACT_APP_UAI_REGULAR;
export const Types = [
    {name: 'All', id: 2,},
    {name: 'Expiring', id: 1},
    {name: 'Non-Expiring', id: 0},
]

export const RegShowIcon = (index, action, regActive) => {
    if (action === 'enter') {
        regActive.set({...regActive.data, val :  index })
    }else {
        if (regActive.data.openMenu === null) {
            regActive.set({...regActive.data, val : '' })
        }
    }
}

export const ConShowIcon = (index, action, conActive) => {
    if (action === 'enter') {
        conActive.set({...conActive.data, val :  index })
    }else {
        if (conActive.data.openMenu === null) {
            conActive.set({...conActive.data, val : '' })
        }
    }
}

export const HandlerActiveStar = (index, starActive) => {
    starActive.set(index)
}

export const HandlerThreeDots = (e, index, val, action, state, show) => {
    state.set({...state.data, val : index, openMenu :  action === 'open' ?  e.currentTarget : null})
    show.set({...show.data, data : show.data.view ? show.data.data : val, view : show.data.view, fullview : false})
    // show.set({...show.data, data : val, view : false, fullview : false})
}

export const HandlerView = async(val, show, regActive, withAccess) => {
    show.set({...show.data, data : val, view : true, fullview : false})
    regActive.set({...regActive.data, val : '',  openMenu : null })
    withAccess.set({...withAccess.data, val: [], loader: true})

    let params = {
        registration_id: val.feature_type === 1 ? val.id : '',
        contracts_id: val.feature_type === 2 ? val.id : '',
        folder_id: '',
        documents_id: '',
    }

    let res = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/access/read`, params)
    withAccess.set({...withAccess.data, val: res, loader: false})
}

export const __InitRecentList = async(isLoading, __SESSION, rcntList) => {
    isLoading.set(true);
    let res_reg = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/registration/read`, {
        company_id:__SESSION.data.uaccess[0].company_id, 
        lastId:0, 
        limit:4,
        filter:2,
        ref: __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? '' : 'regular_user',
        user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1]
    })
    let res_con = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/contracts/read`, {
        company_id:__SESSION.data.uaccess[0].company_id, 
        lastId:0, 
        limit:4,
        ref: __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? '' : 'regular_user',
        user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1]
    })
    
    let reg_fltr = res_reg.map(v => v.access === null || v.access === undefined ? {...v} : {...v, access : JSON.parse(v.access).filter(f => {return f.user_id === parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) }) } )
    let con_fltr = res_con.map(v => v.access === null || v.access === undefined ? {...v} : {...v, access : JSON.parse(v.access).filter(f => {return f.user_id === parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) }) } )
    if (res_reg && res_con) {
        rcntList.set({
            reg:reg_fltr.map(v => { return {...v, feature_type:1} } ),
            contracts:con_fltr.map(v => { return {...v, feature_type:2} } ),
        })
        isLoading.set(false);
    }
}

export const __InitConList = async(val, isLoading, inftScroll, __SESSION, limit, lid, conList) => {
    if (val === 0) {
        isLoading.set(true)
        inftScroll.set(true)
    }

    let params = {
        company_id:__SESSION.data.uaccess[0].company_id, 
        lastId:val, 
        limit:limit.data,
        ref: __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? '' : 'regular_user',
        user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1]
    }

    if (val !== undefined) {
        let data = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/contracts/read`, params)
        let res = data.map(v => { return {...v, feature_type: 2} } )
        let res_fltr = res.map(v => v.access === null || v.access === undefined ? {...v} : {...v, access : JSON.parse(v.access).filter(f => {return f.user_id === parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) }) } )
        if (res) {
            let l_id;   
            for (let i = 0; i < res.length; i++) { l_id = res[i].id}
            lid.set(l_id);
            if (data.length === 0 ) {
                inftScroll.set(false);
                isLoading.set(false);
                return
            }
            if ( val === 0 || val === undefined || val === '') {
                conList.set(res_fltr);
            }else{
                conList.set([...conList.data, ...res_fltr]);
            }
            isLoading.set(false);
        }
    }
}

export const __InitRegList = async(val, fltr, isLoading, inftScroll, __SESSION, limit, lid, regList) => {
    if (val === 0) {
        isLoading.set(true)
        inftScroll.set(true)
    }
    
    if (val !== undefined) {
        let params = {
            company_id:__SESSION.data.uaccess[0].company_id, 
            lastId:val, 
            limit:limit.data,
            filter:fltr,
            ref: __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? '' : 'regular_user',
            user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1]
        }
        let data = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/registration/read`, params)
        let res = data.map(v => { return {...v, feature_type:1} } )
        let res_fltr = res.map(v => v.access === null || v.access === undefined ? {...v} : {...v, access : JSON.parse(v.access).filter(f => {return f.user_id === parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) }) } )
        if (res) {
            let l_id;   
            for (let i = 0; i < res.length; i++) { l_id = res[i].id}
            lid.set(l_id);
            if (data.length === 0 ) {
                inftScroll.set(false);
                isLoading.set(false);
                return
            }
            if ( val === 0 || val === undefined || val === '') {
                regList.set(res_fltr);
            }else{
                regList.set([...regList.data, ...res_fltr]);
            }
            isLoading.set(false);
        }
    }
}

export  const __ListRegName = async(__SESSION, listRegName) => {
    let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/registration/default`, {
        company_id:__SESSION.data.uaccess[0].company_id, 
    })
    let f  = res.filter(v => { return v.id === 0})
    if (f.length === 0) {
        let others = [{id:0, name:'Others'}]
        listRegName.set([...res, ...others])
    }else{
        listRegName.set(res)
    }
}

export const ValidateColor = (form) => {
    let bg_xp;
    let color_xp;
    let bg_non;
    let color_non;
    if (form.data.regName.val === 0) {
        bg_xp = form.data.regType.val === 1 ? "#107038" : "#FFFFFF"
        color_xp = form.data.regType.val === 1 ? "#EAEFF7" : "#333333"
        bg_non = form.data.regType.val === 0 ? "#107038" : "#FFFFFF"
        color_non = form.data.regType.val === 0 ? "#EAEFF7" : "#333333"
    }else{
        bg_xp = form.data.regType.val === 1 ? "#8DA99F" : "#F2F2F2"
        color_xp = form.data.regType.val === 1 ? "#FFFFFF" : "#737373"
        bg_non = form.data.regType.val === 0 ? "#8DA99F" : "#F2F2F2"
        color_non = form.data.regType.val === 0 ? "#FFFFFF" : "#737373"
    }
    return {bg_xp, color_xp, bg_non, color_non}
}

export const HandlerRegType = (form, type) => {
    if (form.data.regName.val === 0) {
        form.set({...form.data, regType : { val: type === 'exp' ? 1 : 0 , stat: false, msg: ''} })
    }
}

export const __RegSeriesNum = async(__SESSION, regsn) => {
    let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/registration/series_num`, {
        company_id:__SESSION.data.uaccess[0].company_id, 
    })
    regsn.set(res)
}

export const ValidateBtnNxtReg = (form) => {
    let bgcolor;
    let disabled = true;
    if (form.data.regType.val === 0 && form.data.regName.val === 0) {
        if (form.data.regOther.val === '' || form.data.name.val === '' || form.data.number.val === '' || form.data.regFile.file.val === '') {
            bgcolor = "#C7DAD7"
            disabled = true
        }else{
            bgcolor = "#107038"
            disabled = false
        }
    }else if (form.data.regType.val === 1 && form.data.regName.val === 0) {
        if (form.data.regOther.val === '' || form.data.name.val === '' || form.data.date_from.val === '' || form.data.date_to.val === '' || form.data.number.val === '' || form.data.regFile.file.val === '') {
            bgcolor = "#C7DAD7"
            disabled = true
        }else{
            bgcolor = "#107038"
            disabled = false
        }
    }else if (form.data.regType.val === 0) {
        if (form.data.name.val === '' || form.data.number.val === '' || form.data.regFile.file.val === '') {
            bgcolor = "#C7DAD7"
            disabled = true
        }else{
            bgcolor = "#107038"
            disabled = false
        }
    }else{
        if (form.data.name.val === '' || form.data.date_from.val === '' || form.data.date_to.val === '' || form.data.number.val === '' || form.data.regFile.file.val === '') {
            bgcolor = "#C7DAD7"
            disabled = true
        }else{
            bgcolor = "#107038"
            disabled = false
        }
    }
    return {bgcolor, disabled}
}

export const validateBtnNxt = (cform) => {
    let bgcolor;
    let disabled = true;
    if ( (cform.data.conName.val === 0 && cform.data.conOther.val === '') || cform.data.name.val === '' || cform.data.date_from.val === '' || cform.data.date_to.val === '' || (cform.data.conFile.file.val === '' && cform.data.conFile2.file.val === '')) {
        bgcolor = "#C7DAD7"
        disabled = true
    }else{
        bgcolor = "#107038"
        disabled = false
    }
    return {bgcolor, disabled}
}

export const HandlerAddFav = async(e, v, __SESSION, state, setFavBtnLoader) => {
    e.stopPropagation()
    
    state.set(
        state.data.map((d,k) => d.id === v.id ? {...d, user_favorite_status : v.user_favorite_status === 2 ? 1 : 2} : {...d} )
    )
    let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/favorites/create`, {
        status:v.user_favorite_status === 2 ? 1 : 2, 
        registration_id: v.feature_type === 1 ? v.id : '',
        contracts_id: v.feature_type === 2 ? v.id : '',
        user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
        documents_id:"",
        folder_id: ""
    })
    
    if (res.message === 'success') {
        setFavBtnLoader(false)
    }
}

export const RecentAddFav = async(e, v, __SESSION, state, setFavBtnLoader) => {
    e.stopPropagation()
    state.set({...state.data,
        [v.feature_type === 2 ? 'contracts' : 'reg'] : (v.feature_type === 2 ? state.data.contracts : state.data.reg).map((d,k) => d.id === v.id ? {...d, user_favorite_status:v.user_favorite_status === 2 ? 1 : 2} : {...d} )
    })
    let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/favorites/create`, {
        status:v.user_favorite_status === 2 ? 1 : 2, 
        registration_id: v.feature_type === 1 ? v.id : '',
        contracts_id: v.feature_type === 2 ? v.id : '',
        user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
        documents_id:"",
        folder_id: ""
    })
    if (res.message === 'success') {
        setFavBtnLoader(false)
    }
}

export const __InitSharedList = async(val, isLoading, inftScroll, __SESSION, lid, sharedFltr, sharedList) => {
    if (val === 0) {
        isLoading.set(true)
        inftScroll.set(true)
    }
    if (val !== undefined) {
        let params = {
            user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
            lastId: val,
            limit: 10,
            ref: sharedFltr.data.type,
            user_type: __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? 'admin' : 'regular_user',
            company_id: __SESSION.data.uaccess[0].company_id,
        }
        const res = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/access/list`, params)
        let flrd = res.map(v => {return {...v, 
            profile : JSON.parse(__SESSION.data.pinfo[0].profile).profile, 
            feature_type : v.identifier === 1 ? 1 : 2, 
            access : v.access !== undefined ? JSON.parse(v.access).filter(f => {return f.user_id === parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) }) : null
        }})
        if (res) {
            let l_id;
            for (let i = 0; i < res.length; i++) {
                l_id = res[i].id
                lid.set(l_id)
            }
            if (res.length === 0) {
                inftScroll.set(false)
                isLoading.set(false)
                return
            }
            if (val === 0 || val === undefined || val === '') {
                sharedList.set(flrd)
            } else {
                sharedList.set([...sharedList.data, ...flrd])
            }
            isLoading.set(false);
        }
    }
}

export const __InitTrashFiles = async(val, isLoading, inftScroll, __SESSION, limit, lid, trashFiles) => {
    if (val === 0) {
        isLoading.set(true)
        inftScroll.set(true)
    }

    if (val !== undefined) {
        let data = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/trash/read`, {
            company_id:__SESSION.data.uaccess[0].company_id, 
            lastId:val, 
            limit:limit.data,
            ref: __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? '' : 'regular_user',
            user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
            filter:'file'
        })
        let res = data.map(v => { return {...v, checked : false} } )
        if (res) {
            let l_id;   
            for (let i = 0; i < res.length; i++) { l_id = res[i].updated_at}
            lid.set(l_id);
            if (l_id === 1 || res.length !== limit.data ) {
                inftScroll.set(false);
            }
            if ( val === 0 || val === undefined || val === '') {
                trashFiles.set(res);
            }else{
                trashFiles.set([...trashFiles.data, ...res]);
            }
            isLoading.set(false);
        }
    }
}

export const __InitTrashFolder = async(val, folderLoader, folderInfi, __SESSION, limit, folderLid, trashFolder) => {
    if (val === 0) {
        folderLoader.set(true)
        folderInfi.set(true)
    }

    if (val !== undefined) {
        let data = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/trash/read`, {
            company_id:__SESSION.data.uaccess[0].company_id, 
            lastId:val, 
            limit:limit.data,
            ref: __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? '' : 'regular_user',
            user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
            filter:'folder'
        })
        let res = data.map(v => { return {...v, checked : false} } )
        if (res) {
            let l_id;   
            for (let i = 0; i < res.length; i++) { l_id = res[i].updated_at}
            folderLid.set(l_id);
            if (l_id === 1 || res.length !== limit.data ) {
                folderInfi.set(false);
            }
            if ( val === 0 || val === undefined || val === '') {
                trashFolder.set(res);
            }else{
                trashFolder.set([...trashFolder.data, ...res]);
            }
            folderLoader.set(false);
        }
    }
}