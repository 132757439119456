import { useState } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import { InfoIcon, InfoIconActive, Restore, TrashIcon } from "../../../../../core/global/Icons";

export const ThreeDots = ({__SESSION, show, active, handlerThreeDots, restore, perma, viewInfo}) => {
    const uai_user = process.env.REACT_APP_UAI_REGULAR;
    const [iconActive, setIconActive] = useState('')
    let validate = show.data.data.access === null || show.data.data.access === undefined || __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) || parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) === show.data.data.created_by
   
    return (
        <>
            <Menu open={Boolean(active.data.openMenu)} anchorEl={active.data.openMenu} elevation={1} onClose={(e) => handlerThreeDots(e, '', '', 'close')}>
                <Box px={1} >
                    {!show.data.view && (
                        <MenuItem sx={{minHeight: '44px !important', py: 0, opacity: show.data.data.type === 3 ? 0.3 : 1}} 
                            onClick={() => viewInfo()}
                            onMouseEnter={() => setIconActive('view')} 
                            onMouseLeave={() => setIconActive('')}
                        >
                            {iconActive === 'view' ? <InfoIconActive /> : <InfoIcon />}
                            <Box ml="12px" fontSize={14} fontWeight={400}> View Info</Box>
                        </MenuItem>
                    )}
                    <MenuItem sx={{minHeight: '44px !important', py: 0, opacity:validate ? 1 : 0.3}} 
                        onClick={ validate ? restore : null} 
                        onMouseEnter={() => setIconActive('restore')} 
                        onMouseLeave={() => setIconActive('')}
                    >
                        <Restore stroke={iconActive === 'restore' ? "#107038" : "#737373"} />
                        <Box ml="12px" mr="40px" fontSize={14} fontWeight={400}> Restore</Box>
                    </MenuItem>
                    <MenuItem sx={{minHeight: '44px !important', py: 0, opacity:validate? 1 : 0.3}} 
                        onClick={validate ? perma : null} 
                        onMouseEnter={() => setIconActive('perma')} 
                        onMouseLeave={() => setIconActive('')}
                    >
                        <TrashIcon fill={iconActive === 'perma' ? "#107038" : "#737373"} />
                        <Box ml="12px" fontSize={14} fontWeight={400}> Permanently Remove</Box>
                    </MenuItem>
                </Box>
            </Menu>
        </>
    )
}