import { Autocomplete, Box, IconButton } from "@mui/material"
import { CstmTextField } from '../../../../../core/global/forms/CstmTextField'
import { NewRemoveRoundIcon } from "../../../../../core/global/GMicons"
import { IOSSwitch } from "../../../../../core/global/forms/CstmToggle"

export const TFields = ({ match, allUsers, teamForm, setTeamForm, teamid }) => {
    const handleInputTeam = (e, id, indx, type) => {
        const {name, value, checked} = e.target
       
        let fdata = [...teamForm]
        if (type === 'member') {
            teamForm[indx][type] = id
        }else{
            teamForm[indx][name] = name === 'team_name' ? value : (checked ? 1 : 0)
        }
        setTeamForm(fdata)
    }

    const handleRemoveRow = (e, index) => {
        let data = [...teamForm]
        data.splice(index, 1)
        data = data.map((v,k) => ({...v, team_number:`TEAM-00000000${teamid + k + 1}`}))
        setTeamForm(data)
    }

    return (
        teamForm.map((v,k) => (
            <Box key={k} width="100%" p={3} display="flex" flexDirection="column" bgcolor="#FFFFFF" alignItems="center" mt={2} borderRadius="10px" className="overflowY">
                <Box width="100%" display="flex" flexDirection="row" bgcolor="#FFFFFF" alignItems="center" mt={2} borderRadius="10px">
                    <Box width="100%" display="flex" flexDirection="column">
                        <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between">
                            <Box mr={2} width="100%">
                                <CstmTextField fullWidth disabled name="team_number" label="Team Number" value={v.team_number}/>
                            </Box>
                            <Box ml={2} width="100%">
                                <CstmTextField fullWidth name="team_name" label="Team Name" error={Boolean(v.error)} helperText={v.error} value={v.team_name} onChange={(e) => handleInputTeam(e, v.id, k)}/>
                            </Box>
                        </Box>
                        <Box mt={2} width="100%">
                            <Autocomplete 
                                name="member"
                                multiple
                                noOptionsText="No Result"
                                disableCloseOnSelect
                                autoHighlight={true}
                                options={allUsers}
                                getOptionLabel={(data) => (data.uname)}
                                value={teamForm[k].member?.length ? allUsers.filter((user) => teamForm[k].member.includes(user.id)) : []}
                                onChange={(e, value) => {
                                    const selectedIds = value.map((option) => option.id)
                                    handleInputTeam(e, selectedIds, k, 'member')
                                }}
                                renderInput={(params) => (
                                    <CstmTextField fullWidth label="Team Member" {...params} />
                                )}
                            />
                        </Box>

                    </Box>
                    {
                        teamForm.length > 1 &&
                        <Box width="5%" ml={2}>
                            <IconButton variant="contained" sx={{bgcolor: '#D8D5D5', '&:hover':{ backgroundColor: '#1B984F' }}} onClick={(e) => handleRemoveRow (e)}>
                                <NewRemoveRoundIcon />
                            </IconButton>
                        </Box>
                    }
                </Box>
                {
                    match.params === 'update' &&
                    <Box width="100%" display="flex" justifyContent="flex-end" gap={1} p="16px 16px 0px 16px">
                        <IOSSwitch name="active" value={teamForm.active} checked={teamForm[k].active === 1 ? true : false} onClick={(e) => handleInputTeam (e, v.id, k,'active')}/>
                        <Box>{v.active === 1 ? 'Active' : 'Inactive'}</Box>
                    </Box>
                }
            </Box>
        ))
    )
}