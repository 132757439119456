import { Box, IconButton, Modal, Slide } from "@mui/material"
import { ArrowLeft } from "../../../../../core/global/Icons"
import { Worker, Viewer } from "@react-pdf-viewer/core"

export const Preview = ({ pModal, setPModal}) => {
    const handlerCloseView = async() => {
        await setPModal({...pModal, fileview: false })
    }

    return (
        <Modal open={pModal.fileview} onClose={handlerCloseView} sx={{zIndex: 1302}} >
            <Slide in={pModal.fileview} direction="left" className="noutlined">
                <Box bgcolor="#FFFFFF" height="100%" width="100%" display="flex" flexDirection="column">
                    {}
                    <Box minHeight="44px" p="12px 16px" display="flex" alignItems="center">
                        <Box>
                            <IconButton sx={{p: '0px'}} onClick={handlerCloseView}>
                                <ArrowLeft stroke="#313131" width={16} height={16} />
                            </IconButton>
                        </Box>
                        <Box display="flex" justifyContent="center" fontSize="16px" fontWeight="600" lineHeight="26px" ml="15px">{pModal.data[0].policyname}</Box>
                    </Box>
                    <Box height="100%" p="12px 16px 24px 16px" className="overflowY">
                        <Box height="100%" bgcolor="#FFFFFF" display="flex" justifyContent="center">
                            <Worker workerUrl={'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'}>
                                <Viewer fileUrl={pModal.data[0].file_link} />
                            </Worker>
                        </Box>
                    </Box>
                    <Box minHeight="20px"></Box>
                </Box>
            </Slide>
        </Modal>
    )
}