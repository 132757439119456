import { Box } from '@mui/material';

export const List = ({isShow, sliderRef, listBanner, handleClick, cstm, loaded, instanceRef, currentSlide}) => {
    return (
        isShow && (
            <Box maxWidth="100%">
                <Box className="navigation-wrapper">
                    <Box ref={sliderRef} className="keen-slider" zIndex={0} width="100%">
                        {listBanner.data.map((value, index) =>(
                            <Box key={index} className={`keen-slider__slide keen-slider__slide_css keen-slider__slidecstm c-pointer`} py={1} onClick={(e)=>handleClick(e, value.links, value.name)} px={1}>
                                <Box className={cstm.hover25ad6a} display="flex" flexDirection="column" justifyContent="space-around" bgcolor="#1C9B51" borderRadius={3} width="100%" height="100%" p={2}>
                                    <Box height="30%">
                                    <Box sx={{ backgroundImage: `url(${value.icon})`, backgroundSize: "cover", height:'40px', width:'40px'}}/>
                                    </Box>
                                    <Box height="70%" display="flex" flexDirection="column" justifyContent="space-around">
                                        <Box my={2} fontSize={16} color="#FFD922" component="strong">{value.title}</Box> 
                                        <Box fontSize={12} color="#ffff" fontWeight={500}>{value.description}</Box>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    
                    {loaded && instanceRef.current && listBanner.data.length > 2 && (
                        <Box className="dots">
                            {[...Array(instanceRef.current.track.details.slides.length).keys()].map((idx) => {
                                return (
                                    <button
                                        key={idx}
                                        onClick={() => {
                                            instanceRef.current?.moveToIdx(idx)
                                        }}
                                        className={"dot" + (currentSlide === idx ? " active" : "")}
                                    />
                                )
                            })}
                        </Box>
                    )}
                </Box>
            </Box>
        )
    )
}