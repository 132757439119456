import { Waypoint } from "react-waypoint";
import { SnakeLoader } from "../../../../../core/loader/SnakeLoader";
import { Loader } from "../../../Loader";
import { NoFound, Tooltipdsg } from "../../../global/DesktopDsg";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { StarIcon, VeriticalThreeDots } from "../../../../../core/global/Icons";
import { FavToolTip } from "../../../../../core/global/ToolTip";
import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import moment from "moment";
import { FileExtension } from "../../../global/FileExtension";

export const FileList = ({cloud_state, addFav, __LoadmoreFiles, handlerViewFile, handlerThreeDots, showThreeDots}) => {
    const { active, favList, inftScroll, isLoading, listView } = cloud_state
    
    return (
        isLoading.data ?
            <Loader />
        :
            (
                favList.data.length === 0 ?
                    <NoFound msg="No favorite files" submsg="Activate stars for files you wish to find later with ease." />
                :
                    <>
                        {
                            listView.data ?
                                favList.data.map((v,k) => (
                                    <Box display="flex" justifyContent="space-between" alignItems="center" p="12px" borderRadius="6px" key={k} mb={2} className="c-pointer" 
                                        sx={{bgcolor: active.data.val === k && active.data.type === 'file' ? '#f0f0f0' : '#ffffff' }}
                                        onMouseOver={() => showThreeDots(k, 'enter', 'file')} 
                                        onMouseLeave={() => showThreeDots('', 'leave', 'file')}
                                    >
                                        <Box display="flex" alignItems="center" width="100%" onClick={() => handlerViewFile(v)}>
                                            {
                                                JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('image')? 
                                                    <Avatar src={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} variant="rounded" sx={{ width: 48, height: 48, borderRadius: '6px' }} />
                                                : JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('pdf') ? 
                                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                        <Box sx={{maxHeight: '48px', width:'48px', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                                            <Box component={Viewer} fileUrl={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} defaultScale={SpecialZoomLevel.PageFit} />
                                                        </Box>
                                                    </Worker>
                                                :
                                                    <Box height="100%" width="48px" display="flex" alignItems="center" justifyContent="center">
                                                        <FileExtension link={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} />
                                                    </Box>
                                            }
                                            <Box display="flex" flexDirection="column" ml="12px">
                                                <Box fontSize={14} fontWeight={600} lineHeight="19px">{`${v.type_name}(${v.name})`}</Box>
                                                <Typography fontSize={14} fontWeight={400} lineHeight="23px" color="#737373" noWrap> 
                                                    {
                                                        v.end_date === null ?
                                                            'Non-Expiring'
                                                        :
                                                            `Expiring • ED: ${moment(v.end_date).format('MM/DD/YY')}`
                                                    }
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                                <FavToolTip title={Tooltipdsg(v.user_favorite_status)}>
                                                    <IconButton sx={{p: '0px !important', mr: 1}} onClick={(e) => addFav(e,v)}>
                                                        <StarIcon fill={v.user_favorite_status === 2 ? '#11783C' : 'none'} 
                                                            stroke={v.user_favorite_status === 2 ? '#11783C' : '#737373'} 
                                                        />
                                                    </IconButton>
                                                </FavToolTip>
                                            {
                                                active.data.val === k && active.data.type === 'file' &&
                                                    <IconButton sx={{p: '0px !important'}} onClick={(e) => handlerThreeDots(e, k, v, 'file')}>
                                                        <VeriticalThreeDots fill={active.data.openMenu !== null ? "#107038" : "#737373"} />
                                                    </IconButton>
                                            }
                                        </Box>
                                    </Box>
                                ))
                            :
                                <Box width="100%" display="flex" flexWrap="wrap" gap="20px">
                                    {
                                        favList.data.map((v, k) => (
                                            <Box minWidth='273px' maxWidth='273px' key={k} >
                                                <Box height="241px" width="100%" p="12px" borderRadius="6px" boxShadow='0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)' display="flex" flexDirection="column" 
                                                    sx={{bgcolor: active.data.val === k && active.data.type === 'file' ? '#f0f0f0' : '#ffffff', '&:hover': { bgcolor: '#f0f0f0' } }}
                                                    onMouseOver={() => showThreeDots(k, 'enter', 'file')} 
                                                    onMouseLeave={() => showThreeDots('', 'leave', 'file')}
                                                > 
                                                    <Box height="100%" className="c-pointer" onClick={() => handlerViewFile(v)}>
                                                        <Box p="16px 16px 0px 16px" bgcolor="#F8F9FA" borderRadius="8px" minHeight={125}>
                                                            {
                                                                JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('image')? 
                                                                    <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                                                        <Avatar src={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} variant="rounded" sx={{height:100, width: 'auto', borderRadius: '6px 6px 0px 0px'}} />
                                                                    </Box>
                                                                :JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('pdf')?
                                                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                                        <Box sx={{maxHeight: '109px', width:'100%', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                                                            <Box component={Viewer} fileUrl={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} defaultScale={SpecialZoomLevel.PageFit} />
                                                                        </Box>
                                                                    </Worker>
                                                                :
                                                                    <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                                                        <FileExtension link={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} />
                                                                    </Box>
                                                            }
                                                        </Box>
                                                        <Box height="100%">
                                                            <Typography fontSize={14} fontWeight={600} lineHeight="22px" sx={{wordBreak: 'break-all'}} className="nowrapY" mt="12px">
                                                                {v.type_name} {`(${v.name})`}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" justifyContent="space-between" minHeight={24}>
                                                        <Typography fontSize={14} fontWeight={400} lineHeight="23px" color="#737373" noWrap> 
                                                            {
                                                                v.end_date === null ?
                                                                    'Non-Expiring'
                                                                :
                                                                    `Expiring • ED: ${moment(v.end_date).format('MM/DD/YY')}`
                                                            }
                                                        </Typography>
                                                        <Box display="flex" alignItems="center">
                                                            <FavToolTip title={Tooltipdsg(v.user_favorite_status)}>
                                                                <IconButton sx={{p: '0px !important', mr: 1}} onClick={(e) => addFav(e,v)}>
                                                                    <StarIcon fill={v.user_favorite_status === 2 ? '#11783C' : 'none'} 
                                                                        stroke={v.user_favorite_status === 2 ? '#11783C' : '#737373'} 
                                                                    />
                                                                </IconButton>
                                                            </FavToolTip>
                                                            {
                                                                active.data.val === k && active.data.type === 'file' &&
                                                                    <IconButton sx={{p: '0px !important'}} onClick={(e) => handlerThreeDots(e, k, v, 'file')}>
                                                                        <VeriticalThreeDots fill={"#737373"} />
                                                                    </IconButton>
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))
                                    }
                                </Box>

                        }
                        {inftScroll.data && 
                            <Box display="flex" justifyContent="center">
                                <Waypoint onEnter={__LoadmoreFiles} >
                                    <Box component="strong" mt={5} display="flex" justifyContent="center">
                                        <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                                    </Box>
                                </Waypoint>
                            </Box>
                        }
                    </>

            )
    )
}
