import { Box, MenuItem, Modal, Slide, Typography } from "@mui/material";
import { DlIcon, ShareIcon, TrashIcon, UpdateIcon } from "../../../../../../core/global/Icons";
// import { useState } from "react";
import { Download, DownloadZip } from "../../../../../../core/transmit/S3";
import { rqx } from "../../../../../../core/request/API";
import { env } from "../../../../../../core/Env";

export const ThreeDots = ({active, showDl, show, openShare, shareType, rename, message, successOpen, __SESSION, docuFile}) => {
    // const dfltbtnList = [
    //     // {name: 'View Details', icon: <InfoIcon />, type: 'view'},
    //     {name: 'Share Access', icon: <ShareIcon fill="#737373" />, type: 'share'},
    //     {name: show.data.data.user_favorite_status === 2 ? 'Remove to Favorites' : 'Add to Favorites', icon: <StarIcon fill={show.data.data.user_favorite_status === 2 ? "#107038" : "none"} stroke={show.data.data.user_favorite_status === 2 ? "#107038" : "#737373"} />, type: 'addfav'},
    //     {name: 'Rename', icon: <UpdateIcon />, type: 'update'},
    //     {name: `Download ${show.data.data.feature_type !== 3 && (show.data.data.file_type.split('/')[0] === 'image') ? 'as...' : ''}`, icon: <DlIcon />, type: 'dl'},
    //     {name: 'Remove', icon: <TrashIcon fill="#737373" />, type: 'remove'},
    // ]

    // const [btnList, setBtnList] = useState([])

    const handlerCloseMenu = (type) => {
        active.set({...active.data, val : '', openMenu : null, type: ''})
        if (type === 'update') {
            show.set({...show.data, view : false, fullview : false})
        }
    }

    const handlerRename = (id, type) => {
        rename.set({...rename.data, id: id, val : true, type: type})
        active.set({...active.data, val : '', openMenu :  null})
    }

    const handlerMenuBtn = async(e, type) => {
        if (type === 'view') {
            show.set({...show.data, data : show.data.data, view : true, fullview : false})
            active.set({...active.data, val : '', openMenu :  null})
        }else if (type === 'addfav') {
            let body = {
                status: show.data.data.user_favorite_status === 2 ? 1 : 2, 
                registration_id: "",
                contracts_id: "",
                user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                documents_id: show.data.data.feature_type === 4 ? show.data.data.id : "",
                folder_id:  show.data.data.feature_type === 3 ? show.data.data.id : ""
            }
            active.set({...active.data, val : '', openMenu : null, type: ''})
            docuFile.set(
                docuFile.data.map((d) => (d.id === show.data.data.id && show.data.data.feature_type === d.feature_type) ? {...d, user_favorite_status : show.data.data.user_favorite_status === 2 ? 1 : 2} : {...d} )
            )
            
            let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/favorites/create`, body)
            if (res.message === 'success') {
                await message.set(show.data.data.user_favorite_status === 2 ? 'Sucessfully removed from favorites' : 'Sucessfully added to favorites')
                successOpen.set(true)
                show.set(prev => ({...prev, data:{...show.data.data, user_favorite_status: (show.data.data.user_favorite_status === 1 ? 2 : 1) }}))
            }

        }else if (type === 'update') {
            handlerRename(show.data.data.id, active.data.type)
            // nav.push(`/my-cloud/registrations/${window.btoa(show.data.data.id)}`)
            // modal.set({...modal.data, regForm : {val : true} })
            // actType.set('update')
        }else if (type === 'dl') {
            let res;
            if (show.data.data.feature_type === 3) {
                let data = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/documents/downloadFolder`, {cf_id:show.data.data.id})
                res = await DownloadZip(data)
            }else{
                if (show.data.data.file_type.split('/')[0] === 'image') {
                    showDl.set(e.currentTarget)
                }else{
                    res = await Download(e, show.data.data.file_link, show.data.data.file_name)
                }
            }
            if (res === 'success') {
                showDl.set(null)
                active.set({...active.data, val : '', openMenu : null})
                message.set('Successfully download')
                successOpen.set(true)
            }

        }else if (type === 'remove') {
            show.set({...show.data, remove:{...show.data.remove, val:true}})
        }else if (type === 'share') {
            openShare.set(true)
            shareType.set('details')
        }
        if (type !== 'dl' && type !== 'addfav') {
            handlerCloseMenu(type)
        }
    }

    // const [subscribe, setSubscribe] = useState(true)
    // console.log(show.data.data.access)
    // useEffect(() => {
    //     if (subscribe) {
    //         if (show.data.data.access !== null && show.data.data.access !== undefined) {
    //             let d; 
    //             if (show.data.data.access !== null && (!show.data.data.access[0].update && !show.data.data.access[0].download)) {
    //                 d = dfltbtnList.filter(v => { return v.type === 'share' || v.type === 'addfav' }  )
    //             }else if(show.data.data.access !== null && (show.data.data.access[0].update && !show.data.data.access[0].download)){
    //                 d = dfltbtnList.filter(v => { return v.type === 'share' || v.type === 'addfav' || v.type === 'update' }  )
    //             }else if(show.data.data.access !== null && (!show.data.data.access[0].update && show.data.data.access[0].download)){
    //                 d = dfltbtnList.filter(v => { return v.type === 'share' || v.type === 'addfav' || v.type === 'dl' }  )
    //             }else{
    //                 d = dfltbtnList.filter(v => { return v.type !== 'remove' }  )
    //             }
    //             setBtnList(d)
    //         }else{
    //             setBtnList(dfltbtnList)
    //         }
    //     }
    //     return () => setSubscribe(false)
    //     // eslint-disable-next-line
    // }, [])

    let accessUpdate;
    let accessDownload;
    if (show.data.data.access) {
        if (typeof show.data.data.access === 'string') {
            accessUpdate = JSON.parse(show.data.data.access)[0].update
            accessDownload = JSON.parse(show.data.data.access)[0].download
        }else{
            accessUpdate = show.data.data.access[0].update
            accessDownload = show.data.data.access[0].download
        }
    }
    
    let dwnldValidAccess = false
    let updtValidAccess = false
    // let removeValidAccess = show.data.data.access !== null && show.data.data.access !== undefined && show.data.data.access[0].remove !== undefined && show.data.data.access[0].remove
    if (show.data.data.access === null || show.data.data.access === undefined || (show.data.data.access !== null && accessUpdate )) {
        updtValidAccess = true
    }
    if (show.data.data.access === null || show.data.data.access === undefined || (show.data.data.access !== null && accessDownload )) {
        dwnldValidAccess = true
    }

    return (
        <Modal open={Boolean(active.data.openMenu)} onClose={() => handlerCloseMenu()} BackdropProps={{ sx: {bgcolor: '#0000001F'} }} sx={{zIndex: 1303}} className="noutlined">
            <Slide in={Boolean(active.data.openMenu)} direction="up" className="noutlined">
                <Box height="100%" width="100%" display="flex" justifyContent="flex-end" flexDirection="column">
                    <Box height="100%" onClick={() => handlerCloseMenu()} />
                    <Box bgcolor="#FFFFFF" p={2} borderRadius="16px 16px 0px 0px" boxShadow="0px 0px 24px rgba(0, 0, 0, 0.08)">
                        <Box display="flex" justifyContent="center" mb={1}>
                            <Box height="2px" width="37px" bgcolor="#333333" />
                        </Box>
                        {
                            !show.data.view &&
                                <Typography fontSize={14} fontWeight={600} lineHeight="25px" noWrap>
                                    {show.data.data.feature_type === 3 ? `${show.data.data.folder_name}` : `${show.data.data.file_name}`}
                                </Typography>
                        }
                        <MenuItem sx={{px: '0px', py: '8px', minHeight: '36px'}} 
                            onClick={(e) => handlerMenuBtn(e, 'share')}
                        >
                            <ShareIcon fill="#737373" />
                            <Box  ml="12px" fontSize={14} fontWeight={400} lineHeight="22px"> Share Access</Box>
                        </MenuItem>
                        {!show.data.view && (
                            <MenuItem sx={{px: '0px', py: '8px', minHeight: '36px', opacity:updtValidAccess ? 1 :0.3 }} 
                                onClick={ updtValidAccess ? (e) => handlerMenuBtn(e, 'update') : null}
                            >
                                <UpdateIcon/>
                                <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px"> Rename</Box>
                            </MenuItem>
                        )}
                        <MenuItem sx={{px: '0px', py: '8px', minHeight: '36px', bgcolor: showDl.data !== null ? '#F2F2F2' : '#ffffff', opacity:dwnldValidAccess ? 1 :0.3,}}
                            onClick={ dwnldValidAccess ? (e) => handlerMenuBtn(e, 'dl') : null}
                        >
                            <DlIcon/>
                            <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px">
                                {`Download ${show.data.data.feature_type !== 3 && (show.data.data.file_type.split('/')[0] === 'image') ? 'as...' : ''}`}
                            </Box>
                        </MenuItem>
                        <MenuItem sx={{px: '0px', py: '8px', minHeight: '36px', opacity:(show.data.data.access === null || show.data.data.access === undefined) ? 1 : 0.3}} 
                            onClick={ (show.data.data.access === null || show.data.data.access === undefined) ? (e) => handlerMenuBtn(e, 'remove') : null}
                        >
                            <TrashIcon fill="#737373"/>
                            <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px"> Delete</Box>
                        </MenuItem>

                        {/* {
                            (show.data.view ? btnList.filter(v => { return v.type !== 'view'}) : btnList).map((v,k) => (  
                                <MenuItem sx={{px: '0px', py: '8px', minHeight: '36px'}} key={k} onClick={(e) => handlerMenuBtn(e, v.type)}>
                                {v.icon}
                                    <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px">{v.name}</Box>
                                </MenuItem>
                            ))
                        } */}
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}
