import { API } from "aws-amplify"
import { onUpdateBusinessTriggerV2, onCreateBusinessTriggerV2 } from "./graphql/subscriptions"

export const NotifUpdate = async(notif_state, validateAccount) => {
    const s = API.graphql({
        query: onUpdateBusinessTriggerV2,
        // variables: {
        //     ifRun: boolen
        // }
    }).subscribe({
        next: (res) => {
            let trigger = res.value.data.onUpdateBusinessTriggerV2
            notif_state.notifPerUser.set(0)
            notif_state.rtAccess.set(false)
            // console.log(trigger)
            if (trigger.id === process.env.REACT_APP_RTID) {
                console.log('realtime running')
                notif_state.rtNotifAccess.set(true)
                notif_state.notifPerUser.set(trigger.uid)
            }else if(trigger.id === '1793dc10-3a1c-48fc-b385-38956d0a2437'){
                console.log('exp and nearly')
                notif_state.trigger.set(trigger)
            }else if(trigger.id === process.env.REACT_APP_RTVAID){
                console.log('validate account')
                validateAccount.set({data:trigger, count:validateAccount.data.count + 1})
            }
        }
    })
    return () => s.unsubscribe()
}

export const NotifCreate = async(boolen, notif_state) => {
    const s = API.graphql({
        query: onCreateBusinessTriggerV2,
    }).subscribe({
        next: (res) => {
            let trigger = res.value.data.onCreateBusinessTriggerV2
            notif_state.trigger.set(trigger)
            // console.log(trigger)
        }
    })
    return () => s.unsubscribe()
}