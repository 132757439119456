import { useContext, useEffect, useState } from "react"
import { ToolContext } from "../../core/context/ToolContext";
import { env } from "../../core/Env";
import { rqx } from "../../core/request/API";
import moment from "moment";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../db/db";
import { UbrApi } from "./UbrAPI";

const tool_id =  process.env.REACT_APP_TID;
const global_id = process.env.REACT_APP_GLOBAL_ID;

const useCombinedData = () => {
    return useLiveQuery(async () => {
        const [reglist, conlist, docs, pol] = await Promise.all([
            db.reglist.toArray(),
            db.conlist.toArray(),
            db.docs.toArray(),
            db.pol.toArray()
        ]);
        return { reglist, conlist, docs, pol };
    });
};

export const RenderAPI = () => {
    const ubrdata = useCombinedData()
    const { tool_state, gen_main_state, home_state, subdivision_state, personalization_state, cloud_state } = useContext(ToolContext)
    const { __SESSION, globalLoader, toolOwned, toolCartCount, noAccessModal, validateAccount } = tool_state
    const { globalList, nobList, ucount, seqName, compCity, compBarangay, compinfo } = gen_main_state
    const { dateToday } = home_state
    const { province } = subdivision_state
    const { personalizationData } = personalization_state
    const { openCloud } = cloud_state

    const __runactiveuser = async() => {
		// const geocoder = new google.maps.Geo
		// if (navigator.geolocation) {
		// 	navigator.geolocation.getCurrentPosition(position => {
		// 		const { latitude, longitude } = position.coords;
		// 		console.log(latitude)
		// 		console.log(longitude)
		// 	});
		// }
		let data = {
			action: "signin",
			aid: __SESSION.data.ainfo.aid,
			date: moment(new Date()).format('YYYY-MM-DD'),
			tool_id: JSON.parse(tool_id)
		}
		await rqx.post(`<<global>>/${env()}/trail/create`, data)
	}

    const loadGlobalFnc = async(plen) => {
      	let user_id = atob(__SESSION.data.ainfo.aid).split('_', 2)[1];
		let prsnl = await rqx.get(`<<global>>/${env()}/personalization/read`, {user_id:user_id, tool_id:tool_id, ref:'all'})
		
		if (prsnl.length === 0 && plen !== 0 ) {
			let prsnl_data = {
                user_id:parseInt(user_id), 
                tool_id:parseInt(tool_id), 
                feature_name:'dashboard', 
                personalization:[
                    {sf_name:'profiling', status:1},
                    {sf_name:'welcomeTour', status:0},
                ] 
            }
			personalizationData.set([prsnl_data])
			await rqx.post(`<<global>>/${env()}/personalization/create`, prsnl_data)
		}else{
			let map_arry = prsnl.map(v => {return {...v, personalization: JSON.parse(v.personalization)} } );
			personalizationData.set(map_arry)
		}
	}

    const loadGlobalBiz = async(__SESSION) => {
		if (__SESSION[0].company_id !== null) {
			const res_seqName = await rqx.get(`<<tool>>/${env()}/general-maintenance/numbersequence/getAllByCompanyId`, {company_id: __SESSION[0].company_id });
			if (res_seqName) {
				seqName.set(res_seqName.map((v) => v.sequence_name === 'department' ? {...v, status:'done'} : (v.updated_by === 'none' ? {...v, status:'pending'} : {...v, status:'done'})))
			}

			let res_compinfo = await rqx.get(`<<tool>>/${env()}/general-maintenance/company/company-setup/getByCompanyId`, {company_id: __SESSION[0].company_id})
			if (res_compinfo.length > 0) {
				if (res_compinfo[0].province_id !== 'none') {
					const res_city = await rqx.get(`<<global>>/${env()}/location/city`, {ref: 'province_id', filter:JSON.stringify([res_compinfo[0].province_id])});
                    const res_barangay = await rqx.get(`<<global>>/${env()}/location/barangay`, {ref: 'city_id', filter:JSON.stringify([res_compinfo[0].city_id])});
                    compCity.set(res_city)
                    compBarangay.set(res_barangay)
					res_compinfo = [{...res_compinfo[0], 
						city_name: res_city.filter(v => { return res_compinfo[0].city_id === v.id})[0].name, 
						barangay_name: res_barangay.filter(v => { return res_compinfo[0].barangay_id === v.id})[0].name,
					}]
                }
			}
			compinfo.set(res_compinfo)
		}
	}

    const __init = async() => {
        await globalLoader.set(true)
        if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') {
          
            await __runactiveuser()
            await loadGlobalFnc(__SESSION.data.pinfo.length)
            await loadGlobalBiz(__SESSION.data.uaccess)
            
            //DATE TODAY
            let res = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/dashboard/today`)
            dateToday.set(res)
    
            //GET TOOL OWNED
            let prms = __SESSION.data.uaccess[0].role_id === 0 ? {aid:__SESSION.data.ainfo.aid, platform:'business' } : {aid:__SESSION.data.ainfo.aid, platform:'business', ref:0, owner_id:__SESSION.data.uaccess[0].owner_id }
            let resTool = await rqx.get(`<<global>>/${env()}/tools/owned`, prms)
            toolOwned.set(resTool)
    
            // GET ADDRESS 
            let res_province = await rqx.get(`<<global>>/${env()}/location/province`, {ref:'id', filter:'all' })
            province.set(res_province)
    
            //GET USER COUNT 
            let u_count = await rqx.get(`<<global>>/${env()}/account/readaccount`, {company_id:__SESSION.data.uaccess[0].company_id, id:'' })
            ucount.set(u_count.length)
            
            //TOOL ON CART COUNT
            let tccount = await rqx.get(`<<global>>/${env()}/tools/cart`, {aid:__SESSION.data.ainfo.aid, ref:'count' })
            toolCartCount.set(tccount)
    
            //LOAD GLOBAL LIST 
            let res_global_list = await rqx.get(`<<global>>/${env()}/tools/globallists`, {tid:parseInt(global_id)})
            
            //LOAD NOB
            let res_nob = await rqx.get(`<<global>>/${env()}/naturebusiness/read`, {id: 'all'});
    
            if (res_global_list && res_global_list.length > 0) {
                let parse_data = JSON.parse(res_global_list[0].global_list).filter(f => { return f.toggle });
                globalList.set(parse_data)
            }
            nobList.set(res_nob.sort((a, b) => a.name.localeCompare(b.name)))
        }
        await globalLoader.set(false)
    }

    const [subscribe, setSubscribe] = useState(true)

    const __Clickinit = async(e) => {
        if (validateAccount.data.data !== '') {
            if (__SESSION.data.uaccess !== null && __SESSION.data.uaccess.length > 0) {
                const company_id = __SESSION.data.uaccess[0].company_id;
                const user_id = atob(__SESSION.data.ainfo.aid).split('_', 2)[1];
                if (JSON.parse(validateAccount.data.data.uid).company_id === company_id && JSON.parse(validateAccount.data.data.uid).user_id === parseInt(user_id)) {
                    noAccessModal.set(true)
                }
            }
        }
    }

    useEffect(() => {
        if (subscribe) {
            if (globalLoader.data) {
                __init()
            }
            __Clickinit()
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validateAccount.data])

    const __UBR = async() => {
        if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0' && ubrdata !== undefined && (ubrdata.reglist.length > 0 || ubrdata.conlist.length > 0 || ubrdata.docs.length > 0 || ubrdata.pol.length > 0 ) ) {
            let comp_id = __SESSION.data.uaccess[0].company_id
            if (comp_id === null) {
            let bdy = { company_email: "", company_name: "", user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1] }
                let res_biz = await rqx.post(`<<tool>>/${env()}/general-maintenance/company/company-setup/createCI`, bdy)
                comp_id = res_biz.id
                compinfo.set([{...compinfo.data[0], company_id:comp_id}])
                __SESSION.set((prev) => ({...prev, uaccess:[{...__SESSION.data.uaccess[0], company_id:comp_id}]}))
            }
            
            let resubr = await UbrApi(ubrdata, comp_id, __SESSION)
            if (resubr === 'success' && !openCloud.data.welcome) {
                let res = personalizationData.data.filter(v => {return v.feature_name === 'cloud'} );
                if (res.length === 0) {
                    openCloud.set((prev) => ({...prev, welcome : true}))
                }
            }
        }
    }
   
    useEffect(() => {
        __UBR()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ubrdata])
    
    return (
        <></>
    )
}