import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Backdrop, Modal, Fade } from '@mui/material';
import { Buttons } from '../../core/global/forms/Button';
import Cafgu from '../../assets/images/core/cafgu_bg.png'

export const MProfiling = ({type}) => {
    const history = useHistory();
    const [open, setOpen] = useState(true);
    const handleClose = async() => {
        if (type === 'GM') {
            history.push('/general-maintenance/company-setup');
        }else{
            history.push('/profiling?account=setup');
        }
        setOpen(false)
    };
    
    return (  
        <Modal
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={open}>
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <Box position="absolute" width={311} height={440}>
                        <Box borderRadius={5} borderColor="#ffffff" bgcolor="white" className="shadow">
                            <img src={Cafgu} width="100%" height="100%" alt="cafgu" />
                            <Box pt="27px" pb="24px" textAlign="center" fontSize="16px" fontWeight={700}>
                                Welcome to POFBusiness
                            </Box>
                            <Box px="24px" pb="16px" textAlign="center" fontSize="12px" fontWeight={400}>
                                I’m CAFGU, your business coach. I will guide you on your journey to grow your extraordinary business.
                            </Box>
                            <Box display="flex" justifyContent="center" fontSize="12px">
                                <Box fontWeight={400}>To continue</Box> <Box px="4px" fontWeight={600}> set up your account </Box> <Box fontWeight={400}> details first.</Box>
                            </Box>
                            <Box display="flex" justifyContent="center" pt="32px" pb="24px" >
                                <Buttons event={handleClose} variant="contained" borderRadius="8px" color="#006129" width="226px" height="48px" fontSize="16px" text="Continue"/>
                            </Box>  
                        </Box>  
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}