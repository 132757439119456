import { Box, TextField, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { FormatFilesize } from "../../../../../global/DesktopDsg"
import CloseIcon from '@mui/icons-material/Close';
import Add from '../../../../../../../assets/images/icons/add.png'
import Pdf from '../../../../../../../assets/images/icons/pdf.png'

export const Details = ({dsg, subparams, cloud_state, onChangeFile}) => {

    const { cform, listConName, saveLoader } = cloud_state

    const __ChangeHandler = (e) => {
        const { name, value } = e.target
        cform.set({...cform.data, [name] : { val : value, stat: value === '' ? true : false, msg: value === '' ? 'This field is required.' : ''}})
    }
    
    const handlerOpenSelect = () => {
        if (subparams === 'create') {
            // modal.set({...modal.data, openConList : { val : true } })
        }
    }

    return (
        <>
            <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={1}>
                Contracts Name*
            </Box>
            <TextField fullWidth className={dsg.txtfld}  
                InputProps={{
                    readOnly:true
                }}
                value={ cform.data.conName.val !== '' ?  listConName.data.filter((f) => {return f.id === cform.data.conName.val})[0].name : ''} 
                onClick={() => handlerOpenSelect()}
            />
            <UploadFileDsg cform={cform} saveLoader={saveLoader} subparams={subparams} onChangeFile={onChangeFile}/>
           <Box pt={2}>
                {
                    cform.data.conName.val === 0 &&
                        <>
                            <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={1}>Other Contract Name<Box component="label" color="red">*</Box></Box>
                            <TextField fullWidth className={dsg.txtfld} 
                                placeholder='Ex.: Employee Contracts, etc.' 
                                // disabled={saveLoader.data}
                                name="conOther" 
                                value={cform.data.conOther.val}
                                error={cform.data.conOther.stat}
                                onChange={(e) => __ChangeHandler(e)} 
                            />
                            <Box fontSize={10} color="red" pl="5px" pt="5px">{cform.data.conOther.msg}</Box>
                        </>
                }
           </Box>
           <Box fontSize={14} fontWeight={600} lineHeight="16px" mt="20px" mb={1}> Client/Company Name<Box component="label" color="red">*</Box> </Box>
            <TextField fullWidth className={dsg.txtfld} 
                placeholder='Ex.: Juan Dela Cruz Jr.' 
                name="name" 
                value={cform.data.name.val}
                error={cform.data.name.stat}
                onChange={(e) => __ChangeHandler(e)} 
            />
            <Box fontSize={10} color="red" pl="5px" pt="5px">{cform.data.name.msg}</Box>
            <Box fontSize={14} fontWeight={400} lineHeight="16px" mt="20px" mb={1} color="#A2A3A9"> Validity Date </Box>
            <Box display="flex" alignItems="center" width="100%" gap="16px" mt="12px">
                <Box>
                    <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={1}> From </Box>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker label="" maxDate={new Date()}
                            value={cform.data.date_from.val} 
                            onChange={(e)=>__ChangeHandler({target: {name: 'date_from', value: e}})}
                            renderInput={(params) => 
                                <TextField {...params} fullWidth className={dsg.txtfld} error={cform.data.date_from.val === null} />
                            }
                        />
                    </LocalizationProvider>
                </Box>
                <Box>
                    <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={1}> To </Box>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker label="" minDate={new Date(cform.data.date_from.val)}
                            value={cform.data.date_to.val} 
                            onChange={(e)=>__ChangeHandler({target: {name: 'date_to', value: e}})}
                            renderInput={(params) => 
                                <TextField {...params} fullWidth className={dsg.txtfld} error={cform.data.date_to.val === null} />
                            }
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
        </>
    )
}

const UploadFileDsg = ({cform, saveLoader, subparams, onChangeFile}) => {
    
    const removeHandler = async(val) => {
        if (val === '1') {
            cform.set(
                {...cform.data, 
                    conFile:{...cform.data.conFile,
                        photo:{val:'', stat:false, msg:''}, 
                        file:{val:'', stat:false, msg:''},
                    }
                }
            )
        }
        if (val === '2') {
            cform.set(
                {...cform.data, 
                    conFile2:{...cform.data.conFile2,
                        photo:{val:'', stat:false, msg:''}, 
                        file:{val:'', stat:false, msg:''},
                    }
                }
            )
        }
    }

    return(
        <>
            {(cform.data.conFile.file.val !== '' && cform.data.conFile2.file.val !== '') || saveLoader.data || subparams === 'review' ? 
                <Box height={206} position="relative" className="img-border-dashed" borderColor="#E6E6E6" p={2} borderRadius="6px" mt={2} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                    <Box pt={2}><img src={Add} alt={Add} /></Box>
                    <Box pt="32px" fontSize={14} fontWeight={400} lineHeight="19px" color="#333333">Click here to upload</Box>
                    <Box textAlign="center" p="12px" fontSize={12} fontWeight={400} lineHeight="19px" color="#A2A3A9">The maximum file size is 25 MB, and there is a limit of two files.</Box>
                    <Box position="absolute" height="100%" width="100%" bgcolor="#FFF" sx={{opacity:0.8}}/>
                </Box>
            :
                <Box height={206} width="100%" className="img-border-dashed" borderColor="#E6E6E6" p={2} borderRadius="6px" mt={2}>
                    <input type="file" id="file" hidden onChange={(e) => onChangeFile(e)} />
                    <label htmlFor="file">
                        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                            <Box pt={2}><img src={Add} alt={Add} /></Box>
                            <Box pt="32px" fontSize={14} fontWeight={400} lineHeight="19px" color="#333333">Click here to upload</Box>
                            <Box textAlign="center" p="12px" fontSize={12} fontWeight={400} lineHeight="19px" color="#A2A3A9">The maximum file size is 25 MB, and there is a limit of two files.</Box>
                        </Box>
                    </label>
                </Box>
            }           
            <Box pt="32px" display="flex" flexDirection="column" gap="12px">
                {cform.data.conFile.file.val !== '' && (
                    <Box width="100%" minHeight="60px" border="1px solid #E6E6E6" borderRadius="6px" display="flex" alignItems="center" justifyContent="space-between" py="14px" px="12px">
                        <Box width="90%" display="flex" alignItems="center" gap="12px">
                            {cform.data.conFile.file.val[0].type.split('/')[1] === 'pdf' ? 
                                <img src={Pdf} alt={Pdf} width={32} height={32} />
                            :
                                <Box component="img" src={cform.data.conFile.photo.val} alt={cform.data.conFile.photo.val} height={32} width={32} borderRadius="12px"/>
                            }
                            <Box width="80%" display="flex" flexDirection="column" ml="13px"> 
                                <Typography color="#333333" fontSize={12} fontWeight={600} lineHeight="16px" noWrap>{cform.data.conFile.file.val[0].name} </Typography>
                                <Box color="#737373" fontWeight={400} fontSize={10} lineHeight="16px"> {FormatFilesize(cform.data.conFile.file.val[0].size)} </Box>
                            </Box>
                        </Box>
                        {(!saveLoader.data && subparams !== 'review') && (
                            <Box minWidth="20px" onClick={()=>removeHandler('1')} className="c-pointer"><CloseIcon sx={{height:'14px', width:'14px', color:'#737373'}}/></Box>
                        )}
                    </Box>
                )}
                {cform.data.conFile2.file.val !== '' && (
                    <Box minHeight="60px" border="1px solid #E6E6E6" borderRadius="6px" display="flex" alignItems="center" justifyContent="space-between" py="14px" px="12px">
                        <Box width="90%" display="flex" alignItems="center" gap="12px">
                            {cform.data.conFile2.file.val[0].type.split('/')[1] === 'pdf' ? 
                                <img src={Pdf} alt={Pdf} width={32} height={32} />
                            :
                                <Box component="img" src={cform.data.conFile2.photo.val} alt={cform.data.conFile2.photo.val} height={32} width={32} borderRadius="12px"/>
                            }
                            <Box width="80%" display="flex" flexDirection="column" ml="13px"> 
                                <Typography color="#333333" fontSize={12} fontWeight={600} lineHeight="16px" noWrap>{cform.data.conFile2.file.val[0].name}</Typography>
                                <Box color="#737373" fontWeight={400} fontSize={10} lineHeight="16px"> {FormatFilesize(cform.data.conFile2.file.val[0].size)} </Box>
                            </Box>
                        </Box>
                        {(!saveLoader.data && subparams !== 'review') && (
                            <Box minWidth="20px" onClick={()=>removeHandler('2')} className="c-pointer"><CloseIcon sx={{height:'14px', width:'14px', color:'#737373'}}/></Box>
                        )}
                    </Box>
                )}
            </Box>
        </>
    )
}