import { Box } from '@mui/material';
import { NoFound } from '../../../../global/DesktopDsg';
import { ThreeDots } from '../modal/ThreeDots';
import { FileInfo } from '../FileInfo';
import { ListView } from './List';
import { GridView } from './Grid';
import { Waypoint } from 'react-waypoint';
import { SnakeLoader } from '../../../../../../core/loader/SnakeLoader';
import { useHistory } from 'react-router-dom';

export const List = ({favList, listView, active, show, __SESSION, message, successOpen, showDl, __InitFavList, __LoadmoreData, inftScroll}) => {
    const uai_user = process.env.REACT_APP_UAI_REGULAR
    const nav = useHistory()

    const handlerThreeDots = (val) => {
        active.set({...active.data, openMenu : true})
        show.set({...show.data, data : val})
    }

    const handlerView = (val) => {
        
        if (val.folder_id === undefined) {
            show.set({...show.data, data : val, view : true, fullview : false})
            active.set({...active.data, val : '',  openMenu : null })
        }else{
            let uid = parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1])
            let feature = val.access === null && uid === val.created_by ? 'documents' : 'shared-access'
            let access = [{access:JSON.stringify(val.access)}]
            nav.push(`/my-cloud/${feature}/folder?subfname=${val.folder_name}&subfid=${window.btoa(val.folder_id)}&f_id=${window.btoa(val.folder_id)}${feature === 'shared-access' && __SESSION.data.uaccess[0].role_id === parseInt(uai_user) ? `&access=${window.btoa(JSON.stringify(access))}` : '' } `)
        }
    }

    return (
        <>
            {
                (favList.data.length === 0 ? 
                    <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
                        <NoFound msg="No favorite files" submsg="Activate star for files you wish to find later with ease." />
                    </Box>
                :
                    <> 
                        {
                            listView.data ? 
                                <ListView favList={favList} handlerView={handlerView} handlerThreeDots={handlerThreeDots} />
                            :
                                <GridView favList={favList} handlerView={handlerView} handlerThreeDots={handlerThreeDots} />
                        }
                        {
                            inftScroll.data && (
                                <Box display="flex" justifyContent="center">
                                    <Waypoint onEnter={__LoadmoreData} >
                                        <Box component="strong" mt={5} display="flex" justifyContent="center">
                                            <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                                        </Box>
                                    </Waypoint>
                                </Box>
                            )
                        }
                    </>
                    
                )
            }
            {active.data.openMenu && 
                <ThreeDots active={active} show={show} handlerView={handlerView} favList={favList} __SESSION={__SESSION} message={message} successOpen={successOpen} showDl={showDl} __InitFavList={__InitFavList} />
            }
            {
                show.data.view &&
                    <FileInfo show={show} handlerThreeDots={handlerThreeDots} />  // Viewing file
            }
        </>
    )
}
