import { useState } from "react"

export const ProfileState = () => {
    //FOR USER PHOTO STATE
    const [isLoader, setIsLoader] = useState({type:'', status:false})
    const [isProgress, setIsProgress] = useState(0)
    
    return {
        isLoader: {data: isLoader, set: setIsLoader},
        isProgress: {data: isProgress, set: setIsProgress},
    }
}