import { Box } from "@mui/material"
import { Contracts, FileInfo, NoFound, ThreeDotsMenu, ConfirmRemove } from "../../global/DesktopDsg"
import { HandlerThreeDots, HandlerView } from "../../global/Function"
import { rqx } from "../../../../core/request/API"
import { env } from "../../../../core/Env"
import { Download, DownloadPDF } from "../../../../core/transmit/S3"
import { Loader } from "../../Loader"
import { NotifCount } from "../../../../core/request/Notif"

export const List = ({conList, isLoading, conActive, starActive, show, listView, inftScroll, __LoadmoreData, nav, match, __SESSION, csuccessOpen, message, showDl, __InitConList, limit, lid, openShare, withAccess, shareType, notif_state }) => {

    const handleUpdate = async(e) => {
        nav.push(`/my-cloud/contracts/${window.btoa(show.data.data.id)}`)
        HandlerThreeDots(e, '', '', 'close', conActive, show)
    }

    const HandlerDelete = async(type) => {
        if (!show.data.remove.loader) {
            if (type === 'alert') {
                show.set({...show.data, remove:{...show.data.remove, val:true}})
            }else if(type === 'cancel'){
                show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}})
            }else{
                show.set({...show.data, remove:{...show.data.remove, loader:true}})
                let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/contracts/update`, {
                    id:show.data.data.id, 
                    user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                    status:4, 
                    ref:'trash'
                })
                if (res.message === 'success') {
                    await NotifCount([{file_id:show.data.data.id, file:'con'}], __SESSION, 'delete')
                    message.set('Successfully removed')
                    csuccessOpen.set(true)
                    conActive.set({...conActive.data, val : '', openMenu : null})
                    __InitConList(0, isLoading, inftScroll, __SESSION, limit, lid, conList)
                    setTimeout(() => {
                        show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}, view:false})
                    }, 1000);
                }
            }
        }
    }

    const HandlerDL = async(e, type) => {
        let parse = JSON.parse(show.data.data.file)
        
        for (let i = 0; i < parse.length; i++) {
            let res;
            if (type === 'pdf') {
                if (parse[i].file_type.includes('image')) {
                    res = await DownloadPDF(parse[i].file_link, parse[i].file_name)       
                }else{
                    res = await Download(e, parse[i].file_link, parse[i].file_name)
                }
            }else{
                res = await Download(e, parse[i].file_link, parse[i].file_name)
            }
            if (res === 'success' && i === parse.length - 1 ) {
                showDl.set(null)
                conActive.set({...conActive.data, val : '', openMenu : null})
                message.set('Successfully download')
                csuccessOpen.set(true)
            }
        }
    }

    const handlerShare = (val) => {
        // if (show.data.data.access === null || show.data.data.access === undefined || ( show.data.data.access !== null && show.data.data.access[0].update)) {
            openShare.set(true)
            conActive.set({...conActive.data, val : '', openMenu :  null})
            show.set({...show.data, data : val, view : false, fullview : false})
            // shareType.set('create_access')
            shareType.set('details')
        // }
    }
    
    return (
        <Box height="100%" width="100%" p="40px" className="overflowY">
            {
                (isLoading.data ?
                    <Loader />
                :
                    <>
                        {
                            conList.data.length === 0 ?
                                <NoFound msg="No File found. Upload Now!" />
                            :
                                <Contracts data={conList.data} conActive={conActive} starActive={starActive} show={show} listView={listView} inftScroll={inftScroll} __LoadmoreData={__LoadmoreData} match={match} __SESSION={__SESSION} state={conList} successOpen={csuccessOpen} message={message} withAccess={withAccess} />
                        }
                    </>
                )
            }
            {Boolean(conActive.data.openMenu) && ( 
                <ThreeDotsMenu 
                    open={Boolean(conActive.data.openMenu)} 
                    anchorEl={conActive.data.openMenu} 
                    onClose={(e) => HandlerThreeDots(e, '', '', 'close', conActive, show)}
                    viewInfo={() => HandlerView(show.data.data, show, conActive, withAccess)}
                    share={() => handlerShare(show.data.data)}
                    update={(e)=>handleUpdate(e)}
                    remove={() => HandlerDelete('alert')}
                    download={HandlerDL}
                    show={show}
                />
            )}
            {
                show.data.view &&
                    <FileInfo show={show} match={match} __SESSION={__SESSION} state={conList} successOpen={csuccessOpen} message={message} stateActive={conActive} showDl={showDl} withAccess={withAccess} handlerShare={handlerShare} notif_state={notif_state}/>
            }
            <ConfirmRemove show={show} HandlerDelete={HandlerDelete}/>
        </Box>
    )
}