import { useState } from "react";
import { Box, CircularProgress, Divider, Menu, MenuItem } from "@mui/material";
import { DlIcon, GreaterThan, InfoIcon, InfoIconActive, TrashIcon, UpdateIcon } from "../../../../../core/global/Icons";
import { DL } from "./DL";
import { Download, DownloadZip } from "../../../../../core/transmit/S3";
import { rqx } from "../../../../../core/request/API";
import { env } from "../../../../../core/Env";

export const ThreeDots = ({show, active, showDl, successOpen, message, remove, viewInfo, rename, fileLoaderAccess}) => {
    const [iconActive, setIconActive] = useState('')
    const handlerShowDL = async(e, type) => {
        if (type === 'open') {
            if (show.data.data.type !== 3 && JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_type.includes('image')) {
                showDl.set(e.currentTarget)
            }else{
                if (show.data.data.type === 3) {
                    let data = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/documents/downloadFolder`, {cf_id:show.data.data.id})
                    let res = await DownloadZip(data)
                    if (res === 'success') {
                        active.set({...active.data, val : '', openMenu : null, type: ''})
                        message.set('Successfully download')
                        successOpen.set(true)
                    }
                }else{
                    let parse = JSON.parse(show.data.data.file)

                    for (let i = 0; i < parse.length; i++) {
                        let res = await Download(e, parse[i].file_link, parse[i].file_name)
                        if (res === 'success' && i === parse.length - 1 ) {
                            active.set({...active.data, val : '', openMenu : null})
                            message.set('Successfully download')
                            successOpen.set(true)
                        }
                    }
                }
            }
        }
    }

    let dwnldValidAccess = false
    let updtValidAccess = false
    if (show.data.data.access === null || show.data.data.access === undefined || (show.data.data.access !== null && show.data.data.access[0].update)) {
        updtValidAccess = true
    }

    if (show.data.data.access === null || show.data.data.access === undefined || (show.data.data.access !== null && show.data.data.access[0].download)) {
        dwnldValidAccess = true
    }

    const handlerCloseMenu = () => {
        active.set({...active.data, val : '', openMenu : null, type: ''})
    }
    
    const handlerRename = (id, type) => {
        rename.set({...rename.data, id: id, val : true, type: type})
        active.set({...active.data, val : '', openMenu :  null})
    }

    return (
        <>
            <Menu open={Boolean(active.data.openMenu)} anchorEl={active.data.openMenu} elevation={1} onClose={() => handlerCloseMenu()}>
                {fileLoaderAccess ? 
                    <Box px={1} >
                        <MenuItem sx={{minHeight: '44px !important', py: 0 }}>
                            <CircularProgress color="primary" />
                        </MenuItem>
                    </Box>
                :
                    <Box px={1} >
                        {
                            active.data.type === 'folder' &&
                                <MenuItem sx={{minHeight: '44px !important', py: 0, opacity:updtValidAccess ? 1 :0.3 }} 
                                    onClick={updtValidAccess ? () => handlerRename(show.data.data.id, active.data.type) : null}
                                    onMouseEnter={() => setIconActive('update')} 
                                    onMouseLeave={() => setIconActive('')}
                                >
                                    <UpdateIcon fill={iconActive === 'update' ? "#107038" : "#A2A3A9"} />
                                    <Box ml="12px" fontSize={14} fontWeight={400}> Rename</Box>
                                </MenuItem>
                        }
                        {!show.data.view && active.data.type === 'file' && (
                            <MenuItem sx={{minHeight: '44px !important', py: 0}} 
                                onClick={viewInfo}
                                onMouseEnter={() => setIconActive('view')} 
                                onMouseLeave={() => setIconActive('')}
                            >
                                {iconActive === 'view' ? <InfoIconActive /> : <InfoIcon />}
                                <Box ml="12px" fontSize={14} fontWeight={400}> View Info</Box>
                            </MenuItem>
                        )}
                        <MenuItem sx={{minHeight: '44px !important', py: 0, opacity:dwnldValidAccess ? 1 :0.3, bgcolor: showDl.data !== null ? '#F2F2F2' : '#ffffff'}} 
                            onClick={ dwnldValidAccess ? (e) => handlerShowDL(e, 'open') : null}
                            onMouseEnter={() => setIconActive('download')} 
                            onMouseLeave={() => setIconActive('')}
                        >
                            <DlIcon fill={iconActive === 'download' || showDl.data !== null ? "#107038" : "#737373"} />
                            <Box ml="12px" mr="40px" fontSize={14} fontWeight={400}> 
                            {
                                (show.data.data.type !== 3 && JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_type.includes('image')) ?
                                    'Download as....'
                                :
                                    'Download'
                            }
                            
                            </Box>
                            {
                                (show.data.data.type !== 3 && JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_type.includes('image')) && (
                                    <GreaterThan stroke={iconActive === 'download' || showDl.data !== null  ? "#107038" : "#737373"} />
                                )
                            }
                        </MenuItem>               
                        <Divider sx={{my: '0px !important'}} />
                        <MenuItem sx={{minHeight: '44px !important', py: 0, opacity:(show.data.data.access === null || show.data.data.access === undefined) ? 1 : 0.3}} 
                            onClick={(show.data.data.access === null || show.data.data.access === undefined) ? remove : null}
                            onMouseEnter={() => setIconActive('remove')} 
                            onMouseLeave={() => setIconActive('')}
                        >
                            <TrashIcon fill={iconActive === 'remove' ? "#107038" : "#737373"} />
                            <Box ml="12px" fontSize={14} fontWeight={400}> Delete</Box>
                        </MenuItem>
                    </Box>
                }
            </Menu>
            <DL showDl={showDl} show={show} active={active} successOpen={successOpen} message={message} />
        </>
    )
}
