import { Avatar, Box, Divider, IconButton, MenuItem, Modal, Select, Slide, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { ToolContext } from "../../../../../core/context/ToolContext";
import { ArrowLeft } from "../../../../../core/global/Icons"
import { SystemConcernsLoader } from "../../../loader/MidLoader";

let bizID = process.env.REACT_APP_TOOL_ID;
let bizName = process.env.REACT_APP_TOOL_NAME;
let bizPhoto = process.env.REACT_APP_TOOL_PHOTO;
export const SystemConcerns = ({hs}) => {
    const { tool_state } = useContext(ToolContext);
    const toolList = tool_state.toolOwned.data
    const [selected, setSelected] = useState(0)
    const [tids] = useState([]) 
    const [datas, setDatas] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [subscribe, setSubscribe] = useState(true)
    const status = [ {name: 'Done', color: '#07A42A'}, {name: 'Ongoing', color: '#2B78E4'}, {name: 'Pending', color: '#F84949'}, ]

    const changeHandler = async(value) => {
        setSelected(value)
    }

    const __init = async(ids) => {
        const tlist = [{ tid: JSON.parse(bizID), name: bizName, logo: bizPhoto }, ...toolList]
        if (ids.length === 0 ) {
            tlist.filter((v) => {return tids.push(v.tid)})
        }
        const toolsort = tlist.filter(g => ids.find(n => (n === g.tid) ))
        setDatas(toolsort)
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
    }
   
    useEffect(() => {
        if (subscribe) {
            __init(tids)
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [])
    
    return (
        <Modal open={hs.showSysCons.data}>
            <Slide in={hs.showSysCons.data} direction="up">
                <Box height="100%" width="100%" bgcolor="#F0F6F3" display="flex" flexDirection="column">
                    <Box minHeight={56} p={2} bgcolor="#FFFFFF" display="flex" alignItems="center">
                        <IconButton sx={{p: '0px !important', position:'absolute'}} onClick={() => hs.showSysCons.set(false)}>
                            <ArrowLeft />
                        </IconButton>
                        <Typography fontSize={14} fontWeight={600} textAlign="center" width="100%">System Concerns</Typography>
                    </Box>
                    <Box mt="12px" bgcolor="#FFFFFF" p="16px 12px" display="flex" flexDirection="column">
                        {isLoading ?
                            <SystemConcernsLoader/>
                        :
                            <>
                                <Box fontSize={14} fontWeight={600} mb={3}>System Concerns</Box>
                                <Select sx={{width: '196px', mb: 2}} size="small" value={selected} onChange={(e) => changeHandler(e.target.value)}>
                                    <MenuItem value={0}>All Categories</MenuItem>
                                </Select>
                                <Box bgcolor="#F0F6F3" borderRadius="4px" border="1px solid #ECECEC" display="flex" alginItems="center" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.06)" mb="12px"> 
                                    <Box minWidth={120}>
                                        <Typography fontSize={14} fontWeight={500} p="4px 12px" borderRight="1px solid #ECECEC" >Total Name</Typography>
                                    </Box>
                                    <Box width="100%">
                                        <Typography fontSize={14} fontWeight={500} p="4px 12px" borderRight="1px solid #ECECEC" >Total Concerns</Typography>
                                    </Box>
                                </Box>
                                {datas.map((v, k) => (
                                    <Box display="flex"  bgcolor="#FFFFFF" border="1px solid #ECECEC" borderRadius="4px" boxShadow="0px 0px 4px rgba(51, 51, 51, 0.06)" mb="4px" key={k}>
                                        <Box minWidth={120} display="flex" alignItems="center" p="24px 8px" borderRight="1px solid #ECECEC">
                                            <Avatar src={v.tid === JSON.parse(bizID) ? v.logo : JSON.parse(v.logo) } variant="rounded" sx={{height: 20, width: 20}} />
                                            <Typography fontSize={12} fontWeight={400} ml="4px" width={78} noWrap>{v.name} </Typography>
                                        </Box>
                                        <Box height="100%"  py="4px" display="flex" alignItems="center">
                                            <Typography fontSize={12} fontWeight={400} width={120} textAlign="center">0</Typography>
                                            <Divider orientation="vertical" />
                                        </Box>
                                        <Box height="100%" width="100%" py="4px" display="flex" flexDirection="column" justifyContent="space-around">
                                            <Box bgcolor="#07A42A" height="12px" width="4px" borderRadius="6px" />
                                            <Box bgcolor="#2B78E4" height="12px" width="4px" borderRadius="6px" />
                                            <Box bgcolor="#F84949" height="12px" width="4px" borderRadius="6px" />
                                        </Box>
                                    </Box>
                                ))}
                                <Box display="flex" alignItems="center" justifyContent="center" mt="12px">
                                    {status.map((v, k) => (
                                        <Box display="flex" alignItems="center" key={k} mr={k !== 2 && 3}>
                                            <Box height="8px" width="8px" bgcolor={v.color} borderRadius="6px" />
                                            <Box fontSize={10} fontWeight={500} color={v.color} ml="4px">{v.name}</Box>
                                        </Box>
                                    ))}
                                </Box>
                            </>
                        }
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}