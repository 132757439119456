import React from 'react';
import { Box, Skeleton } from '@mui/material';

export const SecondaryLoader = () => {
    return (
        <>
            {/* <Box className="tenth-step" bgcolor="rgba(0, 0, 0, 0.10)" borderRadius={3} minHeight="88px" height="88px" mt={3}>
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" >
                    <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="space-around" p={2}>
                        <Skeleton variant="text" height="30px" width="100px" />
                        <Box display="flex" alignItems="center">
                            <Skeleton variant="rectangular" width='32px' height="32px" sx={{borderRadius:'12px'}}/>
                            <Box pl={1}>
                                <Skeleton variant="rectangular" width='100px' height="22px" sx={{borderRadius:'4px'}}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="space-around" p={2}>
                        <Skeleton variant="text" height="30px" width="100px" />
                        <Box display="flex" alignItems="center">
                            <Skeleton variant="rectangular" width='32px' height="32px" sx={{borderRadius:'12px'}}/>
                            <Box pl={1}>
                                <Skeleton variant="rectangular" width='100px' height="22px" sx={{borderRadius:'4px'}}/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box minHeight="88px" height="88px" borderRadius={3}>
                    <Box mx={2}>
                        <Skeleton variant="rectangular" width='100%' height="15px" sx={{borderRadius:"0px 0px 10px 10px"}}/>
                    </Box>
                </Box>
            </Box> */}
            <Box height="100%" width="100%" display="flex" flexDirection="column">
                <Box py={3} pb={2}>
                    <Skeleton variant="rectangular" width='190px' height="20px" sx={{borderRadius:'4px'}}/>
                </Box>
                <Box minHeight="390px">
                    <Box borderRadius={3} sx={{border:"1px solid #EBEBEB"}} bgcolor="#ffffff" p={2} height="370px">
                        <Box display="flex" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                                <Skeleton variant="rectangular" width='32px' height="32px" sx={{borderRadius:'12px'}}/>
                                <Box ml="12px">
                                    <Skeleton variant="text" width='100px' height="25px" sx={{borderRadius:'4px'}}/>
                                    <Skeleton variant="text" width='135px' height="20px" sx={{borderRadius:'4px'}}/>
                                </Box>
                            </Box>
                            <Box display='flex' alignItems='center'>
                                <Skeleton variant="rectangular" width='32px' height="32px" sx={{borderRadius:'12px'}}/>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection='column' height='100%'>
                            <Box my={1}>
                                <Skeleton variant="text" width='135px' height="30px" sx={{borderRadius:'4px'}}/>
                            </Box>
                                <Skeleton variant="rectangular" width='100%' height="225px" sx={{borderRadius:'4px'}}/>
                        </Box>
                    </Box>
                </Box>
                <Box mb={2}>
                    <Skeleton variant="rectangular" width='100%' height="50px" sx={{borderRadius:'12px'}}/>
                </Box>
                <Box height="100%" >
                    <Box height="100%" overflow="auto">
                        <Box bgcolor="rgba(0, 0, 0, 0.10)" borderRadius={3} minHeight="50px" height="50px" mb={1} py={1.3} px={1.8}>
                            <Box display="flex" alignItems="center">
                                    <Skeleton variant="rectangular" width='32px' height="32px" sx={{borderRadius:'12px'}}/>
                                <Box pl={1}>
                                    <Skeleton variant="rectangular" width='100px' height="22px" sx={{borderRadius:'4px'}}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}