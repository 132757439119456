import { Box, TextField, Button, Autocomplete } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { rqx } from "../../../core/request/API"
import { env } from "../../../core/Env"
import { S3Upload } from "../../../core/transmit/S3"
import { db } from "../../../db/db"

export const Right = ({dsg, match, form, setForm, __SESSION, allUsers, successOpen, message, actType, saveLoader, errorOpen, pName, plcyData }) => {

    const handleOnChange = (e, val, key) => {
        if (key === undefined){
            const {name, value} = e.target
            if (name === 'policyname') {
                pName.set(false)
            }
            setForm({...form, [name]: value})

        } else {
            let name = key;
            let value = (val === null ? '' : (val.id > 0 ? val.id:val));
            setForm({...form, [name]: value})
        }
    }

    // const currentDate = new Date();

    const nav = useHistory()

    let stat 
    if (form.departname === '' || form.file_name === '' || form.policyname === '' || form.description === '' || form.dateissued === '' || form.preparedby === '' || form.approvedby === '' || pName.data ) {
        stat = true
    } else {
        stat = false
    }

    const handlerBtn = async(type) => {
        if (type === 'next') {
            if (!stat) {
                nav.push('/policies/review')
            }
        }else if (type === 'edit') {
            nav.push('/policies/create')
        }
        else {
            saveLoader.set(true)
            let res_s3 = await S3Upload(form.file)

            if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0') {
                let data = {
                    dept_name: 'All',
                    description: form.description,
                    policy_name: form.policyname,
                    policyname: form.policyname,
                    policy_num: "PLCY-000000001",
                    stat:false,
                    status : 1,
                    file_link:res_s3.location,
                    file_name:"POFSIS Admin Access v1.pdf",
                    file_size:2605928,
                    file_type:"application/pdf",
                    dept_id:form.departmentname.id,
                    dateupload:form.dateupload,
                    dateissued:form.dateissued
                }

                db.pol.add(data);
                plcyData.set([data])
                successOpen.set(true)
                message.set('Successfully Saved.')
                nav.push('/policies/list')
                saveLoader.set(false)
            }else{
                let body = {...form,
                    file_link:res_s3.location,
                    company_id:__SESSION.data.uaccess[0].company_id,
                    user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                    dept_id:form.departmentname.id
                }
                let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/policy/create`, body)
                if (res.message === 'success') {
                    successOpen.set(true)
                    message.set('Successfully Saved.')
                    nav.push('/policies/list')
                    saveLoader.set(false)
                }else {
                    nav.push('/policies/create')
                    errorOpen.set(true)
                    pName.set(true)
                    message.set('Policy Name already exist.')
                    saveLoader.set(false)
                }
            }
        }
    }

    return (
        <Box width="100%" height="100%" mb="8px" display="flex" flexDirection="column" justifyContent="space-between"> 
            <Box width="100%" height="100%" display="flex" justifyContent="center" flexDirection="column"> 
                <Box color="#107038" fontSize="14px" fontWeight="600" lineHeight="16px" mb="16px">Policy Details</Box>
                <Box className='ubr-Info'>
                    <Box width="100%" mt="10px" mb="8px">
                        <Box fontSize="14px" fontWeight={600} lineHeight="16px" mb={1}>Policy Name*</Box>
                        <TextField fullWidth name="policyname" placeholder='Ex: Employment Policy' disabled={match.params.params === 'review' ? true : false} error={form.policyname === '' || pName.data ? true : false} value={form.policyname} onChange={(e)=>handleOnChange(e)}
                            className={dsg.txtfld}
                        />
                        {/* <TextField className={dsg.txtfld} variant="outlined" sx={{ height: '44px', borderRadius: '20px', bgcolor: '#FFFFFF', fontSize: '14px'}}></TextField> */}
                    </Box>
                    <Box width="100%" mt="16px" lineHeight="16px" mb="8px">
                        <Box fontSize="14px" fontWeight={600} lineHeight="16px" mb={1}>Policy Description​*</Box>
                        <TextField fullWidth multiline rows={3} name="description" sx={{bgcolor: '#FFFFFF'}} disabled={match.params.params === 'review' ? true : false} error={form.description === '' ? true : false} value={form.description} onChange={(e)=>handleOnChange(e)}
                            // className={dsg.txtfld}
                        />
                        <Box color="#A2A3A9" fontSize="12px" display="flex" justifyContent="flex-end">0/1000</Box>
                    </Box>
                    <Box width="100%" mt="16px" lineHeight="16px" mb="8px">
                        <Box fontSize="14px" fontWeight={600} lineHeight="16px" mb={1} display="flex" alignItems="center" justifyContent="space-between">
                            <Box width="100%">
                                <Box fontSize="14px" fontWeight={600} lineHeight="16px" mb={1}>Date of Upload​*</Box>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker label="" disabled
                                        value={form.dateupload} 
                                        onChange={(e)=>handleOnChange(e)}
                                        renderInput={(params) => 
                                            <TextField {...params} fullWidth
                                                className={dsg.txtfld}
                                            />
                                        }
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box width="100%" pl="20px">
                                <Box fontSize="14px" fontWeight={600} lineHeight="16px" mb={1}>Issue Date*</Box>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker disabled={match.params.params === 'review' ? true : false}
                                        // minDate={currentDate}
                                        error={form.dateissued === '' ? true : false} 
                                        value={form.dateissued} 
                                        onChange={(e)=>handleOnChange({target: {name: 'dateissued', value: e}})}
                                        renderInput={(params) =>
                                            <TextField {...params} fullWidth
                                                className={dsg.txtfld}
                                            />
                                        }
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>
                    </Box>
                    <Box width="100%" mt="16px" lineHeight="16px" mb="8px">
                        <Box fontSize="14px" fontWeight={600} lineHeight="16px" display="flex" alignItems="center" >
                            <Box width="100%">
                                <Box fontSize="14px" fontWeight={600} lineHeight="16px" mb={1}>Prepared By*</Box>
                                <Autocomplete
                                    disabled={match.params.params === 'review' ? true : false}
                                    noOptionsText="No Result"
                                    autoHighlight={true}
                                    options={allUsers}
                                    getOptionLabel={(data) => (data.uname)}
                                    value={form.preparedby === '' ? null : allUsers.filter((f) => f.id === form.preparedby? {...f} : null)[0]} 
                                    onChange={(e, value) => handleOnChange(e, value, 'preparedby')}
                                    renderInput={(params) => (
                                        <TextField fullWidth className={dsg.plcyTxt} {...params} />
                                    )}
                                />
                            </Box>
                            <Box width="100%" pl="20px">
                                <Box fontSize="14px" fontWeight={600} lineHeight="16px" mb={1}>Approved By*</Box>
                                <Autocomplete
                                    disabled={match.params.params === 'review' ? true : false}
                                    noOptionsText="No Result"
                                    autoHighlight={true}
                                    options={allUsers}
                                    getOptionLabel={(data) => (data.uname)}
                                    value={form.approvedby === '' ? null : allUsers.filter((f) => f.id === form.approvedby? {...f} : null)[0]} 
                                    onChange={(e, value) => handleOnChange(e, value, 'approvedby')}
                                    renderInput={(params) => (
                                        <TextField fullWidth className={dsg.plcyTxt} {...params} />
                                    )}
                                />
                                {/* <Autocomplete 
                                        disabled={ subdivision_state.city.data.length > 0 ? false : true}
                                        noOptionsText="No Result"
                                        autoHighlight={true}
                                        options={subdivision_state.city.data}
                                        getOptionLabel={(data) => (data.name)}
                                        value={accForm.city_id === '' ? null : subdivision_state.city.data.filter((f) => accForm.city_id === f.id ? {...f} : null)[0]}
                                        onChange={(e,value) => handleChange(e,value, 'city_id')}
                                        renderInput={(params) => <CstmTextField sx={{bgcolor:'#FFFFFF', borderRadius:'8px'}} {...params} error={ error && accForm.city_id === '' ? true : false} />} 
                                    />  */}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end">
                { 
                    match.params.params === 'review' ?
                        <>
                            <Button variant="contained" disableElevation className={dsg.btnEdit} disabled={saveLoader.data} onClick={saveLoader.data ? null : () => handlerBtn('edit')}>
                                Edit
                            </Button>
                            <Button variant="contained" disableElevation className={dsg.btnNext} onClick={() => handlerBtn('save')}>
                                {actType.data === 'update' ?
                                    saveLoader.data ? 'Updating...' : 'Update'
                                :
                                    saveLoader.data ? 'Saving...' : 'Save'
                                }
                            </Button>
                        </>
                        :
                        <Button variant="contained" disableElevation className={dsg.btnNext} onClick={() => handlerBtn('next')} 
                        sx={{backgroundColor: stat && '#C7DAD7' , '&:hover': { backgroundColor: stat && '#C7DAD7' }  }}
                        // sx={{backgroundColor: form.data.name.stat || form.data.number.stat ? '#C7DAD7' : ValidateBtnNxtReg(form).bgcolor, '&:hover': { backgroundColor: form.data.name.stat || form.data.number.stat ? '#C7DAD7' : ValidateBtnNxtReg(form).bgcolor }  }}
                        >
                            Next
                        </Button>
                
                }
                
            </Box>
        </Box>
    )
}