import { Box, MenuItem, Modal, Slide, Typography } from "@mui/material"
import { DlIcon, InfoIcon, ShareIcon, StarIcon, TrashIcon, UpdateIcon } from "../../../../../../core/global/Icons"
import { rqx } from "../../../../../../core/request/API"
import { env } from "../../../../../../core/Env"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

export const ThreeDots = ({cloud_state, tool_state, open, close, show}) => {
    const nav = useHistory()
    const [showslide, setShowSlide] = useState(true)
    const { rcntList, regActive, conActive, csuccessOpen, successOpen, message, reloadStorage, openShare, shareType } = cloud_state
    const { __SESSION } = tool_state

    const dfltbtnList = [
        {name: 'View Details', icon: <InfoIcon />, type: 'view'},
        {name: 'Share Access', icon: <ShareIcon fill="#737373" />, type: 'share'},
        {name: show.data.data.user_favorite_status !== 2 ? 'Add to Favorites' : 'Remove to Favorites', icon: <StarIcon fill={show.data.data.user_favorite_status === 2 ? "#107038" : "none"} stroke={show.data.data.user_favorite_status === 2 ? "#107038" : "#737373"} />, type: 'addfav'},
        {name: 'Update', icon: <UpdateIcon />, type: 'update'},
        {name: 'Download as...', icon: <DlIcon />, type: 'dl'},
        {name: 'Remove', icon: <TrashIcon fill="#737373" />, type: 'remove'},
    ]

    const [btnList, setBtnList] = useState([])

    const handlerCloseMenu = (type) => {
        setShowSlide(false)
        setTimeout(() => {
            if (type === 'reg') {
                regActive.set({...regActive.data, openMenu: null})
            }else{
                conActive.set({...conActive.data, openMenu: null})
            }
        }, 200);
    }
    
    const handlerMenuBtn = async(e, type) => {
        if (type === 'view') {
            show.set({...show.data, data : show.data.data, view : true, fullview : false})
        }else if (type === 'addfav') {
            reloadStorage.set(true)
            let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/favorites/create`, {
                registration_id: show.data.data.feature_type === 1 ? show.data.data.id : '',
                contracts_id: show.data.data.feature_type === 2 ? show.data.data.id : '',
                status: show.data.data.user_favorite_status === 2 ? 1 : 2, 
                user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                documents_id:"",
                folder_id: ""
            })
            if (res.message === 'success') {
                rcntList.set({...rcntList.data,
                    [show.data.data.feature_type === 2 ? 'contracts' : 'reg'] : (show.data.data.feature_type === 2 ? rcntList.data.contracts : rcntList.data.reg).map((d,k) => d.id === show.data.data.id ? {...d, user_favorite_status:show.data.data.user_favorite_status === 2 ? 1 : 2} : {...d} )
                })
                show.set({...show.data, data:{...show.data.data, user_favorite_status: (show.data.data.user_favorite_status === 1 ? 2 : 1) }})
                message.set( show.data.data.user_favorite_status === 1 ? 'Successfully added to favorites.' : 'Sucessfully removed from favorites')
                show.data.data.feature_type === 1 ? await successOpen.set(true) : await csuccessOpen.set(true)
            }
        }else if(type === 'update'){
            nav.push(`/my-cloud/${show.data.data.feature_type === 1 ? 'registrations' : 'contracts'}/${window.btoa(show.data.data.id)}`)
        }else if(type === 'remove'){
            show.set({...show.data, remove:{...show.data.remove, val:true}})
        }else if(type === 'dl'){
            show.set({...show.data, download:true})
        }else if (type === 'share') {
            openShare.set(true)
            shareType.set('details')
        } 
        if (type !== 'dl') {
            handlerCloseMenu(show.data.data.feature_type === 1 ? 'reg' : 'con')
        }
    }

    const [subscribe, setSubscribe] = useState(true)
    useEffect(() => {
        if (subscribe) {
            if (show.data.data.access !== null && show.data.data.access !== undefined) {
                let d;
                if (show.data.data.access !== null && (!show.data.data.access[0].update && !show.data.data.access[0].download)) {
                    d = dfltbtnList.filter(v => { return v.type === 'view' || v.type === 'addfav' }  )
                }else if(show.data.data.access !== null && (show.data.data.access[0].update && !show.data.data.access[0].download)){
                    d = dfltbtnList.filter(v => { return v.type === 'view' || v.type === 'addfav' || v.type === 'update' }  )
                }else if(show.data.data.access !== null && (!show.data.data.access[0].update && show.data.data.access[0].download)){
                    d = dfltbtnList.filter(v => { return v.type === 'view' || v.type === 'addfav' || v.type === 'dl' }  )
                }else{
                    d = dfltbtnList.filter(v => { return v.type !== 'remove' }  )
                }
                setBtnList(d)
            }else{
                setBtnList(dfltbtnList)
            }
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [])
    
    return (
        <Modal open={open} onClose={close} BackdropProps={{ sx: {bgcolor: '#0000001F'} }} sx={{zIndex: 1303}}>
            <Slide in={showslide} direction="up">
                <Box height="100%" width="100%" display="flex" justifyContent="flex-end" flexDirection="column">
                    <Box height="100%" onClick={close} />
                    <Box bgcolor="#FFFFFF" p={2}
                        borderRadius="16px 16px 0px 0px" boxShadow="0px 0px 24px rgba(0, 0, 0, 0.08)"
                    >
                        <Box display="flex" justifyContent="center" mb={1}>
                            <Box height="2px" width="37px" bgcolor="#333333" />
                        </Box>
                        <Typography  fontSize={14} fontWeight={600} lineHeight="25px" noWrap>
                            {show.data.data.feature_type === 1 ? 
                                `${show.data.data.registration_name} (${show.data.data.registered_name})`
                            :   
                               `${show.data.data.contract_name} (${show.data.data.client_name})`
                            } 
                        </Typography>
                        {
                            (show.data.view ? btnList.filter(v => { return v.type !== 'view'}) : btnList).map((v,k) => (
                                <MenuItem sx={{px: '0px', py: '8px', minHeight: '36px'}} key={k} onClick={(e) => handlerMenuBtn(e, v.type)}>
                                   {v.icon}
                                    <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px">{v.name}</Box>
                                </MenuItem>
                            ))
                        }
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}
