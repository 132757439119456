import { Avatar, Box, Checkbox, MenuItem, Modal, Slide } from "@mui/material"
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank, Check as CheckIcon } from '@mui/icons-material';

export const AccessList = ({v, openAccess, setOpenAccess, handleCheck}) => {
    const icon = <CheckBoxOutlineBlank fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;
    const checkedIcon = <CheckBoxIcon fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;
    
    const handlerCloseAccess = () => {
        setOpenAccess(false)
    }
    
    return (
        <Modal open={openAccess}>
            <Slide in={openAccess} direction="up">
                <Box height="100%" width="100%" display="flex" flexDirection="column">
                    <Box height="100%" onClick={() => handlerCloseAccess()} />
                    <Box bgcolor="#FFFFFF" borderRadius="6px 6px 0px 0px" >
                        <Box display="flex" alignItems="center" gap="12px" p="8px 16px">
                            <Avatar src={JSON.parse(v.profile).profile} sx={{width: 32, height: 32}} />
                            <Box>{v.first_name} {v.last_name}</Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between" minHeight={44} maxHeight={44} p="8px 14px">
                            <Box fontSize={14} fontWeight={400} lineHeight="24px">View</Box>
                            <CheckIcon sx={{color: '#A2A3A9', fontSize: 20}} />
                        </Box>
                        <MenuItem sx={{p: "8px 14px", gap: "8px"}} onClick={()=>handleCheck('update')}>
                            <Checkbox checked={v.stat.update} icon={icon} checkedIcon={checkedIcon} sx={{p: '0px'}} />
                            <Box fontSize={14} fontWeight={400} lineHeight="24px">Update</Box>
                        </MenuItem>
                        <MenuItem sx={{p: "8px 14px", gap: "8px"}} onClick={()=>handleCheck('download')}>
                            <Checkbox checked={v.stat.download} icon={icon} checkedIcon={checkedIcon} sx={{p: '0px'}} />
                            <Box fontSize={14} fontWeight={400} lineHeight="24px">Download</Box>
                        </MenuItem>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}
