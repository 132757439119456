import { Box, Button, Grid, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { rqx } from '../../../../../core/request/API'
import { env } from '../../../../../core/Env'
import { S3Upload } from '../../../../../core/transmit/S3'
import { SnakeLoader } from '../../../../../core/loader/SnakeLoader'
import moment from 'moment'
import { NotifCount } from '../../../../../core/request/Notif'
import { db } from '../../../../../db/db'

export const Right = ({csuccessOpen, saveLoader, cform, dsg, __SESSION, listConName, cactType, cOthers, __initConList, __seriesNum, __ListConName, nav, paramsids, reloadStorage, conList, storage}) => {
    const __ChangeHandler = (e) => {
        const { name, value } = e.target 
        cform.set({...cform.data, 
            [name]: { val: value, stat: value === '' ? true : false, msg : value === '' ? 'This field is required.' : '' } 
        })
    }

    const validateBtnNxt = () => {
        let bgcolor;
        let disabled = true;
        if ( (cform.data.conName.val === 0 && cform.data.conOther.val === '') || cform.data.name.val === '' || cform.data.date_from.val === '' || cform.data.date_to.val === '' || (cform.data.conFile.file.val === '' && cform.data.conFile2.file.val === '')) {
            bgcolor = "#C7DAD7"
            disabled = true
        }else{
            bgcolor = "#107038"
            disabled = false
        }
        return {bgcolor, disabled}
    }
    
    const HandlerNxt = async(type) => {
        if (type === 'edit' && !saveLoader.data) {
            if (cform.data.id === undefined) {
                nav.push('/my-cloud/contracts/create')
            }else{
                nav.push(`/my-cloud/contracts/${window.btoa(cform.data.id)}`)
            }
        }else{
            if (!cform.data.name.stat) {
                if (!validateBtnNxt().disabled && !saveLoader.data) {
                    nav.push('/my-cloud/contracts/review')
                }
            }
        }
    }

    const handleSubmit = async() => {
        if (!saveLoader.data) {
            saveLoader.set(true)
            let urlS3;
            let urlS32;
    
            if (cform.data.conFile.photo.val !== '') {
                urlS3 = cactType.data === 'update' && cform.data.conFile.photo.val.slice(0, 5) === 'https' ? cactType.data : await S3Upload(cform.data.conFile.file.val);
            }
            if (cform.data.conFile2.photo.val !== '') {
                urlS32 = cactType.data === 'update' && cform.data.conFile2.photo.val.slice(0, 5) === 'https' ? cactType.data : await S3Upload(cform.data.conFile2.file.val);
            }
            let d = []
            if (urlS3 !== undefined || cform.data.conFile.at_id !== undefined) {
                let v = cform.data.conFile.file.val
                d.push(
                    {   storage:cform.data.conFile.storage,
                        at_id:cform.data.conFile.at_id === undefined ? 0  : cform.data.conFile.at_id,
                        file_name:v === '' ? '' : cform.data.conFile.file.val[0].name,
                        file_type:v === '' ? '' : cform.data.conFile.file.val[0].type,
                        file_size:v === '' ? '' : cform.data.conFile.file.val[0].size,
                        file_link:v === '' ? '' : cform.data.conFile.photo.val.slice(0, 5) === 'https' ? cform.data.conFile.photo.val : urlS3.location,
                    }
                )
            }
            if (urlS32 !== undefined || cform.data.conFile2.at_id !== undefined) {
                let v = cform.data.conFile2.file.val
                d.push(
                    {
                        storage:cform.data.conFile2.storage,
                        at_id:cform.data.conFile2.at_id === undefined ? 0  : cform.data.conFile2.at_id,
                        file_name:v === '' ? '' : cform.data.conFile2.file.val[0].name,
                        file_type:v === '' ? '' : cform.data.conFile2.file.val[0].type,
                        file_size:v === '' ? '' : cform.data.conFile2.file.val[0].size,
                        file_link:v === '' ? '' : cform.data.conFile2.photo.val.slice(0, 5) === 'https' ? cform.data.conFile2.photo.val : urlS32.location,
                    }
                )
            }
            if (d.length > 0) {
                if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0') {

                    let total = d.reduce((prev, current) => {
                        return prev + +current.file_size;
                    }, 0);

                    let data = {
                        contract_name: cOthers.data ? cform.data.conOther.val : listConName.data.filter(v => { return v.id === cform.data.conName.val})[0].name,
                        user_id:null,
                        client_name: cform.data.name.val,
                        end_date: moment(cform.data.date_to.val).format('YYYY-MM-DD'),
                        file: JSON.stringify(d),
                        series_num: "CTRC-000000001",
                        start_date: moment(cform.data.date_from.val).format('YYYY-MM-DD'),
                        status: 1,
                        user_favorite_id: null,
                        user_favorite_status: null,
                        ref:''
                    }
                    db.conlist.add(data);
                    conList.set([data])
                    saveLoader.set(false)
                    storage.set({...storage.data, 
                        used:storage.data.used + total,
                        total:21474836480,
                        con:total, 
                        conFile:1,
                    }) 
                    nav.push('/my-cloud/contracts/list')
                    
                }else{
                    let data = {
                        id:cactType.data === 'update' ? cform.data.id : 0,
                        name:cOthers.data ? cform.data.conOther.val : listConName.data.filter(v => { return v.id === cform.data.conName.val})[0].name,
                        company_id:__SESSION.data.uaccess[0].company_id,
                        user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                        client_name:cform.data.name.val,
                        start_date:moment(cform.data.date_from.val).format('YYYY-MM-DD'),
                        end_date:moment(cform.data.date_to.val).format('YYYY-MM-DD'),
                        file:d,
                        ref:''
                    }
                    let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/contracts/${cactType.data === 'update' ? 'update' : 'create'}`, data)
                    if (res.message === 'success') {
                        await __initConList()
                        await __ListConName()
                        await __seriesNum()
                        await csuccessOpen.set(true)
                        let fltrData = [{user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]), file:'con'}]
                        await NotifCount(fltrData, __SESSION, `create`)
                        setTimeout(() => {
                            csuccessOpen.set(false)
                            saveLoader.set(false)
                            reloadStorage.set(true)
                            nav.push('/my-cloud/contracts/list')
                        }, 1000);
                    }else if(res === 'Contracts Already Exist'){
                        csuccessOpen.set(false)
                        saveLoader.set(false)
                        cform.set({...cform.data, 
                            name:{...cform.data.name, stat:true, msg:'Client name is already exist'},
                        })
                        if (cactType.data === 'update') {
                            nav.push(`/my-cloud/contracts/${window.btoa(cform.data.id)}`)
                        }else{
                            nav.push('/my-cloud/contracts/create')
                        }
                    }
                }
            }
        }
    }

    return (
        <Box height="100%" width="50%" bgcolor="#F8F9FA" display="flex" justifyContent="space-between" flexDirection="column" px="60px" py="40px">
            <Box></Box>
            <Box className='ubr-Info'>
                {
                    cform.data.conName.val === 0 &&
                        <>
                            <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={1}>Other Contract Name<Box component="label" color="red">*</Box></Box>
                            <TextField fullWidth 
                                disabled={saveLoader.data}
                                name="conOther"
                                className={dsg.txtfld} 
                                placeholder='Ex.: Barangay Clearance, etc.'
                                onChange={(e) => __ChangeHandler(e)}
                                value={cform.data.conOther.val}
                                error={cform.data.conOther.stat} 
                            />
                            <Box fontSize={10} color="red" pl="5px" pt="5px">{cform.data.conOther.msg}</Box>
                        </>
                }
                <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={1} mt={2}>Name<Box component="label" color="red">*</Box></Box>
                <TextField fullWidth 
                    disabled={saveLoader.data || paramsids === 'review' ? true : false}
                    className={dsg.txtfld}  
                    name="name" 
                    placeholder='Ex.: Juan Dela Cruz Jr.'
                    onChange={(e) => __ChangeHandler(e)}
                    value={cform.data.name.val}
                    error={cform.data.name.stat} 
                />
                <Box fontSize={10} color="red" pl="5px" pt="5px">{cform.data.name.msg}</Box>
                <Box my={2} color="#737373" fontWeight={400} fontSize={14} lineHeight="16px">Validity Date</Box>
                <Grid container spacing={2.5}>
                    <Grid item sm={6}>
                        <Box color="#313131" fontWeight={600} fontSize={14} lineHeight="16px" mb={1}>From</Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker label="" 
                                disabled={saveLoader.data || paramsids === 'review' ? true : false}
                                maxDate={new Date()} 
                                value={cform.data.date_from.val} 
                                onChange={(e)=>__ChangeHandler({target: {name: 'date_from', value: e}})}
                                renderInput={(params) => 
                                    <TextField {...params} fullWidth className={dsg.txtfld} error={cform.data.date_from.val === null} />
                            }
                            />
                        </LocalizationProvider>
                        <Box fontSize={10} color="red" pl="5px" pt="5px">{cform.data.date_to.msg}</Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box color="#313131" fontWeight={600} fontSize={14} lineHeight="16px" mb={1}>To</Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker label=""
                                disabled={saveLoader.data || paramsids === 'review' ? true : false}
                                minDate={new Date(cform.data.date_from.val)}
                                value={cform.data.date_to.val} 
                                onChange={(e)=>__ChangeHandler({target: {name: 'date_to', value: e}})}
                                renderInput={(params) => 
                                    <TextField {...params} fullWidth className={dsg.txtfld} error={cform.data.date_to.val === null}  />
                                }
                            />
                        </LocalizationProvider>
                        <Box fontSize={10} color="red" pl="5px" pt="5px">{cform.data.date_to.msg}</Box>
                    </Grid>
                </Grid>
            </Box>
            <Box display="flex" justifyContent="flex-end" gap="18px" mb="36px">
                {paramsids === 'review' ? 
                    <>
                        {saveLoader.data ? 
                            <Box className={dsg.btnNext} display="flex" justifyContent="center" alignItems="center">
                                Edit
                            </Box>
                        :
                            <Button variant="contained" className={dsg.btnNext} onClick={() => HandlerNxt('edit')}
                                sx={{backgroundColor: '#F8F9FA', color:'#333333', '&:hover': { backgroundColor: '#E6E6E6'}  }}
                            >
                                Edit
                            </Button>
                        }
                        <Button variant="contained" className={dsg.btnNext} onClick={() => handleSubmit()}
                            sx={{zIndex:saveLoader.data ? 2001 : 'auto', bgcolor:'#107038', '&:hover': { backgroundColor: '#107038'}  }}
                        >
                            {saveLoader.data ? 
                                <Box display="flex" alignItems="center" gap="10px" >
                                    {cactType.data === 'update' ? 
                                        <Box>Updating</Box>
                                    :
                                        <Box>Saving</Box>
                                    }
                                    <SnakeLoader size="5px" bg="#FFFFFF" distance="2.5px" />
                                </Box>
                            :
                                cactType.data === 'update' ? 'Update' : 'Save'
                            }
                        </Button>
                    </>
                :
                    <Button variant="contained" className={dsg.btnNext} onClick={() => HandlerNxt('next')}
                        sx={{backgroundColor: cform.data.name.stat ? '#C7DAD7' : validateBtnNxt().bgcolor, '&:hover': { backgroundColor: cform.data.name.stat ? '#C7DAD7' : validateBtnNxt().bgcolor}  }}
                    >
                        Next
                    </Button>
                }
            </Box>
        </Box>
    )
}