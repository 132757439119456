import { Box, IconButton, Typography } from '@mui/material';
import { FavStarMbl, VeriticalThreeDots } from '../../../../../../core/global/Icons';
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { motion } from 'framer-motion';
import moment from 'moment';
import '@react-pdf-viewer/core/lib/styles/index.css';

export const List = ({conList, handlerView, handlerThreeDots}) => {
    return ( 
        conList.data.map((v,k) => (
            <Box px={2} key={k}>
                <Box p={1} display="flex" alignItems="center" flexDirection="row" bgcolor="#FFFFFF" borderRadius="6px" component={motion.div} whileTap={{ backgroundColor: '#f0f0f0' }} width="100%">
                    <Box display="flex" gap="12px" width="92%">
                        <Box display="flex" alignItems="center" minHeight={40} minWidth={40} onClick={() => handlerView(v)}>
                            {
                                JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('image')? 
                                    <Box sx={{backgroundImage: `url(${JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link})`, backgroundSize: 'cover', backgroundPosition: 'center center', height: '40px', width: '40px', backgroundRepeat: 'no-repeat', borderRadius: '6px' }} />
                                :
                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                        <Box sx={{height:'40px', maxHeight:'40px', minHeight: '40px', width:'40px', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                            <Box component={Viewer} fileUrl={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} defaultScale={SpecialZoomLevel.PageWidth} />
                                        </Box>
                                    </Worker>
                            }
                        </Box>
                        <Box width="80%" onClick={() => handlerView(v)}>
                            <Typography fontSize={12} fontWeight={600} lineHeight="19px" width="100%"  noWrap>{v.contract_name} ({v.client_name})</Typography>
                            <Box display="flex" alignItems="center">
                                {
                                    v.user_favorite_status === 2  &&
                                        <FavStarMbl fill={v.user_favorite_status === 2 ? '#11783C' : 'none'} />
                                }
                                <Box ml={v.user_favorite_status === 2 && 1} fontSize={12} fontWeight={400} lineHeight="23px"> 
                                    Expiring • ED: {moment(v.end_date).format('MM/DD/YY')}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" minHeight={20} minWidth={20}> 
                        <IconButton sx={{p: '0px'}} onClick={() => handlerThreeDots(v)}>
                            <VeriticalThreeDots fill={"#737373"} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        ))
    ) 
}