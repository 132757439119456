import { VM } from "../../../core/VM"

let view = VM()
let uai_user = process.env.REACT_APP_UAI_REGULAR;

export const DeleteRT = async(notif_state, regList, conList, favList, docuFolder, docuFile, match, rcntList, __InitTrashFolder, __InitTrashFiles, folderLoader, folderInfi, __SESSION, limit, folderLid, trashFolder, isLoading, inftScroll, lid, trashFiles) => {

    let fileType = JSON.parse(notif_state.notifPerUser.data).access[0].file
    let fileInt = fileType === 'reg' ? 1 : fileType === 'con' ? 2 : fileType === 'folder' ? 3 : 4
    let listData = fileType === 'reg' ? regList : fileType === 'con'? conList : view === '' && fileType === 'folder' ? docuFolder : docuFile
    
    if (match.params.feature === 'recent') {
        let dataArry = (fileType === 'reg' ? rcntList.data.reg : rcntList.data.contracts).filter(v => {return v.id !== JSON.parse(notif_state.notifPerUser.data).access[0].file_id} )
        rcntList.set({...rcntList.data, [fileType === 'reg' ? 'reg':'contracts']:dataArry })
    }else if (match.params.feature === 'registrations' || match.params.feature === 'contracts') {
        let dataArry = listData.data.filter(v => {return v.id !== JSON.parse(notif_state.notifPerUser.data).access[0].file_id} )
        listData.set(dataArry)
    }else if(match.params.feature === 'favorites'){
        if (fileType === 'folder' ) {
            let dataArry = listData.data.filter(v => { return v.folder_id !== JSON.parse(notif_state.notifPerUser.data).access[0].file_id && v.feature_type === fileInt } )
            listData.set(dataArry)
        }else{
            let dataArry = favList.data.filter(v => { return v.id !== JSON.parse(notif_state.notifPerUser.data).access[0].file_id && v.type === fileInt})
            favList.set(dataArry)
        }
    }else if(match.params.feature === 'trash'){
        if (fileType === 'folder' ) {
            __InitTrashFolder(0, folderLoader, folderInfi, __SESSION, limit, folderLid, trashFolder)
        }else{
            __InitTrashFiles(0, isLoading, inftScroll, __SESSION, limit, lid, trashFiles)
        }
    }else if(match.params.feature === 'shared-access'){
        if (fileType === 'folder') {
            let dataArry = listData.data.filter(v => { return (fileInt === 3 ? v.folder_id : v.id) !== JSON.parse(notif_state.notifPerUser.data).access[0].file_id && v.feature_type === fileInt } )
            listData.set(dataArry)
        }
    }

    return 'successfully delete'
}

export const SharedAccessRT = async( ulistAccess, notif_state, __SESSION, match, listView, isLoading, filter, inftScroll, limit, lid, accessLoader, sharedFltr, show, rcntList, regList, conList, sharedList, docuFolder, docuFile, __InitRecentList, __InitRegList, __InitConList, __InitSharedList) => {

    const mergeArrUAcess = ulistAccess.data.map((md) => {
        const td = JSON.parse(notif_state.notifPerUser.data).access.find((td) => td.user_id === md.id);
        return {...md, stat:td === undefined ? {...md.stat} : {...md.stat, view:td.view, update:td.update, download:td.download, remove:td.remove} };
    });
    ulistAccess.set(mergeArrUAcess)
 
    let d = JSON.parse(notif_state.notifPerUser.data).access.filter(v => { return v.user_id === parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) } )
     
    // console.log(d)
    if (d.length > 0) {
        if ( JSON.parse(notif_state.notifPerUser.data).action === 'crtacc') {
            if (match.params.feature === 'recent') {
                listView.set(view === '' ? false : true)
                await __InitRecentList(isLoading, __SESSION, rcntList)
            }else if (match.params.feature === 'registrations') {
                if (d[0].file === 'reg') {
                    listView.set(view === '' ? false : true)
                    await __InitRegList(0, filter.data, isLoading, inftScroll, __SESSION, limit, lid, regList)
                }
            }else if (match.params.feature === 'contracts') {
                if (d[0].file === 'con') {
                    listView.set(view === '' ? false : true)
                    await __InitConList(0, isLoading, inftScroll, __SESSION, limit, lid, conList)
                }
            }else if (match.params.feature === 'shared-access') {
                if (d[0].file === 'folder' || d[0].file === 'file') {
                    listView.set(view === '' ? false : true)
                    accessLoader.set(!accessLoader.data)
                }
            }else{
                await __InitSharedList(0, isLoading, inftScroll, __SESSION, lid, sharedFltr, sharedList)
            }
            return 'successfully shared access'
        }
    
        let mergeArrShow = {...show.data, data:{...show.data.data, access:d } }
        show.set(mergeArrShow)


        let listData = d[0].file === 'reg' ? regList : d[0].file === 'con'? conList : d[0].file === 'folder' && view === '' ? docuFolder : docuFile
        const Fltrs = listData.data.filter(md => !JSON.parse(notif_state.notifPerUser.data).access.find(td => (md.id === td.file_id && td.remove ) ));
        const Fltr = Fltrs.map((md) => {
            const td = JSON.parse(notif_state.notifPerUser.data).access.find((td) => td.file_id === md.id);
            return {...md, access:md.access === null || md.access === undefined ? md.access : ( td !== undefined ? ( [{...md.access[0], view:td.view, update:td.update, download:td.download, remove:td.remove }] ) : [{...md.access[0]}] ) };
        });
        listData.set(Fltr)
        if (d[0].file === 'folder' || d[0].file === 'file') {
            show.set({...show.data, data : '', view : false, fullview : false})
        }
    
        if (d[0].file === 'reg' || d[0].file === 'con') {
            const rcntFltrs = (d[0].file === 'reg' ? rcntList.data.reg : rcntList.data.contracts).filter(md => !JSON.parse(notif_state.notifPerUser.data).access.find(td => (md.id === td.file_id && td.remove ) ));
            const rcntFltr = rcntFltrs.map((md) => {
                const td = JSON.parse(notif_state.notifPerUser.data).access.find((td) => td.file_id === md.id);
                return {...md, access:md.access === null || md.access === undefined ? md.access : ( td !== undefined ? ( [{...md.access[0], view:td.view, update:td.update, download:td.download, remove:td.remove }] ) : [{...md.access[0]}] ) };
            });
            rcntList.set({...rcntList.data, [d[0].file === 'reg' ? 'reg':'contracts']:rcntFltr })
        }
        
        // FOR MODAL VALIDATION IF THE USER NAG UUPDATE NG FILE.
        if (!d[0].update || d[0].remove) {
            if (d[0].file === 'reg' && match.params.feature === "registrations" && parseInt(window.atob(match.params.params)) === d[0].file_id) {
                notif_state.rtAccess.set(true)
            }
            if (d[0].file === 'con' && match.params.feature === "contracts" && parseInt(window.atob(match.params.params)) === d[0].file_id) {
                notif_state.rtAccess.set(true)
            }
        }else{
            notif_state.rtAccess.set(false)
        }
        return 'successfully remove shared access'
    }
    return ''
}

export const RestoreRemoveRT = async(trashFolder, trashFiles, rcntList, regList, conList, match, listView, isLoading, __SESSION, filter, inftScroll, limit, lid, accessLoader, notif_state, __InitRecentList, __InitRegList, __InitConList) => {
    
    const __initRestoreRemove = async(d) => {
        let fileType = d.file === 1 ? 'reg' : d.file === 2 ? 'con' : d.file === 3 ? 'folder' : 'file'
        let listData = fileType === 'folder' ? trashFolder : trashFiles
        if (match.params.feature === 'trash') {
            listData.set(prevData => prevData.filter(v => v.id !== d.file_id))
            if (JSON.parse(notif_state.notifPerUser.data).action === 'remove' || JSON.parse(notif_state.notifPerUser.data).action === 'multi-remove') {
                return 'successfully permanently remove'
            }
        } 
        if (match.params.feature === 'recent') {
            listView.set(view === '' ? false : true)
            await __InitRecentList(isLoading, __SESSION, rcntList)
        }else if (match.params.feature === 'registrations') {
            if (fileType === 'reg') {
                listView.set(view === '' ? false : true)
                await __InitRegList(0, filter.data, isLoading, inftScroll, __SESSION, limit, lid, regList)
            }
        }else if (match.params.feature === 'contracts') {
            if (fileType === 'con') {
                listView.set(view === '' ? false : true)
                await __InitConList(0, isLoading, inftScroll, __SESSION, limit, lid, conList)
            }
        }else if (match.params.feature === 'shared-access') {
            if (fileType === 'folder' || fileType === 'file') {
                listView.set(view === '' ? false : true)
                accessLoader.set(!accessLoader.data)
            }
        }
        return 'successfully multiple restore'
    }

    if (JSON.parse(notif_state.notifPerUser.data).action === 'multi-restore' || JSON.parse(notif_state.notifPerUser.data).action === 'multi-remove') {
        JSON.parse(notif_state.notifPerUser.data).access.map((i) => {
            let d = i.user_id.filter(v => { return v === parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) || i.created_by === parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) } )
            if (d.length > 0) {
                __initRestoreRemove(i)
            }else{
                //ADMIN
                if (__SESSION.data.uaccess[0].role_id !== parseInt(uai_user)) {
                    __initRestoreRemove(i)
                }
            }
            return false
        })
        return
    }

    if (JSON.parse(notif_state.notifPerUser.data).action === 'restore' || JSON.parse(notif_state.notifPerUser.data).action === 'remove') {
        
        let d = JSON.parse(notif_state.notifPerUser.data).access.filter(v => { return v.user_id === parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) || v.created_by === parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) } )
        if (d.length > 0) {
            await __initRestoreRemove(d[0])
        }else{
            //ADMIN
            if (__SESSION.data.uaccess[0].role_id !== parseInt(uai_user)) {
                let d = JSON.parse(notif_state.notifPerUser.data)
                await __initRestoreRemove(d.access[0])
            }
        }
        return
    }
}