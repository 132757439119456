import { useEffect, useState } from "react"
import { Box, IconButton, Modal, Slide } from "@mui/material"
import { ArrowLeft } from "../../../../../../../core/global/Icons"
import { Details } from "./Details"
import { Review } from "./Review"
import { UseStyles } from "../../../../../global/DesktopDsg"
import { Buttons } from "./Buttons"
import { rqx } from "../../../../../../../core/request/API"
import { env } from "../../../../../../../core/Env"
import { Loader } from "./Loader"

export const Index = ({subparams, cloud_state, tool_state, onChangeFile, __initConList, __ListConName}) => {
    const dsg = UseStyles()
    const [subscribe, setSubscribe] = useState(true)
    const [loader, setLoader] = useState(true)
    const { conToggle, consn, show } = cloud_state
    const { __SESSION } = tool_state

    const handlerBack = () => {
        conToggle.set({...conToggle.data, unsaved:true})
    }

    const __seriesNum = async() => {
        let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/contracts/series_num`, {
            company_id:__SESSION.data.uaccess[0].company_id, 
        })
        consn.set(res)
    }

    useEffect(() => {
        const __init = async() => {
            await __seriesNum()
            await __ListConName()
            await setLoader(false)
        }
        if (subscribe) {
            __init()
        }

        return () => {
            setSubscribe(false)
        }
        // eslint-disable-next-line
    }, [])
    
    return (
        <Modal open={conToggle.data.details} className="noutlined">
            <Slide in={conToggle.data.details} direction="left" className="noutlined">
                <Box height="100%" width="100%" bgcolor="#F8F9FA" display="flex" flexDirection="column">
                    <Box p="12px 16px" minHeight="86px" width="100%">
                        <Box display="flex" alignItems="center" width="100%">
                            <IconButton sx={{p: '0px', minHeight: 20, position: 'absolute'}} onClick={() => handlerBack()}>
                                <ArrowLeft sx={{fontSize: 20}} />
                            </IconButton>
                            <Box width="100%" display="flex" justifyContent="center" fontSize={16} fontWeight={600} lineHeight="26px">
                                Upload Contracts
                            </Box>
                        </Box>
                        <Box py="12px" display="flex" justifyContent="space-between" gap="8px">
                            <Box width="100%" display="flex" alignItems="center" flexDirection="column">
                                <Box fontSize={12} fontWeight={400} lineHeight="19px" color="#107038"> Details</Box>
                                <Box height="4px" width="100%" bgcolor="#107038" borderRadius="12px"  />
                            </Box>
                            <Box width="100%" display="flex" alignItems="center" flexDirection="column">
                                <Box fontSize={12} fontWeight={400} lineHeight="19px" color={subparams === 'review' ? "#107038" : "#A2A3A9"}> Review</Box>
                                <Box height="4px" width="100%" bgcolor={subparams === 'review' ? "#107038" : "#A2A3A9"} borderRadius="12px" />
                            </Box>
                        </Box>
                    </Box>
                    {loader ? 
                        <Loader/>
                    :
                        <>
                            <Box height="100%" width="100%" className='overflowY' bgcolor="#ffffff" borderRadius="12px 12px 0px 0px" px={2} pb={2}>
                                <Box display="flex" justifyContent="center" my="11px">
                                    <Box height="2px" width="37px" bgcolor="#EAEFF7"/>
                                </Box>
                                {subparams === 'review' ? 
                                    <Review cloud_state={cloud_state}/>
                                :
                                    <Details dsg={dsg} subparams={subparams} cloud_state={cloud_state} onChangeFile={onChangeFile}/>
                                }
                            </Box>
                            <Buttons show={show} conToggle={conToggle} dsg={dsg} subparams={subparams} __initConList={__initConList} __seriesNum={__seriesNum} __ListConName={__ListConName} />
                        </>
                    }
                </Box>
            </Slide>
        </Modal>
    )
}