import { useContext, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { ToolContext } from "../../../../../../core/context/ToolContext"
import { SelectType } from "./SelectType"
import { Index as Form } from "./form/Index"
import { rqx } from "../../../../../../core/request/API"
import { env } from "../../../../../../core/Env"
import { Choose } from "../modal/Choose"
import { UnsavedMsgModal } from "../../../../global/DesktopDsg"
import { __InitConList } from "../../../../global/Function"
import { FailedUpload, NewSuccessAlert } from "../../../../../../core/global/forms/CstmModal"

export const FormCon = ({match}) => {
    const nav = useHistory()
    const [subscribe, setSubscribe] = useState(true)
    const [fileError, setFileError] = useState(false)
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { dfltForm, listConName, cform, conToggle, isLoading, inftScroll, limit, lid, conList, csuccessOpen, cactType } = cloud_state
    const { __SESSION } = tool_state

    const validateBase64String = () =>{
        try {
            window.atob(subparams);
            return true;
        } catch (e) {
            return false;
        }
    }

    const subparams = match;
    const isValid = validateBase64String(subparams);

    const onChangeFile = (e) => {
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
        let _file = e.target.files[0]
        if (_file !== undefined) {
            if (allowedFileTypes.includes(_file.type)) {
                if (cform.data.conFile.file.val === '') {
                    cform.set({...cform.data, 
                        conFile:{...cform.data.conFile,
                            photo:{...cform.data.conFile.photo, val:URL.createObjectURL(_file)}, 
                            file:{...cform.data.conFile.file, val:e.target.files}, 
    
                        }
                    })
                }else if(cform.data.conFile2.file.val === '') {
                    cform.set({...cform.data, 
                        conFile2:{...cform.data.conFile2,
                            photo:{...cform.data.conFile2.photo, val:URL.createObjectURL(_file)}, 
                            file:{...cform.data.conFile2.file, val:e.target.files}, 
                        }
                    })
                }
                setFileError(false)
                return true
            }else{
                setFileError(true)
                return false
            }
        }
    }

    const HandlerBtn = (type) => {
        if (type === 'leave') {
            conToggle.set({select:false, slctfile:{stat:false, data:''}, details:false, review:false, unsaved:false})
            cform.set(dfltForm.data)
            nav.goBack()
            // nav.push('/my-cloud/contracts/list')
        }else{
            conToggle.set({...conToggle.data, unsaved:false})
        }
    }

    const __initConList = async() => {
        await __InitConList(0, isLoading, inftScroll, __SESSION, limit, lid, conList)
    }

    const __initGETIDS = async() => {
        if (isNaN(parseInt(window.atob(match)))) {
            await nav.push('/my-cloud/contracts/list')
            return
        }
        let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/contracts/view`, {
            id:parseInt(window.atob(match)), 
        })

        cform.set({...cform.data, 
            id:parseInt(window.atob(match)),
            date_from:{...cform.data.date_from, val:res[0].start_date},
            date_to:{...cform.data.date_from, val:res[0].end_date},
            name:{...cform.data.name, val:res[0].client_name},
            conName:{...cform.data.name, val:res[0].contracts_id},
            conFile:{
                at_id:JSON.parse(res[0].file)[0].attachment_id,
                photo:{val:JSON.parse(res[0].file)[0].file_link, stat:false, msg:''}, 
                file:{val:[{
                    name:JSON.parse(res[0].file)[0].file_name,
                    type:JSON.parse(res[0].file)[0].file_type,
                    size:JSON.parse(res[0].file)[0].file_size,
                }], stat:false, msg:''}
            },
            conFile2:{
                at_id:JSON.parse(res[0].file).length > 1 ? JSON.parse(res[0].file)[1].attachment_id : undefined,
                photo:{val:JSON.parse(res[0].file).length > 1  ? JSON.parse(res[0].file)[1].file_link : '', stat:false, msg:''}, 
                file:{stat:false, msg:'', val:JSON.parse(res[0].file).length > 1 
                    ?
                        [{
                            name:JSON.parse(res[0].file)[1].file_name,
                            type:JSON.parse(res[0].file)[1].file_type,
                            size:JSON.parse(res[0].file)[1].file_size,
                        }]
                    :
                        ''
                }
            },
        })
    }

    const __ListConName = async() => {
        let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/contracts/default`, {
            company_id:__SESSION.data.uaccess[0].company_id, 
        })
        let f  = res.filter(v => { return v.id === 0})
        if (f.length === 0) {
            let others = [{id:0, name:'Others'}]
            listConName.set([...res, ...others])
        }else{
            listConName.set(res)
        }
    }
    
    useEffect(() => {
        
        const __init = async() => {
            if (isValid && (subparams !== 'create' && subparams !== 'review')) {
                conToggle.set({...conToggle.data, details:true })
                cactType.set('update')
                await __initGETIDS()
            }else{
                await __ListConName()
                cactType.set('create')
                if (subparams !== 'create') {
                    await nav.push('/my-cloud/contracts/list')
                    return
                }
            }
        }
        if (subscribe) {
            cform.set(dfltForm.data)
            __init()
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [])
    
    return (
        <>
            {subparams === 'create' && (cform.data.conName.val === '' || conToggle.data.slctfile.stat)  ? 
                <SelectType cloud_state={cloud_state}/>
            :
                <Form subparams={subparams} cloud_state={cloud_state} tool_state={tool_state} onChangeFile={onChangeFile} __initConList={__initConList} __ListConName={__ListConName}/>
            }
            {conToggle.data.slctfile.stat && <Choose cloud_state={cloud_state} onChangeFile={onChangeFile} /> }
            <UnsavedMsgModal open={conToggle.data.unsaved} HandlerBtn={HandlerBtn} />
            <NewSuccessAlert successOpen={csuccessOpen.data} setSuccessOpen={csuccessOpen.set} text={`Successfully ${cactType.data === 'update' ? 'Updated' : 'Saved'}`} /> 
            <FailedUpload open={fileError} close={() => setFileError(false)} type="contracts" />
        </>
    )
}