import { CheckBox, CheckBoxOutlineBlank, KeyboardArrowDown } from "@mui/icons-material";
import { Avatar, Box, Checkbox } from "@mui/material";
import { NoResult } from "../../../../../empty/Index";
import { useState } from "react";
import { AccessList } from "./AccessList";

export const UserList = ({userList, searchInput, searchData, slctAllTxt, usrData, setUsrData, setSearchData}) => {
    const icon = <CheckBoxOutlineBlank fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;
    const checkedIcon = <CheckBox fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;
    const [openAccess, setOpenAccess] = useState(false)
    const [idata, setIdata] = useState([])

    const handlerSelectedUser = (e, id) => {
        let data = userList.data.map((v) => v.id === id ? {...v, stat : {...v.stat, view:e.target.checked} } : {...v})
        let fltrdata = data.filter(v => { return !v.stat.view })
        slctAllTxt.set(fltrdata.length === 0 ? true :false)
        userList.set(data)
        setUsrData({...usrData, users: usrData.users.map((v) => v.id === id ? {...v, stat : {...v.stat, view:e.target.checked} } : {...v}) })
        if (searchInput.data !== '') {
            setSearchData(searchData.map((v) => v.id === id ? {...v, stat : {...v.stat, view:e.target.checked} } : {...v}))
        }
    }

    const handlerSelectAccess = (value) => {
        if (value.stat.view) {
            setIdata(value)
            setOpenAccess(true)
        }
    }

    const handleCheck = (type) => {
        if (type === 'download') {
            if (searchInput.data !== '') {
                setSearchData(
                    searchData.map((i) => i.id === idata.id ? {...i, stat:{...i.stat, download:!i.stat.download}} : {...i} )
                )
            }
            userList.set(
                userList.data.map((i) => i.id === idata.id ? {...i, stat:{...i.stat, download:!i.stat.download}} : {...i} )
            )
        }else{
            if (searchInput.data !== '') {
                setSearchData(
                    searchData.map((i) => i.id === idata.id ? {...i, stat:{...i.stat, update:!i.stat.update}} : {...i} )
                )
            }
            userList.set(
                userList.data.map((i) => i.id === idata.id ? {...i, stat:{...i.stat, update:!i.stat.update}} : {...i} )
            )
        }
    }
    
    return (
        (searchInput.data !== '' && searchData.length === 0) || userList.data.length === 0 ?
            <NoResult/>
        :
            (searchInput.data !== '' ? searchData.sort((a, b) => a.first_name.localeCompare(b.first_name)) : userList.data.sort((a, b) => a.first_name.localeCompare(b.first_name))).map((v,k) => (
                <Box display="flex" alignItems="center" gap="24px" py={1} mb={1} key={k}>
                    <Checkbox checked={v.stat.view} icon={icon} checkedIcon={checkedIcon} sx={{p: '0px'}} onChange={(e) => handlerSelectedUser(e, v.id)} />
                    <Box display="flex" alignItems="center" gap="12px">
                        <Avatar src={JSON.parse(v.profile).profile} alt="profile_img" sx={{height: 32, width: 32}} />
                        <Box display="flex" flexDirection="column" fontWeight={400} lineHeight="24px">
                            <Box fontSize={14}>{v.first_name} {v.last_name}</Box>
                            <Box display="flex" alignItems="center" gap="4px" onClick={() => handlerSelectAccess(v)}>
                                <Box fontSize={12} color={v.stat.view ? "#107038" : "#A2A3A9"}>
                                    {v.stat.update && v.stat.download ?
                                        ` Update & Download`
                                    :
                                        v.stat.update ? `View & Update` : v.stat.download ? `View & Download` : 'View'
                                    }
                                </Box>
                                <KeyboardArrowDown sx={{color: v.stat.view ? "#333333" : "#A2A3A9"}} />
                            </Box>
                        </Box>
                    </Box>
                    {(v.stat.view && idata.id === v.id) && (
                        <AccessList v={v} openAccess={openAccess} setOpenAccess={setOpenAccess} handleCheck={handleCheck} />
                    )}
                </Box>
            ))
    )
}
