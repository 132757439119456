import { useState } from "react"
import { Avatar, Box, Checkbox, Menu, MenuItem, Typography } from "@mui/material"
import { CheckBox, CheckBoxOutlineBlank, KeyboardArrowDown } from "@mui/icons-material"
import { NoResult } from "../../../../empty/Index"
import { rqx } from "../../../../../core/request/API";
import { env } from "../../../../../core/Env";
import { NotifCount } from "../../../../../core/request/Notif";
import CheckIcon from '@mui/icons-material/Check';
import Lock from '../../../../../assets/images/icons/lock.png';

export const UserList = ({show, ulistAccess, searchData, setSearchData, searchInput, saveLoader, __SESSION, successOpen, message, withAccess,  setDefaultList }) => {
    const [openMenu, setOpenMenu] = useState(null)
    const [idata, setIdata] = useState([])
    let company_id = __SESSION.data.uaccess[0].company_id;
    let user_id =  atob(__SESSION.data.ainfo.aid).split('_', 2)[1]

    const handleUpdateMenu = (e, value) => {
        if (show.data.data.access === null || show.data.data.access === undefined) {
            setIdata(value)
            setOpenMenu(e.currentTarget)
        }
    }

    const handlerUpdateAccess = async(type, val) => {
        if (!saveLoader.data) {
            saveLoader.set(true)
            if (type === 'download') {
                if (searchInput.data !== '') {
                    setSearchData(
                        searchData.map((i) => i.id === val.id ? {...i, stat:{...i.stat, download:!val.stat.download}} : {...i} )
                    )
                }
                ulistAccess.set(
                    ulistAccess.data.map((i) => i.id === val.id ? {...i, stat:{...i.stat, download:!val.stat.download}} : {...i} )
                )
                setDefaultList(ulistAccess.data.map((i) => i.id === val.id ? {...i, stat:{...i.stat, download:!val.stat.download}} : {...i} ))
            }else{
                if (searchInput.data !== '') {
                    setSearchData(
                        searchData.map((i) => i.id === val.id ? {...i, stat:{...i.stat, update: !val.stat.update}} : {...i} )
                    )
                }
                ulistAccess.set(
                    ulistAccess.data.map((i) => i.id === val.id ? {...i, stat:{...i.stat, update: !val.stat.update}} : {...i} )
                )
                setDefaultList(ulistAccess.data.map((i) => i.id === val.id ? {...i, stat:{...i.stat, update: !val.stat.update}} : {...i} ))
            }
           
            let fltr = ulistAccess.data.map(v => {return {
                        user_id: v.id, 
                        view:true,
                        update: v.id === val.id && type === 'update' ? !val.stat.update : v.stat.update, 
                        download:  v.id === val.id && type === 'download' ? !val.stat.download :  v.stat.download 
                    }})
            let body = {
                company_id: company_id,
                access:fltr,
                user_id: user_id,
                id: withAccess.data.val[0].id,
                ref: 'update'
            }
            setOpenMenu(null)
            const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/access/update`, body)
            if (res.message === 'success') {
                let f = fltr.filter(v => { return v.user_id === val.id} )
                await NotifCount([{...f[0], remove:false, file:show.data.data.feature_type === 1 ? 'reg' : 'con', file_id:show.data.data.id}], __SESSION, 'updtacc')
                saveLoader.set(false)
                successOpen.set(true)
                message.set('Successfully Update')
            }else{
                saveLoader.set(false)
            }
        }
    }
    
    return (    
        ulistAccess.data.length === 0 ?
            <Box display="flex" alignItems="center" justifyContent="center" height="100%" gap="12px">
                <img src={Lock} alt={Lock} />
                <Box fontWeight={400} fontSize={14} lineHeight="22px" color="#A2A3A9">Not shared</Box>
            </Box>
        :
            (searchInput.data !== '' ? searchData : ulistAccess.data).length === 0 ? 
                <NoResult/>
            :
                (searchInput.data !== '' ? searchData.sort((a, b) => a.first_name.localeCompare(b.first_name)) : ulistAccess.data.sort((a, b) => a.first_name.localeCompare(b.first_name))).map((v,k) => (
                    <Box display="flex" alignItems="center" justifyContent="space-between" p="8px 16px" key={k}>
                        <Box display="flex" alignItems="center">
                            <Avatar src={JSON.parse(v.profile).profile} alt="profile" sx={{width: 32, height: 32}} />
                            <Typography ml="12px" fontSize={14} fontWeight={400} lineHeight="24px">{v.first_name} {v.last_name}</Typography>
                        </Box> 
                        <Box sx={{opacity:show.data.data.access === null || show.data.data.access === undefined ? 1 : 0.3}} display="flex" alignItems="center" className={show.data.data.access === null || show.data.data.access === undefined ? "c-pointer" : ''} onClick={(e) => handleUpdateMenu(e, v)}>
                            <Box fontSize={14} fontWeight={400} lineHeight="16px" >
                                {
                                    v.stat.update === false && v.stat.download === false ?
                                        'View'
                                    :
                                        v.stat.update && v.stat.download ?
                                            'Update & Download'
                                        :
                                            v.stat.update === false && v.stat.download ?
                                                'View & Download'
                                            :
                                                'View & Update'
                                }
                            </Box>
                            <KeyboardArrowDown sx={{fontSize: 20}} />
                        </Box>
                        {
                            idata.id === v.id &&
                                <MenuBtn openMenu={openMenu} setOpenMenu={setOpenMenu} v={v} handlerUpdateAccess={handlerUpdateAccess} saveLoader={saveLoader} />
                        }
                    </Box>
                ))
            )
}

const MenuBtn = ({openMenu, setOpenMenu, v, handlerUpdateAccess, saveLoader}) => {
    const icon = <CheckBoxOutlineBlank fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;
    const checkedIcon = <CheckBox fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;

    return(
        <Menu anchorEl={openMenu} open={Boolean(openMenu)} onClose={() => setOpenMenu(null)}
            PaperProps={{ sx: { width: 250, borderRadius: '6px' } }} elevation={2}
        >
            <MenuItem sx={{p:'16px 14px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Box fontWeight={400} lineHeight="24px">View</Box>
                <CheckIcon sx={{color: '#A2A3A9', fontSize: 20}} />
            </MenuItem>
            <MenuItem sx={{p:'16px 14px', display: 'flex', alignItems: 'center', gap: '8px'}} onClick={()=>handlerUpdateAccess('update', v)} disabled={saveLoader.data}>
                <Checkbox checked={v.stat.update} icon={icon} checkedIcon={checkedIcon} sx={{p: '0px'}} />
                <Box fontWeight={400} lineHeight="24px">Update</Box>
            </MenuItem>
            <MenuItem sx={{p:'16px 14px', display: 'flex', alignItems: 'center', gap: '8px'}} onClick={()=>handlerUpdateAccess('download', v)} disabled={saveLoader.data}>
                <Checkbox checked={v.stat.download} icon={icon} checkedIcon={checkedIcon} sx={{p: '0px'}} />
                <Box fontWeight={400} lineHeight="24px">Download</Box>
            </MenuItem>
        </Menu>
    )
}
