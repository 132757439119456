import { InsertDriveFile as InsertDriveFileIcon } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import Zip from '../../../assets/images/logos/filesExtension/zip.png'
import Excel from '../../../assets/images/logos/filesExtension/excel.png'
import Word from '../../../assets/images/logos/filesExtension/word.png'
import Notepad from '../../../assets/images/logos/filesExtension/notepad.png'
import Powerpoint from '../../../assets/images/logos/filesExtension/pp.png'

export const FileExtension = ({link, width = 'auto', height = 100}) => {
    if (link.includes('.zip')) {
        return <Avatar variant='rounded' src={Zip} alt={Zip} sx={{width:width, height: height, fill: '#E2EDE7'}}/>
    }else if (link.includes('.xlsx')) {
        return <Avatar variant='rounded' src={Excel} alt={Excel} sx={{width:width, height: height, fill: '#E2EDE7'}}/>
    }else if (link.includes('.txt')) {
        return <Avatar variant='rounded' src={Notepad} alt={Notepad} sx={{width:width, height: height, fill: '#E2EDE7'}}/>
    }else if (link.includes('.pptx')) {
        return <Avatar variant='rounded' src={Powerpoint} alt={Powerpoint} sx={{width:width, height: height, fill: '#E2EDE7'}}/>
    }else if (link.includes('.docx')) {
        return <Avatar variant='rounded' src={Word} alt={Word} sx={{width:width, height: height, fill: '#E2EDE7'}}/>
    }else{
        return <InsertDriveFileIcon sx={{width: width, height: height, fill: '#E2EDE7',}} />
    }
}