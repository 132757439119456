import { useEffect, useContext } from 'react';
import { ValidateBtnNxtReg, __InitRegList, __ListRegName, __RegSeriesNum } from '../../../../global/Function';
import { ToolContext } from '../../../../../../core/context/ToolContext';
import { SelectRegType } from './SelectRegType';
import { ImgScan } from './ImgScan';
import { Backdrop, Box, Button, IconButton, Modal } from '@mui/material';
import { UseStyles } from '../../../../global/DesktopDsg'
import { Details } from './Details';
import { useHistory } from 'react-router-dom';
import { ArrowLeft } from '../../../../../../core/global/Icons';
import { Review } from './Review';
import { rqx } from '../../../../../../core/request/API';
import { S3Upload } from '../../../../../../core/transmit/S3';
import { env } from '../../../../../../core/Env';
import { SnakeLoader } from '../../../../../../core/loader/SnakeLoader';
import { useState } from 'react';
import { MblFetchingLoader } from '../../../../../fetching/Loader';
import { MbFailedOcrAlrt } from '../../../../../../core/global/forms/CstmModal'
import moment from 'moment';

export const FormCon = ({match}) => {
    const { tool_state, cloud_state} = useContext(ToolContext)
    const { listRegName, regsn, form, ocrLoader, modal, actType, isLoading, inftScroll, limit, lid, regList, saveLoader, reloadStorage, message, successOpen, filter, show, choices, sufficientStorage, insuffShow, errOpen } = cloud_state
    const { __SESSION } = tool_state
    const dsg = UseStyles()
    const nav = useHistory()
    const [mLoader, setMLoader] = useState(false)
    const [subscribe, setSubscribe] = useState(true)

    const validateBase64String = () =>{
        try {
            window.atob(subparams);
            return true;
        } catch (e) {
            return false;
        }
    }
    const subparams = match
    const isValid = validateBase64String(subparams);
    
    const [dfltForm] = [{
        date_from:{val:'', stat:false, msg:''},
        date_to:{val:'', stat:false, msg:''},
        regName:{val:'', stat:false, msg:''},
        regType:{val:'', stat:false, msg:''},
        regFile:{
            photo:{val:'', stat:false, msg:''}, 
            file:{val:'', stat:false, msg:''}
        },
        name:{val:'', stat:false, msg:''},
        number:{val:'', stat:false, msg:''},
        regOther:{val:'', stat:false, msg:''},
    }]

    const handlerCloseUpldReg = () => {
        if (subparams === 'create') {
            modal.set({...modal.data, regForm : {val : false} })
            form.set(dfltForm)
        }else if (subparams === 'review') {
            nav.push(`/my-cloud/registrations/${actType.data === 'create' ? 'create' : window.btoa(show.data.data.id)} `)  
        }else{
            nav.goBack()
            // nav.push('/my-cloud/registrations/list')  
            modal.set({...modal.data, regForm : {val : false} })
        }
    }
    
    const handlerBtn = async(type) => {
        if (type === 'next') {
            if (ValidateBtnNxtReg(form).disabled !== true) {
                nav.push('/my-cloud/registrations/review')
            }
        }else if (type === 'edit') {
            nav.push('/my-cloud/registrations/create')
        }else{
            if (!saveLoader.data) {
                saveLoader.set(true)
                let count = 0;
                const urlS3 = actType.data === 'update' && form.data.regFile.photo.val.slice(0, 5) === 'https' ? actType.data : await S3Upload(form.data.regFile.file.val);
                if (urlS3) {
                    let data = {
                        id:actType.data === 'update' ? form.data.id : 0,
                        name:form.data.regName.val === 0 ? form.data.regOther.val : listRegName.data.filter(v => { return v.id === form.data.regName.val})[0].name,
                        type:form.data.regType.val,
                        company_id:__SESSION.data.uaccess[0].company_id,
                        user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                        registered_name:form.data.name.val,
                        reference_num:form.data.number.val,
                        start_date:form.data.regType.val === 1 ? moment(form.data.date_from.val).format('YYYY-MM-DD') : '',
                        end_date:form.data.regType.val === 1 ? moment(form.data.date_to.val).format('YYYY-MM-DD') : '',
                        file_name:form.data.regFile.file.val[0].name,
                        file_type:form.data.regFile.file.val[0].type,
                        file_size: form.data.regFile.photo.val.slice(0, 5) === 'https' ? form.data.regFile.file.val[0].size :count += Math.round(form.data.regFile.file.val[0].size / 1024),
                        file_link:form.data.regFile.photo.val.slice(0, 5) === 'https' ? form.data.regFile.photo.val : urlS3.location,
                        ref:''
                    }
                    let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/registration/${actType.data === 'update' ? 'update' : 'create'}`, data)
                    if (res.message === 'success') {
                        await message.set(`Successfully ${actType.data === 'update' ? 'updated':'saved'}`)
                        await successOpen.set(true)
                        await __InitRegList(0, filter.data, isLoading, inftScroll, __SESSION, limit, lid, regList)
                        saveLoader.set(false)
                        reloadStorage.set(true)
                        nav.push('/my-cloud/registrations/list')
                    }else if(res === 'Register name is already exist'){
                        form.set({...form.data, 
                            name:{...form.data.name, stat:true, msg:'Register name is already exist'},
                        })
                        saveLoader.set(false)
                        if (actType.data === 'update') {
                            nav.push(`/my-cloud/registrations/${window.btoa(form.data.id)}`)
                        }else{
                            nav.push('/my-cloud/registrations/create')
                        }
                    }else if(res === 'Reference Num is already exist'){
                        form.set({...form.data, 
                            number:{...form.data.number, stat:true, msg:'Reference number is already exist'},
                        })
                        saveLoader.set(false)
                        if (actType.data === 'update') {
                            nav.push(`/my-cloud/registrations/${window.btoa(form.data.id)}`)
                        }else{
                            nav.push('/my-cloud/registrations/create')
                        }
                    }
                }
            }
        }
    }

    const __initGETIDS = async() => {
        if (isNaN(parseInt(window.atob(match)))) {
            await nav.push('/my-cloud/registrations/list')
            return
        }
        let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/registration/view`, {
            id:parseInt(window.atob(match)), 
        })
       
        form.set({...form.data, 
            id: parseInt(res[0].id),
            series_num:res[0].series_num,
            date_from:{...form.data.date_from, val:res[0].start_date},
            date_to:{...form.data.date_to, val:res[0].end_date},
            regName:{...form.data.date_to, val:res[0].registration_details_id},
            name:{...form.data.name, val:res[0].registered_name},
            number:{...form.data.number, val:res[0].reference_num},
            regType:{...form.data.regType, val:res[0].type},
            regFile:{
                photo:{val:res[0].file_link, stat:false, msg:''}, 
                file:{val:[{
                    name:res[0].file_name,
                    type:res[0].file_type,
                    size:res[0].file_size,
                }], stat:false, msg:''}
            },
            regOther:{...form.data.regOther, val:res[0].registration_name},
        })
    }

    const GotIt = () => {
        form.set({...form.data, 
            regFile:{
                photo:{...form.data.regFile.photo, stat:false, msg:''}, 
                file:{...form.data.regFile.file, stat : false}
            }
        })
    }
    
    useEffect(() => {
        const __init = async() => {
            setMLoader(true)
            await  __ListRegName(__SESSION, listRegName)
            await __RegSeriesNum(__SESSION, regsn)
            if (isValid && subparams !== 'create' && subparams !== 'review') {
                actType.set('update')
                await __initGETIDS()
                modal.set({...modal.data, regForm : {val : true} })
            }
            setMLoader(false)
        }
        if (subscribe) {
            form.set(dfltForm)
            if (subparams === 'review') {
                let res = ValidateBtnNxtReg(form)
                if (res.disabled) {
                    nav.push('/my-cloud/registrations/create')
                }
            }
            __init()
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [])
   
    return (
        <>
            {
                mLoader ?
                    <MblFetchingLoader mt={0} />    
                :
                    <>
                        {
                            !ocrLoader.data &&
                                <SelectRegType modal={modal} listRegName={listRegName} form={form} ocrLoader={ocrLoader} choices={choices} sufficientStorage={sufficientStorage} insuffShow={insuffShow} errOpen={errOpen} message={message} />
                        }
                        {
                            ocrLoader.data ?
                                <ImgScan form={form} nav={nav} dfltForm={dfltForm} modal={modal} ocrLoader={ocrLoader} />
                            :
                                <Modal open={modal.data.regForm.val}>
                                    <Box height="100%" width="100%" bgcolor="#F8F9FA" display="flex" flexDirection="column">
                                        <Backdrop open={saveLoader.data} sx={{ zIndex: 1101}} />
                                        <Box p="12px 16px" minHeight="86px" width="100%">
                                            <Box display="flex" alignItems="center" width="100%">
                                                <IconButton sx={{p: '0px', minHeight: 20, position: 'absolute'}} onClick={() => handlerCloseUpldReg()}>
                                                    <ArrowLeft sx={{fontSize: 20}} />
                                                </IconButton>
                                                <Box width="100%" display="flex" justifyContent="center" fontSize={16} fontWeight={600} lineHeight="26px">
                                                    Upload Registration
                                                </Box>
                                            </Box>
                                            <Box py="12px" display="flex" justifyContent="space-between" gap="8px">
                                                <Box width="100%" display="flex" alignItems="center" flexDirection="column">
                                                <Box fontSize={12} fontWeight={400} lineHeight="19px" color="#107038"> Details</Box>
                                                <Box height="4px" width="100%" bgcolor="#107038" borderRadius="12px"  />
                                                </Box>
                                                <Box width="100%" display="flex" alignItems="center" flexDirection="column">
                                                <Box fontSize={12} fontWeight={400} lineHeight="19px" color={subparams === 'review' ? "#107038" : "#737373"}> Review</Box>
                                                <Box height="4px" width="100%" bgcolor={subparams === 'review' ? "#107038" : "#737373"} borderRadius="12px" />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box height="100%" width="100%" className='overflowY' bgcolor="#ffffff" borderRadius="12px 12px 0px 0px" px={2} pb={2}>
                                            <Box display="flex" justifyContent="center" my="11px">
                                                <Box height="2px" width="37px" bgcolor="#EAEFF7"/>
                                            </Box>
                                            {
                                                subparams === 'review' ?
                                                    <Review form={form} listRegName={listRegName} />
                                                :
                                                    <Details form={form} dsg={dsg} listRegName={listRegName} subparams={subparams} modal={modal} ocrLoader={ocrLoader} choices={choices} />
                                            }
                                        </Box>
                                        <Box minHeight={76} p={2} bgcolor="#FEFEFE" boxShadow="0px 0px 24px rgba(0, 0, 0, 0.08)">
                                            {
                                                subparams === 'review' ?
                                                    <Box display="flex" alignItems="center" gap="9px">
                                                        <Button variant="contained" fullWidth disableElevation
                                                            sx={{ bgcolor: '#E2EDE7', border: '1px solid #107038', color: '#107038', borderRadius: '6px', '&:hover': { bgcolor: '#E2EDE7', color: '#107038', }  }} 
                                                            onClick={() => handlerBtn('edit')}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <Button variant="contained" fullWidth disableElevation
                                                            sx={{ borderRadius: '6px', zIndex: 1102 }} 
                                                            onClick={() => handlerBtn('save')}
                                                        >
                                                        {
                                                            saveLoader.data ? 
                                                                <Box display="flex" alignItems="center">
                                                                    <Box mr="10px">Saving</Box>
                                                                    <SnakeLoader size="5px" bg="#FFFFFF" distance="0.1rem" />
                                                                </Box>
                                                            :
                                                                'Save'
                                                        }
                                                        </Button>
                                                    </Box>
                                                :
                                                    <Button variant="contained" fullWidth disableElevation 
                                                        sx={{height: '100%', borderRadius: '6px', bgcolor: ValidateBtnNxtReg(form).bgcolor, '&:hover': {bgcolor: ValidateBtnNxtReg(form).bgcolor } }} 
                                                        onClick={() => handlerBtn('next')}
                                                    >
                                                        Next
                                                    </Button>
                                            }
                                        </Box>
                                    </Box>
                                </Modal>
                        }
                        <MbFailedOcrAlrt open={form.data.regFile.file.stat} close={() => GotIt()} />
                    </>
            }
        </>
    )
}