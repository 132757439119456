import { Box, Button, TextField, InputAdornment } from "@mui/material"
import { useHistory } from "react-router-dom"
import { ArrowDown } from "../../../core/global/Icons"
import Add from '../../../assets/images/icons/add.png'
import Pdf  from '../../../assets/images/icons/pdf.png'
import { FormatFilesize } from "../../mycloud/global/DesktopDsg"

export const Left = ({dsg, setOpen, form, setForm, getRootProps, getInputProps, match, plcysn, __SESSION}) => {

    const nav = useHistory()

    const handleClose  = () => {
        if ( atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0') {
            nav.push('/ubr?type=select')
        }else{
            setForm({
                departmentname: {id:0, name:''},
                file_name: '',
                file_size: '',
                file_type: '',
                file_link: '',
                policyname: '',
                description: '',
                dateupload: new Date(),
                dateissued: '',
                preparedby: '',
                approvedby: '',
                file: '',
            });
            nav.push('/policies/list')
        }
    }

    const handleOnClick = () => {
        if (match.params.params === 'create') {
            setOpen(true)
        }   
    }

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center">
            <Button variant="contained"  disableElevation sx={{ width: '80px', p: '12px 16px', fontWeight: 600, color: '#333333', borderColor: '#E6E6E6', borderRadius: '8px', bgcolor: '#F8F9FA', '&:hover': { backgroundColor: '#E2EDE7', color: '#107038', borderColor: '#E2EDE7'}}} onClick={handleClose}> 
                Cancel
            </Button>
            <Box width="100%" display="flex" alignItems="center" mt="40px" mb="32px">
                <Box fontSize="20px" fontWeight={600} lineHeight="26px" pr="12px">Upload Policy</Box>
                <Box fontSize="12px" fontWeight={400} lineHeight="26px" display="flex" justifyContent="center" name="series_num">Policy Number: { atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0' ? 'PLCY-000000001':plcysn.data}</Box>
            </Box>
            <Box width="100%" mt="16px" mb="8px">
                <Box fontSize="14px" fontWeight={600} lineHeight="16px" mb={1}>Department Name*</Box>
                <TextField fullWidth name="departmentname" disabled={match.params.params === 'review' ? true : false}
                    error={form.departmentname === '' ? true : false} value={form.departmentname.name}
                    onClick={()=>handleOnClick() }
                    className={dsg.txtfld}
                    inputProps={{ style: { padding: '14px 16px' } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <ArrowDown stroke="#333333" />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box minHeight="272px" height="272px" bgcolor="#F8F9FA" borderRadius="12px" p={2} mt={4} display="flex" alignItems="center" justifyContent="center" className="overflowY ubr-upload">
                {form.file_name !== '' ? 
                    <Box display="flex" alignItems="center" width="100%"  p="9px 13px" bgcolor="#FFFFFF" borderRadius="6px" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)" > 
                        <img src={Pdf} alt={Pdf} />
                        <Box display="flex" flexDirection="column" ml="13px"> 
                            <Box color="#333333" fontWeight={600} fontSize={12} lineHeight="16px" flexWrap="wrap"> {form.file_name} </Box>
                            <Box color="#737373" fontWeight={400} fontSize={10} lineHeight="16px"> {FormatFilesize(form.file_size)} </Box>
                            
                        </Box>
                    </Box>
                :
                    <Box {...getRootProps({className: 'dropzone'})} border="2px dashed #D9D9D9" height="100%" width="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column" className="c-pointer">
                        <img src={Add} alt={Add} />
                        <Box mt={5} fontSize={14} fontWeight={400} lineHeight="19px">Drag and drop a pdf file, or Browse</Box>
                        <Box mt="12px" fontSize={12} fontWeight={400} lineHeight="19px">Maximum file size is 3 MB.</Box>
                        <input {...getInputProps()} />
                    </Box>
                }
            </Box>
            {
                (form.file_name !== '' && match.params.params !== 'review') &&  
                    <Box display="flex" justifyContent="center" mt={3}>
                        <Button {...getRootProps({className: 'dropzone'})}
                            sx={{bgcolor: '#F8F9FA', border: '1px solid #E6E6E6', color: '#333333', p: '12px 16px', lineHeight: '16px', borderRadius: '6px'}}
                        >
                            Change
                        </Button>
                    </Box>
            }
        </Box>
    )
}