import { Avatar, Box, IconButton, Modal, Slide } from '@mui/material'
import { ArrowLeft, VeriticalThreeDots } from '../../../../../core/global/Icons'
import { FormatFilesize } from '../../../global/DesktopDsg'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { Preview } from './modal/Preview'
import moment from 'moment'

export const FileInfo = ({show, handlerThreeDots}) => {
    const reg = [
        {name: 'Registration Number', val: show.data.data.series_num},
        {name: 'Registration Name', val: show.data.data.registration_name},
        {name: 'Registration Type', val: show.data.data.type !== 0 ?'Expiring' : 'Non-Expiring'},
        {name: 'Registered Name', val: show.data.data.registered_name},
        {name: 'Reference Number', val: show.data.data.reference_num},
    ]

    const file_info = [
        {name: 'File Type', val: show.data.data.file_type},
        {name: 'File Size', val: FormatFilesize(show.data.data.file_size)},
    ]
    let validity_date = `${moment(show.data.data.start_date).format('MM/DD/YYYY')} to ${moment(show.data.data.end_date).format('MM/DD/YYYY')}`

    const handlerImgView = (e, val) => {
        if (e.detail >= 1) {
            show.set({...show.data, view:true, fullview:true, data: val})
        }
    }
    return (
        <>
            <Modal open={show.data.view} sx={{zIndex: 1302}} className="noutlined">
                <Slide in={show.data.view} direction="left" className="noutlined">
                    <Box height="100%" width="100%" display="flex" flexDirection="column" bgcolor="#F8F9FA">
                        <Box minHeight={44} p="12px 16px" display="flex" alignItems="center" justifyContent="space-between">
                            <IconButton sx={{p: '0px'}} onClick={() => show.set({...show.data, view : false, data : ''})}>
                                <ArrowLeft stroke="#313131" width="16" height="16" />
                            </IconButton>
                            <Box fontSize={16} fontWeight={600} lineHeight="26px">Registration Details</Box>
                            <IconButton sx={{p: '0px'}} onClick={(e) => handlerThreeDots(e, show.data.data)}>
                                <VeriticalThreeDots fill={"#737373"} />
                            </IconButton>
                        </Box>
                        <Box p="12px 16px 24px 16px" minHeight={216} className="overflowY overflowx c-pointer">
                            <Box bgcolor="#FFFFFF" minHeight={156} p="24px 24px 0px 24px" onClick={(e) => handlerImgView(e,show.data.data)} display="flex" justifyContent="center" alignItems="center" >
                                {
                                    show.data.data.file_type.includes('image') ?
                                        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                                            <Avatar src={show.data.data.file_link} alt={show.data.data.file_link} sx={{height:100, width:'auto', borderRadius:'8px'}}/>
                                        </Box>
                                    : 
                                        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                            <Box sx={{height: 216, width:'100%', borderRadius:'8px 8px 0px 0px'}}>
                                                <Box component={Viewer} fileUrl={show.data.data.file_link} />
                                            </Box>
                                        </Worker>
                                }
                            </Box>
                        </Box>
                        <Box height="100%" width="100%" bgcolor="#FFFFFF" borderRadius="12px 12px 0px 0px" p={2} className='overflowY'>
                            <Box display="flex" justifyContent="center">
                                <Box height="2px" width="37px" bgcolor="#333333" />
                            </Box>
                            <Box fontSize={16} fontWeight={600} lineHeight="25px" pt="20px" pb="32px">
                                {show.data.data.registration_name} ({show.data.data.registered_name})
                            </Box>
                            <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={2}>Registration Information</Box>
                            {
                                reg.map((v,k) => (
                                    <Box width="100%" display="flex" alignItems="baseline" mb={1} gap="4px" key={k}>
                                        <Box width="50%" color="#737373" fontSize={14} fontWeight={400} lineHeight="19px">
                                            {v.name}
                                        </Box>
                                        <Box width="50%" color="#333333" fontSize={14} fontWeight={400} lineHeight="19px" sx={{wordBreak: 'break-word'}}>
                                            {v.val}
                                        </Box>
                                    </Box>
                                ))
                            }
                             {
                                show.data.data.start_date !== null && show.data.data.end_date !== null &&
                                    <Box width="100%" display="flex" alignItems="baseline" mb={3} fontSize={14} fontWeight={400} lineHeight="19px">
                                        <Box width="50%" color="#737373">Validity Date</Box>
                                        <Box width="50%" color="#333333">{validity_date}</Box>
                                    </Box>
                            }
                            <Box fontSize={14} fontWeight={600} lineHeight="16px" mt={2} mb={2}>File Info</Box>
                            {
                                file_info.map((v,k) => (
                                    <Box width="100%" display="flex" alignItems="baseline" mb={1} gap="4px" key={k}>
                                        <Box width="50%" color="#737373" fontSize={14} fontWeight={400} lineHeight="19px">{v.name}</Box>
                                        <Box width="50%" color="#333333" fontSize={14} fontWeight={400} lineHeight="19px" sx={{wordBreak: 'break-all'}}>{v.val}</Box>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                </Slide>
            </Modal>
            {show.data.fullview && (
                <Preview show={show} handlerThreeDots={handlerThreeDots} />
            )}
        </>
    )
}
