import { Box, Divider, Menu, MenuItem } from "@mui/material";
import { Download, DownloadPDF } from '../.../../../../../../core/transmit/S3';
import imgDL from '../../../../../assets/images/icons/imgDl.png';
import pdfDL from '../../../../../assets/images/icons/pdfDl.png';

export const DL = ({showDl, show, active, successOpen, message}) => {

    const handlerDL = async(e, type) => {
        let parse = JSON.parse(show.data.data.file)
        
        for (let i = 0; i < parse.length; i++) {
            let res;
            if (type === 'pdf') {
                if (parse[i].file_type.includes('image')) {
                    res = await DownloadPDF(parse[i].file_link, parse[i].file_name)       
                }else{
                    res = await Download(e, parse[i].file_link, parse[i].file_name)
                }
            }else{
                res = await Download(e, parse[i].file_link, parse[i].file_name)
            }
            if (res === 'success' && i === parse.length - 1 ) {
                showDl.set(null)
                active.set({...active.data, val : '', openMenu : null})
                message.set('Successfully download')
                successOpen.set(true)
            }
        }
    }

    return (
        <Menu open={Boolean(showDl.data)} anchorEl={showDl.data} onClose={() => showDl.set(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            <Box px={1} minWidth={240}>
                <MenuItem sx={{minHeight: '44px !important', py: 0}} onClick={(e) => handlerDL(e, 'pdf')}>
                    <img src={pdfDL} alt={pdfDL} />
                    <Box ml="12px" fontSize={14} fontWeight={400}> PDF </Box>
                </MenuItem>
                <Divider sx={{my: '0px !important'}} />
                <MenuItem sx={{minHeight: '44px !important', py: 0}} onClick={(e) => handlerDL(e, 'img')}>
                    <img src={imgDL} alt={imgDL} />
                    <Box ml="12px" fontSize={14} fontWeight={400}> Image </Box>
                </MenuItem>
                <Divider sx={{my: '0px !important'}} />
            </Box>
        </Menu>
    )
}
