import { createContext } from 'react';
import { InstallState } from './InstallState';
import { ToolStates } from './ToolStates';
import { NavigationStates } from './NavigationStates';
import { HomeStates } from './HomeStates';
import { StatisticsState } from './StatisticsState';
import { ToolCategoryState } from './ToolCategoryState';
import { ProfileState } from './ProfileState';
import { SubdivisionState } from './SubdivisionState';
import { PersonalizationState } from './PersonalizationSate';
import { BannerState } from './BannerState';
import { SwitchingState } from './SwitchingState';
import { GenMainState } from './GenMainState';
import { MyCloudState } from './MyCloudState';
import { NotifState } from './NotifState';
import { UserRoleState } from './UserRoleState';
import { MasterListState } from './MasterListState';
import { PoliciesState } from './PoliciesState';
import { UbrState } from './UbrState';
import { SpeakOutState } from './SpeakOutState';

export const ToolContext = createContext();

export const ToolProvider = ({ children }) => {
    let states = {
        install_state: InstallState(),
        tool_state: ToolStates(),
        home_state: HomeStates(),
        navigation_state: NavigationStates(),
        statistics_state: StatisticsState(),
        tool_category_state: ToolCategoryState(),
        profile_state: ProfileState(),
        subdivision_state: SubdivisionState(),
        personalization_state: PersonalizationState(),
        banner_state: BannerState(),
        switching_state: SwitchingState(),
        gen_main_state: GenMainState(),
        cloud_state: MyCloudState(),
        notif_state: NotifState(),
        user_role_state: UserRoleState(),
        master_list_state: MasterListState(),
        policy_state: PoliciesState(),
        ubr_state: UbrState(),
        speakout_state: SpeakOutState()
    }

    return (
        <ToolContext.Provider value={{...states}}>
            {children}
        </ToolContext.Provider>
    )
}