import { useContext, useEffect, useState } from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { ToolContext } from '../../../../core/context/ToolContext'
import { List } from './List';
import { NewAddRoundIcon } from "../../../../core/global/GMicons";
import { ArrowDown, GridViewIcon, ListViewIcon } from "../../../../core/global/Icons";
import { __InitRecentList } from '../../global/Function';
import { useHistory } from "react-router-dom";
import { ShareAccess } from "../share/Index";

export const Recent = ({match}) => {
    const { cloud_state, tool_state, notif_state } = useContext(ToolContext)
    const { listView, rcntList, starActive, isLoading, addMenu, show, regActive, conActive, inftScroll, successOpen, message, sufficientStorage, insuffShow, openShare, withAccess, shareType, showDl } = cloud_state
 
    const { __SESSION } = tool_state
    const nav = useHistory()

    const handlerMenu = (e) => {
        if (sufficientStorage.data) {
            insuffShow.set(true)
        }else{
            addMenu.set(e.currentTarget)
        }
    }

    const HandlerRedirect = (type) => {
        if (type === 'regs') {
            nav.push('/my-cloud/registrations/create')
        }else{
            nav.push('/my-cloud/contracts/create')
        }
        addMenu.set(null)
    }

    const [subscribe, setSubscribe] = useState(true)
    useEffect(() => {
        if (subscribe) {
            listView.set(false)
            __InitRecentList(isLoading, __SESSION, rcntList)
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [])
  
    return (
        <Box display="flex" flexDirection="column" height="100%">
            <Box minHeight={110}>
                <Box p="24px 40px 8px 40px" color="#333333" fontWeight={600} fontSize={20} lineHeight="26px">
                    Recently Added
                </Box>
                <Box height="1px" bgcolor="#E6E6E6" />
                <Box p="8px 40px" display="flex" justifyContent="space-between" alignItems="center">
                    <Button variant="contained" sx={{p: '10px 30px', height: 36, borderRadius: '6px', bgcolor: '#107038'}} onClick={(e) => handlerMenu(e)}> 
                        <NewAddRoundIcon width="20" height="20" />
                        <Box fontSize={14} fontWeight={600} lineHeight="16px" mx="15px">Add</Box>
                        <ArrowDown />
                    </Button>
                       
                    <Box p={1} bgcolor="#FFFFFF" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.08)" borderRadius="4px" width={36} height={36} className="c-pointer" onClick={() => listView.set(!listView.data)}>
                        { listView.data ? <ListViewIcon /> : <GridViewIcon /> } 
                    </Box>
                </Box>
                <Box height="1px" bgcolor="#E6E6E6" />
            </Box>
            <List rcntList={rcntList} isLoading={isLoading} regActive={regActive} starActive={starActive} listView={listView} show={show} 
                conActive={conActive} inftScroll={inftScroll} __SESSION={__SESSION} match={match.feature} successOpen={successOpen} message={message} showDl={showDl} __InitRecentList={__InitRecentList} openShare={openShare} withAccess={withAccess} shareType={shareType} notif_state={notif_state}
            />
            <Menu open={Boolean(addMenu.data)} anchorEl={addMenu.data} onClose={() => addMenu.set(null)} PaperProps={{ sx: { width: '200px'} }}>
                <MenuItem onClick={() => HandlerRedirect('regs')}>Registration</MenuItem>
                <MenuItem onClick={() => HandlerRedirect('cons')}>Contracts</MenuItem>
            </Menu>
            {
                openShare.data &&
                    <ShareAccess match={match.feature} />
            }
        </Box>
    )
}
