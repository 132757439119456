import { lazy, useContext, useEffect, useState } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { MDocu, Mgraph, MSystemCons, Wave1 } from '../../../../core/global/Icons';
import { ToolContext } from '../../../../core/context/ToolContext';
import { Banner } from './Banner';
import { Summary } from './summary/Index';
import { VM } from '../../../../core/VM';
import { NoGraphicalReportFound } from '../../../empty/Index';
import { Document } from './reports/Document';
import { SystemConcerns } from './reports/SystemConcerns';
import { useHistory } from 'react-router-dom';
import { rqx } from '../../../../core/request/API';
import { env } from '../../../../core/Env';

const device = VM();
const Graph = lazy(() => import(`../../chart${device}/Index`))
const MyTool = lazy(() => import(`../../../mytools${device}/Index`))

const Home = ({linkacct}) => {
    const { tool_state, install_state, navigation_state, home_state, notif_state } = useContext(ToolContext)
    const { __SESSION } = tool_state
    const { tab, trigger, badgeCount } = notif_state
    const [subscribe, setSubscribe] = useState(true)

    const nav = useHistory()
  
    const [reports, setReports] = useState([
        { name: 'Tool Graphical Report', icon:  <Mgraph /> },
        { name: 'Documents', icon: <MDocu /> },
        { name: 'System Concerns', icon: <MSystemCons /> },
    ])

    const clickFeature = async(name) =>{
        if (name === 'my-profile') {
            nav.push('/my-profile')
        }else if (name === 'MyTools') {
            navigation_state.showMT.set(true)
        }else if (name === 'Tool Graphical Report') {
            home_state.showGraph.set(true)
        }else if (name === 'Documents') {
            home_state.showDocu.set(true)
        }else if (name === 'System Concerns') {
            home_state.showSysCons.set(true)
        }else if (name === 'Notifications'){
            tab.set('unread')
            nav.push('/notification')
            if (badgeCount.data > 0) {
                await badgeCount.set(0)
                await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/notification/update`, {
                    user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1], 
                    ref:'notifcount'
                })
            }
        }
    }
    
    const currentDayStatus = () => {
        const dateToday = new Date()
        const hours = dateToday.getHours()
        let dayStatus;
        if (hours < 12 ) {
            dayStatus = 'Morning'
        }else if (hours < 18) {
            dayStatus = 'Afternoon'
        }else{
            dayStatus = 'Evening'
        }
        return dayStatus;
    }

    const __init = async() => {
        let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/notification/notifcount`, {
            user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1], 
        })
        let c
        if (res.length > 0) {
            c = res[0].count
        }else{
            c = 0
        }
        badgeCount.set(c)
    }

    useEffect(() => {
        if (subscribe) {
            __init()
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [trigger.data])

    useEffect(() => {
        if (subscribe) {
            if (__SESSION.data.uaccess[0].role_id === parseInt(process.env.REACT_APP_UAI_REGULAR)) {
                setReports(
                    reports.filter(v => {return v.name !== 'Documents'})
                )
            }
        }
        return () => setSubscribe(false)
       // eslint-disable-next-line
    }, [])
    
	return (
        <Box height="100%" width="100%" bgcolor='primary.main'>
            <Container sx={{height:'100%', width:'100%', bgcolor:'#F1F4F4', borderRadius:"12px 12px 0px 0px", p:'16px'}}>
                <Box height="100%" width="100%">
                    <Box width="100%" display="flex" justifyContent="center">
                        <Box bgcolor="#D9D9D9" height="2px" width="37px" />
                    </Box>
                    <Box display="flex" alignItems="baseline" fontSize="12px" fontWeight={600} color="#333333" pt="12px">
                        <Box fontSize="14px" color="#333333">Extraordinary {currentDayStatus()},</Box>
                        <Box fontWeight={400}>{tool_state.__SESSION.data.pinfo.length === 0 ? 'Hero ' : tool_state.__SESSION.data.pinfo[0].first_name } </Box>
                        <Box pl="16px"><Wave1 fill='#FFCB22'/></Box>
                    </Box>
                    <Banner linkacct={linkacct} />
                    <Summary />
                    <Box width="100%" bgcolor="#FFFFFF" p={2} display="flex" justifyContent="space-around" alignItems="baseline">
                        {reports.map((v, k) => (
                            <Button sx={{display:"flex", flexDirection:"column", alignItems:"center", p: '0px !important'}} key={k} onClick={() => clickFeature(v.name)}>
                                {v.icon}
                                <Typography fontSize={10} fontWeight={500} textAlign="center" color="#333333">{v.name}</Typography>
                            </Button>
                        ))}
                    </Box>
                    <Box minHeight={install_state.prompt.data !== null ? "76px" : "12px"} />
                </Box>
                {navigation_state.showMT.data && <MyTool />} 
                {home_state.showGraph.data && <Graph selectTool={home_state.selectTool.data} toolOwned={tool_state.toolOwned.data} NoGraphicalReportFound={NoGraphicalReportFound}/>}
                {home_state.showDocu.data && <Document hs={home_state} __SESSION={__SESSION.data.uaccess} nav={nav} />} 
                {home_state.showSysCons.data && <SystemConcerns hs={home_state} />} 
            </Container>
        </Box>
		// <Box height="100%" width="100%" display="flex" flexDirection="column" >
        //     <Box minHeight="100px" bgcolor="primary.main">
        //         <Box minHeight="40px" display="flex" justifyContent="space-between" alignItems="center" py="8px" px="16px">
        //             <Box display="flex" alignItems="center" gap="16px">
        //                 <Logo size={24} />
        //                 <Box fontSize={16} fontWeight={600} lineHeight="24px" color="#FFFFFF">Business</Box>
        //             </Box>
        //             <Box display="flex" alignItems="center" gap="16px">
        //                 <SearchIcons size={20} stroke="#FFFFFF"/>
        //                 <ToolTip title={'Chat'} arrow sx={{margin: '0 14px 0 14px'}} onClick={() => clickFeature('Chat')}>
        //                     <Avatar variant='rounded' src={ChatIcon} sx={{height:24, width:24}} className="c-pointer" />
        //                 </ToolTip>
        //                 {navigation_state.tabs.data.mtop.map((v,k) => (
        //                     <IconButton sx={{p: '0px !important'}} key={k} onClick={() => clickFeature(v.name)}> 
        //                         <Badge badgeContent={v.name === 'Notifications' ? badgeCount.data : 0} sx={{ "& .MuiBadge-badge": { color: "#ffffff", backgroundColor: "#FF0000"} }}>
        //                             {v.not_active}
        //                         </Badge>
        //                     </IconButton>
        //                 ))}
        //             </Box>
        //         </Box>
        //         <Box height="52px">
        //             <Platform __SESSION={__SESSION.data} install_state={install_state} />
        //         </Box>
        //     </Box>
        //     <Box height="100%" width="100%" mt={-.8} bgcolor="#F1F4F4" borderRadius="12px 12px 0px 0px">
        //         <Box width="100%" display="flex" justifyContent="center" pt="12px">
        //             <Box bgcolor="#D9D9D9" height="2px" width="37px" />
        //         </Box>
        //         <Box display="flex" alignItems="baseline" fontSize="12px" fontWeight={600} color="#333333" p="16px">
        //             <Box fontSize="14px" color="#333333">Extraordinary {currentDayStatus()},</Box>
        //             <Box fontWeight={400}>{tool_state.__SESSION.data.pinfo.length === 0 ? 'Hero ' : tool_state.__SESSION.data.pinfo[0].first_name } </Box>
        //             <Box pl="16px"><Wave1 fill='#FFCB22'/></Box>
        //         </Box>
        //         <Banner linkacct={linkacct} />
        //         <Summary />
        //         <Box width="100%" bgcolor="#FFFFFF" p={2} display="flex" justifyContent="space-around" alignItems="baseline">
        //             {reports.map((v, k) => (
        //                 <Button sx={{display:"flex", flexDirection:"column", alignItems:"center", p: '0px !important'}} key={k} onClick={() => clickFeature(v.name)}>
        //                     {v.icon}
        //                     <Typography fontSize={10} fontWeight={500} textAlign="center" color="#333333">{v.name}</Typography>
        //                 </Button>
        //             ))}
        //         </Box>
        //         <Box minHeight="12px" />
        //     </Box>
        //     {navigation_state.showMT.data && <MyTool />} 
        //     {home_state.showGraph.data && <Graph selectTool={home_state.selectTool.data} toolOwned={tool_state.toolOwned.data} NoGraphicalReportFound={NoGraphicalReportFound}/>}
        //     {home_state.showDocu.data && <Document hs={home_state} __SESSION={__SESSION.data.uaccess} nav={nav} />} 
        //     {home_state.showSysCons.data && <SystemConcerns hs={home_state} />} 
        // </Box>        
	);
}
export default Home;