import { Avatar, Box, IconButton, Modal } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

export const List = ({ open, setOpen, pModal }) => {

    const handleClose = () => {
        setOpen(false);
    }
    return (
        <Modal open={open}>
            <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="row">
                <Box height={439} width={450} bgcolor="#FFFFFF" borderRadius="12px" >
                    <Box minHeight="32px" width="100%" textAlign="right" p="16px 16px 0px 0px" >
                        <IconButton sx={{p: '0px !important', m: '0px !important'}} onClick={() => handleClose()}>
                            <CloseIcon sx={{color: '#313131'}} className="c-pointer"/>
                        </IconButton>
                    </Box>
                    <Box height="100%" width="100%" display="flex" flexDirection="column" p="0px 32px 32px 32px">
                        <Box minHeight="60px" width="100%">
                            <Box fontSize="20px" fontWeight={600} lineHeight="16px">Employee Agree List</Box>
                        </Box>
                        <Box height="100%" className="overflowY cstmscroll5" >
                            <Box display="flex" flexDirection="column" gap="16px">
                            {
                                pModal.agreeinfo?.map((v,k) => (
                                    <Box display="flex" alignItems="center" flexDirection="row" gap="12px" key={k}>
                                        <Avatar src={v.profile} alt="profile" sx={{width: 32, height: 32}}/>
                                        <Box fontSize="14px" fontWeight={400} lineHeight="24px">{v.uname} </Box>
                                    </Box>
                                ))
                            }
                            </Box>
                        </Box>
                        <Box minHeight="40px">
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}