import { NotFoundPage } from '../empty/Index'
import { Index as Setup } from './setup/Index'
// import { List } from './List'
import { Index as List } from './list/Index'
import { Box, Button, Container } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { rqx } from '../../core/request/API'
import { env } from '../../core/Env'
import { ToolContext } from '../../core/context/ToolContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { NewAddRoundIcon } from '../../core/global/GMicons'
import { PermissionModal } from '../mycloud/global/DesktopDsg'
import { VM } from '../../core/VM'
import { MobIndex } from './devices/mobile/Index'

let uai_user = process.env.REACT_APP_UAI_REGULAR;

export const Policies = ({match}) => {

    const { tool_state, policy_state } = useContext(ToolContext)
    const { __SESSION } = tool_state 
    const { isLoading } = policy_state

    let company_id = __SESSION.data.uaccess[0].company_id;
    let owner_id = __SESSION.data.uaccess[0].owner_id;

    let view = VM()
    const nav = useHistory()
    const [isShow, setisShow] = useState(false)
    const [deptStaticData, setDeptStaticData] = useState([])

    const handleOpen = () => {
        nav.push('/policies/create')
    }

    const handleProceed = async(type) => {
        await setisShow(false)
        setTimeout(() => {
            if (type === 'cancel') {
                nav.push('/')
            } else {
                nav.push('/general-maintenance/company-setup')
            }
        }, 200);
    }
    useEffect(() => {
        if (company_id === null) {
            setisShow(true)
        }
        const __init = async() => {
            let res = await rqx.get(`<<tool>>/${env()}/general-maintenance/masterlist/department/infinite`, {
                lastId: 0,
                limit: 100,
                company_id: company_id !== null ? company_id : 0,
                user_id: owner_id
            })
            setDeptStaticData([{id:0, name:'All'}, ...res])
        }
        __init()
        // eslint-disable-next-line
    }, [])
    // console.log(deptStaticData)

    return (
        view === '' ?
            <Container sx={{height: "100%", width:"100%", display: "flex", flexDirection: "column"}}>
                <Box minHeight="150px">
                    <Box fontSize={20} fontWeight={500} py="24px">Policies</Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        {__SESSION.data.uaccess[0].role_id !== parseInt(uai_user) && (
                            <Button variant="contained" sx={{p:  '10px 16px', height: 36, borderRadius: '6px', bgcolor: '#107038'}} onClick={handleOpen}>
                                <NewAddRoundIcon width="20" height="20"/>
                                <Box fontSize={14} fontWeight={600} lineHeight="16px" mx="15px">Upload</Box>
                            </Button>
                            )
                        }
                    </Box>
                    <Box  lineHeight="1.5px" sx={{border: ".1px solid #E6E6E6"}} m="16px 0px 32px 0px"></Box>
                </Box>
                <Box height="100%" sx={{overflowY:'hidden'}}>
                    <Components match={match} deptStaticData={deptStaticData} isLoading={isLoading}/>
                </Box>
                <Box minHeight="30px"></Box>
                {atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0' && (
                    <PermissionModal show={isShow} handleProceed={handleProceed} type="policies"/>
                )}
            </Container>
        :
            deptStaticData.length > 0 &&
            <MobIndex deptStaticData={deptStaticData} isLoading={isLoading} match={match}/>
        
    )
}


const Components = ({match, deptStaticData, isLoading}) => {
    
    if (match.params.params === 'list') {   
        return deptStaticData.length > 0 && <List match={match} deptStaticData={deptStaticData} isLoading={isLoading}/>
    }else if (match.params.params === 'create' || match.params.params === 'review') {
        return <Setup match={match} deptStaticData={deptStaticData}/>
    }else{
        return <NotFoundPage/>
    }
}