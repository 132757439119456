import { Box, Button, Divider } from "@mui/material"
import { NewAddRoundIcon } from "../../../../core/global/GMicons"
import { ArrowDown, GridViewIcon, ListViewIcon } from "../../../../core/global/Icons"
import { useContext, useEffect } from "react"
import { ToolContext } from "../../../../core/context/ToolContext"
import { List } from './list/Index'
import { NewFolder } from "./modal/NewFolder"
import { ErrorAlert, NewSuccessAlert } from '../../../../core/global/forms/CstmModal'
import { Uploading } from './modal/Uploading' 
import { ThreeDots } from "./menu/ThreeDots"
import { DownloadList } from './menu/Download'
import { ShareAccess } from '../share/Index'
import { NotFoundPage } from "../../../empty/Index"
import { rqx } from "../../../../core/request/API"
import { env } from "../../../../core/Env"
import { useDropzone } from "react-dropzone"
import { MenuAdd } from "./menu/AddBtn"
import { useHistory } from "react-router-dom"
import { ValidateMaxFile } from "./modal/ValidateMaxFile"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const Documents = ({match}) => {
    const { cloud_state, tool_state } = useContext(ToolContext)
    const urlParams = new URLSearchParams(window.location.search)
    const nav = useHistory()
    const { listView, addMenu, successOpen, message, selectedFiles, uploading, show, active, showDl, openShare, shareType, rename, folderInfi, folderLoader, folderLid, docuFolder, fileInfi, fileLoader, fileLid, docuFile, openMaxFile, errOpen, accessLoader } = cloud_state
    const { __SESSION } = tool_state
    let user_id = atob(__SESSION.data.ainfo.aid).split('_', 2)[1];
    let company_id = __SESSION.data.uaccess[0].company_id
    let uai_user = process.env.REACT_APP_UAI_REGULAR;
   
    const handlerAdd = (e) => {
        addMenu.set(e.currentTarget)
    }

    const __InitDocuFile = async(val) => {
        if (val === 0) {
            fileLoader.set(true)
            fileInfi.set(true)
        }
        let qsp = { 
            feature: match.feature,
            lastId: val, 
            limit: 10, 
            user_id: user_id, 
            ref: 'file', 
            user_type: __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? 'admin' : 'regular_user', 
            company_id: company_id, 
            parent_id: match.params === 'list' ? '' : window.atob(urlParams.get('f_id')), 
            status: 1
        }

        let d_file = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/documents/read`, qsp)
        let res = d_file.map(v => { return {...v, feature_type: 4, currFname:v.file_name } } )
        if (d_file) {
            let l_id;
            for (let i = 0; i < d_file.length; i++) {
                l_id = d_file[i].id
            }
            fileLid.set(l_id)
            if (val === 0 || val === undefined || val === '') {
                docuFile.set(res)
            }else{
                docuFile.set([...docuFile.data, ...res])
            }
            if (d_file.length === 0) {
                fileLoader.set(false)
                fileInfi.set(false)
                return
            }
            fileLoader.set(false)
        }
    }
    
    const __InitDocuFolder = async(val) => {
        if (val === 0) {
            folderLoader.set(true)
            folderInfi.set(true)
        }
        let qsp = { 
            feature: match.feature,
            lastId: val, 
            limit: 10, 
            user_id: user_id, 
            ref: 'folder', 
            parent_id: match.params === 'list' ? '' : window.atob(urlParams.get('f_id')), 
            user_type: __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? 'admin' : 'regular_user', 
            company_id: company_id, 
            status: 1 
        }
            
        let d_folder = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/documents/read`, qsp)
        let res = d_folder.map(v => { return {...v, feature_type: 3, currFname:v.folder_name} } )
        if (d_folder) {
            let l_id;
            for (let i = 0; i < d_folder.length; i++) {
                l_id = d_folder[i].id
            }
            folderLid.set(l_id)
            if (val === 0 || val === undefined || val === '') {
                docuFolder.set(res)
            }else{
                docuFolder.set([...docuFolder.data, ...res])
            }
            if (d_folder.length === 0) {
                folderLoader.set(false)
                folderInfi.set(false)
                return
            }
            folderLoader.set(false)
        }
    }

    const onDrop = async(files) => {
        let totalFileSize = 0;  
        for (let i = 0; i < files.length; i++) {
            totalFileSize += parseInt(files[i].size)
        }
        if (totalFileSize > 25 * 1024 * 1024) {
            openMaxFile.set(true)
        }else{
            if (files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    selectedFiles.set((prev) => prev.concat({progress: 0, file_name: files[i].name, file_type: files[i].type, file_size: files[i].size, link: URL.createObjectURL(files[i]), root: Array(files[i]) }))
                }
                addMenu.set(null)
                uploading.set({stat:true, type:'file'})
            }
        }
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, noClick: true})
    
    const handlerBack = () => {
        nav.push(`/my-cloud/${match.feature}/list`)
    }

    const handlerFileBack = (v) => {
        //SUBFNAME
        let subfname = urlParams.get('subfname')
        let indx = subfname.indexOf(v)
        let subfnameUrl = subfname.slice(0, indx+v.length)
        //SUBFID
        let subfid = urlParams.get('subfid')
        let arrSubfname = subfnameUrl.split('/').length
        let arrSubfidFltr = subfid.split('/').filter((i,k) => { return k + 1 === arrSubfname })
        let indxfid = subfid.indexOf(arrSubfidFltr[0])
        let fnal = subfid.slice(0, indxfid+arrSubfidFltr[0].length)
        
        nav.push(`/my-cloud/${match.feature}/folder?subfname=${subfnameUrl}&subfid=${fnal}&f_id=${arrSubfidFltr[0]}&f_name=${v}`)
    }

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (match.params === 'folder' && urlParams.get('f_id') === null) {
                nav.push(`/my-cloud/${match.feature}/list`)
            }else{
                if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') {
                    __InitDocuFolder(0)
                    __InitDocuFile(0)
                }else{
                    fileLoader.set(false)
                    fileInfi.set(false)
                    folderLoader.set(false)
                    folderInfi.set(false)
                }
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line 
    }, [urlParams.get('f_id'), match.params, match.feature, accessLoader.data])
  
    return (
        <Box display="flex" flexDirection="column" height="100%" width="100%">
            <Box minHeight={110}>
                <Box px={5} pt={3} pb={1} fontSize={20} fontWeight={600} lineHeight="26px" display="flex" alignItems="center" gap="12px">
                    <Box color={urlParams.get('subfname') !== null ? "#A2A3A9" : "#333333"} onClick={() => handlerBack()} className="c-pointer" sx={{':hover':{color:`#333333`}}}>
                        {match.feature === 'shared-access' ? 'Shared Documents' : 'My Documents'}
                    </Box>
                    <Box display="flex" flexWrap="wrap">
                        {
                            urlParams.get('subfname') !== null &&
                                urlParams.get('subfname').split('/').map((v,k, d) => (
                                    <Box display="flex" alignItems="center" key={k} flexWrap="wrap" >
                                        <KeyboardArrowRightIcon sx={{fontSize: 20}} />
                                        <Box onClick={() => handlerFileBack(v)} 
                                        color={k === d.length - 1 ? "#333333" : "#A2A3A9"} 
                                        sx={{':hover':{color:`#333333`}}}
                                        className="c-pointer">{v}</Box>
                                    </Box>
                                ))
                        }
                    </Box>
                </Box>
                <Divider />
                {match.feature === 'documents' && (
                    <Box py={1} px={5} display="flex" alignItems="center" justifyContent="space-between">
                        <Button variant="contained" sx={{p: '10px 16px', height: 36, borderRadius: '6px', bgcolor: '#107038'}} onClick={(e) => handlerAdd(e)}> 
                            <NewAddRoundIcon width="20" height="20" />
                            <Box fontSize={14} fontWeight={600} lineHeight="16px" mx="15px">Add</Box>
                            <ArrowDown />
                        </Button>
                        <Box p={1} bgcolor="#FFFFFF" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.08)" borderRadius="4px" width={36} height={36} className="c-pointer" onClick={() => listView.set(!listView.data)}>
                            { listView.data ? <ListViewIcon /> : <GridViewIcon /> } 
                        </Box>
                    </Box>
                )}
                <Divider />
            </Box>
            <Box height="100%" className="overflowY noutlined">
                <Features match={match} __InitDocuFolder={__InitDocuFolder} getRootProps={getRootProps} getInputProps={getInputProps} isDragActive={isDragActive} __InitDocuFile={__InitDocuFile} />
            </Box>
            <NewFolder __SESSION={__SESSION} cloud_state={cloud_state} user_id={user_id} company_id={company_id} __InitDocuFolder={__InitDocuFolder} /> {/* MODAL ADD NEW FOLDER */}
            {openShare.data && <ShareAccess match={match.feature} /> }
            <Uploading uploading={uploading} selectedFiles={selectedFiles} user_id={user_id} show={show} /> {/* UPLOADING FILE MODAL */}
            {active.data.type !== undefined && (
                <ThreeDots active={active} showDl={showDl} show={show} openShare={openShare} shareType={shareType} rename={rename} message={message} successOpen={successOpen} />  
            )}{/* THREEDOTS MENU LIST  */}
            <DownloadList show={show} showDl={showDl} active={active} successOpen={successOpen} message={message} /> {/* DOWNLOAD MENU LIST */}
            <MenuAdd __SESSION={__SESSION} cloud_state={cloud_state} user_id={user_id} company_id={company_id} getRootProps={getRootProps} getInputProps={getInputProps} __InitDocuFile={__InitDocuFile} match={match}  __InitDocuFolder={__InitDocuFolder} openMaxFile={openMaxFile} message={message} successOpen={successOpen} errOpen={errOpen}/> {/*  ADD BUTTON DOCUMENTS MENU LIST */}
            <NewSuccessAlert successOpen={successOpen.data} setSuccessOpen={successOpen.set} text={message.data} /> {/*  SNACKBAR MESSAGE SUCCESS */}
            <ErrorAlert errOpen={errOpen.data} setErrOpen={errOpen.set} text={message.data} /> {/*  SNACKBAR MESSAGE ERROR */}
            <ValidateMaxFile openMaxFile={openMaxFile} /> {/*  MAXIMUM FILE MODAL MESSAGE */}
        </Box>

    )
}

const Features = ({match, __InitDocuFolder, getRootProps, getInputProps, isDragActive, __InitDocuFile}) => {
    if (match.params === 'list' || match.params === 'folder') {
        return <List match={match} __InitDocuFolder={__InitDocuFolder} getRootProps={getRootProps} getInputProps={getInputProps} isDragActive={isDragActive} __InitDocuFile={__InitDocuFile} /> /* DOCUMENT LIST FOLDER AND FILES */
    }else{
        return <NotFoundPage />
    }
}