import { Box, Skeleton } from '@mui/material'
import { UseStyles } from '../../../global/DesktopDsg'

export const LoaderFolder = () => {
    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column" >
            <Box height="100%" display="flex" flexDirection="column">
                <Box height="100%">
                    <Box display="flex" alignItems="center" gap="20px" flexWrap="wrap">
                        {
                                ['',''].map((v,k) => (
                                    <Box width={273} height={64} display="flex" alignItems="center" justifyContent="space-between"  className="c-pointer" key={k}>
                                        <Skeleton variant='rectangular' height="100%" width="100%" sx={{borderRadius: '6px'}} />
                                    </Box>
                                ))
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export const LoaderFile = () => {
    const dsg = UseStyles()
    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column">
            <Box height="100%" display="flex" flexDirection="column">
                <Box height="100%">
                    <Box display="flex" alignItems="center" gap="20px" flexWrap="wrap">
                        {
                            ['',''].map((v,k) => (
                                <Box width={273} height={241} className={dsg.paper} bgcolor='#ffffff' key={k}>
                                    <Box>
                                        <Box p="16px 16px 0px 16px" bgcolor="#F8F9FA" borderRadius="8px" height="125px">
                                            <Skeleton variant="rectangular" sx={{borderRadius: '8px'}} width="100%" height="100%" />
                                        </Box>
                                        <Skeleton variant="text" sx={{borderRadius: '8px', mt: '12px'}} width="100%" height="100%" />
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="flex-end" gap="8px" mt="25px">
                                        <Skeleton variant="rectangular" sx={{borderRadius: '8px'}} width={20} height={20} />
                                        <Skeleton variant="rectangular" sx={{borderRadius: '8px'}} width={20} height={20} />
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
