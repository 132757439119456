import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Profiling } from './Profiling';
import { MProfiling } from './MProfiling';
import { WelcomePersonalization } from './WelcomePersonalization';
import { ToolContext } from '../../core/context/ToolContext';
import { TourPersonalization } from './TourPersonalization';
import { Index as Notif } from '../notifications/Index';
import { VM } from '../../core/VM';
import { NoAccessModal } from './NoAccessModal';

let view = VM()
export const Index = () => {
    const { personalization_state, tool_state
        , notif_state 
    } = useContext(ToolContext); 
    const {__SESSION, noAccessModal } =  tool_state
    const { openNotif } = notif_state
    const personalizationData = personalization_state.personalizationData

    let loc = useLocation().search;
    let location = useLocation().pathname;
    let prsnl = [];
    let welcome_status;
    if (personalizationData.data.length !== 0) {
        prsnl = personalizationData.data.filter(v => { return v.feature_name === 'dashboard'} );
        if (prsnl.length > 0) {
            welcome_status = personalizationData.data.length > 0 && (prsnl[0].personalization.filter(v => {return v.sf_name === 'welcomeTour'} )[0].status);
        }else{
            welcome_status = 0
        }
    }
    
    return (  
        noAccessModal.data ? 
            <NoAccessModal/>
        :
        (location !== '/profiling' && (
           <>
                {prsnl.length === 0 && __SESSION.data.pinfo.length === 0 ?
                    ( view === '' ?  <Profiling/>  : <MProfiling/> )
                : 
                    <>
                        {(location === '/' || location.split('/', 2)[1] === 'tool-categories') && welcome_status === 0 && (
                            <>
                                <WelcomePersonalization personalizationData={personalizationData.data} />
                                <TourPersonalization personalizationData={personalizationData.data}/>
                            </>
                        )}
                        {( ((("Notification" in window) && Notification.permission === 'default') && openNotif.data) && (welcome_status > 0 || location + loc === '/general-maintenance?feature=company&tab=company-profile')) && (
                            <Notif/>
                        )} 
                    </>
                }
               
           </>
        ))
    );
}