import { useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { McDrawer } from './McDrawer';
import { Recent } from './recent/Index';
import { Registrations } from './registrations/Index';
import { Contracts } from './contracts/Index'
import { Favorites } from "./favorites/Index";
import { Trash } from './trash/Index';
import { WelcomeMyCloud } from '../../personalization/WelcomeMyCloud';
import { ToolContext } from "../../../core/context/ToolContext";
import { rqx } from "../../../core/request/API";
import { env } from "../../../core/Env";
import { NotFoundPage } from "../../empty/Index";
import { Documents } from './documents/Index'

export const DIndex = ({match}) => {

    const { personalization_state, cloud_state , notif_state, tool_state} = useContext(ToolContext)
    const { globalLoader, __SESSION } = tool_state
    const { openCloud, filter, csuccessOpen, successOpen, show } = cloud_state
    const { openNotif } = notif_state
    const { personalizationData } = personalization_state

    let res = personalizationData.data.filter(v => {return v.feature_name === 'cloud'} );
    const __initPersonalization = async() => {
        if (res.length === 0) {
            openCloud.set((prev) => ({...prev, welcome : true}))
        }else{
            let welcome = res[0].personalization.filter((v) => { return v.sf_name === 'welcome' && v.status === 1 } );
            let d = {...openCloud.data}
            if (welcome.length === 0) {
                d = {...openCloud.data, welcome : true}
            }
            openCloud.set(d)
        }
    }

    const handlerClose = async() => {
        const user_id = atob(__SESSION.data.ainfo.aid).split('_', 2)[1];
        let filter = personalizationData.data.filter(v => {return v.feature_name !== 'cloud'} );
        let data
        if (res.length > 0) {
            let newprs = res[0].personalization.filter((v) => { return v.sf_name === 'new' && v.status === 1 } );
            let tp = res[0].personalization.filter((v) => { return v.sf_name === 'tooltip' && v.status === 1 } );
            data = {
                user_id:parseInt(user_id), 
                tool_id:parseInt(process.env.REACT_APP_TID), 
                feature_name:'cloud', 
                personalization:[
                    {sf_name:'welcome', status:1},
                    {sf_name:'tooltip', status: newprs.length === 0 ? 0 : 1},
                    {sf_name:'new', status: tp.length === 0 ? 0 : 1},
                ] 
            }
        }
        personalizationData.set([...filter, ...[data]]);
        openCloud.set({...openCloud.data, welcome : false})
        await rqx.post(`<<global>>/${env()}/personalization/create`, data )
    }
    
    useEffect(() => {
        let subscribe = true
        if (!globalLoader.data) {
            if (!openNotif.data || (("Notification" in window) && Notification.permission !== 'default')) {
                if (subscribe) {
                    __initPersonalization()
                }
                return () => subscribe = false
            }
        }
        // eslint-disable-next-line
    }, [globalLoader.data])
   
    return (
        <Box display="flex" width="100%" height="100%">
            <McDrawer match={match} filter={filter} csuccessOpen={csuccessOpen} successOpen={successOpen} show={show}/>
            <Box width="100%" height="100%" bgcolor="#F8F9FA" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.04)" borderLeft="1px solid #E6E6E6">
                <List match={match}/>
            </Box>
            {__SESSION.data.uaccess[0].company_id !== null && (
                <WelcomeMyCloud open={openCloud.data.welcome} event={() => handlerClose()} />
            )}
            {/* <NewSuccessAlert successOpen={true} setSuccessOpen={successOpen.set} text="Successfully added to favorites" /> */}
        </Box>
    )
}

const List = ({match}) => {
    
    if (match.feature === 'recent') {   
        return <Recent match={match} />
    }else if (match.feature === 'registrations') {
        return <Registrations match={match} />
    }else if (match.feature === 'contracts') {
        return <Contracts match={match} />
    }else if (match.feature === 'favorites') {
        return <Favorites match={match} />
    }else if (match.feature === 'trash') {
        return <Trash match={match} />
    }else if (match.feature === 'documents' || match.feature === 'shared-access') {
        return <Documents match={match} />
    }else{
        return <NotFoundPage/>
    }
}