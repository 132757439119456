import { Box, MenuItem, Modal, Slide, Typography } from "@mui/material"
import { InfoIcon, TrashIcon, UpdateIcon } from "../../../../../core/global/Icons"

let uai_user = process.env.REACT_APP_UAI_REGULAR;

export const ThreeDots = ({ pModal, setPModal, __SESSION }) => {

    const btnMenu = [
        {name: 'View Details', icon: <InfoIcon />, type: 'viewdetails'},
        {name: 'Issue', icon: <UpdateIcon />, type: 'update'},
        {name: 'Remove', icon: <TrashIcon fill="#737373" />, type: 'remove'},
        // {name: 'Agree', icon:  <UpdateIcon />, type: 'agree'},
    ]
    
    const handlerViewClose = async() => {
        setPModal({...pModal, view: false, data:[] })
    }

    const handleBtnMenu = async (e, type) => {
        e.stopPropagation()
        
        if (type === 'viewdetails') {
            setPModal({...pModal, view: false, remove: false, update: false, fullview: true})
        }else if (type === 'remove') {
            setPModal({...pModal, view: false, update: false, remove: true})
        }else if (type === 'update') {
            setPModal({...pModal, view: false, remove: false, update: true})
        }
    }
 
    return (
        <Modal open={pModal.view} >
            <Slide in={pModal.view} direction="up" className="noutlined" onClick={handlerViewClose}>
                <Box height="100%" width="100%" display="flex" justifyContent="flex-end" flexDirection="column">
                    <Box bgcolor="#FFFFFF" p={2} borderRadius="16px 16px 0px 0px" boxShadow="0px 0px 24px rgba(0, 0, 0, 0.08)">
                        <Box display="flex" justifyContent="center" mb={1}>
                            <Box height="2px" width="37px" bgcolor="#333333" />
                        </Box>
                        <Typography  fontSize={14} fontWeight={600} lineHeight="25px" noWrap>
                            {pModal.data[0].policyname}
                        </Typography>
                        {
                            btnMenu.map((v, k) => (
                                ((pModal.data[0].status === 1 && (v.name === 'Issue' || v.name === 'Remove')) || (__SESSION.data.uaccess[0].role_id === parseInt(uai_user) && pModal.cp_status !== 3 && v.name === 'Agree') || v.name === 'View Details') &&
                                <MenuItem key={k} sx={{px: '0px', py: '8px', minHeight: '36px', display: 'flex', alignItems: 'center', gap: '12px'}} onClick={(e) => handleBtnMenu(e, v.type)}>
                                    <Box height="20px" width="20px">{v.icon}</Box>
                                    <Box fontSize="14px">{v.name}</Box>
                                </MenuItem>
                            ))
                        }
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}