import { useState } from "react"

export const NotifState = () => {
    const [permission, setPermission] = useState()
    const [openNotif, setOpenNotif] = useState(!("Notification" in window) ? false:true)
    const [allowNotif, setAllowNotif] = useState(false)
    const [tab, setTab] = useState('all')
    const [anchorNotif, setAnchorNotif] = useState(null) // FOR DESKTOP NOTIF MENU
    const [notifData, setNotifData] = useState([]) // FOR DESKTOP NOTIF DATA LIST
    const [loader, setLoader] = useState(false) // FOR DESKTOP NOTIF LOADER
    const [count, setCount] = useState(0)
    const [trigger, setTrigger] = useState()
    const [badgeCount, setBadgeCount] = useState(0)

    const [notifPerUser, setNotifPerUser] = useState(0)
    const [rtNotifAccess, setRTNotifAccess] = useState(true)
    const [rtAccess, setRTAccess] = useState(false)
   
    return {
        permission: {data: permission, set: setPermission},
        openNotif: {data: openNotif, set: setOpenNotif},
        allowNotif: {data: allowNotif, set: setAllowNotif},
        tab: {data: tab, set: setTab},
        anchorNotif: {data: anchorNotif, set: setAnchorNotif},
        notifData: {data: notifData, set: setNotifData},
        loader: {data: loader, set: setLoader},
        count: {data: count, set: setCount},
        trigger: {data: trigger, set: setTrigger},
        badgeCount: {data: badgeCount, set: setBadgeCount},
        notifPerUser: {data: notifPerUser, set: setNotifPerUser},
        rtNotifAccess: {data: rtNotifAccess, set: setRTNotifAccess},
        rtAccess: {data: rtAccess, set: setRTAccess},
    }
}