import { Box, Button, Modal } from "@mui/material";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ToolContext } from "../../../../../core/context/ToolContext";

export const SelectCon = ({openConList, cform, listConName, cOthers, sufficientStorage, insuffShow, __SESSION }) => {
    const { navigation_state } = useContext(ToolContext)
    const { prevURL } = navigation_state

    const nav = useHistory()
    const urlParams = new URLSearchParams(window.location.search);
   
    const handleClick = (v, type) => {
        if (type === 'data') {
            if (sufficientStorage.data && atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') {
                insuffShow.set(true)
            }else{
                cform.set({...cform.data, 
                    conName: {val: v.id, stats: false, msg: ''}, 
                })
                if (v.name === 'Others') {
                    cOthers.set(true)
                }else{
                    cOthers.set(false)
                }
                openConList.set(false)
            }
        }else{
            if (urlParams.get('filter') === 'ubr') {
                nav.push(prevURL.data)
            }else{
                nav.push('/my-cloud/contracts/list')
                openConList.set(false)
            }
        }
    }

    return (
        <Modal open={openConList.data}>
            <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Box display="flex" flexDirection="column" mb={2}
                    p={4} height={277} width={512} bgcolor="#FFFFFF" borderRadius="12px" boxShadow="0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)"
                    className="ubr-select"
                >
                    <Box minHeight={41}>
                        <Box fontSize={20} fontWeight={600} lineHeight="16px" mb={2} color="#333333">Select Contracts</Box>
                    </Box>
                    <Box height="100%" className='overflowY'>
                        {
                            listConName.data.map((v, k) => (
                                <Box p="12px" color="#313131" fontSize={14} fontWeight={400} lineHeight="16px" className='c-pointer' onClick={() => handleClick(v, 'data')} key={k} 
                                    sx={{'&:hover': { color: '#107038', bgcolor: '#f0f0f0', fontWeight: 600 } }}
                                >
                                    {v.name}
                                </Box>
                            ))
                        }
                    </Box>
                </Box>
                <Button variant='outlined' disableElevation
                    sx={{p: '12px 16px', lineHeight: '16px', fontWeight: 600, borderRadius: '8px', color:"#FFFFFF", borderColor: '#E6E6E6',
                        '&:hover': { backgroundColor: '#E2EDE7', color: '#107038', borderColor: '#E2EDE7'} 
                    }}
                    onClick={(e) => handleClick(e, 'cancel')}
                >
                    Cancel
                </Button>
            </Box>
        </Modal>
    )
}