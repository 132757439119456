import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Modal } from "@mui/material";
import { ToolContext } from "../../../../../core/context/ToolContext";
import Sss from '../../../../../assets/images/banner/SSS.png'

export const SelectReg = ({modal, form, listRegName, getRootProps, ocrLoader, sufficientStorage, insuffShow, __SESSION}) => {
    // FOR UBR
    const { navigation_state } = useContext(ToolContext)
    const { prevURL } = navigation_state

    const nav = useHistory()
    const urlParams = new URLSearchParams(window.location.search);
   
    const HandlerSelectReg = (v) => {
        if (sufficientStorage.data && atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') {
            insuffShow.set(true)
        }else{
            form.set({...form.data, 
                regName: {val: v.id, stats: false, msg: ''}, 
                regType : { val:  v.name === 'Others' ? 0 : v.type, stat: false, msg: ''},
                date_from:{val:'', stat:false, msg:''},
                date_to:{val:'', stat:false, msg:''},
                regFile:{
                    photo:{val:'', stat:false, msg:''}, 
                    file:{val:'', stat:false, msg:''}
                },
                name:{val:'', stat:false, msg:''},
                number:{val:'', stat:false, msg:''},
                regOther:{val:'', stat:false, msg:''},
            })
            if (v.name === 'Social Security System') {
                ocrLoader.set(true)
                modal.set({...modal.data, sampleImg : { val : true }, openRegList : { val : false }  })
            }else{
                ocrLoader.set(false)
                modal.set({...modal.data, openRegList : { val : false } })
            }
        }
    }

    const HandlerChangeReg = () => {
        modal.set({...modal.data, sampleImg : { val : false } })
        ocrLoader.set(false)
    }

    const handleCancel = async() => {
        if (urlParams.get('filter') === 'ubr') {
            nav.push(prevURL.data)
        }else{
            nav.push('/my-cloud/registrations/list')
        }
        modal.set({...modal.data, openRegList : { val : false } })
    }

    return (
        <>
            <Modal open={modal.data.openRegList.val}>
                <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                    <Box display="flex" flexDirection="column" mb={2}
                        p={4} height={439} width={512} bgcolor="#FFFFFF" borderRadius="12px" boxShadow="0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)"
                        className="ubr-select"
                    >
                        <Box minHeight={41} mb={5}>
                            <Box fontSize={20} fontWeight={600} lineHeight="16px" mb={2}>Select Registration</Box>
                            <Box fontSize={14} fontWeight={400} lineHeight="16px">Select the type of registration you want to scan.</Box>
                        </Box>
                        <Box height="100%" className='overflowY'>
                            {
                                listRegName.data.map((v, k) => (
                                    <Box p="12px" color="#313131" fontSize={14} fontWeight={400} lineHeight="16px" className='c-pointer' onClick={() => HandlerSelectReg(v)} key={k} 
                                        sx={{'&:hover': { color: '#107038', bgcolor: '#f0f0f0', fontWeight: 600 } }}
                                    >
                                        {v.name}
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                    <Button variant='outlined' disableElevation
                        sx={{p: '12px 16px', lineHeight: '16px', fontWeight: 600, borderRadius: '8px', color:"#FFFFFF", borderColor: '#E6E6E6',
                            '&:hover': { backgroundColor: '#E2EDE7', color: '#107038', borderColor: '#E2EDE7'} 
                        }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </Box>
            </Modal>
            <Modal open={modal.data.sampleImg.val}>
                <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                    <Box>
                        <Box bgcolor="#F8F9FA" p="24px 18px 30px 18px" borderRadius="12px 12px 0px 0px">
                            <img src={Sss} alt={Sss} />
                        </Box>
                        <Box bgcolor="#FFFFFF" p={3} borderRadius="0px 0px 12px 12px ">
                            <Box color="#333333" fontSize={20} fontWeight={700}>SSS REGISTRATION PHOTO</Box>
                            <Box color="#333333" fontSize={14} fontWeight={400} lineHeight="16px" mt="12px" mb="24px">Must be visible clearly: Employer Number</Box>
                            <Box color="#333333" fontSize={14} fontWeight={400} lineHeight="19px" sx={{opacity: '50%'}} >File Supported: JPEG, JPG ,PNG & PDF.</Box>

                            <Box display="flex" justifyContent="flex-end" mt="56px">
                                <Button variant="contained" disableElevation
                                    sx={{mr: 2, p: '12px 16px', lineHeight: '16px', borderRadius: '6px', color: '#737373', bgcolor: '#FFFFFF', '&:hover': {backgroundColor: '#E2EDE7', color: '#107038'} }}
                                    onClick={() => HandlerChangeReg()}
                                >
                                    Change Registration
                                </Button>
                                <Button variant="contained" disableElevation
                                    // {...getRootProps({className: 'dropzone'})}
                                    onClick={getRootProps().onClick}
                                    sx={{p: '12px 16px', lineHeight: '16px', borderRadius: '6px'}}>
                                    Browse Image
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}
