import { useState } from "react";
import { useContext } from "react";
import { ToolContext } from "../../../../../core/context/ToolContext";
import { env } from "../../../../../core/Env";
import { rqx } from "../../../../../core/request/API";
import { ToolFeatures } from "./ToolFeatures"
import moment from 'moment';
import { useEffect } from "react";
import { Tools } from "./Tools";
import { RegularUser } from "./RegularUser";

let bizID = process.env.REACT_APP_TOOL_ID;
let bizName = process.env.REACT_APP_TOOL_NAME;
let bizPhoto = process.env.REACT_APP_TOOL_PHOTO;

export const Summary = () => {
    const { tool_state, home_state, navigation_state } = useContext(ToolContext)
    const { globalLoader } = tool_state
    const ts = tool_state;
    const own_id = tool_state.__SESSION.data.uaccess[0].owner_id
    const toolList = tool_state.toolOwned.data
    const __SESSION = ts.__SESSION.data.uaccess;
    const {dateToday} = home_state

    const [lists, setLists] = useState( [
        { id: 0, title: (__SESSION[0].role_id === 0 ? 'Total Availed Tools' : 'Total Accessed tools'), total:ts.toolOwned.data.length },
        { id: 1, title: 'Total Tools Not Yet Availed', total: 0 },
        { id: 2, title: 'Total Tools on Cart', total: ts.toolCartCount.data },
    ])
    const [datas, setDatas] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [tids] = useState([]) 
    const [subscribe, setSubscribe] = useState(true)

    const __init = async(ids) => {
        const tlist = [{tid:JSON.parse(bizID), name:bizName, logo:bizPhoto }, ...toolList]
        if (ids.length === 0 ) {
            tlist.filter((v) => { return tids.push(v.tid) })
        }
        const toolsort = tlist.filter(g => ids.find(n => (n === g.tid) ))
        const mdata = toolsort.map(v => { return {...v, avlblseat:0, verified_user:0, unverified_user:0, active_user:0, inactiveuser:0}})

        let actvusr = []
        let vrfdusr = []
        for (let i = 0; i < ids.length; i++) {
            let getvrfdusr = await rqx.get(`<<global>>/${env()}/roleaccess/verusrpertool`, {
                company_id:tool_state.__SESSION.data.uaccess[0].company_id, 
                tid:ids[i],
                platform:'business', 
            }) 
            let getactvusr = await rqx.get(`<<global>>/${env()}/trail/activeuser`, {
                company_id:tool_state.__SESSION.data.uaccess[0].company_id, 
                tid:ids[i],
                platform:'business', 
                date:moment(new Date()).format('YYYY-MM-DD'),
                owner_id:own_id,
            }) 
            actvusr.push({tid:ids[i], ...getactvusr})
            vrfdusr.push({tid:ids[i], ...getvrfdusr})
        }
        const mergeArr = mdata.map((md) => {
            const au = actvusr.find((au) => au.tid === md.tid);
            const vu = vrfdusr.find((vu) => vu.tid === md.tid);
            return {...md, ...au, ...vu};
        });
        setDatas(
            mergeArr.map(v => { return {...v, avlblseat:v.tid === JSON.parse(bizID) ? 0 : JSON.parse(v.matrix).useats.value - (v.verified_user + v.unverified_user), inactiveuser:v.verified_user - v.active_user} } )
        )
        const resTool = await rqx.get(`<<global>>/${env()}/tools/toolcount`, { platform:'business' })
        let tools = resTool[0].count - ts.toolOwned.data.length
        setLists(lists.map(v => v.id === 1 ? {...v, total : tools} : {...v}) )
        if (__SESSION[0].role_id !== 0) {
            setLists(
                lists.filter(v => { return v.title !== 'Total Tools on Cart' && v.title !== 'Total Tools Not Yet Availed' })
            )
        }
        setIsLoading(false)
       }

    useEffect(() => {
        if (subscribe) {
            if (!globalLoader.data) {
                __init(tids)
            }
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [globalLoader.data])

    return (
        <>
            <ToolFeatures isLoading={isLoading} lists={lists} __SESSION={__SESSION} navigation_state={navigation_state} ts={ts} />
            {__SESSION[0].role_id !== parseInt(process.env.REACT_APP_UAI_REGULAR) && <Tools dateToday={dateToday} bizID={bizID} __SESSION={__SESSION} datas={datas} isLoading={isLoading} tool_state={tool_state} />} 
            {__SESSION[0].role_id === parseInt(process.env.REACT_APP_UAI_REGULAR) && <RegularUser bizID={bizID} tool_state={tool_state} />} 
        </>
    )
}
