import { motion } from "framer-motion";
import { Box, Modal, Slide } from "@mui/material"
import camera from '../../../../../../assets/images/icons/camera.png';
import file from '../../../../../../assets/images/icons/file.png';
import { ErrorAlert } from "../../../../../../core/global/forms/CstmModal";

export const Choose = ({cloud_state, onChangeFile}) => {
    
    const { cform, dfltForm, conToggle, errOpen, message } = cloud_state 

    const handlerCloseChoises = () => {
        conToggle.set({...conToggle.data, slctfile:{data:'', stat:false} })
        cform.set(dfltForm.data)
    }

    const handleChange = (e) => {
        let files = e.target.files[0]
        let sizelmt = 4194304
        if (files.size > sizelmt) {
            message.set('File uploads must not exceed 3MB.')
            errOpen.set(true)
            return
        }
        
        let res = onChangeFile(e)
        if (res) {
            conToggle.set({...conToggle.data, details:true, slctfile:{...conToggle.data.slctfile, stat:false} })
        }
    }
    
    return (
        <>
            <Modal open={conToggle.data.slctfile.stat} className="noutlined">
                <Slide in={conToggle.data.slctfile.stat} direction="up" className="noutlined">
                    <Box height="100%" width="100%" display="flex" flexDirection="column">
                        <Box height="100%" width="100%" bgcolor="#3333331F" onClick={() => handlerCloseChoises()}>
                        
                        </Box>
                        <Box minHeight={160} width="100%" bgcolor="#FFFFFF" borderRadius="24px 24px 0px 0px" p={2} display="flex" alignItems="center" justifyContent="space-around" flexDirection="column">
                            <Box border="1.5px solid #313131" width="24px" sx={{opacity: 0.2}}/>
                            <Box fontWeight={400} fontSize={14} lineHeight="26px" >
                                {conToggle.data.slctfile.data.name}
                            </Box>
                            <Box fontWeight={400} fontSize={14} lineHeight="26px" color="#8D98A9">
                                Choose Method
                            </Box>
                            <Box display="flex" justifyContent="space-evenly" width="100%">
                                <Box display="flex" alignItems="center" flexDirection="column">
                                    <Box height={40} width={40} border="1px solid #8D98A9" textAlign="center" borderRadius="8px" p={1} component={motion.div} whileTap={{ backgroundColor: '#E2EDE7'}}>
                                        <input accept="image/*,.pdf" type="file" id="camera" hidden capture="camera" onChange={(e) => handleChange(e)} />
                                        <label htmlFor="camera">
                                            <img src={camera} alt={camera} />
                                        </label>
                                    </Box>
                                    <Box fontSize={10} fontWeight={400} lineHeight="17px" mt={1}>Camera</Box>
                                </Box>
                                <Box display="flex" alignItems="center" flexDirection="column">
                                    <Box height={40} width={40} border="1px solid #8D98A9" textAlign="center" borderRadius="8px" p={1} component={motion.div} whileTap={{ backgroundColor: '#E2EDE7'}}>
                                        <input accept="image/*,.pdf" type="file" id="file" hidden onChange={(e) => handleChange(e)} />
                                        <label htmlFor="file">
                                            <img src={file} alt={file} />
                                        </label>
                                    </Box>
                                    <Box fontSize={10} fontWeight={400} lineHeight="17px" mt={1}>Files</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Slide>
            </Modal>
            {errOpen.data && (
                <ErrorAlert errOpen={errOpen.data} setErrOpen={errOpen.set} text={message.data} duration={3000}/>
            )}
        </>
    )
}
