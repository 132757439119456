import { Box, Button, InputAdornment, TextField } from "@mui/material"
import { ArrowDown } from "../../../../../core/global/Icons"
import { FormatFilesize, UnsavedMsgModal } from "../../../global/DesktopDsg"
import CloseIcon from '@mui/icons-material/Close';
import Add  from '../../../../../assets/images/icons/add.png'
import Pdf  from '../../../../../assets/images/icons/pdf.png'

export const Left = ({saveLoader, openConList, cform, listConName, dsg, consn, getRootProps, getInputProps, nav, cOthers, dfltForm, paramsids, cactType, modal, __SESSION}) => {

    const HandlerCancel = () => {
        if ( atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0') {
            nav.push('/ubr?type=select')
        }else{
            if (paramsids === 'review') {
                modal.set({...modal.data, unsaved : { val : true } })
                return
            }
            cform.set(dfltForm)
            cOthers.set(false)
            saveLoader.set(false)
            cactType.set('')
            nav.push('/my-cloud/contracts/list')
        }
    }

    const HandleClick = () => {
        if (!saveLoader.data) {
            openConList.set(true)
        }
    }

    const HandlerBtn = (type) => {
        if (type === 'stay') {
            modal.set({...modal.data, unsaved : { val : false } })
        }else{
            modal.set({...modal.data, unsaved : { val : false } })
            nav.push('/my-cloud/contracts/list')
        }
    }
    
    return (
        <Box height="100%" width="50%" bgcolor="#FFFFFF" px="60px" py="91px" display="flex" flexDirection="column" justifyContent="center">
            <Box>
                <Button variant="contained" disableElevation onClick={() => HandlerCancel()}
                    sx={{bgcolor: '#F8F9FA', color: '#333333', fontWeight: 600, py: '12px', lineHeight: '16px', borderRadius: '6px', border: '1px solid #E6E6E6',
                    '&:hover': {bgcolor: '#E2EDE7', color: '#107038'} }}
                >
                    Cancel
                </Button>
                <Box display="flex" alignItems="baseline" pt="40px" pb="32px" lineHeight="26px">
                    {paramsids === 'review' ? 
                        <Box fontSize={20} fontWeight={600} mr={2}>Review : Contracts</Box>
                    :
                        <Box fontSize={20} fontWeight={600} mr={2}>Upload Contracts</Box>
                    }
                    <Box fontSize={12} fontWeight={400}>Contract Number: {consn.data}</Box>
                </Box>
                <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={1}>Contract Type<Box component="label" color="red">*</Box></Box>
                <TextField fullWidth 
                    disabled={paramsids !== 'create' || paramsids === 'review' ? true : false}
                    value={ cform.data.conName.val === '' ? '' : listConName.data.filter((f) => {return f.id === cform.data.conName.val})[0].name} 
                    onClick={() => HandleClick()}
                    className={`${dsg.txtfld} c-pointer`}
                    inputProps={{ style: { padding: '14px 16px'} }} 
                    InputProps={{ 
                        readOnly:true,
                        endAdornment: (
                            <InputAdornment position='end'>
                                <ArrowDown stroke="#333333" />
                            </InputAdornment>
                        )
                    }}
                />
            </Box>
            <UploadFileDsg cform={cform} saveLoader={saveLoader} getRootProps={getRootProps} getInputProps={getInputProps} paramsids={paramsids} cactType={cactType}/>
            <UnsavedMsgModal open={modal.data.unsaved.val} HandlerBtn={HandlerBtn} />
        </Box>
    )
}

const UploadFileDsg = ({cform, saveLoader, getRootProps, getInputProps, paramsids, cactType}) => {
    
    const removeHandler = async(val) => {
        if (val === '1') {
            cform.set(
                {...cform.data, 
                    conFile:{...cform.data.conFile,
                        photo:{val:'', stat:false, msg:''}, 
                        file:{val:'', stat:false, msg:''},
                    }
                }
            )
        }
        if (val === '2') {
            cform.set(
                {...cform.data, 
                    conFile2:{...cform.data.conFile2,
                        photo:{val:'', stat:false, msg:''}, 
                        file:{val:'', stat:false, msg:''},
                    }
                }
            )
        }
    }

    return(
        <>
            <Box minHeight="272px" height="272px" bgcolor="#F8F9FA" borderRadius="12px" p={2} mt={4} className="ubr-upload">
                {(cform.data.conFile.file.val !== '' && cform.data.conFile2.file.val !== '') || saveLoader.data || paramsids === 'review' ? 
                    <Box position="relative" border="2px dashed #D9D9D9" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                        <img src={Add} alt={Add} />
                        <Box mt={5} fontSize={14} fontWeight={400} lineHeight="19px" color="#333333">Drag and drop an image, or <Box component="label" color="#107038">Browse</Box></Box>
                        <Box mt="12px" fontSize={12} fontWeight={400} lineHeight="19px" color="#737373">The maximum file size is 3 MB, and there is a limit of two files.</Box>
                        <Box position="absolute" height="100%" width="100%" bgcolor="#FFF" sx={{opacity:0.8}}/>
                    </Box>
                :
                    <Box position="relative" {...getRootProps({className: 'dropzone'})} border="2px dashed #D9D9D9" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column" className="c-pointer">
                        <img src={Add} alt={Add} />
                        <Box mt={5} fontSize={14} fontWeight={400} lineHeight="19px" color="#333333">Drag and drop an image, or <Box component="label" color="#107038">Browse</Box></Box>
                        <Box mt="12px" fontSize={12} fontWeight={400} lineHeight="19px" color="#737373">The maximum file size is 3 MB, and there is a limit of two files.</Box>
                        <input {...getInputProps()} />
                    </Box>
                }
            </Box>
            <Box pt="32px" display="flex" flexDirection="column" gap="12px">
                {cform.data.conFile.file.val !== '' && (
                    <Box minHeight="60px" border="1px solid #E6E6E6" borderRadius="6px" display="flex" alignItems="center" justifyContent="space-between" py="14px" px="12px">
                        <Box display="flex" alignItems="center" gap="12px">
                            {cform.data.conFile.file.val[0].type.split('/')[1] === 'pdf' ? 
                                <img src={Pdf} alt={Pdf} width={32} height={32} />
                            :
                                <Box component="img" src={cform.data.conFile.photo.val} alt={cform.data.conFile.photo.val} height={32} width={32} borderRadius="12px"/>
                            }
                            <Box display="flex" flexDirection="column" ml="13px"> 
                                <Box color="#333333" fontWeight={600} fontSize={12} lineHeight="16px"> {cform.data.conFile.file.val[0].name} </Box>
                                <Box color="#737373" fontWeight={400} fontSize={10} lineHeight="16px"> {FormatFilesize(cform.data.conFile.file.val[0].size)} </Box>
                            </Box>
                        </Box>
                        {(!saveLoader.data && paramsids !== 'review') && (
                            <Box onClick={()=>removeHandler('1')} className="c-pointer"><CloseIcon sx={{height:'14px', width:'14px', color:'#737373'}}/></Box>
                        )}
                    </Box>
                )}
                {cform.data.conFile2.file.val !== '' && (
                    <Box minHeight="60px" border="1px solid #E6E6E6" borderRadius="6px" display="flex" alignItems="center" justifyContent="space-between" py="14px" px="12px">
                        <Box display="flex" alignItems="center" gap="12px">
                            {cform.data.conFile2.file.val[0].type.split('/')[1] === 'pdf' ? 
                                <img src={Pdf} alt={Pdf} width={32} height={32} />
                            :
                                <Box component="img" src={cform.data.conFile2.photo.val} alt={cform.data.conFile2.photo.val} height={32} width={32} borderRadius="12px"/>
                            }
                            <Box display="flex" flexDirection="column" ml="13px"> 
                                <Box color="#333333" fontWeight={600} fontSize={12} lineHeight="16px"> {cform.data.conFile2.file.val[0].name} </Box>
                                <Box color="#737373" fontWeight={400} fontSize={10} lineHeight="16px"> {FormatFilesize(cform.data.conFile2.file.val[0].size)} </Box>
                            </Box>
                        </Box>
                        {(!saveLoader.data && paramsids !== 'review') && (
                            <Box onClick={()=>removeHandler('2')} className="c-pointer"><CloseIcon sx={{height:'14px', width:'14px', color:'#737373'}}/></Box>
                        )}
                    </Box>

                )}
            </Box>
        </>
    )
}