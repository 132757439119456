import { Box, Typography } from "@mui/material"
import moment from "moment"
import { FormatFilesize } from "../../../../mycloud/global/DesktopDsg"
import Pdf  from '../../../../../assets/images/icons/pdf.png'

export const Review = ({ form, plcysn }) => {

    const plcyInfoReview =[
        {name: 'Department', val: form.departmentname.name},
        {name: 'Policy Number', val: plcysn.data},
        {name: 'Policy Name', val: form.policyname},
        {name: 'Description', val: form.description},
        {name: 'Date of Upload', val: moment(form.dateupload).format('MM/DD/YY')},
        {name: 'Issue Date', val: moment(form.dateissued).format('MM/DD/YY')},
        {name: 'Prepared By', val: form.preparedname},
        {name: 'Approved By', val: form.preparedname},
    ]

    return (
        <>
            <Box fontSize="20px" lineHeight="26px" fontWeight={600} mb="16px">Review: Policy Information</Box>
            <Box fontSize="12px" lineHeight="16px" fontWeight={400} mb="20px">Note: Make sure that all information is correct.</Box>
            <Box minHeight="50px" width="100%" borderColor="#E6E6E6" className="img-border-dashed" p={2} borderRadius="6px" m="16px 0px 24px 0px">
                {
                    form.file_name !== '' &&
                    <Box display="flex" alignItems="center" width="100%"  p="9px 13px" bgcolor="#FFFFFF" borderRadius="6px" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)"> 
                        <Box minWidth={32}> 
                            <img src={Pdf} alt={Pdf} />
                        </Box>
                        <Box width="85%" ml="13px"> 
                            <Typography color="#333333" fontWeight={600} fontSize={12} lineHeight="16px" noWrap>{form.file_name}</Typography>
                            <Box color="#737373" fontWeight={400} fontSize={10} lineHeight="16px"> {FormatFilesize(form.file_size)} </Box>
                        </Box>
                    </Box>
                }
            </Box>
            {
                plcyInfoReview.map((v,k) => (
                    <Box key={k}>
                        <Box fontSize="12px" fontWeight={400} lineHeight="19px" mb="6px" color="#A2A3A9">{v.name}</Box>
                        <Box fontSize="14px" fontWeight={600} lineHeight="22px" mb="8px">{v.val}</Box>
                    </Box>
                ))
            }
        </>
    )
}