import { useContext } from 'react';
import { Box, Modal, Fade } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowUp, HandArrowUp, Logo } from '../../core/global/Icons';
import { NotifReg } from './register/NotifReg';
import { LogoAnimator } from '../../core/styling/switching';
import { motion } from 'framer-motion';
import { ToolContext } from '../../core/context/ToolContext';
import { VM } from '../../core/VM';

const useStyles = makeStyles(theme => ({
    get: {
        "&:hover, &.Mui-focusVisible": { 
            backgroundColor: "#035827" 
        }
    },
    skip: {
        "&:hover, &.Mui-focusVisible": { 
            backgroundColor: "#D8EEE1" 
        }
    },
}));

const __SESSION = JSON.parse(localStorage.getItem('biz_session'))
const tool_id =  process.env.REACT_APP_TID;

export const Index = () => {
    const classes = useStyles()
    const logoAnimator = LogoAnimator()
    const view = VM()
    const { notif_state, navigation_state } = useContext(ToolContext)
    const { openNotif, allowNotif } = notif_state

    const __getNotif = async() => {
        setTimeout(() => {
            allowNotif.set(true)
        }, 600);
        let user_id = atob(__SESSION.ainfo.aid).split('_', 2)[1];
        let res = await NotifReg( parseInt(user_id), parseInt(tool_id) )
        if (res) {
            allowNotif.set(false)
            openNotif.set(false)
        }
    }

    const __SkipNotif = async() => {
        openNotif.set(false)
        navigation_state.openNotifMenu.set(false)
    }
    
    return (  
        view === '' ?
            <DesktopView classes={classes} openNotif={openNotif} allowNotif={allowNotif} __getNotif={__getNotif} __SkipNotif={__SkipNotif} logoAnimator={logoAnimator} />
        :
            <MobileView classes={classes} openNotif={openNotif} allowNotif={allowNotif} __getNotif={__getNotif} __SkipNotif={__SkipNotif} />
    );
}

const DesktopView = ({classes, openNotif, allowNotif, __getNotif, __SkipNotif, logoAnimator}) => {
    return(
        <Modal
            open={openNotif.data}
            closeAfterTransition
            className="noutlined"
        >
            <Fade in={openNotif.data}>
                {allowNotif.data ? 
                    <Box position="absolute" top={100} left={200}>
                        <Box position="relative" top="12px"><ArrowUp fill="#ffffff" /></Box>
                        <Box bgcolor="white" className="shadow" borderRadius="10px" p={2}>
                            <Box display="flex" fontSize="14px">
                                Click <Box px="4px" color="#11783C" fontWeight={550}>Allow</Box> to get started.
                            </Box>
                            <Box component={motion.div} variants={logoAnimator.loaderContainer} initial="start" animate="end" width="100%" display="flex"  ml={4}>
                                <Box component={motion.div} variants={logoAnimator.cVariants2} transition={logoAnimator.cTransition}>
                                    <HandArrowUp/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                :
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Box position="absolute" >
                            <Box className="shadow" width='440px' height="472px" borderRadius="15px" borderColor="#ffffff" bgcolor="white">
                                <Box height="135px" bgcolor="#D8EEE1" borderRadius="15px 15px 0px 0px" display="flex" justifyContent="center" alignItems="center">
                                    <Logo size={60}/>
                                </Box>
                                <Box pt="32px" textAlign="center" fontSize="20px" fontWeight={700} color="#333333">
                                    Get Notified!
                                </Box>
                                <Box pt="16px" px="40px" textAlign="center" color="#333333" >
                                    Allow us to assist you in maximizing your extraordinary POFBusiness experience by keeping you informed about tool updates and system concerns.
                                </Box>
                                <Box pt="40px" px="40px">
                                    <Box onClick={()=>__getNotif()} className={`${classes.get} c-pointer`} height="49px" bgcolor="#1C9B51" borderRadius="10px" color="#FFFFFF" display="flex" justifyContent="center" alignItems="center">
                                        Get Notified!
                                    </Box>
                                </Box>
                                <Box pt="12px" px="40px">
                                    <Box onClick={()=>__SkipNotif()} className={`${classes.skip} c-pointer`} height="49px" borderRadius="10px" color="#929292" display="flex" justifyContent="center" alignItems="center">
                                        Skip for now
                                    </Box>
                                </Box>
                            </Box>  
                        </Box>
                    </Box>
                }
            </Fade>
        </Modal>
    )
}

const MobileView = ({classes, openNotif, allowNotif, __getNotif, __SkipNotif}) => {
    return(
        <Modal
            open={openNotif.data}
            closeAfterTransition
            BackdropProps={{ sx:{borderColor:''} }}
        >
            <Fade in={openNotif.data}>
                {allowNotif.data ? 
                    <Box position="absolute" bottom={113} display="flex" justifyContent="center" width="100%">
                        <Box display="flex" alignItems="center" justifyContent="center" bgcolor="white" borderRadius="10px" p={3}>
                            Click <Box px="4px" color="#11783C" fontWeight={550}>Allow</Box> to get started.
                        </Box>
                    </Box>
                :
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Box position="absolute" >
                            <Box className="shadow" width="311px" borderRadius="15px" borderColor="#ffffff" bgcolor="white">
                                <Box height="135px" bgcolor="#D8EEE1" borderRadius="15px 15px 0px 0px" display="flex" justifyContent="center" alignItems="center">
                                    <Logo size={60}/>
                                </Box>
                                <Box pt="32px" textAlign="center" fontSize="20px" fontWeight={700} color="#333333">
                                    Get Notified!
                                </Box>
                                <Box pt="16px" px="40px" textAlign="center" color="#333333" >
                                    Allow us to assist you in maximizing your extraordinary POFBusiness experience by keeping you informed about tool updates and system concerns.
                                </Box>
                                <Box pt="40px" px="40px">
                                    <Box onClick={()=>__getNotif()} className={`${classes.get} c-pointer`} height="49px" bgcolor="#1C9B51" borderRadius="10px" color="#FFFFFF" display="flex" justifyContent="center" alignItems="center">
                                        Get Notified!
                                    </Box>
                                </Box>
                                <Box py="12px" px="40px">
                                    <Box onClick={()=>__SkipNotif()} className={`${classes.skip} c-pointer`} height="49px" borderRadius="10px" color="#929292" display="flex" justifyContent="center" alignItems="center">
                                        Skip for now
                                    </Box>
                                </Box>
                            </Box>  
                        </Box>
                    </Box>
                }
            </Fade>
        </Modal>
    )
}