import { useState } from "react"

export const GenMainState = () => {
    const [compinfo, setCompinfo] = useState([
        {
            company_id: "",
            logo: "none",
            cover_photo: "none",
            name: "",
            email: "",
            contact_num: "none",
            street_building_num: "none",
            tin: "none",
            zipcode: "none",
            province_id: "none",
            city_id: "none",
            city_name: "none",
            barangay_id: "none",
            barangay_name: "none",
            nature_id: "none",
        }
    ]);
    const [ucount, setUcount] = useState(0);

    //FOR GLOBAL LIST
    const [globalList, setGlobalList] = useState([])

    // FOR SEQ NAME
    const [seqName, setSeqName] = useState([])

    //FOR NATURE OF BUSINESS
    const [nobList, setNobList] = useState([])

    // SUBDIVISION
    const [compCity, setCompCity] = useState([])
    const [compBarangay, setCompBarangay] = useState([])

    //DEPARTMENT
    const [deptLen, setDeptLen] = useState([])
    
    //POSITION
    const [pstLen, setPstLen] = useState([])
    
    //USER
    const [usrLen, setUsrLen] = useState([])
    
    //DEPARTMENT
    const [clntLen, setClntLen] = useState([])

    return {
        compinfo: {data: compinfo, set: setCompinfo},
        ucount: {data: ucount, set: setUcount},
        globalList: {data: globalList, set: setGlobalList},
        seqName: {data: seqName, set: setSeqName},
        nobList: {data: nobList, set: setNobList},
        compCity: {data: compCity, set: setCompCity},
        compBarangay: {data: compBarangay, set: setCompBarangay},
        deptLen: {data: deptLen, set: setDeptLen},
        pstLen: {data: pstLen, set: setPstLen},
        usrLen: {data: usrLen, set: setUsrLen},
        clntLen: {data: clntLen, set: setClntLen},
    }
}