import { makeStyles, createStyles } from '@mui/styles';

export const Custom = makeStyles(() =>
    createStyles({
        hoverFoundationPrnsl: {
            '&:hover': {
                backgroundColor: '#0070EF',
            },
        },
        hoverFoundation: {
            '&:hover': {
                backgroundColor: '#17CE64',
                // fontWeight:700
            },
        },
        hover11783C: {
            '&:hover': {
                color: '#11783C',
            },
        },
        hoverFFF4E5: {
            '&:hover': {
                backgroundColor: '#FFF4E5',
            },
        },
        hoverD0F9E5: {
            '&:hover': {
                backgroundColor: '#D0F9E5',
            },
        },
        hover25ad6a:{
            '&:hover': {
                backgroundColor: '#25ad6a',
            },
        },
        FB: {
            '&:hover': {
                backgroundColor: '#1DA1F2',
            }
        },
        IG: {
            backgroundImage:'linear-gradient(to right, rgba(156,7,117,100%), rgba(176,46,46,100%), rgba(117,23,46,100%))',
            '&:hover': {
                backgroundImage:'linear-gradient(to right, rgba(176,7,117,100%), rgba(117,46,46,100%), rgba(156,23,46,100%))',
            }
        },
        TWRT: {
            '&:hover': {
                backgroundColor: '#0672F5',
            }
        },
    }),
);