import { useContext, useEffect, useState } from 'react';
import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { KeyboardArrowDown, Search } from '@mui/icons-material';
import { ArrowLeft } from '../../../../../core/global/Icons';
import { RemoveShareConfirmation, UseStyles } from '../../../global/DesktopDsg';
import { UserList } from './UserList';
import { ToolContext } from '../../../../../core/context/ToolContext';
import { SearchLoader } from '../create/Loader';
import { FilterDept } from './FilterDept';
import { rqx } from '../../../../../core/request/API';
import { env } from '../../../../../core/Env';
import { NotifCount } from '../../../../../core/request/Notif';

export const RemoveAccess = () => {
    const dsg = UseStyles()
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { openShare, show, shareType, confirmRemoveShare, filterActive, allUsrList, searchInput, saveLoader, successOpen, message, withAccess, ulistAccess, } = cloud_state
    const { __SESSION } = tool_state
    let company_id = __SESSION.data.uaccess[0].company_id;
    let user_id =  atob(__SESSION.data.ainfo.aid).split('_', 2)[1]
    const [slctTxt, setSlctTxt] = useState(false)
    const [removeMenuDept, setRemoveMenuDept] = useState(null)
    const [usrData, setUsrData] = useState(allUsrList.data)
    const [count, setCount] = useState(0)
    const [searchLoader, setSearchLoader] = useState(false)
    const [searchData, setSearchData] = useState(ulistAccess.data)

    const handlerRemoveAccess = () => {
        if (count > 0) {
            confirmRemoveShare.set({...confirmRemoveShare.data, val : true, type : 'remove'})
        }
    }

    const handleSelectAll = async() => {
        setSlctTxt(!slctTxt)
        let res;
        if (filterActive.data.name === 'All') {
            if (searchInput.data !== '') {
                res = searchData.map(v => { return {...v, stat:{...v.stat, remove:!slctTxt}} }  )
                setSearchData(res)
            }else{
                res = ulistAccess.data.map(v => { return {...v, stat:{...v.stat, remove:!slctTxt}} }  )
            }
        }else{
            if (searchInput.data !== '') {
                res = searchData.map((v) => v.company_department_id === filterActive.data.id ? {...v, stat:{...v.stat, remove:!slctTxt}} : {...v} )
                setSearchData(res)
            }else{
                res = ulistAccess.data.map((v) => v.company_department_id === filterActive.data.id ? {...v, stat:{...v.stat, remove:!slctTxt}} : {...v} )
            }
        }
        const mergeArr = res
        
        let data = []
        if (filterActive.data.name === 'All') {
            const au = mergeArr.filter((au) => au.name !== 'Admin' )
            data = [...data, ...au]
        }else{
            const au = mergeArr.filter((au) => au.company_department_id === filterActive.data.id && au.name !== 'Admin' )
            data = [...data, ...au]
        }
        ulistAccess.set(data)
        const mergeArr2 = usrData.users.map((md) => {
            const td = data.find((td) => td.id === md.id);
            return {...md, ...td};
        });
        setUsrData({...usrData, users:mergeArr2})
    }

    const handleFltrDept = (e) => {
        setRemoveMenuDept(e.currentTarget)
    }

    const handleBack = async() => {
        let fltr = usrData.users.filter(v => { return v.stat.view }  )
        ulistAccess.set(fltr)
        await filterActive.set({id: 0, name: 'All'})
        shareType.set('details')
        searchInput.set('')
        setRemoveMenuDept(null)
    }

    const RemoveUlist = async() => {
        const filter_fn = ulistAccess.data.map((v) => { return {...v, fullname:`${v.first_name} ${v.last_name}`  } }  )
        const res = filter_fn.filter((item) =>
            item.first_name.toLowerCase().match(searchInput.data.toLowerCase()) || item.last_name.toLowerCase().match(searchInput.data.toLowerCase()) || item.fullname.toLowerCase().match(searchInput.data.toLowerCase())
        );
        setSlctTxt(res.length === 0 ? false : res.filter(v => { return !v.stat.remove }  ).length > 0 ? false : true)
        await setSearchData(res)
        await setSearchLoader(false)
    }

    const handlerCloseConfirmation = () => {
        confirmRemoveShare.set({...confirmRemoveShare.data, val : false})
    }

    const handlerSubmit = async() => {
        if (!saveLoader.data) {
            saveLoader.set(true)
            let fltr = ulistAccess.data.filter(v => { return !v.stat.remove} )
            let acc = fltr.length === 0 ? fltr : fltr.map(v => { return { user_id: v.id, view:v.stat.view, update:v.stat.update, download:v.stat.download } }  )
           
            let body = {
                company_id: company_id,
                access: acc,
                user_id: user_id,
                id: withAccess.data.val[0].id,
                ref: 'update'
            }
        
            const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/access/update`, body)
            const fileType = show.data.data.feature_type === 1 ? 'reg' : show.data.data.feature_type === 2 ? 'con' : show.data.data.feature_type === 3 ? 'folder' : 'file'
            if (res.message === 'success') {
                let fltrAccess = ulistAccess.data.filter(v => { return v.stat.remove} )
                if (fltrAccess.length > 0) {
                    let removeAcc = fltrAccess.map(v => { return { user_id: v.id, view:v.stat.view, update:v.stat.update, download:v.stat.download, remove:true, file:fileType, file_id:show.data.data.id } })
                    await NotifCount(removeAcc, __SESSION, 'rmvacc')
                }
                confirmRemoveShare.set({...confirmRemoveShare.data, val : false, type: ''})
                saveLoader.set(false)
                openShare.set(false)    
                successOpen.set(true)
                message.set('Successfully removed')
            }
        }
    }

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (searchInput.data !== '') {
                setSearchLoader(true)
                const searchFcn = setTimeout(() => {
                    RemoveUlist()
                }, 500)
                return () => clearTimeout(searchFcn)
            }else{
                setSearchLoader(true)
                if (usrData.users.length > 0) {
                    const mergeArr = usrData.users.map((md) => {
                        const td = ulistAccess.data.find((td) => td.id === md.id);
                        return {...md, ...td};
                    });
                    setUsrData({...usrData, users:mergeArr})
                    let au;
                    if (filterActive.data.name === 'All') {
                        au = mergeArr.filter((au) => { return au.name !== 'Admin' && au.stat.view } )
                    }else{
                        au = mergeArr.filter((au) => au.company_department_id === filterActive.data.id && au.name !== 'Admin' && au.stat.view )
                    }
                    setSlctTxt(au.filter(v => { return !v.stat.remove }  ).length > 0 ? false : true)
                    ulistAccess.set(au.sort((a, b) => a.first_name.localeCompare(b.first_name)))
                }
                
                setSearchLoader(false)
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [searchInput.data])

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            let res = usrData.length === 0 ? [] : usrData.users.filter(v => { return v.stat.remove })
            if (res.length === 0) {
                res = ulistAccess.data.filter(v => { return v.stat.remove })
            }
            setCount(res.length)
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [ulistAccess.data, usrData])

    return (
        <Box display="flex" flexDirection="column" height="100%" width="100%">
            <Box display="flex" alignItems="center" mb={2}>
                <IconButton sx={{p: '0px'}} onClick={() => handleBack()}>
                    <ArrowLeft />
                </IconButton>
                <Box ml="15px" fontSize={20} fontWeight={600} lineHeight="22px">Remove Access</Box>
            </Box>
            <Box fontSize={14} fontWeight={400} lineHeight="22px" mb={3}>Please select the employee you want to restrict from accessing this file.</Box>
            <TextField onInput={(e) => searchInput.set(e.target.value)} fullWidth className={dsg.search} placeholder='Search...' InputProps={{ startAdornment: <InputAdornment position='start'> <Search />  </InputAdornment> }} />
            <Box display="flex" allignItem="center" mt={3} p="8px 16px" gap="16px">
                <Box display="flex" alignItems="center" gap="16px" className="c-pointer" onClick={(e) => handleFltrDept(e)}>
                    <KeyboardArrowDown />
                    <Box fontSize={16} fontWeight={600} lineHeight="24px">{filterActive.data.name}</Box>
                </Box>
                <Box display="flex" alignItems='center' gap="16px">
                    <Box className="c-pointer" onClick={() => handleSelectAll()} fontSize={16} fontWeight={600} lineHeight="24px" color="#A2A3A9">
                        {slctTxt ? 'Unselect all' : 'Select all'}
                    </Box>
                    {count > 0 && (
                        <Box fontSize={16} fontWeight={600} lineHeight="24px" color="#107038">{count} selected</Box>
                    )}
                </Box>
            </Box>
            <Box height="100%" width="100%" className="overflowY">
                {searchLoader ? 
                    <SearchLoader/>
                :
                    <UserList ulistAccess={ulistAccess} setSlctTxt={setSlctTxt} searchData={searchData} setSearchData={setSearchData} searchInput={searchInput} usrData={usrData} setUsrData={setUsrData} />
                }
            </Box>
            <Box minHeight={64} display="flex" justifyContent="center" pt={2} borderTop="1px solid #E6E6E6">
                <Button 
                    onClick={() => handlerRemoveAccess()}
                    sx={{  opacity: ulistAccess.data.filter(v => { return v.stat.remove } ).length > 0 ? 1 : 0.2, 
                        p: "16px 22px", borderRadius: "6px", bgcolor: "#107038", color: "#FFFFFF", '&:hover': {bgcolor: "#16522F"} 
                    }}
                >
                    Remove Access
                </Button>
            </Box>
            <FilterDept usrData={usrData} setUsrData={setUsrData} removeMenuDept={removeMenuDept} setRemoveMenuDept={setRemoveMenuDept} setSlctTxt={setSlctTxt} cloud_state={cloud_state}  />
            <RemoveShareConfirmation 
               name={
                    show.data.data.feature_type === 1 ? 
                        show.data.data.registration_name 
                    : show.data.data.feature_type === 2 ? 
                        show.data.data.contract_name 
                    : show.data.data.feature_type === 3 ? 
                        show.data.data.folder_name
                    :
                        show.data.data.file_name
                } 
                subname={
                    show.data.data.feature_type === 1 ? 
                        show.data.data.registered_name 
                    : show.data.data.feature_type === 2 ?
                        show.data.data.client_name
                    : ''
                }
                confirmRemoveShare={confirmRemoveShare}  
                cancel={() => handlerCloseConfirmation()}
                submit={() => handlerSubmit()}
                saveLoader={saveLoader.data}
            />
        </Box>
    )
}