import { useContext } from "react";
import { Box } from "@mui/material";
import { GMTourstyle } from "../../../core/styling/tour";
import Joyride from "react-joyride";
import { ToolContext } from "../../../core/context/ToolContext";

export const ConPzl = () => {
    const { ubr_state, cloud_state } = useContext(ToolContext)
    const { ubrTour, welcomeModal } = ubr_state 
    const { openConList, cform } = cloud_state 
   
    const conSelectCont = [
        {
            target: '.ubr-select',
            disableBeacon: true,
            placement:'top',
            content:
                <Box fontSize={14} fontWeight={500} display="flex" alignItems="center" justifyContent="center">
                    Choose a contract type from the files you will upload.
                </Box>
        }
    ]
    const conUploadCont = [
        {
            target: '.ubr-upload',
            disableBeacon: true,
            placement:'bottom', 
            content:
                <Box fontSize={14} fontWeight={500} display="flex" alignItems="center" justifyContent="center">
                    Upload the file, which should be an image or PDF, with a maximum size of at least 3 MB.
                </Box>
        }
    ]

    const conInfoCont = [
        {
            target: '.ubr-Info',
            disableBeacon: true,
            placement:'bottom', 
            content:
                <Box fontSize={14} fontWeight={500} display="flex" alignItems="center" justifyContent="center">
                    Please provide your {cform.data.conName.val === 0 && 'contracts name, the'} name on the client, or indicate if your uploaded files have an expiration date.
                </Box>
        }
    ]

    return (
        <>
            {(ubrTour.data.con.select && openConList.data && !welcomeModal.data) && (
                <Joyride
                    steps={conSelectCont} 
                    run={ubrTour.data.con.select}
                    continuous={false}
                    hideCloseButton={true}
                    spotlightClicks={true}
                    disableCloseOnEsc={true}
                    spotlightPadding={0}
                    disableOverlay={false}
                    disableOverlayClose={true}
                    disableScrolling={true}
                    disableScrollParentFix={true}
                    showSkipButton={false}
                    styles={GMTourstyle('11px', 'ubr')}
                />
            )}
            {(ubrTour.data.con.upload && !openConList.data && cform.data.conFile.file.val === '' && cform.data.conFile2.file.val === '' ) && (
                <Joyride
                    steps={conUploadCont} 
                    run={ubrTour.data.con.upload}
                    continuous={false}
                    hideCloseButton={true}
                    spotlightClicks={true}
                    disableCloseOnEsc={true}
                    spotlightPadding={0}
                    disableOverlay={false}
                    disableOverlayClose={true}
                    disableScrolling={true}
                    disableScrollParentFix={true}
                    showSkipButton={false}
                    styles={GMTourstyle('11px', 'ubr')}
                />
            )}
            {(cform.data.conFile.file.val !== '' || cform.data.conFile2.file.val !== '' ) && (
                <Joyride
                    steps={conInfoCont} 
                    run={ubrTour.data.con.info}
                    continuous={true}
                    hideBackButton={true}
                    hideCloseButton={true}
                    spotlightClicks={false}
                    disableCloseOnEsc={true}
                    spotlightPadding={7}
                    disableOverlay={false}
                    disableOverlayClose={true}
                    disableScrolling={true}
                    disableScrollParentFix={true}
                    showSkipButton={true}
                    locale={{
                        last:'Got it',
                    }}
                    styles={GMTourstyle('11px', 'last-ubr')}
                />
            )}
        </>
    )
}