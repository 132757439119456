import { useState } from "react"

export const MyCloudState = () => {
    const [openCloud, setOpenCloud] = useState({
        welcome:false,
        tooltip:false,
        new:false
    })

    //RECENT
    const [rcntList, setRcntList] = useState({
        reg:[],
        contracts:[]
    })

    const [storageLoader, setStorageLoader] = useState(false)
    const [storage, setStorage] = useState({used:0, total:0, reg:0, con:0, regFile:0, conFile:0, favtotal:0, docs:0, docFile:0})
    
    //REGISTRATION
    const [regsn, setRegsn] = useState('')
    const [regList, setRegList] = useState([])
    const [form, setForm] = useState({
        date_from:{val:'', stat:false, msg:''},
        date_to:{val:'', stat:false, msg:''},
        regName:{val:'', stat:false, msg:''},
        regType:{val:'', stat:false, msg:''},
        regFile:{
            photo:{val:'', stat:false, msg:''}, 
            file:{val:'', stat:false, msg:''}
        },
        name:{val:'', stat:false, msg:''},
        number:{val:'', stat:false, msg:''},
        regOther:{val:'', stat:false, msg:''},
    })
    const [listRegName, setListRegName] = useState([])
    const [actType, setActType] = useState('create')

    //CONTRACTS
    const [openConList, setOpenConList] = useState(true)
    const [consn, setConsn] = useState('')
    const [conList, setConList] = useState([])
    const [saveLoader, setSaveLoader] = useState(false)

    const [cform, setCForm] = useState({
        date_from:{val:'', stat:false, msg:''},
        date_to:{val:'', stat:false, msg:''},
        conName:{val:'', stat:false, msg:''},
        conFile:{
            photo:{val:'', stat:false, msg:''}, 
            file:{val:'', stat:false, msg:''}
        },
        conFile2:{
            photo:{val:'', stat:false, msg:''}, 
            file:{val:'', stat:false, msg:''}
        },
        name:{val:'', stat:false, msg:''},
        conOther:{val:'', stat:false, msg:''},
    })

    const [dfltForm, setDfltForm] = useState({
        date_from:{val:'', stat:false, msg:''},
        date_to:{val:'', stat:false, msg:''},
        conName:{val:'', stat:false, msg:''},
        conFile:{
            photo:{val:'', stat:false, msg:''}, 
            file:{val:'', stat:false, msg:''}
        },
        conFile2:{
            photo:{val:'', stat:false, msg:''}, 
            file:{val:'', stat:false, msg:''}
        },
        name:{val:'', stat:false, msg:''},
        number:{val:'', stat:false, msg:''},
        conOther:{val:'', stat:false, msg:''},
    })

    const [listConName, setListConName] = useState([])

    const [cOthers, setCOthers] = useState(false)
    const [cactType, setCActType] = useState('create')
    const [csuccessOpen, setCSuccessOpen] = useState(false)
    const [conToggle, setConToggle] = useState({select:false, slctfile:{stat:false, data:''}, details:false, review:false, unsaved:false})

    //FAVORITES
    const [favList, setFavList] = useState([])

    //TRASH
    const [trashFiles, setTrashFiles] = useState([])
    const [trashFolder, setTrashFolder] = useState([])

    const [listView, setListView] = useState(false)
    const [starActive, setStarActive] = useState('')
    const [show, setShow] = useState({
        remove:{val:false, loader:false},
        download:false,
        view:false, 
        fullview:false,
        data:''
    })
    const [isLoading, setIsLoading] = useState(true)
    const [addMenu, setAddMenu] = useState(null)
    const [successOpen, setSuccessOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [lid, setLid] = useState(0)
    const [inftScroll, setInftScroll] = useState(true)
    const [limit] = useState(10)
    const [filter, setFilter] = useState(2)
    const [regActive, setRegActive] = useState({ val: '', openMenu: null})
    const [conActive, setConActive] = useState({ val: '', openMenu: null})
    const [active, setActive] = useState({ val: '', openMenu: null})
    const [ocrLoader, setOcrLoader] = useState(false)
    const [modal, setModal] = useState({
        openRegList:{val: true },
        regForm:{val: false },
        sampleImg:{val: false},
        unsaved:{val: false},
    })
    const [showDl, setShowDl] = useState(null)
    const [modalType, setModalType] = useState('')
    const [selectedFiles, setSelectedFiles] = useState([])
    const [reloadStorage, setReloadStorage] = useState(true)
    const [rcntAdd, setRcntAdd] = useState(false)
    const [choices, setChoices] = useState({val: '', show: false})
    const [limitSelect, setLimitSelect] = useState({limit:4, type:''})

    // MY CLOUD SHARE ACCESS
    const [openShare, setOpenShare] = useState(false)
    const [shareType, setShareType] = useState('')
    const [selectedDept, setSelectedDept] = useState([])
    const [menuDept, setMenuDept] = useState(null)
    const [confirmShare, setConfirmShare] = useState({val: false, type: ''})
    const [confirmRemoveShare, setConfirmRemoveShare] = useState({val: false, type: ''})
    const [departmentList, setDepartmentList] = useState([])
    const [allUsrList, setAllUsrList] = useState([])
    const [filterActive, setFilterActive] = useState({id: 0, name: 'All'})
    const [userList, setUserList] = useState([])
    const [withAccess, setWithAcess] = useState({val: [], loader: true})
    const [searchInput, setSearchInput] = useState('')
    const [ulistAccess, setUlistAccess] = useState([])
    const [slctAllTxt, setSlctAllTxt] = useState(false)
    const [sharedList, setSharedList] = useState([])
    const [sharedFltr, setSharedFltr] = useState({name: 'All', show: false, type: 'all'})
    const [sharedCount, setSharedCount] = useState()

    //VALIDATION FOR PLAN STORAGE IN MY CLOUD
    const [sufficientStorage, setSufficientStorage] = useState(false)
    const [insuffShow, setInsuffShow] = useState(false)

    // DOCUMENT
    const [folderName, setFolderName] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [docuFolder, setDocuFolder] = useState([])
    const [docuFile, setDocuFile] = useState([])
    const [fileLoader, setFileLoader] = useState(true)
    const [fileInfi, setFileInfi] = useState(true)
    const [fileLid, setFileLid] = useState(0)
    const [folderLoader, setFolderLoader] = useState(true)
    const [folderInfi, setFolderInfi] = useState(true)
    const [folderLid, setFolderLid] = useState(0)
    const [uploading, setUploading] = useState({stat:false, type:''})
    const [rename, setRename] = useState({id: 0, val: false})
    const [err, setErr] = useState({val: false, msg: ''})
    const [openMaxFile, setOpenMaxFile] = useState(false)

    // DOCUMENT SHARE ACCESS
    const [accessLoader, setAccessLoader] = useState(false) 

    // ERROR
    const [errOpen, setErrOpen] = useState(false)
    
    return {
        sufficientStorage: {data: sufficientStorage, set: setSufficientStorage},
        insuffShow: {data: insuffShow, set: setInsuffShow},
        openCloud: {data: openCloud, set: setOpenCloud},

        //RECENT
        rcntList: {data: rcntList, set: setRcntList},
        storageLoader: {data: storageLoader, set: setStorageLoader},
        storage: {data: storage, set: setStorage},

        //REGISTRATION
        regsn: {data: regsn, set: setRegsn},
        regList: {data: regList, set: setRegList},
        form: {data: form, set: setForm},
        listRegName: {data: listRegName, set: setListRegName},
        actType: {data: actType, set: setActType},

        //CONTRACTS
        openConList: {data: openConList, set: setOpenConList},
        consn: {data: consn, set: setConsn},
        conList: {data: conList, set: setConList},
        saveLoader: {data: saveLoader, set: setSaveLoader},
        cform: {data: cform, set: setCForm},
        listConName: {data: listConName, set: setListConName},
        cOthers: {data: cOthers, set: setCOthers},
        cactType: {data: cactType, set: setCActType},
        csuccessOpen: {data: csuccessOpen, set: setCSuccessOpen},
        dfltForm: {data: dfltForm, set: setDfltForm},
        conToggle: {data: conToggle, set: setConToggle},
        
        //FAVORITES
        favList: {data: favList, set: setFavList},

        //TRASH
        trashFiles: {data: trashFiles, set: setTrashFiles},
        trashFolder: {data: trashFolder, set: setTrashFolder},

        listView: {data: listView, set: setListView},
        starActive: {data: starActive, set: setStarActive},
        show: {data: show, set: setShow},
        isLoading: {data: isLoading, set: setIsLoading},
        addMenu: {data: addMenu, set: setAddMenu},
        successOpen: {data: successOpen, set: setSuccessOpen},
        message: {data: message, set: setMessage},
        lid: {data: lid, set: setLid},
        inftScroll: {data: inftScroll, set: setInftScroll},
        limit: {data: limit},
        filter: {data: filter, set: setFilter},
        regActive: {data: regActive, set: setRegActive},
        conActive: {data: conActive, set: setConActive},
        active: {data: active, set: setActive},
        ocrLoader: {data: ocrLoader, set: setOcrLoader},
        modal: {data: modal, set: setModal},
        showDl: {data:showDl, set: setShowDl},
        modalType: {data:modalType ,set: setModalType},
        selectedFiles: {data:selectedFiles, set: setSelectedFiles},
        reloadStorage: {data: reloadStorage, set: setReloadStorage},
        rcntAdd: {data: rcntAdd, set: setRcntAdd},
        choices: {data: choices, set: setChoices},
        limitSelect: {data: limitSelect, set: setLimitSelect},
        
        //SHARE ACCESS 
        openShare: {data: openShare, set: setOpenShare},
        shareType: {data: shareType, set: setShareType},
        selectedDept: {data: selectedDept, set: setSelectedDept},
        menuDept: {data: menuDept, set: setMenuDept},
        confirmShare: {data: confirmShare, set: setConfirmShare},
        confirmRemoveShare: {data: confirmRemoveShare, set: setConfirmRemoveShare},
        departmentList: {data: departmentList, set: setDepartmentList},
        allUsrList: {data: allUsrList, set: setAllUsrList},
        filterActive: {data: filterActive, set: setFilterActive},
        userList: {data: userList, set: setUserList},
        withAccess: {data: withAccess, set: setWithAcess},
        searchInput: {data: searchInput, set: setSearchInput},
        ulistAccess: {data: ulistAccess, set: setUlistAccess},
        slctAllTxt: {data: slctAllTxt, set: setSlctAllTxt},
        sharedList: {data: sharedList, set: setSharedList},
        sharedFltr: {data: sharedFltr, set: setSharedFltr},
        sharedCount: {data: sharedCount, set: setSharedCount},

        // DOCUMENT
        folderName: {data:folderName, set: setFolderName},
        openModal: {data: openModal, set: setOpenModal},
        docuFolder: {data: docuFolder, set: setDocuFolder},
        docuFile: {data: docuFile, set: setDocuFile},
        fileLoader: {data: fileLoader, set: setFileLoader},
        fileInfi:  {data: fileInfi, set: setFileInfi},
        fileLid: {data: fileLid, set: setFileLid},
        folderLoader: {data: folderLoader, set: setFolderLoader},
        folderInfi: {data: folderInfi, set: setFolderInfi},
        folderLid: {data: folderLid, set: setFolderLid},
        uploading: {data: uploading, set: setUploading},
        rename: {data: rename, set: setRename},
        err: {data: err, set: setErr},
        openMaxFile: {data: openMaxFile, set: setOpenMaxFile},
        
        // DOCUMENT SHARE ACCESS
        accessLoader: {data: accessLoader, set: setAccessLoader},

        // ERROR ALERT
        errOpen: {data: errOpen, set: setErrOpen},
    }
}