import { useEffect, useState } from "react";
import { Box, IconButton, Modal, Slide, Typography } from "@mui/material"
import { MblFetchingLoader } from "../../../../../fetching/Loader"
import { useHistory } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

export const SelectType = ({cloud_state}) => {
    const nav = useHistory()
    const { cform, cOthers, listConName, conToggle, sufficientStorage, insuffShow } = cloud_state

    const handlerClose = () => {
        conToggle.set({...conToggle.data, select:false})
        setTimeout(() => {
            nav.push('/my-cloud/contracts/list')
        }, 100);
    }

    const handlerShowChoices = (v) => {
        if (sufficientStorage.data) {
            insuffShow.set(true)
        }else{
            cform.set({...cform.data, 
                conName: {val: v.id, stats: false, msg: ''}, 
            })
            conToggle.set({...conToggle.data, slctfile:{stat:true, data:v} })
            if (v.name === 'Others') {
                cOthers.set(true)
            }else{
                cOthers.set(false)
            }
        }       
    }

    const [subscribe, setSubscribe] = useState(true)
    useEffect(() => {
        if (subscribe) {
            if (cform.data.conName.val === '') {
                conToggle.set({...conToggle.data, select:true})
            }
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [])
    
    return (
        <Modal open={conToggle.data.select} className="noutlined">
            <Slide in={conToggle.data.select} direction='up' className="noutlined">
                <Box height="100%" width="100%" bgcolor="#FFFFFF" display="flex" flexDirection="column">
                    <Box minHeight={63} p="21px 16px" borderBottom="1px solid #F1F4F4">
                        <IconButton sx={{p: '0px', minHeight: 20, position: 'absolute'}} onClick={() => handlerClose()}>
                            <CloseIcon sx={{fontSize: 20}} />
                        </IconButton>
                        <Box display="flex" justifyContent="center" fontSize={16} fontWeight={600} lineHeight="26px">
                            Select Contracts
                        </Box>
                    </Box>
                    <Box height="100%" width="100%" px={2}>
                        {
                            listConName.data.length === 0 ?
                                <MblFetchingLoader mt={0} />    
                            :
                                listConName.data.map((v,k) => (
                                    <Typography fontSize={14} fontWeight={400} lineHeight="16px" borderBottom="1px solid #EFF1F4" p="13px 16px" key={k} onClick={() => handlerShowChoices(v)}
                                        sx={{backgroundColor: conToggle.data.slctfile.data.id === v.id ? '#E2EDE7' : '#fffff' }}
                                    >
                                        {v.name}
                                    </Typography>
                                ))
                        }
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}