import { useState } from "react"
import Users from '../../assets/images/banner/new-users.png';
import UserAdd from '../../assets/images/banner/new-user-add.png';
import Building from '../../assets/images/banner/new-building.png';
import Glass from '../../assets/images/banner/new-glass.png';


export const BannerState = () => {
    const [bannerList, setBannerList] = useState([
        { links: '#' , name:'market-place', icon: Glass, title: 'Experience Amazing Tools' , description: 'Grow your business 10x Faster 10x Higher 10x Bigger. ' },
        { links: '/general-maintenance?feature=company&tab=company-profile' , name:'gen-maintenance', icon: Building, title: 'Set up your Company Profile' , description: 'Complete setting up your company profile for a better experience.'},
        { links: '/profile' , name:'profile', icon: Users, title: 'Set up your User Profile' , description: 'Complete setting up your user profile for a better experience.'},
        { links: '#' , name:'link', icon: UserAdd, title: 'Link your Account' , description: 'Use your POFBusiness account to explore POFPersonal.'},
    ])
    const [BannerPerView, setBannerPerView] = useState(3);
    const [isShow, setIsShow] = useState(false)
    return {
        bannerList: {data: bannerList, set: setBannerList},
        BannerPerView: {data: BannerPerView, set: setBannerPerView},
        isShow: {data: isShow, set: setIsShow}
    }
}