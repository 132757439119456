import { Box, Grid, IconButton, Typography } from "@mui/material"
import { domain } from "../../../../../core/Env"
import { ArrowRight } from "../../../../../core/global/Icons"
import { MListLoader } from "../../../loader/MidLoader"
import { useHistory } from "react-router-dom"

export const ToolFeatures = ({isLoading, lists, __SESSION, navigation_state, ts}) => {
    const nav = new useHistory();
    const __redirect = (e, type, val) => {
        if (type === 'tools') {
            if (val.id === 0) {
                // navigation_state.showMT.set(true)
                nav.push(`/tool-categories/subscribed`);
            }else{
                ts.preload.set({loader:true, logo:'https://pofsis-business.s3.amazonaws.com/biz/1678861961712.png'})
                window.location.href = `${domain('mp').url}/sso/account/auth?ainfo=${encodeURIComponent(JSON.stringify(ts.__SESSION.data.ainfo))}&subcb=${val.id === 1 ?'TNA':'TOC'}&platform=business`
                setTimeout(() => {
                    ts.preload.set({...ts.preload.data, loader:false})
                }, 2000);
            }
        }
    }

    return (
        <Box p={2} width="100%" bgcolor="#FFFFFF" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.12)">
            {isLoading || ts.globalLoader.data ?
                <MListLoader __SESSION={__SESSION} />
            : 
                <Grid container spacing={1}>
                    <Grid container >
                        <Box p="6px 12px" fontSize={14} fontWeight={600} color="#333333">My Business Tool Summary</Box>
                    </Grid>
                    {lists.map((v, index) => (
                        <Grid item xs={ __SESSION[0].role_id !== 0 ? 6 :4} key={index}>
                            <Box display="flex" flexDirection="column" justifyContent="space-between" bgcolor="#F0F6F3" p="12px" borderRadius="6px">
                                <Typography fontSize={12} noWrap> {v.title} </Typography>
                                <Box display="flex" justifyContent="space-between" alignItems='center'>
                                    <Box fontSize="14px" mb={1}><b> {v.total} </b></Box>
                                    <IconButton sx={{p: '0px !important'}} onClick={(e) => __redirect(e, 'tools', v)}>
                                        <ArrowRight />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>  
                    ))}
                </Grid>
            }
        </Box>
    )
}