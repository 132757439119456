import { Avatar, Box, MenuItem, Select, Typography } from "@mui/material"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { env } from "../../../../../core/Env"
import { rqx } from "../../../../../core/request/API"
import { NoToolFound } from "../../../../empty/Index"
import { ToolLoader } from "../../../loader/MidLoader"

export const RegularUser = ({bizID, tool_state}) => {
    const { globalLoader } = tool_state
    const toolList = tool_state.toolOwned.data
    const uid = parseInt(atob(tool_state.__SESSION.data.ainfo.aid).split('_', 2)[1])

    const [isLoading, setIsLoading] = useState(true)
    const [selected, setSelected] = useState(0)
    const [datas, setDatas] = useState([])
    const [tids] = useState([]) 
   
    const changeHandler = async(value) => {
        setSelected(value)
    }
    const _header = [ {title: 'Total Transactions', size: 150}, {title: 'Ongoing', size: 100}, {title: 'Accomplished', size: 120}, {title: 'Canceled/Rejected', size: 140} ]

    const __init = async(ids) => {
        setIsLoading(true)
        const tlist = [...toolList]
        if (ids.length === 0 ) {
            tlist.filter((v) => { return tids.push(v.tid) })
        }
        const toolsort = tlist.filter(g => ids.find(n => (n === g.tid) ))
        const mdata = toolsort.map(v => { return {...v, total_transact:0, ongoing:0, accomplished:0, reject:0 }})

        let tbdata = []
        for (let i = 0; i < ids.length; i++) {
            if (ids[i] === 14 || ids[i] === 136 ) {
                let res = await rqx.get(`<<tb>>/${env() === 'dev' ? 'local' : env()}/dashboard/read`, {user_id:uid})
                tbdata.push({ tid:ids[i], total_transact:res.transac, ongoing:res.ongoing, accomplished:res.accomplished, reject:res.reject })
                // let rest = await rqx.get(`<<tb>>/${env() === 'dev' ? 'local' : env()}/dashboard/read`, {user_id:uid, ref: 'total_transact'})
                // let reso = await rqx.get(`<<tb>>/${env() === 'dev' ? 'local' : env()}/dashboard/read`, {user_id:uid, ref: 'ongoing'})
                // let resa = await rqx.get(`<<tb>>/${env() === 'dev' ? 'local' : env()}/dashboard/read`, {user_id:uid, ref: 'accomplished'})
                // let resr = await rqx.get(`<<tb>>/${env() === 'dev' ? 'local' : env()}/dashboard/read`, {user_id:uid, ref: 'reject'})
                // tbdata.push({ tid:ids[i], total_transact:rest[0].count, ongoing:reso[0].count, accomplished:resa[0].count, reject:resr[0].count })
            }
            continue;
        }
        const mergeArr = mdata.map((md) => {
            const td = tbdata.find((td) => td.tid === md.tid);
            return {...md, ...td};
        });
        setDatas(mergeArr)
        setIsLoading(false)
    }

    const __redirect = async(e, tid, logo) => {
        e.stopPropagation()
        tool_state.preload.set({loader:true, logo:JSON.parse(logo)})
        let res = await rqx.get(`<<global>>/${env()}/tools/toolcb`, {tid: tid, env:env() !== 'prod' ? 'sandbox' : 'production'});
        if (res.msg === 'success') {
            if (res.tu.length > 0) {
                window.location.href = `${res.tu[0].value}?ainfo=${encodeURIComponent(JSON.stringify(tool_state.__SESSION.data.ainfo))}`
            }
            setTimeout(() => {
                tool_state.preload.set({...tool_state.preload.data, loader:false})
            }, 2000);
            return
        }
    }
    
    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (!globalLoader.data) {
                __init(tids)
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [globalLoader.data])

    return (
        <Box my="12px" py={2} px="12px" width="100%" bgcolor="#FFFFFF" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.12)" maxHeight={300} className="overflowY">
            <Box fontSize={14} fontWeight={600} mb={3}>Tool Transactions Summary</Box>
            {isLoading || globalLoader.data ?
                <ToolLoader/>
            :
                datas.length === 0 ?
                    <NoToolFound m={3}/>
                :
                    <>
                        <Box minHeight={28}> 
                            <Box pb="12px" fontSize={14} fontWeight={600} color="#333333">Tools Summary</Box>
                            <Select sx={{width: '196px'}} size="small" value={selected} onChange={(e) => changeHandler(e.target.value)}>
                                <MenuItem value={0}>All Categories</MenuItem>
                            </Select>
                        </Box>
                        <Box mt={3} mb={2}>
                            <Box display="flex">
                                <Box minWidth={160} display="flex" flexDirection="column">
                                    <Box display="flex" alignItems="center" justifyContent="center"  p="12px 10px" height={37} bgcolor="#F0F6F3" border="1px solid #ECECEC" borderRadius="4px 0px 0px 4px" mb={1}>
                                        <Typography fontSize={14} fontWeight={500} >
                                            Tool Name 
                                        </Typography>
                                    </Box>
                                    {datas.map((v, k) => (
                                        <Box display="flex" flexDirection="column"  mb={1} key={k} component={motion.div} whileTap={{ backgroundColor: '#F0F6F3'}} onClick={(e) => __redirect(e, v.tid, v.logo)}>
                                            <Box display="flex" alignItems="center" p="12px 10px" border="1px solid #ECECEC">
                                                <Avatar src={ v.tid === parseInt(bizID) ? v.logo : JSON.parse(v.logo)} alt="logo" variant="rounded" sx={{width: 20, height: 20}} />
                                                <Typography ml={1} fontSize={14} noWrap>{v.name}</Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                                <Box width="100%" sx={{overflowX: 'scroll'}} className="scrollXColor scrollXCustom">
                                    <Box display="flex" width="100%" mb={1}>
                                        {_header.map((v, k) => (
                                            <Box display="flex" alignItems="center" justifyContent="center" p="12px 10px" height={37} bgcolor="#F0F6F3" borderBottom="1px solid #ECECEC" borderTop="1px solid #ECECEC" key={k}>
                                                <Typography fontSize={14} fontWeight={500} width={v.size} textAlign="center">
                                                    {v.title}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                    {datas.map((v, k) => (
                                        <Box display="flex" flexDirection="column" width="100%" mb={1} key={k} onClick={(e) => __redirect(e, v.tid, v.logo)}>
                                            <Box display="flex">
                                                <Box display="flex" alignItems="center" p="12px 10px" borderBottom="1px solid #ECECEC" borderTop="1px solid #ECECEC" component={motion.div} whileTap={{ backgroundColor: '#F0F6F3'}}>
                                                    <Typography fontSize={14} width={150} textAlign="center">{v.total_transact}</Typography>
                                                </Box>
                                                <Box display="flex" alignItems="center" p="12px 10px" borderBottom="1px solid #ECECEC" borderTop="1px solid #ECECEC" component={motion.div} whileTap={{ backgroundColor: '#F0F6F3'}}>
                                                    <Typography fontSize={14} width={100} textAlign="center">{v.ongoing}</Typography>
                                                </Box>
                                                <Box display="flex" alignItems="center" p="12px 10px" borderBottom="1px solid #ECECEC" borderTop="1px solid #ECECEC" component={motion.div} whileTap={{ backgroundColor: '#F0F6F3'}}>
                                                    <Typography fontSize={14} width={120} textAlign="center">{v.accomplished}</Typography>
                                                </Box>
                                                <Box display="flex" alignItems="center" p="12px 10px" borderBottom="1px solid #ECECEC" borderTop="1px solid #ECECEC" component={motion.div} whileTap={{ backgroundColor: '#F0F6F3'}}>
                                                    <Typography fontSize={14} width={140} textAlign="center">{v.reject}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </>
            }
        </Box>
    )
}
