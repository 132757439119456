import { useContext, useState, useEffect } from "react"
import { Avatar, Box, Grid, MenuItem, Select, Typography } from "@mui/material"
import { ToolContext } from "../../../../../core/context/ToolContext";
import { SystemConcernsLoader } from "../../../loader/MidLoader";

let bizID = process.env.REACT_APP_TOOL_ID;
let bizName = process.env.REACT_APP_TOOL_NAME;
let bizPhoto = process.env.REACT_APP_TOOL_PHOTO;

export const SystemConcerns = () => {
    const { tool_state } = useContext(ToolContext);
    const toolList = tool_state.toolOwned.data
    const { globalLoader } = tool_state

    const [selected, setSelected] = useState(0)
   
    const list = [
        {id: 0, name: 'All Categories'},
        // {id: 1, name: 'All'},
    ]
    const status = [
        {title: 'Done', color: '#07A42A'},
        {title: 'Ongoing', color: '#2B78E4'},
        {title: 'Pending', color: '#F84949'},
    ]

    const [tids] = useState([]) 
    const [datas, setDatas] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [subscribe, setSubscribe] = useState(true)

    const changeHandler = async(value) => {
        // __init([13,14])
        setSelected(value)
    }

    const __init = async(ids) => {
        setIsLoading(true)
        const tlist = [{tid:JSON.parse(bizID), name:bizName, logo:bizPhoto }, ...toolList]
        if (ids.length === 0 ) {
            tlist.filter((v) => { return tids.push(v.tid) })
        }
        const toolsort = tlist.filter(g => ids.find(n => (n === g.tid) ))
        setDatas(toolsort)
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
    }

    useEffect(() => {
        if (subscribe) {
            if (!globalLoader.data && isLoading) {
                __init(tids)
            }
        }
        return () => setSubscribe(false)
    // eslint-disable-next-line
    }, [globalLoader.data])
    
    return (
        <Box bgcolor="#ffffff" borderRadius="6px" py={2} mt={4} height="389px" display="flex" flexDirection="column">
            {isLoading || globalLoader.data ? 
                <SystemConcernsLoader/>
            :
                <>
                    <Box minHeight="5px" px={2}>
                        <Box fontWeight={600} fontSize={16} color="#333333">System Concerns</Box>
                    </Box>
                    <Box minHeight="130px" px={2} pt="12px">
                        <Select sx={{width: '196px', mt: 2, mb: 3}} size="small"  value={selected} onChange={(e) => changeHandler(e.target.value)}>
                            {list.map((v, k) => (
                                <MenuItem key={k} value={v.id}>{v.name}</MenuItem>
                            ))}
                        </Select>
                        <Grid container mb={2} px={2}>
                            <Grid item sm={3} lg={3}>
                                <Typography fontSize={12} noWrap color="#333333" fontWeight={500}>Tool Name</Typography>
                            </Grid>
                            <Grid item sm={3} lg={3} display="flex" alignItems="center" justifyContent="center">
                                <Typography fontSize={12} noWrap color="#333333" fontWeight={500}>Total Number of Concerns</Typography>
                            </Grid>
                            <Grid item sm={6} lg={6} display="flex" justifyContent="space-around">
                                {status.map((v, k) => (
                                    <Box key={k} display="flex" alignItems="center">
                                        <Box height={12} width={12} borderRadius="6px" bgcolor={v.color} />
                                        <Box fontSize={12} ml="4px" color={v.color}>{v.title}</Box>
                                    </Box>
                                ))}
                            </Grid>
                        </Grid>
                    </Box>
                    <Box height="100%" className="overflowY" px={2}>
                        {datas.map((v,k) => (
                            <Grid key={k} container border="1px solid #ECECEC" pl={2} mb="12px">
                                <Grid item sm={3} lg={3} height={73} display="flex" alignItems="center">
                                    <Box display="flex" alignItems="center">
                                        <Avatar src={v.tid === JSON.parse(bizID) ? v.logo : JSON.parse(v.logo) } variant="rounded" sx={{width: 20, height: 20, borderRadius:'6px'}} />
                                        <Typography ml={1} fontSize={14} noWrap color="#333333" fontWeight={400}>{v.name}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={3} lg={3} display="flex" alignItems="center" justifyContent="center">
                                    <Typography fontSize={14} noWrap color="#333333" fontWeight={600}>0</Typography>
                                </Grid>
                                <Grid item sm={6} lg={6} borderLeft="1px solid #E8F5EE" display="flex" justifyContent="space-evenly" flexDirection="column">
                                    <Box height="15px" width="2px" bgcolor="#07A42A" borderRadius="6px" />
                                    <Box height="15px" width="2px" bgcolor="#2B78E4" borderRadius="6px" />
                                    <Box height="15px" width="2px" bgcolor="#F84949" borderRadius="6px" />
                                </Grid>
                            </Grid>
                        ))}
                    </Box>
                </>
            }
        </Box>
    )
}