import { Box } from "@mui/material"
import { ConList } from "./list/Index"
import { ConForm } from "./form/Index"
import { useContext, useEffect, useState } from "react"
import { ToolContext } from "../../../../core/context/ToolContext"
import { env } from "../../../../core/Env"
import { rqx } from "../../../../core/request/API"
import { WarningAdmin } from "../../../../core/global/Design"

let uai_user = process.env.REACT_APP_UAI_REGULAR;

export const Concern = ({ match, categoryType }) => {
    const { speakout_state, tool_state } = useContext(ToolContext)
    const { concernData, ctgyTypeList, teamList, isLoading, infntScroll, lid } = speakout_state
    const { __SESSION } = tool_state
    const uid = atob(__SESSION.data.ainfo.aid).split('_', 2)[1];

    let cid = concernData.data.length
    let concern_num = `CNCRN-00000000${cid + 1}`

    const [concernForm, setConcernForm] = useState([{
        id: cid + 1,
        concern_num: concern_num,
        category: '',
        team: '',
        details: '',
        type: '',
        active: 1,
        status: 1,
        created_at: '',
        feedback: '',
        feedback_date: '',
    }])

    const [cModal, setCModal] = useState({
        id: '',
        data: '',
        feedback: '',
        view: false
    })

    const categoryList = async(type) => {
        let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/scategory/read`, {
            company_id: __SESSION.data.uaccess[0].company_id,
            filter: 'type',
            type, 
        })
        let result = res.map(pd => ({...pd }))
        ctgyTypeList.set(result)
    }

    const handleTeamList = async() => {
        let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/team/read`, {
            company_id: __SESSION.data.uaccess[0].company_id,
            filter: 'active'
        })
        let result = res.map(pd => ({...pd }))
        teamList.set(result)
    }

    const handleInputConcern = (e, val, key) => {
        let name, value;
        if (key === undefined) {
            const {name: eventName, value: eventValue} = e.target
            name = eventName
            value = eventValue
        } else {
            name = key
            value = val && val.id ? val.id : ''
        }
        setConcernForm((prev) => {
            const updatedForm = {...prev, [name]:value}
            if (name === "type") {
                updatedForm.category = ''
                if (value) categoryList(value)
            }
            return updatedForm
        })
    }

    const handleView = async(type, id) => {
        if (type === 'view') {
            let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/concern/view`, {
                id: id
            })
            let fdata = res
            if (res.length > 0) {
                setCModal((prev) => ({...prev, data:fdata, view: true}))
            }
          }
    }
 
    const __init = async(val, search) => {
        if (val === 0) {
            isLoading.set(true)
            infntScroll.set(true)
            concernData.set([])
        }
        if (val !== undefined) {
            let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/concern/read`, {
                company_id: __SESSION.data.uaccess[0].company_id,
                ref: __SESSION.data.uaccess[0].role_id === parseInt(uai_user) ? 'ruser' : '',
                user_id: parseInt(uid),
                filter: search,
                limit: 20,
                lastId: val
            })
            if (res) {
                let last_id
                for (let i = 0; i < res.length; i++) { last_id = res[i].id}
                lid.set(last_id)
                if (last_id === 1 || res.length !== 20) {
                    infntScroll.set(false)
                } 

                let result = res.map(pd => ({...pd}))

                if (search !== '') {
                    concernData.set(result)
                } else {
                    concernData.set((prev) => [...prev, ...result])
                }
                isLoading.set(false)
            }
        }
    }

    const [conSearch, setConSearch] = useState('')
    const loadMoreData = () => {
        __init(lid.data, conSearch)
    }

    const searchData = async() => {
        __init(0, conSearch)
    }

    useEffect(() => {
        if (conSearch === '' && atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') {
            concernData.set([])
            __init(0, '')
            handleTeamList()
        } else {
            const searchFunction = setTimeout(() => {
                searchData()
            }, 800) 
            return () => clearTimeout(searchFunction)
        }
        // eslint-disable-next-line
    }, [conSearch])

    return (
        <Box height="100%" width="100%">
            {
                match.params === 'create' || match.params === 'update' ?
                <Box width="100%" >
                    <ConForm match={match} categoryType={categoryType} concernForm={concernForm} setConcernForm={setConcernForm} ctgyTypeList={ctgyTypeList} handleInputConcern={handleInputConcern} __SESSION={__SESSION} handleTeamList={handleTeamList} teamList={teamList} __init={__init}/>
                </Box>
                :
                <Box height="100%" width="100%" bgcolor={ctgyTypeList.data.length === 0 && teamList.data.length === 0 ? 'transparent' : '#FFFFFF'} borderRadius="12px" border="1px solid #EBEBEB">
                    { 
                        !isLoading.data && ctgyTypeList.data.length === 0 && teamList.data.length === 0 ?
                            <WarningAdmin text={__SESSION.data.uaccess[0].role_id === parseInt(uai_user) ? 'Contact Admin': 'Setup'} subtext={__SESSION.data.uaccess[0].role_id === parseInt(uai_user) ? 'Please contact your admin to setup Speak Out.': 'Please setup Category and Team.'}/>
                        :
                        <ConList match={match} concernData={concernData} concernForm={concernForm} setConcernForm={setConcernForm} __SESSION={__SESSION} uai_user={uai_user} setCModal={setCModal} cModal={cModal} handleView={handleView} isLoading={isLoading} conSearch={conSearch} setConSearch={setConSearch} loadMoreData={loadMoreData}/>
                    }
                </Box>
            }
        </Box>
    )
}