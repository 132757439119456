import { useEffect, useState } from "react";
import { Box, IconButton, Slide } from "@mui/material";
import { Close as CloseIcon, Add as AddIcon}  from '@mui/icons-material';
import { motion } from "framer-motion";
import { FavToolTip } from "../../../../../core/global/ToolTip";
import { S3Upload } from "../../../../../core/transmit/S3";
import { rqx } from "../../../../../core/request/API";
import { env } from "../../../../../core/Env";
import { NotifCount } from "../../../../../core/request/Notif";

export const Add = ({ match, __SESSION, cloud_state, getRootProps, getInputProps, __InitDocuFile, user_id, company_id }) => {
    const { sufficientStorage, insuffShow, addMenu, openModal, folderName, err, selectedFiles, uploading, reloadStorage, openMaxFile, message, successOpen, errOpen } = cloud_state
    const urlParams = new URLSearchParams(window.location.search)
    // const [tipShow, setTipShow] = useState(false)

    const btnList = [
        {name: 'Folder'},
        {name: 'Upload File'},
        {name: 'Upload Folder'},
    ]

    const handlerSelectAdd = async(name) => {
        if (name === 'Folder') {
            openModal.set(true)
            folderName.set('')
            addMenu.set(null)
            err.set({...err, val : false, msg: ''})
        }else if (name === 'Upload Folder'){
            selectedFiles.set([])
        }
    }
    
    const handlerAddIcon = (e) => {
        if (sufficientStorage.data) {
            insuffShow.set(true)
        }else{
            if (Boolean(addMenu.data)) {
                addMenu.set(null)
            }else{
                addMenu.set(e.currentTarget)
            }
        }   
    }

    let data = []
    const LoaderFunction = async(rawFiles) => {
        let startStorage = 11000
        let fecthingStorage = 0
        let count = 0;
        const timer = setInterval(() => {
            if (count === 0) {
                if (startStorage >= rawFiles[0].size) {
                    clearInterval()
                    fecthingStorage = 99
                }else{
                    fecthingStorage = (startStorage / rawFiles[0].size) * 100
                    startStorage = startStorage * 2
                }
                selectedFiles.set((prevProgress) => {
                    return prevProgress.map((v) => v.file_size === rawFiles[0].size ? {...v, progress : Math.round(fecthingStorage)} : {...v, progress:v.progress})
                });
            }
        }, 500);

        let s3Result = await S3Upload(rawFiles, selectedFiles)
        if (s3Result) {
            count = 1;
            let arry = selectedFiles.data.map((v) => v.file_size === rawFiles[0].size ? {...v, progress : 100} : {...v, progress:v.progress})
            let fltrArry = arry.filter((v) => { return v.file_size === rawFiles[0].size } )
            data.push({...fltrArry[0]})
            const mergeArr = arry.map((md) => {
                const au = data.find((au) => au.file_name === md.file_name);
                return {...md, ...au};
            });
            selectedFiles.set(
                mergeArr
            )
            clearInterval(timer)
            startStorage = 11000;
            fecthingStorage = 0;
            count = 0;
            return s3Result.location
        }
    }

    const __initUploadFiles = async() => {
        let rawFiles = [...selectedFiles.data]
        let s3Files = []
        if (rawFiles.length !== 0) {
            for (let i = 0; i < rawFiles.length; i++) {
                let s3Result = await LoaderFunction(rawFiles[i].root)
                await s3Files.push({'at_id': i, 'file_name': rawFiles[i].file_name, 'file_size': rawFiles[i].file_size, 'file_type': rawFiles[i].file_type, 'file_link': s3Result })
            }
            let body = {
                type: 'create_file',
                company_folder_id: match.params === 'list' ? '' : window.atob(urlParams.get('f_id')),
                user_id: user_id,
                company_id: company_id,
                status: 1,
                file: s3Files,
            }
            const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/documents/create`, body)
            
            if (res.msg === 'success') {
                await __InitDocuFile(0, 'folder')
                await __InitDocuFile(0, 'file')
                message.set('Successfully Upload')
                successOpen.set(true)
                reloadStorage.set(true)
                let fltrData = [{user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]), file:'file'}]
                await NotifCount(fltrData, __SESSION, `create`)
            }else if (res.msg === 'existing') {
                addMenu.set(null)   
                errOpen.set(true)
                message.set(`${res.exist[0]} Already Exist` )
            }else{
                alert('Please Reload the page or contact our hero happiness team.')
            }

            if ( (res.msg === 'success' || res.msg === 'existing') && res.exist.length > 0) {
                const mergeArr = selectedFiles.data.map((md) => {
                    const td = res.exist.find((td) => td === md.file_name);
                    return {...md, error:td === undefined ? false : true, progress:td === undefined ? 100 : 0};
                });
                selectedFiles.set(mergeArr)
            }else{
                selectedFiles.set([])
                uploading.set({stat:false, type:'file'})
            }
        }
    }
    
    const [totalSizeFile, setTotalSizeFile] = useState(0)
    const __initUploadFolder = async() => {
        let file = [...selectedFiles.data]
        let dataFile = []        
        for (let i = 0; i < file.length; i++) {
            let s3Upload = await LoaderFunction(file[i].root)
            let slice = file[i].webkitRelativePath.replace(`/${file[i].name}`, '')
            let splitArry = slice.split('/')
            let sdata = []
            for (let j = 0; j < splitArry.length; j++) {
                if (file[i].file_name !== splitArry[j]) {
                    sdata.push({name:splitArry[j], parent_name:splitArry[j-1] === undefined ? '' : splitArry[j-1]})
                }
            }
            dataFile.push(
                {
                    folder_name:sdata, 
                    file_name:file[i].file_name, 
                    file_size:file[i].file_size,
                    file_type:file[i].file_type,
                    file_link:s3Upload,
                }
            )
        }

        let data = {
            parent_id: match.params === 'list' ? 0 : window.atob(urlParams.get('f_id')),
            totalSize:totalSizeFile,
            file:dataFile,
            user_id: user_id,
            company_id: company_id,
            status: 1,
            filter: 'insert'
        }
        // console.log(JSON.stringify(data))
        const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/documents/uploadFolder`, data)
        if (res === 'success') {
            uploading.set({stat:false, type:'folder'})
            reloadStorage.set(true)
            await __InitDocuFile(0, 'folder')
            await __InitDocuFile(0, 'file')
            
            setTotalSizeFile(0)
            message.set('Successfully Upload')
            successOpen.set(true)
            selectedFiles.set([])
            let fltrData = [{user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]), file:'folder'}]
            await NotifCount(fltrData, __SESSION, `create`)
        }
    }
    
    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (uploading.data.stat) {
                if (uploading.data.type === 'file') {
                    __initUploadFiles()
                }else{
                    __initUploadFolder()
                }
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line 
    }, [uploading.data.stat])

    const handleUploadFolder = async(e) => {
        e.stopPropagation()
        let file = e.target.files
        let total = 0
        for (let i = 0; i < file.length; i++) {
            total = total + file[i].size
        }
        setTotalSizeFile(total)

        if (total > 25 * 1024 * 1024) {
            openMaxFile.set(true)
        }else{
            let body = {
                parent_id: match.params === 'list' ? 0 : window.atob(urlParams.get('f_id')),
                folder_name: file[0].webkitRelativePath.split('/')[0],
                user_id: user_id,
                company_id: company_id,
                filter: 'validate'
            }
            const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/documents/uploadFolder`, body)
            if (res === 'existing') {
                addMenu.set(null)   
                errOpen.set(true)
                message.set('Folder Name Already Exist')
            }else{
                for (let i = 0; i < file.length; i++) {
                    selectedFiles.set((prev) => prev.concat({progress: 0, file_name: file[i].name, file_type: file[i].type, file_size: file[i].size, link: URL.createObjectURL(file[i]), webkitRelativePath:file[i].webkitRelativePath, root: Array(file[i]) }))
                }
                addMenu.set(null)
                uploading.set({stat:true, type:'folder'})
            }
        }
    }


    return (
        <Box position="fixed" display="flex" flexDirection="column" alignItems="flex-end" 
            bottom={75} right={40} zIndex={2000} 
            sx={{ transition: 'bottom 0.3s ease 0s'}}
        >
            <Slide in={Boolean(addMenu.data)} direction="left">    
                <Box mb={1}>
                    {
                        btnList.map((v,k) => (
                            <Box bgcolor="#333333" width="100%" textAlign="center" borderRadius="4px 0px 4px 4px" key={k}
                                onClick={() => handlerSelectAdd(v.name)}
                                p={1} fontSize={12} fontWeight={400} lineHeight="12px" 
                                color="#F2F7FF" mb={1} component={motion.div}
                                whileTap={{backgroundColor: '#E2EDE7'}}
                            >
                                {v.name === 'Upload File' || v.name === 'Upload Folder' ? 
                                    <>
                                        {v.name === 'Upload Folder' ?
                                            <input type="file" id="folder" directory="" webkitdirectory="" multiple hidden width="100%" onChange={(e) => handleUploadFolder(e)} />
                                        :
                                            <input type="file" id="file" multiple hidden width="100%" onChange={() => getRootProps().onClick} {...getInputProps()} />
                                        }
                                        <label htmlFor={v.name === 'Upload Folder' ? "folder" : "file"}>
                                            {v.name}
                                        </label>
                                    </>
                                : 
                                    v.name
                                }
                            </Box>
                        ))
                    }
                </Box>
            </Slide>
            <FavToolTip open={Boolean(addMenu.data)} title={''}  placement="left" arrow>
                <IconButton 
                    onClick={(e) => handlerAddIcon(e)}
                    sx={{bgcolor: Boolean(addMenu.data) ? "#FFFFFF" : "#107038B8", height: 48, width: 48, 
                        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)', 
                        '&:hover': { bgcolor: Boolean(addMenu.data) ? "#FFFFFF" : "#107038B8"}
                    }}
                >
                    {Boolean(addMenu.data) ? <CloseIcon sx={{ color: "#333333"}} /> : <AddIcon sx={{ color: "#FFFFFF"}} />} 
                </IconButton>
            </FavToolTip>
        </Box>
    )
}