import { useContext, useState } from "react";
import { Box } from "@mui/material";
import { GMTourstyle } from "../../../core/styling/tour";
import Joyride from "react-joyride";
import { ToolContext } from "../../../core/context/ToolContext";

export const RegPzl = () => {
    const { ubr_state, cloud_state } = useContext(ToolContext)
    const { ubrTour, welcomeModal } = ubr_state 
    const { modal, form } = cloud_state 

    const [regSelectCont] = useState([
        {
            target: '.ubr-select',
            disableBeacon: true,
            placement:'top',
            content:
                <Box fontSize={14} fontWeight={500} display="flex" alignItems="center" justifyContent="center">
                    Choose a registration type from the files you will upload.
                </Box>
        }
    ])
    const [regUploadCont] = useState([
        {
            target: '.ubr-upload',
            disableBeacon: true,
            placement:'bottom', 
            content:
                <Box fontSize={14} fontWeight={500} display="flex" alignItems="center" justifyContent="center">
                    Upload the file, which should be an image or PDF, with a maximum size of at least 3 MB.
                </Box>
        }
    ])

    const regInfoCont = [
        {
            target: '.ubr-regInfo',
            disableBeacon: true,
            placement:'bottom', 
            content:
                <Box fontSize={14} fontWeight={500} display="flex" alignItems="center" justifyContent="center">
                    Please provide your {form.data.regName.val === 0 && 'registration name, the'} name and number on the register, or indicate if your uploaded files have an expiration date.
                </Box>
        }
    ]

    return (
        <>
            {(ubrTour.data.reg.select && modal.data.openRegList.val && !welcomeModal.data) && (
                <Joyride
                    steps={regSelectCont} 
                    run={ubrTour.data.reg.select}
                    continuous={false}
                    hideCloseButton={true}
                    spotlightClicks={true}
                    disableCloseOnEsc={true}
                    spotlightPadding={0}
                    disableOverlay={false}
                    disableOverlayClose={true}
                    disableScrolling={true}
                    disableScrollParentFix={true}
                    showSkipButton={false}
                    styles={GMTourstyle('11px', 'ubr')}
                />
            )}
            {(ubrTour.data.reg.upload && !modal.data.openRegList.val && form.data.regFile.file.val === '' ) && (
                <Joyride
                    steps={regUploadCont} 
                    run={ubrTour.data.reg.upload}
                    continuous={false}
                    hideCloseButton={true}
                    spotlightClicks={true}
                    disableCloseOnEsc={true}
                    spotlightPadding={0}
                    disableOverlay={false}
                    disableOverlayClose={true}
                    disableScrolling={true}
                    disableScrollParentFix={true}
                    showSkipButton={false}
                    styles={GMTourstyle('11px', 'ubr')}
                />
            )}
            {(form.data.regFile.file.val !== '' ) && (
                <Joyride
                    steps={regInfoCont} 
                    run={ubrTour.data.reg.info}
                    continuous={true}
                    hideBackButton={true}
                    hideCloseButton={true}
                    spotlightClicks={false}
                    disableCloseOnEsc={true}
                    spotlightPadding={7}
                    disableOverlay={false}
                    disableOverlayClose={true}
                    disableScrolling={true}
                    disableScrollParentFix={true}
                    showSkipButton={true}
                    locale={{
                        last:'Got it',
                    }}
                    styles={GMTourstyle('11px', 'last-ubr')}
                />
            )}
        </>
    )
}