import { Avatar, Box, Button, Dialog } from "@mui/material"
import { Logo } from "../../../core/global/Icons"
import { makeStyles } from "@mui/styles"
import { VM } from '../../../core/VM'
import InstallLogo from '../../../assets/images/core/install_app.gif'
import { useState } from "react"
import { domain } from "../../../core/Env"

let view = VM()

const custom = makeStyles(theme => ({
    bg1: {
        backgroundPosition: view === '' ? 'right' : 'center', 
        backgroundSize: '61%',
        backgroundColor: '#fbfbfb',
        backgroundImage: `url(${InstallLogo})`,
        backgroundRepeat: 'no-repeat',
    }
}))

export const InstallPromotion = ({ install_state }) => {
    const classes = custom()

    const installHandler = async () => {
        if (install_state.prompt.data !== null) {
            install_state.prompt.data.prompt();
            const { outcome } = await install_state.prompt.data.userChoice;

            if (outcome === 'accepted') {
                install_state.ip.set({stat: false, prompt_type: null})
            }
        }
    }

    const logoutHandler = () => {
        install_state.ip.set({stat: false, prompt_type: null})
        localStorage.removeItem('biz_session')
        window.location.href = `${domain('pm').url}/biz/login`
    }

    // if ('serviceWorker' in navigator && window.matchMedia('(display-mode: standalone)').matches) {
    // // PWA is supported and installed
    // } else if ('serviceWorker' in navigator) {
    // // PWA is supported but not installed
    // } else {
    // // PWA is not supported
    // }

    const [browser] = useState([
        {id:1, name:'Google Chrome', url:'https://pofsis-business.s3.amazonaws.com/biz/1680684612476.webp', desc:'Tap the three dots in the top-right corner of the screen, then select <b>"Add to Home Screen"</b> or <b>"install POFBusiness"</b> or <b>"Open in POFBusiness"</b> from the menu.'},
        {id:2, name:'Safari (iOS)', url:'https://pofsis-business.s3.amazonaws.com/biz/1680684714652.png', desc:'Tap the <b>"Share"</b> button (the square with an upward arrow), then select <b>"Add to Home Screen"</b> or <b>"install POFBusiness"</b> or <b>"Open in POFBusiness"</b> from the list of options.'},
        {id:3, name:'Firefox', url:'https://pofsis-business.s3.amazonaws.com/biz/1680684759972.png', desc:'Tap the three dots in the top-right corner of the screen, then select "Page Options" and <b>"Add to Home Screen"</b> or <b>"install POFBusiness"</b> or <b>"Open in POFBusiness"</b> from the menu.'},
        {id:4, name:'Microsoft Edge', url:'https://pofsis-business.s3.amazonaws.com/biz/1680684792804.png', desc:'Tap the three dots in the bottom-right corner of the screen, then select <b>"Add to Home Screen"</b> or <b>"install POFBusiness"</b> or <b>"Open in POFBusiness"</b> from the menu.'},
    ])
    
    return (
        <Dialog open={install_state.ip.data.stat} keepMounted fullWidth={true} onClose={() => install_state.ip.set({stat: false, prompt_type: null})} 
        maxWidth={view === '' ? 'md' : 'xs'}
        PaperProps={{
            style: {
                backgroundColor: 'transparent',
                boxShadow: 'none'
            }
        }}>
            {
                'serviceWorker' in navigator && window.matchMedia('(display-mode: standalone)').matches ?
                    (view === '/devices/mobile' ? 
                        <Box display="flex" justifyContent="center" height="400px" width="100%">
                            <Box borderRadius={4} p={2} bgcolor="secondary.light" width="100%" >
                                <HeaderLogo/>
                                <Box textAlign="center" fontSize={18} my={1}><b>Thank you for installing POFBusiness App.</b></Box>
                                <Box textAlign="center" fontSize={14}>
                                    Now you can access this tool easier and faster. 
                                </Box>
                                <Box textAlign="center" fontSize={14}>
                                    Easy-to-use mobile business management.
                                </Box>
                                <Box width="100%" display="flex" justifyContent="center">
                                    <Box borderRadius={4} bgcolor="secondary.light" width="100%" height="180px" className={classes.bg1}
                                        sx={{backgroundPosition:'center !important'}}
                                    />
                                </Box>
                                <Box mt={1} textAlign="center" fontSize={14}>Click outside to exit</Box>
                            </Box>
                        </Box>
                    :
                        <Box display="flex" justifyContent="center" height="400px">
                            <Box borderRadius={4} p="16px 16px 0 0" bgcolor="secondary.light" width="100%" className={classes.bg1}>
                                <Box p={3} pt={5} pl={10} width="53%">
                                    <HeaderLogo/>
                                    <Box fontSize={18} mb={1}><b>Thank you for installing POFBusiness App.</b></Box>
                                    <Box textAlign="left" fontSize={14}>
                                        Now you can access this tool easier and faster
                                    </Box>
                                    <Box textAlign="left" fontSize={14} mb={4}>
                                        Easy-to-use mobile business management.
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )
            :
                ('serviceWorker' in navigator && install_state.prompt.data !== null) ? 
                    (view === '/devices/mobile' ? 
                        <Box display="flex" justifyContent="center" height="400px" width="100%">
                            <Box borderRadius={4} p={2} bgcolor="secondary.light" width="100%" >
                                <HeaderLogo/>
                                <Box textAlign="center" fontSize={18} my={1}><b>Before you proceed!</b></Box>
                                <Box textAlign="center" fontSize={14}>
                                    Let's make your extraordinary journey in POFSIS easier by installing POFBusiness in your device.
                                </Box>
                                <Box my={install_state.ip.data.prompt_type === 'logout' ? 1 : 2} borderRadius={4} bgcolor="secondary.light" width="100%" height="100px" className={classes.bg1}/>
                                <Installbtn install_state={install_state} logoutHandler={logoutHandler} installHandler={installHandler}/>
                            </Box>
                        </Box>
                    :
                        <Box display="flex" justifyContent="center" height="400px">
                            <Box borderRadius={4} p="16px 16px 0 0" bgcolor="secondary.light" width="100%" className={classes.bg1}>
                                <Box p={3} pt={5} pl={10} width="53%">
                                    <HeaderLogo/>
                                    <Box fontSize={18} mb={1}><b>Before you proceed!</b></Box>
                                    <Box textAlign="left" fontSize={14} mb={4}>
                                        Let's make your extraordinary journey in POFSIS easier by installing POFBusiness in your device.
                                    </Box>
                                    <Installbtn install_state={install_state} logoutHandler={logoutHandler} installHandler={installHandler}/>
                                </Box>
                            </Box>
                        </Box>
                    )
                :
                    <Box display="flex" justifyContent="center" height={{xs:'450px', sm:'400px'}} width="100%">
                        <Box borderRadius={4} bgcolor="secondary.light" width="100%" height="100%" display="flex" flexDirection="column">
                            <Box minHeight="16px"></Box>
                            <Box minHeight="50px" px={{xs:3, sm:4}} mb={view === '' ? 2 : 0 }>
                                <HeaderLogo/>
                            </Box>
                            <Box minHeight="auto" px={{xs:3, sm:4}} pb={2}>
                                <Box textAlign="center" fontSize={{xs:14, sm:18}} my={1}><b>Already Installed</b> or <b>Unsupported Browser for Installation</b></Box>
                                <Box textAlign="center" fontSize={{xs:12, sm:14}}>
                                   Try these steps to <b>"Add to Home Screen"</b> or <b>"install POFBusiness"</b> or <b>"Open in POFBusiness"</b> in a different browsers. 
                                </Box>
                            </Box>
                            <Box pl={{xs:3, sm:4}} pr={{xs:2, sm:3}} mr={1} height="100%" 
                                className="overflowY scrollXColor cstmscroll"
                            >
                                {browser.map((v,k) => (
                                    <Box key={k} width="100%" fontSize={{xs:12, sm:14}} mb={1}>
                                        <Box fontWeight={700} display="flex" alignItems="center" gap="4px">
                                            <Avatar sx={{width:'24px', height:'24px'}} src={v.url} variant="rounded"/> 
                                            <Box>{v.name} :</Box>
                                        </Box>
                                        <Box pl={5} component="div" dangerouslySetInnerHTML={{ __html: v.desc }}/>
                                    </Box>
                                ))}
                            </Box>
                            <Box minHeight="30px">
                                <Box mt={1} textAlign="center" fontSize={{xs:12}}>Click outside to exit</Box>
                            </Box>
                            <Box minHeight="16px"></Box>
                        </Box>
                    </Box>
                } 
        </Dialog>
    )
}

const HeaderLogo = () => {
    return (
        <Box display="flex" justifyContent={view === '' ? 'flex-start' : 'center'} alignItems="center" mb={view === '' ? 2 : 0 }>
            <Box animate={{ x: [100, 0], rotate: [30, -30, 0], opacity: [0, 1]}} transition={{ duration: 0.8 }} mt={0.5} zIndex={2}>
                <Logo size={view === '' ? 60 : 40}/>
            </Box>
            <Box animate={{ x: [-80, 0], opacity: [0, 1]}} transition={{ duration: 0.8, delay: 0.8 }} ml={1} zIndex={1}>
                <Box color="primary.main" fontSize={view === '' ? 24 : 14} >POF<strong>Business</strong></Box>
                <Box fontSize={12}>
                    Powered by: POFSIS
                </Box>
            </Box>
        </Box>
    )
}

const Installbtn = ({install_state, logoutHandler, installHandler}) => {
    return (
        <Box textAlign="center">
            {install_state.prompt.data === null ? 
                <Box fontWeight={500} py={1} bgcolor="rgba(0, 0, 0, 0.12)" sx={{borderRadius: '8px', width: '100%'}} >INSTALLED</Box>
            :
                <Button variant="contained" color="primary" sx={{borderRadius: '8px', boxShadow: 'none', width: '100%'}} onClick={installHandler}> {install_state.prompt.data !== null ? 'INSTALL NOW' : 'INSTALLED'} </Button>
            }
            {
                install_state.ip.data.prompt_type === 'first_prompt' ? (
                    <Box mt={3} fontSize={14}>Click outside to exit</Box>
                ) : install_state.ip.data.prompt_type === 'logout' ? (
                    <Box mt={view === '' ? 3 : 2} fontSize={14} onClick={logoutHandler} className="c-pointer">Proceed to logout</Box>
                ) : (
                        
                    <Box mt={3} fontSize={14} className="c-pointer"></Box>
                )
            }
        </Box>
    )
}