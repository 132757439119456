import { Box, Modal, Slide, Typography, Button } from '@mui/material';

export const NoAccessModal = () => {

    const handleClose = async() => {
        localStorage.removeItem('biz_session')
        window.location.reload()
    };
    
    return (  
        <Modal open={true} className="noutlined">
            <Slide in={true} direction='up' className="noutlined">
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <Box width="372px" bgcolor="#FFFFFF" p="24px" borderRadius="6px">
                    <Typography fontSize={20} fontWeight={400} lineHeight="32px" color="#333333" flexWrap="wrap" textAlign="center">
                        You don't currently have permission to access this page.
                    </Typography>
                    <Box pt="20px" textAlign="center">
                        <Box color="#A2A3A9" fontSize={14} fontWeight={400} lineHeight="23px" >Contact your administrator for assistance.</Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap="8px" mt="32px">
                        <Button fullWidth variant="contained" disableElevation onClick={handleClose}
                            sx={{ minHeight:'40px', borderRadius:'6px', bgcolor: '#107038', color: '#FFFFFF', '&:hover': { bgcolor: '#16522F' } }} 
                        >
                            OK 
                        </Button>
                    </Box>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    );
}