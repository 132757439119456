//NUMBER SEQUENCE
export const SearchIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 20 20">
            <defs>
                <clipPath id="clipPath">
                <rect id="Rectangle_26506" data-name="Rectangle 26506" width="24" height="24" transform="translate(0 -0.188)" fill="#a4beba" stroke="#707070" strokeWidth="1" opacity="0.57"/>
                </clipPath>
            </defs>
            <g id="Mask_Group_2608" data-name="Mask Group 2608" transform="translate(0 0.188)" clipPath="url(#clip-path)">
                <g id="search-2-line">
                <path id="Path_70925" data-name="Path 70925" d="M0,0H20V20H0Z" fill="none"/>
                <path id="Path_70926" data-name="Path 70926" d="M9.667,2.167a7.5,7.5,0,1,1-7.5,7.5A7.5,7.5,0,0,1,9.667,2.167Zm0,13.333A5.833,5.833,0,1,0,3.833,9.667,5.832,5.832,0,0,0,9.667,15.5Zm7.071.059L19.1,17.916,17.916,19.1l-2.357-2.358,1.178-1.178Z" transform="translate(-0.5 -0.5)" fill="#11783C"/>
                </g>
            </g>
        </svg>
    )
}
export const SortTable = () => {
    return (
        // <svg id="vuesax_linear_menu" data-name="vuesax/linear/menu" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        //     <g id="menu">
        //         <path id="Vector" d="M0,0H18" transform="translate(3 7)" fill="none" stroke="#11783c" strokeLinecap="round" strokeWidth="2"/>
        //         <path id="Vector-2" data-name="Vector" d="M0,0H18" transform="translate(3 12)" fill="none" stroke="#11783c" strokeLinecap="round" strokeWidth="2"/>
        //         <path id="Vector-3" data-name="Vector" d="M0,0H18" transform="translate(3 17)" fill="none" stroke="#11783c" strokeLinecap="round" strokeWidth="2"/>
        //         <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0"/>
        //     </g>
        // </svg>  
        <svg id="Component_192_8" data-name="Component 192 – 8" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="vuesax_linear_textalign-justifycenter" data-name="vuesax/linear/textalign-justifycenter" transform="translate(-364 -188)">
                <g id="textalign-justifycenter">
                <path id="Vector" d="M0,0H18" transform="translate(367 192.5)" fill="none" stroke="#1b1b1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-2" data-name="Vector" d="M0,0H18" transform="translate(367 197.5)" fill="none" stroke="#1b1b1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-3" data-name="Vector" d="M0,0H18" transform="translate(367 202.5)" fill="none" stroke="#1b1b1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-4" data-name="Vector" d="M0,0H18" transform="translate(367 207.5)" fill="none" stroke="#1b1b1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <g id="Vector-5" data-name="Vector" transform="translate(388 212) rotate(180)" fill="none" opacity="0">
                    <path d="M0,0H24V24H0Z" stroke="none"/>
                    <path d="M 1 1 L 1 23 L 23 23 L 23 1 L 1 1 M 0 0 L 24 0 L 24 24 L 0 24 L 0 0 Z" stroke="none" fill="#1b1b1b"/>
                </g>
                </g>
            </g>
        </svg>
    )
}

export const SortGrid = ({fill = 'none'}) => {
    return (
        <svg id="element-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path id="Vector" d="M8.5,6.52V1.98C8.5.57,7.86,0,6.27,0H2.23C.64,0,0,.57,0,1.98V6.51C0,7.93.64,8.49,2.23,8.49H6.27C7.86,8.5,8.5,7.93,8.5,6.52Z" transform="translate(13.5 2)" fill={fill} stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Vector-2" data-name="Vector" d="M8.5,6.27V2.23C8.5.64,7.86,0,6.27,0H2.23C.64,0,0,.64,0,2.23V6.27C0,7.86.64,8.5,2.23,8.5H6.27C7.86,8.5,8.5,7.86,8.5,6.27Z" transform="translate(13.5 13.5)" fill={fill} stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Vector-3" data-name="Vector" d="M8.5,6.52V1.98C8.5.57,7.86,0,6.27,0H2.23C.64,0,0,.57,0,1.98V6.51C0,7.93.64,8.49,2.23,8.49H6.27C7.86,8.5,8.5,7.93,8.5,6.52Z" transform="translate(2 2)" fill={fill} stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Vector-4" data-name="Vector" d="M8.5,6.27V2.23C8.5.64,7.86,0,6.27,0H2.23C.64,0,0,.64,0,2.23V6.27C0,7.86.64,8.5,2.23,8.5H6.27C7.86,8.5,8.5,7.86,8.5,6.27Z" transform="translate(2 13.5)" fill={fill} stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0"/>
        </svg>  
    )
}

export const FilterIcon = () => {
    return (
        <svg id="vuesax_linear_filter-search" data-name="vuesax/linear/filter-search" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="filter-search">
                <g id="Group" transform="translate(3.31 2.01)">
                <path id="Vector" d="M11.01,17.06a2.246,2.246,0,0,1-.91,1.72l-1.41.91a2.058,2.058,0,0,1-3.13-1.72V12.62a3.834,3.834,0,0,0-.81-2.12L.91,6.46A3.323,3.323,0,0,1,0,4.44V2.12A2.051,2.051,0,0,1,2.02,0H15.36a2.027,2.027,0,0,1,2.02,2.02V4.24a3.773,3.773,0,0,1-1.01,2.32" fill="none" stroke="#11783C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
                <g id="Group-2" data-name="Group" transform="translate(12.87 10.12)">
                <path id="Vector-2" data-name="Vector" d="M6.4,3.2A3.2,3.2,0,1,1,3.2,0,3.2,3.2,0,0,1,6.4,3.2Z" fill="none" stroke="#11783C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-3" data-name="Vector" d="M1,1,0,0" transform="translate(6 6)" fill="none" stroke="#121212" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
                <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(24 24) rotate(180)" fill="none" opacity="0"/>
            </g>
        </svg>
    )
}

export const ViewIcon = ({fill = 'none', stroke = '#fff'}) => {
    return (
        <svg id="vuesax_linear_eye" data-name="vuesax/linear/eye" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="eye">
                <path id="Vector" d="M7.16,3.58A3.58,3.58,0,1,1,3.58,0,3.576,3.576,0,0,1,7.16,3.58Z" transform="translate(8.42 8.42)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-2" data-name="Vector" d="M9.785,16.55c3.53,0,6.82-2.08,9.11-5.68a5.326,5.326,0,0,0,0-5.19C16.6,2.08,13.315,0,9.785,0S2.965,2.08.675,5.68a5.326,5.326,0,0,0,0,5.19C2.965,14.47,6.255,16.55,9.785,16.55Z" transform="translate(2.215 3.72)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(24 24) rotate(180)" fill="none" opacity="0"/>
            </g>
        </svg>
    )
}

export const ViewIconActive = () => {
    return (
        <svg id="vuesax_linear_eye" data-name="vuesax/linear/eye" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="eye">
                <path id="Vector-2" data-name="Vector" d="M9.785,16.55c3.53,0,6.82-2.08,9.11-5.68a5.326,5.326,0,0,0,0-5.19C16.6,2.08,13.315,0,9.785,0S2.965,2.08.675,5.68a5.326,5.326,0,0,0,0,5.19C2.965,14.47,6.255,16.55,9.785,16.55Z" transform="translate(2.215 3.72)" fill="#11783C" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector" d="M7.16,3.58A3.58,3.58,0,1,1,3.58,0,3.576,3.576,0,0,1,7.16,3.58Z" transform="translate(8.42 8.42)" fill="#11783C" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(24 24) rotate(180)" fill="none" opacity="0"/>
            </g>
        </svg>
    )
}

export const AddRoundIcon = ({fill = "none"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="31.113" height="31.113" viewBox="0 0 31.113 31.113">
            <g id="x-circle" transform="translate(15.556 1.414) rotate(45)">
                <circle id="Ellipse_88" data-name="Ellipse 88" cx="10" cy="10" r="10" transform="translate(0 0)" fill={fill} stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                <line id="Line_289" data-name="Line 289" x1="6" y2="6" transform="translate(7 7)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                <line id="Line_290" data-name="Line 290" x2="6" y2="6" transform="translate(7 7)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            </g>
        </svg>
    )
}

export const NewAddRoundIcon = ({width="24", height="24"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
            <g id="vuesax_linear_add" data-name="vuesax/linear/add" transform="translate(-492 -252)">
                <g id="add">
                <path id="Vector" d="M0,0H12" transform="translate(498 264)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                <path id="Vector-2" data-name="Vector" d="M0,12V0" transform="translate(504 258)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(492 252)" fill="none" opacity="0"/>
                </g>
            </g>
        </svg>
    )
}

export const LeftArrow = ({stroke="#7B7F82", width="24", height="24"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
            <g id="vuesax_linear_arrow-left" data-name="vuesax/linear/arrow-left" transform="translate(-684 -188)">
                <g id="arrow-left">
                <path id="Vector" d="M6.07,0,0,6.07l6.07,6.07" transform="translate(687.5 193.93)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-2" data-name="Vector" d="M16.83,0H0" transform="translate(687.67 200)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(708 212) rotate(180)" fill="none" opacity="0"/>
                </g>
            </g>
        </svg>
    )
}

export const EditProfile = ({fill = 'none', stroke = '#292d32'}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="edit-2" transform="translate(-684 -252)">
                <path id="Vector" d="M9.263,1.439l-8.21,8.69a3.05,3.05,0,0,0-.67,1.43L.013,14.8a1.527,1.527,0,0,0,1.87,1.77l3.22-.55a2.871,2.871,0,0,0,1.39-.75l8.21-8.69c1.42-1.5,2.06-3.21-.15-5.3C12.353-.791,10.683-.061,9.263,1.439Z" transform="translate(687.997 254.161)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-2" data-name="Vector" d="M0,0A6.126,6.126,0,0,0,5.45,5.15" transform="translate(695.89 257.05)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-3" data-name="Vector" d="M0,0H18" transform="translate(687 274)" fill={fill} stroke={fill === 'none' ? '#292d32' : fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(684 252)" fill={fill} opacity="0"/>
            </g>
        </svg>
    )
}

export const NewRemoveRoundIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="vuesax_linear_add" data-name="vuesax/linear/add" transform="translate(-492 -252)">
                <g id="add">
                <path id="Vector" d="M0,0H12" transform="translate(498 264)" fill="none" stroke="#343434" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(492 252)" fill="none" opacity="0"/>
                </g>
            </g>
        </svg>

    )
}

export const ArrowRightUser = () => {
    return (
        <svg id="vuesax_linear_arrow-right" data-name="vuesax/linear/arrow-right" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="arrow-right">
                <path id="Vector" d="M0,6.388V3.718C0,.408,2.35-.952,5.22.708l2.31,1.34,2.31,1.34c2.87,1.66,2.87,4.37,0,6.03l-2.31,1.34L5.22,12.1C2.35,13.728,0,12.378,0,9.058Z" transform="translate(6 5.612)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(24 24) rotate(180)" fill="none" opacity="0"/>
            </g>
        </svg>
      
    )
}

export const ArrowDownUser = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="arrow-bottom" transform="translate(-620 -316)">
                <path id="Vector" d="M6.4,0h2.67c3.31,0,4.67,2.35,3.01,5.22l-1.34,2.31L9.4,9.84c-1.66,2.87-4.37,2.87-6.03,0L2.031,7.53.691,5.22C-.939,2.35.411,0,3.731,0Z" transform="translate(625.599 322)" stroke="#121212" strokeWidth="1.5" fill="none"/>
                <g id="Vector-2" data-name="Vector" transform="translate(644 340) rotate(180)" fill="none" opacity="0">
                <path d="M0,0H24V24H0Z" stroke="none"/>
                <path d="M 1 1 L 1 23 L 23 23 L 23 1 L 1 1 M 0 0 L 24 0 L 24 24 L 0 24 L 0 0 Z" stroke="none" fill="#707070"/>
                </g>
            </g>
        </svg>   
    )
}

export const ResendIcon = () => {
    return (
        <svg id="vuesax_linear_edit-2" data-name="vuesax/linear/edit-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="vuesax_linear_redo" data-name="vuesax/linear/redo" transform="translate(-172 -316)">
                <g id="redo">
                <path id="Vector" d="M13,10H5A5,5,0,1,1,5,0H16" transform="translate(175.87 324.31)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-2" data-name="Vector" d="M0,5.12,2.56,2.56,0,0" transform="translate(189.57 321.69)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(196 340) rotate(180)" fill="none" opacity="0"/>
                </g>
            </g>
        </svg>
    )
}

export const ResendIconActive = () => {
    return (
        <svg id="redo" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path id="Vector" d="M14.19,0H5.81C2.17,0,0,2.17,0,5.81v8.37C0,17.83,2.17,20,5.81,20h8.37c3.64,0,5.81-2.17,5.81-5.81V5.81C20,2.17,17.83,0,14.19,0Zm1.34,8.22L13.96,9.79a.748.748,0,0,1-1.06,0,.754.754,0,0,1,0-1.06l.26-.26H8.08a2.33,2.33,0,0,0,0,4.66H13a.75.75,0,1,1,0,1.5H8.08a3.83,3.83,0,1,1,0-7.66h5.15l-.33-.32a.75.75,0,1,1,1.06-1.06l1.57,1.57A.754.754,0,0,1,15.53,8.22Z" transform="translate(2 2)" fill="#11783c"/>
            <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(24 24) rotate(180)" fill="none" opacity="0"/>
        </svg>
    )
}

export const RoundInfo = () => {
    return (
        <svg id="round-info-button" xmlns="http://www.w3.org/2000/svg" width="17.254" height="17.254" viewBox="0 0 17.254 17.254">
            <g id="info-outline">
                <path id="Path_3658" data-name="Path 3658" d="M7.764,12.941H9.49V7.764H7.764ZM8.627,0a8.627,8.627,0,1,0,8.627,8.627A8.652,8.652,0,0,0,8.627,0Zm0,15.529a6.9,6.9,0,1,1,6.9-6.9A6.922,6.922,0,0,1,8.627,15.529Zm-.863-9.49H9.49V4.314H7.764Z"/>
            </g>
        </svg>
    )
}

export const ExpandMore = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.789" height="8.426" viewBox="0 0 13.789 8.426">
            <path id="Path_70923" data-name="Path 70923" d="M5.362,6.894,0,1.532,1.532,0,8.426,6.894,1.532,13.789,0,12.257Z" transform="translate(13.789) rotate(90)" fill="#11783c"/>
        </svg>      
    )
}

export const ExpandLess = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.789" height="8.426" viewBox="0 0 13.789 8.426">
            <path id="Path_70923" data-name="Path 70923" d="M5.362,6.895,0,12.257l1.532,1.532L8.426,6.895,1.532,0,0,1.532Z" transform="translate(0 8.426) rotate(-90)" fill="#11783c"/>
        </svg>
    )
}

export const TreeCircle = () => {
    return (
        <svg id="vuesax_linear_more" data-name="vuesax/linear/more" xmlns="http://www.w3.org/2000/svg" width="24" height="9" viewBox="0 0 24 9">
            <g id="more">
                <path id="Vector" d="M2,0A2,2,0,1,0,4,2,2.006,2.006,0,0,0,2,0Z" transform="translate(3 2.5)" fill="none" stroke="#292d32" strokeWidth="1.5"/>
                <path id="Vector-2" data-name="Vector" d="M2,0A2,2,0,1,0,4,2,2.006,2.006,0,0,0,2,0Z" transform="translate(17 2.5)" fill="none" stroke="#292d32" strokeWidth="1.5"/>
                <path id="Vector-3" data-name="Vector" d="M2,0A2,2,0,1,0,4,2,2.006,2.006,0,0,0,2,0Z" transform="translate(10 2.5)" fill="none" stroke="#292d32" strokeWidth="1.5"/>
                <path id="Vector-4" data-name="Vector" d="M0,0H24V9H0Z" fill="none" opacity="0"/>
            </g>
        </svg>      
    )
}

export const RemoveAccessActive = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="vuesax_linear_minus-cirlce" data-name="vuesax/linear/minus-cirlce" transform="translate(-172 -252)">
                <g id="minus-cirlce">
                    <path id="Vector" d="M10,20A10,10,0,1,0,0,10,10.029,10.029,0,0,0,10,20Z" transform="translate(173.92 254)" fill="#11783C" stroke="#11783C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Vector-2" data-name="Vector" d="M0,0H8" transform="translate(179.92 264)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(172 252)" fill="none" opacity="0"/>
                </g>
            </g>
        </svg>
    )
}

export const RemoveAccess = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="vuesax_linear_minus-cirlce" data-name="vuesax/linear/minus-cirlce" transform="translate(-172 -252)">
                <g id="minus-cirlce">
                    <path id="Vector" d="M10,20A10,10,0,1,0,0,10,10.029,10.029,0,0,0,10,20Z" transform="translate(173.92 254)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Vector-2" data-name="Vector" d="M0,0H8" transform="translate(179.92 264)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(172 252)" fill="none" opacity="0"/>
                </g>
            </g>
        </svg>
    )
}

export const Ulistbackicon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="vuesax_bold_arrow-left" data-name="vuesax/bold/arrow-left" transform="translate(-684 -188)">
                <g id="arrow-left">
                <path id="Vector" d="M14.19,0H5.81C2.17,0,0,2.17,0,5.81v8.37C0,17.83,2.17,20,5.81,20h8.37c3.64,0,5.81-2.17,5.81-5.81V5.81C20,2.17,17.83,0,14.19,0ZM16,10.75H5.81l3.01,3.01a.754.754,0,0,1,0,1.06.748.748,0,0,1-1.06,0L3.47,10.53a.748.748,0,0,1,0-1.06L7.76,5.18A.75.75,0,0,1,8.82,6.24L5.81,9.25H16a.75.75,0,0,1,0,1.5Z" transform="translate(686 190)" fill="#fff"/>
                <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(708 212) rotate(180)" fill="none" opacity="0"/>
                </g>
            </g>
        </svg>
    )
}