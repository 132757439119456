/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBusinessTriggerV2 = /* GraphQL */ `
  mutation CreateBusinessTriggerV2($input: CreateBusinessTriggerV2Input!) {
    createBusinessTriggerV2(input: $input) {
      id
      ifRun
    }
  }
`;
export const updateBusinessTriggerV2 = /* GraphQL */ `
  mutation UpdateBusinessTriggerV2($input: UpdateBusinessTriggerV2Input!) {
    updateBusinessTriggerV2(input: $input) {
      id
      ifRun
      uid
    }
  }
`;
export const deleteBusinessTriggerV2 = /* GraphQL */ `
  mutation DeleteBusinessTriggerV2($input: DeleteBusinessTriggerV2Input!) {
    deleteBusinessTriggerV2(input: $input) {
      id
      ifRun
    }
  }
`;
