import { useContext, useEffect, useState } from "react"
import { Box, Container, Modal, useMediaQuery } from "@mui/material"
import { SelectDept } from "./SelectDept"
import { Left } from "./Left" 
import { Right } from "./Right" 
import { UseStyles } from "../../mycloud/global/DesktopDsg"
import { useDropzone } from "react-dropzone"
import { ToolContext } from "../../../core/context/ToolContext"
import { rqx } from "../../../core/request/API"
import { env } from "../../../core/Env"
import { ErrorAlert, } from "../../../core/global/forms/CstmModal"


export const Index = ({match, deptStaticData}) => {
    const { tool_state, policy_state } = useContext(ToolContext)
    const { successOpen, message, actType, saveLoader, errorOpen, pName, plcysn, polForm, plcyData } = policy_state
    const { __SESSION } = tool_state 
    let company_id = __SESSION.data.uaccess[0].company_id;

    // Responsiveness
    const xl = useMediaQuery('(min-width:1921px)');
    const dsg = UseStyles()

    const [open, setOpen] = useState(true);
    const [loader, setLoader] = useState(false);
   
    const onDrop = async(files) => {
        if (files.length === 0) {
            message.set('Uploading is restricted to PDF file formats exclusively.')
            errorOpen.set(true)
            return
        }
        
        let sizelmt = 4194304
        if (files[0].size > sizelmt) {
            message.set('File uploads must not exceed 3MB.')
            errorOpen.set(true)
            return
        }
        
        let _file = files[0];
        polForm.set({...polForm.data, file: files, file_name: _file.name, file_size: _file.size, file_type: _file.type, file_link: URL.createObjectURL(_file)})
        // setForm({...form, file: {name: _file.name, size: _file.size, type: _file.type, link: URL.createObjectURL(_file)}})     
    }

    const {getRootProps, getInputProps} = useDropzone({onDrop, accept: { 'application/pdf' : ['.pdf'] } })

    const __seriesNum = async() => {
        let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/policy/series_num`, {
            company_id: __SESSION.data.uaccess[0].company_id 
        })
        plcysn.set(res)
    }

    const __init = async() => {
        const getAllUsers = await rqx.get(`<<global>>/${env()}/tooluser/gettooluser`, {company_id: company_id !== null ? company_id : 0, tool_id: process.env.REACT_APP_TID, ref: 'active' })
        let samap = getAllUsers.superadmin?.map(v => {return {...v, uname:v.first_name+' '+v.last_name }} )
        let umap = getAllUsers.users?.map(v => {return {...v, uname:v.first_name+' '+v.last_name }} )
        setAllUsers([...samap, ...umap])
    }

    const [allUsers, setAllUsers] = useState([])
    useEffect(() => {
        const __intialize = () => {
            if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0') {
                setAllUsers([{id:1, uname:'Guest'}])
                polForm.set({...polForm.data, departmentname: {id:0, name:'All'}, preparedby:1, approvedby:1 })
            }else{
                __init()
                __seriesNum()
            }
        }
        __intialize()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {errorOpen.data && (
                <ErrorAlert errOpen={errorOpen.data} setErrOpen={errorOpen.set} text={message.data} duration={3000}/>
            )}
            <Modal open={true}>
                <Box height="100%" width="100%" bgcolor="#FFFFFF">
                    <Box component={ xl ? Container : ''}  height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                        <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" p="40px 60px">
                            <Left dsg={dsg} setOpen={setOpen} form={polForm.data} setForm={polForm.set} getRootProps={getRootProps} getInputProps={getInputProps} match={match} loader={loader} setLoader={setLoader} plcysn={plcysn} __SESSION={__SESSION}/>
                        </Box>
                        <Box bgcolor="#F8F9FA" height="100%" width="100%" display="flex" flexDirection="column"  justifyContent="center" p="40px 60px">
                            <Right dsg={dsg} match={match} form={polForm.data} setForm={polForm.set} loader={loader} setLoader={setLoader} __SESSION={__SESSION} allUsers={allUsers} message={message} successOpen={successOpen} actType={actType} saveLoader={saveLoader} errorOpen={errorOpen} pName={pName} plcyData={plcyData} />
                        </Box>
                        {atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0' && (
                            <SelectDept open={open} setOpen={setOpen} form={polForm.data} setForm={polForm.set} deptStaticData={deptStaticData}/>
                        )}
                    </Box>
                </Box>
            </Modal>
        </>
    )
}