/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBusinessTriggerV2 = /* GraphQL */ `
  subscription OnCreateBusinessTriggerV2($id: ID, $ifRun: Boolean) {
    onCreateBusinessTriggerV2(id: $id, ifRun: $ifRun) {
      id
      ifRun
    }
  }
`;
export const onUpdateBusinessTriggerV2 = /* GraphQL */ `
  subscription OnUpdateBusinessTriggerV2($id: ID, $ifRun: Boolean, $uid:String) {
    onUpdateBusinessTriggerV2(id: $id, ifRun: $ifRun, uid:$uid) {
      id
      ifRun
      uid
    }
  }
`;
export const onDeleteBusinessTriggerV2 = /* GraphQL */ `
  subscription OnDeleteBusinessTriggerV2($id: ID, $ifRun: Boolean) {
    onDeleteBusinessTriggerV2(id: $id, ifRun: $ifRun) {
      id
      ifRun
    }
  }
`;
