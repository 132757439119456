import { useContext, useEffect, 
    useState 
} from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { ToolContext } from '../../core/context/ToolContext';
import { rqx } from '../../core/request/API';
import { env } from '../../core/Env';
import { Tourstyle } from '../../core/styling/tour';
import Joyride from "react-joyride";
import { VM } from '../../core/VM';
// import Tour from 'reactour';

// const accentColor = "#64CC90";
const uai_user = process.env.REACT_APP_UAI_REGULAR;

let view = VM()
export const TourPersonalization = ({personalizationData}) => {
    // eslint-disable-next-line
    // const [prevBtnStat, setPrevBtnStat] = useState(false);
    const { tool_state, personalization_state, navigation_state } = useContext(ToolContext);
    const { tc } = navigation_state
    const ps = personalization_state
    const __SESSION = tool_state.__SESSION.data.uaccess
    const showsmallpc = useMediaQuery('(max-width:1199px)');
    const [stepsTour, setStepsTour] = useState({index:0 , name:''});
    // let count = __SESSION[0].role_id === parseInt(uai_user) ? 5 : 6

    const closeTour = async(e) => {
        setStepsTour({...stepsTour, index: e.index, name:e.step.name})
        if (e.action === 'skip' || e.action === 'reset' || e.action === 'stop') {
            ps.isTourOpen.set(false);
            let filter_data = personalizationData.filter(v => { return v.feature_name === 'dashboard'});
            let filter = ps.personalizationData.data.filter(v => { return v.feature_name !== 'dashboard'} );
            let p_data = filter_data[0].personalization.map((v) =>  v.sf_name === 'welcomeTour' ? {...v, status:1} : {...v});
            let f_data = filter_data.map(v => { return {...v, personalization:p_data} } );
            ps.personalizationData.set([...filter, ...f_data]);
            await rqx.post(`<<global>>/${env()}/personalization/create`, f_data[0])
        }
        if( (e.step.name === 'Daily Tasks' || e.step.name === 'My Points') && showsmallpc ){
            tc.set({...tc.data, open: !tc.data.open, list: false})
        }
    };

    // const handler = (e) => {
    //     setPrevBtnStat(e===ps.isSteps.data.length ? true : false)
    // }

    const [subscribe, setSubscribe] = useState(true)
    useEffect(() => {
        if (subscribe) {
            let psz_arr_len;
            let arr_len;
            if (__SESSION[0].role_id === parseInt(uai_user)) {
                if (view === '') {
                    arr_len = ps.isSteps.data.filter(f => { return f.target !== '.seventh-step' && f.target !== '.second-step-second' && f.target !== '.fourth-step' } );
                    psz_arr_len = arr_len.map((v) => v.target === '.eighth-step' ? {...v, desc:'Log in to Personal to grow your career.'} : (v.target === '.ninth-step' ? {...v, desc:'Access your assigned tools here.' } : {...v}) )
                }else{
                    arr_len = ps.isSteps.data.filter(f => { return f.target !== '.third-step' } );
                    psz_arr_len = arr_len.map((v) => v.target === '.fifth-step' ? {...v, desc:'Log in to Personal to grow your career.'} : (v.target === '.seventh-step' ? {...v, desc:'Access your assigned tools here.' } : {...v}) )
                }
            }else if(__SESSION[0].role_name === 'Admin' ){
                if (view === '') {
                    arr_len = ps.isSteps.data.filter(f => { return f.target !== '.seventh-step' && f.target !== '.fourth-step' } );
                    psz_arr_len = arr_len.map((v) => v.target === '.eighth-step' ? {...v, desc:'Log in to Personal to grow your career.'} : (v.target === '.ninth-step' ? {...v, desc:'Access your assigned tools here.' } : {...v}) )
                }else{
                    psz_arr_len = ps.isSteps.data.map((v) => v.target === '.fifth-step' ? {...v, desc:'Log in to Personal to grow your career.'} : (v.target === '.seventh-step' ? {...v, desc:'Access your assigned tools here.' } : {...v}) )
                }
            }else{
                psz_arr_len = ps.isSteps.data;
            }
            
            ps.isSteps.set(
                psz_arr_len.map((v, index) => { return {...v, content: <PszContent k={index} name={v.name} desc={v.desc} len={psz_arr_len.length} /> } } ) 
            )
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [])
  
    return (  
        <Joyride
            steps={ps.isSteps.data} 
            run={ps.isTourOpen.data}
            continuous={true}
            hideBackButton={true}
            hideCloseButton={true}
            spotlightClicks={false}
            disableCloseOnEsc={true}
            spotlightPadding={ 0
                // stepsTour.index >= count ? stepsTour.name === 'Daily Tasks' ? view ? 7 : 0 : 7 : 0
            }
            disableOverlay={false}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            callback={(e)=> closeTour(e)}
            showSkipButton={true}
            locale={{
                last:'Got it',
                skip:'Close',
            }}
            styles={Tourstyle(stepsTour)}
            floaterProps={{
                styles:{
                    floaterOpening:{
                        left: stepsTour.name === 'Personal' ? '-64px' : stepsTour.name === 'My Tools' && view!=='' ? '20px' : 'auto' ,
                        top:stepsTour.name === 'Profile' && view!=='' ? '20px' : 'auto'
                    },
                }
            }}
            
        />
    );
}

//psz mean Personalization
const PszContent = ({k, name, desc, len}) =>{
    return (
        <Box>   
            <Box display='flex' justifyContent='space-between'>
                <Box fontSize={14} fontWeight={400} color="#687B75">{name}</Box>
                <Box fontSize={12} fontWeight={400} color="#687B75"> {k + 1} of {len}  </Box>
            </Box>
            <Box textAlign="left" fontSize={12} fontWeight={400} color="#333333" mt={1}> {desc} </Box>
        </Box>
    )
}