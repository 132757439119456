import { Box } from '@mui/material';
import { Index as Banner } from './banner/Index';
import { ToolFeatures } from './toolFeatures/Index';
import { SystemConcerns } from './systemConcerns/Index';
import { Tools } from './tools/Index';
import { BannerLoader } from '../../loader/MidLoader';
import { RegularUser } from './tools/RegularUser'

export const Index = ({hs, ts, __SESSION, listBanner, banner_state}) => {
    return (
       <Box height="100%" width="100%" display="flex" flexDirection="column">
            {banner_state.isShow.data  ? 
                    <Box width="100%" >
                        <Banner />
                    </Box>
                :
                    <BannerLoader listBanner={listBanner} />
            }
            <Box p="40px 24px 24px 24px" height="100%" width="100%" display="flex" flexDirection="column">
                <ToolFeatures hs={hs} ts={ts} __SESSION={__SESSION} />
                { __SESSION[0].role_id !== parseInt(process.env.REACT_APP_UAI_REGULAR) ? <Tools /> : <RegularUser />} 
                <SystemConcerns />
                <Box minHeight={16} />
            </Box>
            <Box minHeight={20}></Box>
       </Box>
    )
}
