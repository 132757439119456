import { Box, Button } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { ToolContext } from '../../../core/context/ToolContext'
import { Confirmation, NewSuccessAlert } from '../../../core/global/forms/CstmModal'
import { rqx } from '../../../core/request/API'
import { env } from '../../../core/Env'
import { View } from '../modal/View'
import { Loader } from '../../fetching/Loader'
import { CheckBox, CheckBox as CheckBoxIcon, CheckBoxOutlineBlank } from '@mui/icons-material'
import { NoResult } from '../../empty/Index'
import { Top } from './Top'
import { TBody } from './TBody'
import { NoFound } from '../../mycloud/global/DesktopDsg'

let uai_user = process.env.REACT_APP_UAI_REGULAR;

export const Index = ({deptStaticData}) => {

    const { policy_state, tool_state, cloud_state } = useContext(ToolContext)
    const { successOpen, message, plcyData, isLoading, saveLoader } = policy_state
    const { inftScroll, lid } = cloud_state
    const { __SESSION } = tool_state
    const uid = atob(__SESSION.data.ainfo.aid).split('_', 2)[1];

    const icon = <CheckBoxOutlineBlank fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;
    const checkedIcon = <CheckBoxIcon fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;

    const [pModal, setPModal] = useState({
        update: false,
        view: false,
        remove: false,
        id:'',
        loader:false,
        data:[],
        agreeinfo: [],
        list: false,
        count: 0
    })
    
    const [selectAll, setSelectAll] = useState(false)
    const [checkStat, setCheckStat] = useState('')

    const handleCheckChange = (e, value) => {
        setCheckStat(value === 'ALL' ? 0 : 1)
    
        if (value === 'ALL') {
            const newValue = !selectAll
            setSelectAll(newValue)
            let statmap = plcyData.data.map(v => ({...v, stat: v.cp_status !== 3 ? newValue : v.stat}))
            plcyData.set(statmap)
        } else {
            let updatedData = plcyData.data.map(v => v.id === value.id ? {...v, stat: e.target.checked} : v)
            plcyData.set(updatedData)

            // Check if Child Checkbooxes are all checked
            const allChecked = updatedData.filter(v => v.cp_status !== 3).every(v => v.stat);
            setSelectAll(allChecked)
        }
    }

    const handleCancel = () => {
        setPModal({...pModal, update: false, remove: false})
    }

    const handleClick = async(type, ids, cp_status, cpid, count)  => {
        setPModal((prev) => ({...prev, [type]: true, id:ids, cp_status:cp_status}))
       
        if (type === 'view') {
            let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/policy/view`, {id:ids, company_id: __SESSION.data.uaccess[0].company_id})
            let mergeArray = res.map(pd => {
                let fltrDept = deptStaticData.find(dd => { return pd.company_department_id === dd.id })
                return {...pd, dept_name:fltrDept.name}
            })
            if (res.length > 0) {
                setTimeout(() => {
                    isLoading.set(false)
                }, 1000)
                setPModal((prev) => ({...prev, data:mergeArray}))
            }
        }
    }

    const handleSubmit = async() => {
        setPModal({...pModal, loader:true})

        let filter = pModal.remove ? 'remove' : 'update'
        let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/policy/update`, {id: pModal.id, user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1], fltr:filter})

        if (res.message === 'success') {
            if (filter === 'update'){
                let fltr = plcyData.data.map(v => v.id === pModal.id ? {...v, status:2} : {...v})
                plcyData.set(fltr)
                message.set('Successfully Updated.')
            }else{
                let fltr = plcyData.data.filter(v => { return v.id !== pModal.id })
                plcyData.set(fltr)
                message.set('Successfully Removed.')
            }
            setPModal({...pModal, update: false, loader:false, remove: false})
            successOpen.set(true)
            
        }
    }

    const __init = async(val, search) => {
        if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') {
            if (val === 0) {
                isLoading.set(true)
                inftScroll.set(true)
            }
            if (val !== undefined) {
                let deptid = []
                const res_deptid = deptStaticData.filter((item) =>
                    __SESSION.data.uaccess[0].role_id === parseInt(uai_user) ? 
                        item.name.toLowerCase().match(search.toLowerCase()) && (item.id === __SESSION.data.uaccess[0].company_department_id || item.id === 0)
                    :
                        item.name.toLowerCase().match(search.toLowerCase())
                );
                if (res_deptid.length > 0) {
                    res_deptid.filter((v) => { return deptid.push(v.id) })
                }
    
                let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/policy/read`, {
                    company_id: __SESSION.data.uaccess[0].company_id, 
                    lastId:val, 
                    limit:20,
                    filter:search,
                    ref: __SESSION.data.uaccess[0].role_id === parseInt(uai_user) ? 'ruser' : '',
                    dept_id:  JSON.stringify(deptid), 
                    deptOwnedId:  __SESSION.data.uaccess[0].company_department_id, 
                    user_id: parseInt(uid)
    
                })
                
                if (res){
                    let l_id;   
                    for (let i = 0; i < res.length; i++) { l_id = res[i].id}
                    lid.set(l_id);
                    if (l_id === 1 || res.length !== 20 ) {
                        inftScroll.set(false);
                    }
    
                    let mergeArray = res.map(pd => {
                        let fltrDept = deptStaticData.find(dd => { return pd.company_department_id === dd.id })
                        return {...pd, dept_name:fltrDept.name, stat: selectAll}
                    })
                    // sort((a, b) => a.dept_name.localeCompare(b.dept_name))
                    if (search !== '') {
                        plcyData.set(mergeArray);
                    }else {
                        plcyData.set((prev) => [...prev, ...mergeArray]);
                    }
                    isLoading.set(false)
                }
            }
        }
    }
    
    const [pSearch, setPSearch] = useState('');
    const loadMoreData = () => {
        __init(lid.data, pSearch)
    }

    const searchData = async() => {
        __init(0, pSearch)
    }
    // [1,2,3]
    const handleClickAgree = async(id) => {
        let ids = checkStat === 0 ? 'all' : []
        if (id === 'check') {
            if (checkStat !== 0) {
                let fltr = plcyData.data.filter(v => { return v.stat} )
                fltr.map(v => { return ids.push({cpid:v.id, status:3}) })
            }else{
                setSelectAll(!selectAll)
            }
        }else{
            ids.push({cpid:id, status:3})
        }
        
        let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/policy/agree`, {company_id: __SESSION.data.uaccess[0].company_id, dept_id: __SESSION.data.uaccess[0].company_department_id, id: JSON.stringify(ids), user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1]})
        if (res.message === 'success') {
            plcyData.set([])
            __init(0, '')
            message.set('Successfully agreed to Policy.')
            successOpen.set(true)
            if (pModal.view) {
                setPModal((prev) => ({...prev, cp_status:3}))
            }
        }
    }

    useEffect(() => {
        
        if (pSearch === '' && atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') {
            plcyData.set([]);
            __init(0, '')
        } else {
            const searchFunction = setTimeout(() => {
                searchData()
            }, 800);
            return () => clearTimeout(searchFunction)
        }
        // eslint-disable-next-line
    },[pSearch])

    return (
        <>
            <Box height="100%" width="100%">
                <Box height="100%" width="100%" bgcolor="#FFFFFF" borderRadius="12px" py={3} display="flex" flexDirection="column">
                    <Box minHeight="120px" >
                        <Top __SESSION={__SESSION} pSearch={pSearch} setPSearch={setPSearch} uai_user={uai_user} icon={icon} checkedIcon={checkedIcon} selectAll={selectAll} setSelectAll={setSelectAll} handleCheckChange={handleCheckChange} plcyData={plcyData} isLoading={isLoading}/>
                    </Box>
                    {isLoading.data ?
                            <Loader />    
                    :
                        plcyData.data.length === 0 ?
                            pSearch === '' ?
                            <NoFound msg="No Policy Found. Upload Now!"/>
                            :
                            <NoResult />
                        :
                        <TBody plcyData={plcyData} __SESSION={__SESSION} uai_user={uai_user} icon={icon} checkedIcon={checkedIcon} handleClick={handleClick} inftScroll={inftScroll} loadMoreData={loadMoreData} handleCheckChange={handleCheckChange} saveLoader={saveLoader} handleClickAgree={handleClickAgree} />
                    }
                    {__SESSION.data.uaccess[0].role_id === parseInt(uai_user) && plcyData.data.filter((v) => {return v.cp_status === null && v.stat}).length !== 0 &&(
                        <Box minHeight="150px">
                            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" pb={2}>
                                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" p={2} m={2} gap="8px" fontSize="14px" fontWeight={400} lineHeight="20px">
                                    <CheckBox></CheckBox>
                                    <Box>I hereby acknowledge receipt and agree that I have read this policy in its entirety and if there is any policy and provision that I do not understand, I shall seek clarification from my immediate superior.</Box>
                                </Box>
                                <Box display="flex" justifyContent="center" color="#00B74C" fontSize="20px" fontWeight={600} lineHeight="16px" bgcolor="#00be811f" borderRadius="10px" height="40px" width="250px">
                                    <Button onClick={() => handleClickAgree ('check')}>Understood and Agree</Button>
                                </Box>
                            </Box>
                        </Box>
                        )
                    }   
                </Box>
            </Box>
            {successOpen.data && 
                <NewSuccessAlert successOpen={successOpen.data} setSuccessOpen={successOpen.set} text={message.data} />
            }
            {(pModal.update || pModal.remove) && 
                <Confirmation isOpen= { pModal.update || pModal.remove } disabled={pModal.loader} message= {pModal.update ? ` Update the Status to Issued?` : `Once you remove this file you will not be able to access it again.​​` } text={pModal.update ? 'Update' : 'Remove'} proceedBtn= {() => handleSubmit()} cancelBtn= {() => handleCancel()} />
            }
            {(pModal.view && pModal.data.length > 0) &&  
                <View pModal={pModal} setPModal={setPModal} __SESSION={__SESSION} isLoading={isLoading} uai_user={uai_user} plcyData={plcyData} handleClickAgree={handleClickAgree} handleClick={handleClick}/>
            }
        </>
    )
}