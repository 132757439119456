import { Box, IconButton, Modal, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { MblFetchingLoader } from "../../../../fetching/Loader";

export const SelectDept = ({open, setOpen, deptStaticData, form, setForm }) => {
    const nav = useHistory()
    const handleClose = () => {
        nav.push('/policies/list')
        
    }
    
    const handleSelect = (e, val) => {
        setForm({...form, departmentname: {id: val.id, name: val.name}})
        setOpen(false)
    }
    
    return (
        <Modal open={open}>
            <Box height="100%" width="100%" bgcolor="#FFFFFF" display="flex" flexDirection="column" >
                <Box minHeight="63px" p="21px 16px" borderBottom="1px solid #F1F4F4">
                    <IconButton sx={{p: '0px', minHeight: 20, position: 'absolute'}} onClick={handleClose}>
                        <CloseIcon sx={{fontSize: 20}}/>
                    </IconButton>
                    <Box display="flex" justifyContent="center" fontSize="16px" fontWeight={600} lineHeight="26px">Select Department</Box>
                </Box>
                <Box height="100%" width="100%" px={2}>
                    {
                        deptStaticData.length === 0 ?
                            <MblFetchingLoader  mt={0}/>
                        :
                        deptStaticData.map((v,k) => (
                            <Typography key={k} fontSize="14px" fontWeight={400} lineHeight="16px" borderBottom="1px solid #EFF1F4" p="13px 16px" onClick={(e) => handleSelect (e, v)}>
                                {v.name}
                            </Typography>
                        ))
                    }
                </Box>
            </Box>
        </Modal>
    )
}