import { useState } from 'react'
import { Avatar, Box, IconButton, Modal, Slide } from '@mui/material'
import { ArrowLeft, VeriticalThreeDots } from '../../../../../core/global/Icons'
import { FormatFilesize } from '../../../global/DesktopDsg'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { Preview } from './modal/Preview'
import moment from 'moment'

export const FileInfo = ({show, handlerThreeDots}) => {
    const [conData, setConData] = useState(
        {
            file_type:JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_type,
            file_size:JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_size,
            file_link:JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_link,
            file_name:JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_name
        }
    )
    let conFile = JSON.parse(show.data.data.file)
    let validity_date = `${moment(show.data.data.start_date).format('MM/DD/YYYY')} to ${moment(show.data.data.end_date).format('MM/DD/YYYY')}`
    const con = [
        {name: 'Contract Number', val: show.data.data.series_num},
        {name: 'Contract Name', val: show.data.data.contract_name},
        {name: 'Client/Company Name', val: show.data.data.client_name},
        {name: 'Validity Date', val: validity_date},
    ]

    const file_info = [
        {name: 'File Type', val: conData.file_type},
        {name: 'File Size', val: FormatFilesize(conData.file_size)},
    ]
    
    const HandlerImgView = (e, val) => {
        e.preventDefault()
        if (e.detail >= 1) {
            show.set({...show.data, view:true, fullview:true, data: val})
        }
    }

    const HandlerViewTab = async(e, val) => {
        e.preventDefault()
        await setConData({
            file_type:val.file_type,
            file_size:val.file_size,
            file_link:val.file_link,
            file_name:val.file_name,
        })
    }
    
    return (
        <>
            <Modal open={show.data.view} sx={{zIndex: 1302}} className="noutlined">
                <Slide in={show.data.view} direction="left" className="noutlined">
                    <Box height="100%" width="100%" display="flex" flexDirection="column" bgcolor="#F8F9FA">
                        <Box minHeight={44} p="12px 16px" display="flex" alignItems="center" justifyContent="space-between">
                            <IconButton sx={{p: '0px'}} onClick={() => show.set({...show.data, view : false, data : ''})}>
                                <ArrowLeft stroke="#313131" width="16" height="16" />
                            </IconButton>
                            <Box fontSize={16} fontWeight={600} lineHeight="26px">Contracts Details</Box>
                            <IconButton sx={{p: '0px'}} onClick={() => handlerThreeDots(show.data.data)}>
                                <VeriticalThreeDots fill={"#737373"} />
                            </IconButton>
                        </Box>
                        <Box p="12px 16px 24px 16px" maxHeight={216} className="overflowY overflowx c-pointer">
                            <Box bgcolor="#FFFFFF" minHeight={180} p="24px 24px 0px 24px" display="flex" justifyContent="center" alignItems="center">
                                {
                                    conData.file_type.includes('image')? 
                                        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" onClick={(e) => HandlerImgView(e,show.data.data)}>
                                            <Avatar src={conData.file_link} alt={conData.file_link} sx={{height:100, width:'auto', borderRadius:'8px'}}/>
                                        </Box>

                                    :
                                        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                            <Box onClick={(e) => HandlerImgView(e,show.data.data)} sx={{ height: 216, width:'100%', borderRadius:'8px 8px 0px 0px' }}>
                                                <Box component={Viewer} fileUrl={conData.file_link} 
                                                // defaultScale={SpecialZoomLevel.PageFit} 
                                                />
                                            </Box>
                                        </Worker>
                                }
                            </Box>
                        </Box>
                        <Box height="100%" width="100%" bgcolor="#FFFFFF" borderRadius="12px 12px 0px 0px" p={2} className='overflowY'>
                            <Box display="flex" justifyContent="center">
                                <Box height="2px" width="37px" bgcolor="#333333" />
                            </Box>
                            <Box height="56px" mt="16px" display="flex" gap="12px">
                                {conFile.map((v,k) => (
                                    <Box onClick={(e)=>HandlerViewTab(e, v)} key={k} className="c-pointer" width="80px" bgcolor="#E6E6E6" border={`1px solid ${conData.file_link === v.file_link ? '#107038' : '#F8F9FA'}`} borderRadius="6px" display="flex" justifyContent="center">
                                        {
                                            v.file_type.includes('image')? 
                                                <Box sx={{backgroundImage:`url(${v.file_link})`, backgroundSize:"cover", backgroundPosition:"center"}} minHeight="37px" width="62px" mt="10px" borderRadius="6px 6px 0px 0px"/>
                                            :
                                                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                    <Box sx={{ mt:'10px', height:'37px', width:'62px', overflow:'hidden !important', borderRadius:'6px 6px 0px 0px'}}>
                                                        <Box component={Viewer} fileUrl={v.file_link} 
                                                        // defaultScale={SpecialZoomLevel.PageFit} 
                                                        />
                                                    </Box>
                                                </Worker>
                                        }
                                    </Box>
                                )).reverse()}
                            </Box>
                            <Box fontSize={16} fontWeight={600} lineHeight="25px" pt="20px" pb="32px">
                                {show.data.data.contract_name} ({show.data.data.client_name})
                            </Box>
                            <Box fontSize={14} fontWeight={600} lineHeight="16px" mb={2}>Contracts Information</Box>
                            {
                                con.map((v,k) => (
                                    <Box width="100%" display="flex" alignItems="baseline" mb={1} gap="4px" key={k}>
                                        <Box width="50%" color="#737373" fontSize={14} fontWeight={400} lineHeight="19px">
                                            {v.name}
                                        </Box>
                                        <Box width="50%" color="#333333" fontSize={14} fontWeight={400} lineHeight="19px" sx={{wordBreak: 'break-word'}}>
                                            {v.val}
                                        </Box>
                                    </Box>
                                ))
                            }
                            <Box fontSize={14} fontWeight={600} lineHeight="16px" mt={2} mb={2}>File Info</Box>
                            {
                                file_info.map((v,k) => (
                                    <Box width="100%" display="flex" alignItems="baseline" mb={1} gap="4px" key={k}>
                                        <Box width="50%" color="#737373" fontSize={14} fontWeight={400} lineHeight="19px">{v.name}</Box>
                                        <Box width="50%" color="#333333" fontSize={14} fontWeight={400} lineHeight="19px" sx={{wordBreak: 'break-all'}}>{v.val}</Box>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                </Slide>
            </Modal>
            {show.data.fullview && ( 
                <Preview show={show} conData={conData} />
            )}
        </>
    )
}