import { ArrowLeft } from "../../../../../core/global/Icons"
import { Avatar, Box, IconButton, Modal, Slide } from "@mui/material"

export const AgreeList = ({ pModal, setPModal }) => {

    const handlerCloseAgreeList = async() => {
        await setPModal({...pModal, agreelist: false })
    }

    return (
        <Modal open={pModal.agreelist} sx={{zIndex: 1303}} >
            <Slide in={pModal.agreelist} direction="left" className="noutlined">
                <Box bgcolor="#FFFFFF" height="100%" width="100%" display="flex" flexDirection="column">
                    <Box minHeight="44px" p="12px 16px" display="flex" alignItems="center">
                        <Box>
                            <IconButton sx={{p: '0px'}} onClick={handlerCloseAgreeList}>
                                <ArrowLeft stroke="#313131" width={16} height={16} />
                            </IconButton>
                        </Box>
                        <Box display="flex" justifyContent="center" fontSize="16px" fontWeight="600" lineHeight="26px" ml="15px">{pModal.data[0].policyname}</Box>
                    </Box>
                    <Box height="100%" p="12px 16px 24px 16px" className="overflowY">
                        <Box height="100%" display="flex" flexDirection="column" gap="12px">
                            {
                                pModal.agreeinfo?.map((v,k) => (
                                    <Box key={k} display="flex" flexDirection="row" alignItems="center" gap={2}>
                                        <Avatar src={v.profile} alt={v.profile} sx={{width: '32px', height: '32px'}} />
                                        <Box>{v.uname}</Box>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                    <Box minHeight="20px"></Box>
                </Box>
            </Slide>
        </Modal>
    )
}