import { ArrowBack as IArrowBack } from '@mui/icons-material'
import { Box, SwipeableDrawer, MenuItem, Button, Grid } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CstmTextField } from '../../../core/global/forms/CstmTextField';
import moment from 'moment'

export const GraphDrawer = ({ setIsShow, drawer, form, gdHandler, __graphinit }) => {

    const inputHandler = (e) => {
        let g = {...form.data.graph}
        let intval = form.data.graph.interval.value
        const { name, value } = e.target
        if (name === 'sequence' && value < 1) return 
        g[name].value = value
        g[name].stat = false
        g[name].msg = ''
       
        let r = name === 'interval' ? value !== intval ? {...g, date:{value:null, stat:g.date.stat, msg:g.date.msg} } : {...g}  : {...g}
        form.set({...form.data, graph: r})
    }

    const generateHandler = () => {
        setIsShow(true)
        let g = {...form.data.graph}
        let validator = 0
        
        if (form.data.graph.interval.value === '') {
            g.interval.stat = true
            g.interval.msg = 'Interval is required!'
            validator+=1
        } else {
            g.interval.stat = false
            g.interval.msg = ''
        }

        if (form.data.graph.quarter.value === '') {
            g.quarter.stat = true
            g.quarter.msg = 'Quarter is required!'
            validator+=1
        } else {
            g.quarter.stat = false
            g.quarter.msg = ''
        }

        if (form.data.graph.sequence.value === '') {
            g.sequence.stat = true
            g.sequence.msg = 'Sequence is required!'
            validator+=1
        } else {
            g.sequence.stat = false
            g.sequence.msg = ''
        }

        if (form.data.graph.order.value === '') {
            g.order.stat = true
            g.order.msg = 'Order is required!'
            validator+=1
        } else {
            g.order.stat = false
            g.order.msg = ''
        }

        if (form.data.graph.date.value === '' || form.data.graph.date.value === null) {
            g.date.stat = true
            g.date.msg = 'Date is required!'
            validator+=1
        } else {
            g.date.stat = false
            g.date.msg = ''
        }
        
        form.set({...form.data, graph: g})
        if (validator === 0) {
            
            drawer.set({...drawer.data, graph: {...drawer.data.graph, open: false}})
            __graphinit(form.data.graph.interval.value, parseInt(form.data.graph.sequence.value), form.data.graph.order.value, moment(form.data.graph.date.value).format("MM-DD-YYYY"), form.data.graph.quarter.value,)
        }
    }
    
  return (
    <SwipeableDrawer anchor="right" open={drawer.data.graph.open} onClose={()=>gdHandler(false)} onOpen={()=>gdHandler(true)}> 
        <Box width={300} mt={9}>
            <Box px={2.5} display="flex" alignItems="center" >
                <Box onClick={()=>gdHandler(false)} className="c-pointer"><IArrowBack sx={{mr: 1}} /></Box>
            </Box>
            <Box px={2.5} pt={3}>
                <Box fontWeight={500} py={.5}>Interval</Box>
                <CstmTextField select  name="interval" value={form.data.graph.interval.value} variant="outlined" fullWidth error={form.data.graph.interval.stat} helperText={form.data.graph.interval.msg} onChange={(e)=>inputHandler(e)}>
                    <MenuItem value="days">Daily</MenuItem>
                    <MenuItem value="weeks">Weekly</MenuItem>
                    <MenuItem value="months">Monthly</MenuItem>
                    <MenuItem value="quarters">Quarterly</MenuItem>
                    <MenuItem value="years">Yearly</MenuItem>
                </CstmTextField>
                <Box mt={3}>
                    <Box fontWeight={500} pb={.5}>Date from:</Box>
                    {form.data.graph.interval.value !== 'quarters' ? 
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker views={form.data.graph.interval.value==='years'?['year']:form.data.graph.interval.value==='months'?['month', 'year']:['day']} label="" value={form.data.graph.date.value} onChange={(v)=>inputHandler({target: {name: 'date', value: v}})}
                                renderInput={(params) => <CstmTextField {...params} error={form.data.graph.date.stat} helperText={form.data.graph.date.msg} />}
                            />
                        </LocalizationProvider>
                    :
                        <Grid container spacing={1.5} direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={7}>
                                <CstmTextField select name="quarter" value={form.data.graph.quarter.value} variant="outlined" fullWidth error={form.data.graph.quarter.stat} onChange={inputHandler} margin="normal">
                                    <MenuItem value="1st">1st Quarter</MenuItem>
                                    <MenuItem value="2nd">2nd Quarter</MenuItem>
                                    <MenuItem value="3rd">3rd Quarter</MenuItem>
                                    <MenuItem value="4th">4th Quarter</MenuItem>
                                </CstmTextField>
                            </Grid>
                            <Grid item xs={5} mt={1}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker views={['year']} label="" value={form.data.graph.date.value} onChange={(v)=>inputHandler({target: {name: 'date', value: v}})}
                                        renderInput={(params) => <CstmTextField {...params} error={form.data.graph.date.stat}  />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    }
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box fontWeight={500} mb={-1.5} pt={3}>Sequence Order</Box>
                            <CstmTextField select name="order" value={form.data.graph.order.value} variant="outlined" fullWidth error={form.data.graph.order.stat} helperText={form.data.graph.order.msg} onChange={inputHandler} margin="normal">
                                <MenuItem value="asc">4 Succeeding {form.data.graph.interval.value}</MenuItem>
                                <MenuItem value="desc">4 Previous {form.data.graph.interval.value}</MenuItem>
                            </CstmTextField>
                        </Grid>
                    </Grid>
                </Box>
                <Box component={Button} fullWidth variant="contained" size="large" mt={10} borderRadius={3} py={1} onClick={generateHandler}>Generate</Box>
            </Box>
        </Box>
    </SwipeableDrawer>
  )
}