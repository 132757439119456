import { useState } from "react"

export const SpeakOutState = () => {
    const [ctgyData, setCtgyData] = useState([])
    const [ctgyForm, setCtgyForm] = useState([{
        id: '',
        series_num: '',
        category_name: '',
        type: '',
    }])
    const [message, setMessage] = useState('')
    const [successOpen, setSuccessOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)
    const [saveLoader, setSaveLoader] = useState(false)
    const [allSuccess, setAllSuccess] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [infntScroll, setInfntScroll] = useState(true)
    const [lid, setLid] = useState(0)
    const [teamData, setTeamData] = useState([])
    const [teamName, setTeamName] = useState(false)
    const [concernData,  setConcernData] = useState([])
    const [concernView, setConcernView] = useState([])
    const [ctgyTypeList, setCtgyTypeList] = useState([])
    const [teamList, setTeamList] = useState([])
 
    return {
        ctgyData: {data: ctgyData, set: setCtgyData},
        ctgyForm: {data: ctgyForm, set: setCtgyForm},
        message: {data: message, set: setMessage},
        successOpen: {data: successOpen, set: setSuccessOpen},
        errorOpen: {data: errorOpen, set: setErrorOpen},
        saveLoader: {data: saveLoader, set: setSaveLoader},
        allSuccess: {data: allSuccess, set: setAllSuccess},
        isLoading: {data: isLoading, set: setIsLoading},
        infntScroll: {data: infntScroll, set: setInfntScroll},
        lid: {data: lid, set: setLid},
        teamData: {data: teamData, set: setTeamData},
        teamName: {data: teamName, set: setTeamName},
        concernData: {data: concernData, set: setConcernData},
        concernView: {data: concernView, set: setConcernView},
        ctgyTypeList: {data: ctgyTypeList, set: setCtgyTypeList},
        teamList: {data: teamList, set: setTeamList}
    }
}