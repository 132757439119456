import { 
    // lazy, 
    useContext, useEffect, useState } from "react"
import { ToolContext } from "../../core/context/ToolContext";
import { env } from "../../core/Env";
import { rqx } from "../../core/request/API";
import { VM } from "../../core/VM"
import { Index as Dashboard } from './desktop/Index';
import Home from './devices/mobile/Index';
import { Amplify } from 'aws-amplify';
import awsmobile from "../../aws-exports";
Amplify.configure({ ...awsmobile, Analytics: { disabled: true, } });

const view = VM()
// const Home = lazy(() => import(`.${view}/Index`))

export const Index = () => {
    const { tool_state } = useContext(ToolContext)
    const { __SESSION } = tool_state
    const [linkacct, setLinkacct] = useState([])
    const [subscribe, setSubscribe] = useState(true)

    const __init = async() => {
        if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') {
            let res = await rqx.get(`<<global>>/${env()}/account/getlinkaccount`, {aid: __SESSION.data.ainfo.aid, mode: 'linkaccount'})
            if (res.length > 0) {
                setLinkacct(res)
            }
        }
    }

    useEffect(() => {
        if (subscribe) {
            __init()
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        view === '' ? 
            // DESKTOP
            <Dashboard linkacct={linkacct}/>
        :  
            // MOBILE
            <Home linkacct={linkacct}/>
    )
}