import { useEffect, useState } from 'react'
import { Box, Grid, IconButton, Skeleton, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { rqx } from '../../../../../core/request/API';
import { domain, env } from '../../../../../core/Env';
import { ToolFeaturesLoader } from '../../../loader/MidLoader';
import { ArrowRight } from '../../../../../core/global/Icons';
import { motion } from 'framer-motion';
import moment from 'moment';

export const ToolFeatures = ({hs, ts, __SESSION}) => {
    const nav = new useHistory();
    const [loader, setLoader] = useState(true)
    const [lists, setLists] = useState([])
    const [subscribe, setSubscribe] = useState(true);

    // CONTRACTS & REGISTRATION HEADER
    const _header = [ {title: 'Categories', width: '20%'}, {title: 'Total', width: '15%'}, {title: 'Non-Expiring', width: '20%'}, {title: 'Expiring', width: '15%'}, {title: 'Nearly Expired', width: '20%'}, {title: 'Expired!', width: '10%'}, ]
    // CONTRACTS & REGISTRATION DATA
    const regs_cons = [
        [{value: 'Registrations', width: '20%'}, {value: hs.registrations.data.total, width: '15%'}, {value: hs.registrations.data.non_exp, width: '20%'}, {value: hs.registrations.data.expiring, width: '15%'}, {value: hs.registrations.data.nearly, width: '20%'}, {value: hs.registrations.data.expired, width: '10%'}],
        [{value: 'Contracts', width: '20%'}, {value: hs.contracts.data.total, width: '15%'}, {value: hs.contracts.data.non_exp, width: '20%'}, {value: hs.contracts.data.expiring, width: '15%'}, {value: hs.contracts.data.nearly, width: '20%'}, {value: hs.contracts.data.expired, width: '10%'}]
    ]
    
    const __redirect = (e, type, val) => {
        if (type === 'tools') {
            if (val.id === 0) {
                // ts.showMyTool.set(e.currentTarget)
                // ts.isLoaderMT.set(true)
                // ts.showMTDash.set(true)
                nav.push(`/tool-categories/subscribed`);
            }else{
                ts.preload.set({loader:true, logo:'https://pofsis-business.s3.amazonaws.com/biz/1678861961712.png'})
                window.location.href = `${domain('mp').url}/sso/account/auth?ainfo=${encodeURIComponent(JSON.stringify(ts.__SESSION.data.ainfo))}&subcb=${val.id === 1 ?'TNA':'TOC'}&platform=business`
                setTimeout(() => {
                    ts.preload.set({...ts.preload.data, loader:false})
                }, 2000);
            }
        }else{
            return nav.push(`/my-cloud/${val}/list`);
        }
    }

    const __totalSummary = async() => {
        const resTool = await rqx.get(`<<global>>/${env()}/tools/toolcount`, { platform:'business' })
        let toolsAvailable = resTool[0].count - ts.toolOwned.data.length
        let data = [
            { id: 0, title: (__SESSION[0].role_id === 0 ? 'Total Availed Tools' : 'Total Accessed tools'), total:ts.toolOwned.data.length },
            { id: 1, title: 'Total Tools Not Yet Availed', total: toolsAvailable },
            { id: 2, title: 'Total Tools on Cart', total: ts.toolCartCount.data },
        ]
        if (__SESSION[0].role_id !== 0) {
            setLists(
                data.filter(v => { return v.title !== 'Total Tools on Cart' && v.title !== 'Total Tools Not Yet Availed' })
            )
        }else{
            setLists(data)
        }
    }

    const __init = async () => {
        if (hs.dateToday.data !== undefined) {
            let start_date = new Date(hs.dateToday.data);
            let end_date = new Date(hs.dateToday.data);
            start_date.setDate(start_date.getDate() + 1);
            end_date.setDate(end_date.getDate() + 7);
            
            let con = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/dashboard/contracts`, { ref:'reports', company_id: __SESSION[0].company_id, start: moment(start_date).format('YYYY-MM-DD'), end: moment(end_date).format('YYYY-MM-DD'), today: hs.dateToday.data });
            hs.contracts.set((prev) => ({...prev, nearly: con.nearly, expired: con.expired, expiring: con.expiring, total: con.expiring}));
            
            let reg = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/dashboard/registration`, {ref: 'reports', company_id: __SESSION[0].company_id, start: moment(start_date).format('YYYY-MM-DD'), end: moment(end_date).format('YYYY-MM-DD'), today: hs.dateToday.data });
            hs.registrations.set((prev) => ({...prev, nearly: reg.nearly, expired: reg.expired, expiring: reg.expiring, non_exp: reg.nonexpiring, total: reg.expiring + reg.nonexpiring}));
            setLoader(false);
        }
    };

    useEffect(() => {
        if (!ts.globalLoader.data) {
            if (subscribe) {
                __totalSummary();
                __init();
            }
            return () => setSubscribe(false)
        }
        // eslint-disable-next-line
    }, [hs.dateToday.data, ts.globalLoader.data]);

    return (
        loader || ts.globalLoader.data ? 
            <ToolFeaturesLoader __SESSION={__SESSION} />
        :
            <>
                <Grid container >
                    <Grid item sm={__SESSION[0].role_id !== 0 ? 4 : 12} lg={__SESSION[0].role_id !== 0 ? 4 : 12} bgcolor="#FFFFFF" p={2} borderRadius="6px">
                        <Grid container >
                            <Box pb="12px" fontSize={14} fontWeight={600} color="#333333">My Business Tool Summary</Box>
                        </Grid>
                        <Grid container spacing="12px">
                            {lists.map((v, i) => (
                                <Grid item key={i} sm={__SESSION[0].role_id === 0 ? 4 : 12} lg={__SESSION[0].role_id === 0 ? 4 : 12}>
                                    <Box onClick={(e)=>__redirect(e, 'tools', v)} component={motion.div} whileHover={{ backgroundColor:'#D0F9E5'}} className="c-pointer" display="flex" flexDirection="column" justifyContent="space-between" bgcolor="#F0F6F3" p={2} height="100%" borderRadius="6px"> 
                                        <Box fontSize={14}>{ v.title }</Box>
                                        <Box display='flex' justifyContent="space-between" alignItems='center' mt="20px">
                                            {lists.length === 0 ? 
                                                <Skeleton variant="text" height={50} width={50} sx={{borderRadius: '6px'}} />
                                            :
                                                <Box fontSize="32px" ><b>{v.total}</b></Box>
                                            }
                                            <IconButton>
                                                <ArrowRight />
                                            </IconButton>
                                        </Box>    
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                {__SESSION[0].role_id !== parseInt(process.env.REACT_APP_UAI_REGULAR) &&
                    <Box bgcolor="#FFFFFF" mt={4} borderRadius="6px" p="16px">
                        <Box px="12px" pb="12px" fontSize={14} fontWeight={600} color="#333333">Cloud Report</Box>
                        <Box display="flex" px="12px">
                            {_header.map((v, k) => (
                                <Typography key={k} width={v.width} textAlign={k === 0 ? 'left' : 'center'} fontSize={12} fontWeight={600} color={k === 5 && '#F84949'} noWrap>{v.title}</Typography>
                            ))}
                        </Box>
                        <Box onClick={(e)=>__redirect(e, 'docs', 'registrations')} component={motion.div} whileHover={{ backgroundColor:'#F0F6F3'}} className="c-pointer" display="flex" alignItems="center" p="16px 12px" border="1px solid #ECECEC" mt="26px" mb="12px" borderRadius={1} height={42}>
                            {regs_cons[0].map((v, k) => (
                                <Typography key={k} width={v.width} textAlign={k === 0 ? 'left' : 'center'} color={k === 5 && '#F84949'} fontSize={14} noWrap>{v.value}</Typography>
                            ))}
                        </Box>
                        <Box onClick={(e)=>__redirect(e, 'docs', 'contracts')} component={motion.div} whileHover={{ backgroundColor:'#F0F6F3'}} className="c-pointer" display="flex" alignItems="center" p="16px 12px" border="1px solid #ECECEC" borderRadius={1} height={42}>
                            {regs_cons[1].map((v, k) => (
                                <Typography key={k} width={v.width} textAlign={k === 0 ? 'left' : 'center'} color={k === 5 && '#F84949'} fontSize={14} noWrap>{v.value}</Typography>
                            ))}
                        </Box>
                    </Box>
                }
            </>
    )
}