import { Box, Container } from "@mui/material"
import { DIndex } from "./desktop/Index"

export const Speakout = ({match}) => {
    return (
        <Container sx={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column'}}>
            <Box height="100%" width="100%">
                <DIndex match={match.params}/>
            </Box>
        </Container>
    )
}