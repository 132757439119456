import { Box, MenuItem, Modal, Slide, Typography } from "@mui/material";
import { DlIcon, InfoIcon, ShareIcon, StarIcon, TrashIcon, UpdateIcon } from "../../../../../../core/global/Icons";
import { env } from "../../../../../../core/Env";
import { rqx } from "../../../../../../core/request/API";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { Download } from "../../../../../../core/transmit/S3";

export const ThreeDots = ({regActive, show, __SESSION, regList, successOpen, message, modal, actType, showDl, openShare, shareType}) => {
    const nav = useHistory()
    const dfltbtnList = [
        {name: 'View Details', icon: <InfoIcon />, type: 'view'},
        {name: 'Share Access', icon: <ShareIcon fill="#737373" />, type: 'share'},
        {name: show.data.data.user_favorite_status === 2 ? 'Remove to Favorites' : 'Add to Favorites', icon: <StarIcon fill={show.data.data.user_favorite_status === 2 ? "#107038" : "none"} stroke={show.data.data.user_favorite_status === 2 ? "#107038" : "#737373"} />, type: 'addfav'},
        {name: 'Update', icon: <UpdateIcon />, type: 'update'},
        // {name: 'Download as...', icon: <DlIcon />, type: 'dl'},
        {name: `Download ${(show.data.data.file_type.split('/')[0] === 'image') ? 'as...' : ''}`, icon: <DlIcon />, type: 'dl'},
        {name: 'Remove', icon: <TrashIcon fill="#737373" />, type: 'remove'},
    ]

    const [btnList, setBtnList] = useState([])

    const handlerCloseMenu = () => {
        regActive.set({...regActive.data, openMenu: false})
    }
    
    const handlerMenuBtn = async(e, type) => {
        if (type === 'view') {
            show.set({...show.data, data : show.data.data, view : true, fullview : false})
            regActive.set({...regActive.data, val : '',  openMenu : false })
        }else if (type === 'addfav') {
            let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/favorites/create`, {
                registration_id: show.data.data.feature_type === 1 ? show.data.data.id : '',
                contracts_id: show.data.data.feature_type === 2 ? show.data.data.id : '',
                status: show.data.data.user_favorite_status === 2 ? 1 : 2, 
                user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                documents_id:"",
                folder_id: ""
            })
            if (res.message === 'success') {
                regList.set(
                    regList.data.map((d,k) => d.id === show.data.data.id ? {...d, user_favorite_status: show.data.data.user_favorite_status === 2 ? 1 : 2} : {...d} )
                )
                show.set({...show.data, data:{...show.data.data, user_favorite_status: (show.data.data.user_favorite_status === 1 ? 2 : 1) }})
                message.set( show.data.data.user_favorite_status === 2 ? 'Sucessfully removed from favorites' : 'Successfully added to favorites.')
                successOpen.set(true)
            }
        }else if (type === 'update') {
            nav.push(`/my-cloud/registrations/${window.btoa(show.data.data.id)}`)
            modal.set({...modal.data, regForm : {val : true} })
            actType.set('update')
        }else if (type === 'dl') {
            if (show.data.data.file_type.split('/')[0] === 'image') {
                showDl.set(e.currentTarget)
            }else{
                let res = await Download(e, show.data.data.file_link, show.data.data.file_name)
                if (res === 'success') {
                    regActive.set({...regActive.data, openMenu: null})
                    message.set('Successfully download')
                    successOpen.set(true)
                }
            }
        }else if (type === 'remove') {
            show.set({...show.data, remove:{...show.data.remove, val:true}})
        }else if (type === 'share') {
            openShare.set(true)
            shareType.set('details')
        }
        if (type !== 'dl') {
            handlerCloseMenu()
        }
    }
  
    const [subscribe, setSubscribe] = useState(true)
    useEffect(() => {
        if (subscribe) {
            if(show.data.data.access !== null && show.data.data.access !== undefined) {
                let d;
                if (show.data.data.access !== null && (!show.data.data.access[0].update && !show.data.data.access[0].download)) {
                    d = dfltbtnList.filter(v => { return v.type === 'view' || v.type === 'addfav' || v.type === 'share' }  )
                }else if(show.data.data.access !== null && (show.data.data.access[0].update && !show.data.data.access[0].download)){
                    d = dfltbtnList.filter(v => { return v.type === 'view' || v.type === 'addfav' || v.type === 'update' || v.type === 'share' }  )
                }else if(show.data.data.access !== null && (!show.data.data.access[0].update && show.data.data.access[0].download)){
                    d = dfltbtnList.filter(v => { return v.type === 'view' || v.type === 'addfav' || v.type === 'dl' }  )
                }else{
                    d = dfltbtnList.filter(v => { return v.type !== 'remove' }  )
                }
                setBtnList(d)
            }else{
                setBtnList(dfltbtnList)
            }
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [])

    return (
        <Modal open={regActive.data.openMenu} onClose={() => handlerCloseMenu()} BackdropProps={{ sx: {bgcolor: '#0000001F'} }} sx={{zIndex: 1303}} className="noutlined">
            <Slide in={regActive.data.openMenu} direction="up" className="noutlined">
                <Box height="100%" width="100%" display="flex" justifyContent="flex-end" flexDirection="column">
                    <Box height="100%" onClick={() => handlerCloseMenu()} />
                    <Box bgcolor="#FFFFFF" p={2} borderRadius="16px 16px 0px 0px" boxShadow="0px 0px 24px rgba(0, 0, 0, 0.08)">
                        <Box display="flex" justifyContent="center" mb={1}>
                            <Box height="2px" width="37px" bgcolor="#333333" />
                        </Box>
                        {
                            !show.data.view &&
                                <Typography fontSize={14} fontWeight={600} lineHeight="25px" noWrap>
                                    {`${show.data.data.registration_name} (${show.data.data.registered_name})`}
                                </Typography>
                        }
                        {
                            (show.data.view ? btnList.filter(v => { return v.type !== 'view'}) : btnList).map((v,k) => (  
                                <MenuItem sx={{px: '0px', py: '8px', minHeight: '36px'}} key={k} onClick={(e) => handlerMenuBtn(e, v.type)}>
                                {v.icon}
                                    <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px">{v.name}</Box>
                                </MenuItem>
                            ))
                        }
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}
