import { Box, IconButton, Modal, Slide } from "@mui/material"
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom"
import { ToolContext } from "../../../core/context/ToolContext"
import { env } from "../../../core/Env"
import { ArrowLeft } from "../../../core/global/Icons"
import { rqx } from "../../../core/request/API"
import { List } from "./List"

export const MblNotif = () => {
    const { notif_state, tool_state, install_state } = useContext(ToolContext)
    const { tab, notifData, loader, count } = notif_state
    const { __SESSION } = tool_state 
    const nav = useHistory()

    const _clickTab = (type) => {
        tab.set(type)
    }

    const _readNotif = async() => {
        loader.set(false)
        let d = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/notification/read`, {
            user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1], 
            lastId:0, 
            limit:10,
            ref:tab.data
        })
        let c = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/notification/read`, {
            user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1], 
            ref: 'count'
        })
        notifData.set(d);
        count.set(c[0].count)
        loader.set(true)
    }
    
    const _clickTopBtn = async(type) => {
        if (type === 'mark all') {
            await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/notification/update`, {
                user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1], 
                ref:'ma' 
            })
            await _readNotif()
        }else{
            tab.set('')
            setTimeout(() => {
                nav.push('/')
            }, 100);
        }
    }

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            _readNotif()
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [tab.data])

    return (
        <Modal open={tab.data !== '' ? true : false}
            sx={{borderColor:'none !important', zIndex:'1100 !important'}}
            BackdropProps={{style: { backgroundColor: 'transparent', borderColor:'none !important' } }}
        >
            <Slide in={tab.data !== '' ? true : false} direction="left" >
                <Box height="100%" width="100%" display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" minHeight={56} p={2} bgcolor="#11783C" color="#FFFFFF">
                        <Box display="flex" alignItems="center">
                            <IconButton onClick={() => _clickTopBtn('back')}>
                                <ArrowLeft stroke="#FFFFFF" />
                            </IconButton>
                            <Box ml={1} fontSize={18} fontWeight={600} >Notification</Box>
                        </Box>    
                        {(("Notification" in window) && Notification.permission === 'granted') && <Box fontSize={12} sx={{textDecoration: 'underline'}} onClick={() => _clickTopBtn('mark all')}>Mark all as read</Box>}
                    </Box>
                    {
                        (("Notification" in window) && Notification.permission === 'granted') ?
                            <>
                                <Box >
                                    <Box display="flex" alignItems="center" px={2} pt="12px">
                                        <Box borderBottom={tab.data === 'all' ? "3px solid black" : "0px solid #525B6D"} textAlign="center" minWidth="50px" color={tab.data === 'all' ? "#1B1B1B" : "#989A9A"} className="c-pointer" onClick={() => _clickTab('all')}>All</Box>
                                        <Box borderBottom={tab.data === 'unread' ? "3px solid black" : "1px solid #525B6D"} minWidth='90px' display="flex" alignItems="center" justifyContent="center" className="c-pointer" onClick={() => _clickTab('unread')}>
                                            <Box textAlign="center" width="59px" color={tab.data === 'unread' ? "#1B1B1B" : "#989A9A"}>Unread</Box> 
                                            {count.data !== 0 && 
                                                <Box ml="4px" textAlign="center" height="17px" px="6px" bgcolor={tab.data === 'unread' ? "#1B1B1B" : "#989A9A"} color="#FFFFFF" fontSize="12px" borderRadius="5px">{count.data}</Box>
                                            }
                                        </Box>
                                    </Box>
                                    <Box width="100%" height="1px" mt={-.2} bgcolor="#525B6D" />
                                </Box>
                                <List notifData={notifData} loader={loader} __SESSION={__SESSION}/>
                            </>
                        :
                            <Box height="100%" width="100%" py={2} bgcolor="#fff" display="flex" flexDirection="column" alignItems="center" justifyContent="center" className='shadow-sm'>
                                <Box minHeight="20px" display='flex' justifyContent='space-between' alignItems='center' px={3}>
                                    <Box fontSize='14px' fontWeight={600}>Please Allow first your notification or reset permission in your browser.</Box>
                                </Box>
                            </Box>
                    }
                    <Box minHeight={install_state.prompt.data !== null ? "118px" : "54px"} />
                </Box>
            </Slide>
        </Modal>
    )
}