import { Box, Skeleton } from "@mui/material"

export const Loader = () => {

    return (
        <Box height="100%" width="100%">
            <Box height={100} display="flex" justifyContent="center" alignItems="center">
                <Skeleton variant="rectangular" height={120} width="100%" sx={{mt:'50px', borderRadius:'6px'}}/>
            </Box>
            <Box mt={6} display="flex" flexDirection="column" gap="16px">
                {['',''].map((v,k) => (
                    <Box key={k} display="flex" justifyContent="space-between" alignItems="center">
                        <Skeleton variant="rectangular" width='100px' height="16px" sx={{borderRadius:'6px'}}/>
                        <Skeleton variant="rectangular" width='50px' height="16px" sx={{borderRadius:'6px'}}/>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}