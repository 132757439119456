import { Box, Modal, Slide, Button, useMediaQuery } from '@mui/material';
import { PlcyIcons } from '../../core/global/Icons';
import { useHistory } from 'react-router-dom';
import { ToolContext } from '../../core/context/ToolContext';
import { useContext, useEffect, useState } from 'react';
import { rqx } from '../../core/request/API';
import { env } from '../../core/Env';

let uai_user = process.env.REACT_APP_UAI_REGULAR;
export const PlcyPermission = () => {
    const history = useHistory() 
    const sm = useMediaQuery('(max-width:500px)')

    const { tool_state } = useContext(ToolContext)
    const { __SESSION } = tool_state

    const [open, setOpen] = useState(false)
    
    const handleClose = async() => {
        history.push('/policies/list')
    };

    useEffect(() => {
        const __init = async() => {
            if (!open && __SESSION.data.uaccess[0].role_id === parseInt(uai_user)) {
                let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/policy/polvalidate`, {
                    company_id: __SESSION.data.uaccess[0].company_id, 
                    dept_id:  __SESSION.data.uaccess[0].company_department_id, 
                    user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1]
                })
                if (res === 'required') {
                    setOpen(true)
                }
            }
        }
        __init()
        // eslint-disable-next-line 
    }, [open])
    
    return (  
        <Modal open={open} className="noutlined">
            <Slide in={open} direction='up' className="noutlined">
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" px={sm && 2}>
                    <Box width={sm ? '100%' : '456px'} bgcolor="#FFFFFF" px="24px" pb="24px" borderRadius="6px">
                        <Box minHeight="52px"></Box>
                        <Box display="flex" flexDirection="column" gap="20px">
                            <PlcyIcons/>
                            <Box color="#292C2F" fontSize={16} fontWeight={600} lineHeight="20px" >You need to read and agree with the policies of your company.</Box>
                            <Box color="#5B6167" fontSize={14} fontWeight={400} lineHeight="21px" >
                                Read and Agree to the company policies to ensure smooth operations and maintain standards.
                            </Box>
                            <Box width="flex" textAlign="right">
                                <Button variant="contained" disableElevation onClick={handleClose}
                                        sx={{ width:'171px', minHeight:'48px', borderRadius:'6px', bgcolor: '#003022', color: '#FFFFFF', '&:hover': { bgcolor: '#292C2F' } }} 
                                    >
                                    Go to Policies 
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    );
}