import { useState } from "react"
import { Box, Menu, MenuItem } from "@mui/material"
import { DlIcon, GreaterThan, InfoIcon, InfoIconActive, ShareIcon, TrashIcon, UpdateIcon } from "../../../../../core/global/Icons"
import { Download, DownloadZip } from "../../../../../core/transmit/S3"
import { rqx } from "../../../../../core/request/API";
import { env } from "../../../../../core/Env";

export const ThreeDots = ({active, showDl, show, openShare, shareType, rename, message, successOpen}) => {
    const [iconActive, setIconActive] = useState('')
    const handlerCloseMenu = () => {
        active.set({...active.data, val : '', openMenu : null, type: ''})
    }

    const handlerView = (val) => {
        show.set({...show.data, data : val, view : true, fullview : false})
        active.set({...active.data, val : '', openMenu : null, type: ''})
    }

    const handlerDl = async(e) => {
        if (active.data.type === 'file') {
            if (show.data.data.file_type === 'application/pdf') {
                let res = await Download(e, show.data.data.file_link, show.data.data.file_name)
                if (res === 'success') {
                    showDl.set(null)
                    active.set({...active.data, val : '', openMenu : null, type: ''})
                    message.set('Successfully download')
                    successOpen.set(true)
                }
            }else{
                showDl.set(e.currentTarget)
            }
        }else{
            
            let data = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/documents/downloadFolder`, {cf_id:show.data.data.id})
            // let res = await Download(e, show.data.data.file_link, show.data.data.folder_name)
            let res = await DownloadZip(data)
            if (res === 'success') {
                showDl.set(null)
                active.set({...active.data, val : '', openMenu : null, type: ''})
                message.set('Successfully download')
                successOpen.set(true)
            }
        }
    }
    
    const handlerShareAccess = (val) => {
        openShare.set(true)
        active.set({...active.data, val : '', openMenu :  null})
        show.set({...show.data, data : val, view : false, fullview : false})
        shareType.set('details')
    }

    const handlerRename = (id, type) => {
        rename.set({...rename.data, id: id, val : true, type: type})
        active.set({...active.data, val : '', openMenu :  null})
    }

    const handlerDelete = () => {
        show.set({...show.data, remove:{...show.data.remove, val:true}})
    }

    let accessUpdate;
    let accessDownload;
    if (show.data.data.access) {
        if (typeof show.data.data.access === 'string') {
            accessUpdate = JSON.parse(show.data.data.access)[0].update
            accessDownload = JSON.parse(show.data.data.access)[0].download
        }else{
            accessUpdate = show.data.data.access[0].update
            accessDownload = show.data.data.access[0].download
        }
    }
    
    let dwnldValidAccess = false
    let updtValidAccess = false
    // let removeValidAccess = show.data.data.access !== null && show.data.data.access !== undefined && show.data.data.access[0].remove !== undefined && show.data.data.access[0].remove
    if (show.data.data.access === null || show.data.data.access === undefined || (show.data.data.access !== null && accessUpdate )) {
        updtValidAccess = true
    }
    if (show.data.data.access === null || show.data.data.access === undefined || (show.data.data.access !== null && accessDownload )) {
        dwnldValidAccess = true
    }

    return (
        <>
            <Menu open={Boolean(active.data.openMenu)} anchorEl={active.data.openMenu} elevation={1} onClose={() => handlerCloseMenu()}>
                <Box px={1}>
                    {!show.data.view && active.data.type === 'file' && (
                        <MenuItem sx={{minHeight: '44px !important', py: 0}}
                            onMouseEnter={() => setIconActive('view')} 
                            onMouseLeave={() => setIconActive('')}
                            onClick={() => handlerView(show.data.data)}
                        >
                            {iconActive === 'view' ? <InfoIconActive /> : <InfoIcon />}
                            <Box ml="12px" fontSize={14} fontWeight={400}> View Info</Box>
                        </MenuItem>
                    )}
                    <MenuItem sx={{minHeight: '44px !important', py: 0}} 
                        onClick={() => handlerShareAccess(show.data.data)}
                        onMouseEnter={() => setIconActive('share')} 
                        onMouseLeave={() => setIconActive('')}
                    >
                        <ShareIcon fill={iconActive === 'share' ? "#107038" : "#A2A3A9"} />
                        <Box ml="12px" fontSize={14} fontWeight={400}> Share Access</Box>
                    </MenuItem>
                    {!show.data.view && (
                        <MenuItem sx={{minHeight: '44px !important', py: 0, opacity:updtValidAccess ? 1 :0.3 }} 
                            onClick={ updtValidAccess ? () => handlerRename(show.data.data.id, active.data.type) : null}
                            onMouseEnter={() => setIconActive('update')} 
                            onMouseLeave={() => setIconActive('')}
                        >
                            <UpdateIcon fill={iconActive === 'update' ? "#107038" : "#A2A3A9"} />
                            <Box ml="12px" fontSize={14} fontWeight={400}> Rename</Box>
                        </MenuItem>
                    )}
                    <MenuItem sx={{minHeight: '44px !important', py: 0, bgcolor: showDl.data !== null ? '#F2F2F2' : '#ffffff', opacity:dwnldValidAccess ? 1 :0.3,}}
                        onClick={ dwnldValidAccess ? (e) => handlerDl(e) : null}
                        onMouseEnter={() => setIconActive('dl')} 
                        onMouseLeave={() => setIconActive('')}
                    >
                        <DlIcon fill={iconActive === 'dl' || showDl.data !== null ? "#107038" : "#A2A3A9"} />
                        <Box ml="12px" mr="40px" fontSize={14} fontWeight={400}>
                            Download 
                            {active.data.type === 'file' && show.data.data.file_type !== 'application/pdf' ? ' as....' : ''}
                        </Box>
                        {
                            (active.data.type === 'file' && show.data.data.file_type !== 'application/pdf') &&
                                <GreaterThan stroke={iconActive === 'dl' || showDl.data !== null ? "#107038" : "#737373"} />
                        }
                    </MenuItem>
                    <MenuItem sx={{minHeight: '44px !important', py: 0, opacity:(show.data.data.access === null || show.data.data.access === undefined) ? 1 : 0.3}} 
                        onClick={ (show.data.data.access === null || show.data.data.access === undefined) ? () => handlerDelete() : null}
                        onMouseEnter={() => setIconActive('delete')} 
                        onMouseLeave={() => setIconActive('')}
                    >
                        <TrashIcon fill={iconActive === 'delete' ? "#107038" : "#A2A3A9"} />
                        <Box ml="12px" fontSize={14} fontWeight={400}> Delete</Box>
                    </MenuItem>
                </Box>
            </Menu>
        </>
    )
}