import { useState } from "react"

export const SwitchingState = () => {
    const [switchForm, setSwitchForm] = useState({stat: false, platform: 'psl', links: null});
    const [initial, setInitial] = useState(true) 
    
    return {
        switchForm: {data: switchForm, set: setSwitchForm},
        initial: {data: initial, set: setInitial},
    }
}