import { Suspense, useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Box } from '@mui/material/';
import { ToolContext } from './core/context/ToolContext';
import { WebRoute } from "./core/webrouter/Route";
import { SnakeLoader } from './core/loader/SnakeLoader'
import { Logo } from './core/global/Icons';
import { InstallPromotion } from './components/layouts/installation/InstallPromotion';
import { rqx } from './core/request/API';
import { env } from './core/Env';
import { SwitchingLoader } from './core/loader/SwitchingLoader';
import { 
	// NotifCreate, 
	NotifUpdate 
} from './GqRealTime';
import { BrandLog } from './BrandLog';

const __SESSION = JSON.parse(localStorage.getItem('biz_session'))
const tool_id =  process.env.REACT_APP_TID;
// const global_id = process.env.REACT_APP_GLOBAL_ID;

export const Core = () => {
	const { tool_state,install_state, notif_state
		// , home_state, subdivision_state, personalization_state, gen_main_state 
	} = useContext(ToolContext)
	const { preload, validateAccount } = tool_state

	const __installer = () => {
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault();
			install_state.prompt.set(e)
		});
	}

	const installPromptHandler = () => {
		setTimeout(() => {
			install_state.ip.set({stat: true, prompt_type: 'first_prompt'})
		}, 10000);
	}

	const __initRT = async() => {
		await NotifUpdate(notif_state, validateAccount)
		// await NotifUpdate(true, notif_state)
		// await NotifCreate(true, notif_state)
	}
	
	useEffect(() => {
		__initRT()
		BrandLog()
		const __init = async () => {
			// RUN AN API REQUEST HERE ( SAMPLE REQUESTS BELOW: )
			let ainfo;
			let pinfo;
			if (atob(__SESSION.ainfo.aid).split('_', 2)[1] !== '0') {
				ainfo = await rqx.get(`<<global>>/${env()}/account/read`, {aid: __SESSION.ainfo.aid})
				pinfo = await rqx.get(`<<global>>/${env()}/primeinfo/read`, {aid: __SESSION.ainfo.aid})
			}else{
				ainfo = {account_type:2, aid:'YWNjb3VudF8w', email:'unknown@com', user_type:1}
				pinfo = [
					{
						aid:'YWNjb3VudF8w',
						first_name:'Unknown',
						last_name:'Unknown',
						middle_name:"",
						suffix:"",
						contact_info:"{}",
						other_info:"{\"gender\": null, \"birthdate\": null, \"civil_status\": null}",
						profile:"{\"cover\": \"https://pofsis-business.s3.amazonaws.com/biz-image/DefaultBG.png\", \"profile\": \"https://pofsis-business.s3.amazonaws.com/biz/dev/1711527623238.jpg\"}",
						role:0
					}
				]
			}
			let uaccess = await rqx.get(`<<global>>/${env()}/roleaccess/uap`, {aid: __SESSION.ainfo.aid, tid:tool_id})
			let uaddress = await rqx.get(`<<global>>/${env()}/location/assigned`, {aid:__SESSION.ainfo.aid})
			await tool_state.__SESSION.set({ainfo, pinfo, uaccess, uaddress})
			await tool_state.prefetch.set(true)
		}
		
		if (__SESSION !== null) {
			__installer()
			__init()
		} else {
			setTimeout(() => {
				// SET STATES HERE ( SAMPLE STORING BELOW: )
				tool_state.prefetch.set(true)

				// RUN PWA INSTALLATION PROMPT HANDLER
				installPromptHandler()
			}, 1000);
		}
		
		console.log(`POFSIS Business V3.1.61 is running`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Box width="100%" height="100%">
			{
				tool_state.prefetch.data ? (
					<Router>
						<Suspense fallback={
							<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
								<Logo size={60}/>
								<Box pt={4}>
									<SnakeLoader size="1rem" bg="#11783C" distance="0.3rem" />
								</Box>
							</Box>
						}>
							<WebRoute />
						</Suspense>
					</Router>
				) : (
					<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
						<Logo size={60}/>
						<Box mt={4}>
							<SnakeLoader size="1rem" bg="#11783C" distance="0.3rem" />
						</Box>
					</Box>
				)
			}
			<InstallPromotion install_state={install_state} />
			<SwitchingLoader load={preload.data.loader} logo={preload.data.logo}/>
		</Box>
	)
}