import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { Box, Container, Modal, useMediaQuery, } from '@mui/material'
import { NoAccessModal, UseStyles } from '../../../global/DesktopDsg'
import { SelectReg } from './SelectReg'
import { Left } from './Left'
import { Right } from './Right'
import { ErrorAlert, FailedOcrAlrt } from '../../../../../core/global/forms/CstmModal'
import { ToolContext } from '../../../../../core/context/ToolContext';
import { __ListRegName } from '../../../global/Function';
import { rqx } from '../../../../../core/request/API';
import { env } from '../../../../../core/Env';
import { SnakeLoader } from '../../../../../core/loader/SnakeLoader';
import { ValidateBtnNxtReg } from '../../../global/Function'
import Tesseract from 'tesseract.js';

export const FormReg = ({match}) => {
    const [mLoader, setMLoader] = useState(false)
    const xl = useMediaQuery('(min-width:1921px)');
    const { cloud_state, tool_state, notif_state, navigation_state } = useContext(ToolContext)
    const { listRegName, form, modal, regsn, ocrLoader, actType, saveLoader, sufficientStorage, insuffShow, storage, errOpen, message} = cloud_state
    const { __SESSION } = tool_state 
    const { prevURL } = navigation_state
    
    const dsg = UseStyles()
    const nav = useHistory()
    const validateBase64String = () =>{
        try {
            window.atob(subparams);
            return true;
        } catch (e) {
            return false;
        }
    }
    
    const subparams = match
    const isValid = validateBase64String(subparams);

    const onDrop = async(files) => {
        if (files.length === 0) {
            message.set('Uploading is restricted to image and PDF file formats exclusively.')
            errOpen.set(true)
            return
        }
        
        let sizelmt = 4194304
        // let sizelmt = 3145728 
        if (files[0].size > sizelmt) {
            message.set('File uploads must not exceed 3MB.')
            errOpen.set(true)
            return
        }

        let _file = files[0];
        let crntsize = subparams === 'create' ? 0 : parseInt(form.data.regFile.current_size)
        let totalsize = _file.size - crntsize
        // let vldtStrg = (storage.data.used + totalsize) > storage.data.used
        let vldtStrg = (storage.data.used + totalsize) > storage.data.total
        if (vldtStrg && atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') {
            sufficientStorage.set(vldtStrg)
            insuffShow.set(true)
            return
        }else{
            sufficientStorage.set(vldtStrg)
            insuffShow.set(false)
        }

        form.set({...form.data, 
            regFile:{...form.data.regFile,
                storage:{type:_file.size > crntsize ? 'add' : 'sub', val:totalsize},
                photo:{...form.data.regFile.photo, val:URL.createObjectURL(_file), stat:false, msg:''}, 
                file:{...form.data.regFile.file, val:files, stat:false, msg:''}, 
            }
        })
        if (_file.type.includes('image') && form.data.regName.val === 5) {
            ocrLoader.set(true)
            Tesseract.recognize(
                _file,
                'eng',
                ).then(({ data: { text } }) => {
                    // FOR SSS REGISTRATION 
                    if (text.includes('that') && text.includes('Employer Number')) {
                        let searchText = "that";
                        let indexrname = text.indexOf(searchText);
                        let regname = text.slice(indexrname+5).split('\n')[0]
                        let searchTextRegNum = "Employer Number";
                        let indexrum = text.indexOf(searchTextRegNum);
                        let regnum = text.slice(indexrum+16).split('.')[0]
                        form.set({...form.data, 
                            name:{val:regname, stat:false, msg:''},
                            number:{val:regnum, stat:false, msg:''},
                            regFile:{...form.data.regFile,
                                storage:{type:_file.size > crntsize ? 'add' : 'sub', val:totalsize},
                                photo:{...form.data.regFile.photo, val:URL.createObjectURL(_file)}, 
                                file:{...form.data.regFile.file, val:files}, 
                            }
                        })
                        ocrLoader.set(false)
                    }else{
                        ocrLoader.set(false)
                        form.set({...form.data, 
                            regFile:{...form.data.regFile,
                                storage:{type:_file.size > crntsize ? 'add' : 'sub', val:totalsize},
                                photo:{val:URL.createObjectURL(_file), stat:true, msg:''}, 
                                file:{val:files, stat:true, msg:'Image scan failed: We are unable to read the photo you have selected. Please upload a clearer image or verify that it is the intended registration document.'}
                            }
                        })
                    }
            })
        }else if (form.data.regName.val === 5) {
            ocrLoader.set(false)
            form.set({...form.data, 
                regFile:{...form.data.regFile,
                    storage:{type:_file.size > crntsize ? 'add' : 'sub', val:totalsize},
                    photo:{val:'', stat:true, msg:''}, 
                    file:{val:'', stat:true, msg:'Optical Character Recognition (ORC) aceept image file only.'}
                }
            })
        }
        modal.set({...modal.data, sampleImg : { val : false } })
    }
    
    const {getRootProps, getInputProps} =  useDropzone({onDrop, accept: { 'image/png': ['.png'], 'image/jpeg' : ['.jpeg'], 'application/pdf' : ['.pdf'] } })
    
    const GotIt = () => {
        form.set({...form.data, 
            regFile:{...form.data.regFile,
                photo:{...form.data.regFile.photo, stat:false, msg:''}, 
                file:{...form.data.regFile.file, stat : false}
            }
        })
    }

    const __initGETIDS = async() => {
        if (isNaN(parseInt(window.atob(match)))) {
            await nav.push('/my-cloud/registrations/list')
            return
        }
        let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/registration/view`, {
            id:parseInt(window.atob(match)), 
        })
        
        form.set({...form.data, 
            id: parseInt(res[0].id),
            series_num:res[0].series_num,
            date_from:{...form.data.date_from, val:res[0].start_date},
            date_to:{...form.data.date_to, val:res[0].end_date},
            regName:{...form.data.date_to, val:res[0].registration_details_id},
            name:{...form.data.name, val:res[0].registered_name},
            number:{...form.data.number, val:res[0].reference_num},
            regType:{...form.data.regType, val:res[0].type},
            regFile:{
                storage:{type:'add', val:0},
                current_size:res[0].file_size,
                photo:{val:res[0].file_link, stat:false, msg:''}, 
                file:{
                    val:[{
                        name:res[0].file_name,
                        type:res[0].file_type,
                        size:res[0].file_size
                    }], 
                    stat:false, 
                    msg:''
                }
            },
            regOther:{...form.data.regOther, val:res[0].registration_name},
        })
    }

    const handleCloseAccess = () => {
        notif_state.rtAccess.set(false)
        notif_state.notifPerUser.set(0)
        nav.push('/my-cloud/registrations/list')
    }

    const __init = async() =>{
        setMLoader(true)
        await  __ListRegName(__SESSION, listRegName)
        if (isValid && subparams !== 'create' && subparams !== 'review') {
            actType.set('update')
            await __initGETIDS()
        }
        setMLoader(false)
    }

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (subparams === 'create' || subparams === 'review') {
                modal.set({...modal.data, openRegList : { val : true } })
                form.set({...form.data, 
                    date_from:{val:'', stat:false, msg:''},
                    date_to:{val:'', stat:false, msg:''},
                    regName:{val:'', stat:false, msg:''},
                    regType:{val:'', stat:false, msg:''},
                    regFile:{
                        photo:{val:'', stat:false, msg:''}, 
                        file:{val:'', stat:false, msg:''}
                    },
                    name:{val:'', stat:false, msg:''},
                    number:{val:'', stat:false, msg:''},
                    regOther:{val:'', stat:false, msg:''},
                })
            }
            if (subparams === 'review') {
                let res = ValidateBtnNxtReg(form)
                if (res.disabled) {
                    nav.push('/my-cloud/registrations/create')
                }
            }
            __init()
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (notif_state.notifPerUser.data !== 0) {
            if (JSON.parse(notif_state.notifPerUser.data).company_id === __SESSION.data.uaccess[0].company_id) {
                let d = JSON.parse(notif_state.notifPerUser.data).access.filter(v => { return v.user_id === parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) } )
                if (d.length > 0) {
                    if ((!d[0].update || d[0].remove) && __SESSION.data.uaccess[0].role_id !== 0) {
                        if (d[0].file === 'reg') {
                            if (form.data.id === d[0].file_id) {
                                notif_state.rtAccess.set(true)
                            }
                        }
                    }else{
                        notif_state.rtAccess.set(false)
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [notif_state.notifPerUser.data])

    return (
        <>
            <Modal open={true}>
                <Box height="100%" width="100%" bgcolor="#FFF">
                    {
                        mLoader ?
                            <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                                <SnakeLoader size="1rem" bg="#11783C" distance="0.3rem" />
                            </Box>
                        :
                            <Box height="100%" width="100%" display="flex"  component={xl ? Container : ''} position="relative" className="overflowY">
                                <Left form={form} listRegName={listRegName} modal={modal} dsg={dsg} regsn={regsn} getRootProps={getRootProps} getInputProps={getInputProps} nav={nav} ocrLoader={ocrLoader} subparams={subparams} actType={actType} prevURL={prevURL} __SESSION={__SESSION}/>
                                <Right dsg={dsg} nav={nav} listRegName={listRegName} __SESSION={__SESSION} subparams={subparams} />
                                {saveLoader.data && (
                                    <Box height="100%" width="100%" position="absolute" bgcolor="rgba(0, 0, 0, 0.12)" zIndex={2000}/>
                                )}
                            </Box>
                    }
                </Box>
            </Modal>
            {subparams === 'create' && (
                <SelectReg modal={modal} form={form} listRegName={listRegName} getRootProps={getRootProps} ocrLoader={ocrLoader} sufficientStorage={sufficientStorage} insuffShow={insuffShow} __SESSION={__SESSION}/>
            )}
            <FailedOcrAlrt open={form.data.regFile.file.stat} message={form.data.regFile.file.msg} close={() => GotIt()} />
            {notif_state.rtAccess.data && (
                <NoAccessModal open={notif_state.rtAccess.data} handleCloseAccess={handleCloseAccess}/>
            )}
            {errOpen.data && (
                <ErrorAlert errOpen={errOpen.data} setErrOpen={errOpen.set} text={message.data} duration={3000}/>
            )}
        </>
    )
}