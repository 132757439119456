import { useEffect, useContext, useState } from 'react';
import { Box, SwipeableDrawer, } from '@mui/material';
import { Details } from './details/Index';
import { ToolContext } from '../../../../core/context/ToolContext';
import { ShareConfirmation } from '../../global/DesktopDsg';
import { RemoveAccess } from './removeAccess/Index';
import { CreateAccess } from './create/Index';
import { rqx } from '../../../../core/request/API';
import { env } from '../../../../core/Env';
import { NotifCount } from '../../../../core/request/Notif';

export const ShareAccess = ({match}) => {
    const { cloud_state, tool_state } = useContext(ToolContext)
    const { menuDept, openShare, show, shareType, selectedDept, confirmShare, allUsrList, userList, searchInput, saveLoader, successOpen, message, withAccess, ulistAccess, filterActive } = cloud_state
    const { __SESSION } = tool_state
    let company_id = __SESSION.data.uaccess[0].company_id;
    const [usrData, setUsrData] = useState(allUsrList.data)

    const handlerCloseConfirmation = () => {
        confirmShare.set({...confirmShare.data, val : false, type: ''})
    }

    const handleClose = () => {
        userList.set([])
        searchInput.set('')
        openShare.set(false)
        withAccess.set({val: [], loader: false})
        ulistAccess.set([])
        menuDept.set(null)
        filterActive.set({id: 0, name: 'All'})
    }
    
    const handlerShareSubmit = async() => {
        if (!saveLoader.data) {
            saveLoader.set(true)
            let mergeArr;
            let data = userList.data.filter(v => { return v.stat.view } )
            if (ulistAccess.data.length > 0) {
                mergeArr = [...ulistAccess.data, ...data]
            }
            let body = {
                company_id: company_id,
                access: (ulistAccess.data.length > 0 ? mergeArr : data).map(v => { return { user_id:v.id, view:v.stat.view, update:v.stat.update, download:v.stat.download } }),
                user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                contracts_id: show.data.data.feature_type === 2 ? show.data.data.id : '',
                registration_id: show.data.data.feature_type === 1 ?  show.data.data.id : '',
                folder_id: show.data.data.feature_type === 3 ? show.data.data.folder_id : '',
                documents_id: show.data.data.feature_type === 4 ? show.data.data.id : '',
            }
            const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/access/create`, body)
            if (res.message === 'success') {
                const fileType = show.data.data.feature_type === 1 ? 'reg' : show.data.data.feature_type === 2 ? 'con' : show.data.data.feature_type === 3 ? 'folder' : 'file'
                let fltr = userList.data.map(v => { return {...v.stat, remove:false, user_id:v.id, file:fileType, file_id:show.data.data.id } } )
                await NotifCount(fltr, __SESSION, 'crtacc')
                await handleClose()
                confirmShare.set({...confirmShare.data, val : false, type: ''})
                saveLoader.set(false)
                openShare.set(false)    
                successOpen.set(true)
                message.set('Successfully shared')
            }
        }
    }

    const initGetWithAccess = async() => {
        withAccess.set({...withAccess.data, val: [], loader: true})
        let params = {
            registration_id: show.data.data.feature_type === 1 ? show.data.data.id : '',
            contracts_id: show.data.data.feature_type === 2 ? show.data.data.id : '',
            folder_id: show.data.data.feature_type === 3 ? show.data.data.folder_id : '',
            documents_id: show.data.data.feature_type === 4 ? show.data.data.id : '',
        }
        let res = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/access/read`, params )
        let fdata = res.length === 0 ? res : JSON.parse(res[0].access)
        await getUserWithAccess(res, fdata)
    }

    const getUserWithAccess = (val, fdata) => {
        if (val.length > 0) {
            let fltrAccess = allUsrList.data.users.filter(f => JSON.parse(val[0].access).find(x => f.id  === x.user_id))
            fdata = fltrAccess.map((v) => {
                const d = JSON.parse(val[0].access).filter((c) => c.user_id === v.id)[0];
                return {...v, stat: {...v.stat, view:d.view === undefined ? true : d.view, update : d.update, download: d.download, remove:false}}
            })
            fdata.sort((a, b) => a.first_name.localeCompare(b.first_name))
        }
       
        ulistAccess.set(fdata)
        withAccess.set({...withAccess.data, val: [{...val[0], access:fdata}], loader: false})
    }

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (openShare.data) {
                if (show.data.data.access === null || show.data.data.access === undefined) {
                    shareType.set('create_access')
                }
                selectedDept.set([])
            }
            initGetWithAccess()
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [])
    
    return (
        <SwipeableDrawer anchor='right' open={openShare.data} onOpen={() => openShare.set(true)} onClose={handleClose}
            PaperProps={{ sx: { backgroundColor: "#3333331F ", boxShadow:"none", height: '100%', width: '100%', py: 2 } }}
        >
            <Box height="100%" width="100%" display="flex">
                <Box width="100%" onClick={handleClose} />
                <Box height="100%" minWidth="720px" display="flex" flexDirection="column" bgcolor="#ffffff" borderRadius="6px 0px 0px 6px" p={3}>
                    {
                        shareType.data === 'create_access' ?
                           <CreateAccess usrData={usrData} setUsrData={setUsrData} match={match} />
                        :
                            shareType.data === 'details' ?
                                <Details handleClose={handleClose} />
                            :
                                <RemoveAccess/>
                    }
                    <ShareConfirmation 
                        name={
                            show.data.data.feature_type === 1 ? 
                                show.data.data.registration_name 
                            : show.data.data.feature_type === 2 ? 
                                show.data.data.contract_name 
                            : show.data.data.feature_type === 3 ? 
                                show.data.data.folder_name
                            : 
                                show.data.data.file_name
                        } 
                        subname={
                            show.data.data.feature_type === 1 ? 
                                show.data.data.registered_name 
                            : show.data.data.feature_type === 2 ? 
                                show.data.data.client_name
                            : ''
                        }
                        confirmShare={confirmShare}  
                        cancel={() => handlerCloseConfirmation()}
                        submit={() => handlerShareSubmit()}
                        saveLoader={saveLoader.data}
                    />
                </Box>
            </Box>
        </SwipeableDrawer>
    )
}
