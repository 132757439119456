import { Autocomplete, Box, Button, InputAdornment, TextField, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { ArrowDown } from "../../../../../core/global/Icons"
import Pdf  from '../../../../../assets/images/icons/pdf.png'
import { FormatFilesize } from "../../../../mycloud/global/DesktopDsg"
import { motion } from "framer-motion"
import Add from '../../../../../assets/images/icons/add.png'

export const Details = ({ dsg, form, setForm, match, setOpen, __SESSION, plcysn, handlerBtn, pName, allUsers, getRootProps, getInputProps }) => {

    const handleOnClick = () => {
        if (match.params.params === 'create') {
            setOpen(true)
        }   
    }

    const handleOnChange = (e, val, key) => {
        if (key === undefined){
            const {name, value} = e.target
            if (name === 'policyname') {
                pName.set(false)
            }
            setForm({...form, [name]: value})

        } else {
            let name = key;
            let value = (val === null ? '' : (val.id > 0 ? val.id:val));
            setForm({...form, [name]: value, [name === 'preparedby' ? 'preparedname' : 'approvedname']: val !== null ? val.uname : ''})
        }
    }
    return (
        <>
            <Box fontSize="14px" fontWeight={600} lineHeight="16px" >
                <Box mb="8px">Department *</Box>
                <TextField fullWidth className={dsg.txtfld}
                    name="departmentname" disabled={match.params.params === 'review' ? true : false}
                    error={form.departmentname === '' ? true : false}
                    value={form.departmentname.name}
                    onClick={()=>handleOnClick()}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <ArrowDown stroke="#333333" />
                            </InputAdornment>
                        ),
                    }}  
                />
            </Box>
            <Box height="206px" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" border="1px dashed" borderColor={form.file_name === '' ? '#D32F2F' : '#E6E6E6'} borderRadius="6px" p={2} mt={2} fontSize="14px" fontWeight={600} lineHeight="14px">
                <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" mb="12px" >
                {
                    form.file_name === '' ?
                    <Box display="flex" alignItems="center" flexDirection="column" {...getRootProps({className: 'dropzone'})} onClick={() => document.getElementById('file-upload').click()}>
                    <input {...getInputProps()} id="file-upload" style={{ display: 'none' }} />
                        <Box pt="16px"><img src={Add} alt={Add}/></Box>
                        <Box fontSize="14px" fontWeight={400} lineHeight="19px" pt="32px" color="#333333">Click here to upload .pdf file.</Box>
                        <Box fontSize="12px" fontWeight={400} lineHeight="19px" p="12px" color="#A2A3A9">Maximum file size is 3MB.</Box>
                    </Box>
                :
                    <Box display="flex" alignItems="center" width="100%"  p="9px 13px" bgcolor="#FFFFFF" borderRadius="6px" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)"> 
                        <Box minWidth={32}> 
                            <img src={Pdf} alt={Pdf} />
                        </Box>
                        <Box width="85%" ml="13px"> 
                            <Typography color="#333333" fontWeight={600} fontSize={12} lineHeight="16px" noWrap>{form.file_name}</Typography>
                            <Box color="#737373" fontWeight={400} fontSize={10} lineHeight="16px"> {FormatFilesize(form.file_size)} </Box>
                        </Box>
                    </Box>
                }
                </Box>
                {
                    (match.params.params !== 'review') && form.file_name !== '' &&
                    <Box display="flex" justifyContent="center">
                        <Button component={motion.div} sx={{bgcolor: '#E2EDE7', color: '#107038', borderColor: '1px solid #E2EDE7', borderRadius: '6px'}} {...getRootProps({className: 'dropzone'})} onClick={() => document.getElementById('file-upload').click()}>
                                Change
                        </Button>
                        <input {...getInputProps()} id="file-upload" style={{ display: 'none' }} />
                    </Box>
                }
            </Box>
            <Box fontSize="14px" lineHeight="16px" display="flex" flexDirection="row" alignItems="center">
                <Box mb="8px" mt="20px" fontWeight={600}>Policy Number:</Box>
                <Box m="0px 0px 8px 5px" mt="20px" fontWeight={400}>{ atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0' ? 'PLCY-000000001':plcysn.data}</Box>
            </Box>
            <Box fontSize="14px" fontWeight={600} lineHeight="16px" >
                <Box mb="8px" mt="20px">Policy Name *</Box>
                <TextField name="policyname" fullWidth className={dsg.txtfld} placeholder="Ex: Employment Policy" disabled={match.params.params === 'review' ? true : false} error={form.policyname === '' ||  pName.data ? true : false} value={form.policyname} onChange={(e)=>handleOnChange(e)}
                />
            </Box>
            <Box fontSize="14px" fontWeight={600} lineHeight="16px" >
                <Box mb="8px" mt="20px">Policy Description *</Box>
                <TextField name="description" fullWidth multiline rows={3} disabled={match.params.params === 'review' ? true : false} error={form.description === '' ||  pName.data ? true : false} value={form.description} onChange={(e)=>handleOnChange(e)}/>
            </Box>
            <Box fontSize="14px" fontWeight={600} lineHeight="16px" >
                <Box mb="8px" mt="20px">Date of Upload *</Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker label="" disabled value={form.dateupload} onChange={(e)=>handleOnChange(e)}
                        renderInput={(params) => 
                            <TextField {...params} fullWidth className={dsg.txtfld}/>
                        }
                    />
                </LocalizationProvider>
            </Box>
            <Box fontSize="14px" fontWeight={600} lineHeight="16px" >
                <Box mb="8px" mt="20px">Issue Date *</Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker disabled={match.params.params === 'review' ? true : false} error={form.dateissued === '' ||  pName.data ? true : false} value={form.dateissued} onChange={(e)=>handleOnChange({target: {name: 'dateissued', value: e}})}
                        renderInput={(params) =>
                            <TextField {...params} fullWidth className={dsg.txtfld} />
                        }
                    />
                </LocalizationProvider>
            </Box>
            <Box fontSize="14px" fontWeight={600} lineHeight="16px" >
                <Box mb="8px" mt="20px">Prepared By *</Box>
                <Autocomplete disabled={match.params.params === 'review' ? true : false}
                    noOptionsText="No Result"
                    autoHighlight={true}
                    options={allUsers}
                    getOptionLabel={(data) => (data.uname)}
                    onChange={(e, value) => handleOnChange(e, value, 'preparedby')}
                    renderInput={(params) => (
                        <TextField fullWidth className={dsg.plcyTxt} {...params}/>
                    )}
                />
            </Box>
            <Box fontSize="14px" fontWeight={600} lineHeight="16px" >
                <Box mb="8px" mt="20px">Approved By *</Box>
                <Autocomplete disabled={match.params.params === 'review' ? true : false}
                    noOptionsText="No Result"
                    autoHighlight={true}
                    options={allUsers}
                    getOptionLabel={(data) => (data.uname)}
                    onChange={(e, value) => handleOnChange(e, value, 'approvedby')}
                    renderInput={(params) => (
                        <TextField fullWidth className={dsg.plcyTxt} {...params}/>
                    )}
                />
            </Box>
        </>
    )
}