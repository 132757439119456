import { useState } from "react"
import { Avatar, Box, Button } from "@mui/material"
import { NoResult } from "../../../../../empty/Index"
import { rqx } from "../../../../../../core/request/API"
import { env } from "../../../../../../core/Env"
import { UpdateAccess } from "./UpdateAccess"
import { NotifCount } from "../../../../../../core/request/Notif"
import Lock from '../../../../../../assets/images/icons/lock.png';

export const UserList = ({searchInput, searchData, ulistAccess, show, withAccess, saveLoader, setSearchData, successOpen, message, __SESSION, setDefaultList}) => {
    const [open, setOpen] = useState(false)
    const [idata, setIdata] = useState([])
    let company_id = __SESSION.data.uaccess[0].company_id;
    let user_id =  atob(__SESSION.data.ainfo.aid).split('_', 2)[1]
    const handleUpdateMenu = (value) => {
        if (show.data.data.access === null || show.data.data.access === undefined) {
            setIdata(value)
            setOpen(true)
        }
    }

    const handlerUpdateAccess = async(type, val) => {
        if (!saveLoader.data) {
            saveLoader.set(true)
            if (type === 'download') {
                if (searchInput.data !== '') {
                    setSearchData(
                        searchData.map((i) => i.id === val.id ? {...i, stat:{...i.stat, download:!val.stat.download}} : {...i} )
                    )
                }
                ulistAccess.set(
                    ulistAccess.data.map((i) => i.id === val.id ? {...i, stat:{...i.stat, download:!val.stat.download}} : {...i} )
                )
                setDefaultList(ulistAccess.data.map((i) => i.id === val.id ? {...i, stat:{...i.stat, download:!val.stat.download}} : {...i} ))
            }else{
                if (searchInput.data !== '') {
                    setSearchData(
                        searchData.map((i) => i.id === val.id ? {...i, stat:{...i.stat, update: !val.stat.update}} : {...i} )
                    )
                }
                ulistAccess.set(
                    ulistAccess.data.map((i) => i.id === val.id ? {...i, stat:{...i.stat, update: !val.stat.update}} : {...i} )
                )
                setDefaultList(ulistAccess.data.map((i) => i.id === val.id ? {...i, stat:{...i.stat, update: !val.stat.update}} : {...i} ))
            }
           
           
            let fltr = ulistAccess.data.map(v => {return {
                user_id: v.id, 
                view:true,
                update: v.id === val.id && type === 'update' ? !val.stat.update : v.stat.update, 
                download:  v.id === val.id && type === 'download' ? !val.stat.download :  v.stat.download 
            }})
            let body = {
                company_id: company_id,
                access:fltr,
                user_id: user_id,
                id: withAccess.data.val[0].id,
                ref: 'update'
            }
            setOpen(false)
            const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/access/update`, body)
            if (res.message === 'success') {
                let f = fltr.filter(v => { return v.user_id === val.id} )
                await NotifCount([{...f[0], remove:false, file:show.data.data.feature_type === 1 ? 'reg' : 'con', file_id:show.data.data.id}], __SESSION, 'updtacc')
                saveLoader.set(false)
                successOpen.set(true)
                message.set('Successfully Update')
            }else{
                saveLoader.set(false)
            }
        }
    }
    return (
        ulistAccess.data.length === 0 ?
            <Box display="flex" alignItems="center" justifyContent="center" height="100%" gap="12px">
                <img src={Lock} alt={Lock} />
                <Box fontWeight={400} fontSize={14} lineHeight="22px" color="#A2A3A9">Not shared</Box>
            </Box>
        :
            (searchInput.data !== '' ? searchData : ulistAccess.data).length === 0 ? 
                <NoResult />
            :
                (searchInput.data !== '' ? searchData.sort((a, b) => a.first_name.localeCompare(b.first_name)) : ulistAccess.data.sort((a, b) => a.first_name.localeCompare(b.first_name))).map((v,k) => (
                    <Box display="flex" alignItems="center" gap="11px" py={1} key={k}>
                        <Avatar src={JSON.parse(v.profile).profile} sx={{height: 32, width: 32}} />
                        <Box display="flex" flexDirection="column" fontWeight={400}>
                            <Box fontSize={14} lineHeight="24px">{v.first_name} {v.last_name}</Box>
                            <Button variant="text" onClick={() => handleUpdateMenu(v)}
                                sx={{p: '0px',  fontSize: 12, lineHeight: '24px', justifyContent: 'flex-start', opacity:show.data.data.access === null || show.data.data.access === undefined ? 1 : 0.3}} >
                                {
                                    v.stat.update === false && v.stat.download === false ?
                                        'View'
                                    :
                                        v.stat.update && v.stat.download ?
                                            'Update & Download'
                                        :
                                            v.stat.update === false && v.stat.download ?
                                                'View & Download'
                                            :
                                                'View & Update'
                                }
                            </Button>
                        </Box>
                        {
                            idata.id === v.id &&
                                <UpdateAccess open={open} setOpen={setOpen} v={v} handlerUpdateAccess={handlerUpdateAccess} saveLoader={saveLoader} />
                        }
                    </Box>
            ))
    )
}


