import { Avatar, AvatarGroup, Box, Skeleton } from "@mui/material";
import Lock from '../../../../../../assets/images/icons/lock.png';

export const SharedWith = ({shareType, withAccess, ulistAccess}) => {
    const handlerRedirectTo = () => {
        shareType.set('details')
    }
  
    return (
        <Box display="flex" alignItems="center" gap="8px">
            <Box fontSize={14} fontWeight={400} lineHeight="22px" >Shared with:</Box>
            {
                 withAccess.data.loader ?
                    ['', '', ''].map((v,k) => (
                        <Skeleton variant="circular" width={32} height={32} key={k} sx={{mr: '12px'}} />
                    ))
                :
                    ulistAccess.data.length === 0 ?
                        <Box display="flex" alignItems="center" gap="12px" onClick={() => handlerRedirectTo()}>
                            <img src={Lock} alt={Lock} />
                            <Box fontWeight={400} fontSize={14} lineHeight="22px" color="#A2A3A9">Not shared</Box>
                        </Box> 
                    :
                        <AvatarGroup max={5} sx={{gap: '12px', '& .MuiAvatar-root': {width: 32, height: 32, fontSize: 14} }} onClick={() => handlerRedirectTo()}>
                            {
                                ulistAccess.data.map((v,k)=> (
                                    <Avatar src={JSON.parse(v.profile).profile} alt="profile" sx={{width: 32, height: 32}} key={k} />
                                ))
                            }
                        </AvatarGroup>
            }
        </Box>
    )
}
