import { Box } from '@mui/material';
import { Waypoint } from 'react-waypoint';
import { SnakeLoader } from '../../../../../../core/loader/SnakeLoader';
import { ConfirmRemove, NoFound } from '../.././../../global/DesktopDsg';
import { __InitRegList } from '../../../../global/Function';
import { rqx } from '../../../../../../core/request/API';
import { env } from '../../../../../../core/Env';
import { ThreeDots } from '../modal/ThreeDots';
import { FileInfo } from '../FileInfo';
import { ListView } from './List';
import { GridView } from './Grid';
import { DL } from '../modal/DL';
import '@react-pdf-viewer/core/lib/styles/index.css';

export const List = ({ __SESSION, __LoadmoreData, cloud_state}) => {
    const { regList, listView, regActive, show, successOpen, message, modal, actType, showDl, filter, isLoading, inftScroll, limit, lid, openShare, shareType, } = cloud_state

    const handlerThreeDots = (e, val, type) => {
        if (type === 'dl') {
            showDl.set(e.currentTarget)
        }else{
            e.stopPropagation()
            regActive.set({...regActive.data, openMenu : true})
            show.set({...show.data, data : val})
        }
    }

    const handlerView = (val) => {
        show.set({...show.data, data : val, view : true, fullview : false})
        regActive.set({...regActive.data, val : '',  openMenu : false })
    }

    const handlerDelete = async(type) => {
        if (type === 'cancel') {
            show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}})
        }else{
            show.set({...show.data, remove:{...show.data.remove, loader:true}})
            let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/registration/update`, {
                id:show.data.data.id, 
                user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                status:4, 
                ref:'trash'
            })
            if (res.message === 'success') {
                message.set('Successfully removed')
                successOpen.set(true)
                regActive.set({...regActive.data, val : '', openMenu : null})
                __InitRegList(0, filter.data, isLoading, inftScroll, __SESSION, limit, lid, regList)
                show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}, view:false})
            }
        }
    }
    
    return (
        <>
            {
                (regList.data.length === 0?
                    <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
                        <NoFound msg="No File found. Upload Now!" />
                    </Box>
                :
                    <>
                        {listView.data ?
                            <ListView regList={regList} handlerView={handlerView} handlerThreeDots={handlerThreeDots} />
                        :
                            <GridView regList={regList} handlerView={handlerView} handlerThreeDots={handlerThreeDots} />
                        }
                        {
                            inftScroll.data && (
                                <Box display="flex" justifyContent="center">
                                    <Waypoint onEnter={__LoadmoreData} >
                                        <Box component="strong" mt={5} display="flex" justifyContent="center">
                                            <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                                        </Box>
                                    </Waypoint>
                                </Box>
                            )
                        }
                    </>
                )
            }
            {
                regActive.data.openMenu && 
                    <ThreeDots regActive={regActive} show={show} __SESSION={__SESSION} regList={regList} successOpen={successOpen} message={message} modal={modal} actType={actType} showDl={showDl} openShare={openShare} shareType={shareType} />  // ThreeDotsModal
            }
            {
                show.data.view &&
                    <FileInfo show={show} handlerThreeDots={handlerThreeDots} />  // Viewing file
            }
            {
                showDl.data &&
                    <DL showDl={showDl} show={show} message={message} successOpen={successOpen} regActive={regActive} />
            }
            <ConfirmRemove show={show} HandlerDelete={handlerDelete} />
        </>
    )
}
