import { Box, Skeleton } from "@mui/material";

export const SearchLoader = ({shareType}) => {
    return (
        ['','','','',].map((v,k) => (
            <Box display="flex" alignItems="center" gap="24px" py={1} mb={1} key={k}>
                {
                    shareType.data !== 'details' &&
                        <Skeleton variant="rectangular" width={16} height={16} />
                }
                <Box display="flex" alignItems="center" gap="12px">
                    <Skeleton variant="circular" width={32} height={32} />
                    <Box display="flex" flexDirection="column" fontWeight={400} lineHeight="24px">
                        <Skeleton variant="text" width={115} height={16} />
                        <Skeleton variant="text" width={80} height={16} />
                    </Box>
                </Box>
            </Box>
        ))
    )
}
