
import { useHistory } from 'react-router-dom';
import { validateBtnNxt } from '../../../../../global/Function';
import { Box, Button } from '@mui/material';
import { S3Upload } from '../../../../../../../core/transmit/S3';
import { rqx } from '../../../../../../../core/request/API';
import { env } from '../../../../../../../core/Env';
import { ToolContext } from '../../../../../../../core/context/ToolContext';
import { useContext } from 'react';
import { SnakeLoader } from '../../../../../../../core/loader/SnakeLoader';
import moment from 'moment';

export const Buttons = ({show, conToggle, subparams, __initConList, __seriesNum, __ListConName }) =>{
    
    const nav = useHistory()
    const { tool_state, cloud_state} = useContext(ToolContext)
    const { cform, listConName, cactType, cOthers, saveLoader, csuccessOpen, reloadStorage } = cloud_state
    const { __SESSION } = tool_state

    const handlerBtn = async(type) => { 
        if (type === 'next') {
            if (!cform.data.name.stat) {
                if (!validateBtnNxt(cform).disabled && !saveLoader.data) {
                    nav.push('/my-cloud/contracts/review')
                }
            }
        }else if (type === 'edit') {
            nav.push('/my-cloud/contracts/create')
        }else{
            if (!saveLoader.data) {
                saveLoader.set(true)
                let count = 0;
                let urlS3;
                let urlS32;
        
                if (cform.data.conFile.photo.val !== '') {
                    urlS3 = cactType.data === 'update' && cform.data.conFile.photo.val.slice(0, 5) === 'https' ? cactType.data : await S3Upload(cform.data.conFile.file.val);
                }
                if (cform.data.conFile2.photo.val !== '') {
                    urlS32 = cactType.data === 'update' && cform.data.conFile2.photo.val.slice(0, 5) === 'https' ? cactType.data : await S3Upload(cform.data.conFile2.file.val);
                }
                let d = []
                if (urlS3 !== undefined || cform.data.conFile.at_id !== undefined) {
                    let v = cform.data.conFile.file.val
                    d.push(
                        {
                            at_id:cform.data.conFile.at_id === undefined ? 0  : cform.data.conFile.at_id,
                            file_name:v === '' ? '' : cform.data.conFile.file.val[0].name,
                            file_type:v === '' ? '' : cform.data.conFile.file.val[0].type,
                            file_size:v === '' ? '' : cform.data.conFile.photo.val.slice(0, 5) === 'https' ? cform.data.conFile.file.val[0].size :count += Math.round(cform.data.conFile.file.val[0].size / 1024),
                            file_link:v === '' ? '' : cform.data.conFile.photo.val.slice(0, 5) === 'https' ? cform.data.conFile.photo.val : urlS3.location,
                        }
                    )
                }
                if (urlS32 !== undefined || cform.data.conFile2.at_id !== undefined) {
                    let v = cform.data.conFile2.file.val
                    d.push(
                        {
                            at_id:cform.data.conFile2.at_id === undefined ? 0  : cform.data.conFile2.at_id,
                            file_name:v === '' ? '' : cform.data.conFile2.file.val[0].name,
                            file_type:v === '' ? '' : cform.data.conFile2.file.val[0].type,
                            file_size:v === '' ? '' : cform.data.conFile2.photo.val.slice(0, 5) === 'https' ? cform.data.conFile2.file.val[0].size :count += Math.round(cform.data.conFile2.file.val[0].size / 1024),
                            file_link:v === '' ? '' : cform.data.conFile2.photo.val.slice(0, 5) === 'https' ? cform.data.conFile2.photo.val : urlS32.location,
                        }
                    )
                }
                if (d.length > 0) {
                    let data = {
                        id:cactType.data === 'update' ? cform.data.id : 0,
                        name:cOthers.data ? cform.data.conOther.val : listConName.data.filter(v => { return v.id === cform.data.conName.val})[0].name,
                        company_id:__SESSION.data.uaccess[0].company_id,
                        user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                        client_name:cform.data.name.val,
                        start_date:moment(cform.data.date_from.val).format('YYYY-MM-DD'),
                        end_date:moment(cform.data.date_to.val).format('YYYY-MM-DD'),
                        file:d,
                        ref:''
                    }
                    let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/contracts/${cactType.data === 'update' ? 'update' : 'create'}`, data)
                    if (res.message === 'success') {
                        await __initConList()
                        await __ListConName()
                        await __seriesNum()
                        await csuccessOpen.set(true)
                        setTimeout(() => {
                            csuccessOpen.set(false)
                            saveLoader.set(false)
                            reloadStorage.set(true)
                            conToggle.set({select:false, slctfile:{stat:false, data:''}, details:false, review:false, unsaved:false})
                            show.set({...show.data, view:false, fullview:false})
                            nav.push('/my-cloud/contracts/list')
                        }, 1000);
                    }else if(res === 'Contracts Already Exist'){
                        csuccessOpen.set(false)
                        saveLoader.set(false)
                        cform.set({...cform.data, 
                            name:{...cform.data.name, stat:true, msg:'name is already exist'},
                        })
                        if (cactType.data === 'update') {
                            nav.push(`/my-cloud/contracts/${window.btoa(cform.data.id)}`)
                        }else{
                            nav.push('/my-cloud/contracts/create')
                        }
                    }
                }
            }
        }
    }
    
    return (
        <Box minHeight={76} p={2} bgcolor="#FEFEFE" boxShadow="0px 0px 24px rgba(0, 0, 0, 0.08)">
            {
                subparams !== 'review' ?
                    <Button variant="contained" fullWidth disableElevation 
                        sx={{backgroundColor: cform.data.name.stat ? '#C7DAD7' : validateBtnNxt(cform).bgcolor, '&:hover': { backgroundColor: cform.data.name.stat ? '#C7DAD7' : validateBtnNxt(cform).bgcolor}  }}
                        onClick={() => handlerBtn('next')}
                    >
                        Next
                    </Button>
                :
                    <Box display="flex" alignItems="center" gap="9px">
                        {saveLoader.data ? 
                            <Button variant="contained" fullWidth disableElevation 
                                sx={{backgroundColor:'#C7DAD7',  borderRadius: '6px', }} 
                                >
                                Edit
                            </Button>
                        :
                            <Button variant="contained" fullWidth disableElevation 
                                sx={{ bgcolor: '#E2EDE7', border: '1px solid #107038', color: '#107038', borderRadius: '6px', '&:hover': { bgcolor: '#E2EDE7', color: '#107038', }  }} 
                                    onClick={() => handlerBtn('edit')}
                                >
                                Edit
                            </Button>
                        }
                        <Button variant="contained" fullWidth disableElevation
                            sx={{ borderRadius: '6px' }} 
                            onClick={() => handlerBtn('save')}
                        >
                            {saveLoader.data ? 
                                <Box display="flex" alignItems="center">
                                    {cactType.data === 'update' ? 
                                        <Box>Updating</Box>
                                    :
                                        <Box>Saving</Box>
                                    }
                                    <SnakeLoader size="5px" bg="#FFFFFF" distance="2.5px" />
                                </Box>
                            : 
                            (cactType.data === 'update' ? 
                                    'Update'
                                :
                                    'Save'
                                )
                            }
                        </Button>
                    </Box>

            }
        </Box>
    )
}