import { DIndex } from './desktop/Index'
import { MIndex } from '../mycloud/devices/mobile/Index'
import { VM } from '../../core/VM'
import { ToolContext } from '../../core/context/ToolContext'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PermissionModal, UpgradeStorage } from './global/DesktopDsg'
import { rqx } from '../../core/request/API'
import { env } from '../../core/Env'
import { __InitConList, __InitRecentList, __InitRegList, __InitSharedList, __InitTrashFiles, __InitTrashFolder } from './global/Function'
import { Box, Skeleton } from '@mui/material'
import { DeleteRT, RestoreRemoveRT, SharedAccessRT } from './global/RealTimeFunctions'
import { useLiveQuery } from 'dexie-react-hooks'
import { db } from '../../db/db'


const useCombinedData = () => {
    return useLiveQuery(async () => {
        const [reglist, conlist, docs] = await Promise.all([
            db.reglist.toArray(),
            db.conlist.toArray(),
            db.docs.toArray()
        ]);
        return { reglist, conlist, docs };
    });
};

export const MyCloud = ({match}) => {
    const ubrdata = useCombinedData()
    let view = VM()
    const nav = useHistory()
    const [isShow, setisShow] = useState(false)
    const { tool_state, cloud_state, home_state, notif_state } = useContext(ToolContext)
    const { 
        regList, conList, rcntList,
        reloadStorage, sufficientStorage, insuffShow, storage, storageLoader, allUsrList, departmentList, show,  ulistAccess,
        isLoading, inftScroll, listView, limit, lid, filter, sharedList, sharedFltr, sharedCount, accessLoader, docuFolder, docuFile, 
        favList, trashFiles,
        folderLoader, folderInfi, folderLid, trashFolder
    } = cloud_state
    const { dateToday } = home_state
    const { __SESSION, globalLoader } = tool_state
    let company_id = __SESSION.data.uaccess[0].company_id;
    let owner_id = __SESSION.data.uaccess[0].owner_id;
    const uai_user = process.env.REACT_APP_UAI_REGULAR;

    const handleProceed = async(type) => {
        await setisShow(false)
        setTimeout(() => {
            if (type === 'cancel') {
                nav.push('/')
            }else{
                nav.push('/general-maintenance/company-setup')
            }
        }, 200);
    }

    useEffect(() => {
        const __initUBR = () => {
            if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0' && ubrdata !== undefined) {
                let total = 0
                let rdata = {reg:0, regFile:0}
                let cdata = {con:0, conFile:0}
                let ddata = {docs:0, docFile:0}
                if (ubrdata.reglist.length > 0) {
                    let rtotal =  ubrdata.reglist[0].file_size
                    total =  total + rtotal
                    rdata = {reg:rtotal, regFile:1}
                
                }
                if (ubrdata.conlist.length > 0) {
                    let ctotal = JSON.parse(ubrdata.conlist[0].file).reduce((prev, current) => { return prev + + current.file_size; }, 0);
                    total = total + ctotal
                    cdata = {con:ctotal, conFile:1}
                }
                if (ubrdata.docs.length > 0) {
                    let dtotal = ubrdata.docs[0].file_size
                    total = total + dtotal
                    ddata = {docs:dtotal, docFile:ubrdata.docs[0].file_length}
                }
                storage.set((prev) => ({...prev, 
                    used:total,
                    total:21474836480,
                    ...rdata,
                    ...cdata,
                    ...ddata
                })) 
            }
        }
        __initUBR()
        // eslint-disable-next-line
    }, [ubrdata])
    
    useEffect(() => {
        if (company_id === null) {
            setisShow(true)
        }
        reloadStorage.set(true)
        const __initUsersAndDept = async() => {
            const getAllUsers = await rqx.get(`<<global>>/${env()}/tooluser/gettooluser`, {company_id: company_id !== null ? company_id : 0, tool_id: process.env.REACT_APP_TID, ref: 'active' })
            let res_fltr = getAllUsers.users.map(v => { return {...v, stat:{view:false, update:false, download:false}  } })
            allUsrList.set({...getAllUsers, users:res_fltr})
            let res = await rqx.get(`<<tool>>/${env()}/general-maintenance/masterlist/department/infinite`, {
                lastId: 0,
                limit: 100,
                company_id: company_id !== null ? company_id : 0,
                user_id: owner_id
            })
            if (res) {
                let all = [{id:0, name:'ALL'}]
                departmentList.set([...all, ...res.sort((a, b) => a.name.localeCompare(b.name))])
            }
        }
        let subscribe = true
        if (subscribe) {
            __initUsersAndDept()
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let subscribe = true
        if (!globalLoader.data) {
            if (reloadStorage.data && dateToday.data !== undefined) {
                const __init = async() => {
                    storageLoader.set(true)
                    let data = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/storage/read`, {
                        company_id:company_id, 
                    })
                    let reg = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/dashboard/registration`, {ref: 'total', company_id: company_id, today: dateToday.data})
                    let con = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/dashboard/contracts`, {ref: 'expiring', company_id: company_id, today: dateToday.data})
                    let doc = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/documents/read`, {ref:'all-files', company_id: company_id })
                    
                    let favorites = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/dashboard/favorites`, { user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1] })
                    let shared = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/access/count`, {user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1], user_type: __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? 'admin' : 'regular_user', company_id: company_id })
                    sharedCount.set(shared)
                    let valid = (data[0].registrations_used + data[0].contracts_used + data[0].documents_used) >= data[0].total
                    sufficientStorage.set(valid)
                    storage.set({...storage.data, 
                        used:data[0].registrations_used + data[0].contracts_used + data[0].documents_used, 
                        total:data[0].total, 
                        reg:data[0].registrations_used === null ? 0 : data[0].registrations_used, 
                        con:data[0].contracts_used === null ? 0 : data[0].contracts_used,
                        regFile:reg[0].count,
                        conFile:con[0].count,
                        favtotal:favorites,
                        docs:data[0].documents_used === null ? 0 : data[0].documents_used,
                        docFile:doc[0].count,
                    })
                    await storageLoader.set(false)
                }
                if (subscribe) {
                    __init()
                }
                
                reloadStorage.set(false)
                return () => subscribe = false
            }
        }
        // eslint-disable-next-line
    }, [globalLoader.data, reloadStorage.data, dateToday.data])
    
    useEffect(() => {
        const __initAccess = async() => {
            if (notif_state.rtNotifAccess.data) {
                if (notif_state.notifPerUser.data !== 0) {
                    if (JSON.parse(notif_state.notifPerUser.data).company_id === company_id) {
                        reloadStorage.set(true)
                        const { action, access } = JSON.parse(notif_state.notifPerUser.data) 

                        // CREATE
                        if (action === 'create') {
                            if (__SESSION.data.uaccess[0].role_id !== parseInt(uai_user)) {
                                if (parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) !== access[0].user_id) {
                                    if (match.params.feature === 'shared-access') {
                                        if (access[0].file === 'folder' || access[0].file === 'file') {
                                            listView.set(view === '' ? false : true)
                                            accessLoader.set(!accessLoader.data)
                                        }
                                    }else if(match.params.feature === 'registrations') {
                                        if (access[0].file === 'reg') {
                                            listView.set(view === '' ? false : true)
                                            await __InitRegList(0, filter.data, isLoading, inftScroll, __SESSION, limit, lid, regList)
                                        }
                                    }else if(match.params.feature === 'contracts') {
                                        if (access[0].file === 'con') {
                                            listView.set(view === '' ? false : true)
                                            await __InitConList(0, isLoading, inftScroll, __SESSION, limit, lid, conList)
                                        }
                                    }else if (match.params.feature === 'recent') {
                                        if (access[0].file === 'reg' || access[0].file === 'con') {
                                            listView.set(view === '' ? false : true)
                                            await __InitRecentList(isLoading, __SESSION, rcntList)
                                        }
                                    }
                                }
                            }
                            return 'successfully create'
                        }

                        //RESTORE AND REMOVE
                        if (action === 'restore' || action === 'remove' || action === 'multi-restore' || action === 'multi-remove' ) {
                            let res = await RestoreRemoveRT(trashFolder, trashFiles, rcntList, regList, conList, match, listView, isLoading, __SESSION, filter, inftScroll, limit, lid, accessLoader, notif_state, __InitRecentList, __InitRegList, __InitConList)
                            return res
                        }
                       
                        // DELETE FILE
                        if (action === 'delete') {
                            let res = await DeleteRT( notif_state, regList, conList, favList, docuFolder, docuFile, match, rcntList, __InitTrashFolder, __InitTrashFiles, folderLoader, folderInfi, __SESSION, limit, folderLid, trashFolder, isLoading, inftScroll, lid, trashFiles)
                            return res
                        }
                        
                        // SHARED ACCESS
                        let res_access = await SharedAccessRT(ulistAccess, notif_state, __SESSION, match, listView, isLoading, filter, inftScroll, limit, lid, accessLoader, sharedFltr, show, rcntList, regList, conList, sharedList, docuFolder, docuFile, __InitRecentList, __InitRegList, __InitConList, __InitSharedList)
                        return res_access
                    }
                }
            }
        }
        const __init = async() => {
            await __initAccess()
            await notif_state.rtNotifAccess.set(false)
        }
        let subscribe = true
        if (subscribe) {
            __init()            
        }

        return () => subscribe = false
        // eslint-disable-next-line
    }, [
        notif_state.rtNotifAccess.data, 
        notif_state.notifPerUser.data
    ])
    
    return (
        <Box height="100%">
            {
                globalLoader.data ?
                    <Box height="100%" display="flex" alignItems="center" justifyContent="center" m={2}>
                        <Skeleton variant="rectangular" sx={{height: '100%', width:'100%', borderRadius:'10px'}} />
                    </Box>
                :(
                    view === '' ?
                        <DIndex match={match.params}/>
                    :
                        <MIndex match={match.params}/>
                )
            }
            {(sufficientStorage.data && atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0') && (
                <UpgradeStorage insuffShow={insuffShow}/>
            )}
            {atob(__SESSION.data.ainfo.aid).split('_', 2)[1] !== '0' && (
                <PermissionModal show={isShow} handleProceed={handleProceed}/>
            )}
        </Box>
    )
}