import { Box, Button, Fade, Modal, Typography } from "@mui/material"
import CampaignIcon from '@mui/icons-material/Campaign';
import { useContext } from "react";
import { ToolContext } from "../../core/context/ToolContext";
import { rqx } from "../../core/request/API";
import { env } from "../../core/Env"

export const WelcomeSpeakout = ({ open, setOpen }) => {
    const { personalization_state, tool_state} = useContext(ToolContext)
    const { __SESSION } = tool_state
    const { personalizationData } = personalization_state

    const handleWelcome = async() => {
        const user_id = atob(__SESSION.data.ainfo.aid).split('_',  2)[1];
        let filter = personalizationData.data.filter(v => {return v.feature_name !== 'speakout'}
        )
        let data = {
            user_id: parseInt(user_id),
            tool_id: parseInt(process.env.REACT_APP_TID),
            feature_name: 'speakout',
            personalization: [
                {sf_name: 'welcome', status: 1}
            ]
        }
        personalizationData.set([...filter, ...[data]])
        let res = await rqx.post(`<<global>>/${env()}/personalization/create`, data)
        if(res.msg === 'success') {
            setOpen(false)
        }
    }

    return (
        <Modal
            open={open}
            closeAfterTransition
        >
            <Fade in={open}>
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" >
                    <Box bgcolor="#ffffff" borderRadius="12px" width={411}>
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{backgroundImage:`url(https://pofsis-business.s3.amazonaws.com/biz/1683079246054.png)`, height:'250px', width:'100%', borderRadius:'12px 12px 0px 0px'}}>
                            <Box><CampaignIcon fill="#FFFFFF" sx={{color: '#FFFFFF', fontSize: 100, transform: 'rotate(-35deg)', textShadow: '0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)'}}/></Box>
                        </Box>
                        <Box p="32px 24px 24px 24px">
                            <Typography fontSize={24} fontWeight={700} lineHeight="26px" color="#313131" align="center">Welcome to Speak Out!</Typography>
                            <Typography fontSize={14} fontWeight={400} lineHeight="23px" pt="16px" color="#737373">Send your concerns, suggestions, or feedback to the company anonymously.</Typography>
                            <Button variant="contained" fullWidth sx={{borderRadius: '6px', mt:'32px', minHeight:'48px'}} onClick={handleWelcome}>
                                Continue
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}