import { Box, Skeleton } from "@mui/material"
import { Category } from "./category/Index"
import { Team } from "./team/Index"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { NotFoundPage } from "../../empty/Index"
import { Concern } from "./concern/Index"
import { useContext, useEffect, useState } from "react"
import { ToolContext } from "../../../core/context/ToolContext"
import { WelcomeSpeakout } from "../../personalization/WelcomeSpeakout"

let uai_user = process.env.REACT_APP_UAI_REGULAR;

export const DIndex = ({match}) => {
    const { tool_state, personalization_state, notif_state } = useContext(ToolContext)
    const { __SESSION, globalLoader } = tool_state
    const { personalizationData } = personalization_state
    const { openNotif } = notif_state

    const [open, setOpen] = useState(false)
    let res = personalizationData.data.filter(v => {return v.feature_name === 'speakout'} );
    const __initPersonalization = async() => { 
        if (res.length === 0) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }

    const categoryType = [
        {id: 1, name: 'Suggestion/Recommendation'},
        {id: 2, name: 'Concern'}
    ]

    const nav = useHistory()
    const handleFeature = (feature) => {
        if (feature === 'category') {
            nav.push('/speakout/category/list')
        } else if (feature === 'team') {
            nav.push('/speakout/team/list')
        } else if (feature === 'concern') {
            nav.push('/speakout/concern/list')
        }
    }

    const createActive = match.params === 'create' || match.params === 'update'

    useEffect(() => {
        let subscribe = true
        if (!globalLoader.data) {
            if (!openNotif.data || (("Notification" in window) && Notification.permission !== 'default')) {
                if (subscribe) {
                    __initPersonalization()
                }
                return () => subscribe = false
            }
        }
        // eslint-disable-next-line
    }, [globalLoader.data])

    return (
        globalLoader.data ?
        <Box height="100%" display="flex" alignItems="center" justifyContent="center" m={2}>
            <Skeleton variant="rectangular" sx={{height: '100%', width:'100%', borderRadius:'10px'}} />
        </Box>
        :
        <Box height="100%" width="100%" display="flex" flexDirection="column">
            {
                !createActive && (
                <Box minHeight={__SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? '170px' : '150px'}>
                    <Box py="24px" fontSize="20px" fontWeight={500}>Speak Out</Box>
                    {
                        __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) &&
                        <Box width="50%" display="flex" flexDirection="row" justifyContent="center" alignItems="center" fontSize="16px" fontWeight={500}>
                            <>
                                <Box color={match.feature === 'category' ? '#FFFFFF' : '#7B7F82'} border="1px solid #EBEBEB" borderRadius="12px" width="100%" bgcolor={match.feature === 'category' ? '#11783C' : '#FFFFFF'} p={2} mr={2} display="flex" justifyContent="center" className="c-pointer" onClick={() => handleFeature('category')}>Category</Box>
                                <Box color={match.feature === 'team' ? '#FFFFFF' : '#7B7F82'} border="1px solid #EBEBEB" borderRadius="12px" width="100%" bgcolor={match.feature === 'team' ? '#11783C' : '#FFFFFF'} p={2} mr={2} display="flex" justifyContent="center" className="c-pointer" onClick={() => handleFeature('team')}>Team</Box>
                                <Box color={match.feature === 'concern' ? '#FFFFFF' : '#7B7F82'} border="1px solid #EBEBEB" borderRadius="12px" width="100%" bgcolor={match.feature === 'concern' ? '#11783C' : '#FFFFFF'} p={2} mr={2} display="flex" justifyContent="center" className="c-pointer" onClick={() => handleFeature('concern')}>Concern</Box>
                            </>
                        </Box>
                    }
                    <Box m="16px 0px 16px 0px" borderBottom="1px solid #0000001f" ></Box>
                </Box>

            )}
            <Box height="100%" pt={match.params === 'list' ? '' : '40px'} sx={{overflowY: 'hidden'}}>
                <Components match={match} categoryType={categoryType} />
            </Box>
            <Box minHeight="20px" ></Box>
            {
                __SESSION.data.uaccess[0].company_id !== null && (
                <WelcomeSpeakout open={open} setOpen={setOpen}/>

                )
            }
        </Box>
    )
}

const Components = ({match, categoryType }) => {
    // const feature = match.params?.feature
    if (match.feature === 'category') {
        return <Category match={match} categoryType={categoryType} />
    }else if (match.feature === 'team') {
        return <Team match={match}/>
    }else if (match.feature === 'concern') {
        return <Concern match={match} categoryType={categoryType} />
    }else {
        return <NotFoundPage />
    }
}