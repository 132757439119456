import { Box, InputBase } from '@mui/material'
import { SearchIcon } from '../../../../../core/global/Icons';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

export const Index = ({selectTool, setSelectTool, toolOwned , NoToolFound}) => {
    const selectToolHandle = (id) => {
        if (selectTool === id) {
            setSelectTool('')
        }else{
            setSelectTool(id)
        }
    }
  return (
        <Box height="100%" display="flex" flexDirection="column">
            <Box minHeight="36px">
                <InputBase
                    fullWidth
                    placeholder={`Search for...`} 
                    inputProps={{ 'aria-label': 'search' }}  startAdornment={ <Box pr={1} display="flex" alignItems="center"><SearchIcon/></Box> }
                    sx={{backgroundColor:"#ffffff", border:"#D9EBE8 solid 2px", borderRadius:'10px', padding:'5px 5px 5px 20px', minWidth:`291`, height:'49px'}}
                />
            </Box>
            <Box height="100%">
                {toolOwned.length > 0 ? 
                    <Box height="100%" overflow="auto" className="eleventh-step" pt={3}>
                        {toolOwned.map((v,k) => (
                            <Box onClick={()=> selectToolHandle(v.tid)} key={k} className="c-pointer" borderRadius={3} sx={{border:selectTool === v.tid ? "1px solid #11783C" : ''}} bgcolor="#ffffff" py={1} my={1} px={2}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Box display="flex" alignItems="center">
                                        <Box className="personal-logo-flat-top " borderRadius={1} overflow="hidden" style={{backgroundImage: `url(${JSON.parse(v.logo)})`, backgroundSize: 'cover', minWidth: '32px', minHeight:'32px'}}/>
                                        <Box>
                                            <Box fontSize={14} fontWeight="bold" ml="12px">{v.name}</Box>
                                        </Box>
                                    </Box>
                                    <CircleRoundedIcon fontSize="small" sx={{color:selectTool === v.tid ?"#11783C" : '#E1EEE6'}} />
                                </Box>
                            </Box>
                        )) }
                    </Box>
                : 
                    <Box height="100%">
                        <NoToolFound m={3}/>
                    </Box>
                }
            </Box> 
        </Box>
    )
}