import { Box, Modal, Slide, MenuItem, Divider } from "@mui/material"
import { Download, DownloadPDF } from '../../.../../../../../../core/transmit/S3';
import imgDL from '../../../../../../assets/images/icons/imgDl.png';
import pdfDL from '../../../../../../assets/images/icons/pdfDl.png';
import { DlIcon } from "../../../../../../core/global/Icons";

export const DL = ({showDl, show, message, successOpen, active}) => {
    const handlerDL = async(e, type) => {
        let res;
        if (type === 'pdf') {
            res = await DownloadPDF(show.data.data.file_link, show.data.data.file_name+'.'+show.data.data.file_extension)       
        }else if(type === 'download'){
            res = await Download(e, show.data.data.file_link, show.data.data.file_name)
        }else{
            res = await Download(e, show.data.data.file_link, show.data.data.file_name+'.'+show.data.data.file_extension)
        }

        if (res === 'success') {
            showDl.set(null)
            active.set({...active.data, val : '', openMenu : null})
            message.set('Successfully download')
            successOpen.set(true)
        } 
    }
    
    return (
        <Modal open={Boolean(showDl.data)} BackdropProps={{ sx: {bgcolor: '#0000001F'} }} sx={{zIndex: 1304}} onClose={() => showDl.set(null)} className="noutlined">
            <Slide in={Boolean(showDl.data)} direction="up" className="noutlined">
                <Box height="100%" width="100%" display="flex" justifyContent="flex-end" flexDirection="column">
                    <Box height="100%" onClick={() => showDl.set(null)} />
                    {show.data.data.file_type.includes('pdf') || show.data.data.file_type.includes('image') ? 
                        <Box minHeight={112} bgcolor="#FFFFFF" p="12px 8px" borderRadius="16px 16px 0px 0px" boxShadow="0px 0px 24px rgba(0, 0, 0, 0.08)">
                            <MenuItem sx={{minHeight: '44px !important', py: 0}} onClick={(e) => handlerDL(e, 'pdf')}>
                                <img src={pdfDL} alt={pdfDL} />
                                <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px"> PDF </Box>
                            </MenuItem>
                            <Divider sx={{my: '0px !important'}} />
                            <MenuItem sx={{minHeight: '44px !important', py: 0}} onClick={(e) => handlerDL(e, 'image')}>
                                <img src={imgDL} alt={imgDL} />
                                <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px"> Image </Box>
                            </MenuItem>
                        </Box>
                    :
                        <Box minHeight={60} bgcolor="#FFFFFF" p="12px 8px" borderRadius="16px 16px 0px 0px" boxShadow="0px 0px 24px rgba(0, 0, 0, 0.08)">
                            <MenuItem sx={{minHeight: '44px !important', py: 0}} onClick={(e) => handlerDL(e, 'download')}>
                                <DlIcon />
                                <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px"> Download </Box>
                            </MenuItem>
                        </Box>
                    }
                </Box>
            </Slide>
        </Modal>
    )
}
