import { Box, Modal, Slide, MenuItem, Divider } from "@mui/material"
import { Download, DownloadPDF } from '../../.../../../../../../core/transmit/S3';
import imgDL from '../../../../../../assets/images/icons/imgDl.png';
import pdfDL from '../../../../../../assets/images/icons/pdfDl.png';

export const DL = ({showDl, show, message, successOpen, active}) => {

    const handlerDL = async(e, type) => {
        let parse = JSON.parse(show.data.data.file)
        
        for (let i = 0; i < parse.length; i++) {
            let res;
            if (type === 'pdf') {
                if (parse[i].file_type.includes('image')) {
                    res = await DownloadPDF(parse[i].file_link, parse[i].file_name)       
                }else{
                    res = await Download(e, parse[i].file_link, parse[i].file_name)
                }
            }else{
                res = await Download(e, parse[i].file_link, parse[i].file_name)
            }
            if (res === 'success' && i === parse.length - 1 ) {
                showDl.set(null)
                active.set({...active.data, val : '', openMenu : null})
                message.set('Successfully download')
                successOpen.set(true)
            }
        }
    }
    
    return (
        <Modal open={Boolean(showDl.data)} BackdropProps={{ sx: {bgcolor: '#0000001F'} }} sx={{zIndex: 1304}} onClose={() => showDl.set(null)} className="noutlined">
            <Slide in={Boolean(showDl.data)} direction="up" className="noutlined">
                <Box height="100%" width="100%" display="flex" justifyContent="flex-end" flexDirection="column">
                    <Box height="100%" onClick={() => showDl.set(null)} />
                    <Box minHeight={112} bgcolor="#FFFFFF" p="12px 8px" borderRadius="16px 16px 0px 0px" boxShadow="0px 0px 24px rgba(0, 0, 0, 0.08)">
                        <MenuItem sx={{minHeight: '44px !important', py: 0}} onClick={(e) => handlerDL(e, 'pdf')}>
                            <img src={pdfDL} alt={pdfDL} />
                            <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px"> PDF </Box>
                        </MenuItem>
                        <Divider sx={{my: '0px !important'}} />
                        <MenuItem sx={{minHeight: '44px !important', py: 0}} onClick={(e) => handlerDL(e, 'image')}>
                            <img src={imgDL} alt={imgDL} />
                            <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px"> Image </Box>
                        </MenuItem>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}
