import { useEffect, useState } from "react"
import { Box, Menu, MenuItem } from "@mui/material"
import { FolderIcon, UploadFileIcon, UploadFolderIcon } from "../../../../../core/global/Icons"
import { rqx } from "../../../../../core/request/API"
import { env } from "../../../../../core/Env"
import { S3Upload } from "../../../../../core/transmit/S3"
import { NotifCount } from "../../../../../core/request/Notif"
import { db } from "../../../../../db/db"

export const MenuAdd = ({__SESSION, cloud_state, user_id, company_id, getRootProps, getInputProps, __InitDocuFile, match, __InitDocuFolder, openMaxFile, message, successOpen, errOpen }) => {
    const { err, addMenu, openModal, folderName, selectedFiles, uploading, reloadStorage, docuFile, storage, docuFolder } = cloud_state
    const urlParams = new URLSearchParams(window.location.search)
    const handlerAddAction = (action) => {    
        if (action === 'folder') {
            openModal.set(true)
            folderName.set('')
            addMenu.set(null)
            err.set({...err, val : false, msg: ''})
        }else if(action === 'file'){
            selectedFiles.set([])
        }
    }

    let data = []
    const LoaderFunction = async(rawFiles) => {
        let startStorage = 11000
        let fecthingStorage = 0
        let count = 0;
        const timer = setInterval(() => {
            if (count === 0) {
                if (startStorage >= rawFiles[0].size) {
                    clearInterval()
                    fecthingStorage = 99
                }else{
                    fecthingStorage = (startStorage / rawFiles[0].size) * 100
                    startStorage = startStorage * 2
                }
                selectedFiles.set((prevProgress) => {
                    return prevProgress.map((v) => v.file_size === rawFiles[0].size ? {...v, progress : Math.round(fecthingStorage)} : {...v, progress:v.progress})
                });
            }
        }, 500);

        let s3Result = await S3Upload(rawFiles, selectedFiles)
        if (s3Result) {
            count = 1;
            let arry = selectedFiles.data.map((v) => v.file_size === rawFiles[0].size ? {...v, progress : 100} : {...v, progress:v.progress})
            let fltrArry = arry.filter((v) => { return v.file_size === rawFiles[0].size } )
            data.push({...fltrArry[0]})
            const mergeArr = arry.map((md) => {
                const au = data.find((au) => au.file_name === md.file_name);
                return {...md, ...au};
            });
            selectedFiles.set(
                mergeArr
            )
            clearInterval(timer)
            startStorage = 11000;
            fecthingStorage = 0;
            count = 0;
            return s3Result.location
        }
    }

    const __initUploadFiles = async() => {
        let rawFiles = [...selectedFiles.data]
        let s3Files = []
        if (rawFiles.length !== 0) {
            for (let i = 0; i < rawFiles.length; i++) {
                let s3Result = await LoaderFunction(rawFiles[i].root)
                await s3Files.push({'at_id': i, 'file_name': rawFiles[i].file_name, 'file_size': rawFiles[i].file_size, 'file_type': rawFiles[i].file_type, 'file_link': s3Result })
            }
            if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0') {
                let data = {
                    user_favorite_status:null,
                    user_favorite_id:null,
                    access:null,
                    company_folder_id:null,
                    series_num:"DCMT-00000001",
                    file_name:s3Files[0].file_name,
                    file_type:s3Files[0].file_type,
                    file_size:s3Files[0].file_size,
                    file_link:s3Files[0].file_link,
                    status:1,
                    folder_status:null,
                    parent_id:null,
                    data_status:"InUse",
                    active:1,
                    type:'create_file', 
                    file: s3Files,
                    file_length:1
                }
                db.docs.add(data);
                docuFile.set([data])
                message.set('Successfully Upload')
                successOpen.set(true)
                reloadStorage.set(true)
                storage.set({...storage.data, 
                    used:storage.data.used + s3Files[0].file_size,
                    total:21474836480,
                    docs:s3Files[0].file_size,
                    docFile:1,
                }) 
                
                const mergeArr = selectedFiles.data.map((md) => {
                    return {...md, error:false, progress:100};
                });
                selectedFiles.set(mergeArr)
                setTimeout(() => {
                    uploading.set({stat:false, type:'file'})
                    selectedFiles.set([])
                }, 1000);
            }else{
                let body = {
                    type: 'create_file',
                    company_folder_id: match.params === 'list' ? '' : window.atob(urlParams.get('f_id')),
                    user_id: user_id,
                    company_id: company_id,
                    status: 1,
                    file: s3Files,
                }
                const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/documents/create`, body)
                if (res.msg === 'success') {
                    __InitDocuFile(0)
                    message.set('Successfully Upload')
                    successOpen.set(true)
                    reloadStorage.set(true)
                    let fltrData = [{user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]), file:'file'}]
                    await NotifCount(fltrData, __SESSION, `create`)
                }else if (res.msg === 'existing') {
                    addMenu.set(null)   
                    errOpen.set(true)
                    message.set(`${res.exist[0]} Already Exist` )
                }else{
                    alert('Please Reload the page or contact our hero happiness team.')
                }
                if ( (res.msg === 'success' || res.msg === 'existing') && res.exist.length > 0) {
                    const mergeArr = selectedFiles.data.map((md) => {
                        const td = res.exist.find((td) => td === md.file_name);
                        return {...md, error:td === undefined ? false : true, progress:td === undefined ? 100 : 0};
                    });
                    selectedFiles.set(mergeArr)
                }else{
                    selectedFiles.set([])
                    uploading.set({stat:false, type:'file'})
                }
            }
        }
    }
    
    const [totalSizeFile, setTotalSizeFile] = useState(0)
    const __initUploadFolder = async() => {
        let file = [...selectedFiles.data]
        let dataFile = []       
        for (let i = 0; i < file.length; i++) {
            let s3Upload = await LoaderFunction(file[i].root)
            let slice = atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0' ? file[i].path.replace(`/${file[i].name}`, '') : file[i].webkitRelativePath.replace(`/${file[i].name}`, '')
            let splitArry = slice.split('/')
            let sdata = []
            for (let j = 0; j < splitArry.length; j++) {
                if (file[i].file_name !== splitArry[j]) {
                    sdata.push({name:splitArry[j], parent_name:splitArry[j-1] === undefined ? '' : splitArry[j-1]})
                }
            }
            dataFile.push(
                {
                    folder_name:sdata, 
                    file_name:file[i].file_name, 
                    file_size:file[i].file_size,
                    file_type:file[i].file_type,
                    file_link:s3Upload,
                }
            )
        }

        if (atob(__SESSION.data.ainfo.aid).split('_', 2)[1] === '0') {
            let data = {
                user_favorite_status:null,
                user_favorite_id:null,
                access:null,
                company_folder_id:0,
                folder_id:0,
                series_num:"FLDR-00000001",
                status:3,
                folder_name:file[0].path.split('/')[0],
                folder_status:3,
                parent_id:0,
                data_status:"InUse",
                active:1,
                type:'create_folder', 
                file:dataFile,
                file_size:parseInt(urlParams.get('strg')),
                file_length:file.length
            }
            db.docs.add(data);
            docuFolder.set([data])
            uploading.set({stat:false, type:'folder'})
            reloadStorage.set(true)
            storage.set({...storage.data, 
                used:storage.data.used + parseInt(urlParams.get('strg')),
                total:21474836480,
                docs:parseInt(urlParams.get('strg')),
                docFile:file.length,
            }) 
            setTotalSizeFile(0)
            message.set('Successfully Upload')
            successOpen.set(true)
            selectedFiles.set([])
        }else{
            let data = {
                parent_id: match.params === 'list' ? 0 : window.atob(urlParams.get('f_id')),
                totalSize:totalSizeFile,
                file:dataFile,
                user_id: user_id,
                company_id: company_id,
                status: 1,
                filter: 'insert'
            }
           
            const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/documents/uploadFolder`, data)
            if (res === 'success') {
                uploading.set({stat:false, type:'folder'})
                reloadStorage.set(true)
                __InitDocuFolder(0)
                setTotalSizeFile(0)
                message.set('Successfully Upload')
                successOpen.set(true)
                selectedFiles.set([])
                let fltrData = [{user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]), file:'folder'}]
                await NotifCount(fltrData, __SESSION, `create`)
            }
        }
    }
    
    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (uploading.data.stat) {
                if (uploading.data.type === 'file') {
                    __initUploadFiles()
                }else{
                    __initUploadFolder()
                }
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line 
    }, [uploading.data.stat])
    
    const handleUploadFolder = async(e) => {
        let file = e.target.files
        let total = 0
        for (let i = 0; i < file.length; i++) {
            total = total + file[i].size
        }
        setTotalSizeFile(total)

        if (total > 25 * 1024 * 1024) {
            openMaxFile.set(true)
        }else{
            let body = {
                parent_id: match.params === 'list' ? 0 : window.atob(urlParams.get('f_id')),
                folder_name: file[0].webkitRelativePath.split('/')[0],
                user_id: user_id,
                company_id: company_id,
                filter: 'validate'
            }
            const res = await rqx.post(`<<tool>>/${env()}/general-maintenance/cloud/documents/uploadFolder`, body)
            if (res === 'existing') {
                addMenu.set(null)   
                errOpen.set(true)
                message.set('Folder Name Already Exist')
            }else{
                for (let i = 0; i < file.length; i++) {
                    selectedFiles.set((prev) => prev.concat({progress: 0, name: file[i].name, file_name: file[i].name, file_type: file[i].type, file_size: file[i].size, link: URL.createObjectURL(file[i]), webkitRelativePath:file[i].webkitRelativePath, root: Array(file[i]) }))
                }
                addMenu.set(null)
                uploading.set({stat:true, type:'folder'})
            }
        }
    }

    return (
        <Menu open={Boolean(addMenu.data)} anchorEl={addMenu.data} onClose={() => addMenu.set(null)} PaperProps={{ sx: { width: '200px', boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.08)', borderRadius: '4px', } }}>
            <MenuItem sx={{py: '8px'}} onClick={() => handlerAddAction('folder')}>
                <FolderIcon />
                <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px">Folder</Box>
            </MenuItem>
            <MenuItem sx={{p: '0px'}} onClick={() => handlerAddAction('file')}>
                <input type="file" id="file" multiple hidden width="100%" onChange={() => getRootProps().onClick} {...getInputProps()} />
                <label htmlFor="file" style={{display: 'flex', alignItems: 'center', width: '100%', height: '100%', padding: '8px 16px'}} className="c-pointer" >
                    <UploadFileIcon />
                    <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px">Upload File</Box>
                </label>
            </MenuItem>
            <MenuItem sx={{p: '0px'}} >
                {/* <input {...getInputProps()} directory="" webkitdirectory="" type="file" /> */}
                <input type="file" id="folder" directory="" webkitdirectory="" multiple hidden width="100%" onChange={(e) => handleUploadFolder(e)} />
                <label htmlFor="folder" style={{display: 'flex', alignItems: 'center', width: '100%', height: '100%', padding: '8px 16px'}} className="c-pointer" >
                    <UploadFolderIcon />
                    <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px">Upload Folder</Box>
                </label> 
            </MenuItem>
        </Menu>
    )
}