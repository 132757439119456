import { Avatar, Box, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { UseStyles } from '../../../global/DesktopDsg';
import { KeyboardArrowDown, Search } from '@mui/icons-material';
import { ArrowLeft, ShareIcon } from '../../../../../core/global/Icons';
import { UserList } from './UserList';
import { useContext, useEffect, useState } from 'react';
import { ToolContext } from '../../../../../core/context/ToolContext';
import { SearchLoader } from '../create/Loader';
import { FilterDept } from './FilterDept';

export const Details = ({ handleClose}) => {
    const dsg = UseStyles()
    const { cloud_state, tool_state  } = useContext(ToolContext)
    const { show, shareType, allUsrList, departmentList, selectedDept, ulistAccess, searchInput, saveLoader, successOpen, message, withAccess, menuDept, filterActive } = cloud_state
    const { __SESSION } = tool_state

    const [searchData, setSearchData] = useState(ulistAccess.data)
    const [searchLoader, setSearchLoader] = useState(false)
    const [defaultList, setDefaultList] = useState([])
    
    let dfltOwnerList = [{...allUsrList.data.superadmin[0], name:'Super Admin'}, ...allUsrList.data.users.filter(f => {return f.name === 'Admin'})]
    let regUsrList = [{...allUsrList.data.superadmin[0], name:'Super Admin'}, ...allUsrList.data.users]
    let ownerList = regUsrList.filter(f => {return show.data.data.created_by === f.id})
    const searchUser = async() => {
        let filter_fn = ulistAccess.data.map((v) => {return {...v, fullname: `${v.first_name} ${v.last_name}`}})
        let res = filter_fn.filter((item) => 
            item.first_name.toLowerCase().match(searchInput.data.toLowerCase()) || item.last_name.toLowerCase().match(searchInput.data.toLowerCase()) || item.fullname.toLowerCase().match(searchInput.data.toLowerCase())
        )
        await setSearchData(res)
        await setSearchLoader(false)
    }

    const handlerBack = () => {
        if (show.data.data.access === null || show.data.data.access === undefined) {
            shareType.set('create_access')
        }else{
            handleClose()
        }
        menuDept.set(null)
        searchInput.set('')
        selectedDept.set([])
        filterActive.set({id: 0, name: 'All'})
    }

    const handlerFilterDept = (e) => {
        menuDept.set(e.currentTarget)
    }
    
    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (searchInput.data !== '') {
                setSearchLoader(true)      
                const searchFcn = setTimeout(() => {
                    searchUser()
                }, 500);
                return () => clearTimeout(searchFcn)      
            }else{
                setSearchLoader(false)
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [searchInput.data])

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (!withAccess.data.loader && withAccess.data.val.length !== 0) {
                let fdata = withAccess.data.val[0].access
                if (fdata.length !== ulistAccess.data.length) {
                    ulistAccess.set(fdata.sort((a, b) => a.first_name.localeCompare(b.first_name)))
                }
                selectedDept.set(departmentList.data.filter(v=> fdata.find(x => {return x.company_department_id === v.id }) ))
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [withAccess.data.loader])
    
    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" mb={3}>
                    <IconButton sx={{p: '0px'}} onClick={() => handlerBack()}>
                        <ArrowLeft />
                    </IconButton>
                    <Box ml="15px" fontSize={20} fontWeight={600} lineHeight="22px">Sharing Setting</Box>
                </Box>
                {
                    (shareType.data === 'details' && (show.data.data.access === null || show.data.data.access === undefined)) &&
                        <IconButton sx={{p: '0px'}} onClick={() => handlerBack()}>
                            <ShareIcon fill="#333333" />
                        </IconButton>
                }
            </Box>
            <Box color="#333333" fontSize={20} fontWeight={400} lineHeight="22px" sx={{wordBreak: 'break-word'}} mb={4}>
                {
                    show.data.data.feature_type === 1 ?
                        `${show.data.data.registration_name} (${show.data.data.registered_name})`
                    :
                        show.data.data.feature_type === 2 ?
                            `${show.data.data.contract_name} (${show.data.data.client_name})`
                        :
                            show.data.data.feature_type === 3 ?
                                show.data.data.folder_name
                            :
                                show.data.data.file_name
                }
            </Box>
            <Box maxHeight={{xs:'100px', lg:'200px'}} py={1} position="relative" display="flex" flexDirection="column">
                <Box height="100%" className='overflowY'>
                    {dfltOwnerList.map((v, k) => (
                        <Box display="flex" justifyContent="space-between" p="8px 16px" key={k}>
                            <Box display="flex" alignItems="center">
                                <Avatar src={JSON.parse(v.profile).profile} alt="profile" sx={{width: 32, height: 32}} />
                                <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="24px">{v.first_name} {v.last_name}</Box>
                            </Box>
                            <Box display="flex" alignItems="center" gap="56px">
                                <Box fontSize={14} fontWeight={400} lineHeight="16px" color="#A2A3A9">{v.name}</Box>
                                <Box textAlign="right" minWidth="95px" fontSize={14} fontWeight={400} lineHeight="16px" color="#107038"> Default Owner</Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box position="absolute" bottom={0} minHeight="30px" width="100%" sx={{opacity:0.5}} bgcolor="#F1F1F1" borderRadius="4px 4px 0px 0px" />
            </Box>
            {ownerList.map((v, k) => (
                <Box display="flex" justifyContent="space-between" p="8px 16px" key={k}>
                    <Box display="flex" alignItems="center">
                        <Avatar src={JSON.parse(v.profile).profile} alt="profile" sx={{width: 32, height: 32}} />
                        <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="24px">{v.first_name} {v.last_name}</Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap="56px">
                        <Box fontSize={14} fontWeight={400} lineHeight="16px" color="#107038"> Owner</Box>
                    </Box>
                </Box>
            ))}
            <Box fontSize={14} fontWeight={400} lineHeight="22px" my={2}>Shared with:</Box>
            {
                ulistAccess.data.length > 0 &&
                    <>
                        <TextField onInput={(e) => searchInput.set(e.target.value)} fullWidth className={dsg.search} placeholder='Search...' InputProps={{ startAdornment: <InputAdornment position='start'> <Search />  </InputAdornment> }} />
                        <Box display="flex" allignItem="center" mt={3} p="8px 16px" gap="16px">
                            <Box display="flex" alignItems="center" gap="16px" className="c-pointer" onClick={(e) => handlerFilterDept(e)}>
                                <KeyboardArrowDown />
                                <Box fontSize={16} fontWeight={600} lineHeight="24px">{filterActive.data.name}</Box>
                            </Box>
                        </Box>
                    </>
            }
            <Box height="100%" className='overflowY'>
                {
                    searchLoader ?
                        <SearchLoader />
                    :
                        <UserList show={show} ulistAccess={ulistAccess} searchData={searchData} setSearchData={setSearchData} searchInput={searchInput} saveLoader={saveLoader} __SESSION={__SESSION} successOpen={successOpen} message={message} withAccess={withAccess} setDefaultList={setDefaultList} />
                }
            </Box>
            <Box minHeight={56} display="flex" justifyContent="flex-end" borderTop="1px solid #E6E6E6" pt={2} gap="19px">
                {(show.data.data.access === null || show.data.data.access === undefined) && (
                    <Button onClick={() => ulistAccess.data.length > 0 ? shareType.set('remove_access') : null}
                        sx={{
                            opacity: ulistAccess.data.length > 0 ? 1 : 0.2,
                            p: '12px 16px', border: '1px solid #E6E6E6', borderRadius: '6px', color: '#333333', bgcolor:"#F8F9FA", '&:hover': ulistAccess.data.length > 0 ?  { bgcolor: '#A4CAB4', color: '#107038' } : ''
                        }}
                    >
                        Remove Access
                    </Button>
                )}
            </Box>
            {ulistAccess.data.length > 0 && ( 
                <FilterDept menuDept={menuDept} selectedDept={selectedDept} filterActive={filterActive} ulistAccess={ulistAccess} searchInput={searchInput} withAccess={withAccess} defaultList={defaultList} setDefaultList={setDefaultList} />
            )}
        </>
    )
}