import { Box } from "@mui/material"
import { ThreeDots } from "../menu/ThreeDots"
import { FileList } from './Files'
import { FileInfo } from "../FileInfo"
import { ConfirmRemove } from "../../../global/DesktopDsg"
import { rqx } from "../../../../../core/request/API"
import { env } from "../../../../../core/Env"
import { useState } from "react"
import { Folder } from "./Folder"
import { ErrorAlert } from "../../../../../core/global/forms/CstmModal"
import { NotifCount } from "../../../../../core/request/Notif"

export const FavoritesList = ({cloud_state, __SESSION, __InitFavList, __LoadmoreFiles, __LoadmoreFolders, __InitFolderList}) => {
    const { show, showDl, active, message, successOpen, favList, rename, docuFolder, errOpen } = cloud_state
    const [favBtnLoader, setFavBtnLoader] = useState(false)
    const [fileLoaderAccess, setFileLoaderAccess] = useState(false)

    const hanlderModalBtn = async(option) => {
        if (!show.data.remove.loader) {
            if (option === 'cancel') {
                show.set({...show.data, remove:{...show.data.remove, val:false, loader:false}})
            }else{
                show.set({...show.data, remove:{...show.data.remove, loader:true}})
                let res;
                if (show.data.data.type === 1 || show.data.data.type === 2) {
                    res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/${show.data.data.type ===  1 ? 'registration' : 'contracts'}/update`, {
                        id:show.data.data.id, 
                        user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                        status:4, 
                        ref:'trash'
                    })
                }else{
                    let body = show.data.data.type === 3 ?
                        {
                            folder_id:show.data.data.id, 
                            user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                            status:4, 
                            ref:'trash',
                            filter: 'folder'
                        }
                    :
                        {
                            documents_id: show.data.data.id,
                            ref: 'trash',
                            user_id: parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                            filter: 'file',
                            status: 4
                        }
                        res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/documents/remove`, body)
                }
                if (res.message === 'success') {
                    message.set('Successfully removed')
                    successOpen.set(true)
                    if (active.data.type === 'folder') {
                        __InitFolderList(0)
                    }else{
                        await NotifCount([{file_id:show.data.data.id, file:show.data.data.type === 1 ? 'reg' : show.data.data.type === 2 ? 'con' : 'file'}], __SESSION, 'delete')
                        __InitFavList(0)
                    }
                    show.set({...show.data, remove:{...show.data.remove, val:false, loader:false},  view : false, fullview : false})
                    active.set({...active.data, val : '', openMenu : null})
                }
            }
        }
    }

    const handlerMenuBtn = () => {
        show.set({...show.data, remove:{...show.data.remove, val:true}})
    }

    const handlerThreeDots = async(e, k, v, type) => {
        e.stopPropagation()
        active.set({...active.data, val: k, openMenu: e.currentTarget, type : type})
       
        if (v.type === 4 && v.access === null) {
            setFileLoaderAccess(true)
            let res = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/favorites/read`, {
                company_id:__SESSION.data.uaccess[0].company_id, 
                user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                filter:'file_access',
                file_id:v.id
            })
            v = {...v, access:JSON.parse(res[0].access)}
            setFileLoaderAccess(false)
        }
        show.set({...show.data, data : v, view : false, fullview : false})
    } 

    const addFav = async(e, v) => {
        e.stopPropagation()
        if (!favBtnLoader) {
            setFavBtnLoader(true)
            show.set({...show.data, data:'', view : false, fullview : false})
            if (v.feature_type === 3) {
                let newArr = docuFolder.data.map((d,k) => d.folder_id === v.folder_id ? {...d, user_favorite_status:v.user_favorite_status === 2 ? 1 : 2} : {...d} )
                docuFolder.set(newArr.filter((v) => v.user_favorite_status !== 1))
            }else{
                let newArr = favList.data.map((d,k) => d.id === v.id ? {...d, user_favorite_status:v.user_favorite_status === 2 ? 1 : 2} : {...d} )
                favList.set(newArr.filter((v) => v.user_favorite_status !== 1))
            }
            message.set('Sucessfully removed from favorites')
            successOpen.set(true)
            let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/favorites/create`, {
                registration_id: v.type === 1 ? v.id : '',
                contracts_id: v.type === 2 ? v.id : '',
                status:v.user_favorite_status === 2 ? 1 : 2, 
                user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                documents_id: v.type === 4 ? v.id : '',
                folder_id: v.type === 3 ? v.folder_id : ''
            })
            if (res.message === 'success') {
                setFavBtnLoader(false)
            }
        }
    }

    const handlerViewFile = (val) => {
        active.set({...active.data, val : '', openMenu : null})
        show.set({...show.data, data : val, view : true, fullview : false})
    }

    const showThreeDots = (index, action, type) => {
        if (action === 'enter') {
            active.set({...active.data, val : index, type: type})
        }else{
            if (active.data.openMenu === null) {
                active.set({...active.data, val : ''})
            }
        }
    }
    
    return (
        <Box height="100%" width="100%" pb="40px" display="flex" flexDirection="column" className="overflowY">
            <Box p="40px" height="40%" className="overflowY">
                <Folder cloud_state={cloud_state} addFav={addFav} handlerThreeDots={handlerThreeDots} __LoadmoreFolders={__LoadmoreFolders} showThreeDots={showThreeDots} rename={rename} __SESSION={__SESSION} errOpen={errOpen}/>
            </Box>
            <Box p="40px" height="60%" className="overflowY">
                <FileList cloud_state={cloud_state} addFav={addFav} __LoadmoreFiles={__LoadmoreFiles} handlerViewFile={handlerViewFile} handlerThreeDots={handlerThreeDots} showThreeDots={showThreeDots} />
            </Box>  
            {
                show.data.view &&
                    <FileInfo show={show} active={active} addFav={addFav} />
            }
            {Boolean(active.data.openMenu) && (
                <ThreeDots show={show} active={active} handlerThreeDots={handlerThreeDots} showDl={showDl} successOpen={successOpen} message={message} rename={rename}
                    viewInfo={() => handlerViewFile(show.data.data)}
                    remove={() => handlerMenuBtn()} 
                    fileLoaderAccess={fileLoaderAccess}
                />
            )}
            <ConfirmRemove show={show} HandlerDelete={hanlderModalBtn} />
            <ErrorAlert errOpen={errOpen.data} setErrOpen={errOpen.set} text={message.data} /> {/*  SNACKBAR MESSAGE ERROR */}
        </Box>
    )
}
