import { useState } from "react"

export const UbrState = () => {
    const[welcomeModal, setWelcomeModal] = useState(true)
    const [ubrTour, setUbrTour] = useState({
        reg:{select:true, upload:true, info:true},
        con:{select:true, upload:true, info:true},
        pol:{upload:true, info:true},
    })

    const [ubrModal, setUbrModal] = useState(false)

    return {
        welcomeModal: {data: welcomeModal, set: setWelcomeModal},
        ubrTour: {data: ubrTour, set: setUbrTour},
        ubrModal: {data: ubrModal, set: setUbrModal},
    }
}