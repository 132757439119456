import { Box, MenuItem, Modal, Slide } from "@mui/material"
import { Download, DownloadPDF } from "../../../../../../core/transmit/S3"
import Pdf from '../../../../../../assets/images/icons/pdficon.png'
import IMG from '../../../../../../assets/images/icons/imageicon.png'

export const DownloadCons = ({conActive, show, csuccessOpen, message}) => {
    const btnList = [
        {name: 'PDF', icon: Pdf, type: 'pdf'},
        {name: 'Image', icon: IMG, type: 'img'},
    ]

    const handlerCloseMenu = () => {
        setTimeout(() => {
            show.set({...show.data, download:false})
        }, 200);
    }
    
    const handlerMenuBtn = async(e, type) => {
        let parse = JSON.parse(show.data.data.file)
        
        for (let i = 0; i < parse.length; i++) {
            let res;
            if (type === 'pdf') {
                if (parse[i].file_type.includes('image')) {
                    res = await DownloadPDF(parse[i].file_link, parse[i].file_name)       
                }else{
                    res = await Download(e, parse[i].file_link, parse[i].file_name)
                }
            }else{
                res = await Download(e, parse[i].file_link, parse[i].file_name)
            }
            if (res === 'success' && i === parse.length - 1 ) {
                conActive.set({...conActive.data, val : '', openMenu : null})
                message.set('Successfully download')
                csuccessOpen.set(true)
                handlerCloseMenu()
            }
        }
    }
    
    return (
        <Modal open={show.data.download} onClose={() => handlerCloseMenu()} BackdropProps={{ sx: {bgcolor: '#0000001F'} }} sx={{zIndex: 1303}}>
            <Slide in={show.data.download} direction="up">
                <Box height="100%" width="100%" display="flex" justifyContent="flex-end" flexDirection="column">
                    <Box height="100%" onClick={() => handlerCloseMenu()} />
                    <Box minHeight={"112px"} bgcolor="#FFFFFF" p={2}
                        borderRadius="16px 16px 0px 0px" boxShadow="0px 0px 24px rgba(0, 0, 0, 0.08)"
                    >
                        {
                            (show.data.view ? btnList.filter(v => { return v.type !== 'view'}) : btnList).map((v,k) => (
                                <MenuItem sx={{px: '0px', py: '8px', minHeight: '36px'}} key={k} onClick={(e) => handlerMenuBtn(e, v.type)}>
                                    <img src={v.icon} alt="" />
                                    <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px">{v.name}</Box>
                                </MenuItem>
                            ))
                        }
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}