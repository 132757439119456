import { Box, Grid } from "@mui/material"
import { ViewIcon } from "../../../../../core/global/GMicons"
import moment from "moment"
import { Waypoint } from "react-waypoint"
import { SnakeLoader } from "../../../../../core/loader/SnakeLoader"

export const TBody = ({ concernData, handleView, infntScroll, loadMoreData }) => {

    return (
        <Box height="100%" width="100%" gap="12px" className="overflowY" display="flex" flexDirection="column" p="0px 32px 32px 32px">
            {
                concernData.data.map((v,k) => (
                <Box key={k} width="100%" fontWeight={400} display="flex" alignItems="center" p={2} border="1px solid #EBEBEB" borderRadius="10px" bgcolor="#FFFFFF">
                    <Grid container direction="row" spacing={2} pl={2} display="flex" alignItems="center">
                        <Grid item xs={3} className="classTextEllipsis">
                            <Box>{v.speakout_category}</Box>
                        </Grid>
                        <Grid item xs={3} className="classTextEllipsis">
                            <Box>{v.speakout_team}</Box>
                        </Grid>
                        <Grid item xs={2} className="classTextEllipsis">
                            <Box>{moment(v.created_at).format("MMM DD,YYYY")}</Box>
                        </Grid>
                        {/* <Grid item xs={2} textAlign="center" className="classTextEllipsis">
                            <Box width="100%" display="flex" textAlign="center" justifyContent="center">
                                <Box width="50%" bgcolor="#00be811f" color="#00be81" borderRadius="16px">{v.status === 1 ? 'Received' : 'Done'}</Box>
                            </Box>
                        </Grid> */}
                        <Grid item xs={2} display="flex" justifyContent="center">
                            <Box display="flex" alignItems="center" textAlign="center" justifyContent="center" bgcolor={v.status === 1 ? '#00be811f' : '#E4E5E6'} color={v.status === 1 ? '#00be81' : '#7B7F82'} borderRadius="16px" width="100px">
                                <Box>{v.status === 1 ? 'Received' : 'Done'}</Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} textAlign="center" display="flex" alignItems="center" justifyContent="center">
                            <Box display="flex" flexDirection="row" alignContent="center" gap={1}>
                                <Box className="c-pointer" onClick={() => handleView ('view', v.id)}><ViewIcon stroke='#292d32' /></Box>
                                {/* <Box className="c-pointer" ><EditProfile stroke='#292d32' width="24" height="24"/></Box> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                ))
            }
            {
                infntScroll.data && (
                    <Box display="flex" justifyContent="center">
                        <Waypoint onEnter={loadMoreData} >
                            <Box component="strong" mt={5} display="flex" justifyContent="center">
                                <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                            </Box>
                        </Waypoint>
                    </Box>
                )
            }
        </Box>
    )
}