import { useEffect, useContext, useState } from "react"
import { Avatar, Box, Button, Divider, Grid, MenuItem, Select, Tooltip, Typography } from "@mui/material"
import { ToolContext } from "../../../../../core/context/ToolContext";
import { rqx } from "../../../../../core/request/API";
import { domain, env } from "../../../../../core/Env";
import { ToolLoader } from "../../../loader/MidLoader";
import { makeStyles } from "@mui/styles";
import { motion } from 'framer-motion';
import Warning from '../../../../../assets/images/icons/warning.png'
import moment from 'moment';

let bizID = process.env.REACT_APP_TOOL_ID;
let bizName = process.env.REACT_APP_TOOL_NAME;
let bizPhoto = process.env.REACT_APP_TOOL_PHOTO;

export const Tools = () => {
    const { tool_state, home_state } = useContext(ToolContext);
    const { __SESSION, preload, globalLoader } = tool_state
    const {dateToday} = home_state
    const own_id = tool_state.__SESSION.data.uaccess[0].role_name === 'Super Admin' ? parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]) :tool_state.__SESSION.data.uaccess[0].owner_id
    const toolList = tool_state.toolOwned.data
    const [datas, setDatas] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [selected, setSelected] = useState(0)
    const [tids] = useState([])

    const _header = [ {title: 'Tool Name', size: 2}, {title: 'Subscription', size: 2}, {title: 'Available User Seats', size: 2}, {title: 'Total Verified Users', size: 2}, {title: 'Total Unverified Users', size: 2}, {title: `Today's Active Users`, size: 1}, {title: `Today's Inactive Users`, size: 1}, ]
   
    const changeHandler = async(value) => {
        setSelected(value)
    }

    const __redirect = async(e, tid, logo) => {
        e.stopPropagation()
        if (parseInt(bizID) !== tid ) {
            preload.set({loader:true, logo:JSON.parse(logo)})
            let res = await rqx.get(`<<global>>/${env()}/tools/toolcb`, {tid: tid, env:env() !== 'prod' ? 'sandbox' : 'production'});
            if (res.msg === 'success') {
                if (res.tu.length > 0) {
                    window.location.href = `${res.tu[0].value}?ainfo=${encodeURIComponent(JSON.stringify(__SESSION.data.ainfo))}`
                }
                setTimeout(() => {
                    preload.set({...preload.data, loader:false})
                }, 2000);
                return
            }
        }
    }

    const __init = async(ids) => {
        // setIsLoading(true)
        const tlist = [{tid:JSON.parse(bizID), name:bizName, logo:bizPhoto }, ...toolList]
        if (ids.length === 0 ) {
            tlist.filter((v) => { return tids.push(v.tid) })
        }
        const toolsort = tlist.filter(g => ids.find(n => (n === g.tid) ))
        const mdata = toolsort.map(v => { return {...v, avlblseat:0, verified_user:0, unverified_user:0, active_user:0, inactiveuser:0}})

        let actvusr = []
        let vrfdusr = []
        for (let i = 0; i < ids.length; i++) {
            let getvrfdusr = await rqx.get(`<<global>>/${env()}/roleaccess/verusrpertool`, {
                company_id:tool_state.__SESSION.data.uaccess[0].company_id === null ? 0 : tool_state.__SESSION.data.uaccess[0].company_id , 
                tid:ids[i],
                platform:'business', 
            }) 
            let getactvusr = await rqx.get(`<<global>>/${env()}/trail/activeuser`, {
                company_id:tool_state.__SESSION.data.uaccess[0].company_id === null ? 0 : tool_state.__SESSION.data.uaccess[0].company_id , 
                tid:ids[i],
                platform:'business', 
                date:moment(new Date()).format('YYYY-MM-DD'),
                owner_id:own_id,
            }) 
            actvusr.push({tid:ids[i], ...getactvusr})
            vrfdusr.push({tid:ids[i], ...getvrfdusr})
        }
        const mergeArr = mdata.map((md) => {
            const au = actvusr.find((au) => au.tid === md.tid);
            const vu = vrfdusr.find((vu) => vu.tid === md.tid);
            return {...md, ...au, ...vu};
        });
        setDatas(
            mergeArr.map(v => { return {...v, avlblseat:v.tid === JSON.parse(bizID) ? 0 : JSON.parse(v.matrix).useats.value - (v.verified_user + v.unverified_user), inactiveuser:v.verified_user - v.active_user} } )
        )
        setIsLoading(false)
    }
    
    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (!globalLoader.data && isLoading) {
                __init(tids)
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [globalLoader.data])
    
    return (
        <Box bgcolor="#ffffff" py={2} mt={4} borderRadius="6px" height="310px" display="flex" flexDirection="column"  pl={2}>
            {
                isLoading || globalLoader.data ? 
                    <ToolLoader />
                :
                    <>
                        <Box minHeight={60}> 
                            <Box pb="12px" fontSize={14} fontWeight={600} color="#333333">Tools Summary</Box>
                            <Select sx={{width: '196px'}} size="small" value={selected} onChange={(e) => changeHandler(e.target.value)}>
                                <MenuItem selected value={0}>All Categories</MenuItem>
                            </Select>
                        </Box>
                        <Box minHeight={10} mt={3} mb={2}> 
                            <Grid container  px={2}>
                                {_header.map((v, k) => (
                                    <Tooltip key={k} title={v.title} placement={k === 0 ? 'top-start' : 'top'}>
                                        <Grid item sm={v.size} lg={v.size} key={k} textAlign={k !== 0 && 'center'} >
                                            <Typography fontSize={12} fontWeight={500} noWrap>{v.title}</Typography>
                                        </Grid>
                                    </Tooltip>
                                ))}
                            </Grid>
                        </Box>
                        <Box height="100%" sx={{overflowY: 'auto'}}> 
                            {datas.map((v, k, d) => (
                                <Box onClick={(e)=>__redirect(e, v.tid, v.logo)} component={motion.div} whileHover={{ backgroundColor:'#F0F6F3'}} className="c-pointer" key={k} p="16px 12px" border="1px solid #ECECEC" borderRadius={1} mb={k + 1 === d.length ? 0 : '12px'} mr={1}>
                                    <Grid container>
                                        <Grid item sm={2} lg={2}>
                                            <Box display="flex" alignItems="center">
                                                <Avatar src={ v.tid === parseInt(bizID) ? v.logo : JSON.parse(v.logo)} alt="logo" variant="rounded" sx={{width: 20, height: 20}} />
                                                <Typography ml={1} fontSize={14} noWrap>{v.name}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={2} lg={2} display="flex" justifyContent="center">
                                            {
                                                v.end_date <= dateToday.data ?
                                                    <Typography p="4px 8px" borderRadius="100px" bgcolor="#FEE9E9" color="#F84949" fontSize={10} noWrap> Expired </Typography>
                                                :
                                                    <Typography p="4px 8px" borderRadius="100px" bgcolor="#07A42A1A" color="#07A42A" fontSize={10} noWrap> Ongoing </Typography>

                                            }
                                        </Grid>
                                        <Grid item sm={2} lg={2} display="flex" justifyContent="center">
                                            { v.tid === parseInt(bizID) ? 
                                                    <Typography fontSize={11} color="#333333" mr="4px">Unlimited</Typography> 
                                                : 
                                                    <>
                                                        <Typography fontSize={11} color={v.avlblseat === 0 ? "#F84949" : "#333333"} mr="4px">{v.avlblseat}</Typography>
                                                        {v.avlblseat === 0 && (
                                                            <Avatar src={Warning} alt={Warning} sx={{width: 12, height: 12}} />
                                                        )}
                                                    </>
                                            }
                                        </Grid>
                                        <Grid item sm={2} lg={2} display="flex" justifyContent="center">
                                            <Typography fontSize={14} noWrap>{v.verified_user}</Typography>
                                        </Grid>
                                        <Grid item sm={2} lg={2} display="flex" justifyContent="center">
                                            <Typography fontSize={14} noWrap>{v.unverified_user}</Typography>
                                        </Grid>
                                        <Grid item sm={1} lg={1} display="flex" justifyContent="center">
                                            <Typography fontSize={14} noWrap>{v.active_user}</Typography>
                                        </Grid>
                                        <Grid item sm={1} lg={1} display="flex" justifyContent="center">
                                            <Typography fontSize={14} noWrap>{v.inactiveuser}</Typography>
                                        </Grid>
                                    </Grid>
                                    { __SESSION.data.uaccess[0].role_name === 'Super Admin' && (
                                        <RenewDsg data={v} dateToday={dateToday} tool_state={tool_state} />
                                    )}
                                </Box>
                            ))}
                        </Box>
                    </>
            }
        </Box>
    )
}

const RenewDsg = ({data, dateToday, tool_state}) => {
    const useStyle = makeStyles(() => ({
        btnRenew: {
            backgroundColor: '#07A42A',
            padding: '8px 24px',
            fontSize: 12
        }
    }))
    const styles = useStyle()
    const _clickRenew = () => {
        const domainLocation = `${domain('mp').url}/sso/account/auth?ainfo=${encodeURIComponent(JSON.stringify(tool_state.__SESSION.data.ainfo))}&market_renewal=1&tid=${data.tid}&platform=business`
        try {
            window.open(domainLocation, '_blank').focus()
        } catch (error) {
            window.location.href = domainLocation;
        }
        return
    }
    return(
        (data.end_date !== undefined && data.end_date <= dateToday.data) && 
            <>
                <Divider sx={{mt: 2, mb: '12px'}} />
                <Button variant="contained" className={styles.btnRenew} onClick={() => _clickRenew()}>
                    Renew Subscription
                </Button>
            </>
    )
}