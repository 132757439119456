import { Box, Button, Grid, InputBase } from "@mui/material"
import { SearchIcons } from "../../../../../core/global/Icons"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { AddRoundIcon } from "../../../../../core/global/GMicons"

export const Top = ({ concernData, isLoading, conSearch, setConSearch }) => {
    const nav = useHistory()
    const handleConcern = () => {
        nav.push('/speakout/concern/create')
    }

    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/;
    const handleSearch = (e) => {
        if (!isLoading.data) {
            isLoading.set(true)
        }
        setConSearch(e.target.value)
    }

    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column" p={4}>
            <Box height="100%" width="100%" display="flex" flexDirection="row" justifyContent="space-between">
                <InputBase
                    onKeyDown={(event) => {
                        if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                        event.preventDefault();
                        }
                    }}
                    onPaste={(e)=>{
                        e.preventDefault()
                        return false;
                    }} 
                    onCopy={(e)=>{
                        e.preventDefault()
                        return false;
                    }}
                    value={conSearch}
                    onChange={(e) => handleSearch(e)}
                    placeholder="Search"
                    endAdornment={ <Box pr={1} pt={.5}><SearchIcons /></Box> }
                    sx={{backgroundColor: "#FFFFFFF", border: "2px solid #D9EBE8", borderRadius: "10px", padding: '5px 5px 5px 20px', minWidth: '291', height:'49px'}}
                />
                <Box>
                </Box>
                {/* <Buttons height="49px" borderRadius="15px" variant="contained" startIcon={<AddRoundIcon />} text="Category" onClick={handleCategory}/> */}
                <Button height="49px" variant="contained" sx={{borderRadius: '15px'}} onClick={handleConcern}>
                    <AddRoundIcon />
                    <Box>Concern</Box>
                </Button>
            </Box>
            <Box height="100%" width="100%">
                {
                    concernData.data.length > 0 ?
                    <Box width="100%" my={3} px={2} fontWeight={500}>
                        <Grid container direction="row" spacing={2} pl={2}>
                            <Grid item xs={3}>
                                <Box>Category</Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box>Receiver</Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box>Date</Box>
                            </Grid>
                            <Grid item xs={2} textAlign="center">
                                <Box>Status</Box>
                            </Grid>
                            <Grid item xs={2} textAlign="center">
                                <Box>Action</Box>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <Box display="flex" justifyContent="center"></Box>
                }
            </Box>
        </Box>
    )
}