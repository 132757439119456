import { Autocomplete, Box, Button, Checkbox, Chip, IconButton, InputAdornment, TextField } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, Close, KeyboardArrowDown, Search } from '@mui/icons-material';
import { SharedWith } from './SharedWith';
import { UserList } from './UserList';
import { UseStyles } from '../../../global/DesktopDsg';
import { useContext, useState, useEffect } from 'react';
import { ToolContext } from '../../../../../core/context/ToolContext';
import { SearchLoader } from './Loader';
import { FilterDept } from '../menu/FilterDept';

export const CreateAccess = ({ usrData, setUsrData, match }) => {   
    const dsg = UseStyles()
    const { cloud_state } = useContext(ToolContext)
    const { openShare, show, shareType, selectedDept, confirmShare, departmentList, allUsrList, userList, filterActive, searchInput, slctAllTxt, withAccess, ulistAccess, menuDept } = cloud_state
    const [slctAll, setSlctAll] = useState(false)
    const [searchLoader, setSearchLoader] = useState(false)
    const [searchData, setSearchData] = useState(userList.data)
    const [count, setCount] = useState(0)
    
    const icon = <CheckBoxOutlineBlank fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;
    const checkedIcon = <CheckBox fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;

    let dept_id;
    let check;
    const handlerSelectDept = (e, id, props) => {
        check = !props['aria-selected']
        dept_id = id
    }

    const handlerOnChangeDept = async(e, val) => {
        searchInput.set('')
        if (val.length === 0) {
            setCount(0)
            slctAllTxt.set(false)
            setUsrData(allUsrList.data)
        }
        
        let validateAll = val.filter(v => { return v.name === 'ALL' } )
        setSlctAll(validateAll.length > 0  ? true : false)
        
        let value;
        if (validateAll.length > 0) {
            value = departmentList.data
        }else{
            value = val
        }
        
        let data = []
        let userWithAccess = []
        if (withAccess.data.val.length !== 0) {
            userWithAccess = withAccess.data.val[0].access
        }
        
        value.filter((v) => {
            const au = allUsrList.data.users.filter((au) => au.company_department_id === v.id && au.name !== 'Admin');
            if (withAccess.data.val.length !== 0) {
                let filtered = au.filter(f => !userWithAccess.find(x => f.id === x.id))
                data = [...data, ...filtered]
            }else{
                data = [...data, ...au]
            }
            return false 
        });
        
        let fdata = data.map((v) => {
            const d = (usrData.length > 0 ? usrData.users : userList.data).filter((d) => d.id === v.id );
            return {...v, stat: d.length === 0 ? v.stat : d[0].stat }
        });
        
        let slctAllValidate = fdata.filter(v => { return !v.stat.view})
        if (slctAllValidate.length > 0) { slctAllTxt.set(false) }
        let fltrdata = fdata.filter(v => { return v.id !== show.data.data.created_by } )
        userList.set(fltrdata)
        setUsrData({...usrData, users:fltrdata})
        if (dept_id === 0) {
            if (check) {
                selectedDept.set(departmentList.data)
            }else{
                selectedDept.set([])
            }
        }else{
            selectedDept.set(value)
        }
        filterActive.set({id: 0, name: 'All'})
    }

    const handlerShareAccess = () => {
        if (count > 0) {
            confirmShare.set({...confirmShare.data, val : true, type : 'share'})
        }
    }

    const Ulist = async() => {
        const filter_fn = userList.data.map((v) => { return {...v, fullname:`${v.first_name} ${v.last_name}`  } }  )
        const res = filter_fn.filter((item) =>
            item.first_name.toLowerCase().match(searchInput.data.toLowerCase()) || item.last_name.toLowerCase().match(searchInput.data.toLowerCase()) || item.fullname.toLowerCase().match(searchInput.data.toLowerCase())
        );
        slctAllTxt.set(res.length === 0 ? false : res.filter(v => { return !v.stat.view }  ).length > 0 ? false : true)
        await setSearchData(res)
        await setSearchLoader(false)
    }

    const handleSelectAll = () => {
        slctAllTxt.set(!slctAllTxt.data)

        let res;
        if (filterActive.data.name === 'All') {
            if (searchInput.data !== '') {
                res = searchData.map(v => { return {...v, stat:{...v.stat, view:!slctAllTxt.data}} }  )
                setSearchData(res)
            }else{
                res = userList.data.map(v => { return {...v, stat:{...v.stat, view:!slctAllTxt.data}} }  )
            }
        }else{
            if (searchInput.data !== '') {
                res = searchData.map((v) => v.company_department_id === filterActive.data.id ? {...v, stat:{...v.stat, view:!slctAllTxt.data}} : {...v} )
                setSearchData(res)
            }else{
                res = userList.data.map((v) => v.company_department_id === filterActive.data.id ? {...v, stat:{...v.stat, view:!slctAllTxt.data}} : {...v} )
            }
        }
        const mergeArr = res
        
        let data = []
        if (filterActive.data.name === 'All') {
            const au = mergeArr.filter((au) => au.name !== 'Admin' )
            data = [...data, ...au]
        }else{
            const au = mergeArr.filter((au) => au.company_department_id === filterActive.data.id && au.name !== 'Admin' )
            data = [...data, ...au]
        }
        userList.set(data)
        const mergeArr2 = usrData.users.map((md) => {
            const td = data.find((td) => td.id === md.id);
            return {...md, ...td};
        });
        setUsrData({...usrData, users:mergeArr2})
    }
    
    const handlerFilterDept = (e) => {
        menuDept.set(e.currentTarget)
    }

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (searchInput.data !== '') {
                setSearchLoader(true)
                const searchFcn = setTimeout(() => {
                    Ulist()
                }, 500)
                return () => clearTimeout(searchFcn)
            }else{
                setSearchLoader(true)
                if (usrData.users.length > 0) {
                    const mergeArr = usrData.users.map((md) => {
                        const td = userList.data.find((td) => td.id === md.id);
                        return {...md, ...td};
                    });
                    setUsrData({...usrData, users:mergeArr})
                    let au;
                    if (filterActive.data.name === 'All') {
                        au = mergeArr.filter((au) => { return au.name !== 'Admin' } )
                    }else{
                        au = mergeArr.filter((au) => au.company_department_id === filterActive.data.id && au.name !== 'Admin' )
                    }
                    slctAllTxt.set(au.filter(v => { return !v.stat.view }  ).length > 0 ? false : true)
                    userList.set(au)
                }
                setSearchLoader(false)
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [searchInput.data])

    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            let res = usrData.length === 0 ? [] : usrData.users.filter(v => { return v.stat.view })
            if (res.length === 0) {
                res = userList.data.filter(v => { return v.stat.view })
            }
            setCount(res.length)
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [userList.data, usrData])
    
    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" mb={3}>
                    <IconButton sx={{p: '0px'}} onClick={() => openShare.set(false)}>
                        <Close sx={{color: '#A2A3A9', fontSize: 16}} />
                    </IconButton>
                    <Box ml="15px" fontSize={20} fontWeight={600} lineHeight="22px">Sharing Setting</Box>
                </Box>
            </Box>
            <Box color="#333333" fontSize={20} fontWeight={400} lineHeight="22px" sx={{wordBreak: 'break-word'}} mb={4}>
                {
                    show.data.data.feature_type === 1 ?
                        `${show.data.data.registration_name} (${show.data.data.registered_name})`
                    :
                        show.data.data.feature_type === 2 ?
                            `${show.data.data.contract_name} (${show.data.data.client_name})`
                        :
                            show.data.data.feature_type === 3 ?
                                show.data.data.folder_name
                            :
                                show.data.data.file_name
                }
            </Box>
            <Box color="#0A0A0A" fontSize={16} fontWeight={400} lineHeight="20px" mb={1}>Share access with</Box>
            <Autocomplete multiple options={departmentList.data} getOptionLabel={(data) => data.name} disableCloseOnSelect 
                onChange={(e, val) => handlerOnChangeDept(e, val)}
                value={selectedDept.data}
                renderOption={(props, options, { selected }) => (
                    <li {...props} style={{padding: '0px !important', margin:'0px !important'}}>
                        <Box display="flex" width="112%" onClick={(e) => handlerSelectDept(e, options.id, {...props})} style={{padding: '20px', margin:-15}}>
                            <Checkbox checked={selected} icon={icon} checkedIcon={checkedIcon} sx={{p: '0px'}} />
                            <Box fontSize={16} fontWeight={400} lineHeight="24px" ml="8px">{options.name === 'ALL' ? 'Select All' : options.name}</Box>
                        </Box>
                    </li>
                ) }
                renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option, index) => (
                        <Chip variant='outlined' {...getTagProps({ index })} label={option.name === 'ALL' ? 'Select All' : option.name} sx={{borderRadius: '8px', display:slctAll ? option.name === 'ALL' ? '' : 'none':''}} />
                    ));
                }}
                renderInput={(params) => (
                    <TextField {...params} placeholder='Select Department' />
                )}
            />
            {
                selectedDept.data.length === 0 ?
                    <SharedWith shareType={shareType} withAccess={withAccess} ulistAccess={ulistAccess} allUsrList={allUsrList} />
                :
                    <>
                        <Box fontSize={14} fontWeight={400} lineHeight="22px" mb="12px" mt={3}>Give access directly to specific people within your company.</Box>
                        <TextField onInput={(e) => searchInput.set(e.target.value)} value={searchInput.data} fullWidth className={dsg.search} placeholder='Search...' InputProps={{ startAdornment: <InputAdornment position='start'> <Search />  </InputAdornment> }} />
                        <Box display="flex" allignItem="center" mt={3} p="8px 16px" gap="16px">
                            <Box display="flex" alignItems="center" gap="16px" className="c-pointer" onClick={(e) => handlerFilterDept(e)}>
                                <KeyboardArrowDown />
                                <Box fontSize={16} fontWeight={600} lineHeight="24px">{filterActive.data.name}</Box>
                            </Box>
                            <Box display="flex" alignItems='center' gap="16px">
                                <Box className="c-pointer" onClick={handleSelectAll} fontSize={16} fontWeight={600} lineHeight="24px" color="#A2A3A9"> {slctAllTxt.data ? 'Unselect all' : 'Select all'}</Box>
                                {count > 0 && (
                                    <Box fontSize={16} fontWeight={600} lineHeight="24px" color="#107038">{count} selected</Box>
                                )}
                            </Box>
                        </Box>
                    </>
            }
            <Box height="100%" width="100%" className="overflowY">
                {searchLoader ? 
                    <SearchLoader/>
                :
                    (
                        selectedDept.data.length !== 0 &&
                            <UserList userList={userList} searchData={searchData} setSearchData={setSearchData} searchInput={searchInput} slctAllTxt={slctAllTxt} usrData={usrData} setUsrData={setUsrData} />
                    )
                }
            </Box>
            <Box pt={2} display="flex" justifyContent="center" minHeight={64} >
                <Button  onClick={() => handlerShareAccess()}
                    sx={{p: '16px 30px', bgcolor: '#107038', borderRadius: '6px', color: '#FFFFFF', '&:hover': { bgcolor: '#16522F' },
                        opacity: count ? 1 : 0.2,
                    }}
                >
                    Share Access
                </Button>
            </Box>
            <FilterDept selectedDept={selectedDept} menuDept={menuDept} filterActive={filterActive} allUsrList={allUsrList} userList={userList} usrData={usrData} setUsrData={setUsrData} slctAllTxt={slctAllTxt} searchInput={searchInput}/>
        </>
    )
}