import { Box, IconButton, Modal, Slide } from '@mui/material';
import { ArrowLeft } from '../../../../core/global/Icons';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { FileExtension } from '../../global/FileExtension';

export const FullView = ({show, conData}) => {
    return (
        <>
            <Modal open={show.data.fullview}   
                sx={{borderColor:'none !important'}}
                onClose={()=> show.set({...show.data, view: true, fullview:false})}>
                <Slide in={show.data.fullview} direction='up'>
                    <Box height="100%" width="100%" display="flex" flexDirection="column"> 
                        <Box minHeight="56px" display="flex" justifyContent="space-between" bgcolor="#313131" px={3} py="20px">
                            <Box display="flex" alignItems="center">
                                <IconButton onClick={()=> show.set({...show.data, view: true, fullview:false})}>
                                    <ArrowLeft stroke='#FFFFFF' />
                                </IconButton>
                                <Box ml={2} color="#FFFFFF" fontSize={14} lineHeight="16px" fontWeight={400}>
                                    {show.data.data.type === 4 ? show.data.data.name : `${show.data.data.type_name} (${show.data.data.name})` }
                                </Box>
                            </Box>
                        </Box>
                        <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                            <Box>
                                <Box minHeight="730px" maxHeight="730px" width="100%" className="overflowY">
                                    <Box height="100%" width="100%">
                                        { 
                                            conData.file_type.includes('image')? 
                                                <Box component="img" src={conData.file_link} alt={conData.file_link} height="100%" width="531px" borderRadius="12px" />
                                            : conData.file_type.includes('pdf') ?
                                                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                    <Box sx={{height: '730px', width:'730px', borderRadius:'12px'}}>
                                                        <Box component={Viewer} fileUrl={conData.file_link} defaultScale={SpecialZoomLevel.PageFit} />
                                                    </Box>
                                                </Worker>
                                            :
                                                <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                                    <FileExtension link={conData.file_link} />
                                                </Box>
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Slide>
            </Modal>
        </>
    )
}
