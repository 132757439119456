import { Box, Menu, MenuItem, Checkbox } from '@mui/material';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank, Check as CheckIcon } from '@mui/icons-material';

export const AccessList = ({v, handleCheck, anchorEl, setAnchorEl}) => {
    const icon = <CheckBoxOutlineBlank fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;
    const checkedIcon = <CheckBoxIcon fontSize="small" sx={{color: '#333333', borderRadius: '4px'}} />;
 
    return (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} elevation={1}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            PaperProps={{ sx: { width: 232 } }}
        >
            <MenuItem sx={{p:'16px 14px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Box fontWeight={400} lineHeight="24px">View</Box>
                <CheckIcon sx={{color: '#A2A3A9', fontSize: 20}} />
            </MenuItem>
            <MenuItem sx={{p:'16px 14px', display: 'flex', alignItems: 'center', gap: '8px'}} onClick={()=>handleCheck('update')}>
                <Checkbox checked={v.stat.update} icon={icon} checkedIcon={checkedIcon} sx={{p: '0px'}} />
                <Box fontWeight={400} lineHeight="24px">Update</Box>
            </MenuItem>
            <MenuItem sx={{p:'16px 14px', display: 'flex', alignItems: 'center', gap: '8px'}} onClick={()=>handleCheck('download')}>
                <Checkbox checked={v.stat.download} icon={icon} checkedIcon={checkedIcon} sx={{p: '0px'}} />
                <Box fontWeight={400} lineHeight="24px">Download</Box>
            </MenuItem>
        </Menu>
    )
}
