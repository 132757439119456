import { Box, MenuItem, Modal, Slide, Typography } from "@mui/material"
import { DlIcon, InfoIcon, StarIcon, TrashIcon } from "../../../../../../core/global/Icons"
import { rqx } from "../../../../../../core/request/API"
import { env } from "../../../../../../core/Env"
import { DL } from './Dl'
import { useEffect, useState } from "react"
import { Download, DownloadZip } from "../../../../../../core/transmit/S3"

export const ThreeDots = ({active, show, handlerView, favList, __SESSION, message, successOpen, showDl}) => {
    const dfltbtnList = [
        {name: 'View Details', icon: <InfoIcon />, type: 'view'},
        {name: show.data.data.user_favorite_status === 1 ? 'Add to Favorites' : 'Remove to Favorites', icon: <StarIcon fill={show.data.data.user_favorite_status === 2 ? "#107038" : "none"} stroke={show.data.data.user_favorite_status === 2 ? "#107038" : "#737373"} />, type: 'addfav'},
        {name: `Download ${show.data.data.type !== 3 && JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_type.includes('image') ? 'as...' : ''}`, icon: <DlIcon />, type: 'dl'},
        {name: 'Remove', icon: <TrashIcon fill="#737373" />, type: 'remove'},
    ]
    
    const [btnList, setBtnList] = useState([])

    const handlerCloseMenu = () => {
        active.set({...active.data, openMenu: false})
    }
    
    const handlerMenuBtn = async(e, type) => {
        if (type === 'view') {
            handlerView(show.data.data)
        }else if (type === 'addfav') {
            e.stopPropagation()
            let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/general-maintenance/cloud/favorites/create`, {
                registration_id: show.data.data.type === 1 ? show.data.data.id : '',
                contracts_id: show.data.data.type === 2 ? show.data.data.id : '',
                status: show.data.data.user_favorite_status === 2 ? 1 : 2, 
                user_id:parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]),
                documents_id:show.data.data.type === 4 ? show.data.data.id : '',
                folder_id: show.data.data.type === 3 ? show.data.data.folder_id : ''
            })
            if (res.message === 'success') {
                let newArr = favList.data.map((d,k) => d.id === show.data.data.id ? {...d, user_favorite_status : show.data.data.user_favorite_status === 2 ? 1 : 2} : {...d} )
                favList.set(newArr.filter((v) => v.user_favorite_status !== 1))
                message.set('Sucessfully removed from favorites')
                successOpen.set(true)
            }

        }else if (type === 'dl') {
            if (show.data.data.type !== 3 && JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_type.includes('image')) {
                showDl.set(e.currentTarget)
            }else{
                if (show.data.data.type !== 3) {
                    let parse = JSON.parse(show.data.data.file)
                    for (let i = 0; i < parse.length; i++) {
                        let res = await Download(e, parse[i].file_link, parse[i].file_name)
                        if (res === 'success' && i === parse.length - 1 ) {
                            showDl.set(null)
                            active.set({...active.data, val : '', openMenu : null})
                            message.set('Successfully download')
                            successOpen.set(true)
                        }
                    }
                }else{
                    let data = await rqx.get(`<<tool>>/${env()}/general-maintenance/cloud/documents/downloadFolder`, {cf_id:show.data.data.id})
                    let res = await DownloadZip(data)
                    if (res === 'success') {
                        showDl.set(null)
                        active.set({...active.data, val : '', openMenu : null})
                        message.set('Successfully download')
                        successOpen.set(true)
                    }
                }
            }
        }else if (type === 'remove') {
            show.set({...show.data, remove:{...show.data.remove, val:true}})
        }
        if (type !== 'dl') {
            handlerCloseMenu()
        }
    }

    const [subscribe, setSubscribe] = useState(true)
    useEffect(() => {
        if (subscribe) {
            if (show.data.data.access !== null && show.data.data.access !== undefined) {
                let d; 
                if (show.data.data.access !== null && (!show.data.data.access[0].update && !show.data.data.access[0].download)) {
                    d = dfltbtnList.filter(v => { return v.type === 'view' || v.type === 'addfav' }  )
                }else if(show.data.data.access !== null && (show.data.data.access[0].update && !show.data.data.access[0].download)){
                    d = dfltbtnList.filter(v => { return v.type === 'view' || v.type === 'addfav' || v.type === 'update' }  )
                }else if(show.data.data.access !== null && (!show.data.data.access[0].update && show.data.data.access[0].download)){
                    d = dfltbtnList.filter(v => { return v.type === 'view' || v.type === 'addfav' || v.type === 'dl' }  )
                }else{
                    d = dfltbtnList.filter(v => { return v.type !== 'remove' }  )
                }
                setBtnList(d)
            }else{
                setBtnList(dfltbtnList)
            }
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line
    }, [])
    
    return (
        <>
            <Modal open={active.data.openMenu} sx={{ zIndex: 1304}} className="noutlined">
                <Slide in={active.data.openMenu} direction="up" className="noutlined">
                    <Box height="100%" width="100%" display="flex" justifyContent="flex-end" flexDirection="column">
                        <Box height="100%" onClick={() => handlerCloseMenu()} />
                        <Box bgcolor="#FFFFFF" p={2} borderRadius="16px 16px 0px 0px" boxShadow="0px 0px 24px rgba(0, 0, 0, 0.08)">
                            <Box display="flex" justifyContent="center" mb={1}>
                                <Box height="2px" width="37px" bgcolor="#333333" />
                            </Box>
                            {
                                !show.data.view &&
                                    <Typography fontSize={14} fontWeight={600} lineHeight="25px" noWrap>
                                        {show.data.data.type === 1 || show.data.data.type === 2 ? 
                                            `${show.data.data.type_name} (${show.data.data.name})`
                                        :
                                            show.data.data.type === 4 ? show.data.data.name : show.data.data.folder_name
                                        }
                                    </Typography>
                            }
                            {
                                (show.data.view ? btnList.filter(v => { return v.type !== 'view'}) : btnList).map((v,k) => (
                                    (show.data.data.type !== 3 || (show.data.data.type === 3 && v.name !== 'View Details')) && (
                                        <MenuItem sx={{px: '0px', py: '8px', minHeight: '36px'}} key={k} onClick={(e) => handlerMenuBtn(e, v.type)}>
                                            {v.icon}
                                            <Box ml="12px" fontSize={14} fontWeight={400} lineHeight="22px">{v.name}</Box>
                                        </MenuItem>
                                    ) 
                                ))
                            }
                        </Box>
                    </Box>
                </Slide>
            </Modal>
            <DL showDl={showDl} show={show} message={message} successOpen={successOpen} active={active} />
        </>
    )
}