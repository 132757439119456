import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ToolContext } from '../../../core/context/ToolContext';
import { Avatar, AvatarGroup, Box, Button, Divider, IconButton, Menu, MenuItem, Modal, Slide, Typography } from '@mui/material';
import { ArrowLeft, DlIcon, GreaterThan, GridViewIcon, IconConfirm, InfoIcon, ListViewIcon, NoToolsIcon, ShareIcon, StarIcon, TrashIcon, UpdateIcon, VeriticalThreeDots } from '../../../core/global/Icons';
import { NewAddRoundIcon } from '../../../core/global/GMicons';
import { makeStyles } from '@mui/styles';
import { FavToolTip } from '../../../core/global/ToolTip'
import { ConShowIcon, HandlerActiveStar, HandlerAddFav, HandlerThreeDots, HandlerView, RegShowIcon } from './Function'
import { SnakeLoader } from '../../../core/loader/SnakeLoader';
import { Waypoint } from 'react-waypoint';
import { RecentAddFav } from './Function';
import { ImportIcon } from '../../../core/global/Icons';
import { ArrowDown } from '../../../core/global/Icons';
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { useState } from 'react';
import { Download, DownloadPDF } from '../../../core/transmit/S3';
import { InfoIconActive } from '../../../core/global/Icons';
import { VM } from '../../../core/VM';
import CloseIcon from '@mui/icons-material/Close';
import Info from '@mui/icons-material/Info';
import moment from 'moment';
import imgDL from '../../../assets/images/icons/imgDl.png';
import pdfDL from '../../../assets/images/icons/pdfDl.png';
import restoreBanner from '../../../assets/images/banner/restoreBanner.png';
import permaBanner from '../../../assets/images/banner/permanentBanner.png';
import FolderIcon from '../../../assets/images/icons/folder.png';
import Lock from '../../../assets/images/icons/lock.png';
import '@react-pdf-viewer/core/lib/styles/index.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';

let view = VM()
export const UseStyles = makeStyles({
    paper: {
        padding: '12px',
        borderRadius: '6px',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)',
        '&:hover': {
            backgroundColor: '#F1F1F1'
        }
    },
    txtfld: {
        lineHeight: '16px',
        '& .MuiOutlinedInput-root':{ 
            borderRadius: '6px',
            height: view === '' ? 44 : 40,
            backgroundColor: '#FFFFFF',
            fontSize: view === '' ? 16 : 14,
        },
    },
    btnNext: {
        height: '40px',
        width: '138px',
        borderRadius: '6px',
       
    },
    btnEdit: {
        height: '40px',
        width: '138px',
        borderRadius: '6px',
        color: '#333333',
        backgroundColor: '#F8F9FA',
        border: '1px solid #E6E6E6',
        '&:hover': {backgroundColor: '#E2EDE7', color: '#107038'},
        marginRight: '18px'
    },
    search: {
        lineHeight: '16px',
        '& .MuiOutlinedInput-root':{ 
            borderRadius: '6px',
            height: view === '' ? 44 : 40,
            backgroundColor: '#F8F9FA',
            fontSize: view === '' ? 16 : 14,
            border: '1px solid #E6E6E6'
        },
    },
    mblAutoTxt: {
        lineHeight: '16px',
        '& .MuiOutlinedInput-root':{ 
            borderRadius: '6px',
            padding: '4px 14px !important',
            backgroundColor: '#FFFFFF',
            fontSize: 14,
        },
    },
    plcyTxt: {
        lineHeight: '16px',
        '& .MuiOutlinedInput-root':{ 
            borderRadius: '6px',
            padding: '0px 10px !important',
            height: view === '' ? 44 : 40,
            backgroundColor: '#FFFFFF',
            fontSize: view === '' ? 16 : 14,
        },
    }
})

export const Tooltipdsg = (status) => {
    return(
        <Box fontSize={12} color="#F2F7FF" lineHeight="12px">
            {status !== 2 ? 'Add to favorites' : 'Remove from favorites'} 
        </Box>
    )
}

export const NoFound = ({msg, submsg, folder = false}) => {
    return (
        window.location.pathname !== '/my-cloud/recent' ?
            <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                {folder ? 
                    <img src={FolderIcon} alt="folder-img" />
                :
                    <NoToolsIcon />
                }
                <Box fontSize={14} fontWeight={600} lineHeight="16px">{msg}</Box>
                <Box mt={2} fontSize={14} fontWeight={400} lineHeight="16px" textAlign="center">{submsg}</Box>
            </Box>
        :
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                {folder ? 
                    <img src={FolderIcon} alt="folder-img" />
                :
                    <NoToolsIcon />
                }
                <Box fontSize={14} fontWeight={600} lineHeight="16px">{msg}</Box>
            </Box>
    )
}

export const Top = ({title, btnName, add, changeView, listView, match, trashFiles, trashFolder, selectedFiles,  restore, perma, empty, active, limitSelect}) => {
    const [btnMenu, setBtnMenu] = useState(null)
    let ownArray = []
    if (match === 'trash') {
        // let ownFolder = trashFolder.data.filter((v) => { return v.access === null || v.access === undefined } )
        // let ownFiles = trashFiles.data.filter((v) => { return v.access === null || v.access === undefined } )
        let ownFolder = trashFolder.data
        let ownFiles = trashFiles.data
        ownArray = [...ownFolder, ...ownFiles]
    }

    const handlerSelect = (e, option) => {
        e.stopPropagation()
        if (option === 'select') {
            limitSelect.set({...limitSelect.data, type:'select'})
            // trashFolder.set(trashFolder.data.map((v) => {return {...v, checked : option === 'select' && (v.access === null || v.access === undefined) ? true : false} } ))
            // trashFiles.set(trashFiles.data.map((v) => {return {...v, checked : option === 'select' && (v.access === null || v.access === undefined) ? true : false} } ))
            trashFolder.set(trashFolder.data.map((v) => {return {...v, checked : option === 'select' } } ))
            trashFiles.set(trashFiles.data.map((v) => {return {...v, checked : option === 'select' } } ))
            selectedFiles.set(ownArray)
        }else{
            limitSelect.set({...limitSelect.data, type:''})
            trashFolder.set(trashFolder.data.map((v) => {return {...v, checked : false} } ))
            trashFiles.set(trashFiles.data.map((v) => {return {...v, checked : false} } ))
            selectedFiles.set([])
        }
    }

    const btnList = [
        {name: 'All', type: 0},
        {name: 'Registrations', type: 1},
        {name: 'Contracts', type: 2},
    ]

    const handlerOpenMenu = (e) => {
        setBtnMenu(e.currentTarget)
    }

    const hanlderFilter = () => {

    }
    
    return(
        <Box minHeight={110}>
            {
                match === 'trash' ?
                    (
                        selectedFiles.data.length === 0 ?
                            <Box p="24px 40px 8px 40px" color="#333333" fontWeight={600} fontSize={20} lineHeight="26px">
                                {title}
                            </Box>
                        :
                            <Box p="24px 40px 8px 40px" display="flex" justifyContent="space-between">
                                <Box display="flex" alignItems="center">
                                    <IconButton sx={{p: '0px !important', mr: 2}}  onClick={(e) => handlerSelect(e, 'deselect')}>
                                        <CloseIcon sx={{fontSize: 20, color: '#737373'}} />
                                    </IconButton>
                                    <Box color="#107038" fontSize={20} fontWeight={600} lineHeight="26px">
                                        {`${selectedFiles.data.length} item${selectedFiles.data.length > 1 ? 's' : ''}`} selected
                                    </Box>
                                    {
                                        ownArray.length === selectedFiles.data.length 
                                        ?
                                            <Box p="8px 12px" borderRadius="6px" ml="12px" fontSize={14} fontWeight={600} className='c-pointer' 
                                                onClick={(e) => handlerSelect(e, 'deselect')}
                                                sx={{'&:hover': { bgcolor: '#E2EDE7', color: '#107038' } }}
                                            >
                                                Deselect all
                                            </Box>
                                        :   
                                            <Box p="8px 12px" borderRadius="6px" ml="12px" fontSize={14} fontWeight={600} className='c-pointer' 
                                                onClick={(e) => handlerSelect(e, 'select')}
                                                sx={{'&:hover': { bgcolor: '#E2EDE7', color: '#107038' } }}
                                            >
                                                Select All
                                            </Box>
                                   }
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Box p="8px 12px" borderRadius="6px" ml="12px" fontSize={14} fontWeight={600} className='c-pointer' onClick={restore}
                                        sx={{'&:hover': { bgcolor: '#E2EDE7', color: '#107038' } }}>
                                        Restore 
                                    </Box>
                                    <Box p="8px 12px" borderRadius="6px" ml="12px" fontSize={14} fontWeight={600} className='c-pointer' onClick={perma}
                                        sx={{'&:hover': { bgcolor: '#E2EDE7', color: '#107038' } }}>
                                        Permanently Remove 
                                    </Box>
                                </Box>
                            </Box>
                    )
                :
                    <Box p="24px 40px 8px 40px" color="#333333" fontWeight={600} fontSize={20} lineHeight="26px">
                        {title}
                    </Box>
            }
            <Box height="1px" bgcolor="#E6E6E6" />
            <Box p="8px 40px" display="flex" justifyContent="space-between" alignItems="center">
                {
                    match === 'favorites' ?
                        <Button sx={{gap: '4px', color: "#333333"}} onClick={(e) => handlerOpenMenu(e)}>
                            <Box>All</Box>
                            <KeyboardArrowDownIcon />
                        </Button>
                    :
                    match === 'trash' ?
                        <Box fontSize={12} fontWeight={400} lineHeight="26px">30 days after they are placed in trash, trash items are deleted permanently.</Box>
                    :
                        <Button variant="contained" sx={{p: '8px 16px', height: 36, borderRadius: '6px'}} onClick={add}> 
                            <NewAddRoundIcon width="20" height="20" />
                            <Box fontSize={14} fontWeight={600} lineHeight="16px" mx="15px">{btnName}</Box>
                        </Button>

                }
                <Box display="flex" alignItems="center">
                    {/* {
                        match === 'trash' && selectedFiles.data.length > 0 &&
                            <Box fontSize={14} fontWeight={600} lineHeight="16px" p="8px 12px" borderRadius="6px"  className='c-pointer' mr={2} onClick={empty}
                                sx={{ bgcolor: active ?  '#E2EDE7' : '#ffffff', color:  active ? '#107038' : '#333333', 
                                    '&:hover': { color: '#107038', backgroundColor: '#E2EDE7' } }}
                                >
                                Empty Trash
                            </Box>
                    } */}
                    <Box p={1} bgcolor="#FFFFFF" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.08)" borderRadius="4px" width={36} height={36} className="c-pointer" onClick={changeView}>
                        { listView.data ? <ListViewIcon /> : <GridViewIcon /> } 
                    </Box>
                </Box>
            </Box>
            <Box height="1px" bgcolor="#E6E6E6" />
            <Menu open={Boolean(btnMenu)} anchorEl={btnMenu} onClose={() => setBtnMenu(null)} PaperProps={{sx: { width: 200 } }} >
                {
                    btnList.map((v,k) => (
                        <MenuItem key={k} sx={{display:"flex", alignItems:"center", justifyContent:"space-between"}} onClick={() => hanlderFilter(v.type)}>
                            <Box>{v.name}</Box>
                            { v.type === 0 &&  <CheckIcon sx={{fontSize: 20}} /> }
                        </MenuItem>
                    ))
                }
            </Menu>
        </Box>
    )
}

export const ThreeDotsMenu = ({open, anchorEl, onClose, viewInfo, share, update, remove, download, show}) => {
    const { cloud_state } = useContext(ToolContext)
    const { showDl } = cloud_state
    const [iconActive, setIconActive] = useState('')
    
    const handlerShowDl = async(e) => {
        if ((show.data.data.feature_type === 1 && show.data.data.file_type.split('/')[0] === 'image') || (show.data.data.feature_type === 2 && JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_type.includes('image'))) {
            showDl.set(e.currentTarget)
        }else{
            await download(e, 'img')
        }
    }
    
    let validateImgDl; 
    if (show.data.data.feature_type === 2) {
        if (JSON.parse(show.data.data.file).filter(f => {return f.file_type.includes('image') }).length > 0) {
            validateImgDl = true
        }else{
            validateImgDl = false
        }
    }else{
        validateImgDl = show.data.data.file_type !== 'application/pdf'
    }

    let dwnldValidAccess = false
    let updtValidAccess = false
    let removeValidAccess = show.data.data.access !== null && show.data.data.access !== undefined && show.data.data.access[0].remove !== undefined && show.data.data.access[0].remove
    if (show.data.data.access === null || show.data.data.access === undefined || (show.data.data.access !== null && show.data.data.access[0].update)) {
        updtValidAccess = true
    }
    if (show.data.data.access === null || show.data.data.access === undefined || (show.data.data.access !== null && show.data.data.access[0].download)) {
        dwnldValidAccess = true
    }

    return (
        <>
            <Menu open={removeValidAccess ? Boolean(null) : open} anchorEl={removeValidAccess ? false : anchorEl} onClose={onClose} elevation={1}>
                <Box px={1} >
                    {!show.data.view && (
                        <MenuItem sx={{minHeight: '44px !important', py: 0}} 
                            onClick={viewInfo}
                            onMouseEnter={() => setIconActive('view')} 
                            onMouseLeave={() => setIconActive('')}
                        >
                            {iconActive === 'view' ? <InfoIconActive /> : <InfoIcon />}
                            <Box ml="12px" fontSize={14} fontWeight={400}> View Info</Box>
                        </MenuItem>
                    )}
                    <MenuItem sx={{minHeight: '44px !important', py: 0}} 
                        onClick={share}
                        onMouseEnter={() => setIconActive('share')} 
                        onMouseLeave={() => setIconActive('')}
                    >
                        <ShareIcon fill={iconActive === 'share' ? "#107038" : "#A2A3A9"} />
                        <Box ml="12px" fontSize={14} fontWeight={400}> Share Access</Box>
                    </MenuItem>
                    <MenuItem sx={{minHeight: '44px !important', py: 0, opacity:updtValidAccess ? 1 :0.3}} 
                        onClick={updtValidAccess ? update : null}
                        onMouseEnter={() => setIconActive('update')} 
                        onMouseLeave={() => setIconActive('')}
                    >
                        <UpdateIcon fill={iconActive === 'update' ? "#107038" : "#A2A3A9"} />
                        <Box ml="12px" fontSize={14} fontWeight={400}> Update</Box>
                    </MenuItem>
                    <MenuItem sx={{minHeight: '44px !important', py: 0, opacity:dwnldValidAccess ? 1 :0.3, bgcolor: showDl.data !== null ? '#F2F2F2' : '#ffffff'}}
                        onClick={dwnldValidAccess ? (e) => handlerShowDl(e) : null}
                        onMouseEnter={() => setIconActive('dl')} 
                        onMouseLeave={() => setIconActive('')}
                    >
                        <DlIcon fill={iconActive === 'dl' || showDl.data !== null ? "#107038" : "#A2A3A9"} />

                        <Box ml="12px" mr="40px" fontSize={14} fontWeight={400}> 
                            {
                                ( (show.data.data.feature_type === 1 && show.data.data.file_type.split('/')[0] === 'image') || (show.data.data.feature_type === 2 && JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_type.includes('image')) ) ? 
                                    'Download as...' 
                                : 
                                    'Download'
                            }
                        </Box>
                        {
                            (
                                (show.data.data.feature_type === 1 && show.data.data.file_type.split('/')[0] === 'image') || (show.data.data.feature_type === 2 && JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_type.includes('image'))
                            ) && (
                                <GreaterThan stroke={iconActive === 'dl' || showDl.data !== null ? "#107038" : "#737373"} />
                            )
                        }
                    </MenuItem>
                    <Divider sx={{my: '0px !important'}} />
                    <MenuItem sx={{minHeight: '44px !important', py: 0, opacity:(show.data.data.access === null || show.data.data.access === undefined) ? 1 : 0.3}} 
                        onClick={(show.data.data.access === null || show.data.data.access === undefined) ? remove : null}
                        onMouseEnter={() => setIconActive('delete')} 
                        onMouseLeave={() => setIconActive('')}
                    >
                        <TrashIcon fill={iconActive === 'delete' ? "#107038" : "#A2A3A9"} />
                        <Box ml="12px" fontSize={14} fontWeight={400}> Delete</Box>
                    </MenuItem>
                </Box>
            </Menu>
            {/* DOWNLOAD AS... MENU */}
            <Menu open={Boolean(showDl.data)} anchorEl={showDl.data} onClose={() => showDl.set(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <Box px={1} minWidth={240}>
                    <MenuItem sx={{minHeight: '44px !important', py: 0}} onClick={(e) => download(e, 'pdf')}>
                        <img src={pdfDL} alt={pdfDL} />
                        <Box ml="12px" fontSize={14} fontWeight={400}> PDF </Box>
                    </MenuItem>
                    <Divider sx={{my: '0px !important'}} />
                    {
                        validateImgDl &&
                            <>
                                <MenuItem sx={{minHeight: '44px !important', py: 0}} onClick={(e) => download(e, 'img')}>
                                    <img src={imgDL} alt={imgDL} />
                                    <Box ml="12px" fontSize={14} fontWeight={400}> Image </Box>
                                </MenuItem>
                                <Divider sx={{my: '0px !important'}} />
                            </>
                    }
                </Box>
            </Menu>
        </>
    )
}

export const FormatFilesize = (size) => {
    if (size < 1024) {
        return `${size} Bytes`;
    }else if (size < 1024 * 1024) {
        return `${(size / 1024).toFixed(2) } KB `;
    }else if (size < 1024 * 1024 * 1024) {
        return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    }else {
        return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    }
}

export const FileInfo = ({show, match, __SESSION, state, successOpen, message, stateActive, showDl, withAccess, handlerShare, notif_state}) => {
    const { cloud_state } = useContext(ToolContext)
    const { allUsrList } = cloud_state
    const nav = useHistory()
    const [open, setOpen] = useState(null)
    const [loader, setLoader] = useState(false)
    const [conData, setConData] = useState(
        {
            file_type:show.data.data.feature_type === 2 ? JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_type:'',
            file_size:show.data.data.feature_type === 2 ? JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_size:'',
            file_link:show.data.data.feature_type === 2 ? JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_link:'',
            file_name:show.data.data.feature_type === 2 ? JSON.parse(show.data.data.file)[JSON.parse(show.data.data.file).length > 1 ?1:0].file_name:''
        }
    )
    const [favBtnLoader, setFavBtnLoader] = useState(false)
    let conFile = show.data.data.feature_type === 2 && JSON.parse(show.data.data.file)
    let validity_date = `${moment(show.data.data.start_date).format('MM/DD/YYYY')} to ${moment(show.data.data.end_date).format('MM/DD/YYYY')}`
    const reg = [
        {name: 'Registration Number', val: show.data.data.series_num},
        {name: 'Registration Name', val: show.data.data.registration_name},
        {name: 'Registration Type', val: show.data.data.type !== 0 ?'Expiring' : 'Non-Expiring'},
        {name: 'Registered Name', val: show.data.data.registered_name},
        {name: 'Reference Number', val: show.data.data.reference_num},
    ]

    const con = [
        {name: 'Contract Number', val: show.data.data.series_num},
        {name: 'Contract Name', val: show.data.data.contract_name},
        {name: 'Client/Company Name', val: show.data.data.client_name},
        {name: 'Validity Date', val: validity_date},
    ]
    
    const file_info = [
        {name: 'File Type', val: show.data.data.feature_type === 2 ? conData.file_type : show.data.data.file_type},
        {name: 'File Size', val: show.data.data.feature_type === 2 ? FormatFilesize(conData.file_size) : FormatFilesize(show.data.data.file_size)},
    ]

    const HandlerImgView = (e, val) => {
        if (e.detail >= 1) {
            show.set({...show.data, view: true, fullview:true, data: val})
        }
    }

    const download = async(e, type) => {
        let res;
        if (show.data.data.feature_type === 1) {
            if (type === 'pdf') {
                res = await DownloadPDF(show.data.data.file_link, show.data.data.file_name)
            }else{
                res = await Download(e, show.data.data.file_link, show.data.data.file_name)
            }
            if (res === 'success') {
                setOpen(null)
                message.set('Successfully download')
                successOpen.set(true)
            }
        }else{
            let parse = JSON.parse(show.data.data.file)
            for (let i = 0; i < parse.length; i++) {
                if (type === 'pdf') {
                    if (parse[i].file_type.includes('image')) {
                        res = await DownloadPDF(parse[i].file_link, parse[i].file_name)       
                    }else{
                        res = await Download(e, parse[i].file_link, parse[i].file_name)
                    }
                }else{
                    res = await Download(e, parse[i].file_link, parse[i].file_name)
                }
                if (res === 'success' && i === parse.length - 1 ) {
                    showDl.set(null)
                    stateActive.set({...stateActive.data, val : '', openMenu : null})
                    message.set('Successfully download')
                    successOpen.set(true)
                }
            }
        }
    }
    
    const addFav = async(e, v) => {
        if (!favBtnLoader) {
            show.set({...show.data, data:{...show.data.data, user_favorite_status: (show.data.data.user_favorite_status === 1 ? 2 : 1) }})
            message.set( show.data.data.user_favorite_status === 1 ? 'Successfully added to favorites.' : 'Sucessfully removed from favorites')
            successOpen.set(true)
            setFavBtnLoader(true)
            if (match === 'recent') {
                await RecentAddFav(e, v, __SESSION, state, setFavBtnLoader)
            }else{
                await HandlerAddFav(e, v, __SESSION, state, setFavBtnLoader)
            }
        }
    }
    
    const HandlerViewTab = async(val) => {
        setLoader(true)
        await setConData({
            file_type:val.file_type,
            file_size:val.file_size,
            file_link:val.file_link,
            file_name:val.file_name,
        })
        setTimeout(() => {
            setLoader(false)
        }, 500);
    }

    let usrDetails = [];
    if (!withAccess.data.loader && withAccess.data.val.length !== 0) {
        let l_access = withAccess.data.val.map(v => {return {...v, access : JSON.parse(v.access) } } )
        usrDetails = l_access[0].access.map(v => allUsrList.data.users.filter(f => f.id === v.user_id )[0] )
    }

    let validate =  (show.data.data.access === null || show.data.data.access === undefined || (show.data.data.access !== null && show.data.data.access[0].download))
    
    const handleCloseAccess = () => {
        show.set({...show.data, view: false, fullview:false})
        notif_state.rtAccess.set(false)
        nav.push(`/my-cloud/${show.data.data.feature_type === 2 ? 'contracts' : 'registrations'}/list`)
    }

    useEffect(() => {
        if (show.data.data.access !== undefined && show.data.data.access !== null && show.data.data.access.length > 0) {
            if (show.data.data.access[0].remove && !notif_state.rtAccess.data) {
                notif_state.rtAccess.set(true)
            }
        }
         // eslint-disable-next-line
    }, [notif_state.rtNotifAccess.data])
    
    return(
        <>
            <Modal open={show.data.view} className="noutlined">
                <Slide in={show.data.view} direction='up' className="noutlined">
                    <Box height="100%" width="100%" display="flex" flexDirection="column">
                        <Box minHeight={88} display="flex" justifyContent="flex-end" alignItems="center" px={4} onClick={() => show.set({...show.data, view : false, data : ''})}>
                            <IconButton onClick={() => show.set({...show.data, view : false, data : ''})}>
                                <CloseIcon sx={{color: '#FFFFFF'}} />
                            </IconButton>
                        </Box>
                        <Box height="100%" width="100%"  borderRadius="24px 24px 0px 0px" display="flex">
                            <Box height="100%" width="100%" bgcolor="#FFFFFF" borderRadius="24px 0px 0px 0px" display="flex" justifyContent="center" >
                                <Box height="100%" width={{xs:'100%', xl:'883px'}} p="135px 105px 135px 125px" display="flex" flexDirection="column">
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>  
                                        <Box fontSize={16} fontWeight={600} lineHeight="16px">{show.data.data.feature_type === 2 ?  show.data.data.contract_name : show.data.data.registration_name}</Box>
                                        <Box display="flex" gap="12px">
                                            <Button variant="contained" disableElevation onClick={(e) => addFav(e,show.data.data)} disabled={favBtnLoader}
                                                sx={{p: '10px 24px', fontSize: 14, fontWeight: 400, lineHeight: '16px', bgcolor: '#F8F9FA', color: '#333333', '&:hover': {bgcolor: '#E2EDE7', color: '#107038'}  }}
                                            >
                                                <StarIcon 
                                                    fill={ show.data.data.user_favorite_status === 2 ? '#11783C' : 'none' } 
                                                    stroke={ show.data.data.user_favorite_status === 2 ? '#11783C' : '#737373'}
                                                />
                                                {
                                                    show.data.data.user_favorite_status === 2 ?
                                                        <Box ml="12px">Revome from Favorites</Box>
                                                    :
                                                        <Box ml="12px">Add to Favorites</Box>
                                                }
                                            </Button>
                                            {
                                                (show.data.data.access === null || show.data.data.access === undefined || ( show.data.data.access !== null && (show.data.data.access[0].update || show.data.data.access[0].download)) ) && (
                                                    <Box 
                                                        display="flex" alignItems="center" justifyContent="center" height={36} width={36} bgcolor="#E2EDE7" borderRadius="6px" className='c-pointer' pr="4px" pb="4px"
                                                        sx={{border: `1px solid ${stateActive.data.openMenu !== null ? '#107038' : '#E2EDE7'}`,  '&:hover': { border: '1px solid #107038' } }}
                                                        onClick={(e) => HandlerThreeDots(e, e.currentTarget, show.data.data, 'open', stateActive , show)}
                                                    >
                                                        <IconButton sx={{p: '0px !important'}}>
                                                            <VeriticalThreeDots fill={stateActive.data.openMenu !== null ? "#107038" : "#737373"} />
                                                        </IconButton>
                                                    </Box>
                                                )
                                            }
                                        </Box>
                                    </Box>
                                    <Box height="100%" width="100%" bgcolor="#F8F9FA" p="61px 89px" borderRadius="16px" className="overflowY overflowx c-pointer" onClick={(e) => HandlerImgView(e,show.data.data)}>
                                        {show.data.data.feature_type === 2 ?
                                            loader ? 
                                                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                                                    <SnakeLoader size="1rem" bg="#11783C" distance="0.3rem" />
                                                </Box>
                                            : 
                                                conData.file_type.includes('image')? 
                                                    <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                                        <Avatar src={conData.file_link} variant="rounded" sx={{height:100, width: 'auto', borderRadius: '6px 6px 0px 0px'}} />
                                                    </Box>
                                                :
                                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                        <Box sx={{height: '100%', width:'100%'}}>
                                                            <Box component={Viewer} fileUrl={conData.file_link} />
                                                        </Box>
                                                    </Worker>
                                        :
                                            (
                                                show.data.data.file_type.includes('image')? 
                                                    <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                                        <Avatar src={show.data.data.file_link} variant="rounded" sx={{height:100, width: 'auto', borderRadius: '6px 6px 0px 0px'}} />
                                                    </Box>
                                                :
                                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                        <Box sx={{height: '100%', width:'100%'}}>
                                                            <Box component={Viewer} fileUrl={show.data.data.file_link} 
                                                            // defaultScale={SpecialZoomLevel.PageFit} 
                                                            />
                                                        </Box>
                                                    </Worker>
                                            )
                                        }
                                    </Box>
                                    {show.data.data.feature_type === 2 && (
                                        <Box height="87px" mt="24px" display="flex" justifyContent="center" gap="20px">
                                            {conFile.map((v,k) => (
                                                <Box onClick={()=>HandlerViewTab(v)} key={k} className="c-pointer" width="150px" bgcolor="#F8F9FA" border={`2px solid ${conData.file_link === v.file_link ? '#107038' : '#F8F9FA'}`} borderRadius="12px" display="flex" justifyContent="center">
                                                    {
                                                        v.file_type.includes('image')? 
                                                            <Box sx={{backgroundImage:`url(${v.file_link})`, backgroundSize:"cover", backgroundPosition:"center"}} minHeight="70px" width="118px" mt="12px" borderRadius="12px 12px 0px 0px"/>
                                                        :
                                                            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                                <Box sx={{mt:'16px', maxHeight:'70px', width:'118px', overflow:'hidden !important', borderRadius:'12px 12px 0px 0px'}}>
                                                                    <Box component={Viewer} fileUrl={v.file_link} 
                                                                    // defaultScale={SpecialZoomLevel.PageFit} 
                                                                    />
                                                                </Box>
                                                            </Worker>
                                                    }
                                                </Box>
                                            )).reverse()}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <Box height="100%" minWidth={{md: 320, lg: 380}} bgcolor="#F8F9FA" borderRadius="0px 24px 0px 0px">
                                <Box height="100%" p="80px 24px">
                                    <Box display="flex" alignItems="center" mb="20px">
                                        <Box p="7px" bgcolor="#FFFFFF" borderRadius="6px" display="flex" alignItems="center" mr="20px">
                                            <Info  />
                                        </Box>
                                        <Box fontSize={14} fontWeight={600} lineHeight="16px"> {`${show.data.data.feature_type === 2 ?  'Contract' : 'Registration'} Info`}  </Box>
                                    </Box>
                                    {
                                        show.data.data.feature_type === 2 ?
                                            con.map((v,k) => (
                                                <Box width="100%" display="flex" alignItems="baseline" mb={k === 3 ? 3 : 2} fontSize={14} fontWeight={400} lineHeight="19px" key={k}>
                                                    <Box width="50%" color="#737373">{v.name}</Box>
                                                    <Box width="50%" color="#333333" sx={{wordBreak: 'break-all'}}>{v.val}</Box>
                                                </Box>
                                            ))
                                        :
                                            <>
                                                {reg.map((v,k, d) => (
                                                    <Box width="100%" display="flex" alignItems="baseline" mb={ (k === d.length - 1 && show.data.data.end_date === null) ? 3 : 2} fontSize={14} fontWeight={400} lineHeight="19px" key={k}>
                                                        <Box width="50%" color="#737373">{v.name}</Box>
                                                        <Box width="50%" color="#333333" sx={{wordBreak: 'break-all'}}>{v.val}</Box>
                                                    </Box>
                                                ))}
                                                {
                                                    show.data.data.start_date !== null && show.data.data.end_date !== null &&
                                                        <Box width="100%" display="flex" alignItems="baseline" mb={3} fontSize={14} fontWeight={400} lineHeight="19px">
                                                            <Box width="50%" color="#737373">Validity Date</Box>
                                                            <Box width="50%" color="#333333">{validity_date}</Box>
                                                        </Box>
                                                }
                                            </>
                                            
                                    }
                                    <Box fontSize={14} fontWeight={600} color="#333333" lineHeight="16px" mb="20px">File Info</Box>
                                    {
                                        file_info.map((v,k) => (
                                            <Box width="100%" display="flex" alignItems="baseline" mb={2} fontSize={14} fontWeight={400} lineHeight="19px" key={k}>
                                                <Box width="50%" color="#737373">{v.name}</Box>
                                                <Box width="50%" color="#333333">{v.val}</Box>
                                            </Box>
                                        ))
                                    }
                                    <Box display="flex" alignItems="center" borderTop="1px solid #E6E6E6" pt="28px">
                                        <Box fontSize={14} fontWeight={400} lineHeight="22px" mr="19px">Shared with:</Box>
                                        {
                                            usrDetails.length === 0 ? 
                                                <Box display="flex" alignItems="center" gap="12px" className='c-pointer' onClick={() => handlerShare(show.data.data)}>
                                                    <img src={Lock} alt={Lock} />
                                                    <Box fontWeight={400} fontSize={14} lineHeight="22px" color="#A2A3A9">Not shared</Box>
                                                </Box>
                                            :
                                                <AvatarGroup max={5} sx={{gap: '12px', '& .MuiAvatar-root': {width: 32, height: 32, fontSize: 14} }} className='c-pointer' onClick={() => handlerShare(show.data.data)}>
                                                    {
                                                        usrDetails.map((v,k)=> (
                                                            <Avatar src={JSON.parse(v.profile).profile} alt="profile" sx={{width: 32, height: 32}} key={k} />
                                                        ))
                                                    }
                                                </AvatarGroup>
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Slide>
            </Modal>
            {/* FULLVIEW OF FILE/IMAGE */}
            <Modal open={show.data.fullview}   
                sx={{borderColor:'none !important'}}
                onClose={()=> show.set({...show.data, view: true, fullview:false})}>
                <Box height="100%" width="100%" display="flex" flexDirection="column"> 
                    <Box minHeight="56px" display="flex" justifyContent="space-between" bgcolor="#313131" px={3} py="20px">
                        <Box display="flex" alignItems="center">
                            <IconButton onClick={()=> show.set({...show.data, view: true, fullview:false})}>
                                <ArrowLeft stroke='#FFFFFF' />
                            </IconButton>
                            <Box ml={2} color="#FFFFFF" fontSize={14} lineHeight="16px" fontWeight={400}>
                                {show.data.data.feature_type === 2 ? 
                                    `${show.data.data.contract_name} ( ${show.data.data.client_name} ) ${conData.file_name}`
                                :
                                    `${show.data.data.registration_name} ( ${show.data.data.registered_name} ) ${show.data.data.file_name}`
                                }
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" p="8px 16px" bgcolor="#3A3A3A" borderRadius="6px" className={validate ? 'c-pointer' : '' }  onClick={validate ? (e) => setOpen(e.currentTarget) : null}
                            sx={{'&:hover': {backgroundColor: validate ? '#107038' : ''}, opacity: validate ? 1 : 0.3  }}>
                            <ImportIcon />
                            <Box mx="10px" fontSize={14} lineHeight="16px" fontWeight={600} color="#FFFFFF">Download as</Box>
                            <ArrowDown />
                        </Box>
                    </Box>
                    <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                        <Box>
                            <Box minHeight="730px" maxHeight="730px" width="100%" className="overflowY">
                                <Box height="100%" width="100%">
                                    {show.data.data.feature_type === 2 ?
                                            conData.file_type.includes('image')? 
                                                <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                                    <Avatar src={conData.file_link} alt={conData.file_link} variant="rounded" sx={{height:200, width: 'auto', borderRadius: '6px 6px 0px 0px'}} />
                                                </Box>
                                            :
                                                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                    <Box sx={{height: '730px', width:'730px', borderRadius:'12px'}}>
                                                        <Box component={Viewer} fileUrl={conData.file_link} 
                                                        // defaultScale={SpecialZoomLevel.PageFit} 
                                                        />
                                                    </Box>
                                                </Worker>
                                    :
                                    (
                                        show.data.data.file_type.includes('image')? 
                                                <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                                    <Avatar src={show.data.data.file_link} variant="rounded" sx={{height:200, width: 'auto', borderRadius: '6px 6px 0px 0px'}} />
                                                </Box>
                                            :
                                                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                    <Box sx={{height: '730px', width:'730px', borderRadius:'12px'}}>
                                                        <Box component={Viewer} fileUrl={show.data.data.file_link} 
                                                        // defaultScale={SpecialZoomLevel.PageFit} 
                                                        />
                                                    </Box>
                                                </Worker>
                                        )
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            {/* DOWNLOAD MENU */}
            <Menu open={validate ? Boolean(open) : Boolean(null)} anchorEl={validate ? open : false} onClose={() => setOpen(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <Box px={1} minWidth={240}>
                    <MenuItem sx={{minHeight: '44px !important', py: 0}} onClick={(e) => download(e, 'pdf')}>
                        <img src={pdfDL} alt={pdfDL} />
                        <Box ml="12px" fontSize={14} fontWeight={400} > PDF </Box>
                    </MenuItem>
                    <Divider sx={{my: '0px !important'}} />
                    <MenuItem sx={{minHeight: '44px !important', py: 0}} onClick={(e) => download(e, 'img')}>
                        <img src={imgDL} alt={imgDL} />
                        <Box ml="12px" fontSize={14} fontWeight={400}> Image </Box>
                    </MenuItem>
                    <Divider sx={{my: '0px !important'}} />
                </Box>
            </Menu>
            {notif_state.rtAccess.data && (
                <NoAccessModal open={notif_state.rtAccess.data} handleCloseAccess={handleCloseAccess}/>
            )}
        </>
    )
}

export const Registration = ({data, regActive, starActive, show, listView, inftScroll, __LoadmoreData, match, __SESSION, state, successOpen, message, withAccess}) => {
    const dsg = UseStyles()
    const [favBtnLoader, setFavBtnLoader] = useState(false)
    const addFav = async(e, v) => {
        if (!favBtnLoader) {
            setFavBtnLoader(true)
            await message.set(v.user_favorite_status === 2 ? 'Sucessfully removed from favorites' : 'Sucessfully added to favorites')
            successOpen.set(true)
            if (match === 'recent') {
                await RecentAddFav(e,v, __SESSION, state, setFavBtnLoader)
            }else{
                await HandlerAddFav(e, v, __SESSION, state, setFavBtnLoader)
            }
        }
    }
    return(
        <>
            {listView.data ? 
                data.map((v,k) => (
                    <Box display="flex" justifyContent="space-between" alignItems="center" p="12px" borderRadius="6px" key={k} mb={2} className="c-pointer" 
                        sx={{bgcolor: regActive.data.openMenu !== null && k === regActive.data.val  ? '#f0f0f0' : '#ffffff', '&:hover': { bgcolor: '#f0f0f0' } }}
                        onMouseOver={() => RegShowIcon(k, 'enter', regActive)}  
                        onMouseLeave={() => RegShowIcon('', 'leave', regActive)}
                    >
                        <Box display="flex" alignItems="center" width="100%" onClick={() => HandlerView(v, show, regActive, withAccess)}>
                            {
                                v.file_type === null || v.file_type.includes('image') ?
                                    <Avatar src={v.file_link} variant="rounded" sx={{ width: 48, height: 48, borderRadius: '6px' }} />
                                :
                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                        <Box sx={{maxHeight: '48px', minHeight:'48px', width:'48px', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                            <Box component={Viewer} fileUrl={v.file_link} 
                                            // defaultScale={SpecialZoomLevel.PageFit} 
                                            />
                                        </Box>
                                    </Worker>
                            }
                            <Box display="flex" flexDirection="column" ml="12px">
                                <Box fontSize={14} fontWeight={600} lineHeight="19px" sx={{wordBreak: 'break-word'}} >{v.registration_name}({v.registered_name})</Box>
                                <Box fontSize={14} fontWeight={400} lineHeight="23px" color="#737373">
                                    {
                                        v.end_date === null ?
                                            'Non-Expiring'
                                        :
                                            `Expiring • ED: ${moment(v.end_date).format('MM/DD/YYYY')}`
                                    }
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                            {
                                (regActive.data.val === k || v.user_favorite_status === 2) && 
                                    <FavToolTip title={Tooltipdsg(v.user_favorite_status)}>
                                        <IconButton sx={{p: '0px !important', mr: 1}} 
                                            onMouseEnter={() => HandlerActiveStar(k, starActive)} 
                                            onMouseLeave={() => HandlerActiveStar('', starActive)}
                                            onClick={(e) => addFav(e,v)}
                                        >
                                            <StarIcon fill={starActive === k && v.user_favorite_status !== 2 ? '#333333' : v.user_favorite_status === 2 ? '#11783C' : 'none'} 
                                                stroke={starActive === k && v.user_favorite_status !== 2 ? '#333333' : v.user_favorite_status === 2 ? '#11783C' : '#737373'} 
                                            />
                                        </IconButton>
                                    </FavToolTip>
                            }
                            {
                                regActive.data.val === k && 
                                    <IconButton sx={{p: '0px !important'}} onClick={(e) => HandlerThreeDots(e, k, v, 'open', regActive, show)}>
                                        <VeriticalThreeDots fill={regActive.data.openMenu !== null ? "#107038" : "#737373"} />
                                    </IconButton>
                            }
                        </Box>
                    </Box>
                ))
            :
                <Box width="100%" display="flex" flexWrap="wrap" gap="20px">
                    {
                        data.map((v, k) => (
                            <Box minWidth='273px' maxWidth='273px' key={k}>
                                <Box 
                                    className={dsg.paper} 
                                    backgroundColor={regActive.data.openMenu !== null && k === regActive.data.val  ? '#f0f0f0' : '#ffffff'} 
                                    onMouseOver={() => RegShowIcon(k, 'enter', regActive)} 
                                    onMouseLeave={() => RegShowIcon('', 'leave', regActive)}
                                > 
                                    <Box onClick={() => HandlerView(v, show, regActive, withAccess)}>
                                        <Box p="16px 16px 0px 16px" bgcolor="#F8F9FA" borderRadius="8px" height="125px">
                                            {
                                                v.type === null || v.file_type.includes('image') ?
                                                    <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                                        <Avatar src={v.file_link} variant="rounded" sx={{height:100, width: 'auto', borderRadius: '6px 6px 0px 0px'}} />
                                                    </Box>
                                                :
                                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                        <Box sx={{height:'109px', maxHeight:'109px', minHeight: '109px', width:'100%', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                                            <Box component={Viewer} fileUrl={v.file_link} 
                                                            defaultScale={SpecialZoomLevel.PageWidth}
                                                            />
                                                        </Box>
                                                    </Worker>
                                            }
                                        </Box>
                                        <Typography fontSize={14} fontWeight={600} lineHeight="22px" mt="12px" mb="35px" noWrap>{v.registration_name}({v.registered_name})</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Box fontSize={14} fontWeight={400} lineHeight="23px" color="#737373"> 
                                            {
                                                v.end_date === null ?
                                                    'Non-Expiring'
                                                :
                                                    `Expiring • ED: ${moment(v.end_date).format('MM/DD/YY')}`
                                            }
                                        </Box>
                                        <Box display="flex" alignItems="center" gap="4px">
                                            {
                                                (regActive.data.val === k || v.user_favorite_status === 2) &&
                                                    <FavToolTip title={Tooltipdsg(v.user_favorite_status)}>
                                                        <IconButton sx={{p: '0px !important'}} 
                                                            onMouseEnter={() => HandlerActiveStar(k, starActive)} 
                                                            onMouseLeave={() => HandlerActiveStar('', starActive)}
                                                            onClick={(e) => addFav(e,v)}
                                                        >
                                                            <StarIcon fill={starActive.data === k && v.user_favorite_status !== 2 ? '#333333' : v.user_favorite_status === 2 ? '#11783C' : 'none'} 
                                                                stroke={starActive.data === k && v.user_favorite_status !== 2 ? '#333333' : v.user_favorite_status === 2 ? '#11783C' : '#737373'} 
                                                            />
                                                        </IconButton>
                                                    </FavToolTip>
                                            }
                                            {
                                                regActive.data.val === k &&
                                                    <IconButton sx={{p: '0px !important', height:'23px', width:'24px', display:'flex', justifyContent:'center', alignItems:'center'}} onClick={(e) => HandlerThreeDots(e, k, v, 'open', regActive, show)}>
                                                        <Box pr="4px"><VeriticalThreeDots fill={regActive.data.openMenu !== null ? "#107038" : "#737373"}  /></Box>
                                                    </IconButton>
                                            }   
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        ))
                    }
                </Box>
            }
            {
                window.location.pathname === '/my-cloud/registrations/list' &&
                    inftScroll.data && (
                        <Box display="flex" justifyContent="center">
                            <Waypoint onEnter={__LoadmoreData} >
                                <Box component="strong" mt={5} display="flex" justifyContent="center">
                                    <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                                </Box>
                            </Waypoint>
                        </Box>
                    )
            }
        </>

        
        
    )
}

export const Contracts = ({data, conActive, show, starActive, listView, inftScroll, __LoadmoreData, match, __SESSION, state, successOpen, message, withAccess}) => {
    const dsg = UseStyles()
    const [favBtnLoader, setFavBtnLoader] = useState(false)
    const addFav = async(e, v) => {
        if (!favBtnLoader) {
            setFavBtnLoader(true)
            await message.set(v.user_favorite_status === 2 ? 'Sucessfully removed from favorites' : 'Sucessfully added to favorites')
            successOpen.set(true)
            if (match === 'recent') {
                await RecentAddFav(e,v, __SESSION, state, setFavBtnLoader)
            }else{
                await HandlerAddFav(e, v, __SESSION, state, setFavBtnLoader)
            }
        }
    }
    return(
        <>
            {
                listView.data ?
                    data.map((v,k) => (
                        <Box display="flex" justifyContent="space-between" alignItems="center" p="12px" borderRadius="6px" key={k} mb={2} className="c-pointer" 
                            sx={{bgcolor: conActive.data.openMenu !== null && k === conActive.data.val  ? '#f0f0f0' : '#ffffff', '&:hover': { bgcolor: '#f0f0f0' } }}
                            onMouseOver={() => ConShowIcon(k, 'enter', conActive)} 
                            onMouseLeave={() => ConShowIcon('', 'leave', conActive)}
                        >
                            <Box display="flex" alignItems="center" width="100%" onClick={() => HandlerView(v, show, conActive, withAccess)}>
                                {
                                    JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('image')? 
                                        <Avatar src={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} variant="rounded" sx={{ width: 48, height: 48, borderRadius: '6px' }} />
                                    :
                                        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                            <Box sx={{height:'48px', maxHeight:'48px', minHeight: '48px', width:'48px', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                                <Box component={Viewer} fileUrl={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} defaultScale={SpecialZoomLevel.PageWidth} />
                                            </Box>
                                        </Worker>
                                }
                                <Box display="flex" flexDirection="column" ml="12px">
                                    <Box fontSize={14} fontWeight={600} lineHeight="19px">{v.contract_name}({v.client_name})</Box>
                                    <Box fontSize={14} fontWeight={400} lineHeight="23px" color="#737373">
                                        ED: {moment(v.end_date).format('MM/DD/YY')}
                                    </Box>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center">
                                {
                                    (conActive.data.val === k || v.user_favorite_status === 2) && 
                                        <FavToolTip title={Tooltipdsg(v.user_favorite_status)}>
                                            <IconButton sx={{p: '0px !important', mr: 1}} 
                                                onMouseEnter={() => HandlerActiveStar(k, starActive)} 
                                                onMouseLeave={() => HandlerActiveStar('', starActive)}
                                                onClick={(e) => addFav(e,v)}
                                            >
                                                <StarIcon fill={starActive.data === k && v.user_favorite_status !== 2 ? '#333333' : v.user_favorite_status === 2 ? '#11783C' : 'none'} 
                                                    stroke={starActive.data === k && v.user_favorite_status !== 2 ? '#333333' : v.user_favorite_status === 2 ? '#11783C' : '#737373'} 
                                                />
                                            </IconButton>
                                        </FavToolTip>
                                }
                                {
                                    conActive.data.val === k && 
                                        <IconButton sx={{p: '0px !important'}} onClick={(e) => HandlerThreeDots(e, k, v, 'open', conActive, show)}>
                                            <VeriticalThreeDots fill={conActive.data.openMenu !== null ? "#107038" : "#737373"} />
                                        </IconButton>
                                }
                            </Box>
                        </Box>
                    ))
                :
                    <Box width="100%" display="flex" flexWrap="wrap" gap="20px">
                        {
                            data.map((v,k) => (
                                <Box minWidth='273px' maxWidth='273px' key={k}>
                                    <Box className={dsg.paper} backgroundColor={conActive.data.openMenu !== null && k === conActive.data.val  ? '#f0f0f0' : '#ffffff'}
                                        onMouseOver={() => ConShowIcon(k, 'enter', conActive)} 
                                        onMouseLeave={() => ConShowIcon('', 'leave', conActive)}
                                    >
                                        <Box className="c-pointer" onClick={() => HandlerView(v, show, conActive, withAccess)}>
                                            <Box p="16px 16px 0px 16px" bgcolor="#F8F9FA" borderRadius="8px">
                                                {
                                                    JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('image')? 
                                                        <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                                                            <Avatar src={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} variant="rounded" sx={{height:100, width: 'auto', borderRadius: '6px 6px 0px 0px'}} />
                                                        </Box>
                                                    :
                                                        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                                            <Box sx={{maxHeight:'109px', minHeight: '109px', height: '109px', width:'100%', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                                                <Box component={Viewer} fileUrl={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} 
                                                                // defaultScale={SpecialZoomLevel.PageFit} 
                                                                />
                                                            </Box>
                                                        </Worker>
                                                        
                                                }
                                            </Box>
                                            <Typography fontSize={14} fontWeight={600} lineHeight="22px" mt="12px" mb="35px" noWrap>{v.contract_name}({v.client_name})</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Box fontSize={14} fontWeight={400} lineHeight="23px" color="#737373"> 
                                                ED: {moment(v.end_date).format('MM/DD/YY')}
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                {
                                                    (conActive.data.val === k || v.user_favorite_status === 2) && 
                                                        <FavToolTip title={Tooltipdsg(v.user_favorite_status)}>
                                                            <IconButton sx={{p: '0px !important', mr: 1}} 
                                                                onMouseEnter={() => HandlerActiveStar(k, starActive)} 
                                                                onMouseLeave={() => HandlerActiveStar('', starActive)}
                                                                onClick={(e) => addFav(e,v)}
                                                            >
                                                                <StarIcon fill={starActive.data === k && v.user_favorite_status !== 2 ? '#333333' : v.user_favorite_status === 2 ? '#11783C' : 'none'} 
                                                                    stroke={starActive.data === k && v.user_favorite_status !== 2 ? '#333333' : v.user_favorite_status === 2 ? '#11783C' : '#737373'} 
                                                                />
                                                            </IconButton>
                                                        </FavToolTip>
                                                }
                                                {
                                                    conActive.data.val === k && 
                                                        <IconButton sx={{p: '0px !important'}} onClick={(e) => HandlerThreeDots(e, k, v, 'open', conActive, show)}>
                                                            <VeriticalThreeDots fill={conActive.data.openMenu !== null ? "#107038" : "#737373"} />
                                                        </IconButton>
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box> 
            }
            {
                window.location.pathname === '/my-cloud/contracts/list' &&
                    inftScroll.data && (
                      <Box display="flex" justifyContent="center">
                          <Waypoint onEnter={__LoadmoreData} >
                              <Box component="strong" mt={5} display="flex" justifyContent="center">
                                  <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                              </Box>
                          </Waypoint>
                      </Box>
                    )
            }
        </>
    )
}

export const UnsavedMsgModal = ({open, onClose, HandlerBtn}) => {
    return(
        <Modal open={open} onClose={onClose} className="noutlined">
            <Slide in={open} direction="up" className="noutlined">
                <Box px={{xs:2, sm:0}} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                    <Box p={3} bgcolor="#FFFFFF" borderRadius="6px" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.08)" width="406px">
                        <Box fontSize={24} fontWeight={600} lineHeight="29.05px" mb={3}>Unsaved changes</Box>
                        <Box fontSize={14} fontWeight={400} lineHeight="20px" mb={4}>Hey there! Your changes will be lost if you navigate away from this page. Are you sure you want to leave this page?</Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <Button variant="outlined" disableElevation sx={{mr: 2, bgcolor: '#E2EDE7', p: '12px 16px', lineHeight: '16px'}}
                                onClick={() => HandlerBtn('stay')}
                            >
                                Stay
                            </Button>
                            <Button variant="contained" disableElevation sx={{p: '12px 16px', lineHeight: '16px'}}
                                onClick={() => HandlerBtn('leave')}
                            >
                                Leave
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}

export const ConfirmRemove = ({show, HandlerDelete, sharedFltr}) => {
    return (
        <Modal open={show.data.remove.val} onClose={()=>HandlerDelete('cancel')} sx={{zIndex:1304}} className="noutlined">
            <Slide in={show.data.remove.val} direction="up" className="noutlined">
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <Box height="205px" width="343px" bgcolor="#FFFFFF" p="24px" borderRadius="6px" mx={2}>
                        <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="space-between">
                            <Box height="100%">
                                <Box minHeight="16px" fontSize={20} fontWeight={600} lineHeight="16px" color="#333333">
                                    Remove {window.location.pathname === '/my-cloud/documents/list' || window.location.pathname === '/my-cloud/shared-access/list' || show.data.data.type === 3 || show.data.data.type === 4 ? 
                                            'Documents'
                                        :
                                            show.data.data.feature_type === 1 || show.data.data.type === 1 ? 'Registration' : 'Contracts'}
                                </Box>
                                {
                                    window.location.pathname === '/my-cloud/favorites/list' ? 
                                        <Box height="100%" pt="20px" fontSize={14} fontWeight={400} lineHeight="23px" color="#333333">
                                            Once you remove 
                                            <Box component="label" color="#107038">
                                                {
                                                    (show.data.data.type === 3) ? 
                                                        ` ( ${show.data.data.folder_name} ) `
                                                    :(show.data.data.type === 4) ? 
                                                        ` ( ${show.data.data.name} ) `
                                                    :
                                                        ` ${show.data.data.type_name} ( ${show.data.data.name} ) `
                                                }
                                            </Box> it will be move to trash.
                                        </Box>
                                    :
                                        window.location.pathname === '/my-cloud/shared/list' &&  sharedFltr.data.type === 'all' ?
                                            <Box height="100%" pt="20px" fontSize={14} fontWeight={400} lineHeight="23px" color="#333333">
                                                Once you remove <Box component="label" color="#107038">{` ( ${show.data.data.type_name} ${show.data.data.name} ) `} </Box> 
                                                it will be move to trash.
                                            </Box>
                                        :
                                        <Box height="100%" pt="20px" fontSize={14} fontWeight={400} lineHeight="23px" color="#333333">
                                            Once you remove <Box component="label" color="#107038">
                                            {show.data.data.feature_type === 1 ? 
                                                ` ( ${show.data.data.registration_name} ${show.data.data.registered_name} ) ` 
                                            :
                                                window.location.pathname === '/my-cloud/documents/list' || window.location.pathname === '/my-cloud/shared-access/list' ?
                                                    show.data.data.feature_type === 4 ? ` ( ${show.data.data.file_name} ) ` : ` ( ${show.data.data.folder_name} ) `
                                                :
                                                    ` ( ${show.data.data.contract_name} ${show.data.data.client_name} ) `} 
                                        </Box> 
                                            it will be move to trash.
                                        </Box>
                                }
                            </Box>
                            <Box minHeight="40px" display="flex" gap="8px">
                                <Box onClick={()=>HandlerDelete('cancel')} className="c-pointer" width="100%" bgcolor="#E2EDE7" borderRadius="6px" display="flex" justifyContent="center" alignItems="center" border="1px solid #107038">
                                    <Box fontSize={14} fontWeight={600} color="#107038" >Cancel</Box>
                                </Box>
                                <Box onClick={()=>HandlerDelete('submit')} className="c-pointer" width="100%" bgcolor="#107038" borderRadius="6px" display="flex" justifyContent="center" alignItems="center">
                                    <Box fontSize={14} fontWeight={600} color="#FFFFFF" display="flex" alignItems="center" gap="4px">
                                        Remove 
                                        <Box pt="8px">{show.data.remove.loader && <SnakeLoader size="5px" bg="#FFFFFF" distance="2.5px" />}</Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}

export const UpgradeStorage = ({insuffShow}) => {
    return (
        <Modal open={insuffShow.data} onClose={()=>insuffShow.set(false)} sx={{zIndex:1304}} className="noutlined">
            <Slide in={insuffShow.data} direction="up" className="noutlined">
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <Box height="auto" width="343px" bgcolor="#FFFFFF" p="24px" borderRadius="6px" mx={2}>
                        <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="space-between">
                            <Box height="100%">
                                <Box minHeight="16px" fontSize={20} fontWeight={600} lineHeight="16px" color="#333333" textAlign="center">
                                    <IconConfirm/>
                                </Box>
                                <Box height="100%" py="20px" fontSize={14} fontWeight={400} lineHeight="23px" color="#333333">
                                    Insufficient Storage to continue please upgrade your plan.
                                </Box>
                            </Box>  
                            <Box minHeight="40px" display="flex" gap="8px">
                                <Box onClick={()=>insuffShow.set(false)} className="c-pointer" width="100%" bgcolor="#E2EDE7" borderRadius="6px" display="flex" justifyContent="center" alignItems="center" border="1px solid #107038">
                                    <Box fontSize={14} fontWeight={600} color="#107038" >Cancel</Box>
                                </Box>
                                <Box onClick={()=>insuffShow.set(false)} className="c-pointer" width="100%" bgcolor="#107038" borderRadius="6px" display="flex" justifyContent="center" alignItems="center">
                                    <Box fontSize={14} fontWeight={600} color="#FFFFFF" display="flex" alignItems="center" gap="4px">
                                        Upgrade 
                                        {/* <Box pt="8px">{show.data.remove.loader && <SnakeLoader size="5px" bg="#FFFFFF" distance="2.5px" />}</Box> */}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}

export const ConfirmationTrash = ({show, hanlderModalBtn, modalType, trashList}) => {
    let selectedCount = trashList.data.length
    
    return (
        <Modal open={show.data.remove.val} onClose={()=>hanlderModalBtn('cancel')} sx={{zIndex:1304}} className="noutlined">
            <Slide in={show.data.remove.val} direction="up" className="noutlined">
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <Box height="308px" width="343px" bgcolor="#FFFFFF" borderRadius="6px" display="flex" flexDirection="column" mx={2}>
                        { 
                            modalType === 'restore' ? <
                                img src={restoreBanner} alt={restoreBanner} width="100%" />  
                            : 
                                <img src={permaBanner} alt={permaBanner} width="100%"/>  
                        }
                        <Box p="34px 24px 24px 24px" height="100%" width="100%" display="flex" flexDirection="column">
                            {
                                modalType === 'restore' ?
                                    <Box height="100%">
                                        {
                                            selectedCount > 1 ?
                                                <Typography fontSize={14} fontWeight={400} lineHeight="23px" mt="20px" color="#333333" sx={{wordBreak: 'break-all'}} className="nowrapY">
                                                    Restore all these
                                                        <Box fontSize={14} component="label" color="#107038" mx="4px">
                                                            {selectedCount} {`item${selectedCount > 1 ? 's?' : '?'}`}
                                                        </Box>
                                                </Typography>
                                            :
                                                <>
                                                    <Box fontSize={20} fontWeight={600} lineHeight="16px">Restore this file?</Box>
                                                    <Typography fontSize={14} fontWeight={400} lineHeight="23px" mt="20px" color="#107038" sx={{wordBreak: 'break-all'}} className="nowrapY">
                                                        {
                                                            show.data.data.type === 3 || show.data.data.type === 4 ? 
                                                                show.data.data.name
                                                            :
                                                                `${show.data.data.type_name} (${show.data.data.name})`
                                                        }
                                                    </Typography>
                                                </>
                                                   

                                        }
                                    </Box>
                                :
                                    modalType === 'perma' ?
                                        <Box height="100%" width="100%" >
                                            <Box fontSize={20} fontWeight={600} lineHeight="16px">Permanently Remove?</Box>
                                            {
                                                selectedCount > 1 ?
                                                    <Typography fontSize={14} fontWeight={400} lineHeight="23px" mt="20px" color="#333333" sx={{wordBreak: 'break-all'}} className="nowrapY">
                                                        All these
                                                        <Box fontSize={14} component="label" color="#107038" mx="4px">
                                                            {selectedCount} items
                                                        </Box>
                                                        can never be recovered. 
                                                    </Typography>
                                                :
                                                    <Typography fontSize={14} fontWeight={400} lineHeight="23px" mt="20px" color="#333333"  className="nowrapY">
                                                        Once you remove 
                                                        <Box fontSize={14} component="label" color="#107038" mx="4px">
                                                            {show.data.data.type === 3 || show.data.data.type === 4 ? 
                                                                `${show.data.data.name}`
                                                            :
                                                                `${show.data.data.type_name} (${show.data.data.name})`
                                                            }
                                                        </Box>
                                                        it will never be recovered.
                                                    </Typography>
                                            }
                                        </Box>
                                    :
                                        <Box height="100%" width="100%" >
                                            <Box fontSize={20} fontWeight={600} lineHeight="16px">Permanently Remove?</Box>
                                            <Typography fontSize={14} fontWeight={400} lineHeight="23px" mt="20px" color="#333333" sx={{wordBreak: 'break-all'}} className="nowrapY">
                                                All these
                                                <Box fontSize={14} component="label" color="#107038" mx="4px">
                                                    {selectedCount} items
                                                </Box>
                                                can never be recovered.
                                            </Typography>
                                        </Box>

                                    

                            }
                            <Box display="flex" alignItems="center" minHeight={40}>
                                <Button fullWidth variant="outlined" onClick={()=>hanlderModalBtn('cancel')}
                                    sx={{mr: 1, height: 40, borderRadius: '6px', bgcolor: '#E2EDE7', '&:hover':{bgcolor: '#A4CAB4'} }}>
                                        Cancel
                                </Button>
                                <Button fullWidth variant='contained' disableElevation onClick={()=>hanlderModalBtn('submit')}
                                     sx={{height: 40, borderRadius: '6px',  }}
                                >
                                    {
                                        modalType === 'restore' ? 'Restore' : 'Remove'
                                    }
                                    <Box pt="8px">{show.data.remove.loader && <SnakeLoader size="5px" bg="#FFFFFF" distance="2.5px" />}</Box>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}

export const PermissionModal = ({show, handleProceed, type}) => {
    return (
        <Modal open={show} className="noutlined">
            <Slide in={show} direction="up" className="noutlined">
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <Box height="180px" width="343px" bgcolor="#FFFFFF" p="24px" borderRadius="6px">
                        <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="space-between">
                            <Box height="100%" textAlign="center">
                                <Box height="100%" fontSize={16} fontWeight={400} lineHeight="23px" color="#333333">
                                {type === 'policies' ? 
                                    'To access Policies features, set up your company details first through general-maintenance.'
                                :
                                    'To access Business Cloud features, set up your company details first through general-maintenance.'
                                }
                                </Box>
                            </Box>
                            <Box minHeight="40px" display="flex" gap="8px">
                                <Box onClick={()=>handleProceed('cancel')} className="c-pointer" width="143px" bgcolor="#E2EDE7" borderRadius="6px" display="flex" justifyContent="center" alignItems="center">
                                    <Box fontSize={14} fontWeight={600} color="#107038" >Not now</Box>
                                </Box>
                                <Box onClick={()=>handleProceed('proceed')} className="c-pointer" width="143px" bgcolor="#107038" borderRadius="6px" display="flex" justifyContent="center" alignItems="center">
                                    <Box fontSize={14} fontWeight={600} color="#FFFFFF" display="flex" alignItems="center" gap="4px">
                                    Proceed 
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}

export const ShareConfirmation = ({confirmShare, name, subname, cancel, submit, saveLoader}) => {
    return(
        <Modal open={confirmShare.data.val} className="noutlined">
            <Slide in={confirmShare.data.val} direction='up' className="noutlined">
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" >
                    <Box width="372px" bgcolor="#FFFFFF" p="24px" borderRadius="6px" mx={2} sx={{wordBreak: 'break-word'}} >
                    
                    {
                        confirmShare.data.type === 'share' ?
                            <>
                                <Typography fontSize={{xs: 16, md: 20}} fontWeight={400} lineHeight="32px" color="#333333" flexWrap="wrap" >
                                    Share access of “
                                        <Box component="label" color="#107038">
                                            {name} {subname !== '' && `(${subname})`} 
                                        </Box>
                                    ”to employees? 
                                </Typography>
                                <Box display="flex" alignItems="center" gap="8px" mt={5}>
                                    <Button fullWidth variant="contained" disableElevation onClick={cancel} disabled={saveLoader}
                                        sx={{ bgcolor: '#E2EDE7', color: '#107038', '&:hover': { bgcolor: '#A4CAB4' }  }} 
                                    >
                                        No
                                    </Button>
                                    <Button fullWidth variant="contained" disableElevation onClick={submit}
                                        sx={{ bgcolor: '#107038', color: '#FFFFFF', '&:hover': { bgcolor: '#16522F' } }} 
                                    >
                                        Share
                                        <Box pl="8px">{saveLoader && <SnakeLoader size="5px" bg="#FFFFFF" distance="2.5px" />}</Box>
                                    </Button>
                                </Box>
                            
                            </>
                        : 
                            (
                                confirmShare.data.type === 'remove' && (
                                    <>
                                        <Typography fontSize={20} fontWeight={400} lineHeight="32px" color="#333333" flexWrap="wrap" >
                                            Remove employee access to “
                                                <Box component="label" color="#107038">
                                                    {name} {subname !== '' && `(${subname})`} 
                                                </Box>
                                            ”? 
                                        </Typography>
                                        <Box pt="20px">
                                            <Box color="#A2A3A9" fontSize={14} fontWeight={400} lineHeight="23px" >The file will no longer be visible or editable to the selected employees once their access is removed.</Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" gap="8px" mt="48px">
                                            <Button fullWidth variant="contained" disableElevation onClick={cancel} disabled={saveLoader}
                                                sx={{ minHeight:'40px', border: '1px solid #107038', borderRadius:'6px', bgcolor: '#E2EDE7', color: '#107038', '&:hover': { bgcolor: '#A4CAB4' }  }} 
                                            >
                                                No
                                            </Button>
                                            <Button fullWidth variant="contained" disableElevation onClick={submit}
                                                sx={{ minHeight:'40px', borderRadius:'6px', bgcolor: '#107038', color: '#FFFFFF', '&:hover': { bgcolor: '#16522F' } }} 
                                            >
                                                Remove 
                                                <Box pl="8px">{saveLoader ? <SnakeLoader size="5px" bg="#FFFFFF" distance="2.5px" /> : 'Access'}</Box>
                                            </Button>
                                        </Box>
                                    
                                    </>
                                )
                            )
                    }
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}

export const RemoveShareConfirmation = ({confirmRemoveShare, name, subname, cancel, submit, saveLoader}) => {
    return(
        <Modal open={confirmRemoveShare.data.val} className="noutlined">
            <Slide in={confirmRemoveShare.data.val} direction='up' className="noutlined">
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <Box width="372px" bgcolor="#FFFFFF" p="24px" borderRadius="6px" mx={1}>
                    {
                        confirmRemoveShare.data.type === 'remove' && (
                            <>
                                <Typography fontSize={20} fontWeight={400} lineHeight="32px" color="#333333" flexWrap="wrap" >
                                    Remove employee access to “
                                        <Box component="label" color="#107038">
                                            {name} {subname !== '' && `(${subname})`} 
                                        </Box>
                                    ”? 
                                </Typography>
                                <Box pt="20px">
                                    <Box color="#A2A3A9" fontSize={14} fontWeight={400} lineHeight="23px" >The file will no longer be visible or editable to the selected employees once their access is removed.</Box>
                                </Box>
                                <Box display="flex" alignItems="center" gap="8px" mt="48px">
                                    <Button fullWidth variant="contained" disableElevation onClick={cancel} disabled={saveLoader}
                                        sx={{ minHeight:'40px', border: '1px solid #107038', borderRadius:'6px', bgcolor: '#E2EDE7', color: '#107038', '&:hover': { bgcolor: '#A4CAB4' }  }} 
                                    >
                                        No
                                    </Button>
                                    <Button fullWidth variant="contained" disableElevation onClick={submit}
                                        sx={{ minHeight:'40px', borderRadius:'6px', bgcolor: '#107038', color: '#FFFFFF', '&:hover': { bgcolor: '#16522F' } }} 
                                    >
                                        Remove 
                                        <Box pl="8px">{saveLoader ? <SnakeLoader size="5px" bg="#FFFFFF" distance="2.5px" /> : 'Access'}</Box>
                                    </Button>
                                </Box>
                            
                            </>
                        )
                    }
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}

export const NoAccessModal = ({open, handleCloseAccess}) => {
    return(
        <Modal open={open} className="noutlined">
            <Slide in={open} direction='up' className="noutlined">
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <Box width="372px" bgcolor="#FFFFFF" p="24px" borderRadius="6px">
                    <Typography fontSize={20} fontWeight={400} lineHeight="32px" color="#333333" flexWrap="wrap" >
                        You don't currently have permission to access this file.
                    </Typography>
                    <Box pt="20px">
                        <Box color="#A2A3A9" fontSize={14} fontWeight={400} lineHeight="23px" >Contact your administrator for assistance.</Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap="8px" mt="48px">
                        <Button fullWidth variant="contained" disableElevation onClick={handleCloseAccess}
                            sx={{ minHeight:'40px', borderRadius:'6px', bgcolor: '#107038', color: '#FFFFFF', '&:hover': { bgcolor: '#16522F' } }} 
                        >
                            OK 
                        </Button>
                    </Box>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}

export const SizeLimitModal = ({open, setOlOpen}) => {
    return(
        <Modal open={open} className="noutlined">
            <Slide in={open} direction='up' className="noutlined">
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <Box width="372px" bgcolor="#FFFFFF" p="24px" borderRadius="6px">
                    <Typography fontSize={20} fontWeight={400} lineHeight="32px" color="#333333" flexWrap="wrap" >
                        You don't currently have permission to access this file.
                    </Typography>
                    <Box pt="20px">
                        <Box color="#A2A3A9" fontSize={14} fontWeight={400} lineHeight="23px" >Contact your administrator for assistance.</Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap="8px" mt="48px">
                        <Button fullWidth variant="contained" disableElevation onClick={()=>setOlOpen(false)}
                            sx={{ minHeight:'40px', borderRadius:'6px', bgcolor: '#107038', color: '#FFFFFF', '&:hover': { bgcolor: '#16522F' } }} 
                        >
                            OK 
                        </Button>
                    </Box>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}