import { Box } from "@mui/material"
import { Buttons } from "../../../../../core/global/forms/Button"
import { Fields } from "./Fields"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { rqx } from "../../../../../core/request/API"
import { env } from "../../../../../core/Env"
import { useContext } from "react"
import { ToolContext } from "../../../../../core/context/ToolContext"

export const ConForm = ({ match, ctgyData, categoryType, concernForm, setConcernForm, ctgyTypeList, handleInputConcern, __SESSION, handleTeamList, teamList, __init }) => {
    const { speakout_state, } = useContext(ToolContext)
    const { message, successOpen, saveLoader } = speakout_state
    const nav =  useHistory()
    const handleBack = () => {
        nav.push('/speakout/concern/list')
        setConcernForm([{
            id: 0,
            concern_num: '',
            category: '',
            team: '',
            details: '',
            type: '',
            active: 1,
            status: 1,
            created_at: '',
            feedback: '',
            feedback_date: '',
        }])
    }

    const handleSave = async() => {
        if (match.params === 'create') {
            saveLoader.set(true)
            let body = {...concernForm, company_id:__SESSION.data.uaccess[0].company_id, user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1]}
            let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/concern/create`, body)
            if (res.message === 'success') {
                successOpen.set(true)
                message.set('Concern successfully submitted.')
                nav.push('/speakout/concern/list')
                saveLoader.set(false)
                __init(0, '')
                setConcernForm([{
                    id: 0,
                    concern_num: '',
                    category: '',
                    team: '',
                    details: '',
                    type: '',
                    active: 1,
                    status: 1,
                    created_at: '',
                    feedback: '',
                    feedback_date: '',
                }])
            }
        }
    }
    
    return (
        <Box height="100%" width="100%">
            <Box bgcolor="#ECECEC" borderRadius="10px" p={3}  display="flex" justifyContent="space-between" alignItems="center" >
                <Box fontSize="16px" fontWeight="700">{match.params === 'create' ? 'Add Concern' : 'Update Concern'}</Box>
            </Box>
            {
                match.params === 'create' &&
                <Box fontSize="12px" color="red" display="flex" justifyContent="flex-end" mt={2}>* All Fields are required.</Box>
            }
            <Fields match={match} categoryType={categoryType} ctgyData={ctgyData} concernForm={concernForm} setConcernForm={setConcernForm} ctgyTypeList={ctgyTypeList} handleInputConcern={handleInputConcern} __SESSION={__SESSION} handleTeamList={handleTeamList} teamList={teamList} />
            <Box display="flex" justifyContent="flex-end" mt={4}>
                <Buttons variant="contained" margin="0px 24px 0px 0px" borderRadius="10px" color="cancel" width="190px" height="44px" text="Back" event={handleBack}>Back</Buttons>
                <Buttons variant="contained" borderRadius="10px" width="190px" height="44px" text={match.params === 'create' ? 'Save' : 'Update'} event={()=>handleSave()} disabled={!concernForm.type || !concernForm.category || !concernForm.team || !concernForm.details }></Buttons>
            </Box>
        </Box>
    )
} 