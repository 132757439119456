import { useEffect, useContext, useState } from "react"
import { Avatar, Box, Grid, MenuItem, Select, Tooltip, Typography } from "@mui/material"
import { ToolContext } from "../../../../../core/context/ToolContext";
import { rqx } from "../../../../../core/request/API";
import { env } from "../../../../../core/Env";
import { motion } from 'framer-motion';
import { NoToolFound } from "../../../../empty/Index";
import { ToolLoader } from "../../../loader/MidLoader";

export const RegularUser = () => {
    const { tool_state } = useContext(ToolContext);
    const { __SESSION, preload, globalLoader } = tool_state
    const toolList = tool_state.toolOwned.data
    const uid = parseInt(atob(tool_state.__SESSION.data.ainfo.aid).split('_', 2)[1])
    
    const [datas, setDatas] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [selected, setSelected] = useState(0)
    const [tids] = useState([]) 

    const _header = [ {title: 'Tool Name', size: 3}, {title: 'Total Transactions', size: 3}, {title: 'Ongoing', size: 2}, {title: 'Accomplished', size: 2}, {title: 'Canceled/Rejected', size: 2} ]
   
    const changeHandler = async(value) => {
        setSelected(value)
    }

    const __redirect = async(e, tid, logo) => {
        e.stopPropagation()
        preload.set({loader:true, logo:JSON.parse(logo)})
        let res = await rqx.get(`<<global>>/${env()}/tools/toolcb`, {tid: tid, env:env() !== 'prod' ? 'sandbox' : 'production'});
        if (res.msg === 'success') {
            if (res.tu.length > 0) {
                window.location.href = `${res.tu[0].value}?ainfo=${encodeURIComponent(JSON.stringify(__SESSION.data.ainfo))}`
            }
            setTimeout(() => {
                preload.set({...preload.data, loader:false})
            }, 2000);
            return
        }
    }

    const __init = async(ids) => {
        const tlist = [...toolList]
        if (ids.length === 0 ) {
            tlist.filter((v) => { return tids.push(v.tid) })
        }
        const toolsort = tlist.filter(g => ids.find(n => (n === g.tid) ))
        const mdata = toolsort.map(v => { return {...v, total_transact:0, ongoing:0, accomplished:0, reject:0 }})

        let tbdata = []
        for (let i = 0; i < ids.length; i++) {
            if (ids[i] === 14 || ids[i] === 136 ) {
                let res = await rqx.get(`<<tb>>/${env() === 'dev' ? 'local' : env()}/dashboard/read`, {user_id:uid})
                // let rest = await rqx.get(`<<tb>>/${env() === 'dev' ? 'local' : env()}/dashboard/read`, {user_id:uid, ref: 'total_transact'})
                // let reso = await rqx.get(`<<tb>>/${env() === 'dev' ? 'local' : env()}/dashboard/read`, {user_id:uid, ref: 'ongoing'})
                // let resa = await rqx.get(`<<tb>>/${env() === 'dev' ? 'local' : env()}/dashboard/read`, {user_id:uid, ref: 'accomplished'})
                // let resr = await rqx.get(`<<tb>>/${env() === 'dev' ? 'local' : env()}/dashboard/read`, {user_id:uid, ref: 'reject'})
                tbdata.push({ tid:ids[i], total_transact:res.transac, ongoing:res.ongoing, accomplished:res.accomplished, reject:res.reject })
            }
            continue;
        }
        const mergeArr = mdata.map((md) => {
            const td = tbdata.find((td) => td.tid === md.tid);
            return {...md, ...td};
        });
        // console.log(mergeArr)
        setDatas(mergeArr)
        setIsLoading(false)
    }
    
    useEffect(() => {
        let subscribe = true
        if (subscribe) {
            if (!globalLoader.data && isLoading) {
                __init(tids)
            }
        }
        return () => subscribe = false
        // eslint-disable-next-line
    }, [globalLoader.data])

    return (
        <Box bgcolor="#ffffff" py={2} mt={4}  borderRadius="6px" height="310px" display="flex" flexDirection="column" px={2}>
            {isLoading || globalLoader.data? 
                <ToolLoader />
            :
                datas.length === 0 ?
                    <NoToolFound m={3}/>
                :
                    <>
                        <Box minHeight="130px" >
                            <Box fontSize={14} fontWeight={600} color="#333333">Tools Summary</Box>
                            <Select sx={{width: '196px', mt: 2}} size="small"  value={selected} onChange={(e) => changeHandler(e.target.value)}>
                                <MenuItem selected value={0}>All Categories</MenuItem>
                            </Select>
                            <Grid container pt="29px" >
                                {_header.map((v, k) => (
                                    <Tooltip key={k} title={v.title} placement={k === 0 ? 'top-start' : 'top'}>
                                        <Grid item sm={v.size} lg={v.size} key={k} textAlign={k !== 0 && 'center'} >
                                            <Typography fontSize={12} fontWeight={500} noWrap>{v.title}</Typography>
                                        </Grid>
                                    </Tooltip>
                                ))}
                            </Grid>
                        </Box>
                        <Box height="100%" className="overflowY" px={2}>
                            {datas.map((v,k) => (
                                <Grid onClick={(e)=>__redirect(e, v.tid, v.logo)} component={motion.div} whileHover={{ backgroundColor:'#F0F6F3'}} className="c-pointer" key={k} container mb="12px" minHeight="43px" px="12px" sx={{border: '1px solid #ECECEC', borderRadius:'4px'}} justifyContent="center" alignItems="center">
                                    <Grid item sm={3} lg={3}>
                                        <Box display="flex">
                                            <Avatar src={JSON.parse(v.logo)} alt="logo" variant="rounded" sx={{width: 20, height: 20, borderRadius:'6px'}} />
                                            <Typography ml={1} fontSize={14} noWrap color="#333333" fontWeight={400}>{v.name}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={3} lg={3} textAlign="center">
                                        <Typography ml={1} fontSize={14} noWrap color="#333333" fontWeight={600}>{v.total_transact}</Typography>
                                    </Grid>
                                    <Grid item sm={2} lg={2} textAlign="center">
                                        <Typography ml={1} fontSize={14} noWrap color="#333333" fontWeight={400}>{v.ongoing}</Typography>
                                    </Grid>
                                    <Grid item sm={2} lg={2} textAlign="center">
                                        <Typography ml={1} fontSize={14} noWrap color="#333333" fontWeight={400}>{v.accomplished}</Typography>
                                    </Grid>
                                    <Grid item sm={2} lg={2} textAlign="center">
                                        <Typography ml={1} fontSize={14} noWrap color="#333333" fontWeight={400}>{v.reject}</Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>
                    </>
            }
        </Box>
    )
}