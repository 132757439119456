import { Avatar, Box, IconButton, Typography } from '@mui/material'
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core'
import { FavStarMbl, VeriticalThreeDots } from '../../../../../../core/global/Icons'
import { motion } from 'framer-motion'
import moment from 'moment'

export const RegListView = ({handlerView, data, handlerOpenMenu}) => {
  
    return (
            data.map((v,k) => (
                <Box key={k} onClick={() => handlerView(v)}>
                    <Box p={1} display="flex" alignItems="center" flexDirection="row" bgcolor="#FFFFFF" borderRadius="6px" component={motion.div} whileTap={{ backgroundColor: '#f0f0f0' }} width="100%">
                        <Box display="flex" gap="12px" width="92%">
                            <Box display="flex" alignItems="center" minHeight={40} minWidth={40} >
                                {
                                    v.file_type === null || v.file_type.includes('image') ?
                                        <Avatar variant="rounded" src={v.file_link} sx={{borderRadius: '6px'}} />
                                    :
                                        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                            <Box sx={{maxHeight: '40px', minHeight:'40px', width:'40px', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                                <Box component={Viewer} fileUrl={v.file_link} defaultScale={SpecialZoomLevel.PageWidth} />
                                            </Box>
                                        </Worker>
                                }
                            </Box>
                            <Box width="80%" >
                                <Typography fontSize={12} fontWeight={600} lineHeight="19px" width="100%" noWrap>{v.registration_name} ({v.registered_name})</Typography>
                                <Box display="flex" alignItems="center">
                                    {
                                        v.user_favorite_status === 2  &&
                                            <FavStarMbl fill={v.user_favorite_status === 2 ? '#11783C' : 'none'} />
                                    }
                                    <Box ml={v.user_favorite_status === 2 && 1} fontSize={12} fontWeight={400} lineHeight="23px"> 
                                        {
                                            v.end_date === null ?
                                                'Non-Expiring'
                                            :
                                                `Expiring • ED: ${moment(v.end_date).format('MM/DD/YYYY')}`
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" minHeight={20} minWidth={20}> 
                            <IconButton sx={{p: '0px'}} onClick={(e) => handlerOpenMenu(e,v,'reg')}>
                                <VeriticalThreeDots fill={"#737373"} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            ))
    )
}

export const ConListView = ({handlerView, data, handlerOpenMenu}) => {
    
    return(
            data.map((v,k) => (
                <Box key={k} onClick={() => handlerView(v)}>
                    <Box p={1} display="flex" alignItems="center" flexDirection="row" width="100%">
                        <Box display="flex" gap="12px" width="92%">
                            <Box display="flex" alignItems="center" minHeight={40} minWidth={40}>
                                {
                                    JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_type.includes('image')? 
                                        <Avatar variant="rounded" src={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link} sx={{borderRadius: '6px'}} />
                                :
                                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                        <Box sx={{height:'40px', maxHeight:'40px', minHeight: '40px', width:'40px', overflow: 'hidden !important', borderRadius:'6px 6px 0px 0px', bgcolor:'#fff'}}>
                                            <Box component={Viewer} fileUrl={JSON.parse(v.file)[JSON.parse(v.file).length > 1 ?1:0].file_link}  defaultScale={SpecialZoomLevel.PageWidth}  />
                                        </Box>
                                    </Worker>
                                }
                            </Box>
                            <Box width="80%">
                                <Typography fontSize={12} fontWeight={600} lineHeight="19px" width="100%" noWrap>{v.contract_name}({v.client_name})</Typography>
                                <Box display="flex" alignItems="center">
                                    {
                                        v.user_favorite_status === 2 &&
                                            <FavStarMbl fill={v.user_favorite_status === 2 ? '#11783C' : 'none'} />
                                    }
                                    <Box ml={1} fontSize={12} fontWeight={400} lineHeight="23px">  ED: {moment(v.end_date).format('MM/DD/YY')} </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" minHeight={20} minWidth={20}> 
                            <IconButton sx={{p: '0px'}} onClick={(e) => handlerOpenMenu(e, v,'con')}>
                                <VeriticalThreeDots fill={"#737373"} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            ))
    )
}