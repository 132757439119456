import { Box, IconButton } from "@mui/material"
import { NewAddRoundIcon } from "../../../../../core/global/GMicons"
import { TFields } from "./Fields"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useContext, useEffect } from "react"
import { rqx } from "../../../../../core/request/API"
import { env } from "../../../../../core/Env"
import { ToolContext } from "../../../../../core/context/ToolContext"
import { Buttons } from "../../../../../core/global/forms/Button"

export const TForm = ({ match, teamForm, setTeamForm, __init, allUsers, teamData }) => {
    const { speakout_state, tool_state } = useContext(ToolContext)
    const { message, successOpen, saveLoader } = speakout_state
    const { __SESSION } = tool_state 

    const teamid = teamData.data.length

    const nav = useHistory()
    const handleBack = () => {
        nav.push('/speakout/team/list')
    }

    const handleAddRow = () => {
        setTeamForm((prev) => [...prev, {id: teamid + teamForm.length + 1, team_number: `TEAM-00000000${teamid + teamForm.length + 1}`, team_name: '', member: {}, active: 1},])
    }

    const handleSave = async() => {
        // Extract existing category names from ctgyData
        const teamExist = teamData.data.filter((cat) => cat && cat.name).map((cat) => cat.name.toLowerCase());

        // 🔹 Find the team being updated
        const existingTeam = teamData.data.find((t) => t.id === teamForm[0]?.id);
        const existingTeamName = existingTeam ? existingTeam.name.toLowerCase() : '';

        // Check for duplicates and update state with error messages
        let hasDuplicate = false;
        const updatedForm = teamForm.map((item) => {
            if (item.team_name) {
                const lowerCaseName = item.team_name.toLowerCase();
                // Exclude the current team name when updating
                const isDuplicate = match.params === 'update'
                    ? teamExist.includes(lowerCaseName) && lowerCaseName !== existingTeamName.toLowerCase()
                    : teamExist.includes(lowerCaseName);
    
                if (isDuplicate) {
                    hasDuplicate = true;
                    return { ...item, error: 'Team Name already exists.' };
                }
            }
            return { ...item, error: '' };
        });

        if (hasDuplicate) {
            setTeamForm(updatedForm);
            return;
        }

        if (match.params === 'create') {
            let bodyArray = [...teamForm]
            for (let i = 0; i < bodyArray.length; i++) {
                let body = {
                    company_id:__SESSION.data.uaccess[0].company_id,
                    user_id:atob(__SESSION.data.ainfo.aid).split('_', 2)[1],
                    name:bodyArray[i].team_name,
                    member:bodyArray[i].member
                }
                let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/team/create`, body)
                if (res.message === 'success') {
                    successOpen.set(true)
                    message.set('Team successfully saved.')
                    __init(0, '')
                    nav.push('/speakout/team/list')
                    saveLoader.set(false)
                }
                
            }
        } else {
            let body = {...teamForm}
            let res = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/team/update`, {id: body[0].id, name: body[0].team_name, member: body[0].member, active: body[0].active, user_id: atob(__SESSION.data.ainfo.aid).split('_', 2)[1]})

            if (res.message === 'success') {
                successOpen.set(true)
                message.set('Team successfully updated.')
                __init(0, '')
                nav.push('/speakout/team/list')
                saveLoader.set(true)
            }
        }
    }

    const __initialize = () => {
        if (match.params !== 'update') {
            setTeamForm([{
                id: teamid + 1,
                team_number: `TEAM-00000000${teamid + 1}`,
                team_name: '',
                member: [],
                active: 1,
                error: ''
            }])
        }
    }
    
    useEffect(() => {
        __initialize()
        // eslint-disable-next-line
    }, [])

    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column">
            <Box minHeight="100px" bgcolor="#ECECEC" borderRadius="10px" p={3}  display="flex" justifyContent="space-between" alignItems="center">
            <Box fontSize="16px" fontWeight="700">{match.params === 'create' ? 'Add Team' : 'Update Team'}</Box>
                {
                    match.params === 'create' && teamForm.length < 5 ?
                    <IconButton variant="contained" sx={{bgcolor: '#11783C', '&:hover':{ backgroundColor: '#1B984F' }}} onClick={handleAddRow}><NewAddRoundIcon /></IconButton>
                    :
                    match.params === 'create' &&
                    <Box fontSize="12px" color="red">You can only add up to 5 teams at a time!</Box>
                }
            </Box>
            {
                match.params === 'create' &&
                <Box minHeight="20px" fontSize="12px" color="red" display="flex" justifyContent="flex-end" mt={2}>* All Fields are required.</Box>
            }
            <Box maxHeight="600px" className="overflowY">
                <TFields match={match} allUsers={allUsers} teamForm={teamForm} setTeamForm={setTeamForm} teamid={teamid} />
            </Box>
            <Box minHeight="60px" display="flex" justifyContent="flex-end" mt={4}>
                <Buttons variant="contained" margin="0px 24px 0px 0px" borderRadius="10px" color="cancel" width="190px" height="44px" event={()=>handleBack()} text="Back">Back</Buttons>
                <Buttons variant="contained" borderRadius="10px" width="190px" height="44px" event={()=>handleSave()} text={match.params === 'create' ? 'Save' : 'Update'} disabled={teamForm.some((item) => !item.team_name || !item.member || item.member.length === 0)}></Buttons>
            </Box>
        </Box>
    )
}