import { ConPzl } from "./Contracts";
import { PolPzl } from "./Policy";
import { RegPzl } from "./Registrations";

export const UbrPersonalization = ({match}) => {
   
    if (match.feature === 'registrations') {
        return <RegPzl/>
    }else if(match.feature === 'contracts'){
        return <ConPzl/>
    }else if(match.url === '/policies/create'){
        return <PolPzl/>
    }else{
        return <></>
    }
}