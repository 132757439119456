import { useState } from "react"

export const ToolStates = () => {
    const [globalLoader, setGlobalLoader] = useState(true)
    const [prefetch, setPrefetch] = useState(false)
    const [__SESSION, setSession] = useState({ainfo: null, pinfo: null, uaccess:null})

    const [toolOwned, setToolOwned] = useState([
        // {
        //     abbreviation: "PTB",
        //     amount: "100.00",
        //     cart_item_id: 32,
        //     description: "A request management tool that processes and catalogs customer service requests.",
        //     end_date: "2022-09-30 14:19:15",
        //     interval_name: "Annually",
        //     logo: "\"https://pofsis-repo.s3.amazonaws.com/prod/file/image/png/031022175358753989.png\"",
        //     name: "POFTeambond",
        //     plan_name: "Enterprise",
        //     start_date: "2022-03-31 14:19:15",
        //     tid: 14,
        //     tn_name: "Technology & Software",
        //     toid: 36,
        //     tool_category_id: "{\"business\": {\"mode\": 3, \"tcid\": 10}}",
        // }
    ])
    const [toolCartCount, setToolCartCount] = useState(0)

    // FOR MY TOOL STATE
    const [showMyTool, setShowMyTool] = useState(false) 
    const [isLoaderMT, setIsLoaderMT] = useState(false) 
    const [showMTDash, setShowMyDash] = useState(false) // SHOW DASHBOARD MY TOOL

    //PRE LOAD FOR SWITCHING TOOLS
    const [preload, setPreload] = useState({loader:false, logo:''}) 

    //VALIDATE ACCOUNT IF ACTIVE
    const [validateAccount, setValidateAccount] = useState({data:'', count:0}) 
    const [noAccessModal, setNoAccessModal] = useState(false)

    return {
        globalLoader: {data: globalLoader, set: setGlobalLoader},
        prefetch: {data: prefetch, set: setPrefetch},
        __SESSION: {data: __SESSION, set: setSession},

        toolOwned: {data: toolOwned, set: setToolOwned},
        toolCartCount: {data: toolCartCount, set: setToolCartCount},

        // FOR MY TOOL STATE
        showMyTool: {data: showMyTool, set: setShowMyTool},
        isLoaderMT: {data: isLoaderMT, set: setIsLoaderMT},
        showMTDash: {data: showMTDash, set: setShowMyDash},
        
        //PRE LOAD FOR SWITCHING TOOLS
        preload: {data: preload, set: setPreload},

        validateAccount: {data: validateAccount, set: setValidateAccount},
        noAccessModal: {data: noAccessModal, set: setNoAccessModal},
    }
}