import { Box, Checkbox, IconButton } from "@mui/material"
import { VeriticalThreeDots } from "../../../../../core/global/Icons"
import { Waypoint } from "react-waypoint";
import { SnakeLoader } from "../../../../../core/loader/SnakeLoader";

let uai_user = process.env.REACT_APP_UAI_REGULAR;

export const List = ({ handleClick, plcyData, __SESSION, inftScroll, loadMoreData, handleCheckChange, handleLongPress, selectPolicy, checkedIcon, icon }) => {
    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column" gap="8px">
            {
                plcyData.data.map((v, k) => (
                    <Box borderBottom="1px #E6E6E6 solid" p="8px 4px 8px 4px" width="100%" key={k} display="flex" alignItems="center" flexDirection="row" justifyContent="space-between" >
                        {
                            __SESSION.data.uaccess[0].role_id === parseInt(uai_user) && plcyData.data.filter((v) => { return v.cp_status === null}).length !== 0 && (
                            <Box minWidth="20px">
                                {
                                    v.cp_status !== 3  &&
                                    <Checkbox icon={icon} checkedIcon={checkedIcon} sx={{p: '0px', pr:'4px', mb:'3px'}} checked={v.stat} onChange={(e) => handleCheckChange(e, v)}></Checkbox>
                                }
                            </Box>
                            
                            )
                        }
                        <Box height="100%" width="85%" display="flex" flexDirection="column" pl={1} onClick={() => handleClick('fullview', v)} gap="4px"  >
                            <Box width="100%" fontSize="12px" fontWeight="500" lineHeight="19px" display="flex" flexDirection="row" justifyContent="space-between">
                                <Box width="100%" color="#11783C" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{v.policy_num}</Box>
                                {
                                    __SESSION.data.uaccess[0].role_id !== parseInt(uai_user) ? (
                                        <Box minWidth="65px" display="flex" alignItems="flex-start" color={v.status === 2 && '#11783C' }>{v.status === 1 ? 'Uploaded' : 'Issued'}</Box>
                                    )
                                    :
                                        <Box minWidth="65px" display="flex" alignItems="flex-start" color={v.cp_status === 3 && '#11783C' }>{v.cp_status !== 3 ? 'Agree' : 'Agreed'}</Box>
                                }
                            </Box>
                            <Box fontSize="12px" fontWeight="400" lineHeight="19px">{v.dept_name}</Box>
                            <Box fontSize="14px" fontWeight="600" lineHeight="19px" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{v.policy_name}</Box>
                        </Box>
                        <Box minHeight={20} minWidth={10} display="flex" alignItems="center">
                            <IconButton onClick={() => handleClick('view', v)} >
                                <VeriticalThreeDots fill={"#737373"} />
                            </IconButton>
                        </Box>
                    </Box>

                ))
            }
            {
                inftScroll.data && (
                    <Box display="flex" justifyContent="center">
                        <Waypoint onEnter={loadMoreData}>
                            <Box component="strong" mt={5} display="flex" justifyContent="center">
                                <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                            </Box>
                        </Waypoint>
                    </Box>
                )
            }
        </Box>
    )
}